import React from 'react';
import {
    Grid,Typography,Breadcrumbs,
    Card,Table,TableCell,TableHead,TableRow,
    TableContainer,TableBody
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
}));

const ViewSalesInvoiceQuery = props => {
    const classes = useStyles();
    const [salesQueryData, setSalesQueryData] = React.useState(localStorage.getItem('customer-master-query') ? JSON.parse(localStorage.getItem('customer-master-query')) : []);

    var replaceChars={ 
        "_":" " ,
        "uom":"UoM",
        "id":"ID",
        "ima":"IMA"
        };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/material-x-master/material-x-master-excel-upload'
                >
                    Material Master XREF
                </Link>
                <Typography color="textPrimary" variant='h1'> View Material XREF</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="h3" color={'primary'}>
                                    {salesQueryData ? salesQueryData['format_name'] : ''}
                                </Typography>
                            </div>
                            {salesQueryData ?
                                <div
                                >
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} >Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)
                                                        
                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'organization_id' && v > 0 && k != 'ID' && k!='updated_by_id'&& k!=='created_by_id')
                                                        .sort(function (a, b) {
                                                            return a[1] > b[1] ? 1 : -1;
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_|uom|id|ima/g, function(match) {return replaceChars[match]})}</TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}}align='center'> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    );

};

export default connect(null,null)(ViewSalesInvoiceQuery);