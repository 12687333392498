import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton, TablePagination, Fab, Card, CardContent, CardActions,
    Tabs, Tab,
    Dialog, DialogContent, DialogContentText, DialogActions, Checkbox,
} from '@mui/material';
import { Edit, Visibility, DeleteForever, LibraryAdd, } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { createPriceListData, getAllDistinctPriceListData, getPriceMaintenanceFields, getPriceMaintenanceFieldsByPriceType, getUniquePriceData } from '../../../redux/actions/Pricing/AuthAction';
import secureLocalStorage from 'react-secure-storage';
import FilterComponent from './Components/FilterComponent';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    headContainer: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'left'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const PriceTypeConfigMain = props => {
    useEffect(() => {
        props.getConfigData(null, null, { 'price_list_id': [], 'price_type_name': [], 'price_type': [] });
    }, []);
    const tableRef = useRef(null);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [configData, setConfigData] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [priceListUnique, setPriceListUnique] = React.useState([]);
    const [priceType, setPriceType] = React.useState('');
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [formData, setFormData] = React.useState([]);
    const [isValid, setIsValid] = React.useState(false);

    var selectedData = []

    useEffect(() => {
        var tempRows = [];
        if (props.configData && props.configData.length > 0) {
            setConfigData(props.configData);
            props.configData
                .sort((a, b) => a.price_list_id > b.price_list_id ? -1 : 1)
                .sort((a, b) => (a.price_list_id < b.price_list_id) && (a.price_type_name > b.price_type_name) ? 1 : -1)
                .map((e, i) => {
                    tempRows.push({
                        id: i + 1,
                        price_list_id: e.price_list_id,
                        price_type_name: e.price_type_name,
                        price_type: e.price_type,
                        price_type_category: e.price_type_category,
                        calculation_type: e.calculation_type,
                        price_list_id_description: e.price_list_id_description,
                        number_of_records: e.number_of_records
                    });
                })
            setCount(props.configData.length)
            setDataRows(tempRows);
        }
        else {
            setCount(0)
            setDataRows([]);
        }
    }, [props.configData]);

    function editConfig(item) {
        if (selected && selected.length > 0 && selected.includes(item.id))
            editAll()
        else {
            localStorage.setItem('pricetypename', item.price_type_name)
            var data = [{ 'price_list_id': item.price_list_id, 'price_type_name': item.price_type_name, 'price_type': item.price_type, "calculation_type": item.calculation_type }]
            props.getPriceMaintenanceFields(data)
            history.push({
                pathname: '/pricing-maintenance/edit-list-data/' + item.price_list_id,
                data: { 'price_type_name': item.price_type_name, 'price_type': item.price_type },
                id: item.price_list_id
            });
        }
    }
    function editAll() {

        props.getPriceMaintenanceFields(formData)
        history.push({
            pathname: '/pricing-maintenance/edit-list-data-all',
            state: { type: 'priceType_based' }
        });
    }
    function openConfig(item) {
        if ((selected && selected.length > 0) || selected.includes(item.id)) {
            props.getPriceMaintenanceFields(formData)
            history.push({
                pathname: '/pricing-maintenance/view-list-data-all',
                state: { type: 'priceType_based', mode: 'view' }
            });
        }
        else {
            var data = [{ 'price_list_id': item.price_list_id, 'price_type_name': item.price_type_name, 'price_type': item.price_type, "calculation_type": item.calculation_type }]
            props.getPriceMaintenanceFields(data)
            history.push({
                pathname: '/pricing-maintenance/view-list-data/' + item.price_list_id,
                data: { 'price_type_name': item.price_type_name, 'price_type': item.price_type },
            });
        }
    }
    function createListData(item) {
        if ((selected && selected.length > 0) || selected.includes(item.id)) {
            props.getPriceMaintenanceFields(formData)
            history.push({
                pathname: '/pricing-maintenance/add-list-data/',
                state: { type: 'priceType_based', mode: 'bulk-add' }
            });
        }
        else {
            var data = [{ 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name, "calculation_type": item.calculation_type }]

            props.getPriceMaintenanceFields(data)
            history.push({
                pathname: '/pricing-maintenance/add-list-data',
                state: { type: 'priceType_based', mode: 'bulk-add' }
            });
        }
    }

    const deleteConfig = (id) => {
        var data = {
            "price_fields": formData
        }
        props.onDelete(data);
    }
    const [currentDelID, setCurrentDelID] = React.useState('');
    const handleDelete = (item) => {
        setCurrentDelID(item.price_list_id)
        if (selected && selected.length == 0) {
            var data = [{ 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name }]
            setFormData(data)
        }
        setOpenConfirmDelete(true)
    }

    const handleClick = (event, item) => {
        let newSelected = [];
        let newPriceId = []
        const selectedIndex = selected.indexOf(item.id);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(formData, { 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name })
            newPriceId = newPriceId.concat(selected, item.id);
            // setIsValid(((selected&&selected.length>1)||item.number_of_records!=0)?true:false)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(formData.slice(1));
            newPriceId = newPriceId.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(formData.slice(0, -1));
            newPriceId = newPriceId.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {

            newSelected = newSelected.concat(
                formData.slice(0, selectedIndex),
                formData.slice(selectedIndex + 1),
            );
            newPriceId = newPriceId.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newPriceId);
        setFormData(newSelected);
        if (newPriceId && newPriceId.length > 0) {
            var len = (dataRows.filter((k, i) => k.number_of_records == 0 && newPriceId.includes(k.id))).length
            if (len > 0 && (len == newPriceId.length)) {
                setIsValid(false)
            }
            else
                setIsValid(true)
        }
    };

    const handleCheckAll = () => {
        var IDS = [];
        var data = []
        if (!isCheckAll) {
            configData && configData.map((item, i) => {
                data.push({ 'price_list_id': item.price_list_id, "price_type": item.price_type, "price_type_name": item.price_type_name })
                IDS.push(i + 1)
            })
            setFormData(data)
            setSelected(IDS);
        } else {
            setSelected([]);
            setFormData([])
        }
        setIsCheckAll(!isCheckAll);
        if (IDS && IDS.length > 0) {
            var len = (dataRows.filter((k, i) => k.number_of_records == 0 && IDS.includes(k.id))).length
            if (len > 0 && (len == IDS.length)) {
                setIsValid(false)
            }
            else
                setIsValid(true)
        }
    }
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const columns = [
        {
            field: 'price_list_id',
            id: 'price_list_id',
            title: 'Price List ID',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'price_list_id_description',
            id: 'price_list_id_description',
            title: 'Price List ID Description',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'price_type_name',
            id: 'price_type_name',
            title: 'Price Type Name',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'price_type',
            id: 'price_type',
            title: 'Price Type',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'price_type_category',
            id: 'price_type_category',
            title: 'Price Type Category',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'calculation_type',
            id: 'calculation_type',
            title: 'Calculation Type',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'number_of_records',
            id: 'number_of_records',
            title: 'Number of Records',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'actions',
            id: 'actions',
            title: <div color='white' classes={{ root: classes.headContainer }}>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-' + sessionStorage.getItem('application')) &&
                    <Checkbox
                        size='small'
                        style={{ padding: '0px 30px 0px 0px' }}
                        onChange={handleCheckAll}
                    />}<span style={{ marginLeft: -15 }}>Actions</span></div>,
            type: 'string',
            editable: 'never',
            filtering: false,
            render: rowData => {
                const isItemSelected = isSelected(rowData.id);
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-' + sessionStorage.getItem('application')) &&

                            <Checkbox
                                color='primary'
                                size='small'
                                style={{ padding: '0px 10px 0px 0px' }}
                                checked={selected && selected.includes(rowData.id)}
                                key={rowData.price_list_id}
                                selected={selected && selected.includes(rowData.id)}
                                onClick={(event) => handleClick(event, rowData)}

                            />


                        }
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_price_maintenance-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => createListData(rowData)}
                                size="large">
                                <LibraryAdd color={selected && selected.length > 0 && selected.includes(rowData.id) ? 'primary' : "disabled"}
                                    style={{ fontSize: 20 }}
                                />
                            </IconButton>}
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editConfig(rowData)}
                                disabled={(isValid && (selected.includes(rowData.id)) || rowData.number_of_records != 0) ? false : true}
                                size="large">
                                <Edit color={isValid && (selected.includes(rowData.id)) ? 'primary' : "disabled"}
                                    style={{ fontSize: 20 }}
                                />
                            </IconButton>}
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('get_price_maintenance-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => openConfig(rowData)}
                                disabled={(isValid && (selected.includes(rowData.id)) || rowData.number_of_records != 0) ? false : true}
                                size="large">
                                <Visibility color={isValid && (selected.includes(rowData.id)) ? 'primary' : "disabled"}
                                    style={{ fontSize: 20 }}
                                />
                            </IconButton>
                        }
                        <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_price_maintenance-' + sessionStorage.getItem('application')) &&
                                <IconButton
                                    classes={{ root: classes.IconButton }}
                                    onClick={() => handleDelete(rowData)}
                                    disabled={(isValid && (selected.includes(rowData.id)) || rowData.number_of_records != 0) ? false : true}
                                    size="large">
                                    <DeleteForever color={isValid && (selected.includes(rowData.id)) ? 'primary' : "disabled"}
                                        style={{ fontSize: 20 }}
                                    />
                                </IconButton>
                            }
                        </div>
                    </div>
                );
            }
        },
    ]
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
    const [columnsList, setColumnsList] = React.useState(columns);

    function handleDialog(bool) {
        if (bool === 'No') {
            setOpenConfirmDelete(true)
        } else {
            deleteConfig(currentDelID)
            setOpenConfirmDelete(false);
        }
    }

    function handleSelect(e) {
        selectedData = e
        var formdata = []
        var selectedId = []
        selectedData.map((item, i) => {
            formdata.push({
                'price_list_id': item.price_list_id,
                "price_type": item.price_type,
                "price_type_name": item.price_type_name,
                "calculation_type": item.calculation_type
            })
            selectedId.push(item.id)
        })
        console.log(formdata)
        setFormData(formdata)
        //var selectedId=selectedData.map(item=>item.id)
        setSelected(selectedId)
        if (selectedData && selectedData.length > 0) {
            var len = (dataRows.filter((k, i) => k.number_of_records == 0 && selectedData.findIndex(({ id }) => id == k.id) > -1)).length
            if (len > 0 && (len == selectedData.length)) {
                setIsValid(false)
            }
            else
                setIsValid(true)
        }
    }

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Price Maintenance </Typography>
                    <div>
                        <FilterComponent />
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={createListData}
                            disabled={selected && selected.length > 0 ? false : true}
                            style={{ borderColor: (selected && selected.length > 0) ? theme.palette.primary.main : '#B8B8B8' }}
                        >
                            <LibraryAdd color={selected && selected.length > 0 ? 'primary' : 'disabled'} /><Typography color={selected && selected.length > 0 ? 'primary' : 'disabled'} style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={editAll}
                            disabled={(selected && selected.length > 0) && isValid ? false : true}
                            style={{ borderColor: (selected && selected.length > 0) && isValid ? theme.palette.primary.main : '#B8B8B8' }}
                        >
                            <Edit color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} /><Typography color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} style={{ marginLeft: 3 }}>Bulk Edit</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={openConfig}
                            disabled={(selected && selected.length > 0) && isValid ? false : true}
                            style={{ borderColor: (selected && selected.length > 0) && isValid ? theme.palette.primary.main : '#B8B8B8' }}
                        >
                            <Visibility color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} /><Typography color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} style={{ marginLeft: 3 }}>View</Typography>
                        </Fab>
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            className={classes.fabContainer}
                            onClick={handleDelete}
                            disabled={(selected && selected.length > 0) && isValid ? false : true}
                            style={{ borderColor: (selected && selected.length > 0) && isValid ? theme.palette.primary.main : '#B8B8B8' }}
                        >
                            <DeleteForever color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} /><Typography color={(selected && selected.length > 0) && isValid ? 'primary' : 'disabled'} style={{ marginLeft: 3 }}>Delete</Typography>
                        </Fab>
                    </div>

                </div>

                {configData ?
                    <MaterialTable
                        tableRef={tableRef}
                        components={{

                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        onSelectionChange={handleSelect}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            sorting: false,
                            paging: false,
                            selectionProps: rowData => ({
                                color: 'primary',
                            }),
                            selectionColumnIndex: -1,
                            // pageSize: rowsPerPage,
                            // count: count,
                            // page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,

                            // pageSize: 10,
                            // pageSizeOptions: [5, 10, 20],

                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

            <Dialog open={openConfirmDelete}>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            Are you sure you want to delete ?
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpenConfirmDelete(false)} autoFocus color="primary" variant="outlined" >
                        No
                    </Button>
                    <Button onClick={() => handleDialog('Yes')} color="primary" autoFocus variant="contained"  >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        loading: state.pricingData.loading,
        configData: state.pricingData.distinctPriceList,
        filterList: state.pricingData.uniquePriceData,
        filterListID: state.pricingData.uniquePriceID,
        filterListType: state.pricingData.uniquePriceType,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: (pagination, limit, data) => dispatch(getAllDistinctPriceListData(pagination, limit, '', data)),
        onDelete: (id) => dispatch(createPriceListData(null, id, null, 'delete')),
        getPriceMaintenanceFields: (data) => dispatch(getPriceMaintenanceFieldsByPriceType(data)),
        getUniquePriceData: (name) => dispatch(getUniquePriceData(name))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceTypeConfigMain);