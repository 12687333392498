import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    CardHeader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { createIntegrationMapping, getIntegrationSetupData, getIntegrationMappingDataById, getIntegrationMappingFields, deleteIntegrationMapping } from '../../../redux/actions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DeleteForever, Edit, Check } from '@mui/icons-material';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 17
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
}));
const EditIntegrationMapping = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [activity, setActivity] = React.useState('');
    const [integrationName, setIntegrationName] = React.useState('');
    const [sourceField, setSourceField] = React.useState('');
    const [targetField, setTargetField] = React.useState('');
    const [filter, setFilter] = React.useState(false);
    const [integrationFields, setIntegrationFields] = React.useState([]);
    const [editID, setEditID] = React.useState(0);
    const activityArray = ["Direct - Sales Data", "Customer Master", "Material Master", "Supplier Master", "Membership", "Direct - Purchase Data", "Accrual", "COPA", "Payment"];
    const [mapID, setMapID] = React.useState(0);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    const [open, setOpen] = React.useState(false);
    const [integrationMapData, setIntegrationMapData] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [integrationNameArray, setIntegrationNameArray] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [dataSource, setDataSource] = React.useState();
    const location = useLocation()
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        var datasource = location.state.datasource;
        setDataSource(datasource)
        setEditID(appId)
        if (appId) {
            props.getIntegrationData(appId)
        }
        console.log(appId)
    }, [sourceField, targetField]);
    useEffect(() => {
        if (activity)
            props.getIntegrationFields(activity);
    }, [activity]);
    useEffect(() => {
        if (props.integrationData) {
            if (props.integrationData[0].integration_name)
                setIntegrationName(props.integrationData[0].integration_name)
            if (props.integrationData[0].activity)
                setActivity(props.integrationData[0].activity)
            setIntegrationMapData(props.integrationData)
        }
    }, [props.integrationData]);
    useEffect(() => {
        if (props.integrationFields) {

            setIntegrationFields(props.integrationFields);
        }
    }, [props.integrationFields]);
    function handleSubmit(index) {
        var data = {
            "integration_name": integrationName,
            "activity": activity,
            "source_field_name": sourceField,
            "target_field_name": targetField,
        };
        if (editMode) {
            props.onSubmit(data, mapID, 'edit')
        }
        else {
            props.onSubmit(data)
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    useEffect(() => {
        var temp = []
        if (props.integrationList && props.integrationList.records && props.integrationList.total_record > 0) {
            props.integrationList.records.map((item, index) => {
                temp.push(item.name)
            })
        }
        setIntegrationNameArray(temp)
    }, [props.integrationList]);
    function handleRemove() {
        setEditMode(false);
        setEditIndex('');
        setSourceField('')
        setTargetField('')
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setMapID(item.ID)
        setActivity(item.activity);
        setSourceField(item.source_field_name)
        setTargetField(item.target_field_name)
        setIntegrationName(item.integration_name)
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/integration-mapping'
                >
                    Integration Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Integration Mapping</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Integration Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={integrationName}
                                        classes={{ root: classes.inputTwoLine }}
                                        disabled
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Activity
                                    </FormLabel>
                                    <OutlinedInput
                                        value={activity}
                                        classes={{ root: classes.inputTwoLine }}
                                        disabled
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {dataSource === 'Kafka Producer' ? 'Target' : 'Source'} Field Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={sourceField}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setSourceField(e.target.value)} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {dataSource === 'Kafka Producer' ? 'Source' : 'Target'} Field Name
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => setTargetField(e.target.value)}
                                        value={targetField}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        style={{ textTransform: 'capitalize' }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {integrationFields.map(item => {
                                            return (
                                                <MenuItem value={item} key={item} name={item} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 23, marginTop: 15 }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Edit />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit(editIndex)}
                                                    disabled={sourceField && targetField ? false : true}
                                                    style={{ marginTop: 9 }}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Check />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={sourceField && targetField ? false : true}
                                                    onClick={handleSubmit}
                                                    style={{ marginTop: 9 }}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForever />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                                style={{ marginTop: 9 }}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="INTEGRATION MAPPING DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {integrationMapData.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead} >Integration Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead}  >Activity</TableCell>
                                                <TableCell align='center' className={classes.tabHead} > {dataSource === 'Kafka Producer' ? 'Target' : 'Source'}  Field Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead} > {dataSource === 'Kafka Producer' ? 'Source' : 'Target'}  Field Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead} style={{ width: 70 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {integrationMapData.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.integration_name}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.activity}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.source_field_name}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.target_field_name.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <Edit color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => props.onDelete(item.ID, editID)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForever />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        integrationData: state.customerData.integrationMappingSingleData,
        integrationFields: state.customerData.integrationMappingFields,
        integrationList: state.customerData.integrationSetupData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(createIntegrationMapping(data, id, type)),
        onDelete: (id, typeId) => dispatch(deleteIntegrationMapping(id, typeId)),
        getIntegrationData: (id) => dispatch(getIntegrationMappingDataById(id)),
        getIntegrationFields: (activity) => dispatch(getIntegrationMappingFields(activity)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditIntegrationMapping);