import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    TextField,
    Checkbox,
    ListItemText, Button, OutlinedInput
} from '@mui/material';
import { runCalculationFormulaValuesByContractReports, getDefaultValues, getFormulasList, getDefaultValuesAllData } from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import { Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown } from '../../../components/Inputs';
import { AllOtherAttributeMasterComponent, ContractNumberAC, MasterDataComponent } from '../../../components/Inputs';
import { RingLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    container: {
        paddingTop: 18
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    spinner: {
        height: '100vh'
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    selectRoot: {
        padding: "0px 0px 15px 15px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    select: {
        width: "100%",
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: '0px 15px 0px 15px'
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
}));

const CalculateFormulaValuesByContractTab = props => {
    const classes = useStyles();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [attributeName, setAttributeName] = React.useState([]);
    const [aggAttributeName, setAggAttributeName] = React.useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState('');
    const [secondKeyFigure, setSecondKeyFigure] = React.useState('');
    const [selected3, setSelected3] = React.useState([]);
    const [open3, setOpen3] = React.useState('');
    const [selected4, setSelected4] = React.useState([]);
    const [open4, setOpen4] = React.useState('');
    const isAllSelected3 = attributeNameArray && Object.entries(attributeNameArray).length > 0 && selected3.length === Object.entries(attributeNameArray).length;
    const isAllSelected4 = attributeNameArray && Object.entries(attributeNameArray).length > 0 && selected4.length === Object.entries(attributeNameArray).length;
    const [dynamicFields, setDynamicFields] = React.useState([])
    const [attributeValues, setAttributeValues] = React.useState({});
    const [activeDropdown, setActiveDropdown] = React.useState({});
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.onLoadingDefault('Customer Rebate', 'Direct - Sales Data');
    }, [])
    useEffect(() => {
        var newArray = []
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records.length > 0) {
            setAttributeNameArray(props.dropdownAttributeData.records[0].label_names)
        }
    }, [props.dropdownAttributeData])
    useEffect(() => {
        var newArray = []
        if (attributeName && attributeName.length > 0 && attributeNameArray && Object.entries(attributeNameArray).length > 0) {
            Object.entries(attributeNameArray).map(([key, value]) => {
                if (attributeName.includes(key)) {
                    newArray.push({ 'key': key, 'name': value })
                }
            })
            setDynamicFields(newArray)
        }

    }, [attributeName, attributeNameArray])
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected3.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected3, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected3.slice(1));
        } else if (selectedIndex === selected3.length - 1) {
            newSelected = newSelected.concat(selected3.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected3.slice(0, selectedIndex),
                selected3.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected3(selected3.length === Object.entries(attributeNameArray).length ? [] : Object.entries(attributeNameArray).map(([key, value]) => key));
        }
        else
            setSelected3(newSelected)
    }
    const handleSelectValuess = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected4.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected4, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected4.slice(1));
        } else if (selectedIndex === selected4.length - 1) {
            newSelected = newSelected.concat(selected4.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected4.slice(0, selectedIndex),
                selected4.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected4(selected4.length === Object.entries(attributeNameArray).length ? [] : Object.entries(attributeNameArray).map(([key, value]) => key));
        }
        else
            setSelected4(newSelected)
    }
    function handleOpen() {
        setSelected3(attributeName)
        setOpen3(true)
    }
    function handleOpenn() {
        setSelected4(aggAttributeName)
        setOpen4(true)
    }
    function onCancel() {
        setOpen3(false)
        setSelected3([])
    }
    function onCancell() {
        setOpen4(false)
        setSelected4([])
    }
    function onAdd() {
        setAttributeName(selected3)
        setOpen3(false)
        setSelected3([])
    }
    function onAddd() {
        setAggAttributeName(selected4)
        setOpen4(false)
        setSelected4([])
    }
    function handleClose() {
        setOpen3(false)
    }
    function handleClosee() {
        setOpen4(false)
    }
    function handleAttributeValues(newValue, item, key) {
        setAttributeValues({ ...attributeValues, [item]: key ? [Number(newValue)] : [newValue] })
    }
    function handleAttributeValue(newValue, key, type, type2) {
        let temp = []
        if (type2 === "pastedItem") {
            newValue.forEach((arg) => {
                if (arg) {
                    setAttributeValues((prevData) => ({
                        ...prevData,
                        [key]: [...(prevData[key] || []), arg.trim()],
                    }));
                }
            });
        }
        else {
            // setAttributeValues((prevData) => ({
            //     ...prevData,
            //     [key]: [...(prevData[key] || []), newValue],
            // }));
            setAttributeValues((prevData) => ({
                ...prevData,
                [key]: newValue,
            }));
        }
    }
    function handleDeleteChip(newValue, key) {
        setAttributeValues((prevData) => {
            const currentData = prevData[key] || [];
            const updatedArray = currentData.filter((item) => item !== newValue);
            return {
                ...prevData,
                [key]: updatedArray,
            };
        });
    }
    const onSubmitHandler = () => {
        var formData = {
            "start_date": billingDateStart ? Moment.utc(billingDateStart).format('MM/DD/YYYY') : '',
            "end_date": billingDateEnd ? Moment.utc(billingDateEnd).format('MM/DD/YYYY') : '',
            "aggregate_by": aggAttributeName.toString(),
            // "formula_name": formulaName,

        };
        // if (aggAttributeName.length > 0) {
        //     // formData["aggregate_by"] = aggAttributeName;
        // }
        formData["filters"] = attributeValues;
        props.onSubmit(formData);
        onCancelHandler();
    }
    const onCancelHandler = () => {
        setBillingDateStart(null)
        setBillingDateEnd(null)
        setAttributeValues({})
        setSecondKeyFigure('')
        setAttributeName([])
        setDynamicFields([])
        setAggAttributeName([])
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader />}
            className={classes.spinner}>
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'> Calculate Formula Values By Contract</Typography>
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}

                            >
                                <div style={{ display: 'flex' }}>

                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required >Billing Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: '0.6rem',
                                                            border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: '37px'
                                                        }
                                                    }}
                                                    value={billingDateStart}
                                                    onChange={handleBillingDateStart}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                    <div className={classes.selectRoot}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: '4.2rem',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10,
                                                            height: '37px'
                                                        }
                                                    }}
                                                    value={billingDateEnd}
                                                    onChange={handleBillingDateEnd}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={billingDateStart ? billingDateStart : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >   <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel} required>
                                        Aggregation Attribute Name
                                    </FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={aggAttributeName}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        style={{ maxHeight: "50px", width: '100%', height: 37, marginTop: 10 }}
                                        // style={{ marginTop: '2rem', marginLeft: '-8rem' }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpenn()}
                                        onClose={handleClosee}
                                        open={open4}
                                        renderValue={(appType) => {
                                            var priceType = []
                                            priceType = Object.entries(attributeNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                            return priceType.join(", ")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValuess(event, 'all',)}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected4} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {attributeNameArray &&
                                            Object.entries(attributeNameArray)
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValuess(event, key)} value={key} key={key}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected4.indexOf(key) > -1} />
                                                            <ListItemText primary={value} />
                                                        </MenuItem>
                                                    )
                                                })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancell}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAddd('identifier')} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >   <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Filter Attribute Name
                                    </FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={attributeName}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        style={{ maxHeight: "50px", height: 37, width: '100%', marginTop: 10 }}
                                        // style={{ marginTop: '2rem', marginLeft: '-8rem' }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen()}
                                        onClose={handleClose}
                                        open={open3}
                                        renderValue={(appType) => {
                                            var priceType = []
                                            priceType = Object.entries(attributeNameArray).filter(([key, value]) => appType.includes(key)).map(([key, value]) => value)
                                            return priceType.join(", ")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all',)}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected3} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {attributeNameArray &&
                                            Object.entries(attributeNameArray)
                                                .map(([key, value]) => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValues(event, key)} value={key} key={key}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected3.indexOf(key) > -1} />
                                                            <ListItemText primary={value} />
                                                        </MenuItem>
                                                    )
                                                })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid>
                            {dynamicFields && dynamicFields.length > 0 && dynamicFields.map(item => {
                                return (
                                    (item.key == 'supplier_number' || item.key == 'employee_number' || item.key == 'customer_number' || item.key == 'material_number') && activeDropdown && activeDropdown[item.key] ?
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        // style={{ marginTop: '0.6rem' }}
                                        >    <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formLabel}>
                                                    {item.name}
                                                </FormLabel>
                                                <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} onChange={(value) => handleAttributeValues(value, item.key, item.is_key_figure)} />
                                            </div>
                                        </Grid>

                                        :
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            style={{ marginTop: '10px' }}
                                        >
                                            {props.viewMode ?
                                                <LabelText heading={item.name} data={attributeValues && attributeValues[item.key] && attributeValues[item.key].join(",")} twoline='true' required />
                                                :
                                                <>   <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel}>
                                                        {item.name}
                                                    </FormLabel>
                                                    <ChipInput data={attributeValues[item.key] || []} onChange={newValue => handleAttributeValue(newValue, item.key)} />
                                                    {/* <ChipInput
                                                        classes={{
                                                            root: classes.rootContainer,
                                                            chip: classes.chip,
                                                            input: classes.input,
                                                            inputRoot: classes.inputRoot,
                                                            chipContainer: classes.chipContainer,
                                                            label: classes.chipLabel
                                                        }}
                                                        value={attributeValues[item.key] || []}
                                                        onAdd={(chips) => handleAttributeValue(chips, item.key)}
                                                        onDelete={(chip) => handleDeleteChip(chip, item.key)}
                                                        onPaste={(event) => {
                                                            const clipboardText = event.clipboardData
                                                                .getData("Text")
                                                                .split("\n");
                                                            event.preventDefault();
                                                            handleAttributeValue(
                                                                clipboardText,
                                                                item.key,
                                                                null,
                                                                "pastedItem"
                                                            );
                                                            if (props.onPaste) {
                                                                props.onPaste(event);
                                                            }
                                                        }}
                                                        disableUnderline={true}
                                                        className={clsx({
                                                            [classes.textInput]: true
                                                        })}
                                                    /> */}
                                                </div>
                                                </>
                                            }
                                        </Grid>
                                )
                            })}
                        </Grid>
                        {/* // */}

                        {/* // */}
                        <div className={classes.buttonRoot}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler}
                                disabled={billingDateStart && billingDateEnd && aggAttributeName.length > 0 ? false : true}
                            >
                                Run
                            </Button>
                        </div>
                    </div>
                </div >
            </div >
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runCalculationFormulaValuesByContractReports(formData)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculateFormulaValuesByContractTab);