import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Radio, RadioGroup,
    FormControlLabel, FormLabel, Select, MenuItem,
    OutlinedInput, TextField, Checkbox,
    ListItemText, Input, useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getContractsTableDataList, getCalculationSimulationNumbers, getCustomerMasterData, postCollaborationPortal, runContractTrends, loadValuesBasedOnAppTypeAndField, allowedApps, fetchDropdownConfig, downloadContractTrendsResltsSheet } from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import secureLocalStorage from 'react-secure-storage';
import { SearchDropdown, ChipInput } from '../../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 5,
        padding: "10px 10px 0px 10px",
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px',
        marginTop: '1.5rem'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        fontSize: theme.typography.h3.fontSize,
        marginTop: '1.7rem'
    },
    spinner: {
        height: '100vh'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: '2.6rem',
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    menuItem: {
        height: '500px'
    },

    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        marginBottom: 6
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const ContractTrends = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [displayLevel, setDisplayLevel] = React.useState([]);
    const [open2, setOpen2] = React.useState("");
    const [selected2, setSelected2] = React.useState([]);
    const AggregationLevelArray = [
        "bill_to_party",
        "customer_number",
        "customer_group",
        "customer_chain",
        "flex_attribute1",
        "material_number",
        "material_group",
        "payer",
        "product_hierarchy",
        "profit_center",
        "plant",
        "sold_to_party",
        "supplier_number",
        "parent_customer_number",
        "top_parent_customer_number",
        "employee_number",
        "territory_id",
    ];
    const isAllSelected2 =
        AggregationLevelArray &&
        AggregationLevelArray.length > 0 && selected2.length === AggregationLevelArray.length;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.onCheckContractsTable();
        localStorage.removeItem('contractTrendsQuery')
        props.getdropdownConfig('customer_number')
        setCalculationPeriodStart(Moment.utc().subtract(1, "months").startOf("month"));
        setCalculationPeriodEnd(Moment.utc().subtract(1, "months").endOf("month"));
    }, []);
    useEffect(() => {
        props.allowedApps();
        //props.onCheckCustomerTable();
        if (secureLocalStorage.getItem("dYtz").collaboration_id != '0') {
            props.postCollaborationPortal(null, secureLocalStorage.getItem("dYtz").collaboration_id, 'get')
        }
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
    }, []);
    useEffect(() => {
        var tempArray = [];
        var tempNameArray = [];
        var temp1 = [];
        if (props.collaborationPortalData && props.collaborationPortalData.records && props.collaborationPortalData.records[0].customer_number) {
            temp1 = props.collaborationPortalData.records[0].customer_number
        }
        if (temp1 && temp1.length > 0 && props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {

            props.customerMasterData.records
                .filter(item => temp1.includes(item.customer_number))
                .map((item) => {
                    tempArray.push(item.customer_number);
                    tempNameArray.push(item.customer_name);
                })

            setCustomerNameListArray(tempNameArray);
            setCustomerNumberList(tempArray)
        }
    }, [props.customerMasterData, props.collaborationPortalData])
    useEffect(() => {
        var tempArray = [];
        var tempNameArray = [];
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            props.customerMasterData.records
                .map((item) => {
                    tempArray.push(item.customer_number);
                    tempNameArray.push(item.customer_name);
                })

            setCustomerNameListArray(tempNameArray);
            setCustomerNumberList(tempArray)
        }
    }, [props.customerMasterData])
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState('');
    const [radioGroupValue, setRadioGroupValue] = React.useState('calculation_simulation');
    const [open, setOpen] = React.useState(false);
    const [postingNumber, setPostingNumber] = React.useState('');
    const [calculationSimulationNumbers, setCalculationSimulationNumbers] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [tags, setTags] = useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    useEffect(() => {
        if (props.dropdownConfig)
            setActiveDropdown(props.dropdownConfig)

    }, [props.dropdownConfig])
    const filterOptions2 = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    function handleTags(newValue) {
        setTags(newValue)
    }
    function handleCustomerDelete(newValue) {
        var deletedTags = customerNumber.filter(item => item !== newValue)
        setCustomerNumber(deletedTags)
    }
    var formData = {
        "customer_number": customerNumber.toString(),
        "start_date": Moment(calculationPeriodStart).format('MM/DD/YYYY'),
        "end_date": Moment(calculationPeriodEnd).format('MM/DD/YYYY'),
        "contract_number": contractNumber,
        "posting_number": postingNumber,//radioGroupValue === 'calculation_simulation' ? calculationSimulationNumber : postingNumber,
        "posting_type": radioGroupValue === 'calculation_simulation' ? '' : radioGroupValue,
        "display_level": displayLevel.toString()
    };
    function handleOpen2() {
        setSelected2(displayLevel);
        setOpen2(true);
    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            if (sourceDataType == "Direct - Sales Data")
                setSelected2(
                    selected2.length ===
                        AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
            else
                setSelected2(
                    selected2.length === AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
        } else setSelected2(newSelected);
    };
    function onAdd2() {
        setDisplayLevel(selected2);
        setOpen2(false);
        setSelected2([]);
    }
    function onCancel2() {
        setOpen2(false);
        setSelected2([]);
    }
    const handleClickOpen = () => {
        localStorage.setItem("contractTrendsQuery", JSON.stringify(formData));
        props.runContractTrends(formData);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);

            props.onSubmit(formData);
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        setCalculationPeriodStart(newValue);
    }
    function handleCalculationPeriodEnd(newValue) {
        setCalculationPeriodEnd(newValue);
    }
    function handleContractNumber(e) {
        setContractNumber(e);
    }
    useEffect(() => {
        if (contractNumber && radioGroupValue && calculationPeriodStart && calculationPeriodEnd)
            props.getCalculationSimulationNumbers(contractNumber, Moment(calculationPeriodStart).format('MM/DD/YYYY'), Moment(calculationPeriodEnd).format('MM/DD/YYYY'), radioGroupValue)
        else
            setCalculationSimulationNumbers([])
    }, [contractNumber, radioGroupValue, calculationPeriodStart, calculationPeriodEnd])
    function handleRadioGroup(newValue) {
        setPostingNumber('');
        setRadioGroupValue(newValue.target.value);
    }
    function handleCustomerNumber(newValue, type) {
        // if (type == 'custom')
        //     setCustomerNumber([...customerNumber, newValue])
        // else
        setCustomerNumber(newValue)
    }
    useEffect(() => {
        if (props.calculationSimulationNumbers)
            setCalculationSimulationNumbers(props.calculationSimulationNumbers)
        else
            setCalculationSimulationNumbers([])
    }, [props.calculationSimulationNumbers])
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    useEffect(() => {
        var contracts = [];
        var tempNameArray = []
        if (props.contractablesdata && props.contractablesdata.length > 0) {
            props.contractablesdata.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);

        }

    }, [props.contractablesdata])
    const handleClear = () => {
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setContractNumber('');
        setTags([]);
        setRadioGroupValue('calculation_simulation');
        setPostingNumber('');
        setCalculationSimulationNumbers([]);
        setDisplayLevel([]);
        setCustomerNumber([]);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }} >Calculation Analysis</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }} style={{ marginBottom: 10 }}>
                                    Calculation Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            size="medium"
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: '2.5rem'
                                                }
                                            }}
                                            value={calculationPeriodStart}
                                            onChange={handleCalculationPeriodStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }} style={{ marginBottom: 10 }}>
                                    Calculation End Date
                                </FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: '2.5rem'
                                                }
                                            }}
                                            value={calculationPeriodEnd}
                                            onChange={handleCalculationPeriodEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            // style={{ marginTop: '1.2rem' }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }} >
                                    Contract Number
                                </FormLabel>
                                <div style={{ marginTop: -10 }}>
                                    <SearchDropdown id='contract_number'
                                        table='contract_header' keyName='contract_number'
                                        description={true}
                                        onChange={handleContractNumber}
                                        prevalue={contractNumber}
                                        applicationType={sessionStorage.getItem('application')}

                                    />
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            // style={{ marginTop: '1.2rem' }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} >
                                    Customer Number
                                </FormLabel>
                                <div style={{ marginTop: -5 }}>
                                    <SearchDropdown id='customer_number_multiple'
                                        multiple
                                        table='customer_master' keyName='customer_number'
                                        description={true}
                                        onChange={handleCustomerNumber}
                                        prevalue={customerNumber}

                                    />
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            //style={{ marginTop: '-1.1rem' }}
                            >
                                <FormLabel className={classes.fontSetting}
                                    required={radioGroupValue === 'calculation_simulation' ? true : false}
                                >
                                    {radioGroupValue && radioGroupValue === 'calculation_simulation' ? 'Calculation Simulation Number' : 'Posting Number'}
                                </FormLabel>
                                <Select
                                    value={postingNumber}
                                    onChange={(e) => setPostingNumber(e.target.value)}
                                    displayEmpty

                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {calculationSimulationNumbers && Object.entries(calculationSimulationNumbers).sort(([a1, b1], [a2, b2]) => a2 - a1).map(([key, value]) => {
                                        return (
                                            <MenuItem value={key} key={key}>
                                                {value ? key + " - " + value : key}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>

                            <Grid item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            // style={{ marginTop: '0.5rem' }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Display Level
                                </FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={displayLevel}
                                    style={{ width: "100%", textTransform: "capitalize" }}
                                    input={<OutlinedInput sx={{ padding: '4px' }} />}
                                    onOpen={() => handleOpen2()}
                                    onClose={() => setOpen2(false)}
                                    open={open2}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                    renderValue={(appType) => {
                                        var priceType = [];
                                        appType.map((item) => {
                                            priceType.push(item.replace(/_/g, " "));
                                        });
                                        return priceType.join(", ");
                                    }}
                                >
                                    <MenuItem
                                        value="all"
                                        onClick={(event) => handleSelectValues2(event, "all")}
                                        style={{ textTransform: "capitalize", display: 'flex', justifyConent: 'center' }}
                                    >
                                        <Checkbox color="primary" checked={isAllSelected2} />
                                        <ListItemText primary="Select All" />
                                    </MenuItem>
                                    {AggregationLevelArray &&
                                        AggregationLevelArray.map((item) => {
                                            return (
                                                <MenuItem
                                                    onClick={(event) =>
                                                        handleSelectValues2(event, item)
                                                    }
                                                    value={item}
                                                    key={item}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selected2.indexOf(item) > -1}
                                                    />
                                                    <ListItemText primary={item.replace(/_/g, " ")} />
                                                </MenuItem>
                                            );
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.btn}
                                            onClick={onCancel2}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn}
                                            onClick={onAdd2}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Tags
                                </FormLabel>
                                <ChipInput data={tags} onChange={handleTags} />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex' }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="calculation_simulation"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Calculation Simulation </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="accrual"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Accrual </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="payment"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Payment </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    (radioGroupValue === 'calculation_simulation' && !postingNumber) || !contractNumber || !calculationPeriodStart || !calculationPeriodEnd ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run Online'
                                }
                            </Button>
                            <Button
                                disabled={
                                    (radioGroupValue === 'calculation_simulation' && !postingNumber) || !contractNumber || !calculationPeriodStart || !calculationPeriodEnd ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={() => { props.downloadSheet(formData) }} twoline='true'>
                                {props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Download'
                                }
                            </Button>
                        </div>
                    </div>

                </form >
                <SimpleDialog open={open} content='Do you want to submit financial postings?' handleDialog={handleDialog} />
            </div >
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        runContractTrends: (formData) => dispatch(runContractTrends(formData, "contractV2")),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getCalculationSimulationNumbers: (e, sd, ed, postingType) => dispatch(getCalculationSimulationNumbers(e, sd, ed, postingType)),
        onCheckContractsTable: () => dispatch(getContractsTableDataList('AllApps')),
        allowedApps: () => dispatch(allowedApps()),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 0)),
        postCollaborationPortal: (data, editId, type) => dispatch(postCollaborationPortal(data, editId, type)),
        getdropdownConfig: (field) => dispatch(fetchDropdownConfig(field)),
        downloadSheet: (formData) => dispatch(downloadContractTrendsResltsSheet(formData)),
    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        calculationSimulationNumbers: state.addMultipleConfigurationData.calculationSimulationNumbers,
        contractablesdata: state.addContractData.contractAllListData,
        allowedAppsData: state.initialData.allowedApps,
        customerMasterData: state.customerData.customerMasterData,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        dropdownConfig: state.financialCloseData.dropdownFieldConfig
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTrends);