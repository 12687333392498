import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Button, IconButton, MenuItem, Grid, OutlinedInput, FormLabel, Select,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Edit, DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import { getQueryasList, getQueryDetails, submitQuery, runPredefinedQuery, getPredefinedQuery, getExcelPredefinedFileDownlaod } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
import Tooltip from "@mui/material/Tooltip";
import { DirectionsRun } from '@mui/icons-material';
import BeatLoader from "react-spinners/BeatLoader";
import { TextInputWithLabel } from '../../components/Inputs';
import { toast } from 'react-toastify';
import FileDownload from '@mui/icons-material/FileDownload';
import Email from '@mui/icons-material/Email';
import LoadingOverlay from 'react-loading-overlay';
import { RingLoader } from 'react-spinners';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
    spinner: {
        height: '100vh'
    },
}));
const PredefinedQuery = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentPage, setCurrentPage] = React.useState('');
    const [queryListID, setQueryListID] = React.useState(1);
    const location = useLocation();
    const [queryListData, setQueryListData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [activity, setActivity] = React.useState('');
    const [value, setValue] = React.useState([]);
    const [name, setName] = React.useState([]);
    const [nameArray, setNameArray] = React.useState([]);
    const [addName, setAddName] = React.useState(1);
    const [mode, setMode] = React.useState('');
    useEffect(() => {
        setCurrentPage(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))
    }, [location])
    useEffect(() => {
        if (props.queryListData && props.queryListData.records) {
            setQueryListData(props.queryListData)
        }
    }, [props.queryListData])
    useEffect(() => {
        if (currentPage) {
            if (currentPage === 'partner-statement') {
                setQueryListID(3)
                props.getQueryList(1, 10, 3, sessionStorage.getItem('application'));
            } else if (currentPage === 'collaboration-portal') {
                setQueryListID(3)
                props.getQueryList(1, 10, 2, sessionStorage.getItem('application'));
            } else {
                setQueryListID(1)
                props.getQueryList(1, 10, 1, sessionStorage.getItem('application'));
            }
        }
    }, [currentPage])
    // useEffect(() => {
    //     props.getQueryasList(1, 10);
    // }, []);
    useEffect(() => {
        if (props.queryListData && props.queryListData.length > 0) {
            setApiResponse2(props.queryListData);
        }
    }, [props.queryListData]);
    function runQuery(item, type) {
        localStorage.setItem('mode', type)
        localStorage.setItem('current-id', item.ID)
        if (item.filters == null) {
            handleSubmit()
        } else {
            setOpen(true)
        }
        // setCurrentId(item.ID)
        if (item.filters !== null) {
            setNameArray(item.filters)
        }
        setValue([])
    }
    const handleEdit = (item) => {
        history.push({
            pathname: '/query-field/edit-query-field/' + item.ID,
            id: item.ID
        })
        props.getQueryDetails(item.ID);
        localStorage.setItem('query-details', JSON.stringify(item));
    }
    function viewQuery(item) {
        localStorage.setItem('query-details', JSON.stringify(item))
        history.push({
            pathname: '/query-field/view-query-field/' + item.ID,
            id: item.ID
        });
    }
    function handleSubmit() {
        var json = {}
        setOpen(false);
        nameArray.map((item, index) => {
            var [one, two, three] = nameArray[index].split(" ")
            if (item.includes('IN')) {
                json[three] = [value[index]]
            }
            else if (item.includes('BETWEEN')) {
                if (!value[index].includes('and')) {
                    toast.error('Please Enter date like this 01/01/2020 and 12/31/2020');
                } else {
                    var [value1, value2] = value[index].split('and')
                    json[three] = value1.trim()
                    json['$' + (index + 2)] = value2.trim()
                }
            }
            else {
                json[three] = value[index]
            }
        })
        if (localStorage.getItem('mode') === 'Excel') {
            props.getExcelPredefinedFileDownlaod(json, localStorage.getItem('current-id'));
        } 
        else if(localStorage.getItem('mode') === 'Email'){
            props.getExcelPredefinedFileDownlaod(json, localStorage.getItem('current-id'),'Email');
        }
        else {
            props.runPredefinedQuery(json, localStorage.getItem('current-id'));
        }
    }
    function handleFilterName(newValue, index) {
        name[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        value[index] = newValue;
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}>
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginTop: 8 }}> Predefined Query</Typography>

                </div>
                {props.queryListData && props.queryListData.length > 0 ?
                    <TableContainer style={{ marginTop: -15 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >

                                    <TableCell align='center' className={classes.tabHead}>Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Description</TableCell>
                                    {/* <TableCell align='center' className={classes.tabHead}>Query</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {apiResponse2

                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.ID} >

                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewQuery(item)} style={{ cursor: 'pointer' }}>{item.Name}</TableCell>

                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewQuery(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.Description}</TableCell>
                                                {/* <TableCell align='center' classes={{root:classes.fontSetting}} onClick={() => viewQuery(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.Query}</TableCell> */}
                                                {props.loading ?
                                                    <TableCell width={300} align='center' classes={{ root: classes.fontSetting }}>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell> :
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights &&
                                                                <Tooltip title='Run'>
                                                                    <IconButton
                                                                        classes={{ root: classes.IconButton }}
                                                                        onClick={() => runQuery(item)}
                                                                        style={{ marginLeft: 5 }}
                                                                        size="large">
                                                                        <DirectionsRun color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                </Tooltip>}
                                                            <Tooltip title='Downlaod Excel'>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => runQuery(item, 'Excel')}
                                                                    style={{ marginLeft: 5 }}
                                                                    size="large">
                                                                    <FileDownload color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title='Email'>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => runQuery(item, 'Email')}
                                                                    style={{ marginLeft: 5 }}
                                                                    size="large">
                                                                    <Email color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </div>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2} style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }} >
                                    {(() => {
                                        let td = [];
                                        for (let i = 0; i < (nameArray ? nameArray.length : addName); i++) {
                                            td.push(<>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <TextInputWithLabel classes={{ root: classes.formLabel }} heading={i == 0 && 'Name'} twoline='true' onChange={(e) => handleFilterName(e, i)}
                                                        data={nameArray && nameArray[i]} prevalue={nameArray[i]}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <TextInputWithLabel classes={{ root: classes.formLabel }} heading={i == 0 && 'Value'} twoline='true' onChange={(e) => handleFilterValue(e, i)}
                                                        data={value && value[i]} prevalue={value[i]}
                                                    />
                                                </Grid>

                                            </>)
                                        }
                                        return td;
                                    })()}
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => setOpen(false)} autoFocus color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus variant="contained"
                        // disabled={value.filter(item => item === "").length === 0}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.QueryFieldData.queryListData,
        loading: state.operationalReportsData.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getQueryList: (pagination, limit) => dispatch(getQueryasList(pagination, limit)),
        getQueryDetails: (id) => dispatch(getQueryDetails(id)),
        deleteQuery: (data, id) => dispatch(submitQuery(data, id, 'delete')),
        runPredefinedQuery: (data, id) => dispatch(runPredefinedQuery(data, id)),
        getPredefinedQuery: (id) => dispatch(getPredefinedQuery(id)),
        getExcelPredefinedFileDownlaod: (data, id,type) => dispatch(getExcelPredefinedFileDownlaod(data, id,type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedQuery);