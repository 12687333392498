import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Button, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { getQueryList, runDynamicQuery, getDynamicQuery, deleteDynamicQuery, cloneDynamicQuery } from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
}));
const DynamicQuery = props => {
    useEffect(() => {
        props.getQueryList(1, 10);
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([10, 20, 30]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getQueryList(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getQueryList(1, event.target.value);
    };
    function runQuery(item) {
        localStorage.removeItem('dyamicQueryDownloadData')
        var data = {
            id: item.ID.toString()
        };
        if (item.filter_fields.length > 0) {
            var object = { id: item.ID, fields: item.filter_fields, app_type: item.app_type, source_type: item.data_source_type }
            localStorage.setItem('dynamic_filter_fields', JSON.stringify(object));
            history.push({ pathname: '/partner-statement/partner-statement-form', filterValues: item.filter_fields });
        } else {
            props.runDynamicQuery(data, item.ID);
        }
    }
    function editQuery(item) {
        props.getDynamicQuery(item.ID);
        history.push('/partner-statement/edit-partner-statement/' + item.ID);
    }
    function viewQuery(item) {
        props.getDynamicQuery(item.ID);
        history.push('/partner-statement/view-partner-statement/' + item.ID);
    }
    function deleteQuery(item) {
        props.deleteDynamicQuery(item.ID);
    }
    function cloneQuery(item) {
        props.cloneDynamicQuery(item.ID);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2"> Partner Statement </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/partner-statement/add-partner-statement')}
                >
                    {isDesktop ? 'Add Partner Statement' : ''}
                </Button>
            </div>
            {props.queryListData && props.queryListData.records && props.queryListData.records.length > 0 ?
                <TableContainer style={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'>Query ID</TableCell>
                                <TableCell align='center'>Data Source</TableCell>
                                <TableCell align='center'>Query Name</TableCell>
                                <TableCell align='center'>Query Description</TableCell>
                                <TableCell align='center'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.queryListData.records
                                .filter(item => item.query_type_id === 3)
                                .sort(function (a, b) {
                                    if (a.table_name.toLowerCase() < b.table_name.toLowerCase()) return -1;
                                    if (a.table_name.toLowerCase() > b.table_name.toLowerCase()) return 1;
                                    return a.ID > b.ID ? 1 : -1;
                                })
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' style={{ cursor: 'pointer' }}>{item.ID}</TableCell>
                                            <TableCell align='center' style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.data_source}</TableCell>
                                            <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            <TableCell align='center' style={{ cursor: 'pointer' }}>{item.query_description}</TableCell>
                                            <TableCell align='center'>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div>
                                                        <Tooltip title='View'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => viewQuery(item)}
                                                                size="large">
                                                                <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title='Edit'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editQuery(item)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title='Clone'>
                                                            <IconButton
                                                                onClick={() => cloneQuery(item)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <img src={require('../../library/images/Copy.png')} alt='' />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title='Delete'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => deleteQuery(item)}
                                                                size="large">
                                                                <DeleteIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                                                        <Tooltip title='Run'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runQuery(item)}
                                                                style={{ marginLeft: 5 }}
                                                                size="large">
                                                                <DirectionsRunIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={props.queryListData && props.queryListData.total_record ? props.queryListData.total_record : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    />
                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.operationalReportsData.queryListSuccessData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getQueryList: (pagination, limit) => dispatch(getQueryList(pagination, limit, 3)),
        runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id, null, 'paymentpartner')),
        getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
        deleteDynamicQuery: (id) => dispatch(deleteDynamicQuery(id)),
        cloneDynamicQuery: (id) => dispatch(cloneDynamicQuery(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQuery);