import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  SplineSeries,
  DateTime,
} from "@syncfusion/ej2-react-charts";

const SplineChart = (props) => {
  // console.log("Props received:", props)
  const primaryxAxis = {
    title: "Months - " + new Date().getFullYear().toString(),
    valueType: "DateTime",
    intervalType: "Months",
    interval: 1,
    // minimum: props.min,
    // maximum: props.max,
    minimum: props.min !== undefined ? props.min : null,
    maximum: props.max !== undefined ? props.max : null,
    labelFormat: "MMM",
    majorGridLines: { width: 1 },
    majorTickLines: { width: 0 },
  };
  const primaryyAxis = {
    title: "Total ",
    labelFormat: "n",
    majorGridLines: { width: 1 },
    //minimum: 0,
  };
  const palette = ["#0000FF", "#CA4218", "#1cfcf1", "#fff836", "#58FF33"];
  const animation = { enable: true, duration: 1200, delay: 100 };
  return (
    <div>
      {props.data && props.data.length > 0 ? (
        <ChartComponent
          id={props.id}
          primaryXAxis={primaryxAxis}
          primaryYAxis={primaryyAxis}
          useGroupingSeparator={true}
          //title="Accrual v/s Payment"
          palettes={palette}
          animation={animation}
          tooltip={{ enable: true }}
        >
          <Inject
            services={[
              DateTime,
              SplineSeries,
              Legend,
              Tooltip,
              DataLabel,
              Category,
            ]}
          />
          <SeriesCollectionDirective>
            {props.data &&
              props.data.length > 0 &&
              props.data.map((item, index) => (
                <SeriesDirective
                  dataSource={item.data}
                  xName={item.xName}
                  yName={item.yName}
                  width={2}
                  name={item.legend}
                  type={item.type ? item.type : "Spline"}
                  marker={{
                    visible: true,
                    width: 10,
                    height: 10,
                    shape: item.shape ? item.shape : "Diamond",
                    isFilled: true,
                    fill: item.color ? item.color : "#0000FF",
                  }}
                ></SeriesDirective>
              ))}
            {/* <SeriesDirective
          dataSource={props.splineData2}
          xName="Date"
          yName="Total"
          width={2}
          name="Payment"
          type="Spline"
          marker={{
            visible: true,
            width: 10,
            height: 10,
            shape: "Diamond",
            isFilled: true,
            fill: "#CA4218",
          }}
        ></SeriesDirective> */}
          </SeriesCollectionDirective>
        </ChartComponent>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
            fontSize: 13,
            color: "grey",
          }}
        >
          No Data to Display !
        </div>
      )}
    </div>
  );
};
export default SplineChart;
