import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField,
    OutlinedInput, Grid, Button, FormLabel, MenuItem, Select
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ArrowLeft, AddCommentOutlined, Square, Circle, Search } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getBudgetingConfig } from '../../../../../../redux/actions';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // border: "1px solid #EEEEEE",

        // padding: '0px 16px',
        //paddingBottom: 16,
        borderBottom: '1px solid #E2E2E2',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 15,
        //backgroundColor: '#E8E8E8'
    },
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    gridContainer: {
        padding: '15px 16px 0px 16px',
        justifyContent: 'flex-start'
        // display: 'flex'
    },
    subContainer: {
        padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stickerLabel: {
        textTransform: 'capitalize',
    },
    header: {
        fontSize: theme.typography.h7.fontSize,
        marginRight: 15,
        color: theme.palette.text.grey,
        paddingLeft: 0,
        whiteSpace: 'wrap'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    contentSection: {
        marginRight: -20
    },
    navStatusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '25%'
    },
    searchBox: {
        backgroundColor: theme.palette.white
    },
    toggleButton: {
        //marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        // height: 34,
        //width: 61,
        //display: 'flex',
        alignItems: 'center',
    },
}));
const Header = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([{ option: '' }]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [enableDesc, setEnableDesc] = React.useState(false);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('pending');
    const [scenario, setScenario] = React.useState('current');

    useEffect(() => {
        setStickerDesc(props.stickerName)
    }, [props.stickerName]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData)
        }
    }, [props.configData]);
    useEffect(() => {
        if (props.data) {
            setStickerDesc(props.data.sticker_description)
            setExternalDesc(props.data.external_description)
            setCountry(props.data.country)
            setChecked(props.data.country && props.data.country == 'CA' ? true : false)
            setProductLine(props.data.product_lines)
        }
    }, [props.data])
    useEffect(() => {
        var data = {
            "product_lines": productLine,
            "sticker_description": stickerDesc,
            "external_description": externalDesc,
            "country": country
        }
        props.onChange(data)
    }, [productLine, stickerDesc, country])

    function handleChange(value) {
        setProductLine(value)
    }

    function handleOnAdd() {
        setEnableDesc(true)
    }
    function handleCountry(event) {
        setChecked(event.target.checked)
        if (event.target.checked)
            setCountry('CA')
        else
            setCountry('USA')

    }

    function handleStatus(event) {
        setStatus(event.target.value);
    };
    function handleScenario(event) {
        setScenario(event.target.value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.row} >
                <div className={classes.navStatusContainer}>
                    <Button variant="outlined" startIcon={<ArrowLeft />} onClick={props.handleBack}>
                        Return
                    </Button>
                    <Typography style={{ fontSize: 13, marginTop: -5 }}>
                        <Select
                            value={status}
                            onChange={handleStatus}
                            displayEmpty
                            style={{ border: 'none' }}
                        >
                            <MenuItem value="progress">
                                <Circle style={{ height: 10, color: 'green' }}></Circle>
                                Approved</MenuItem>
                            <MenuItem value="pending">
                                <Circle style={{ height: 10, color: 'orange' }}></Circle>
                                Pending</MenuItem>
                            <MenuItem value="rejected">
                                <Circle style={{ height: 10, color: 'red' }}></Circle>
                                Rejected</MenuItem>
                        </Select>

                    </Typography>
                </div>
                <div >
                    <Stack direction="row" spacing={1} alignItems="center" style={{ height: 30 }}>
                        <Typography variant='h4' color='black'>USA</Typography>
                        <Switch sx={{ m: 1 }} checked={props.country == 'CA' ? true : false} className={classes.toggleButton} disabled />
                        <Typography variant='h4' color='black'>CANADA</Typography>
                    </Stack>
                </div>
            </div>

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionTypes: () => dispatch(getPromoTypes()),
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);