import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  OutlinedInput,
} from "@mui/material";
//import ChipInput from "material-ui-chip-input";
import { createMuiTheme } from "@mui/material";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DropdownArray, Dropdown, DropdownKeyValue, ChipInput } from "../../../components/Inputs";
import { ContractNumberAC } from "../../../components/Inputs";
import SimpleDialog from "../../../components/Dialog";
import { SearchDropdown } from "../../../components/Inputs";
import {
  runCalculationSimulation,
  getDefaultValues,
  getContractsTableDataList,
  addBatchJobSetup, getLblDispDesFieldValue
} from "../../../redux/actions";
import { connect } from "react-redux";


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader, RotateLoader } from "react-spinners";
import { history } from "../../../components/Helpers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "10px 0px 0px 0px",
    marginTop: 5,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    padding: "0px 10px 0px 10px",
    marginTop: 0,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 28,
    paddingBottom: 30,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: -12,
    height: 36,
    padding: 0,
    marginBottom: 14,
    width: '100%'
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
  contractContiner: {
    padding: "15px 0px 18px",
  },
  spinner: {
    // height: "100vh",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
  dropdownContainer: {
    fontSize: theme.typography.h3.fontSize,
    marginBottom: 3
  },
  paper: { minWidth: window.screen.width * 0.5 },
  inputTwoLine: {
    marginTop: 10,
    width: "100%",
    paddingLeft: 10,
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
    padding: "1rem",
  },
}));
const CalculationSimulationMaintenance = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { className } = props;
  const contractCustomisationUpdation = useRef();
  const [contractTypeArray, setContractTypeArray] = React.useState([]);
  const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
  const [calculationMethod, setCalculationMethod] = React.useState("");
  const [calculationPeriodStart, setCalculationPeriodStart] = useState(null);
  const [calculationPeriodEnd, setCalculationPeriodEnd] = useState(null);


  useEffect(() => {
    var data = {
      "data_source": "contract_header",
      "application_type": sessionStorage.getItem('application')
    }
    props.fieldDesigner(data, 'post');
    //props.onLoadingDefault(sessionStorage.getItem('application'));
  }, []);
  useEffect(() => {
    setCalculationPeriodStart(
      Moment.utc().subtract(1, "months").startOf("month")
    );
    setCalculationPeriodEnd(Moment.utc().subtract(1, "months").endOf("month"));
  }, []);



  // useEffect(() => {
  //   if (!contractCustomisationUpdation.current) {
  //     {
  //       if (
  //         props.dropdownData &&
  //         props.dropdownData.records &&
  //         props.dropdownData.records.length > 0
  //       ) {
  //         props.dropdownData.records.map((item) => {
  //           if (item.field_id === "contract_type") {
  //             setContractTypeArray(item);
  //           }
  //           if (item.field_id === "calculation_method") {
  //             setCalculationMethodArray(item);
  //           }
  //         });
  //       }
  //     }
  //   }
  // });
  useEffect(() => {
    if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {

      setContractTypeArray(props.dropdownData.field_label_attributes.contract_type)
      setCalculationMethodArray(props.dropdownData.field_label_attributes.calculation_method);
    }
  }, [props.dropdownData && props.dropdownData.field_label_attributes])
  const [contractNumber, setContractNumber] = useState([]);
  const [contractType, setContractType] = useState("");
  const [contractSubtype, setContractSubtype] = useState("");
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [startValid, setStartValid] = useState(true);
  const [endValid, setEndValid] = useState(true);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const typeRef = useRef(null);
  const subTypeRef = useRef(null);
  const [batchJobName, setBatchJobName] = React.useState("");
  const [open2, setOpen2] = useState(false);
  const [masterValue, setMasterValue] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));

  var sd = calculationPeriodStart
    ? Moment.utc(calculationPeriodStart)
      .toISOString()
      ?.split("T")[0]
      .concat("T00:00:00.000Z")
    : "";
  var ed = calculationPeriodEnd
    ? Moment.utc(calculationPeriodEnd)
      .toISOString()
      ?.split("T")[0]
      .concat("T23:59:59.000Z")
    : "";
  const formData = {
    application_type: applicationType,
    contract_number: contractNumber,
    contract_type: contractType,
    calculation_method: calculationMethod,
    calculation_period_start: sd,
    calculation_period_end: ed,
    tags: tags,
  };
  const format2 = {
    app_type: applicationType,
    batch_job_type: "Calculation Simulation",
    batch_job_name: batchJobName,
    planned_date_type: "static",
    planned_start_date: Moment.utc(new Date())
      .add(1, "minute")
      .local()
      .format("YYYY-MM-DDTHH:mm:ssZ")
      .toString(),
    planned_start_calendar: "",
    planned_start_time: Moment.utc(new Date())
      .add(1, "minute")
      .local()
      .format("HH:mm:00")
      .toString(),
    frequency: "One Time",
    batch_filter_criteria: [
      {
        filters: {
          ...formData,
          calculation_date_type: "static",
          calculation_start_date: sd,
          calculation_end_date: ed,
        },
      },
    ],
  };

  const handleDialog = (bool) => {
    if (bool) {
      setOpen(false);
      formData['contract_number'] = contractNumber.map(Number);
      var temp = [];

      // if (applicationType === 'Customer Rebate' && masterValue.length > 0) {
      //   masterValue.map(item => {
      //     temp.push({
      //       "attribute_name": "customer_number",
      //       "attribute_value": item,
      //       "attribute_type": "Attribute"
      //     });
      //   });
      // } else if (applicationType === 'Supplier Rebate' && masterValue.length > 0) {
      //   masterValue.map(item => {
      //     temp.push({
      //       "attribute_name": "supplier_number",
      //       "attribute_value": item,
      //       "attribute_type": "Attribute"
      //     });
      //   });
      // }

      if (masterValue && masterValue.length > 0 && (applicationType === 'Customer Rebate' || applicationType === 'Supplier Rebate')) {
        temp = [
          {
            "attribute_name": applicationType === 'Customer Rebate' ? "customer_number" : "supplier_number",
            "attribute_value": masterValue,
            "attribute_type": "Attribute"
          }
        ]
        formData['eligibility_rules'] = [
          {
            "option": "EQUAL",
            "effective_start_date": sd,
            "effective_end_date": ed,
            "enabled": true,
            "type": "Both",
            "sequence": 0,
            "attribute_key_values": temp
          }
        ];
      }

      props.onSubmit(formData);
      // handleClear();
    } else {
      setOpen(false);
    }
  };
  const handleClear = () => {
    startRef.current.reset();
    endRef.current.reset();
    typeRef.current.reset();
    subTypeRef.current.reset();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  function handleCalculationPeriodStart(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodStart(newValue);
      setStartValid(true);
    } else {
      setCalculationPeriodStart(newValue);
      if (!newValue) setCalculationPeriodEnd(null);

      setStartValid(false);
    }
  }
  function handleCalculationPeriodEnd(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodEnd(newValue);
      setEndValid(true);
    } else {
      setCalculationPeriodEnd(newValue);
      setEndValid(false);
    }
  }

  function handleContractNumberAC(value) {
    if (value.length > 0) {
      setContractNumber(value);
    } else {
      setContractNumber([]);
    }
  }
  function handleTags(newValue) {
    // if (fn === "add") {
    //   setTags([...tags, newValue]);
    // } else {
    //   var delTags = tags.filter((item) => item !== newValue);
    //   setTags(delTags);
    // }
    setTags(newValue)
  }
  function handleContractType(newValue) {
    setContractType(newValue);
  }
  function handleCalculationMethod(newValue) {
    setCalculationMethod(newValue);
  }

  const handlePaste = (event) => {
    event.preventDefault();
  };
  const handleBatchJobName = (e) => {
    setBatchJobName(e.target.value);
  };
  function handleBatchOnSubmit() {
    setOpen2(false);
    setBatchJobName("");
    delete format2.batch_filter_criteria[0].filters["calculation_period_start"];
    delete format2.batch_filter_criteria[0].filters["calculation_period_end"];
    props.onSubmitBatchJob(format2);
  }
  function handleCancelDialog() {
    setOpen2(false);
    setBatchJobName("");
    history.push("/calculation-simulation");
  }

  function handleMasterdata(value) {
    setMasterValue(value)
  }
  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="h1" color="primary" style={{ marginLeft: 16 }}>
            {sessionStorage.getItem("application") &&
              sessionStorage.getItem("application") == "Supplier Chargeback"
              ? "Accrual Simulation "
              : "Calculation Simulation"}
          </Typography>
        </div>
        <form autoComplete="off" noValidate>
          <div className={classes.bodyContainer}>
            <Grid container>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <Dropdown
                  heading={"Application Type"}
                  twoline="true"
                  {...props}
                  disabled={true}
                  classes={{ root: classes.dropdownContainer }}
                  placeholder={sessionStorage.getItem("application")}
                  style={{ paddingTop: "0.1rem", paddingBottom: 5 }}
                />

              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ marginTop: 15 }}
              >
                <FormLabel required={contractNumber.length > 0 ? false : true} classes={{ root: classes.fontSetting }}>
                  {
                    calculationMethodArray && calculationMethodArray.current
                      ? calculationMethodArray.current
                      : "Calculation Method"
                  }
                </FormLabel>
                <div style={{ paddingBottom: 5, marginTop: -15 }}>
                  <DropdownKeyValue
                    classes={{ root: classes.fontSetting }}
                    data={
                      calculationMethodArray &&
                        calculationMethodArray.drop_down_reference_value
                        ? calculationMethodArray.drop_down_reference_value
                        : []
                    }
                    twoline="true"
                    onChange={handleCalculationMethod}
                    error={
                      typeof calculationMethod === "boolean" &&
                        !calculationMethod
                        ? true
                        : false
                    }
                    ref={subTypeRef}
                    labelFormat={'desc'}
                  />
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              //style={{ marginTop: -15 }}
              >
                <FormLabel required={contractNumber.length > 0 ? false : true} classes={{ root: classes.fontSetting }}>
                  {
                    contractTypeArray && contractTypeArray.current
                      ? contractTypeArray.current
                      : "Contract Type"
                  }
                </FormLabel>
                <div style={{ paddingBottom: 5, marginTop: -15 }}>
                  <DropdownArray
                    classes={{ root: classes.fontSetting }}

                    data={
                      contractTypeArray && contractTypeArray.drop_down_values
                        ? contractTypeArray.drop_down_values
                        : []
                    }
                    placeholder={
                      contractTypeArray && contractTypeArray.drop_down_values &&
                      contractTypeArray.drop_down_values.length == 0 &&
                      "- Please configure contract type -"
                    }
                    twoline="true"
                    onChange={handleContractType}
                    error={
                      typeof contractType === "boolean" && !contractType
                        ? true
                        : false
                    }
                    ref={typeRef}

                  />
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              //style={{ marginTop: -25 }} // Adjust the height here
              >
                <FormLabel required={calculationMethod || contractType ? false : true} classes={{ root: classes.fontSetting }}>
                  Contract Number
                </FormLabel>
                <div style={{ marginTop: -5, paddingBottom: 5 }}>
                  <SearchDropdown id='contract_number_multiple'
                    multiple
                    table='contract_header' keyName='contract_number'
                    description={true}
                    onChange={handleContractNumberAC}
                    applicationType={applicationType}
                  />
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation Start Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: '1px 10px 1px 11px',
                          alignSelf: "center",
                          alignItems: "center",
                          border: "1px solid #E0E0E0",
                          width: "100%",
                          marginBottom: 20
                        },
                      }}
                      value={calculationPeriodStart}
                      onChange={handleCalculationPeriodStart}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation End Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          // padding: '1px 10px 1px 11px',
                          alignSelf: "center",
                          alignItems: "center",
                          border: "1px solid #E0E0E0",
                          width: "100%",
                          marginBottom: 20
                        },
                      }}
                      value={calculationPeriodEnd}
                      onChange={handleCalculationPeriodEnd}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      minDate={
                        calculationPeriodStart ? calculationPeriodStart : ""
                      }
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              {applicationType == 'Customer Rebate' &&
                <Grid
                  item
                  md={6}
                  xs={12}
                  classes={{ root: classes.gridContainer }}
                >
                  <FormLabel classes={{ root: classes.fontSetting }}>
                    Customer Number
                  </FormLabel>
                  <div style={{ marginTop: -5 }}>
                    <SearchDropdown id='customer_multiple'
                      table='customer_master' keyName='customer_number'
                      description={true}
                      multiple
                      onChange={handleMasterdata}
                    />
                  </div>
                </Grid>
              }
              {applicationType == 'Supplier Rebate' &&
                <Grid
                  item
                  md={6}
                  xs={12}
                  classes={{ root: classes.gridContainer }}
                >
                  <FormLabel classes={{ root: classes.fontSetting }}>
                    Supplier Number
                  </FormLabel>
                  <div style={{ pmarginTop: -5 }}>
                    <SearchDropdown id='customer_multiple'
                      table='supplier_master' keyName='supplier_number'
                      description={true}
                      multiple
                      onChange={handleMasterdata}
                    />
                  </div>
                </Grid>
              }
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel classes={{ root: classes.fontSetting }}>
                  Tags
                </FormLabel>
                {/* <ChipInput
                  classes={{
                      root: classes.rootContainer,
                    chip: classes.chip,
                    input: classes.input,
                      inputRoot: classes.inputRoot,
                      chipContainer: classes.chipContainer,
                      label: classes.chipLabel,
                  }}
                  onPaste={handlePaste}
                  value={tags}
                  onAdd={(chips) => handleTags(chips, "add")}
                  onDelete={(chips) => handleTags(chips, "delete")}
                  disableUnderline={true}
                  blurBehavior="add"
                /> */}
                <ChipInput data={tags} onChange={handleTags} />
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                twoline="true"
                onClick={handleClear}
              >
                Clear
              </Button>
              <Button
                disabled={
                  (contractType && startValid && endValid ? false : true) &&
                  (calculationMethod && startValid && endValid ? false : true) &&
                  (contractNumber.length > 0 && startValid && endValid
                    ? false
                    : true)
                }
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickOpen}
                twoline="true"
              >
                {props.loading ? (
                  <RotateLoader
                    color={"rgb(54, 215, 183)"}
                    loading={props.loading}
                    size={2}
                  />
                ) : (
                  "Run Online"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickOpen2}
                twoline="true"
                style={{ width: 170 }}
                disabled={
                  (contractType && startValid && endValid ? false : true) &&
                  (calculationMethod && startValid && endValid ? false : true) &&
                  (contractNumber.length > 0 && startValid && endValid
                    ? false
                    : true)
                }
              >
                {props.loading ? (
                  <RotateLoader
                    color={"rgb(54, 215, 183)"}
                    loading={props.loading}
                    size={2}
                  />
                ) : (
                  "Schedule Batch Job"
                )}
              </Button>
            </div>
            <SimpleDialog
              open={open}
              content="Do you want to simulate the calculation results?"
              handleDialog={handleDialog}
            />
            <Dialog
              onClose={() => setOpen2(false)}
              aria-labelledby="simple-dialog-title"
              open={open2}
              disableEscapeKeyDown
              classes={{ paper: classes.paper }}>
              <div style={{ padding: 20 }}>
                <FormLabel className={classes.formLabel} required>
                  Batch Job Name
                </FormLabel>
                <OutlinedInput
                  value={batchJobName}
                  classes={{ root: classes.inputTwoLine }}
                  onChange={handleBatchJobName}
                />
              </div>
              <DialogActions>
                <Button color="primary" onClick={handleCancelDialog}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={handleBatchOnSubmit}
                  disabled={batchJobName ? false : true}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          </div>

        </form>

      </div>

    </LoadingOverlay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => dispatch(runCalculationSimulation(formData)),
    onSubmitBatchJob: (data) => dispatch(addBatchJobSetup(data)),
    onLoadingDefault: (app) => dispatch(getDefaultValues(app)),
    onCheckContractsTable: (app) => dispatch(getContractsTableDataList(null, "Designer Studio", app)),
    fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
    contractTabledataComplete: state.addContractData.contractAllListData,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculationSimulationMaintenance);
