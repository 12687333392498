import React, { useEffect } from 'react';
import {
    Card, CardHeader, Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import Moment from 'moment';
import { connect } from 'react-redux';
import { getDefaultValuesAllDataNew } from '../../../../../redux/actions'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const Payment = props => {

    const classes = useStyles();
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }
    useEffect(() => {
        if (props.contractData && props.contractData.source_data_type) {
            var appType = sessionStorage.getItem('application');
            // if (appType == 'Customer Rebate')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
            // else if (appType == 'Supplier Rebate')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
            // else if (appType == 'Royalty')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
            // else if (appType == 'Sales Commissions')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
            // else if (appType == 'Customer Chargeback')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
            // else if (appType == 'Supplier Chargeback')
            //     props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.contractData.source_data_type)
        }
    }, [])

    return (
        <div className={classes.root}>
            <Card>
                <CardHeader
                    title="PAYMENT"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {props.data && props.fieldData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {props.data && props.data.payment_level && props.fieldData.payment_level &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_level.current} data={props.data.payment_level} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_level && !(props.data.payment_level === 'Single Payment') && props.fieldData.payment_aggregation_level &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_aggregation_level.current} data={props.data.payment_aggregation_level} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_partner_role && (props.data.payment_level === 'Single Payment') && props.fieldData.payment_partner_role &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_partner_role.current} data={props.data.payment_partner_role} twoline='true' />
                                </Grid>
                            }

                            {props.data && props.data.payment_partner && (props.data.payment_level === 'Single Payment') && props.fieldData.payment_partner &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_partner.current} data={props.data.payment_partner} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_frequency && props.fieldData.payment_frequency &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_frequency.current} data={props.data.payment_frequency} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_calendar && props.fieldData.payment_calendar &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_calendar.current} data={props.data.payment_calendar} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_method && props.fieldData.payment_method &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_method.current} data={props.data.payment_method} twoline='true' />
                                </Grid>
                            }


                            {props.data && props.data.payment_terms && props.fieldData.payment_terms &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_terms.current} data={props.data.payment_terms} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_due_date && props.fieldData.payment_due_date &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_due_date.current} data={props.data.payment_due_date} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.external_reference && props.fieldData.external_reference &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_reference.current} data={props.data.external_reference} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.flex_fields_5 && props.fieldData.flex_fields_5 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_5.current} data={props.data.flex_fields_5} twoline='true' />
                                </Grid>
                            }
                            {props.data.pass_through && props.fieldData.pass_through &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through.current} data={props.data.pass_through} twoline='true' />
                                </Grid>
                            }
                            {props.data.pass_through_percentage && props.data.pass_through_percentage !== 0 &&
                                props.fieldData.pass_through_percentage ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through_percentage.current} data={props.data.pass_through_percentage} twoline='true' />
                                </Grid>
                                : ''
                            }
                            {props.data.pass_through_payment_partner_role && props.fieldData.pass_through_payment_partner_role &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through_payment_partner_role.current} data={props.data.pass_through_payment_partner_role} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_document_type && props.fieldData.payment_document_type &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_document_type.current} data={props.data.payment_document_type} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_run && props.fieldData.payment_run &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_run.current} data={props.data.payment_run} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );

}

const mapDispatchToProps = dispatch => {
    return {
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}

const mapStateToProps = state => {
    return {
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);