import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    IconButton,
    FormLabel,
    Select,
    MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { getBatchJobDetail, addIntegrationBatchJobSetup, getIntegrationSetupData, getFilterNameList } from '../../../redux/actions';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { LabelText } from '../../../components/Inputs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DropdownArray, TextInputWithLabel } from '../../../components/Inputs';
import { useHistory } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import Moment from 'moment';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        //alignItems: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
}));
const EditIntegrationBatchJobSetup = props => {
    const classes = useStyles();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState([]);
    const [integrationName, setIntegrationName] = React.useState('');
    const [integration, setIntegration] = React.useState([]);
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [batchSetupID, setBatchSetupID] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [currentID, setCurrentID] = React.useState('');
    const [integrationID, setIntegrationID] = React.useState('');
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterValue2, setFiltervalue2] = React.useState([]);
    const [filterName, setFilterName] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [activityArray, setActivityArray] = React.useState([]);
    const [dateData, setDateData] = React.useState([]);
    useEffect(() => {
        // props.getIntegrationSetupData(1, 0);
    }, [])
    console.log(props.batchJobDetail)
    useEffect(() => {
        if (props.batchJobDetail && props.batchJobDetail.app_type) {
            setBatchSetupID(props.batchJobDetail.ID)
            setCurrentID(props.batchJobDetail.ID);
            setBatchJobType(props.batchJobDetail.batch_job_type);
            setBatchJobName(props.batchJobDetail.batch_job_name);
            setPlannedStartDate(props.batchJobDetail.planned_start_date);
            setFrequency(props.batchJobDetail.frequency);
            setPlannedStartTime(props.batchJobDetail.planned_start_time)
            setPlannedDateType(props.batchJobDetail.planned_date_type)
            if (props.batchJobDetail.batch_filter_criteria) {
                props.batchJobDetail.batch_filter_criteria.map((item, index) => {
                    setIntegrationName(item.name)
                    setIntegrationID(item.integration_id)
                    if (item.integration_filters !== null) {
                        setFilterName(item.integration_filters)
                    }
                    if (item.integration_filter_values === null) {
                        setDateData([])
                    }
                    else {
                        setDateData(item.integration_filter_values)
                    }
                })
            }
        }
    }, [props.batchJobDetail])
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getBatchJobDetail(appId);
        props.getIntegrationSetupData(1, 0);

    }, []);
    useEffect(() => {
        if (props.filterNameList) {
            setFilterNameArray(props.filterNameList);
        }
    }, [props.filterNameList]);
    useEffect(() => {
        var newArray = []
        var newActivityArray = []
        if (props.integrationSetupData && props.integrationSetupData.records && props.integrationSetupData.records.length > 0) {
            props.integrationSetupData.records.map((item, index) => {
                newArray.push(item.name)
                newActivityArray.push(item.ID + '(' + item.activity)
            })
            setIntegration(newArray)
            setActivityArray(newActivityArray)
        }
    }, [props.integrationSetupData])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    const frequencyTypesArray = [
        'Hourly', 'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleContinue() {
        var newValue = Math.max(addMember, filterName && filterName.length);
        setAddMember(newValue);
        var data
        activityArray.map(item => {
            data = item.slice(0, 2)
            if (parseFloat(data) == integrationID) {
                props.getFilterNameList(item.split('(').pop(), 'date');
            }
        })
        setOpen(false);
        if (dateData && dateData.length > 0) {
            dateData.map((item, index) => {
                let [value1, value2] = item.split('To')
                filterValue.push(value1)
                filterValue2.push(value2)
            })
        }
    }
    function handleFrequency(value) {
        setFrequency(value)
        setPlannedDateType('');
    }
    function handlePlannedDateType(value) {
        setPlannedDateType(value)
        setPlannedStartDate(null)
    }
    function handleClear() {
        setAddMember(1);
    }
    function handleOnSubmit() {
        var date = [];
        filterValue.map((item, index) => {
            date.push(filterValue[index].concat("To", filterValue2[index]))
        })
        var data = {
            "app_type": "Integration Studio",
            "batch_job_type": "Automatic Integration",
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ'),
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,
            "id": batchSetupID,
            "batch_filter_criteria": [{
                "integration_id": integrationID,
                "integration_filters": filterName,
                "integration_filter_values": date,
            }]
        }
        props.onSubmit(data, 'edit', data.id);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value.concat(":00"))
    }
    function handleBatchJobType(newValue) {
        setBatchJobType(newValue)
    }
    function handleFilterName(newValue, index) {
        filterName[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterValue[index] = newValue;
    }
    function handleFilterValue2(newValue, index) {
        filterValue2[index] = newValue;
    }
    function handleOnAdd() {
        filterName[addMember] = ''
        filterValue[addMember] = ''
        filterValue2[addMember] = ''
        setAddMember(addMember + 1)
    }
    const handleDeleteLocally = (e, index) => {
        setFilterName(item => item.filter((item, i) => i !== index));
        setFiltervalue(item => item.filter((item, i) => i != index));
        setFiltervalue2(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/integration-batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                {batchJobName &&
                                    <Grid
                                        item
                                        md={10}
                                        xs={12}
                                    >  <LabelText heading='Integration Name' data={batchJobName} twoline='true' />
                                    </Grid>
                                }
                                {(() => {
                                    let td = [];
                                    for (let i = 0; i < addMember; i++) {
                                        td.push(<>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >  <DropdownArray heading={i == 0 && 'Filter Name'}
                                                placeholder={'Please select'}
                                                twoline='true'
                                                onChange={(e) => handleFilterName(e, i)}
                                                data={filterNameArray}
                                                prevalue={filterName && filterName[i] ? filterName[i] : ''} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >  <TextInputWithLabel
                                                    heading={i == 0 && 'Start Date and Time'}
                                                    twoline='true'
                                                    onChange={(e) => handleFilterValue(e, i)}
                                                    data={filterValue}
                                                    prevalue={filterValue && filterValue[i] ? filterValue[i] : ''}
                                                    type="datetime-local"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                <TextInputWithLabel
                                                    heading={i == 0 && 'End Date and Time'}
                                                    twoline='true'
                                                    onChange={(e) => handleFilterValue2(e, i)}
                                                    data={filterValue2}
                                                    prevalue={filterValue && filterValue2[i] ? filterValue2[i] : ''}
                                                    type="datetime-local"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                                style={{ paddingTop: 20, marginTop: 20 }}
                                            >
                                                <IconButton
                                                    style={{ marginTop: i == 0 ? 32 : 20 }}
                                                    onClick={(e) => handleDeleteLocally(e, i)}
                                                    classes={{ root: classes.IconButton }}
                                                    size="large">
                                                    <ClearIcon />
                                                </IconButton>
                                                <IconButton
                                                    style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                    onClick={handleOnAdd}
                                                    classes={{ root: classes.IconButton }}
                                                    size="large">
                                                    <AddBoxOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                        </>)
                                    }
                                    return td;
                                })()}
                            </Grid>
                        </div>
                    </form>
                </Card>
                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <Select
                                                onChange={(e) => setBatchJobName(e.target.value)}
                                                value={batchJobName}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >    <MenuItem value="">
                                                    {props.placeholder}
                                                </MenuItem>
                                                {integration.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} name={item}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >   <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => handleFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >   {frequencyTypesArray
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >  <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => handlePlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency ? true : false}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >  <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            {/* <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid> */}
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 4px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 9,
                                                            height: 38,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >   {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                .filter(item => item != 'Manual')
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >   <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>
                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>
                                            <TextField
                                                id="time"
                                                type="time"
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                // className={classes.textField}
                                                style={{ paddingLeft: 0, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/integration-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={batchJobName && plannedStartTime && frequency && plannedStartDate ? false
                                : true}
                        > Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        batchJobDetail: state.customerData.batchJobDetail,
        integrationSetupData: state.customerData.integrationSetupData,
        filterNameList: state.customerData.filterNameList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addIntegrationBatchJobSetup(data, type, id)),
        getBatchJobDetail: (id) => dispatch(getBatchJobDetail(id)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getFilterNameList: (activity, date) => dispatch(getFilterNameList(activity, date)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditIntegrationBatchJobSetup);