import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Button,
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    TextField,
    ListItemText,
    Checkbox,
    OutlinedInput,
    Chip
} from '@mui/material';
import SubmitContainer from '../../components/Containers/SubmitContainer';
import { getWorkFlowAnchors, addApprovalDesigner, getDefaultValues, getAllOrganizationUsers, getApprovalGroupData, getWorkflowRequests, getContractsTableDataList, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import RingLoader from 'react-spinners/RingLoader';
import { SearchDropdown } from "../../components/Inputs";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    container: {
        paddingTop: 35
    },
    leftGridContainer: {
        paddingRight: 10
    },
    gridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 10px 10px 20px',
        width: '100%'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    multiSelect: {
        //marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        // height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chip: {
        // height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    error: {
        border: '1px solid red !important'
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    dropdownContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'

    },

    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: '1rem'
    },
}));

const PostingFilterTab = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [postingType, setPostingType] = React.useState('');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [contractType, setContractType] = React.useState([]);
    const [contractNumber, setContractNumber] = React.useState([]);
    const [approvalGroup, setApprovalGroup] = React.useState([]);
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [thresholdType, setThresholdType] = React.useState([]);
    const [contractAnalyst, setContractAnalyst] = React.useState([]);
    const [contractManager, setContractManager] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [thresholdTypeArray, setThresholdTypeArray] = React.useState([]);
    const [orgUsersAarray, setOrgUsersArray] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [label, setLabel] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const isAllSelected =
        thresholdTypeArray.length > 0 && selected.length === thresholdTypeArray.length;
    const isContractManagerAll =
        orgUsersAarray.length > 0 && selected.length === orgUsersAarray.length;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        var pathname = window.location.pathname;
        var type = pathname.substring(pathname.lastIndexOf('/') + 1);
        setPostingType(type)
        // props.onLoadingDefault(applicationType);
        props.getUsers();
        props.getApprovalGroupData();
        props.getWorkFlowAnchors();
        props.onCheckContractsTable();
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
        handleClearAll()
    }, [window.location.pathname]);
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records && props.approvalGroupData.records.length > 0) {
            setApprovalGroupData(props.approvalGroupData)
        }
    }, [props.approvalGroupData]);
    useEffect(() => {
        if (props.orgUsers) {
            setOrgUsersArray(props.orgUsers)
            let newSelected = []
            props.orgUsers.map(item => {
                newSelected = newSelected.concat(item.username);
            })
            setUsersId(newSelected)
        }
    }, [props.orgUsers]);
    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);
        }
    }, [props.contractTabledataComplete])

    // useEffect(() => {
    //     if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
    //         props.dropdownData.records.map((item) => {
    //             if (item.field_id === 'contract_type') {
    //                 setContractTypeArray(item)
    //             }
    //         })
    //     }
    // }, [props.dropdownData])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            setContractTypeArray(props.dropdownData.field_label_attributes.contract_type)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    function handleContractNumberAC(value) {
        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected);
            setContractNumber(newSelected);
        } else {
            setSelected([]);
            setContractNumber([]);
        }
    }
    const handleContractType = (event) => {
        setContractType(event.target.value);
    }
    const handleApprovalGroup = (event) => {
        setApprovalGroup(event.target.value);
    }
    const handleContractNumber = (e, newValue) => {
        setContractNumber(newValue);
    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (label === 'thresholdtype')
                setSelected(selected.length === thresholdTypeArray.length ? [] : thresholdTypeArray);
            else
                setSelected(selected.length === orgUsersAarray.length ? [] : usersId);
            return;
        }
        setSelected(newSelected)
    }
    useEffect(() => {
        if (props.workflowAnchors) {
            props.workflowAnchors.filter(function (v) {
                if (postingType === 'payment-approvals' && v.functionality === 'payment') {
                    setThresholdTypeArray(v.incentive_threshold_type.filter(item => item != 'incentive_adjustment'))
                }
                if (postingType === 'accrual-approvals' && v.functionality === 'accrual') {
                    setThresholdTypeArray(v.incentive_threshold_type.filter(item => item != 'incentive_adjustment'))
                }
            })
        }
    }, [props.workflowAnchors])

    const onSubmitHandler = () => {
        var type = postingType.split('-')[0]
        if (type === 'accrual')
            type = 'Accrual'
        else
            type = 'Payment'
        var data = {
            "functionality": type,
            "application_type": applicationType.toString(),
            "contract_type": contractType.toString(),
            "contract_number": contractNumber ? parseInt(contractNumber) : '',
            "approval_group": parseInt(approvalGroup) ? parseInt(approvalGroup) : '',
            "start_date": calculationStartDate ? Moment(calculationStartDate, 'YYYY-MM-DD').local().toISOString().split('T')[0] : '',
            "end_date": calculationEndDate ? Moment(calculationEndDate, 'YYYY-MM-DD').local().toISOString().split('T')[0] : '',
            "incentive_threshold_type": thresholdType.toString(),
            "contract_analyst": contractAnalyst,
            "contract_manager": contractManager
        };
        props.onSubmit(type, data);
    }
    const handleClearAll = () => {
        setContractType([])
        setContractNumber('')
        setApprovalGroup([])
        setCalculationStartDate(null)
        setCalculationEndDate(null)
        setThresholdType([])
        setContractManager([])
        setContractAnalyst([])
    }
    function onAdd() {
        if (label === 'contractManager')
            setContractManager(selected)
        else if (label === 'contractAnalyst')
            setContractAnalyst(selected)
        else
            setThresholdType(selected)
        setOpen(false)
        setSelected([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])
    }
    function handleOpen(type) {
        if (type === 'contractManager')
            setSelected(contractManager)
        else if (type === 'contractAnalyst')
            setSelected(contractAnalyst)
        else
            setSelected(thresholdType)
        setLabel(type)
        setOpen(true)
    }
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>

                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ textTransform: 'capitalize', marginLeft: 16 }}>
                        {postingType.replace(/-/g, ' ')}
                    </Typography>
                    <div className={classes.bodyContainer}>
                        <Grid container spacing={2}>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{ paddingTop: 15, marginTop: -9, width: '100%' }}>
                                    <SearchDropdown id='contract_number_multiple'
                                        multiple
                                        table='contract_header' keyName='contract_number'
                                        description={true} heading='Contract Number'
                                        onChange={handleContractNumberAC}
                                        applicationType={sessionStorage.getItem("application")}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                                style={{ marginTop: 10 }}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Contract Type</FormLabel>
                                    <Select
                                        value={contractType}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        onChange={handleContractType}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={classes.multiSelect}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length > 0 &&
                                            contractTypeArray.drop_down_values
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}

                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Start Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationStartDate}
                                                onChange={(e) => setCalculationStartDate(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >End Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '0px 11px 0px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationEndDate}
                                                onChange={(e) => setCalculationEndDate(e)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={calculationStartDate ? calculationStartDate : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Contract Analyst</FormLabel>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={contractAnalyst}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        onOpen={() => handleOpen('contractAnalyst')}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'contractAnalyst'}
                                        renderValue={(contractAnalyst) => contractAnalyst.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isContractManagerAll} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {orgUsersAarray && orgUsersAarray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.username)} value={item.username} key={index}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item.username) > -1} />
                                                        <ListItemText primary={item.username} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Contract Manager</FormLabel>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={contractManager}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        onOpen={() => handleOpen('contractManager')}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'contractManager'}
                                        renderValue={(contractAnalyst) => contractAnalyst.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isContractManagerAll} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {orgUsersAarray && orgUsersAarray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.username)} value={item.username} key={index}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item.username) > -1} />
                                                        <ListItemText primary={item.username} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Approval Group</FormLabel>
                                    <Select
                                        id="appoval-mutiple-name"
                                        value={approvalGroup}
                                        input={<OutlinedInput sx={{ padding: '4px' }} />}
                                        onChange={handleApprovalGroup}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={classes.multiSelect}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {approvalGroupData && approvalGroupData.records
                                            && approvalGroupData.records.length > 0
                                            && approvalGroupData.records.map((item) => {
                                                return (
                                                    <MenuItem value={item.ID} key={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </div>
                            </Grid >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Threshold Type</FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={thresholdType}
                                        input={<OutlinedInput sx={{ padding: '4px' }} style={{ textTransform: 'capitalize' }} />}
                                        onOpen={() => handleOpen('thresholdtype')}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'thresholdtype'}
                                        renderValue={(thresholdType) => {
                                            let type = []
                                            thresholdType.map(item => {
                                                type = type.concat(item.replace(/_/g, ' '))
                                            })
                                            return type.join(',')
                                        }}
                                        className={classes.multiSelect}
                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {thresholdTypeArray && thresholdTypeArray.length > 0 &&
                                            thresholdTypeArray
                                                .filter(item => item != 'incentive_adjustment')
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} onClick={(event) => handleSelectValues(event, item)} style={{ textTransform: 'capitalize' }}>

                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected.indexOf(item) > -1} />
                                                            <ListItemText primary={item.replace(/_/g, ' ')} />
                                                        </MenuItem>
                                                    );
                                                })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </div>
                            </Grid >



                        </Grid >
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClearAll} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler} twoline='true'>
                                {props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                                }
                            </Button>
                        </div>
                    </div >

                </div >


            </div >
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (functionality, data) => dispatch(getWorkflowRequests(functionality, data)),
        // onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getUsers: () => dispatch(getAllOrganizationUsers()),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        getWorkFlowAnchors: () => dispatch(getWorkFlowAnchors()),
        onCheckContractsTable: () => dispatch(getContractsTableDataList()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        workflowAnchors: state.customerData.workflowAnchors,
        orgUsers: state.operationalReportsData.orgUsers,
        contractTabledataComplete: state.addContractData.contractAllListData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostingFilterTab);