import React, { useRef } from 'react';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker, TextInputWithLabel } from '../../../components/Inputs';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    Grid,
    Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { runContractReports } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '29px 29px 0px 29px',
        borderRadius: 10
    },
    headerRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 45px 0px 45px'
    },
    adminRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    submitContainer: {
        marginRight: -25,
        marginTop: 15
    },
    spinner: {
        height: '100vh'
    },
    fontSetting:{
        fontSize:theme.typography.h3.fontSize
    }
}));
const applicationTypeData = [
    { key: 'customer_rebate', value: 'Customer Rebate' },
    { key: 'supplier_rebate', value: 'Supplier Rebate' },
    { key: 'sales_commission', value: 'Sales Commission' },
    { key: 'royalty', value: 'Royalty' }
];
const contractTypeData = [
    { key: 'flat_rate', value: 'Flat Rate' },
    { key: 'fixed_amount', value: 'Fixed Amount' },
    { key: 'price_difference', value: 'Price Difference' },
    { key: 'tiered_growth', value: 'Tiered Growth' },
    { key: 'tiered_volume', value: 'Tiered Volume' },
    { key: 'quota_achievement', value: 'Quota Achievement' },
    { key: 'pricing_calculation', value: 'Price Calculation' },
    { key: 'multi_axis', value: 'Multi Axis' }
    
];
const contractGroupData = [
    { key: '0', value: 'Group 0' },
    { key: '1', value: 'Group 1' }
];
const currencyData = [
    { key: 'usd', value: 'USD' },
    { key: 'cad', value: 'CAD' },
    { key: 'gbp', value: 'GBP' },
    { key: 'inr', value: 'INR' },
    { key: 'eur', value: 'EUR' },
];
const contractSubtypeData = [
    { key: 'FFS', value: 'Fee for Service' },
    { key: 'COMPLIANCE', value: 'Compliance' },
    { key: 'MDF', value: 'Marketing Development Fund' },
    { key: 'CHNLPERF', value: 'Channel Performance' },
    { key: 'PP', value: 'Price Protection' }
];
const contractSubGroupData = [
    { key: '0', value: 'Subgroup 1' },
    { key: '1', value: 'Subgroup 2' }
];
const approvedStatusData = [
    { key: '0', value: 'INIT' },
    { key: '1', value: 'WAIT' },
    { key: '2', value: 'APPR' },
    { key: '3', value: 'REJ' }
];

const ContractsReportsTab = props => {
    const classes = useStyles();
    const headerFieldRef = useRef(null);
    const adminFieldRef = useRef(null);
    const { className } = props;
    const [applicationType, setApplicationType] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [contractGroup, setContractGroup] = React.useState('');
    const [internalDescription, setInternalDescription] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [internalReference, setInternalReference] = React.useState('');
    const [validFrom, setValidFrom] = React.useState('');
    const [contractSubtype, setContractSubtype] = React.useState('');
    const [contractSubgroup, setContractSubgroup] = React.useState('');
    const [externalDescription, setExternalDescription] = React.useState('');
    const [approvedStatus, setApprovedStatus] = React.useState('');
    const [externalReference, setExternalReference] = React.useState('');
    const [createdBy, setCreatedBy] = React.useState('');
    const [createdOn, setCreatedOn] = React.useState('');
    const [changedBy, setChangedBy] = React.useState('');
    const [changedOn, setChangedOn] = React.useState('');
    const [validTo, setValidTo] = React.useState('');
    var formData = {
        "application_type": sessionStorage.getItem('application'),
        "calculation_schema": contractType,
        "contract_type": contractSubtype,
        "contract_group": contractGroup,
        "contract_sub_group": contractSubgroup,
        "internal_description": internalDescription,
        "external_description": externalDescription,
        "currency": currency,
        "approved_status": approvedStatus,
        "internal_reference": internalReference,
        "external_reference": externalReference,
        "valid_from": validFrom,
        "valid_to": validTo,
        "created_by": createdBy,
        "changed_by": changedBy,
        "CreatedAt": createdOn,
        "UpdatedAt": changedOn
    }
    const handleSubmit = () => {
        localStorage.setItem("ContractReportsData", JSON.stringify(formData));
        props.onSubmit(0, 10);
    }
    const handleClear = () => {
        headerFieldRef.current.reset();
        adminFieldRef.current.reset();
        setApplicationType('');
        setContractType('');
        setContractGroup('');
        setInternalDescription('');
        setCurrency('');
        setInternalReference();
        setValidFrom('');
        setContractSubtype('');
        setContractSubgroup('');
        setExternalDescription('');
        setApprovedStatus('');
        setExternalReference('');
        setCreatedBy('');
        setCreatedOn('');
        setChangedBy('');
        setChangedOn('');
        setValidTo('');
    }
    function handleApplicationtype(newValue) {
        setApplicationType(newValue);
    }
    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleContractGroup(newValue) {
        setContractGroup(newValue);
    }
    function handleInternalDescription(newValue) {
        setInternalDescription(newValue);
    }
    function handleCurrency(newValue) {
        setCurrency(newValue);
    }
    function handleInternalReference(newValue) {
        setInternalReference(newValue);
    }
    function handleValidFrom(newValue) {
        if (newValue && newValue.isValid())
            setValidFrom(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setValidFrom(newValue)
    }
    function handleContractSubtype(newValue) {
        setContractSubtype(newValue);
    }
    function handleContractSubgroup(newValue) {
        setContractSubgroup(newValue);
    }
    function handleExternalDescription(newValue) {
        setExternalDescription(newValue);
    }
    function handleApprovedStatus(newValue) {
        setApprovedStatus(newValue);
    }
    function handleExternalReference(newValue) {
        setExternalReference(newValue);
    }
    function handleValidTo(newValue) {
        if (newValue && newValue.isValid())
            setValidTo(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setValidTo(newValue);
    }
    function handleCreatedBy(newValue) {
        setCreatedBy(newValue);
    }
    function handleCreatedOn(newValue) {
        if (newValue && newValue.isValid())
            setCreatedOn(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setCreatedOn(newValue);
    }
    function handleChangedBy(newValue) {
        setChangedBy(newValue);
    }
    function handleChangedOn(newValue) {
        if (newValue && newValue.isValid())
            setChangedOn(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setChangedOn(newValue);
    }

    return (
        <div className={classes.root}>
            <Typography variant="h1" color='primary' style={{ marginBottom: 18 }}> Contract Reports </Typography>
            {/* Header Container */}
            <div className={clsx(classes.headerRoot, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="HEADER FIELDS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                                    {/* <Dropdown heading='Application Type' placeholder='Select Application Type' twoline='true' {...props} onChange={handleApplicationtype} data={applicationTypeData} /> */}
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Calculation Method' placeholder='Select Calculation Method' twoline='true' {...props} onChange={handleContractType} data={contractTypeData} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Contract Group' placeholder='Select Contract Group' data={contractGroupData} twoline='true' onChange={handleContractGroup} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{root:classes.fontSetting}} heading='Internal Description' placeholder='Internal Description' twoline='true' {...props} onChange={handleInternalDescription} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}}  style={{marginTop:-5}} heading='Currency' placeholder='Select Currency' twoline='true' {...props} data={currencyData} onChange={handleCurrency} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{root:classes.fontSetting}} heading='Internal Reference' placeholder='Internal Reference' twoline='true' onChange={handleInternalReference} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{root:classes.fontSetting}} heading='Start Date' twoline='true'{...props} onChange={handleValidFrom} error={typeof validFrom === 'boolean' && !validFrom ? true : false} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Contract Type' placeholder='Select Contract Type' twoline='true' {...props} onChange={handleContractSubtype} data={contractSubtypeData} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Contract SubGroup' placeholder='Select Contract Sub Group' twoline='true' onChange={handleContractSubgroup} data={contractSubGroupData} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{root:classes.fontSetting}} heading='External Description' placeholder='External Description' twoline='true' onChange={handleExternalDescription} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown  classes={{root:classes.fontSetting}} style={{marginTop:-5}} heading='Approved Status' placeholder='Select Approval Status' twoline='true' onChange={handleApprovedStatus} data={approvedStatusData} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{root:classes.fontSetting}} heading='External Reference' placeholder='External Reference' twoline='true' onChange={handleExternalReference} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{root:classes.fontSetting}} heading='End Date' twoline='true' {...props} onChange={handleValidTo} error={typeof validTo === 'boolean' && !validTo ? true : false} minimum={validFrom} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div>
            {/* Admin Container */}
            <div className={clsx(classes.adminRoot, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="ADMIN DATA"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Created By' twoline='true' placeholder='Select' onChange={handleCreatedBy} data={createdBy} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{root:classes.fontSetting}} style={{marginTop:7}} heading='Created On' twoline='true' onChange={handleCreatedOn} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Dropdown classes={{root:classes.fontSetting}} heading='Changed By' twoline='true' placeholder='Select' onChange={handleChangedBy} data={changedBy} />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{root:classes.fontSetting}} heading='Changed On' twoline='true' onChange={handleChangedOn} data={changedOn} />
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div>
            <div className={classes.submitContainer}>
                <SubmitContainer onSubmit={handleSubmit} onCancel={handleClear} loadingAPI={props.loading} />
            </div>
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (initial, total) => dispatch(runContractReports(initial, total, ''))
    }
}
const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsReportsTab);