import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getIndirectSalesInvoiceQueryList, indirectSalesDataUploadNew, postIndirectSalesDataQuery } from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import BeatLoader from "react-spinners/BeatLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SimpleDialog from '../../components/Dialog';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const SalesInvoiceQueryList = props => {
    useEffect(() => {
        props.getSalesInvoiceQueryList();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    const inputBatchFile = useRef(null)
    const [currentBatchFile, setCurrentBatchFile] = useState(undefined);
    const [currentBatchFileName, setCurrentBatchFileName] = useState('');
    const [formatBatch, setFormatBatch] = useState('');
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');
    const [open1, setOpen1] = useState(false);
    const [currentId, setCurrentId] = React.useState("")
    const [currentData, setCurrentData] = React.useState("")
    useEffect(() => {
        if (props.indirectSalesInvoiceQueryErrorList) {
            setErrorList(props.indirectSalesInvoiceQueryErrorList)
            if (Object.entries(props.indirectSalesInvoiceQueryErrorList).length > 0 && props.indirectSalesInvoiceQueryErrorList.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.indirectSalesInvoiceQueryErrorList])
    const handleDrop = (event, item) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadSalesData(data, currentEditableID);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
        setOpen(true)
    }

    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    const handleBatchDrop = (event, item) => {
        setCurrentBatchFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentBatchFile(data);
        props.uploadSalesData(data, currentBatchEditableID);
        setCurrentBatchFile(undefined);
        setCurrentBatchFileName('');
        event.target.value = '';
        //setFormatBatch('')
        setOpen(true)
    }
    function runBatchQuery(item, type) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click();
    }
    //dynamic query functions
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('indirect-sales-invoice-query', JSON.stringify(item))
        history.push('/indirect-sales-inovoice-query/edit/' + item.id);

    }
    function viewQuery(item) {
        localStorage.setItem('indirect-sales-invoice-query', JSON.stringify(item))
        history.push('/indirect-sales-inovoice-query/view/' + item.id);

    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen1(false);
            props.onDelete(currentData, currentId, 'delete');
        } else {
            setOpen1(false);
        }
    }
    const handleClickOpen = (item, id) => {
        setOpen1(true)
        setCurrentId(id)
        setCurrentData(item)
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Indirect - Sales Data Flexible File Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    // component={CustomRouterLink}
                    onClick={() => history.push('/indirect-sales-inovoice-query/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
                {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/indirect-sales-inovoice-query/add-query')}
                >
                    {isDesktop ? 'Add Format' : ''}
                </Button> */}
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer style={{ marginTop: -25 }}>
                    <Table stickyHeader>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.queryListData
                                .sort(function (a, b) {
                                    return a.id < b.id ? 1 : -1;
                                })
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editQuery(item)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => viewQuery(item)}
                                                        size="large">
                                                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleDrop(e, item)} />
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => runQuery(item)}
                                                        size="large">
                                                        <PublishIcon color="disabled" style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputBatchFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleBatchDrop(e, item)} />
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => runBatchQuery(item)}
                                                        size="large">
                                                        <UploadFileIcon color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => handleClickOpen(item, item.id,)}
                                                        size="large">
                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                    </IconButton>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={errorDialogOpen}
            >
                <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <SimpleDialog open={open1} content='Do you want to delete data?' handleDialog={handleDialog} />
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.indirectSalesInvoiceQueryListData,
        loading: state.dataSetupData.loading,
        indirectSalesInvoiceQueryErrorList: state.dataSetupData.indirectSalesInvoiceQueryErrorList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSalesInvoiceQueryList: () => dispatch(getIndirectSalesInvoiceQueryList()),
        uploadSalesData: (file, currentEditableID) => dispatch(indirectSalesDataUploadNew(file, currentEditableID)),
        onDelete: (data, id, type) => dispatch(postIndirectSalesDataQuery(data, id, type)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceQueryList);