import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getSalesForecastFormatData,
    deleteSalesForecastFormatData,
    uploadSalesForecast,
} from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    // tableCursor:{
    //     cursor: 'pointer'
    // },
    fontSizeicon: {
        fontSize: 20
    },
    noneDisplay: {
        display: 'none'
    },
    dialogSucessMsg: {
        color: 'green',
        marginBottom: 20
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const SalesForecastExcelUpload = props => {
    useEffect(() => {
        props.getSalesForecastFormatData();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [salesForecastData, setSalesForecastData] = useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open, setOpen] = useState(false)
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadSalesForecast(currentEditableID, data);
        setOpen(true)
    }
    useEffect(() => {
        props.getSalesForecastFormatData();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.salesForecastFormatData) {
            setSalesForecastData(props.salesForecastFormatData)
        }
    }, [props.salesForecastFormatData]);
    useEffect(() => {
        if (props.salesForecastError) {
            setErrorList(props.salesForecastError)
            if (Object.entries(props.salesForecastError).length > 0 && props.salesForecastError.error_list != null && open)
                setErrorDialogOpen(true)
        }
    }, [props.salesForecastError])

    useEffect(() => {
        if (props.salesForecast && props.salesForecast.records && props.salesForecast.records.length > 0) {
            setSalesForecastData(props.salesForecast.records)
        }
    }, [props.salesForecast])
    //dynamic query functions
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('sales-forecast-query', JSON.stringify(item))
        history.push('/sales-forecasting/sales-forecasting-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        localStorage.setItem('sales-forecast-query', JSON.stringify(item))
        history.push('/sales-forecasting/sales-forecasting-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteSalesForecastFormatData(item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            // spinner={<HashLoader />}
            className={classes.spinner}

        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'>
                        Sales Forecasting Excel Upload</Typography>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => history.push('/sales-forecasting/sales-forecasting-excel-upload/add-query')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                    {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/sales-forecasting/sales-forecasting-excel-upload/add-query')}
                >
                    {isDesktop ? 'Add Format' : ''}
                </Button> */}
                </div>
                {
                    salesForecastData && salesForecastData.length > 0 ?
                        <TableContainer style={{ marginTop: -20 }}>
                            <Table>
                                <TableHead >
                                    <TableRow >
                                        <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                        <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                        <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }}>
                                    {salesForecastData
                                        .map(item => {
                                            return (
                                                <StyledTableRow key={item.ID} >
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.id}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.format_name}</TableCell>
                                                    {props.loading ?
                                                        <TableCell width={300} align='center'>
                                                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                        </TableCell>
                                                        :
                                                        <TableCell align='center' width={300}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editQuery(item)}
                                                                size="large">
                                                                <EditIcon color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => viewQuery(item)}
                                                                size="large">
                                                                <VisibilityIcon color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleDrop(e)} />
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runQuery(item)}
                                                                size="large">
                                                                <PublishIcon color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => deleteQuery(item)}
                                                                size="large">
                                                                <DeleteForeverIcon color="disabled" classes={{ root: classes.fontSizeicon }} disabled={true} />
                                                            </IconButton>
                                                        </TableCell>
                                                    }
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Typography variant='h4'>
                            No invoice query list added.
                        </Typography>
                }
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={errorDialogOpen}
                >
                    <DialogActions style={{ position: 'absolute', marginLeft: 320 }}>
                        <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                    </DialogActions>
                    <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>

                        <div classes={{ root: classes.dialogSucessMsg }}>{errorList.success_msg}</div>
                        <span style={{ color: 'grey' }}>Error Details</span>
                        <List>
                            {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                                return (<ListItem>
                                    <ErrorOutlineIcon classes={{ root: classes.errorlOutlineColor }} />
                                    <ListItemText style={{ color: '#FF1A1A' }}
                                        primary={item}
                                    />
                                </ListItem>
                                )
                            })}
                        </List>
                    </DialogContent>
                </Dialog>
            </div >
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        salesForecastFormatData: state.dataSetupData.salesForecastFormatData,
        loading: state.dataSetupData.loading,
        salesForecastError: state.dataSetupData.salesForecastErrorList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadSalesForecast: (id, file) => dispatch(uploadSalesForecast(id, file)),
        deleteSalesForecastFormatData: (id) => dispatch(deleteSalesForecastFormatData(id)),
        getSalesForecastFormatData: () => dispatch(getSalesForecastFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesForecastExcelUpload);