import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../components/Dialog';
import { getDefaultValues, getAllSalesDataList, } from '../../redux/actions';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import LoadingOverlay from 'react-loading-overlay';
import RotateLoader from "react-spinners/RotateLoader";
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        // width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const CalculationSimulationForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [fiscalYearMonth, setFiscalYearMonth] = React.useState([]);
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [customerName, setCustomerName] = React.useState([]);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierName, setSupplierName] = React.useState([]);
    const [billingDocumentNumber, setBillingDocumentNumber] = React.useState([]);
    const [billingDocumentLine, setBillingDocumentLine] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [orderReason, setOrderReason] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [supplierCustomerNumber, setSupplierCustomerNumber] = React.useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [billingDocLineRange, setBillingDocLineRange] = React.useState('');
    const [billingType, setBillingType] = React.useState([]);
    const [companyCodeMultiple, setCompanyCodeMultiple] = React.useState([]);
    const [salesOrgMultiple, setSalesOrgMultiple] = React.useState([]);
    const [soldToPartyMultiple, setSoldToPartyMultiple] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [salesOrderTypeMultiple, setSalesOrderTypeMultiple] = React.useState([]);
    const [itemCategory, setItemCategory] = React.useState([]);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [customerChainMultiple, setCustomerChainMultiple] = React.useState([]);
    const [customerGroupMultiple, setCustomerGroupMultiple] = React.useState([]);
    const [vendorGroupMultiple, setVendorGroupMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [materialTypeMultiple, setMaterialTypeMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    useEffect(() => {
        localStorage.removeItem('Syncfusion')
        props.onLoadingDefault();
    }, []);
    const [open, setOpen] = useState(false);
    const formData = {
        "billing_date.range": (billingDateStart ? Moment(billingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (billingDateEnd ? Moment(billingDateEnd).local().format('YYYY-MM-DD') : ''),
        "billing_doc_line_key.in": billingDocLineMultiple.length > 0 ? billingDocLineMultiple.toString() : null,
        "billing_type.in": billingType.length > 0 ? billingType.toString() : null,
        "item_category.in": itemCategory.length > 0 ? itemCategory.toString() : null,
        "company_code.in": companyCodeMultiple.length > 0 ? companyCodeMultiple.toString() : null,
        "sales_org.in": salesOrgMultiple.length > 0 ? salesOrgMultiple.toString() : null,
        "calendar_year_month.in": calendarYearMonth.length > 0 ? calendarYearMonth.toString() : null,
        "sold_to_party.in": soldToPartyMultiple.length > 0 ? soldToPartyMultiple.toString() : null,
        "customer_chain.in": customerChainMultiple.length > 0 ? customerChainMultiple.toString() : null,
        "customer_group.in": customerGroupMultiple.length > 0 ? customerGroupMultiple.toString() : null,
        "supplier_group.in": vendorGroupMultiple.length > 0 ? vendorGroupMultiple.toString() : null,
        "material_number.in": materialNumberMultiple.length > 0 ? materialNumberMultiple.toString() : null,
        "material_group.in": materialGroupMultiple.length > 0 ? materialGroupMultiple.toString() : null,
        "material_type.in": materialTypeMultiple.length > 0 ? materialTypeMultiple.toString() : null,
        "product_hierarchy.in": productHierarchyMultiple.length > 0 ? productHierarchyMultiple.toString() : null,
        "sales_order_type.in": salesOrderTypeMultiple.length > 0 ? salesOrderTypeMultiple.toString() : null,
        "plant.in": plantMultiple.length > 0 ? plantMultiple.toString() : null,
        "profit_center.in": profitCenterMultiple.length > 0 ? profitCenterMultiple.toString() : null,
        "fiscal_year_month.in": fiscalYearMonth.length > 0 ? fiscalYearMonth.toString() : null,
        "pricing_date.range": (pricingDateStart ? Moment(pricingDateStart).local().format('YYYY-MM-DD') : '') + ',' + (pricingDateEnd ? Moment(pricingDateEnd).local().format('YYYY-MM-DD') : ''),
        "customer_name.in": customerName.length > 0 ? customerName.toString() : null,
        "material_description.in": materialDescription.length > 0 ? materialDescription.toString() : null,
        "supplier_number.in": supplierNumber.length > 0 ? supplierNumber.toString() : null,
        "supplier_name.in": supplierName.length > 0 ? supplierName.toString() : null,
        "supplier_customer_number.in": supplierCustomerNumber.length > 0 ? supplierCustomerNumber.toString() : null,
        "billing_doc_number.in": billingDocumentNumber.length > 0 ? billingDocumentNumber.toString() : null,
        "billing_doc_line.in": billingDocumentLine.length > 0 ? billingDocumentLine.toString() : null,
        "distribution_channel.in": distributionChannel.length > 0 ? distributionChannel.toString() : null,
        "division.in": division.length > 0 ? division.toString() : null,
        "employee_number.in": employeeNumber.length > 0 ? employeeNumber.toString() : null,
        "employee_name.in": employeeName.length > 0 ? employeeName.toString() : null,
        "territory_id .in": terittoryID.length > 0 ? terittoryID.toString() : null,
        "region.in": region.length > 0 ? region.toString() : null,
        "sales_district.in": salesDistrict.length > 0 ? salesDistrict.toString() : null,
        "sales_office.in": salesOffice.length > 0 ? salesOffice.toString() : null,
        "sales_group.in": salesGroup.length > 0 ? salesGroup.toString() : null,
        "order_reason.in": orderReason.length > 0 ? orderReason.toString() : null,
        "flex_attribute1.in": flexAttribute1.length > 0 ? flexAttribute1.toString() : null,
        "flex_attribute2.in": flexAttribute2.length > 0 ? flexAttribute2.toString() : null,
        "customer_number.in": customerNumber.length > 0 ? customerNumber.toString() : null,
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setRadioGroupValue('');
        setFiscalYearMonth([]);
        setPricingDateStart(null);
        setPricingDateEnd(null);
        setCustomerName('');
        setMaterialDescription([]);
        setSupplierNumber([]);
        setSupplierName([]);
        setBillingDocumentNumber([]);
        setBillingDocumentLine([]);
        setDistributionChannel([]);
        setDivision([]);
        setEmployeeNumber([]);
        setEmployeeName([]);
        setTerittoryID([]);
        setRegion([]);
        setSalesDistrict([]);
        setSalesOffice([]);
        setSalesGroup([]);
        setOrderReason([]);
        setFlexAttribute1([]);
        setFlexAttribute2([]);
        setSupplierCustomerNumber([]);
        setCustomerNumber([]);
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocLineMultiple([]);
        setBillingDocLineRange('');
        setBillingType([]);
        setCompanyCodeMultiple([]);
        setSalesOrgMultiple([]);
        setSoldToPartyMultiple([]);
        setMaterialNumberMultiple([]);
        setSalesOrderTypeMultiple([]);
        setItemCategory([]);
        setCalendarYearMonth([]);
        setCustomerChainMultiple([]);
        setCustomerGroupMultiple([]);
        setVendorGroupMultiple([]);
        setMaterialGroupMultiple([]);
        setMaterialTypeMultiple([]);
        setProductHierarchyMultiple([]);
        setPlantMultiple([]);
        setProfitCenterMultiple([]);
    }
    const handleClickOpen = (arg) => {
        if (arg === 'SF') {
            localStorage.setItem('Syncfusion', true);
        } else {
            localStorage.removeItem('Syncfusion');
        }
        setOpen(true);

    };
    const handleCompanyCodeMultiple = (newValue) => {
        setCompanyCodeMultiple(newValue);
    }
    const handleSalesOrgMultiple = (newValue) => {
        setSalesOrgMultiple(newValue);
    }
    const handleCustomerChainMultiple = (newValue) => {
        setCustomerChainMultiple(newValue);
    }
    const handleCustomerGroupMultiple = (newValue) => {
        setCustomerGroupMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }

    const handleVendorGroupMultiple = (newValue) => {
        setVendorGroupMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);

    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);

    }
    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(newValue);

    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(newValue);
    }
    function handleCalendarYearMonth(newValue) {
        setCalendarYearMonth(newValue)
    }
    function handleFiscalYearMonth(newValue) {
        setFiscalYearMonth(newValue)
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
    }
    function handleCustomerName(newValue) {
        setCustomerName(newValue)
    }
    function handleMaterialDescription(newValue) {
        setMaterialDescription(newValue)
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue)
    }
    function handleSupplierName(newValue) {
        setSupplierName(newValue)
    }
    function handleSupplierCustomerNumber(newValue) {
        setSupplierCustomerNumber(newValue)
    }
    function handleBillingDocumentNumber(newValue) {
        setBillingDocumentNumber(newValue)
    }
    function handleBillingDocumentLine(newValue) {
        setBillingDocumentLine(newValue)
    }
    function handleBillingType(newValue) {
        setBillingType(newValue)
    }
    function handleItemCategory(newValue) {
        setItemCategory(newValue)
    }
    function handleDistributionChannel(newValue) {
        setDistributionChannel(newValue)
    }
    function handleDivision(newValue) {
        setDivision(newValue)
    }
    function handleEmployeeNumber(newValue) {
        setEmployeeNumber(newValue)
    }
    function handleEmployeeName(newValue) {
        setEmployeeName(newValue)
    }
    function handleTerittoryID(newValue) {
        setTerittoryID(newValue)
    }
    function handleRegion(newValue) {
        setRegion(newValue)
    }
    function handleSalesDistrict(newValue) {
        setSalesDistrict(newValue)
    }
    function handleSalesOffice(newValue) {
        setSalesOffice(newValue)
    }
    function handleSalesGroup(newValue) {
        setSalesGroup(newValue)
    }
    function handleOrderReason(newValue) {
        setOrderReason(newValue)
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue)
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> Display Sales Data</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Calendar Year Month</FormLabel>
                                    <ChipInput data={calendarYearMonth} onChange={handleCalendarYearMonth} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Fiscal Year Month</FormLabel>
                                    <ChipInput data={fiscalYearMonth} onChange={handleFiscalYearMonth} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 20 }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>Billing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={date => handleBillingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10,
                                                        marginRight: 15
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={date => handleBillingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                style={{ marginTop: 20 }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>Pricing Date</FormLabel>
                                <div className={classes.dateColumn} style={{ display: 'flex' }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        //width: '100%'
                                                    }
                                                }}
                                                value={pricingDateStart}
                                                onChange={date => handlePricingDateStart(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px 0px 11px',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        // marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        //width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={pricingDateEnd}
                                                onChange={date => handlePricingDateEnd(date)}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={pricingDateStart ? pricingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Number</FormLabel>
                                    <ChipInput data={customerNumber} onChange={handleCustomerNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Name</FormLabel>
                                    <ChipInput data={customerName} onChange={handleCustomerName} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Chain</FormLabel>
                                    <ChipInput data={customerChainMultiple} onChange={handleCustomerChainMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Customer Group</FormLabel>
                                    <ChipInput data={customerGroupMultiple} onChange={handleCustomerGroupMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Number</FormLabel>
                                    <ChipInput data={materialNumberMultiple} onChange={handleMaterialNumberMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Description</FormLabel>
                                    <ChipInput data={materialDescription} onChange={handleMaterialDescription} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Product Hierarchy</FormLabel>
                                    <ChipInput data={productHierarchyMultiple} onChange={handleProductHierarchyMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Material Group</FormLabel>
                                    <ChipInput data={materialGroupMultiple} onChange={handleMaterialGroupMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Number</FormLabel>
                                    <ChipInput data={supplierNumber} onChange={handleSupplierNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Name</FormLabel>
                                    <ChipInput data={supplierName} onChange={handleSupplierName} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Group</FormLabel>
                                    <ChipInput data={vendorGroupMultiple} onChange={handleVendorGroupMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Supplier Customer Number</FormLabel>
                                    <ChipInput data={supplierCustomerNumber} onChange={handleSupplierCustomerNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Number</FormLabel>
                                    <ChipInput data={billingDocumentNumber} onChange={handleBillingDocumentNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Line</FormLabel>
                                    <ChipInput data={billingDocumentLine} onChange={handleBillingDocumentLine} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Billing Type</FormLabel>
                                    <ChipInput data={billingType} onChange={handleBillingType} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Item Category</FormLabel>
                                    <ChipInput data={itemCategory} onChange={handleItemCategory} />


                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Company Code</FormLabel>
                                    <ChipInput data={companyCodeMultiple} onChange={handleCompanyCodeMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Org</FormLabel>
                                    <ChipInput data={salesOrgMultiple} onChange={handleSalesOrgMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Distribution Channel</FormLabel>
                                    <ChipInput data={distributionChannel} onChange={handleDistributionChannel} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Division</FormLabel>
                                    <ChipInput data={division} onChange={handleDivision} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Number</FormLabel>
                                    <ChipInput data={employeeNumber} onChange={handleEmployeeNumber} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Employee Name</FormLabel>
                                    <ChipInput data={employeeName} onChange={handleEmployeeName} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Territory ID</FormLabel>
                                    <ChipInput data={terittoryID} onChange={handleTerittoryID} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Region</FormLabel>
                                    <ChipInput data={region} onChange={handleRegion} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales District</FormLabel>
                                    <ChipInput data={salesDistrict} onChange={handleSalesDistrict} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Office</FormLabel>
                                    <ChipInput data={salesOffice} onChange={handleSalesOffice} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Sales Group</FormLabel>
                                    <ChipInput data={salesGroup} onChange={handleSalesGroup} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Order Reason</FormLabel>
                                    <ChipInput data={orderReason} onChange={handleOrderReason} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Plant</FormLabel>
                                    <ChipInput data={plantMultiple} onChange={handlePlantMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Profit Center</FormLabel>
                                    <ChipInput data={profitCenterMultiple} onChange={handleProfitCenterMultiple} />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 1</FormLabel>
                                    <ChipInput data={flexAttribute1} onChange={handleFlexAttribute1} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '104%', paddingRight: 15, marginTop: 20 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Flex Attribute 2</FormLabel>
                                    <ChipInput data={flexAttribute2} onChange={handleFlexAttribute2} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            {/* <Button
                                disabled={(
                                    fiscalYearMonth.length > 0
                                    || (pricingDateStart != null) || (pricingDateEnd != null) || customerName.length > 0 || materialDescription.length > 0
                                    || supplierNumber.length > 0 || supplierName.length > 0 || billingDocumentNumber.length > 0 || billingDocumentLine.length > 0
                                    || distributionChannel.length > 0 || division.length > 0 || employeeNumber.length > 0 || employeeName.length > 0
                                    || terittoryID.length > 0 || region.length > 0 || salesDistrict.length > 0 || salesOffice.length > 0 || salesGroup.length > 0
                                    || orderReason.length > 0 || flexAttribute1.length > 0 || flexAttribute2.length > 0 || supplierCustomerNumber.length > 0
                                    || customerNumber.length > 0 || (billingDateStart != null) || (billingDateEnd != null)
                                    || billingDocLineMultiple.length > 0 || billingDocLineRange.length > 0 || billingType.length > 0
                                    || companyCodeMultiple.length > 0 || salesOrgMultiple.length > 0 || soldToPartyMultiple.length > 0
                                    || materialNumberMultiple.length > 0 || salesOrderTypeMultiple.length > 0 || materialTypeMultiple.length > 0
                                    || itemCategory.length > 0 || calendarYearMonth.length > 0 || customerChainMultiple.length > 0
                                    || customerGroupMultiple.length > 0 || vendorGroupMultiple.length > 0 || materialGroupMultiple.length > 0
                                    || productHierarchyMultiple.length > 0 || plantMultiple.length > 0 || profitCenterMultiple.length > 0
                                ) ? false : true}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                }
                            </Button> */}
                            <Button
                                disabled={(
                                    fiscalYearMonth.length > 0
                                    || (pricingDateStart != null) || (pricingDateEnd != null) || customerName.length > 0 || materialDescription.length > 0
                                    || supplierNumber.length > 0 || supplierName.length > 0 || billingDocumentNumber.length > 0 || billingDocumentLine.length > 0
                                    || distributionChannel.length > 0 || division.length > 0 || employeeNumber.length > 0 || employeeName.length > 0
                                    || terittoryID.length > 0 || region.length > 0 || salesDistrict.length > 0 || salesOffice.length > 0 || salesGroup.length > 0
                                    || orderReason.length > 0 || flexAttribute1.length > 0 || flexAttribute2.length > 0 || supplierCustomerNumber.length > 0
                                    || customerNumber.length > 0 || (billingDateStart != null) || (billingDateEnd != null)
                                    || billingDocLineMultiple.length > 0 || billingDocLineRange.length > 0 || billingType.length > 0
                                    || companyCodeMultiple.length > 0 || salesOrgMultiple.length > 0 || soldToPartyMultiple.length > 0
                                    || materialNumberMultiple.length > 0 || salesOrderTypeMultiple.length > 0 || materialTypeMultiple.length > 0
                                    || itemCategory.length > 0 || calendarYearMonth.length > 0 || customerChainMultiple.length > 0
                                    || customerGroupMultiple.length > 0 || vendorGroupMultiple.length > 0 || materialGroupMultiple.length > 0
                                    || productHierarchyMultiple.length > 0 || plantMultiple.length > 0 || profitCenterMultiple.length > 0
                                ) ? false : true}
                                variant="contained" color="primary" className={classes.button} onClick={() => handleClickOpen('SF')} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run with Syncfusion'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content={'Do you want to run' + radioGroupValue + '?'} handleDialog={handleDialog} />
            </div >
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(getAllSalesDataList(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        AllSalesDataList: state.dataSetupData.AllSalesDataList,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationSimulationForm);
