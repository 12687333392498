import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main
    }
}));
const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [


    {
        'Integration Setup': [
            { 'Integration Activity': '/integration-setup' },
            { 'Integration Mapping': '/integration-mapping' },
            { 'Integration Filter': '/integration-filter' },
        ]
    },
    // { 'Integration Manager': '/integration-manager' },
    // { 'Integration Mapping': '/integration-mapping' },
    {
        'Execution Management': [
            { 'On-Demand Integration': '/on-demand-integration' },
            { 'Batch Job Setup': '/integration-batchjob-setup' }
        ]
    },
    {
        'Execution Status': [
            { 'Integration Status': '/integration-status' },
            { 'Batch Job Status': '/integration-batchjob-status' },
        ]
    },
    {
        'Maintenance': [
            { 'Delete Master Data': '/delete-master-data' },
            { 'Delete External Formula Result': '/delete-external-formula-result' },
            { "Delete Calculation Simulation Data": "/delete-calculation-simulation-data" },
            { "kafka Upload": "/kafka-upload" },
            { "Application Logs": "/application-logs" }
        ]
    },
]
const subMenuItems = [
    {
        'Delete Transaction Data': [
            { 'Delete Sales Data': '/delete-sales-invoice' },
            { 'Delete Indirect Sales Data': '/delete-indirect-sales-invoice' },
            { 'Delete Purchase Data': '/delete-purchase-invoice' },


        ]
    },
]

const IntegrationStudio = (props) => {
    const classes = useStyles();
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                            {Object.keys(item)[0] == 'Maintenance' && subMenuItems.map(item => {
                                return (
                                    <NavDropdown title={Object.keys(item)[0]} className={classes.navBgColor} style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationStudio);