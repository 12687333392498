import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    TextField,
    Button,
    ListItemText,
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel, OutlinedInput
} from '@mui/material';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { runcontractChangeReports, getDefaultValues, getDefaultValuesContractChange, getAllOrganizationUsers, getContractsTableDataList, allowedApps, getLblDispDesFieldValue } from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
import moment from 'moment-timezone';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { HashLoader } from 'react-spinners';
import { ContractNumberAC, SearchDropdown } from '../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        paddingTop: 10
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        // marginTop: 15,
        padding: "0px 20px 15px 20px"
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    dateColumn: {
        display: 'flex',
        //flexDirection: 'column',
        width: '100%',
        //marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 5,
        height: 36
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    textInputHeight: {
        height: 17
    },
    error: {
        border: '1px solid red !important'
    },
    spinner: {
        // height: '100vh'
    },
    dropdownContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',

    },
    button: {
        marginRight: 25,
        width: 140
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        paddingTop: 15
    },
}));

const ContractChangeReportsTab = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const contractCustomisationUpdation = useRef();
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application') != 'Audit Lab' ? sessionStorage.getItem('application') : '');
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [contractType, setContractType] = React.useState([]);
    const [open, setOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [label, setLabel] = React.useState('');
    const [contractAnalyst, setContractAnalyst] = React.useState([]);
    const [contractManager, setContractManager] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const [ChangeOnDateStart, setChangeOnDateStart] = React.useState(null);
    const [ChangeOnDateEnd, setChangeOnDateEnd] = React.useState(null);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [contractNumberRange, setContractNumberRange] = React.useState('');
    const [orgUsersAarray, setOrgUsersArray] = React.useState([]);
    const [contractNumberKey, setContractNumberKey] = React.useState('');
    const [disableContractNumberRange, setDisableContractNumberRange] = React.useState(false);
    const [ChangeOnDateRangeKey, setChangeOnDateRangeKey] = React.useState('updated_at');
    const [radioGroupValue, setRadioGroupValue] = React.useState('all-changes');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [zone, setZone] = React.useState('');
    const [usersName, setUsersName] = React.useState([]);
    const isAllSelected =
        orgUsersAarray.length > 0 && selected.length === orgUsersAarray.length;

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.orgUsers) {
            setOrgUsersArray(props.orgUsers)
            let newSelected = []
            let selectedId = []
            props.orgUsers.map(item => {
                newSelected = newSelected.concat(item.username);
                selectedId = selectedId.concat(item.id)
            })
            setUsersId(selectedId)
            setUsersName(newSelected)
            //console.log(newSelected)
        }

    }, [props.orgUsers]);

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {

            setContractTypeArray(props.dropdownData.field_label_attributes.contract_type)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])


    const handleContractNumberMultiple = (newValue) => {
        setContractNumberMultiple(newValue);
        if (contractNumberMultiple.length > 0) {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(true);
            setContractNumberRange('');
        } else {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(false);
        }
    };

    const handleContractNumberRange = (newValue) => {
        setContractNumberRange(newValue);
        if (newValue) {
            setContractNumberKey('contract_number.range');
        } else {
            setContractNumberKey('contract_number.in');
        }
    }




    useEffect(() => {

        props.getUsers()
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');

    }, []);
    useEffect(() => {
        if (applicationType) {
            props.onCheckContractsTable(applicationType);
            props.getDefaultValuesAllData(applicationType);
        }

    }, [applicationType]);

    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);
        }


    }, [props.contractTabledataComplete])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    const handleChangeOnDateStart = (newValue) => {
        setChangeOnDateStart(newValue);
        if (ChangeOnDateEnd) {
            setChangeOnDateRangeKey('updated_at.range');
        } else {
            setChangeOnDateRangeKey('updated_at');
        }
    }
    const handleChangeOnDateEnd = (newValue) => {
        setChangeOnDateEnd(newValue);
        if (newValue) {
            setChangeOnDateRangeKey('updated_at.range');
        } else {
            setChangeOnDateRangeKey('updated_at');
        }
    }

    const [createdBy, setCreatedBy] = React.useState([]);
    const handleCreatedBy = (event) => {
        setCreatedBy(event.target.value);
    }
    const handleSelectValues = (event, value) => {

        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (label == 'createdBy') {
                setSelected(selected.length === orgUsersAarray.length ? [] : usersId);
            }

            else {
                setSelected(selected.length === orgUsersAarray.length ? [] : usersName);
            }


        }
        else
            setSelected(newSelected)

    }

    const onSubmitHandler = () => {

        var formData = {

            [ChangeOnDateRangeKey]: (ChangeOnDateStart ? Moment(ChangeOnDateStart).local().format('YYYY-MM-DD') : '') + (ChangeOnDateEnd ? ',' + Moment(ChangeOnDateEnd).local().format('YYYY-MM-DD') : ''),
            "contract_number.in": contractNumberRange ? contractNumberMultiple.toString() + ',' + contractNumberRange : contractNumberMultiple.toString(),
            "created_by_id.in": createdBy.toString(),// ? parseInt(createdBy) : '',
            "contract_type.equals": contractType,
            "contract_analyst.in": contractAnalyst.toString(),
            "contract_manager.in": contractManager.toString(),
            "current_zone": moment.tz.guess() ? moment.tz.guess() : ''
            // "changed_by_id.in":createdBy?parseInt(createdBy):''

        };

        localStorage.setItem("ContractChangeReportsData", JSON.stringify(formData));
        localStorage.setItem("ContractChangeReportType", JSON.stringify(radioGroupValue));
        props.onSubmit(1, 0, radioGroupValue);
        onCancelHandler()
    }
    const onCancelHandler = () => {

        setCreatedBy([])
        setContractNumberMultiple([]);
        setContractNumberRange('');
        setChangeOnDateEnd(null)
        setChangeOnDateStart(null)
        setContractType([])
        setContractAnalyst([])
        setContractManager([])
        setRadioGroupValue('')
    }
    const handleContractType = (event) => {
        setContractType(event.target.value);
    }
    function onAdd() {
        console.log("test" + selected)
        if (label === 'contractManager')
            setContractManager(selected)
        if (label === 'contractAnalyst')
            setContractAnalyst(selected)
        if (label === 'createdBy')
            setCreatedBy(selected)

        setOpen(false)
        setSelected([])
    }
    function onCancel() {
        setOpen(false)
        setSelected([])

    }
    function handleOpen(type) {
        if (type === 'contractManager')
            setSelected(contractManager)
        if (type === 'contractAnalyst')
            setSelected(contractAnalyst)
        if (type == 'createdBy')
            setSelected(createdBy)

        setLabel(type)
        setOpen(true)
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function handleContractNumberAC(value) {
        setContractNumberMultiple(value);
        // let newSelected = value;
        // if (value.length > 0) {
        //     setSelected(newSelected);
        //     setContractNumberMultiple(newSelected);
        // } else {
        //     setSelected([]);
        //     setContractNumberMultiple([]);
        // }
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 4, marginBottom: -7 }}> Contract Change Log</Typography>
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            {sessionStorage.getItem('application') == 'Audit Lab' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                        <FormLabel classes={{ root: classes.fontSetting }}>
                                            Application Type
                                        </FormLabel>
                                        <Select
                                            value={applicationType}
                                            onChange={(e) => setApplicationType(e.target.value)}
                                            displayEmpty
                                            className={classes.multiSelect}
                                        >
                                            {allowedApps
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            <Grid
                                item
                                md={6}
                                xs={12}
                                // style={{ marginTop: '0rem' }}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} >
                                    {/* <ContractNumberAC
                                        multiple
                                        onChange={handleContractNumberAC}
                                        prevalue={contractNumberMultiple}
                                    /> */}
                                    <SearchDropdown id='contract_number_multiple'
                                        multiple
                                        table='contract_header' keyName='contract_number'
                                        description={true} heading='Contract Number'
                                        onChange={handleContractNumberAC}
                                        prevalue={contractNumberMultiple}
                                        applicationType={applicationType}
                                    // prevalue={['100001', '45']}

                                    />

                                </div>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Changed By</FormLabel>


                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={createdBy}
                                        style={{ maxHeight: "50px" }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen('createdBy')}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'createdBy'}
                                        renderValue={(name) => {
                                            let userName = []
                                            createdBy.map(id => {
                                                orgUsersAarray.filter(item => item.id === id)
                                                    .map((item, index) => {
                                                        userName = userName.concat(item.username)
                                                    })
                                            })
                                            return userName.join(", ")
                                        }

                                        }
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {orgUsersAarray && orgUsersAarray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.id)} value={item.uid} key={index}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.username} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ marginBottom: 10 }}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Changed On</FormLabel>


                                    <div className={classes.dateColumn}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 5,
                                                            border: !ChangeOnDateStart && ChangeOnDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                            width: '100%',
                                                            height: '36px',
                                                        }
                                                    }}
                                                    value={ChangeOnDateStart}
                                                    onChange={handleChangeOnDateStart}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    maxDate={new Date()}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                        {/* </div>
                                    <div className={classes.dateSecondColumn}> */}
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around" >
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '0px 11px 0px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 5,
                                                            height: '36px',
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={ChangeOnDateEnd}
                                                    onChange={handleChangeOnDateEnd}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={ChangeOnDateStart ? ChangeOnDateStart : ''}
                                                    maxDate={new Date()}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Contract Type</FormLabel>
                                    <Select
                                        value={contractType}
                                        input={<OutlinedInput />}
                                        onChange={handleContractType}
                                        className={classes.multiSelect}
                                    >
                                        <MenuItem value=''> </MenuItem>
                                        {contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length > 0 &&
                                            contractTypeArray.drop_down_values
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ marginBottom: 10 }}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Contract Analyst</FormLabel>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={contractAnalyst}
                                        style={{ maxHeight: "50px" }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen('contractAnalyst')}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'contractAnalyst'}
                                        renderValue={(contractAnalyst) => contractAnalyst.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {orgUsersAarray && orgUsersAarray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.username)} value={item.username} key={index}>

                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item.username) > -1} />
                                                        <ListItemText primary={item.username} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%' }}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>Contract Manager</FormLabel>

                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={contractManager}
                                        style={{ maxHeight: "50px" }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen('contractManager')}
                                        onClose={() => setOpen(false)}
                                        open={open && label === 'contractManager'}
                                        renderValue={(contractAnalyst) => contractAnalyst.join(", ")}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllSelected} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {orgUsersAarray && orgUsersAarray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.username)} value={item.username} key={index}>

                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected.indexOf(item.username) > -1} />
                                                        <ListItemText primary={item.username} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownContainer}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>


                                    </Select>
                                </div>
                            </Grid >
                            <Grid
                                item
                                md={12}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                                style={{ paddingTop: 10 }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="pending-changes"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Changes Pending Approval </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="all-changes"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > All Changes </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid >
                        <div className={classes.buttonRoot}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler}
                                disabled={!radioGroupValue}>
                                Run
                            </Button>
                        </div>
                    </div >
                </div >
            </div >
        </LoadingOverlay >
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, type) => dispatch(runcontractChangeReports(pagination, limit, type)),
        onLoadingDefaultValues: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getDefaultValuesAllData: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Display Designer')),
        getUsers: () => dispatch(getAllOrganizationUsers()),
        onCheckContractsTable: (applicationType) => dispatch(getContractsTableDataList(applicationType)),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),


    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        orgUsers: state.operationalReportsData.orgUsers,
        contractTabledataComplete: state.addContractData.contractAllListData,
        allowedAppsData: state.initialData.allowedApps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractChangeReportsTab);