import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    {
        'Direct - Sales Data': [
            { 'Upload Sales Data': '/sales-inovoice-query' },
            { 'Build Sales Data': '/build-sales-invoice' },
            { 'Display Sales Data': '/display-sales-invoice' },
            { 'Upload External Calculations': '/upload-external-calculation-main' },
            { 'Upload External Postings': '/upload-external-posting-main' },
        ]
    },
    {
        'Indirect - Sales Data': [
            { 'Upload Indirect Sales Data': '/indirect-sales-inovoice-query' },
            { 'Build Indirect Sales Data': '/build-indirect-sales-invoice' },
            { 'Display Indirect Sales Data': '/display-indirect-sales-invoice' },
        ]
    },
    {
        'Purchase Data': [
            { 'Upload Purchase Data': '/purchase-invoice' },
            { 'Build Purchase Data': '/build-purchase-invoice' },
            { 'Display Purchase Data': '/display-purchase-invoice' },
        ]
    },
    { 'Commitment Data': '/target-invoice-query' },
    { 'Inventory Data': '/inventory-data' },
    { 'Demand Supply': '/demand-supply' },
    { 'Competitor Data': '/competitor-data' },
    {
        'Reporting & Analytics': [
            { 'On Demand Query': '/dynamic-query' },
            { 'Predefined Query': '/predefined-query' },
        ]
    },
    {
        'Utilities': [
            { 'Batch Job': '/transaction-data-batchjob-setup' },
            { 'Batch Job Status': '/transaction-data-batchjob-status' }
        ]
    }
]

const TransactionData = (props) => {

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionData);