import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(0.3),
        //color: theme.palette.black
    },
    inputContainer: {
        // padding: props => props.size === "large" ? '30.5px 6px' : '4.5px 6px',
        // padding: '4.5px 6px',
        border: '1px solid #ccc',
        maxHeight: '36px',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    },
}));

const ChipInput = (props) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [chips, setChips] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const handleInputChange = (event, newValue) => {
        setInputValue(newValue);
    };

    const handleAddChip = (event) => {
        if (event.key === 'Enter' && inputValue.trim()) {
            setChips([...chips, inputValue.trim()]);
            setInputValue('');
            props.onChange([...chips, inputValue.trim()])
        }
    };

    const handleDeleteChip = (chipToDelete) => () => {
        setChips(chips.filter((chip) => chip !== chipToDelete));
        props.onChange(chips.filter((chip) => chip !== chipToDelete))
    };
    useEffect(() => {
        if (props.data)
            setChips(props.data)
    }, [props.data])
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (
        <div id={props.id} style={{
            height: props.size === "large" ? '50px' : '36px', overflowY: 'auto',
            border: isFocused ? 'transparent' : '1px solid #ccc',
        }}
            onClick={handleClick}
        >
            <Autocomplete
                multiple
                id={props.id}
                options={[]} // You can provide options for autocomplete here
                freeSolo
                value={chips}
                inputValue={inputValue}
                onChange={(event, newValue) => setChips(newValue)}
                onInputChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        //label="Autocomplete"
                        size={props.size ? props.size : "small"}
                        onKeyDown={handleAddChip}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: '3px 6px', border: 'none'// border: '1px solid #ccc'
                            },
                        }}
                    // sx={{
                    //     '& .MuiOutlinedInput-root': {
                    //         height: '50px', // Set the height of the input field
                    //     },
                    // }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option}
                            label={option}
                            size="small"
                            //variant="outlined" color="primary"
                            onDelete={handleDeleteChip(option)}
                            className={classes.chip}

                        />
                    ))
                }
                //style={{ backgroundColor: (props.disabled == true) && '#EBEBE4' }}
                disabled={props.disabled ? props.disabled : false}
            />
        </div>
    );
};

export default ChipInput;
