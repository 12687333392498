import React, { useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import Moment from 'moment';
import allStates from "./AllStates.json";
import { geographicalIncentive, loadValuesBasedOnAppTypeAndField, allowedApps, dashboardAnalyticsNames } from '../../redux/actions';
import { connect } from 'react-redux';
import { endOfMonth, subMonths, startOfMonth } from 'date-fns';
import { makeStyles } from "@mui/styles";
import { Select, MenuItem, FormLabel, Button, Typography, TextField } from '@mui/material';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import GeoChart from "./GeoChart";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';


const colorScale = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];



const GeographicalChartUS = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: '0px 0px 15px 0px',
            display: 'flex',
            alignItems: 'center'
        },
        rootDiv: {
            backgroundColor: theme.palette.white,
            borderRadius: 10,
            margin: '5px 0px 16px 0px',
        },
        select: {
            width: '100%',
            color: '#1675e0',
            backgroundColor: 'white'
        },
        selectedItem: {
            borderRadius: 5
        },
        buttonContainer: {
            justifyContent: "center",
            display: "flex",
            paddingTop: 34,
            paddingBottom: 5,
        },
        button: {
            marginRight: 10,
        },
        fontSetting: {
            fontSize: theme.typography.h3.fontSize
        },
        spinner: {
            height: '100vh'
        },
    }));
    const [offsets, setOffsets] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [groupBy, setGroupBy] = React.useState('contract_number');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [postingType, setPostingType] = React.useState('Accrual');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [geographicalIncentiveDataAPI, setGeographicalIncentiveDataAPI] = React.useState([]);
    const classes = useStyles();

    useEffect(() => {
        props.allowedAnalyticsApps(sessionStorage.getItem("application"));
    }, []);

    useEffect(() => {
        if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
            const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
                item.analytic_name === "Incentive Ratio by Attribute"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedAnalyticsAppsData]);


    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy));
            setSourceDataType(allowedTilesFilter[0].data_source_type);
            setPostingType(capitalizeFirstLetter(allowedTilesFilter[0].posting_type));
        }
    }, [allowedTilesFilter]);


    useEffect(() => {
        if (props.geographicalIncentiveData) {
            setGeographicalIncentiveDataAPI(props.geographicalIncentiveData);
        }
    }, [props.geographicalIncentiveData]);


    useEffect(() => {
        if (startDate && endDate && sourceDataType && postingType && groupBy) {
            props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, groupBy);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const handleStartDate = (e) => {
        if (e && e.isValid()) {
            setStartDate(e);
        } else {
            setStartDate(null);
        }
    }

    const handleEndDate = (e) => {
        if (e && e.isValid()) {
            setEndDate(e);
        } else {
            setEndDate(null);
        }
    }

    const handlePostingType = (e) => {
        setPostingType(e.target.value);
    }


    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }

    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }

    const handleGroupBy = (e) => {
        setGroupBy(e.target.value);
    }

    const handleRun = () => {
        props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, applicationType, sourceDataType, groupBy);
    }

    function convertToSnakeCase(string) {
        if (string) {
            return string.replace(/\s+/g, '_').toLowerCase();
        }
        return "";
    }

    function capitalizeFirstLetter(word) {
        if (word && word.length > 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return ""; // Or any other default value you prefer
    }


    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, []);


    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root}>
                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        {/* <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Application Type</Typography>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps
                                        .filter(item => item !== 'Customer Chargeback' && item !== 'Promotions' && item !== 'Supplier Chargeback' && item !== 'Pricing' && item !== 'Profit Optimization')
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid> */}

                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Source Data Type</Typography>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Posting Type</Typography>
                                <Select
                                    value={postingType}
                                    onChange={handlePostingType}
                                    label='Posting Type'
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}

                                >
                                    <MenuItem value={'Accrual'}>
                                        Accrual
                                    </MenuItem>
                                    <MenuItem value={'Payment'}>
                                        Payment
                                    </MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={endDate}
                                            minDate={startDate ? startDate : false}
                                            onChange={handleEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <Typography classes={{ root: classes.fontSetting }} >Aggregation Field</Typography>
                            <Select
                                value={groupBy}
                                onChange={handleGroupBy}
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {/* {groupByValue.length > 0 &&
                                groupByValue.map((item) => (                           
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                                ))} */}

                                <MenuItem value={'contract_number'}>
                                    Contract Number
                                </MenuItem>
                                <MenuItem value={'customer_number'}>
                                    Customer Number
                                </MenuItem>
                                <MenuItem value={'material_number'}>
                                    Material Number
                                </MenuItem>
                                <MenuItem value={'supplier_number'}>
                                    Supplier Number
                                </MenuItem>
                            </Select>
                        </Grid>

                        <div className={classes.buttonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleRun}
                                twoline="true"
                                disabled={(!startDate || !endDate || !sourceDataType || !groupBy || !postingType) ? true : false}

                            >
                                <DirectionsRunIcon />
                            </Button>
                        </div>

                    </Grid>
                    <Grid container className={classes.root}>
                        {groupBy === 'supplier_number' &&
                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
                                    color="primary"
                                >
                                    Supplier Number
                                </Typography>

                                {geographicalIncentiveDataAPI && geographicalIncentiveDataAPI.length > 0 ?
                                    <GeoChart data={geographicalIncentiveDataAPI} />
                                    :
                                    <Typography variant='h4'>
                                        No Data
                                    </Typography>
                                }
                            </Grid>}

                        {groupBy === 'contract_number' &&
                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
                                    color="primary"
                                >
                                    Contract Number
                                </Typography>

                                {geographicalIncentiveDataAPI && geographicalIncentiveDataAPI.length > 0 ?
                                    <GeoChart data={geographicalIncentiveDataAPI} />
                                    :
                                    <Typography variant='h4'>
                                        No Data
                                    </Typography>
                                }
                            </Grid>}

                        {groupBy === 'customer_number' &&
                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
                                    color="primary"
                                >
                                    Customer Number
                                </Typography>

                                {geographicalIncentiveDataAPI && geographicalIncentiveDataAPI.length > 0 ?
                                    <GeoChart data={geographicalIncentiveDataAPI} />
                                    :
                                    <Typography variant='h4'>
                                        No Data
                                    </Typography>
                                }
                            </Grid>}

                        {groupBy === 'material_number' &&
                            <Grid
                                item
                                md={12}
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    variant="h3"
                                    style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}
                                    color="primary"
                                >
                                    Material Number
                                </Typography>

                                {geographicalIncentiveDataAPI && geographicalIncentiveDataAPI.length > 0 ?
                                    <GeoChart data={geographicalIncentiveDataAPI} />
                                    :
                                    <Typography variant='h4'>
                                        No Data
                                    </Typography>
                                }
                            </Grid>}

                    </Grid>
                </Grid>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        geographicalIncentiveData: state.initialData.georgraphicalIncentive,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname
    }
};

const mapDispatchToProps = dispatch => {
    return {
        geographicalIncentive: (s, e, postingType, applicationType, sourceDataType, groupBy) => dispatch(geographicalIncentive(s, e, postingType, applicationType, sourceDataType, groupBy)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics"))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeographicalChartUS);