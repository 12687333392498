import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem,
    TextField, OutlinedInput,
    Button,
} from '@mui/material';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { getCalculationSummaryData, getDefaultValuesFinancialSummaryReports, getDefaultValues, getDefaultValuesAllData, allowedApps, getDeleteCalculationSimulationData } from '../../../redux/actions';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
// import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ChipInput } from '../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    container: {
        paddingTop: 18
    },
    calendarLeftGridContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        padding: '0px 10px 0px 10px'
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    multiSelect: {
        marginTop: 10,
        height: 36
    },
    textInput: {
        marginTop: 10
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    textInputHeight: {
        height: 17
    },
    error: {
        border: '1px solid red !important'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30,
    },
    button: {
        marginRight: 25,
        width: 140
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
}));

const DeleteSimulationTab = props => {
    const classes = useStyles();
    const { className } = props;
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application') != 'Audit Lab' ? sessionStorage.getItem('application') : '');
    const [contractSubType, setContractSubType] = React.useState([]);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState([]);
    const [incentiveBasis, setIncentiveBasis] = React.useState([]);
    const [incentiveRate, setIncentiveRate] = React.useState([]);
    const [incentiveAmount, setIncentiveAmount] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [contractNumberRange, setContractNumberRange] = React.useState('');
    const [contractNumberKey, setContractNumberKey] = React.useState('');
    const [disableContractNumberRange, setDisableContractNumberRange] = React.useState(false);
    const [allowedApps, setAllowedApps] = React.useState([]);

    useEffect(() => {
        props.allowedApps();
    }, [])
    useEffect(() => {
        props.onLoadingDefault();
        props.onLoadingDefaultDropdown();
        props.getDefaultValuesAllData();
    }, []);
    useEffect(() => {
        props.onLoadingDefaultDropdown(applicationType);
    }, [applicationType]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            localStorage.setItem('appType', props.allowedAppsData[0])
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {
            props.formFieldsAllData.records.map((item) => {
                if (item.field_id === 'contract_type') {
                    setContractTypeArray(item);
                }
            })
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.dropdownData2 && props.dropdownData2.records && props.dropdownData2.records.length > 0) {
            props.dropdownData2.records.map((item) => {
                if (item.field_id === 'contract_type') {
                    setContractTypeArray(item);
                }
            })
        }
    }, [props.dropdownData2])

    const handleContractNumberMultiple = (newValue) => {
        setContractNumberMultiple(newValue);
        if (contractNumberMultiple.length > 0) {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(true);
            setContractNumberRange('');
        } else {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(false);
        }
    };
    const handleDeleteContractNumberMultiple = (value, index) => {
        var deletedContractNumber = contractNumberMultiple.filter(item => item !== value)
        setContractNumberMultiple(deletedContractNumber)
        if (contractNumberMultiple.length === 2)
            setDisableContractNumberRange(false);
    }
    const handleContractNumberRange = (event) => {
        setContractNumberRange(event.target.value);
        if (event.target.value) {
            setContractNumberKey('contract_number.range');
        } else {
            setContractNumberKey('contract_number.in');
        }
    }
    const handleContractSubType = (event) => {
        setContractSubType(event.target.value);
    }
    function handleCalculationSimulationNumber(newValue) {
        setCalculationSimulationNumber(newValue)
    }
    function handleCalculationSimulationNumberDelete(newValue) {
        var deletedCalculationSimulationNumber = calculationSimulationNumber.filter(item => item !== newValue)
        setCalculationSimulationNumber(deletedCalculationSimulationNumber)
    }
    const onSubmitHandler = () => {
        var formData = {
            [contractNumberKey]: contractNumberRange ? contractNumberMultiple.toString() + ',' + contractNumberRange : contractNumberMultiple.toString(),
            "application_type": applicationType,
            "contract_type.in": contractSubType.toString(),
            "start_date": calculationStartDate ? Moment(calculationStartDate).toISOString().split('T')[0].concat('T00:00:00.000Z') : '',
            "end_date": calculationEndDate ? Moment(calculationEndDate).toISOString().split('T')[0].concat('T23:59:59.000Z') : '',
            "calculation_simulation_number.in": calculationSimulationNumber.toString(),
        };
        props.onSubmit(formData);
        onCancelHandler();
        localStorage.setItem('search-cal-sum', JSON.stringify(formData))
    }
    const onCancelHandler = () => {
        setContractNumberMultiple([]);
        setContractNumberRange('');
        setApplicationType('');
        setContractSubType([]);
        setCalculationStartDate(null);
        setCalculationEndDate(null);
        setCalculationSimulationNumber([]);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary'> Delete Calculation Simulation Data </Typography>
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            {contractTypeArray && contractTypeArray.name &&
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>

                                        <FormLabel classes={{ root: classes.fontSetting }} >{contractTypeArray.name}</FormLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={contractSubType}
                                            input={<OutlinedInput />}
                                            onChange={handleContractSubType}
                                            className={classes.multiSelect}
                                        >
                                            {contractTypeArray.drop_down_values.map((name) => (
                                                <MenuItem key={name} value={name} >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 5 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Contract Number</FormLabel>
                                    <div style={{ marginTop: 10 }}>
                                        <ChipInput
                                            data={contractNumberMultiple}
                                            onChange={newValue => handleContractNumberMultiple(newValue)}
                                        />
                                        {/* <ChipInput
                                            classes={{
                                                root: classes.rootContainer,
                                                chip: classes.chip,
                                                input: classes.input,
                                                inputRoot: classes.inputRoot,
                                                chipContainer: classes.chipContainer,
                                                label: classes.chipLabel
                                            }}
                                            value={contractNumberMultiple}
                                            onAdd={(chips) => handleContractNumberMultiple(chips)}
                                            onDelete={(chip, index) => handleDeleteContractNumberMultiple(chip, index)}
                                            disableUnderline={true}
                                            className={clsx({
                                                [classes.textInput]: true,
                                                [classes.error]: contractNumberMultiple.length === 0 && contractNumberRange
                                            })}
                                            style={{ width: '400px' }}
                                        /> */}
                                        {/* <TextField
                                            className={clsx({
                                                [classes.textInput]: true,
                                            })}
                                            InputProps={{ classes: { input: classes.textInputHeight } }}
                                            variant="outlined"
                                            value={contractNumberRange}
                                            onChange={handleContractNumberRange}
                                            disabled={disableContractNumberRange}
                                            style={{ marginLeft: 5 }}
                                            required
                                        /> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Start Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        height: 36,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationStartDate}
                                                onChange={date => setCalculationStartDate(date)}
                                                renderInput={(params) => <TextField {...params} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >   <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >End Date</FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        height: 36,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationEndDate}
                                                onChange={date => setCalculationEndDate(date)}
                                                renderInput={(params) => <TextField {...params} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Calculation Simulation Number</FormLabel>
                                    <div style={{ marginTop: 10 }}>
                                        <ChipInput
                                            data={calculationSimulationNumber}
                                            onChange={newValue => handleCalculationSimulationNumber(newValue)}
                                        />
                                        {/* <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={calculationSimulationNumber}
                                        onAdd={(chips) => handleCalculationSimulationNumber(chips)}
                                        onDelete={(chip) => handleCalculationSimulationNumberDelete(chip)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    /> */}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonRoot}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={onSubmitHandler} >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>

            </div >
        </LoadingOverlay >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        allowedAppsData: state.initialData.allowedApps,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        // onSubmit: (pagination, limit, data) => dispatch(getCalculationSummaryData(pagination, limit, data)),
        onSubmit: (data) => dispatch(getDeleteCalculationSimulationData(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesFinancialSummaryReports()),
        onLoadingDefaultDropdown: (applicationType) => dispatch(getDefaultValues(applicationType, 'Direct - Sales Data', null, null, 'Display Designer')),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        allowedApps: () => dispatch(allowedApps())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSimulationTab);