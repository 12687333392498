import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getOffInvoiceCostFormatData,
    deleteOffInvoiceCostFormatData,
    uploadOffInvoiceCostFile,
} from "../../redux/actions";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialogSucessMsg:{
        color:'green',
        marginBottom:20
    },
    errorlOutlineColor:{
        color:'#FF1A1A',
        marginRight:5   
    }
}));
const OffInvoiceUpload = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [productGroup,setProductGroup]= useState([]);
    const [errorList, setErrorList] = React.useState([])
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)
    const [open,setOpen]= useState(false)
        useEffect(() => {
       if(props.OffInvoiceCostFormatData){
           setProductGroup(props.OffInvoiceCostFormatData)
       }
    }, [props.OffInvoiceCostFormatData]);
    const handleDrop = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadOffInvoiceCostFile(currentEditableID, data);
        setOpen(true)
    }
    useEffect(() => {
        props.getOffInvoiceCostFormatData();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if(props.competitorDataErrorList){
            setErrorList(props.OffInvoiceCostErrorList)
            if(Object.entries(props.OffInvoiceCostErrorList).length>0&& props.OffInvoiceCostErrorList.error_list!=null && open)
             setErrorDialogOpen(true)
            }
    }, [props.OffInvoiceCostErrorList])
    //dynamic query functions
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('offinvoice-cost', JSON.stringify(item))
        history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        localStorage.setItem('offinvoice-cost-query', JSON.stringify(item))
        history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteOffInvoiceCostFormatData(item.id);
    }
    function handleError() {
        setErrorDialogOpen(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2">
                    OffInvoice Cost Excel Upload</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlinedIcon />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/off-Invoice-cost/off-Invoice-cost-excel-upload/add')}>
                    {isDesktop ? 'Add Format' : ''}
                </Button>
            </div>
            {props.OffInvoiceCostFormatData && props.OffInvoiceCostFormatData.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'>Format ID</TableCell>
                                <TableCell align='center'>Format</TableCell>
                                <TableCell align='center'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.OffInvoiceCostFormatData
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                            <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell> :
                                                <TableCell align='center' width={300}>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => editQuery(item)}
                                                        size="large">
                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true}/>
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => viewQuery(item)}
                                                        size="large">
                                                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true}/>
                                                    </IconButton>
                                                    <input type='file'
                                                        accept=".xlsx, .xls, .csv"
                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                        onChange={(e) => handleDrop(e)} />
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => runQuery(item)}
                                                        size="large">
                                                        <PublishIcon color="disabled" style={{ fontSize: 20 }} disabled={true}/>
                                                    </IconButton>
                                                    <IconButton
                                                        classes={{ root: classes.IconButton }}
                                                        onClick={() => deleteQuery(item)}
                                                        size="large">
                                                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true}/>
                                                    </IconButton>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer> : <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }
            <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={errorDialogOpen} >   
                    <DialogActions style={{position:'absolute',marginLeft:320}}>
                            <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                    </DialogActions>
                    <DialogTitle >File Upload Summary  -  <span style={{color:'blue'}}>{errorList.file_name}</span></DialogTitle>
                    <DialogContent classes={{
                        root: { padding: '43px 37px 0px 37px !important' }
                    }}>  
                        <div classes={{root:classes.dialogSucessMsg}}>{errorList.success_msg}</div>
                        <span style={{color:'grey'}}>Error Details</span>
                        <List>
                            {errorList && errorList.error_list &&errorList.error_list.map((item, index) => {
                                return (<ListItem>
                                    <ErrorOutlineIcon classes={{root:classes.errorlOutlineColor}}/> 
                                    <ListItemText style={{color:'#FF1A1A'}}
                                        primary={item} />
                                </ListItem>
                                )
                            })}
                        </List>
                    </DialogContent>
            </Dialog>
        </div >
    );};
const mapStateToProps = state => {
    return {
        OffInvoiceCostFormatData: state.dataSetupData.offInvoiceCostFormatData,
        loading: state.dataSetupData.loading,
        OffInvoiceCostErrorList:state.dataSetupData.offInvoiceCostErrorList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        uploadOffInvoiceCostFile: (id, file) => dispatch(uploadOffInvoiceCostFile(id, file)),
        deleteOffInvoiceCostFormatData: (id) =>dispatch(deleteOffInvoiceCostFormatData(id)),
        getOffInvoiceCostFormatData: () => dispatch(getOffInvoiceCostFormatData()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OffInvoiceUpload);