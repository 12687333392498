import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Typography,
  useMediaQuery,
  TableCell,
  TableRow,
  TableFooter,
  Paper,
  Fab,
} from "@mui/material";
import { SearchInput } from "../../components";
import { connect } from "react-redux";
import {
  getOrgDefaults,
  downloadDynamicQuery,
  getDefaultValuesAllDataNew,
  getDynamicQuery,
  runDynamicQuery,
  getDynamicQueryFieldsNew
} from "../../redux/actions";
import Moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import { PDFExport } from "@progress/kendo-react-pdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import { toast } from "react-toastify";
import { Zoom } from "react-toastify";
import { tableIcons } from "../../components/Icons/TableIcons";
import MaterialTable, { MTableBody } from "material-table";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Resize,
  ExcelExport,
  Toolbar,
  PdfExport,
  Search,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { history } from "../../components/Helpers";
import { filter } from "d3";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "10px 10px 0px 10px",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  tableCell: {
    paddingLeft: 18,
  },
  buttonContainer: {
    display: "flex",
  },
  button: {
    marginLeft: 15,
  },
  sumContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  cell: {
    backgroundColor: "white !important",
    background: "white !important",
    color: "black",
  },
  fabContainer: {
    marginRight: 5,
    padding: 3,
    border: "2px solid",
    backgroundColor: "#ffff",
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize,
  },
  tableTop: {
    paddingTop: "0px",
    marginTop: 0,
    height: 0,
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.table.row,
      height: 46,
    },
    "&:nth-of-type(even)": {
      height: 40,
    },
  },
}))(TableRow);

const DynamicQueryResults = props => {
  const classes = useStyles();
  const { className } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  var numeric = []
  var dateField = []
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [tableHeaders, setTableHeaders] = React.useState([]);
  const [sortedTableHeader, setSortedTableHeader] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataRows, setDataRows] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [filters, setFilters] = React.useState({})
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSheetDownload = (type) => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
    var data = {
      "id": appId,
    }
    if (filters && Object.entries(filters).length > 0)
      data["filter_fields"] = filters
    // if (on === 'switch') {
    //   props.runDynamicQuery(data, appId, 'dq');
    //   history.push('/dynamic-query/dynamic-query-form-sf/results/' + appId)
    // } else {

    if (appId)
      props.downloadDynamicQuery(data, type);
    //}
  }
  useEffect(() => {
    if (
      props.queryResultsFinalData &&
      props.queryResultsFinalData[1] &&
      props.queryResultsFinalData[1].app_type &&
      props.queryResultsFinalData[1].data_source_type
    )
      props.onLoadingLabelDesignerNew(
        "field-label-designer",
        props.queryResultsFinalData[1].app_type,
        props.queryResultsFinalData[1].data_source_type
      );
  }, []);

  useEffect(() => {
    // props.getDynamicQueryFields()
    props.getOrgDefaults();
    props.getDynamicQuery(props.match.params.id)
    if (localStorage.getItem('dyamicQueryDownloadData')) {
      var filter = JSON.parse(localStorage.getItem('dyamicQueryDownloadData'))
      if (filter.filter_fields) {
        setFilters(filter.filter_fields)
      }
    }
  }, [])
  useEffect(() => {
    var temp = []
    var head = []

    if (props.dynamicQueryFieldsData) {
      if (props.dynamicQueryFieldsData.numeric_fields)
        numeric = props.dynamicQueryFieldsData.numeric_fields.map((item, i) => item.key)
      if (props.dynamicQueryFieldsData.date_fields) {
        dateField = props.dynamicQueryFieldsData.date_fields.map((item, i) => item.key)
      }
    }
    if (props.queryResultsFinalData && props.queryResultsFinalData.length > 0) {
      setCount(props.queryResultsFinalData[0].length - 1)

      // var singleValue = props.queryResultsFinalData[0][0];
      // setTableHeaders(Object.keys(singleValue));
      if (props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1] &&
        props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields &&
        props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields.length > 0) {
        props.queryResultsFinalData[(props.queryResultsFinalData.length) - 1].sort_fields
          .map(item => {
            head.push(item.key)
            temp.push({
              field: item.key,
              title: item.label,
              render: (rowData) => (
                <div>
                  {
                    rowData[item.key] ?
                      numeric && numeric.includes(item.key) && item.key != 'contract_number' ?
                        parseFloat(rowData[item.key]).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) :
                        dateField && dateField.includes(item.key) && Moment(rowData[item.key], true).isValid() ?
                          Moment.utc(rowData[item.key]).format('MM/DD/YYYY')
                          :
                          rowData[item.key]
                      : ""}
                </div>
              ),
            });
          });
        setColumns(temp);
        setSortedTableHeader(head);
      }
      setDataRows(
        props.queryResultsFinalData[0].filter((item, i) => !item.sortOrder)
      );
    }
  }, [props.queryResultsFinalData, props.dynamicQueryFieldsData]);


  //pdf download
  const pdfExportComponent = React.useRef(null);
  const ref = React.createRef();
  const handlePDFDownload = () => {
    toast.info("Download will start soon.", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      transition: Zoom,
    });
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const [sumField, setSumField] = React.useState([]);

  useEffect(() => {
    var temp = [];
    if (props.queryResultsFinalData) {
      var sum = props.queryResultsFinalData.map((e) => {
        if (e.total_sum) {
          //temp.push(e.total_sum)
          setSumField(e.total_sum);
        }
      });
      // setSumField(temp);
    }
  }, [props.queryResultsFinalData]);

  return (
    <div>
      <div className={clsx(classes.root, className)}>
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color="primary" style={{ padding: 10 }}>
            {" "}
            Query Result

          </Typography>
          <div className={classes.buttonContainer}>
            <div className={classes.buttonContainer}>
              {/* <SearchInput /> */}

              <Fab
                aria-label="edit"
                variant="extended"
                size="medium"
                classes={{ root: classes.fabContainer }}
                className={classes.fabContainer}
                onClick={() => handleSheetDownload('excel')}
                style={{
                  marginLeft: 10,
                }}
              >
                <GetAppIcon color="primary" />
                <Typography color="primary" style={{ marginLeft: 3 }}>
                  Excel
                </Typography>
              </Fab>
              {//props.orgDefaults &&
                //Object.keys(props.orgDefaults).length > 0 && (
                <Fab
                  aria-label="edit"
                  variant="extended"
                  size="medium"
                  classes={{ root: classes.fabContainer }}
                  className={classes.fabContainer}
                  onClick={() => handleSheetDownload('pdf')}
                  style={{ marginLeft: 10 }}
                >
                  <GetAppIcon color="primary" />
                  <Typography color="primary" style={{ marginLeft: 3 }}>
                    PDF
                  </Typography>
                </Fab>
                // )
              }
            </div>
          </div>
        </div>

        {sortedTableHeader &&
          sortedTableHeader.length > 0 &&
          props.queryResultsFinalData &&
          props.queryResultsFinalData[0] &&
          props.queryResultsFinalData[0].length > 0 && (
            <Paper className={classes.root} style={{ paddingTop: 0 }}>
              <div style={{ margin: 0 }}>
                <MaterialTable
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          height: "0px",
                        }}
                      ></div>
                    ),
                    Body: (props) => (
                      <>
                        <MTableBody
                          {...props}
                          classes={{ root: classes.tableTop }}
                        />
                        <TableFooter>
                          <TableRow className={classes.sumContainer}>
                            {sortedTableHeader &&
                              sortedTableHeader.length > 0 &&
                              sortedTableHeader.map((sh) => {
                                if (sumField[sh]) {
                                  return (
                                    <TableCell
                                      classes={{ root: classes.fontSetting }}
                                      style={{
                                        color: "#E0F2F7",
                                        border: "1px solid #445AC7",
                                      }}
                                    >
                                      {sumField[sh]
                                        ? parseFloat(
                                          sumField[sh]
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                        : ""}
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      classes={{ root: classes.fontSetting }}
                                      style={{ border: "1px solid #445AC7" }}
                                    ></TableCell>
                                  );
                                }
                              })}
                          </TableRow>
                        </TableFooter>
                      </>
                    ),
                  }}
                  title={" "}
                  editable={true}
                  icons={tableIcons}
                  columns={columns}
                  data={dataRows}
                  options={{
                    search: false,
                    filtering: true,
                    pageSize: rowsPerPage,
                    count: count,
                    page: page,
                    headerStyle: theme.mixins.MaterialHeader,
                    cellStyle: theme.mixins.MaterialCell,
                    pageSize: 15,
                    pageSizeOptions: [
                      15,
                      50,
                      { value: dataRows.length, label: "Show all" },
                    ],
                  }}
                />

              </div>
            </Paper>
          )}
        {/* hidden content for PDF */}
        {/* {sortedTableHeader &&
          sortedTableHeader.length > 0 &&
          props.queryResultsFinalData &&
          props.queryResultsFinalData[0] &&
          props.queryResultsFinalData[0].length > 0 && (
            <div
              style={{
                position: "absolute",
                left: "-100000000000px",
                top: "-100000000000px",
              }}
            >
              <PDFExport
                margin="2cm"
                ref={pdfExportComponent}
                fileName={`${props.queryListData.format_name} ${new Date().getFullYear() +
                  "" +
                  (new Date().getMonth() + 1) +
                  "" +
                  new Date().getDate() +
                  "" +
                  new Date().getHours() +
                  "" +
                  new Date().getMinutes() +
                  "" +
                  new Date().getSeconds()
                  }`}
              >
                <Paper className={classes.root}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={require("../../library/images/finalWhite.jpg")}
                      alt=""
                      width={100}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        <div style={{ padding: "5px 0px 5px" }}>Address</div>
                      </div>
                      <div style={{ width: 200, marginLeft: 20 }}>
                        <div style={{ padding: "5px 0px 5px" }}>
                          {props.orgDefaults && props.orgDefaults.address
                            ? props.orgDefaults.address
                            : "-Please define address from settings-"}
                        </div>
                      </div>
                    </div>
                    <h3 style={{ color: "#4C657D" }}>
                      {props.orgDefaults && props.orgDefaults.headerText
                        ? props.orgDefaults.headerText
                        : "-Please define header from settings-"}
                    </h3>
                  </div>
                  <div></div>
                </Paper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: 15,
                  }}
                >
                  <h4>
                    {props.orgDefaults && props.orgDefaults.footerText
                      ? props.orgDefaults.footerText
                      : "-Please define footer from settings-"}
                  </h4>
                </div>
                
              </PDFExport>
            </div>
          )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    queryResultsFinalData: state.operationalReportsData.queryResultsFinalData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    orgDefaults: state.addMultipleConfigurationData.orgDefaults,
    queryListData: state.operationalReportsData.dynamicQueryDetailsData,
    dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadDynamicQuery: (data, type) => dispatch(downloadDynamicQuery(data, type)),
    onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
      dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    getOrgDefaults: () => dispatch(getOrgDefaults()),
    getDynamicQuery: (id) => dispatch(getDynamicQuery(id)),
    runDynamicQuery: (data) => dispatch(runDynamicQuery(data, null, "dq")),
    getDynamicQueryFields: (applicationType, tableDesc) =>
      dispatch(getDynamicQueryFieldsNew(applicationType, tableDesc)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicQueryResults);
