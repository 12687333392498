import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card, CardHeader, Grid, OutlinedInput, FormLabel, TextField, Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { TextInputWithLabel, DropdownArray, AllOtherAttributeMasterComponent, SearchDropdown, DropdownKeyValue } from '../../../../../components/Inputs';
import { connect } from 'react-redux';
import { getContractDetailsData, getEmployeeData, getSupplierMasterData, getCustomerMasterData } from '../../../../../redux/actions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        //borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        // padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 36,
        paddingTop: 15
    },
    inputTwoLineOutlined: {
        //marginTop: 7,
        width: '100%',
        paddingLeft: 10,
        height: 36,
    },
    borderRad0: {
        borderRadius: '0px !important',
        padding: '3px 6px !important'

    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));
const Payment = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    const [paymentFrequencyData, setPaymentFrequencyData] = React.useState([]);
    const [paymentTermsData, setPaymentTermsData] = React.useState([]);
    const [paymentFrequencyName, setPaymentFrequencyName] = React.useState('');
    const [paymentLevelData, setPaymentLevelData] = React.useState([]);
    const [paymentLevelName, setPaymentLevelName] = React.useState('');
    const [paymentMethodData, setPaymentMethodData] = React.useState([]);
    const [paymentMethodName, setPaymentMethodName] = React.useState('');
    const [paymentPartnerRoleData, setPaymentPartnerRoleData] = React.useState([]);
    const [paymentPartnerRoleName, setPaymentPartnerRoleName] = React.useState('');
    const [paymentCalendarArray, setPaymentCalendarArray] = React.useState([]);
    const [paymentTermsArray, setPaymentTermsArray] = React.useState([]);
    const [paymentPartnerArray, setPaymentPartnerArray] = React.useState([]);
    const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    const [externalReference, setExternalReference] = React.useState('');
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [paymentAggregationLevel, setPaymentAggregationLevel] = React.useState('');
    const [paymentAggregationLevelArray, setPaymentAggregationLevelArray] = React.useState([]);
    const [paymentAggregationLevelData, setPaymentAggregationLevelData] = React.useState([]);
    const [customerNameArray, setCustomerNameArray] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [passThroughArray, setPassThroughArray] = React.useState([]);
    const [passThroughPaymentPartnerRoleArray, setPassThroughPaymentPartnerRoleArray] = React.useState([]);
    const [passThroughPercentageArray, setPassThroughPercentageArray] = React.useState([]);
    const [passThrough, setPassThrough] = React.useState('');
    const [passThroughPercentage, setPassThroughPercentage] = React.useState('');
    const [passThroughPaymentPartnerRole, setPassThroughPaymentPartnerRole] = React.useState('');
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const [paymentDueDate, setPaymentDueDate] = React.useState('');
    const [paymentDueDateName, setPaymentDueDateName] = React.useState('');
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })

    }
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.employeeMasterData])
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setCustomerNameArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.supplierMasterData])

    useEffect(() => {
        setExternalReference(props.externalReference);
    }, [props.externalReference])
    useEffect(() => {
        props.onChange(paymentValues);

    });
    const classes = useStyles();
    const { className } = props;
    const [paymentFrequency, setPaymentFrequency] = React.useState('');
    const [paymentLevel, setPaymentLevel] = React.useState('Single Payment');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [paymentCalendar, setPaymentCalendar] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState('');
    const [paymentPartner, setPaymentPartner] = React.useState('');
    const [flexFields5, setFlexFields5] = React.useState('');
    const [paymentDocumentType, setPaymentDocumentType] = React.useState('');
    const [paymentRun, setPaymentRun] = React.useState('');
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const [listActive, setListActive] = React.useState(false);
    const paymentFrequencyRef = useRef(null);
    const paymentLevelRef = useRef(null);
    const paymentTermsRef = useRef(null);
    const paymentCalenderRef = useRef(null);
    const paymentMethodRef = useRef(null);
    const paymentPartnerRoleRef = useRef(null);
    const paymentPartnerRef = useRef(null);
    var mode = localStorage.getItem('mode');
    var paymentValues = {
        payment_frequency: paymentFrequency,
        payment_level: paymentLevel,
        payment_terms: paymentTerms,
        payment_calendar: paymentCalendar,
        payment_method: paymentMethod,
        payment_partner_role: paymentPartnerRole,
        payment_partner: paymentLevel === 'Multiple Payments' ? '' : paymentPartner,
        external_reference: externalReference,
        flex_fields_5: flexFields5,
        payment_document_type: paymentDocumentType,
        payment_run: paymentRun,
        payment_aggregation_level: paymentAggregationLevel,
        pass_through: passThrough,
        pass_through_percentage: parseInt(passThroughPercentage),
        pass_through_payment_partner_role: passThroughPaymentPartnerRole,
        payment_due_date: paymentDueDate ? parseInt(paymentDueDate) : 0
    };
    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (mode === 'edit')
                props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!paymentFrequencyRef.current.value || paymentFrequencyRef.current.value === '')
                setPaymentFrequency(false);
            if (!paymentLevelRef.current.value || paymentLevelRef.current.value === '')
                setPaymentLevel(false);
            if (!paymentTermsRef.current.value || paymentTermsRef.current.value === '')
                setPaymentTerms(false);
            if (!paymentCalenderRef.current.value || paymentCalenderRef.current.value === '')
                setPaymentCalendar(false);
            if (!paymentMethodRef.current.value || paymentMethodRef.current.value === '')
                setPaymentMethod(false);
            if (!paymentPartnerRoleRef.current.value || paymentPartnerRoleRef.current.value === '')
                setPaymentPartnerRole(false);
            if (!paymentPartnerRef.current.value || paymentPartnerRef.current.value === '')
                setPaymentPartner(false);
        },
        getValues() {
            paymentValues = {
                payment_frequency: paymentFrequencyRef.current.value,
                payment_level: paymentLevelRef.current.value,
                payment_terms: paymentTermsRef.current.value,
                payment_calendar: paymentCalenderRef.current.value,
                payment_method: paymentMethodRef.current.value,
                payment_partner_role: paymentPartnerRoleRef.current.value,
                payment_partner: paymentPartnerRef.current.value
            };
            return paymentValues;
        }
    }));
    useEffect(() => {
        if (props.activeDropdown)
            setActiveDropdown(props.activeDropdown)
    }, [props.activeDropdown])
    function handlePaymentFrequency(e) {
        setPaymentFrequency(e);
    }
    function handlePaymentLevel(newValue) {
        setPaymentLevel(newValue.target.value);
        setPaymentPartnerRole('');
        setPaymentPartner('');
    }
    function handlePaymentTerms(e) {
        setPaymentTerms(e);
    }
    function handlePaymentCalendar(newValue) {
        setPaymentCalendar(newValue);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
    }
    function handlePaymentPartnerRole(newValue) {
        setPaymentPartner('');
        setCustomerNameArray([])
        setPaymentPartnerRole(newValue);
    }
    //new fields
    function handleExternalReference(e) {
        setExternalReference(e.target.value);
    }
    function handleFlexFields5(newValue) {
        setFlexFields5(newValue);
    }
    function handlePaymentDocumentType(newValue) {
        setPaymentDocumentType(newValue);
    }
    function handlePaymentRun(newValue) {
        setPaymentRun(newValue);
    }
    function handlePaymentDueDate(newValue) {
        setPaymentDueDate(newValue);
        // props.onChange('payment_due_date', newValue);
    }
    //handledefault
    useEffect(() => {
        if (props.contractDefaultData) {
            setPaymentFrequency(props.contractDefaultData.payment_frequency);
            setPaymentCalendar(props.contractDefaultData.payment_calendar);
            setPaymentMethod(props.contractDefaultData.payment_method);
            setPaymentPartnerRole(props.contractDefaultData.payment_partner_role);
            setPaymentTerms(props.contractDefaultData.payment_terms);
            if (props.contractDefaultData && props.contractDefaultData.payment_partner_role && activeDropdown) {
                if (props.contractDefaultData && props.contractDefaultData.payment_partner_role
                    && props.contractDefaultData.payment_partner_role === 'Supplier' && activeDropdown['supplier_number']) {
                    props.getSupplierMasterData();
                } else if (props.contractDefaultData && props.contractDefaultData.payment_partner_role && props.contractDefaultData.payment_partner_role === 'Employee'
                    && activeDropdown['employee_number']) {
                    props.getEmployeeData();
                } else {
                    if (activeDropdown['customer_number'])
                        props.getCustomerMasterData();
                }
            }

        } else {
            setPaymentFrequency('');
            setPaymentCalendar('');
            setPaymentMethod('');
            setPaymentPartnerRole('');
            setPaymentTerms('');
        }

    }, [props.contractDefaultData]);
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNameArray.indexOf(option)] + option
    });

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-2rem' }}
                >
                    <CardHeader
                        title="PAYMENT"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['payment_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel required>
                                        {props.fieldData['payment_level']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={paymentLevel}
                                        onChange={handlePaymentLevel}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ marginTop: "5px" }}
                                    >
                                        {props.fieldData['payment_level']['drop_down_reference_value'] && props.fieldData['payment_level']['drop_down_reference_value']
                                            .map((item) => {
                                                return (
                                                    <MenuItem
                                                        value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.key}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                            }
                            {!(paymentLevel === 'Single Payment') && props.fieldData && props.fieldData['payment_aggregation_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_aggregation_level']['current']} data={props.fieldData['payment_aggregation_level']['drop_down_reference_value']}
                                        onChange={(e) => setPaymentAggregationLevel(e)} required twoline="true" placeholder={' '} labelFormat={'desc'}
                                    />
                                </Grid>
                            }
                            {!(paymentLevel === 'Multiple Payments') && props.fieldData && props.fieldData['payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_partner_role']['current']} data={props.fieldData['payment_partner_role']['drop_down_reference_value']} onChange={handlePaymentPartnerRole}
                                        required twoline="true" placeholder={' '} error={typeof paymentPartnerRole === 'boolean' && !paymentPartnerRole ? true : false} ref={paymentPartnerRoleRef}
                                        prevalue={paymentPartnerRole}
                                        labelFormat={'desc'}
                                    />
                                </Grid>
                            }
                            {paymentPartnerRole && !(paymentLevel === 'Multiple Payments') && props.fieldData && props.fieldData['payment_partner'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel required style={{ marginTop: -2 }}>
                                        {props.fieldData['payment_partner']['current']}
                                    </FormLabel>
                                    <div style={{ marginTop: '-10px' }}>
                                        <SearchDropdown id='payment_single'
                                            table={paymentPartnerRole == 'supplier' ? 'supplier_master' : paymentPartnerRole == 'employee' ? 'employee_master' : 'customer_master'}
                                            keyName={paymentPartnerRole == 'supplier' ? 'supplier_number' : paymentPartnerRole == 'employee' ? 'employee_number' : 'customer_number'}
                                            description={true}
                                            onChange={(value) => setPaymentPartner(value)}
                                        />
                                    </div>

                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_frequency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div>
                                        <DropdownKeyValue heading={props.fieldData['payment_frequency']['current']} data={props.fieldData['payment_frequency']['drop_down_reference_value']}
                                            onChange={handlePaymentFrequency} required twoline="true" placeholder={' '} labelFormat={'desc'}
                                            prevalue={props.contractDefaultData && props.contractDefaultData.payment_frequency ? props.contractDefaultData.payment_frequency : ''}
                                        />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_calendar'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray heading={props.fieldData['payment_calendar']['current']} data={props.fieldData['payment_calendar']['drop_down_values']}
                                        twoline='true' onChange={handlePaymentCalendar}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.payment_calendar ? props.contractDefaultData.payment_calendar : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_method'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['payment_method']['current']} data={props.fieldData['payment_method']['drop_down_reference_value']}
                                        onChange={handlePaymentMethod} required placeholder={' '} twoline='true' error={typeof paymentMethod === 'boolean' && !paymentMethod ? true : false} ref={paymentMethodRef}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.payment_method ? props.contractDefaultData.payment_method : ''} labelFormat={'desc'}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_terms'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{
                                        padding: '15px 0px 5px 0px',
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column'
                                    }}>
                                        <FormLabel required >
                                            {props.fieldData['payment_terms']['current']}
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <AllOtherAttributeMasterComponent attributeName={'payment_terms'}
                                                value={paymentTerms} onChange={(e) => handlePaymentTerms(e)}
                                                prevalue={paymentTerms} withOutLabel={true} id={'payment_terms'}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_due_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={props.fieldData['payment_due_date']['current']} twoline="true" mandatory
                                        onChange={handlePaymentDueDate} type={'Number'} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_reference'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel>
                                        {props.fieldData['external_reference']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={handleExternalReference}
                                        value={externalReference}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_5'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_5']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields5}
                                                onChange={(e) => setFlexFields5(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_5']['drop_down_reference_value'] && props.fieldData['flex_fields_5']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel heading={props.fieldData['flex_fields_5']['current']} twoline="true" onChange={handleFlexFields5} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['pass_through']['current']}
                                        data={props.fieldData['pass_through']['drop_down_reference_value']} labelFormat={'desc'}
                                        onChange={(e) => setPassThrough(e)} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_percentage'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <FormLabel>
                                        {props.fieldData['pass_through_percentage']['current']}
                                    </FormLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={(e) => setPassThroughPercentage(e.target.value)}
                                        value={passThroughPercentage}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pass_through_payment_partner_role'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['pass_through_payment_partner_role']['current']}
                                        data={props.fieldData['pass_through_payment_partner_role']['drop_down_reference_value']} labelFormat={'desc'}
                                        onChange={(e) => setPassThroughPaymentPartnerRole(e)} twoline="true" placeholder={' '} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_document_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['payment_document_type']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentDocumentType}
                                                onChange={(e) => setPaymentDocumentType(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['payment_document_type']['drop_down_reference_value'] && props.fieldData['payment_document_type']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel heading={props.fieldData['payment_document_type']['current']} twoline="true" onChange={handlePaymentDocumentType} />}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['payment_run'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['payment_run']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={paymentRun}
                                                onChange={(e) => setPaymentRun(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['payment_run']['drop_down_reference_value'] && props.fieldData['payment_run']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel heading={props.fieldData['payment_run']['current']} twoline="true" onChange={handlePaymentRun} />}
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    customerMasterData: state.customerData.customerMasterData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    employeeMasterData: state.customerData.employeeMasterData,
    supplierMasterData: state.customerData.supplierMasterData,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData()),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Payment);