import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ContractCell } from "./components/ContractCell";
import { Grid } from "@mui/material";
import NewContracts from "./components/NewContracts";
import MonthlyIncentiveGraph from "./components/MonthlyIncentiveGraph";
import ActiveContractsGraph from "./components/ActiveContractsGraph";
import ClaimValueAndErrorGraph from "./components/ClaimValueAndErrorGraph";
import {
  AccrualPaymentClaim,
  ChargebackClaim,
  ClaimCount,
} from "./components/ClaimAnalytics";
import { DBPromoPlan } from "./components/DBPromoPlan";
import { PricingApp } from "./components/PricingApp";
import SalesCommissionDash from "./SalesCommission";
import { connect } from "react-redux";
import {
  getDashboardCount,
  getContractsTableDataList,
  getActiveContractsGraph,
  getMonthlyIncentiveGraph,
  loadPaymentDurationsGraph,
  loadClaimError,
  loadClaimErrorCustomerChargeBack,
  loadClaimAgingCustomerChargeBack,
  loadClaimValueAndError,
  loadClaimCountCCBSCB,
  claimErrorCount, getRebateDashboardData
} from "../../redux/actions";
import Moment from "moment";
import RebateGraph from "./components/RebateGraph";
import { WidthProvider, Responsive } from "react-grid-layout";
import { useHistory } from "react-router-dom";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};
function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}
const useStyles = makeStyles((theme) => ({
  // root: {
  //   backgroundColor: theme.palette.secondary.main,
  // },
  root: {
    backgroundColor: theme.palette.white, //secondary.main,
    marginTop: -20,
    borderRadius: 10,
    padding: "27px 10px 27px 10px",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  newColor: {
    backgroundColor: "#800000",
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  gridContainer: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 6px 20px 0 rgba(0, 0, 0, 0.02)",
    paddingTop: 10,
  },
}));
const Dashboard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [app, setApp] = React.useState(sessionStorage.getItem("application"));
  const [claimValueAndErrorCB, setClaimValueAndErrorCB] = React.useState({});
  const [contractTableData, setContractTableData] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState("");
  const [layouts, setlayouts] = React.useState("");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const onLayoutChange = (layout, layouts) => {
    setlayouts(layouts);
  };

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Contract Stats" && item.dashboard_name === "Default Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      // console.log("test")
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    var application = sessionStorage.getItem("application");
    if (application === "Customer Rebate" && startDate && endDate) {
      // console.log("no")
      props.loadDashboardCount(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'));
    }
  }, [allowedTilesFilter, startDate]);

  useEffect(() => {
    if (
      props.claimValueAndErrorCB &&
      Object.keys(props.claimValueAndErrorCB).length > 0
    ) {
      setClaimValueAndErrorCB(props.claimValueAndErrorCB);
    } else {
      setClaimValueAndErrorCB({});
    }
  }, [props.claimValueAndErrorCB]);
  useEffect(() => {
    if (props.contractTableData) setContractTableData(props.contractTableData);
  }, [props.contractTableData]);
  useEffect(() => {
    var application = sessionStorage.getItem("application");
    if (application != "Profit Optimization") {
      var ed = Moment()
        .subtract(0, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
      var sd = Moment()
        .subtract(5, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      var ed2 = Moment()
        .subtract(0, "months")
        .endOf("month")
        .format("YYYY-MM-DD")
        .concat("T00:00:00.000Z");
      var sd2 = Moment()
        .subtract(5, "months")
        .startOf("month")
        .format("YYYY-MM-DD")
        .concat("T00:00:00.000Z");
      if (application === "Customer Chargeback") {
        var data = {
          start_date: sd2,
          end_date: ed2,
          stat_level: "aggregate",
          application_type: "customer_chargeback",
        };
        props.loadDashboardCount(data, null);
      } else if (application === "Supplier Chargeback") {
        var data = {
          start_date: new Date(new Date().getFullYear(), 0, 2),
          end_date: Moment.utc(
            new Date().getMonth() +
            1 +
            "/" +
            new Date().getDate() +
            "/" +
            new Date().getFullYear()
          ),
          stat_level: "aggregate",
          application_type: "supplier_chargeback",
        };
        props.loadDashboardCount(data, null);
      }
      // else {
      //   props.loadDashboardCount(sd, ed);
      // }
      if (application != "Customer Rebate" && application === "Supplier Chargeback") {
        if (application != "Supplier Rebate")
          props.loadPaymentDurationsGraph(sd, ed);
      }
      // if (
      //   application === "Customer Chargeback" ||
      //   application === "Supplier Chargeback"
      // ) {
      //   props.loadClaimCount(sd, ed);
      //   props.loadClaimValueAndError(sd, ed);
      // }
      if (application === "Supplier Chargeback") {
        props.loadClaimError(sd, ed);
      } else if (application === "Customer Chargeback") {
        props.loadClaimErrorCustomerChargeBack(sd, ed);
        props.loadClaimAgingCustomerChargeBack(sd, ed);
      }
      if (
        application !== "Customer Chargeback" &&
        application !== "Supplier Chargeback"
      ) {
        props.loadContractTable();
        if (
          application !== "Customer Rebate" &&
          application !== "Supplier Rebate"
        ) {
          props.loadActiveContractsGraph();
          props.loadMonthlyIncentiveGraph();
        }
      }
    }
    if (application === "Customer Chargeback") {
      setApplicationType("customer_chargeback");
    } else if (application === "Supplier Chargeback") {
      setApplicationType("supplier_chargeback");
    }
  }, []);
  const handlCell = (type) => {
    if (type === "Rejected") {
      if (app != "Customer Chargeback" && app != "Supplier Chargeback") {
        history.push({ pathname: "/contract-setup", status: type });
      } else {
        var sd = Moment()
          .subtract(10, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        var ed = Moment().add(1, "months").endOf("month").format("YYYY-MM-DD");
        props.claimErrorCount(sd, ed, applicationType);
      }
    }
  };
  const handleContractCell = (type) => {
    if (app != "Customer Chargeback" && app != "Supplier Chargeback") {
      history.push({ pathname: "/contract-setup", status: type });
    }
  };
  return (
    <div className={classes.root}>
      {sessionStorage.getItem("application") === "Pricing" ? (
        "  <PricingApp />"
      ) : sessionStorage.getItem("application") === "Profit Optimization" ? (
        <DBPromoPlan />
      ) : sessionStorage.getItem("application") === "Pricing" ? (
        <PricingApp />
      ) : sessionStorage.getItem("application") === "Sales Commission" ? (
        <SalesCommissionDash />
      ) : (
        <>
          <div style={{ flex: 1, display: "flex" }}>
            {app === "Customer Chargeback" && (
              <div
                style={{ flex: 0.2, padding: 4 }}
                classes={{ root: classes.contractCellContainer }}
              >
                <ContractCell
                  className={classes.newColor}
                  heading={app === "Customer Chargeback" ? "New" : ""}
                  value={
                    app === "Customer Chargeback"
                      ? props.dashboardCountData[0]?.new
                      : 0
                  }
                  onClick={() => handleContractCell("New")}
                />
              </div>
            )}
            <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.activeContracts}
                heading={
                  app === "Customer Chargeback"
                    ? "Submitted"
                    : app === "Supplier Chargeback"
                      ? "Approved"
                      : "Active Contracts"
                }
                value={
                  app === "Customer Chargeback" ||
                    (app === "Supplier Chargeback" &&
                      props.dashboardCountData[0]?.approved)
                    ? props.dashboardCountData[0]?.submitted
                    : props.dashboardCountData?.active_contracts
                      ? props.dashboardCountData.active_contracts
                      : 0
                }
                onClick={() => handleContractCell("Active")}
              />
            </div>
            <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.expiredContracts}
                heading={
                  app === "Customer Chargeback" || app === "Supplier Chargeback"
                    ? "Rejected"
                    : "Expired Contracts"
                }
                value={
                  app === "Customer Chargeback" ||
                    (app === "Supplier Chargeback" &&
                      props.dashboardCountData[0]?.rejected)
                    ? props.dashboardCountData[0]?.rejected
                    : props.dashboardCountData?.expired_contracts
                      ? props.dashboardCountData.expired_contracts
                      : 0
                }
                onClick={() => handlCell("Rejected")}
              />
            </div>

            <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.newContracts}
                heading={
                  app === "Customer Chargeback" || app === "Supplier Chargeback"
                    ? "Adjusted"
                    : "New Contracts"
                }
                value={
                  app === "Customer Chargeback" ||
                    (app === "Supplier Chargeback" &&
                      props.dashboardCountData[0]?.adjusted)
                    ? props.dashboardCountData[0]?.adjusted
                    : props.dashboardCountData?.new_contracts
                      ? props.dashboardCountData.new_contracts
                      : 0
                }
                onClick={() => handleContractCell("New")}
              />
            </div>
            <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.waitingApprovals}
                heading={
                  app === "Customer Chargeback"
                    ? "Approved"
                    : app === "Supplier Chargeback"
                      ? "In Progress"
                      : "Waiting for Approvals"
                }
                value={
                  app === "Customer Chargeback" ||
                    (app === "Supplier Chargeback" &&
                      props.dashboardCountData[0]?.in_progress)
                    ? props.dashboardCountData[0]?.approved
                    : props.dashboardCountData?.waiting_for_approval
                      ? props.dashboardCountData.waiting_for_approval
                      : 0
                }
                onClick={() => handleContractCell("Submit_approval")}
              />
            </div>

            <div
              style={{ flex: 0.2, padding: 4 }}
              classes={{ root: classes.contractCellContainer }}
            >
              <ContractCell
                className={classes.totalContracts}
                heading={
                  app === "Customer Chargeback" || app === "Supplier Chargeback"
                    ? "Total Claim Lines"
                    : "Total Contracts"
                }
                value={
                  app === "Customer Chargeback" ||
                    (app === "Supplier Chargeback" &&
                      props.dashboardCountData[0]?.total)
                    ? props.dashboardCountData[0]?.total
                    : props.dashboardCountData?.total_contracts
                      ? props.dashboardCountData.total_contracts
                      : 0
                }
                onClick={() => handleContractCell("Total_contracts")}
              />
            </div>
          </div>
          <Grid container spacing={2} style={{ height: "75%" }}>
            {sessionStorage.getItem("application") === "Customer Chargeback" ||
              sessionStorage.getItem("application") === "Supplier Chargeback" ? (
              <>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xl={6}
                  xs={12}
                  className={classes.gridContainer}
                >
                  <ChargebackClaim />
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xl={6}
                  xs={12}
                  className={classes.gridContainer}
                >
                  <AccrualPaymentClaim data={sessionStorage.getItem("application")} />
                </Grid>

                {/* </Grid> */}
              </>
            ) : (
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                className={classes.gridContainer}
              >
                <NewContracts
                  data={contractTableData}
                //className={classes.gridContainer}
                />
              </Grid>
            )}
            {/* <Grid container spacing={2} className={classes.container}> */}
            {sessionStorage.getItem("application") === "Customer Rebate" ||
              sessionStorage.getItem("application") === "Supplier Rebate" ? (
              <RebateGraph />
            ) : (
              <>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xl={6}
                  xs={12}
                  className={classes.gridContainer}
                >
                  <MonthlyIncentiveGraph
                    data={props.monthlyIncentiveGraphData}
                    claimError={props.claimError}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xl={6}
                  xs={12}
                  className={classes.gridContainer}
                >
                  <ActiveContractsGraph
                    data={props.activeContractsGraphData}
                    paymentDuration={
                      sessionStorage.getItem("application") ===
                        "Customer Chargeback"
                        ? props.claimAgingCustomerChargeback
                        : props.paymentDuration
                    }
                    claimAging={props.claimAgingCustomerChargeback}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardCountData: state.initialData.dashboardCountData,
    contractTableData: state.addContractData.contractAllListData,
    activeContractsGraphData: state.initialData.activeContractsGraphData,
    monthlyIncentiveGraphData: state.initialData.monthlyIncentiveGraphData,
    paymentDuration: state.initialData.paymentDuration,
    claimError: state.initialData.claimError,
    claimAgingCustomerChargeback:
      state.initialData.claimAgingCustomerChargeback,
    claimValueAndErrorCB: state.initialData.claimValueAndErrorCB,
    claimCountCCBSCB: state.initialData.claimCountCCBSCB,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDashboardCount: (sd, ed) => dispatch(getDashboardCount(sd, ed)),
    loadContractTable: () => dispatch(getContractsTableDataList(0, 20)),
    loadActiveContractsGraph: () => dispatch(getActiveContractsGraph()),
    loadPaymentDurationsGraph: (sd, ed) =>
      dispatch(loadPaymentDurationsGraph(sd, ed)),
    loadClaimError: (sd, ed) => dispatch(loadClaimError(sd, ed)),
    loadClaimErrorCustomerChargeBack: (sd, ed) =>
      dispatch(loadClaimErrorCustomerChargeBack(sd, ed)),
    loadMonthlyIncentiveGraph: () => dispatch(getMonthlyIncentiveGraph()),
    loadClaimAgingCustomerChargeBack: (sd, ed) =>
      dispatch(loadClaimAgingCustomerChargeBack(sd, ed)),
    loadClaimValueAndError: (sd, ed) =>
      dispatch(loadClaimValueAndError(sd, ed)),
    loadClaimCount: (sd, ed) => dispatch(loadClaimCountCCBSCB(sd, ed)),
    claimErrorCount: (sd, ed, applicationType) =>
      dispatch(claimErrorCount(sd, ed, applicationType)),
    getDashboardData: (data) => dispatch(getRebateDashboardData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
