import React, { useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, TextField,
    Card, OutlinedInput, FormLabel, Select, MenuItem, Radio, RadioGroup,
    Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Checkbox
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { addBatchJobSetup, getDefaultValues, getOutgoingClaims, getIntegrationSetupData, allowedApps } from '../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ChipInput from 'material-ui-chip-input';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../../components/Inputs';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider,DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
        marginTop: 20

    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        width:'100%',
        marginBottom: 14
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const AddCCBatchJobSetup = props => {
    const classes = useStyles();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [postingTypeArray, setPostingTypeArray] = React.useState([]);
    const history = useHistory();

    useEffect(() => {
        props.onLoadingDefault(sessionStorage.getItem('application'));
        // props.getIntegrationSetupData();
        props.allowedApps();
    }, []);

    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records.length > 0) {
            var newArray = []
            props.integrationSetupData.records.map((item, index) => {
                newArray.push(item.name)
            })
            setIntegration(newArray)
        }
    }, [props.integrationSetupData])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])


    useEffect(() => {

        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'contract_type') {
                    setContractTypeArray(item)
                }
                if (item.field_id === 'calculation_method') {
                    setCalculationMethodArray(item);
                }
                if (item.field_id === 'accrual_calendar') {
                    setPlannedStartCalendarArray(item)

                }
            })

        }

    }, [props.dropdownData])
    useEffect(() => {
        var tempNameArray = [];
        if (props.outgoingClaimListData) {
            props.outgoingClaimListData.map(item => {
                tempNameArray.push(item.claim_number.toString());
            })
            setClaimNumbersList(tempNameArray);
        }
    }, [props.outgoingClaimListData])

    const [integrationName, setIntegrationName] = React.useState('');
    const [integration, setIntegration] = React.useState([]);
    const [claimNumber, setClaimNumber] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [contractNumber, setContractNumber] = React.useState('');
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [postingType, setPostingType] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [postingDate, setPostingDate] = React.useState(null);
    const [billingStartDate, setBillingStartDate] = React.useState('');
    const [billingEndDate, setBillingEndDate] = React.useState('');
    const [calculationDateType, setCalculationDateType] = React.useState('');
    const [batchJobArray, setBatchJobArray] = React.useState([]);
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [supplierNumberNew, setSupplierNumberNew] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [customerGroup, setCustomerGroup] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [billingDocNumber, setBillingDocNumber] = React.useState('');
    const [billingDocLine, setBillingDocLine] = React.useState('');
    const [splitCriteria, setSplitCriteria] = React.useState(['supplier_number']);
    const [claimNumbersList, setClaimNumbersList] = React.useState([]);
    const [selected, setSelected] = useState([]);
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const accrualType = [
        'Accrual', 'Accrual Reversal'
    ];
    const paymentType = [
        'Payment', 'Payment Reversal'
    ]
    // const BatchJobTypesArray = [
    //     'Accrual Postings', 'Automatic PPA','Calculation Simulation', 'Payment Postings'
    // ];
    useEffect(() => {
        props.getOutgoingClaims();
    }, []);

    useEffect(() => {
        // setCalculationPeriodStart(Moment().subtract(1, 'months').startOf('month'));
        // setCalculationPeriodEnd(Moment().subtract(1, 'months').endOf('month'));
        // setPostingDate(Moment().startOf('month'))
    }, []);
    useEffect(() => {
        if (applicationType == 'Customer Rebate') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Automatic PPA', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Supplier Rebate') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Automatic PPA', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }

        if (applicationType == 'Customer Chargeback') {
            const tempBatchjobArr = [
                'Create Claim', 'Accrual Postings', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Supplier Chargeback') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Royalty') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
        if (applicationType == 'Sales Commission') {
            const tempBatchjobArr = [
                'Accrual Postings', 'Calculation Simulation', 'Payment Postings'
            ];
            setBatchJobArray(tempBatchjobArr)
        }
    }, [applicationType, props.allowedAppsData])

    const frequencyTypesArray = [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    const postingDateArray = [
        'Last Day of Previous Month', 'First Day of Current Month', 'Current Date'
    ]
    const calculationStartDateArray = [
        "Frist Day of Previous Month", "Frist Day of Current Month", "Current Date", "Today", "Previous Day"]
    const calculationEndDateArray = [
        "Last Day of Current Month", "Last Day of Previous Month", "Current Date", "Today", "Previous Day"
    ]
    function handleTagsAdd(newValue) {
        setTags([...tags, newValue]);
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function handleContinue() {
        setOpen(false);

        props.onLoadingDefault(applicationType)
        if (batchJobType === 'Accrual Postings') {
            setPostingTypeArray([...accrualType])
        }
        if (batchJobType === 'Payment Postings') {
            setPostingTypeArray([...paymentType]);
        }

    }

    function handlePostingDate(newValue) {

        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue));
        }
        else {

            setPostingDate(newValue);
        }

    }
    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(Moment(newValue));

        }
        else {
            setCalculationPeriodStart(newValue);
            if (!newValue)
                setCalculationPeriodEnd(null)
        }
    }
    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(Moment(newValue));

        }
        else {
            setCalculationPeriodEnd(newValue);
        }

    }
    function handleClear() {
        setCalculationStartDate(null)
        setCalculationEndDate(null)
        setSupplierNumber('')
        setSupplierNumberNew('')
        setCustomerNumber('')
        setCustomerGroup('')
        setMaterialNumber('')
        setMaterialGroup('')
        setBillingDocNumber('')
        setBillingDocLine('')
        setClaimNumber([]);
        setSelected([])
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setSupplierNumber([])
        setPostingDate(null)
        setTags([])
        setRadioGroupValue('')
    }

    function handleClaimNumber(e, value) {

        let newSelected = value;
        if (value.length > 0) {

            setSelected(newSelected)
            setClaimNumber(newSelected);
        }
        else {
            setSelected([])
            setClaimNumber([]);
        }

    }
    function handleSupplierNumberAdd(newValue) {
        setSupplierNumber([...supplierNumber, newValue]);
    }
    function handleSupplierNumberDelete(newValue) {
        var deletedTags = supplierNumber.filter(item => item !== newValue)
        setSupplierNumber(deletedTags)
    }
    function handleOnSubmit() {
        var batchJobFilter = [{
            "filters": {
                "supplier_number": supplierNumberNew,
                "customer_number": customerNumber,
                "customer_group": customerGroup,
                "material_number": materialNumber,
                "material_group": materialGroup,
                "billing_doc_number": billingDocNumber,
                "billing_doc_line": billingDocLine,
                "calculation_date_type": calculationDateType,
                // "posting_date": postingDate ? (typeof (postingDate) === 'string' ? postingDate : Moment.utc(postingDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
                "billing_start_date": calculationStartDate ? (typeof (calculationStartDate) === 'string' ? calculationStartDate : Moment.utc(calculationStartDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
                "billing_end_date": calculationEndDate ? (typeof (calculationEndDate) === 'string' ? calculationEndDate : Moment.utc(calculationEndDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
                "split_criteria": splitCriteria.toString()
            },
        }]
        var batchJobFilter2 = [
            {
                "filters": {
                    "claim_number": claimNumber,
                    "posting_date": postingDate ? (typeof (postingDate) === 'string' ? postingDate : Moment.utc(postingDate, 'YYYY/MM/DD').local().toISOString().split('T')[0]) : '',
                    "calculation_start_date": calculationStartDate ? (typeof (calculationStartDate) === 'string' ? calculationStartDate : Moment.utc(calculationStartDate, 'YYYY/MM/DD').local().toISOString().split('T')[0]) : '',
                    "calculation_end_date": calculationEndDate ? (typeof (calculationEndDate) === 'string' ? calculationEndDate : Moment.utc(calculationEndDate, 'YYYY/MM/DD').local().toISOString().split('T')[0]) : '',
                    "posting_type": radioGroupValue,
                    "supplier_number": supplierNumber.length > 0 ? supplierNumber : [],
                    "calculation_date_type": calculationDateType,

                }
            }
        ]
        // var data = {
        //     "app_type": applicationType,
        //     "batch_job_type": batchJobType,
        //     "batch_job_name": batchJobName,
        //     "planned_date_type": plannedDateType,
        //     "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').local().format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().local().format('YYYY-MM-DDThh:mm:ssZ'),
        //     "planned_start_calendar": plannedStartCalendar,
        //     "planned_start_time": plannedStartTime.concat(":00"),
        //     "frequency": frequency,
        //     "batch_filter_criteria": [{
        //         "filters":{
        //             // "billing_date": billingDateEnd ? { "from": Moment(billingDateStart).format('YYYY-MM-DD'), "to": Moment(billingDateEnd).format('YYYY-MM-DD') }
        //             // : [Moment(billingDateStart).format('YYYY-MM-DD')],
        //             "supplier_number": supplierNumber,
        //             "customer_number": customerNumber,
        //             "customer_group": customerGroup,
        //             "material_number": materialNumber,
        //             "material_group": materialGroup,
        //             "billing_doc_number": billingDocNumber,
        //             "billing_doc_line": billingDocLine,
        //             "calculation_date_type": calculationDateType,
        //             // "posting_date": postingDate ? (typeof (postingDate) === 'string' ? postingDate : Moment.utc(postingDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
        //             "billing_start_date": calculationStartDate ? (typeof (calculationStartDate) === 'string' ? calculationStartDate : Moment.utc(calculationStartDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
        //             "billing_end_date": calculationEndDate ? (typeof (calculationEndDate) === 'string' ? calculationEndDate : Moment.utc(calculationEndDate, 'YYYY-MM-DD').local().toISOString().split('T')[0]) : '',
        //             "split_criteria":splitCriteria.toString(),
        //              "claim_number": claimNumber,
        //             "calculation_start_date": sd,
        //             "calculation_end_date": ed,
        //             "supplier_number": supplierNumber,
        //         // "calculation_method": calculationMethod,
        //         // "contract_type": contractType,
        //         // "contract_number": contractNumber ? contractNumber : [],
        //         // "posting_type": postingType,
        //         // "billing_start_date": billingStartDate,
        //         // "billing_end_date": billingEndDate,
        //         // "tags": tags,
        //         // "name":integrationName,
        //         // "supplier_number": supplierNumber,
        //         // "split_criteria":splitCriteria.toString()
        //         }
        //     }]
        // }
        var data = {
            "app_type": applicationType,
            "batch_job_type": batchJobType,
            "batch_job_name": batchJobName,
            "planned_date_type": plannedDateType,
            "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').local().format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().local().format('YYYY-MM-DDThh:mm:ssZ'),
            "planned_start_calendar": plannedStartCalendar,
            "planned_start_time": plannedStartTime,
            "frequency": frequency,
            "batch_filter_criteria": batchJobType === 'Create Claim' ? batchJobFilter : batchJobFilter2
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }

    function handleApplicationType(newValue) {
        setApplicationType(newValue)
    }
    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value)
    }
    const handleChange = (e) => {
        if (e.target.checked) {
            setSplitCriteria([...splitCriteria, e.target.name])
        }
        else {
            var pos = splitCriteria.indexOf(e.target.name)
            if (pos > -1) {
                splitCriteria.splice(pos, 1)
            }
        }

    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/batchjob-setup'
                >
                    Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Supplier Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={supplierNumberNew}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setSupplierNumberNew(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Customer Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={customerNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setCustomerNumber(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Customer Group
                                    </FormLabel>
                                    <OutlinedInput
                                        value={customerGroup}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setCustomerGroup(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Material Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={materialNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setMaterialNumber(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Material Group
                                    </FormLabel>
                                    <OutlinedInput
                                        value={materialGroup}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setMaterialGroup(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Billing Document Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={billingDocNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setBillingDocNumber(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Billing Document Line
                                    </FormLabel>
                                    <OutlinedInput
                                        value={billingDocLine}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setBillingDocLine(e.target.value)}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Date Type
                                        </FormLabel>
                                        <Select
                                            value={calculationDateType}
                                            onChange={(e) => setCalculationDateType(e.target.value)}
                                            displayEmpty
                                            disabled={!frequency ? true : false}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >

                                            <MenuItem value='static'>
                                                Static
                                            </MenuItem>
                                            <MenuItem value='dynamic'>
                                                Dynamic
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (calculationDateType === 'dynamic' ? 'none' : batchJobType === 'Create Claim' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Billing Start Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationStartDate}
                                                    onChange={(e) => setCalculationStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (calculationDateType === 'dynamic' ? 'none' : batchJobType === 'Create Claim' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Billing End Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationEndDate}
                                                    onChange={(e) => setCalculationEndDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={calculationStartDate ? calculationStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (!calculationDateType || calculationDateType === 'static' ? 'none' : batchJobType === 'Create Claim' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Billing Start Date</FormLabel>
                                        <Select
                                            value={calculationStartDate}
                                            onChange={(e) => setCalculationStartDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {calculationStartDateArray &&
                                                calculationStartDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (!calculationDateType || calculationDateType === 'static' ? 'none' : batchJobType === 'Create Claim' ? 'block' : 'none')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Billing End Date</FormLabel>
                                        <Select
                                            value={calculationEndDate}
                                            onChange={(e) => setCalculationEndDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {calculationEndDateArray &&
                                                calculationEndDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 15, display: batchJobType === 'Create Claim' ? 'block' : 'none' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Split Claim Criteria
                                    </FormLabel>
                                    <div style={{ display: 'flex' }}>
                                        <FormControlLabel
                                            control={<Checkbox checked color="primary" disabled />}
                                            label={<Typography classes={{ root: classes.fontSetting }} > Supplier Number </Typography>}

                                        />
                                        <FormControlLabel
                                            control={<Checkbox Checked={splitCriteria.includes('billing_date') > -1 ? true : false} color="primary" onChange={handleChange} name="billing_date" />}
                                            label={<Typography classes={{ root: classes.fontSetting }} > Billing Date </Typography>}
                                        />

                                    </div>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none', marginTop: '-1rem', height: '1.6rem' }}
                                >
                                    <Dropdown classes={{ root: classes.fontSetting }} heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                                </Grid>


                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none' }}
                                >


                                    <FormLabel className={classes.chargeBackLabel} required={supplierNumber.length == 0 ? true : false}>
                                        Claim Number
                                    </FormLabel>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: { borderRadius: '0px !important' }
                                        }}
                                        multiple
                                        limitTags={3}
                                        id="checkboxes-tags-demo"
                                        options={claimNumbersList}
                                        getOptionLabel={(option) => option}
                                        defaultValue={selected}
                                        value={selected}
                                        renderInput={params => (

                                            <TextField {...params} variant="outlined" size="small" style={{ borderRadius: '0px !important' }} />

                                        )}


                                        disableCloseOnSelect
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={selected} />
                                                {option}

                                            </li>
                                        )}
                                        style={{ width: "100%", marginTop: '0.6rem' }}
                                        onChange={(event, newValue) => { handleClaimNumber(event, newValue) }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none' }}
                                >
                                    <FormLabel className={classes.chargeBackLabel} required={claimNumber.length == 0 ? true : false}>
                                        Supplier Number
                                    </FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={supplierNumber}
                                        blurBehavior='add'
                                        onAdd={(chips) => handleSupplierNumberAdd(chips)}
                                        onDelete={(chip) => handleSupplierNumberDelete(chip)}

                                        disableUnderline={true}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none' }}

                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Calculation Date Type
                                        </FormLabel>
                                        <Select
                                            value={calculationDateType}
                                            onChange={(e) => setCalculationDateType(e.target.value)}
                                            displayEmpty
                                            disabled={!frequency ? true : false}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >

                                            <MenuItem value='static'>
                                                Static
                                            </MenuItem>
                                            <MenuItem value='dynamic'>
                                                Dynamic
                                            </MenuItem>

                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    className={classes.calendarLeftGridContainer}
                                    style={{
                                        display: (!calculationDateType || calculationDateType === 'static' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}


                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>
                                            Posting Date
                                        </FormLabel>
                                        <Select
                                            value={postingDate}
                                            onChange={(e) => setPostingDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {postingDateArray &&
                                                postingDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Create Claim' || calculationDateType === 'dynamic' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>  Posting Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={postingDate}
                                                    onChange={(e) => setPostingDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (calculationDateType === 'dynamic' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation Start Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationStartDate}
                                                    onChange={(e) => setCalculationStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (calculationDateType === 'dynamic' || batchJobType === 'Create Claim' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation End Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={calculationEndDate}
                                                    onChange={(e) => setCalculationEndDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                    minDate={calculationStartDate ? calculationStartDate : ''}
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>


                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (!calculationDateType || batchJobType === 'Create Claim' || calculationDateType === 'static' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} required>Calculation Start Date</FormLabel>
                                        <Select
                                            value={calculationStartDate}
                                            onChange={(e) => setCalculationStartDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {calculationStartDateArray &&
                                                calculationStartDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: (batchJobType === 'Create Claim' || !calculationDateType || calculationDateType === 'static' ? 'none' : 'block')
                                    }}

                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel className={classes.formLabel} required>Calculation End Date</FormLabel>
                                        <Select
                                            value={calculationEndDate}
                                            onChange={(e) => setCalculationEndDate(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                        >
                                            {calculationEndDateArray &&
                                                calculationEndDateArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                                {batchJobType == 'Accrual Postings' ?
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none' }}
                                    >
                                        <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                            <FormControlLabel
                                                value="Accrual"
                                                control={<Radio color="primary" />}
                                                label={<Typography classes={{ root: classes.chargeBackLabel }} > Accrual </Typography>}
                                                classes={{ labelPlacementStart: classes.radioLabel }}
                                            />
                                            <FormControlLabel
                                                value="Accrual Reversal"
                                                control={<Radio color="primary" />}
                                                label={<Typography classes={{ root: classes.chargeBackLabel }} > Accrual Reversal </Typography>}
                                                classes={{ labelPlacementStart: classes.radioLabel }}
                                            />
                                        </RadioGroup>
                                    </Grid>
                                    :
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: batchJobType === 'Payment Postings' ? 'block' : batchJobType === 'Accrual Postings' ? 'block' : 'none' }}
                                    >
                                        <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                            <FormControlLabel
                                                value="Payment"
                                                control={<Radio color="primary" />}
                                                label={<Typography classes={{ root: classes.chargeBackLabel }} > Payment </Typography>}
                                                classes={{ labelPlacementStart: classes.radioLabel }}
                                            />
                                            <FormControlLabel
                                                value="Payment reversal"
                                                control={<Radio color="primary" />}
                                                label={<Typography classes={{ root: classes.chargeBackLabel }} > Payment Reversal </Typography>}
                                                classes={{ labelPlacementStart: classes.radioLabel }}
                                            />

                                        </RadioGroup>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </form>
                </Card >

                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{marginTop:'0.5rem'}}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Application Type
                                        </FormLabel>
                                        <Select
                                            value={applicationType}
                                            onChange={(e) => handleApplicationType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {allowedApps
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Type
                                            </FormLabel>
                                            <Select
                                                value={batchJobType}
                                                onChange={(e) => setBatchJobType(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {batchJobArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {frequencyTypesArray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>


                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <OutlinedInput
                                                value={batchJobName}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setBatchJobName(e.target.value)} />
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency ? true : false}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem value='static'>
                                                    Static
                                                </MenuItem>
                                                <MenuItem value='dynamic'
                                                    style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>

                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 11px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                    .filter(item => item != 'Manual')
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>

                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>

                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                className={classes.textField}
                                                style={{ paddingLeft: 8, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>


                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={!applicationType || !batchJobType || !batchJobName || !plannedStartTime || !frequency || !plannedDateType ? true
                                : plannedStartDate || plannedStartCalendar ? false : true
                            }
                        >
                            Continue

                        </Button>
                    </DialogActions>
                </Dialog>



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}

                        disabled={((calculationEndDate && calculationStartDate && calculationDateType) ? false : true) ?
                            ((claimNumber.length > 0 || supplierNumber.length > 0) && calculationStartDate && calculationEndDate && radioGroupValue && calculationDateType ? false : true) : false}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addBatchJobSetup(data)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        allowedApps: () => dispatch(allowedApps()),
        getOutgoingClaims: () => dispatch(getOutgoingClaims()),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        allowedAppsData: state.initialData.allowedApps,
        integrationSetupData: state.customerData.integrationSetupData,
        outgoingClaimListData: state.initialData.outgoingClaimListData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCCBatchJobSetup);