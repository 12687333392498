import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    allowedApps, getDefaultValues, getAllApprovalDesigner, addContractDefaultDesigner,
    getApprovalGroupData, getContractDefaultDesigner, getLblDispDesFieldValue
} from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { DropdownArray } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 11,
        height: 34,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        // fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem: {
        color: theme.palette.text.grey
    }
}));

const AddContractDefaultDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState('');
    const [approvalContractsContractArray, setApprovalContractsContractArray] = React.useState([]);
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualGLAC, setAccrualGLAC] = React.useState('');
    const [accrualGLAD, setAccrualGLAD] = React.useState('');
    const [accrualCPC, setAccrualCPC] = React.useState('');
    const [accrualDPC, setAccrualDPC] = React.useState('');
    const [formFields, setFormFields] = React.useState({});
    const [contractPurpose, setContractPurpose] = React.useState('');
    const [postingSchema, setPostingSchema] = React.useState('');
    const [postingsApproval, setPostingsApproval] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState(null);
    const [paymentFrequency, setPaymentFrequency] = React.useState('');
    const [paymentCalendar, setPaymentCalendar] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState('');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [editIndex, setEditIndex] = React.useState('');
    const [contractType, setContractType] = React.useState('');
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [userStore, setUserStore] = React.useState([]);
    const [currentContractArray, setCurrentContractArray] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [workflowName, setWorkflowName] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.allowedApps();
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            props.onLoadingDefault(props.allowedAppsData[0])
            var data = {
                "data_source": "contract_header",
                "application_type": props.allowedAppsData[0]
            }
            props.fieldDesigner(data, 'post');
        }
    }, [props.allowedAppsData])
    useEffect(() => {
        if (applicationType) {

            var data = {
                "data_source": "contract_header",
                "application_type": applicationType
            }
            props.fieldDesigner(data, 'post');
        }
    }, [applicationType])
    useEffect(() => {
        props.getAllApprovalDesigner(1, 130);
        props.getApprovalGroupData();
        props.getContractDefaultDesigner();
    }, []);
    useEffect(() => {
        if (props.contractDefaultData && props.contractDefaultData.length > 0) {
            props.contractDefaultData.map((item) => setApprovalContractsContractArray(approvalContractsContractArray => [...approvalContractsContractArray, item.contract_type]));
        }
    }, [props.approvalData]);

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
        setContractType('')
        setSourceDataType('')

        props.onLoadingDefault(e.target.value, '', '', '', 'Contract Designer');
    }
    function handleContractType(e) {
        setContractType(e.target.value);
    }
    function handleWorkflowName(e) {
        setWorkflowName(e.target.value);
    }
    function handleClear() {
        setContractType('');
        setAccrualLevel('');
        setCopaLevel('');
        setApprovalGroup('');
        setAccrualGLAC('');
        setAccrualGLAD('');
        setAccrualCPC('');
        setAccrualDPC('');
        setContractPurpose('');
        setPostingSchema('');
        setPostingsApproval('');
        setCurrency('');
        setSourceDataType('');
        setAccrualFrequency('');
        setAccrualCalendar('');
        setPaymentFrequency('');
        setPaymentCalendar('');
        setPaymentMethod('');
        setPaymentPartnerRole('');
        setPaymentTerms('');
        setApprovalGroup('');
        setApplicationType('');
        setWorkflowName('');
    }
    function handleOnSubmit() {
        setCurrentContractArray([...currentContractArray, contractType]);
        var data = {
            "app_type": applicationType,
            "contract_type": contractType,
            "accrual_level": accrualLevel,
            "copa_level": copaLevel,
            "approval_group": approvalGroup ? approvalGroup : 0,
            "accrual_gl_account_credit": accrualGLAC,
            "accrual_gl_account_debit": accrualGLAD,
            "accrual_credit_profit_center": accrualCPC,
            "accrual_debit_profit_center": accrualDPC,
            "contract_purpose": contractPurpose,
            "posting_schema": postingSchema,
            "currency": currency,
            "source_data_type": sourceDataType,
            "accrual_frequency": accrualFrequency,
            "accrual_calendar": accrualCalendar,
            "payment_frequency": paymentFrequency,
            "payment_calendar": paymentCalendar,
            "payment_method": paymentMethod,
            "payment_partner_role": paymentPartnerRole,
            "payment_terms": paymentTerms,
            "workflow_name": workflowName,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...userStore];
            editedArray[editIndex] = data;
            setUserStore(editedArray);
        }
        else {
            setUserStore([...userStore, data]);
        }
        props.onSubmit([...userStore, data]);
        //handleClear();
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/contract-default-designer'
                >
                    Default Designer
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Contract Default</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: 9 }}
                            >
                                <FormLabel className={classes.formLabel} required style={{ paddingBottom: 8 }}>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    style={{ textTransform: 'capitalize' }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>{console.log(formFields)}
                            {formFields && formFields['contract_type'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ marginTop: 9 }}
                                >
                                    <FormLabel className={classes.formLabel} required style={{ paddingBottom: 8 }}>
                                        Contract Type
                                    </FormLabel>
                                    <Select
                                        value={contractType}
                                        onChange={handleContractType}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                    >
                                        {formFields['contract_type']['drop_down_values'] && formFields.contract_type.drop_down_values.length > 0 &&
                                            formFields.contract_type.drop_down_values
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            }
                            {formFields && formFields['accrual_level'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ marginTop: 9 }}
                                >
                                    <FormLabel className={classes.formLabel} style={{ paddingBottom: 8 }}>
                                        Accrual Level
                                    </FormLabel>

                                    <Select
                                        value={accrualLevel}
                                        onChange={(e) => setAccrualLevel(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                    >
                                        {formFields.accrual_level.drop_down_reference_value &&
                                            formFields.accrual_level.drop_down_reference_value.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                        {item.desc}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>

                                </Grid>
                            }
                            {formFields && formFields['copa_level'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['copa_level']['current']}
                                        </FormLabel>

                                        <Select
                                            value={copaLevel}
                                            onChange={(e) => setCopaLevel(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                        >
                                            {formFields.copa_level.drop_down_reference_value &&
                                                formFields.copa_level.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.key}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields['accrual_gl_account_credit'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['accrual_gl_account_credit']['current']}
                                        </FormLabel>
                                        {formFields['accrual_gl_account_credit']['drop_down_values'] &&
                                            formFields['accrual_gl_account_credit']['drop_down_values'].length > 0 ?
                                            <DropdownArray classes={{ root: classes.fontSetting }} //heading={formFields['accrual_gl_account_credit']['current']}
                                                placeholder={' '} data={formFields['accrual_gl_account_credit']['drop_down_values']} twoline='true'
                                                onChange={(value) => setAccrualGLAC(value)}
                                            />

                                            :
                                            <OutlinedInput
                                                value={accrualGLAC}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setAccrualGLAC(e.target.value)} />
                                        }
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields['accrual_gl_account_debit'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Accrual GL Account Debit
                                        </FormLabel>
                                        {formFields['accrual_gl_account_debit']['drop_down_values'] &&
                                            formFields['accrual_gl_account_debit']['drop_down_values'].length > 0 ?
                                            <DropdownArray classes={{ root: classes.fontSetting }} //heading={formFields['accrual_gl_account_debit']['current']}
                                                placeholder={' '} data={formFields['accrual_gl_account_debit']['drop_down_values']} twoline='true'
                                                onChange={(value) => setAccrualGLAD(value)}
                                            />

                                            :
                                            <OutlinedInput
                                                value={accrualGLAD}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setAccrualGLAD(e.target.value)} />
                                        }
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields['accrual_credit_profit_center'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['accrual_credit_profit_center']['current']}
                                        </FormLabel>
                                        {formFields['accrual_credit_profit_center']['drop_down_values'] &&
                                            formFields['accrual_credit_profit_center']['drop_down_values'].length > 0 ?
                                            <DropdownArray classes={{ root: classes.fontSetting }} //heading={formFields['accrual_credit_profit_center']['current']}
                                                placeholder={' '} data={formFields['accrual_credit_profit_center']['drop_down_values']} twoline='true'
                                                onChange={(value) => setAccrualCPC(value)}

                                            />
                                            :
                                            <OutlinedInput
                                                value={accrualCPC}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setAccrualCPC(e.target.value)}
                                            />
                                        }
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields['accrual_debit_profit_center'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} style={{ whiteSpace: 'nowrap', marginBottom: 0 }}>
                                            {formFields['accrual_debit_profit_center']['current']}
                                        </FormLabel>
                                        {formFields['accrual_debit_profit_center']['drop_down_values'] &&
                                            formFields['accrual_debit_profit_center']['drop_down_values'].length > 0 ?
                                            <DropdownArray classes={{ root: classes.fontSetting }} //heading={formFields['accrual_debit_profit_center']['current']}
                                                placeholder={' '} data={formFields['accrual_debit_profit_center']['drop_down_values']} twoline='true'
                                                onChange={(value) => setAccrualDPC(value)}

                                            />
                                            :
                                            <OutlinedInput
                                                style={{ marginTop: 17 }}
                                                value={accrualDPC}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setAccrualDPC(e.target.value)}
                                            />
                                        }
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.contract_purpose &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['contract_purpose']['current']}
                                        </FormLabel>

                                        <Select
                                            value={contractPurpose}
                                            onChange={(e) => setContractPurpose(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.contract_purpose.drop_down_reference_value &&
                                                formFields.contract_purpose.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.posting_schema &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['posting_schema']['current']}
                                        </FormLabel>

                                        <Select
                                            value={postingSchema}
                                            onChange={(e) => setPostingSchema(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.posting_schema.drop_down_reference_value &&
                                                formFields.posting_schema.drop_down_reference_value
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                {item.desc}
                                                            </MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.currency &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['currency']['current']}
                                        </FormLabel>

                                        <Select
                                            value={currency}
                                            onChange={(e) => setCurrency(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.currency.drop_down_reference_value &&
                                                formFields.currency.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.source_data_type &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Source Data Type
                                        </FormLabel>
                                        <Select
                                            value={sourceDataType}
                                            onChange={(e) => setSourceDataType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.source_data_type.drop_down_reference_value &&
                                                formFields.source_data_type.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.accrual_frequency &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['accrual_frequency']['current']}
                                        </FormLabel>

                                        <Select
                                            value={accrualFrequency}
                                            onChange={(e) => setAccrualFrequency(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.accrual_frequency.drop_down_reference_value &&
                                                formFields.accrual_frequency.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.accrual_calendar &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['accrual_calendar']['current']}
                                        </FormLabel>
                                        <Select
                                            value={accrualCalendar}
                                            onChange={(e) => setAccrualCalendar(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.accrual_calendar.drop_down_values &&
                                                formFields.accrual_calendar.drop_down_values.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.payment_frequency &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['payment_frequency']['current']}
                                        </FormLabel>

                                        <Select
                                            value={paymentFrequency}
                                            onChange={(e) => setPaymentFrequency(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.payment_frequency.drop_down_reference_value &&
                                                formFields.payment_frequency.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.payment_calendar &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['payment_calendar']['current']}
                                        </FormLabel>
                                        <Select
                                            value={paymentCalendar}
                                            onChange={(e) => setPaymentCalendar(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.payment_calendar.drop_down_values &&
                                                formFields.payment_calendar.drop_down_values.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.payment_method &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['payment_method']['current']}
                                        </FormLabel>
                                        <Select
                                            value={paymentMethod}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.payment_method.drop_down_reference_value &&
                                                formFields.payment_method.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.payment_partner_role &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['payment_partner_role']['current']}
                                        </FormLabel>
                                        <Select
                                            value={paymentPartnerRole}
                                            onChange={(e) => setPaymentPartnerRole(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ marginTop: 7, height: 37 }}
                                        >
                                            {formFields.payment_partner_role.drop_down_reference_value &&
                                                formFields.payment_partner_role.drop_down_reference_value.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>

                                    </div>
                                </Grid>
                            }
                            {formFields && formFields.payment_terms &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            {formFields['payment_terms']['current']}
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={paymentTerms}
                                            onChange={(e) => setPaymentTerms(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                        >
                                            {/* <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem> */}
                                            {formFields.payment_terms.drop_down_reference_value &&
                                                formFields.payment_terms.drop_down_reference_value.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.key} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                            {formFields && formFields['workflow_name'] &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Workflow Name
                                    </FormLabel>
                                    <Select
                                        value={workflowName}
                                        onChange={handleWorkflowName}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ marginTop: 18, height: 35 }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                    >
                                        {formFields['workflow_name']['drop_down_reference_value'] && formFields.workflow_name.drop_down_reference_value.length > 0 &&
                                            formFields.workflow_name.drop_down_reference_value

                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                            {item.desc}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit} disabled={applicationType && contractType ? false : true}>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        contractDefaultData: state.customerData.contractDefaultDesignerGetAll,
        allowedAppsData: state.initialData.allowedApps,
        loadingDropdownValues: state.addMultipleConfigurationData.loading,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addContractDefaultDesigner(data)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        getContractDefaultDesigner: () => dispatch(getContractDefaultDesigner(1, 100)),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddContractDefaultDesigner);