import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Table, TableCell, TableHead, TableRow, Typography, TableContainer, Link, TableBody, CircularProgress
} from '@mui/material';
import Moment from 'moment';
import { history } from '../../../../components/Helpers';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // marginTop: 20,
        borderRadius: 10,
        // padding: 22,
        paddingLeft: 5,
        paddingRight: 5
    },
    table: {
        width: '100%'
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    header: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 7
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const Loading = () => {
    return (
        <CircularProgress size={12} color='secondary' />
    )
}
const handleViewAll = () => {
    history.push('/contract-setup');
}

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const NewContracts = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>{console.log(props.data)}
            <div className={classes.header}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 6, marginTop: 5 }} > Latest Contracts </Typography>
                <Typography variant="h1" color='primary' >
                    <Link onClick={handleViewAll} underline='none' style={{ cursor: 'pointer' }}>
                        View All
                    </Link>
                </Typography>
            </div>
            {props.data && props.data.length > 0 ?
                <TableContainer>
                    <Table >
                        <TableHead >
                            <TableRow >
                                <TableCell classes={{ root: classes.fontSetting }}
                                    align='center'
                                >Contract Number</TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Calculation Method</TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Contract Type </TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Internal Description </TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Approval Status </TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Accrual Amount </TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Payment Amount </TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>Start Date</TableCell>
                                <TableCell classes={{ root: classes.fontSetting }} align='center'>End Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }} >
                            {props.data
                                .filter(item => item.is_update_approval_contract === false)
                                .sort(function (a, b) {
                                    return a.contract_number < b.contract_number ? 1 : -1;
                                }).sort()
                                .slice(0, 5)
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.contract_number}>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center' >
                                                {item.contract_number}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.description }}>{item.calculation_method}</TableCell>
                                            <TableCell classes={{ root: classes.description }}>{item.contract_type}</TableCell>
                                            <TableCell classes={{ root: classes.description }}>{item.internal_description}</TableCell>
                                            <TableCell classes={{ root: classes.description }}>
                                                {item.approval_status === 'new' ? 'New' : item.approval_status === 'acc' ? 'Accepted' : item.approval_status === 'rej' ? 'Rejected'
                                                    : item.approval_status === 'changes_acc' ? 'Changes Accepted' :
                                                        'Submit For Approval'}

                                            </TableCell>
                                            <TableCell classes={{ root: classes.description }}>
                                                {parseFloat(item.accrual_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.description }}>
                                                {parseFloat(item.payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center' > {item.valid_from === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.valid_from).format('MM/DD/YYYY')} </TableCell>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center'>{item.valid_to === '0001-01-01T00:00:00Z' ? '' : Moment.utc(item.valid_to).format('MM/DD/YYYY')}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div style={{ paddingTop: 15 }}>
                    {
                        props.data && props.data.length === 0 ?
                            <Typography variant='h4'>
                                There is no data to show now.
                            </Typography>
                            :
                            <CircularProgress />
                    }
                </div>
            }
        </div>
    );

};

export default NewContracts;