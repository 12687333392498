import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    updateQuotaFormatData,getQuotaFormatFields
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: 13,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize:theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    paper: { minWidth: window.screen.width * .50 },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    }
}));

const QuotaManagementEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName.trim() });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [employeeNumber, setEmployeeNumber] = React.useState('');
    const [quotaType, setQuotaType] = React.useState('');
    const [quotaValue, setQuotaValue] = React.useState('');
    const [territoryID, setTerritoryID] = React.useState('');
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [customerNumber,setCustomerNumber] = React.useState('');
    const [supplierNumber,setSupplierNumber] = React.useState('');
    const [materialNumber,setMaterialNumber] = React.useState('');
    const requiredFields=[
        'quota_type','quota_value','start_date','end_date','employee_number','territory_id','customer_number','material_number','supplier_number'
    ]
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(localStorage.getItem('quota-management-query') ? JSON.parse(localStorage.getItem('quota-management-query')) : []);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    //newfields
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.getQuotaFormatFields();
    }, []);

    useEffect(() => {
        if (purchaseQueryDataStorage) {
            setQueryName(purchaseQueryDataStorage.format_name)
            setQueryObject(purchaseQueryDataStorage)
            if(purchaseQueryDataStorage.employee_number)
            setEmployeeNumber(purchaseQueryDataStorage.employee_number)
            if(purchaseQueryDataStorage.territory_id)
            setTerritoryID(purchaseQueryDataStorage.territory_id)
            if(purchaseQueryDataStorage.quota_type)
            setQuotaType(purchaseQueryDataStorage.quota_type)
            if(purchaseQueryDataStorage.quota_value)
            setQuotaValue(purchaseQueryDataStorage.quota_value)
            if(purchaseQueryDataStorage.start_date)
            setStartDate(purchaseQueryDataStorage.start_date)
            if(purchaseQueryDataStorage.end_date)
            setEndDate(purchaseQueryDataStorage.end_date)
            if(purchaseQueryDataStorage.customer_number)
            setCustomerNumber(purchaseQueryDataStorage.customer_number)
            if(purchaseQueryDataStorage.material_number)
            setMaterialNumber(purchaseQueryDataStorage.material_number)
            if(purchaseQueryDataStorage.supplier_number)
            setSupplierNumber(purchaseQueryDataStorage.supplier_number)
        }
    }, [purchaseQueryDataStorage]);

    function handleClear() {
        for (var propName in queryObject) {
            if(propName!='format_name')
            {
                 queryObject[propName] =''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, queryObject);
    }
    function handleClearAll() {
        handleClear();
        setQuotaType('')
        setQuotaValue('')
        setStartDate('')
        setEndDate('')
        setEmployeeNumber('')
        setTerritoryID('')
        setMaterialNumber('')
        setCustomerNumber('')
        setSupplierNumber('')
    }
    function handleCancelDialog() {
        history.push('/quota-management/quota-management-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if(item==='quota_type'){
            setQuotaType(event.target.value)
        }
        if(item=='quota_value'){
            setQuotaValue(event.target.value)
        }
        if(item=='start_date'){
            setStartDate(event.target.value)
        }
        if(item=='end_date'){
            setEndDate(event.target.value)
        }
        if(item=='employee_number'){
            setEmployeeNumber(event.target.value)
        }
        if(item=='territory_id'){
            setTerritoryID(event.target.value)
        }
        if(item=='supplier_number'){
            setSupplierNumber(event.target.value)
        }
        if(item=='customer_number'){
            setCustomerNumber(event.target.value)
        }
        if(item=='material_number'){
            setMaterialNumber(event.target.value)
        }
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/quota-management/quota-management-excel-upload'
                >
                   Quota Management Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.fontSetting} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.fontSetting} style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell className={classes.fontSetting} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    { requiredFields.includes(item)?
                                                                   (item!='employee_number'&& item!='territory_id' &&item!='customer_number' && item!='material_number' && item!='supplier_number')||
                                                                   ((item=='employee_number' && !queryObject['territory_id'] && !queryObject['customer_number'] && !queryObject['material_number'] && !queryObject['supplier_number']?true:false)
                                                                   ||(item=='territory_id'&& !queryObject['employee_number'] && !queryObject['customer_number'] && !queryObject['material_number'] && !queryObject['supplier_number']?true:false)
                                                                   ||(item=='customer_number'&& !queryObject['employee_number'] && !queryObject['territory_id'] && !queryObject['material_number'] && !queryObject['supplier_number']?true:false)
                                                                   ||(item=='material_number'&& !queryObject['employee_number'] && !queryObject['customer_number'] && !queryObject['territory_id'] && !queryObject['supplier_number']?true:false)
                                                                   ||(item=='supplier_number'&& !queryObject['employee_number'] && !queryObject['customer_number'] && !queryObject['material_number'] && !queryObject['territory_id']?true:false)
                                                                   )? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> 
                                                                    : item.replace(/_/g, ' '): item.replace(/_/g, ' ')
                                                                   }
                                                                </TableCell>
                                                                    <TableCell className={classes.fontSetting} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item]? queryObject[item]:''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('quota-management-query'))[item] ? JSON.parse(localStorage.getItem('quota-management-query'))[item] : ''}
                                                                            />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={quotaType&&quotaValue&&startDate&&endDate&& (territoryID ||employeeNumber||supplierNumber||materialNumber||customerNumber) ?false:true}
                        >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateQuotaFormatData(id, data)),
        getQuotaFormatFields: () => dispatch(getQuotaFormatFields()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
       queryListData: state.quotaManagementData.quotaFormatFieldData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotaManagementEditQuery);