import React, { useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    Checkbox,
    MenuItem,
    Select,
    ListItemText,
    TextField, OutlinedInput
} from "@mui/material";
import Moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
    addAnalysisDesigner,
    allowedApps,
    getAnalysisDesignerList,
    getAnalyticsFilters,
    postAccessData,
    addUser,
    getAccessDataNew,
    appRights,
    getOrganizationAppTitle, getAnaylsisFormula, getAttributesConfig
} from "../../redux/actions";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import ChipInput from "material-ui-chip-input";
import Autocomplete, {
    createFilterOptions,
} from '@mui/material/Autocomplete';
import { Link } from "react-router-dom";
import { TextInputWithLabel } from '../../components/Inputs';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: "27px 29px 27px 29px",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: "1px solid #EEEEEE",
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10,
    },
    button: {
        marginRight: 25,
        width: 140,
    },
    selectRoot: {
        padding: "0px 0px 15px 0px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    select: {
        width: "100%",
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: "100%",
    },
    buttonRoot: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 3,
        paddingBottom: 28,
    },
    paper: { minWidth: window.screen.width * 0.5 },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        fontSize: 13,
    },
    chipLabel: {
        fontFamily: "ProximaNova",
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
    },
    formDiv: {
        fontSize: 13,
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll",
    },
    inputTwoLine: {
        marginTop: 0,
        width: '100%',
        paddingLeft: 10,
        height: 37
    },
    rootContainer: {
        border: "1px solid",
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        height: 36,
        marginTop: 9,
        height: 38,
        padding: 0,
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: "#E1E4F3",
        "&&:hover": {
            backgroundColor: "#E1E4F3",
            color: theme.palette.black,
        },
        fontFamily: "ProximaNova",
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: "ProximaNova",
        fontSize: 13,
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
    },
    chipContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: "overlay",
        flexFlow: "unset",
        borderRadius: 5,
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 30,
        display: "flex",
        alignItems: "flex-end",
    },
}));
const AddDashboardDesigner = (props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    const classes = useStyles();
    const [applicationType, setApplicationType] = React.useState("");
    const [accessData, setAccessData] = React.useState([]);
    const [fApplicationType, setfApplicationType] = React.useState("");
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [analyticName, setAnalyticName] = React.useState('');
    const [analyticNameArray, setAnalyticNameArray] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [filtersArray, setFiltersArray] = React.useState([]);
    const [filtersGroup, setFiltersGroup] = React.useState([]);
    const [listOpen, setListOpen] = React.useState("");
    const [listOpen1, setListOpen1] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [selected1, setSelected1] = React.useState([]);
    const [dashBoardDescription, setDashBoardDescription] = React.useState("");
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [functionality, setFunctionality] = React.useState("");
    const isAllSelected =
        filtersArray.length > 0 && selected.length === filtersArray.length;
    const isAllSelected1 =
        accessData.length > 0 && selected1.length === accessData.length;
    // const functionalityArray = ["Dashboard", "Analytics"];
    const [functionalityArray, setFunctionalityArray] = React.useState([]);
    const [startDate, setStartDate] = React.useState("");
    const [forecastStartDate, setForecastStartDate] = React.useState("");
    const [historicalStartDate, setHistoricalStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [forecastendDate, setForecastEndDate] = React.useState("");
    const [historicalendDate, setHistoricalEndDate] = React.useState("");
    const [startDateC, setStartDateC] = React.useState("");
    const [endDateC, setEndDateC] = React.useState("");
    const [postingType, setPostingType] = React.useState("");
    const [dataSourceType, setDataSourceType] = React.useState("");
    const [groupBy, setGroupBy] = React.useState("");
    const [periodicity, setPeriodicity] = React.useState("");
    const [simulation, setSimulation] = React.useState("");
    const [formulaName, setFormulaName] = React.useState("");
    const [attributeName, setAttributeName] = React.useState("");
    const [formulaNames, setFormulaNames] = React.useState("");
    const [formulaNameArray, setFormulaNameArray] = React.useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);
    const [aggrigationField, setAggrigationField] = React.useState("");
    const [masterDataType, setMasterDataType] = React.useState("");
    const [apps, setApps] = React.useState([]);
    const startDateValues = [
        "First Day of Last Year",
        "First Day of Current Year",
        "First Day of Last Three Months",
        "First Day of Last Six Months",
        "First Day of Previous Month",
        "First Day of Current Month",
    ];

    const startDateValuesConverted = [
        Moment().subtract(1, "year").startOf("year").format("MM/DD/YYYY"),
        Moment().startOf("year").format("MM/DD/YYYY"),
        Moment().subtract(3, "months").startOf("month").format("MM/DD/YYYY"),
        Moment().subtract(6, "months").startOf("month").format("MM/DD/YYYY"),
    ];

    const endDateValues = [
        "Last Day of Current Month",
        "Last Day of Previous Month",
        "Last Day of Last Year",
    ];

    const endDateValuesConverted = [
        Moment().endOf("month").format("MM/DD/YYYY"),
        Moment().subtract(1, "months").format("MM/DD/YYYY"),
        Moment().subtract(1, "year").format("MM/DD/YYYY"),
    ];
    const postingTypeValues = ["Accrual", "Payment"];
    const dataSourceTypeValues = ["Direct - Sales Data", "Indirect - Sales Data", "Direct - Purchase Data"];
    const periodicityValues = ["Cumulative", "Month"];
    const simulationValues = ["Historical - Actual", "Historical - What-If"];
    const groupByValues = [
        "Contract Type",
        "Contract Group",
        "Contract Sub Group",
        "Calculation Method",
    ];
    const groupByValues1 = [
        "Contract Number",
        "Customer Number",
        "Material Number",
        "Supplier Number"
    ];
    const masterDataTypeArray = [
        "Customer Master",
        "Employee Master",
        "Material Master",
        "Supplier Master"
    ];
    useEffect(() => {
        props.allowedApps();
        props.getAccessData();
        props.getOrganizationAppTitle();
        props.getConfigData(1, 10);
        props.getAttributesConfig(1, 100);
    }, []);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0]);
            props.getAnalyticsFilters(props.allowedAppsData[0]);
        }
    }, [props.allowedAppsData]);

    useEffect(() => {
        if (props.accessData) {
            const names = []
            props.accessData.map(item =>
                names.push(item.name));
            // console.log("names", names)
            setAccessData(names);
            // setApps(props.accessData.security_role);
        }
    }, [props.accessData]);
    useEffect(() => {
        if (props.configData) {
            const names = []
            props.configData.map(item =>
                names.push(item.formula_name));
            setFormulaNameArray(names);
        }
    }, [props.configData]);
    useEffect(() => {
        if (props.configAttributeData) {
            const names = []
            props.configAttributeData.map(item =>
                names.push(item.qualifier_key));
            setAttributeNameArray(names);
        }
    }, [props.configAttributeData]);
    useEffect(() => {
        var newArray = [];
        var newArray2 = [];
        if (props.analysisDropdown && props.analysisDropdown.length > 0) {
            props.analysisDropdown.map((item) => {
                newArray.push(item);
                newArray2.push(item.filters);
            });
        }
    }, [props.analysisDropdown]);
    const filterOptions = createFilterOptions({
        stringify: (option) =>
            customerNameListArray[customerNumberList.indexOf(option)] + option,
    });
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
        props.getAnalyticsFilters(e.target.value);
    };
    const handleAccessData = (e) => {
        setAccessData(e.target.value);
    };
    console.log("filtrarray", filtersArray)
    function handleAnalysisName(e) {
        setAnalyticName(e.target.value);
        const selectedAnalytic = filtersGroup.find(analytic => analytic.analytic_name === e.target.value) || {};
        const selectedFiltersArray = selectedAnalytic.filters || [];
        setFiltersArray(selectedFiltersArray);
    }

    function handleOnSubmit() {
        Object.entries(attributeObjectAPI).map(([key, value]) => {
            if (filters.includes(key)) {
            } else {
                delete attributeObjectAPI[key];
            }
        });
        var atObj = attributeObjectAPI;

        atObj["application_type"] = applicationType;
        var data = {
            application_type: applicationType,
            security_role: apps,
            analytic_name: analyticName,
            filters: atObj,
            dashboard_name: functionality,
            dashboard_description: dashBoardDescription,
        };
        // console.log(atObj);
        props.onSubmit(data);
    }
    const handleFunctionality = (e) => {
        setFunctionality(e.target.value);

        const selectedDashboard = e.target.value;
        const selectedDashboardData = props.analyticsfiltersDropdown.response.find(
            (dashboard) => dashboard.dashboard_name === selectedDashboard
        );

        const analyticNamesArray = selectedDashboardData ? selectedDashboardData.analytics.map((analytic) => analytic.analytic_name) : [];
        const analyticNamesGroup = selectedDashboardData ? selectedDashboardData.analytics : [];
        setFiltersGroup(analyticNamesGroup);
        setAnalyticNameArray(analyticNamesArray);
    };
    console.log("filterGroup", filtersGroup)
    useEffect(() => {
        // console.log(props.analyticsfiltersDropdown)
        var newArray = [];
        if (props.analyticsfiltersDropdown && props.analyticsfiltersDropdown.response && props.analyticsfiltersDropdown.response.length > 0) {
            props.analyticsfiltersDropdown.response.map((item) => {
                newArray.push(item.dashboard_name);
            });
        }
        setFunctionalityArray(newArray);
    }, [props.analyticsfiltersDropdown]);

    function handleDashBoardDescription(e) {
        setDashBoardDescription(e.target.value)
    }
    const handleSelectValues = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            setSelected(selected.length === filtersArray.length ? [] : filtersArray);
            return;
        }
        setSelected(newSelected);
    };
    const handleSelectValues1 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected1.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected1, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected1.slice(1));
        } else if (selectedIndex === selected1.length - 1) {
            newSelected = newSelected.concat(selected1.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected1.slice(0, selectedIndex),
                selected1.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            setSelected1(selected1.length === accessData.length ? [] : accessData);
            return;
        }
        setSelected1(newSelected);
    };
    function onAdd() {
        setFilters(selected);
        setListOpen(false);
    }
    function onAdd1() {
        setApps(selected1);
        setListOpen1(false);
    }
    function onCancel() {
        setListOpen(false);
        setSelected([]);
    }
    function onCancel1() {
        setListOpen1(false);
        setSelected1([]);
    }
    function handleOpen() {
        setSelected(filters);
        setListOpen(true);
    }
    function handleOpen1() {
        setSelected1(apps);
        setListOpen1(true);
    }
    const [attributeObjectAPI, setAttributeObjectAPI] = React.useState([]);
    const handleAttributeValues = (value, item) => {
        var startIndex;
        var endIndex;
        if (item === "start_date") {
            startIndex = startDateValues.indexOf(value).toString();
            console.log(startDateValuesConverted[startIndex]);

            setStartDate(value);
        }
        if (item === "forecast_start_date") {
            startIndex = startDateValues.indexOf(value).toString();
            setForecastStartDate(value);
        }
        if (item === "historical_start_date") {
            startIndex = startDateValues.indexOf(value).toString();
            setHistoricalStartDate(value);
        }
        if (item === "end_date") {
            endIndex = endDateValues.indexOf(value).toString();
            setEndDate(value);
        }
        if (item === "forecast_end_date") {
            endIndex = endDateValues.indexOf(value).toString();
            setForecastEndDate(value);
        }
        if (item === "historical_end_date") {
            endIndex = endDateValues.indexOf(value).toString();
            setHistoricalEndDate(value);
        }
        if (item === "posting_type") {
            setPostingType(value);
        }
        if (item === "data_source_type") {
            setDataSourceType(value);
        }
        if (item === "groupBy") {
            setGroupBy(value);
        }
        if (item === "periodicity") {
            setPeriodicity(value);
        }
        if (item === "simulation_category") {
            setSimulation(value);
        }
        if (item === "aggregation_field") {
            setAggrigationField(value);
        }
        if (item === "master_data_type") {
            setMasterDataType(value);
        }
        if (item === "formula") {
            setFormulaName(value);
        }
        if (item === "formula_name") {
            setFormulaNames(value);
        }
        if (item === "attribute_name") {
            setAttributeName(value);
        }
        var attributeObject = [];
        attributeObject.push({
            [item]: value,
        });
        // if (item === "customer_number") {
        //   value = [value];
        // }
        if (item === "start_date") {
            setAttributeObjectAPI((prevState) => ({
                ...prevState,
                [item]: startDateValues[startIndex],
            }));
        } else if (item === "end_date") {
            setAttributeObjectAPI((prevState) => ({
                ...prevState,
                [item]: endDateValues[endIndex],
            }));
        } else
            setAttributeObjectAPI((prevState) => ({
                ...prevState,
                [item]: value,
            }));
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    to="/dashboard-designer"
                >
                    DashBoard Designer
                </Link>
                <Typography color="textPrimary" variant="h4">
                    Add DashBoard Designer
                </Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form autoComplete="off" noValidate>
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid item md={3} xs={12}>
                                    <FormLabel className={classes.formLabel} required>
                                        Application Type
                                    </FormLabel>
                                    <Select
                                        value={applicationType}
                                        onChange={handleApplicationtype}
                                        displayEmpty
                                        style={{ textTransform: "capitalize" }}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem,
                                        }}
                                    >
                                        {allowedApps.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    value={item}
                                                    key={index}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormLabel className={classes.formLabel} required>
                                        Persona
                                    </FormLabel>
                                    <Select
                                        value={apps}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={handleOpen1}
                                        onClose={() => setListOpen1(false)}
                                        open={listOpen1}
                                        renderValue={(selected1) => selected1}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem,
                                        }}
                                        multiple
                                        style={{ marginTop: 9 }}
                                    >
                                        <MenuItem
                                            value="all"
                                            onClick={(event) => handleSelectValues1(event, "all")}
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            <Checkbox color="primary" checked={isAllSelected1} />
                                            <ListItemText primary="Select All" />
                                        </MenuItem>
                                        {accessData && accessData.length > 0 &&
                                            accessData.map((item) => {
                                                return (
                                                    <MenuItem
                                                        onClick={(event) =>
                                                            handleSelectValues1(event, item)
                                                        }
                                                        value={item}
                                                        key={item}
                                                        style={{ textTransform: "capitalize" }}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            checked={selected1.indexOf(item) > -1}
                                                        />
                                                        <ListItemText primary={item.replace(/_/g, " ")} />
                                                    </MenuItem>
                                                );
                                            })}
                                        <div className={classes.dropdownAction}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={classes.btn}
                                                onClick={onCancel1}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.btn}
                                                onClick={onAdd1}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            DashBoard
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={functionality}
                                                onChange={handleFunctionality}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem,
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >
                                                {functionalityArray &&
                                                    functionalityArray.map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item}>
                                                                {item.replace(/-/g, " ")}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} style={{ marginBottom: "8px" }}>
                                            DashBoard Description
                                        </FormLabel>
                                        <OutlinedInput
                                            value={dashBoardDescription}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDashBoardDescription}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Analytics Name
                                        </FormLabel>
                                        <Select
                                            value={analyticName}
                                            onChange={handleAnalysisName}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            style={{ textTransform: "capitalize" }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                        >
                                            {analyticNameArray &&
                                                analyticNameArray.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={item}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Filters
                                        </FormLabel>
                                        <Select
                                            value={filters}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={handleOpen}
                                            onClose={() => setListOpen(false)}
                                            open={listOpen}
                                            renderValue={(appType) => {
                                                var priceType = [];
                                                appType.map((item) => {
                                                    priceType.push(item.replace(/_/g, " "));
                                                });
                                                return priceType.join(", ");
                                            }}
                                            className={clsx({
                                                [classes.select]: true,
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem,
                                            }}
                                            multiple
                                        >
                                            <MenuItem
                                                value="all"
                                                onClick={(event) => handleSelectValues(event, "all")}
                                                style={{ textTransform: "capitalize" }}
                                            >
                                                <Checkbox color="primary" checked={isAllSelected} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {filtersArray &&
                                                filtersArray.map((item) => {
                                                    return (
                                                        <MenuItem
                                                            onClick={(event) =>
                                                                handleSelectValues(event, item)
                                                            }
                                                            value={item}
                                                            key={item}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={selected.indexOf(item) > -1}
                                                            />
                                                            <ListItemText primary={item.replace(/_/g, " ")} />
                                                        </MenuItem>
                                                    );
                                                })}
                                            <div className={classes.dropdownAction}>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onCancel}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={onAdd}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </div>
                                </Grid>
                                {filters.map((item) => {
                                    return (
                                        <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                            <FormLabel
                                                className={classes.formLabel}
                                                style={{ textTransform: "capitalize" }}
                                            >
                                                {item.replace(/_/g, " ")}
                                            </FormLabel>
                                            {
                                                item === "start_date" && (
                                                    <Select
                                                        displayEmpty
                                                        value={startDate}
                                                        onChange={(startDate) =>
                                                            handleAttributeValues(startDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {startDateValues.length > 0 &&
                                                            startDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "forecast_start_date" && (
                                                    <Select
                                                        displayEmpty
                                                        style={{ marginTop: "-1px" }}
                                                        value={forecastStartDate}
                                                        onChange={(forecastStartDate) =>
                                                            handleAttributeValues(forecastStartDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {startDateValues.length > 0 &&
                                                            startDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "historical_start_date" && (
                                                    <Select
                                                        displayEmpty
                                                        style={{ marginTop: "-1px" }}
                                                        value={historicalStartDate}
                                                        onChange={(historicalStartDate) =>
                                                            handleAttributeValues(historicalStartDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {startDateValues.length > 0 &&
                                                            startDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "end_date" && (
                                                    <Select
                                                        displayEmpty
                                                        value={endDate}
                                                        onChange={(endDate) =>
                                                            handleAttributeValues(endDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {endDateValues.length > 0 &&
                                                            endDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {/* {item.replace(/_/g, ' ')} */}
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "forecast_end_date" && (
                                                    <Select
                                                        displayEmpty
                                                        style={{ marginTop: "-1px" }}
                                                        value={forecastendDate}
                                                        onChange={(forecastendDate) =>
                                                            handleAttributeValues(forecastendDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {endDateValues.length > 0 &&
                                                            endDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {/* {item.replace(/_/g, ' ')} */}
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "historical_end_date" && (
                                                    <Select
                                                        displayEmpty
                                                        style={{ marginTop: "-1px" }}
                                                        value={historicalendDate}
                                                        onChange={(historicalendDate) =>
                                                            handleAttributeValues(historicalendDate.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {endDateValues.length > 0 &&
                                                            endDateValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {/* {item.replace(/_/g, ' ')} */}
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "posting_type" && (
                                                    <Select
                                                        displayEmpty
                                                        value={postingType}
                                                        onChange={(postingType) =>
                                                            handleAttributeValues(
                                                                postingType.target.value,
                                                                item
                                                            )
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {postingTypeValues.length > 0 &&
                                                            postingTypeValues.map((item) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={item ? item.toLowerCase() : item}
                                                                        key={item}
                                                                    >
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "data_source_type" && (
                                                    <Select
                                                        displayEmpty
                                                        value={dataSourceType}
                                                        onChange={(dataSourceType) =>
                                                            handleAttributeValues(
                                                                dataSourceType.target.value,
                                                                item
                                                            )
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {dataSourceTypeValues.length > 0 &&
                                                            dataSourceTypeValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {item === "groupBy" && (
                                                <Select
                                                    displayEmpty
                                                    value={groupBy}
                                                    onChange={(groupBy) =>
                                                        handleAttributeValues(groupBy.target.value, item)
                                                    }
                                                    className={clsx({
                                                        [classes.select]: true,
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem,
                                                    }}
                                                >
                                                    {" "}
                                                    {["Incentive Ratio by Attribute", "Top Incentives by Attribute", "Postings Variance by Attribute"].includes(analyticName) ? (
                                                        groupByValues1.length > 0 &&
                                                        groupByValues1.map((item) => (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        groupByValues.length > 0 &&
                                                        groupByValues.map((item) => (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))
                                                    )}
                                                </Select>
                                            )}
                                            {item === "aggregation_field" && (
                                                <Select
                                                    displayEmpty
                                                    value={aggrigationField}
                                                    onChange={(aggrigationField) =>
                                                        handleAttributeValues(aggrigationField.target.value, item)
                                                    }
                                                    className={clsx({
                                                        [classes.select]: true,
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem,
                                                    }}
                                                >
                                                    {" "}
                                                    {groupByValues.length > 0 &&
                                                        groupByValues.map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                            {item === "master_data_type" && (
                                                <Select
                                                    displayEmpty
                                                    value={masterDataType}
                                                    onChange={(masterDataType) =>
                                                        handleAttributeValues(masterDataType.target.value, item)
                                                    }
                                                    className={clsx({
                                                        [classes.select]: true,
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem,
                                                    }}
                                                >
                                                    {" "}
                                                    {masterDataTypeArray.length > 0 &&
                                                        masterDataTypeArray.map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                            {
                                                item === "periodicity" && (
                                                    <Select
                                                        displayEmpty
                                                        value={periodicity}
                                                        onChange={(periodicity) =>
                                                            handleAttributeValues(periodicity.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {periodicityValues.length > 0 &&
                                                            periodicityValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "simulation_category" && (
                                                    <Select
                                                        displayEmpty
                                                        value={simulation}
                                                        onChange={(simulation) =>
                                                            handleAttributeValues(simulation.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {simulationValues.length > 0 &&
                                                            simulationValues.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "formula" && (
                                                    <Select
                                                        displayEmpty
                                                        value={formulaName}
                                                        onChange={(formulaName) =>
                                                            handleAttributeValues(formulaName.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {formulaNameArray.length > 0 &&
                                                            formulaNameArray.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "formula_name" && (
                                                    <Select
                                                        displayEmpty
                                                        value={formulaNames}
                                                        onChange={(formulaNames) =>
                                                            handleAttributeValues(formulaNames.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {formulaNameArray.length > 0 &&
                                                            formulaNameArray.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item === "attribute_name" && (
                                                    <Select
                                                        displayEmpty
                                                        value={attributeName}
                                                        onChange={(attributeName) =>
                                                            handleAttributeValues(attributeName.target.value, item)
                                                        }
                                                        className={clsx({
                                                            [classes.select]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        {" "}
                                                        {attributeNameArray.length > 0 &&
                                                            attributeNameArray.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                )
                                            }
                                            {
                                                item != "start_date" &&
                                                item != "forecast_start_date" &&
                                                item != "historical_start_date" &&
                                                item != "end_date" &&
                                                item != "forecast_end_date" &&
                                                item != "historical_end_date" &&
                                                item != "posting_type" &&
                                                item != "data_source_type" &&
                                                item != "periodicity" &&
                                                item != "simulation_category" &&
                                                item != "formula" &&
                                                item != "formula_name" &&
                                                item != "attribute_name" &&
                                                item != "groupBy" &&
                                                item != "aggregation_field" &&
                                                item != "master_data_type" && (
                                                    <ChipInput
                                                        classes={{
                                                            root: classes.rootContainer,
                                                            chip: classes.chip,
                                                            input: classes.input,
                                                            inputRoot: classes.inputRoot,
                                                            chipContainer: classes.chipContainer,
                                                            label: classes.chipLabel,
                                                        }}
                                                        onChange={(value) =>
                                                            handleAttributeValues(value, item)
                                                        }
                                                        disableUnderline={true}
                                                        className={clsx({
                                                            [classes.textInput]: true,
                                                        })}
                                                    />
                                                )
                                            }
                                        </Grid>
                                    );
                                })}
                            </Grid>


                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleOnSubmit}
                        disabled={
                            apps &&
                                applicationType &&
                                analyticName &&
                                filters &&
                                selected
                                ? false
                                : true
                        }
                    >
                        {props.loadingAPI ? (
                            <BeatLoader
                                color={"rgb(54, 215, 183)"}
                                loading={props.loadingAPI}
                                size={6}
                            />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </div>
            </div>
        </div >
    );
};
const mapStateToProps = (state) => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        allowedAppsData: state.initialData.allowedApps,
        analysisDropdown: state.AnalyticsDesignerData.AnalysisDropdownData,
        analyticsfiltersDropdown: state.AnalyticsDesignerData.AnalyticsFilters,
        accessData: state.addMultipleConfigurationData.accessDataNew,
        appRightsData: state.addMultipleConfigurationData.appRights,
        orgAppTitleData: state.addMultipleConfigurationData.orgAppTitleData,
        configData: state.profitOptimizationData.priceCalForListData,
        configAttributeData: state.profitOptimizationData.qualifierConfigData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (data) => dispatch(addAnalysisDesigner(data, null, null, "dashboard")),
        allowedApps: () => dispatch(allowedApps()),
        // getAnalysisDesignerList:() => dispatch(getAnalysisDesignerList()),
        getAnalyticsFilters: (applicationType, functionality, personnelType) =>
            dispatch(
                getAnalyticsFilters(applicationType, "dashboard", personnelType)
            ),
        getAccessData: () => dispatch(getAccessDataNew()),
        postAccessData: (formData, id) => dispatch(postAccessData(formData, id)),
        addUser: (name) => dispatch(addUser(name)),
        deleteUser: (id) => dispatch(addUser(id, "delete")),
        appRights: (app) => dispatch(appRights(app)),
        getOrganizationAppTitle: () => dispatch(getOrganizationAppTitle()),
        getConfigData: (pagination, limit) => dispatch(getAnaylsisFormula(pagination, limit)),
        getAttributesConfig: (pagination, limit) => dispatch(getAttributesConfig(pagination, limit)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDashboardDesigner);
