import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Breadcrumbs,

    Grid,
    Card, OutlinedInput
} from '@mui/material';
import { connect } from 'react-redux';
import { getSingleTerritoryData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import { LabelText } from '../../components/Inputs';
import {Link} from "react-router-dom"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        marginTop: -20
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        padding: 20
    },
    treeFont: {
        font: '13px',
        color: '#0D1333',
        padding: '10px',
        height: 35,
        width:window.screen.width * .25,
    },
    
    nodeContainer: {
        padding: 20,
        width: '100%',
        overflowY: 'scroll ',
        flex: 1,
        height: '100%',
        marginLeft: '-30px'
        

    }
}));

const ViewTerritory = props => {
    useEffect(() => {

        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);

        if (appId)
            props.getSingleTerritoryData(appId);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [treeData, setTreeData] = React.useState([]);
    const [flatData, setFlatData] = React.useState([]);
    const [territoryType, setTerritoryType] = React.useState(localStorage.getItem('territory-type'))


    useEffect(() => {
        if (props.territoryData)
            setTreeData(props.territoryData)
    }, [props.territoryData]);

    return (

        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                  to='/terittory'
                >
                    Territory
                </Link>
                <Typography color="textPrimary" variant='h4'>View Territory</Typography>
            </Breadcrumbs>
            <Grid container
                style={{ paddingTop: 20, display: territoryType != 'Linear' ? 'block' : 'none' }}>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ height: '100vh' }}
                >


                    {treeData ?
                        <div className={classes.nodeContainer}>
                            <SortableTree
                                treeData={toggleExpandedForAll({
                                    treeData: [treeData],
                                    expanded: true
                                })}
                                style={{ marginTop:5, align: 'center'}}

                                isVirtualized={false}
                                generateNodeProps={({ node, path }) => ({
                                    title: (
                                        <div>
                                            <OutlinedInput
                                                 style={{ width:150, marginRight: 10,borderColor:'black' }}
                                                className={classes.treeFont}
                                                value={node.territory_name}
                                                readOnly
                                            />
                                            <OutlinedInput
                                                style={{ width:150, marginRight: 10,borderColor:'black' }}
                                                className={classes.treeFont}
                                                value={node.territory_id}
                                                readOnly
                                            />
                                            <OutlinedInput
                                            style={{width:"320px",borderColor:'black'}}
                                                className={classes.treeFont}
                                                value={node.territory_details}
                                                readOnly
                                            />
                                        </div>
                                    )
                                })}
                            />
                        </div>
                        :
                        <Typography style={{ marginTop: 20 }} variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </Grid>
            </Grid>
            <div className={classes.bodyContainer}
                style={{ paddingTop: 20, display: territoryType == 'Linear' ? 'block' : 'none' }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <LabelText heading='Territory Name' twoline='true' />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <LabelText heading='Territory ID' twoline='true' />
                                </Grid>

                            </Grid>
                        </div>
                    </form>
                </Card>

            </div>

        </div>


    );
};

const mapStateToProps = state => {
    return {
        territoryData: state.customerData.territorySingleData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleTerritoryData: (id) => dispatch(getSingleTerritoryData(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTerritory);
