import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main
    }
}));
const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Customer Master': '/customer-master' },
    {
        'Product Master': [
            { 'Material Master': '/material-master' },
            { 'Sales Bundle': '/bom' },
            { 'Product Group': '/product-group' },
            { 'Product Feature': '/product-feature' },
        ]
    },
    { 'Supplier Master': '/supplier-master' },
    {
        'Membership': [
            { 'Master Roster': '/membership' },
            { 'Contract XREF': '/membership-contract' },
            { 'Contract Group': '/membership-contract-group' },
            { 'On Demand Query': '/dynamic-query' }
        ]
    },
    { 'Employee Master': '/employee' },
    { 'Territory Master': '/terittory' },
    { 'Attributes': '/attributes' },
    { 'Currency Conversion': '/currency-conversion' },
    { 'UoM': '/uom' },

    {
        'XREF': [
            { 'Customer XREF': '/customer-x-master' },
            { 'Material XREF': '/material-x-master' },
            { 'Contract XREF': '/contract-xref' },
            { 'COT XREF': '/cot-xref' },
        ]
    },
    { 'Configuration': '/master-data-config' },
]
const subMenuItems = [
    {
        'GPO Roster': [
            { 'Excel Upload': { pathname: '/membership/membership-excel-upload', state: 'MembershipGPORosterFormats' } },
            { 'Roster Search': '/gpo-address-search' }
        ]
    },
    {
        'Tier Activation': [
            { 'Excel Upload': { pathname: '/membership/membership-excel-upload', state: 'MembershipGPOTierActivationFormats' } },
            { 'Activation Search': '/tier-address-search' }
        ]
    },
]
const MasterData = (props) => {
    const classes = useStyles();
    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}
                    >{Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]} style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                            {Object.keys(item)[0] == 'Membership' && subMenuItems.map(item => {
                                return (
                                    <NavDropdown title={Object.keys(item)[0]} className={classes.navBgColor} style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                        </NavDropdown>
                    )
                }
            })}
        </Nav >
    );
};
const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MasterData);