import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, Table, TableCell, TableHead, TableRow,
    TableContainer, TableBody, Button, IconButton,
    Dialog, DialogActions, DialogContent, DialogContentText, Fab, ListItemText, ListItem, List, DialogTitle
} from '@mui/material';
import { Edit, Visibility, Publish, DeleteForever, AddBoxOutlined, LibraryAdd } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    getCustomerMasterQuery, customerMasterUpload,
    getCustomerMasterQueryList, deleteMasterQuery
} from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { cloneMasterQuery } from '../../redux/actions/DataSetup/DataSetupTransaction/AuthAction';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    dialog: {
        justifyContent: 'center',
        padding: '20px 20px 27px 20px'
    },
    dialogContent: {
        padding: '43px 37px 0px 37px !important'
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    errorlOutlineColor: {
        color: '#FF1A1A',
        marginRight: 5
    },
    paperContainer: {
        minWidth: window.screen.width * .40,
        padding: "10px 30px 10px 30px"

    },
}));
const CustomerMasterExcelUpload = props => {
    useEffect(() => {
        props.getCustomerMasterQueryList();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const inputBatchFile = useRef(null)
    const [currentBatchFile, setCurrentBatchFile] = useState(undefined);
    const [currentBatchFileName, setCurrentBatchFileName] = useState('');
    const [formatBatch, setFormatBatch] = useState('');
    const [currentBatchEditableID, setCurrentBatchEditableID] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [errorList, setErrorList] = useState('');
    const [dialog, setDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const handleDrop = (event, item) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadCustomerMasterData(data, currentEditableID);
        event.target.value = '';
        setOpen(true)
    }
    const handleBatchDrop = (event, item) => {

        const data = new FormData();
        data.append('file', event.target.files[0]);
        // setCurrentBatchFile(data);
        props.uploadCustomerMasterData(data, currentBatchEditableID);
        // setCurrentBatchFile(undefined);
        // setCurrentBatchFileName('');
        event.target.value = '';
        setOpen(true)

    }
    useEffect(() => {
        if (props.queryUploadError) {
            setErrorList(props.queryUploadError);
            if (Object.entries(props.queryUploadError).length > 0 && props.queryUploadError.error_list != null && open)
                setDialog(true)
        }
    }, [props.queryUploadError]);
    function runBatchQuery(item, type) {
        setCurrentBatchEditableID(item.id);
        inputBatchFile.current.click();
    }
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('customer-master-query', JSON.stringify(item))
        // props.getCustomerMasterQuery(item.id,'Employee');
        history.push('/employee/employee-excel-upload/edit/' + item.id);
    }
    function viewQuery(item) {
        props.getCustomerMasterQuery(item.id);
        localStorage.setItem('customer-master-query', JSON.stringify(item))
        history.push('/employee/employee-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deleteMasterQuery(item.id, 'Employee');
    }
    const clone = (item) => {
        props.cloneMasterQuery(item.id, 'Employee Master');
    }
    function handleError() {
        setDialog(false)
        setOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Employee Master Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={() => history.push('/employee/employee-excel-upload/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
                {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxOutlined />}
                    classes={{
                        startIcon: !isDesktop && classes.startIcon,
                        root: !isDesktop && classes.container
                    }}
                    onClick={() => history.push('/employee/employee-excel-upload/add-query')}
                >
                    {isDesktop ? 'Add Format' : ''}
                </Button> */}
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer style={{ marginTop: -20 }}>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.queryListData
                                .sort(function (a, b) {
                                    return a.id > b.id ? 1 : -1;
                                })
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                            {props.loading ?
                                                <TableCell width={300} align='center'>
                                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                </TableCell>
                                                :
                                                <TableCell align='center' width={300}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton onClick={() => clone(item)} classes={{ root: classes.IconButton }} size={'small'}>
                                                            <img src={require('../../library/images/Copy.png')} alt='' />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <Visibility color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e, item)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <Publish color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputBatchFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleBatchDrop(e, item)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runBatchQuery(item, 'Material Master Data')}
                                                            size="large">
                                                            <UploadFileIcon color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                        </IconButton>
                                                        <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => {
                                                                    setOpenDelete(true);
                                                                }}
                                                                size="large">
                                                                <DeleteForever color="disabled" classes={{ root: classes.caption }} disabled={true} />
                                                            </IconButton>
                                                            <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>

                                                                <DialogContent classes={{
                                                                    root: classes.dialogContent
                                                                }}>
                                                                    <Typography variant="h4">
                                                                        <DialogContentText>
                                                                            Do you want to delete data?
                                                                        </DialogContentText>
                                                                    </Typography>
                                                                </DialogContent>
                                                                <DialogActions classes={{
                                                                    root: classes.dialog
                                                                }}>
                                                                    <Button onClick={() => setOpenDelete(false)} autoFocus color="primary" variant="outlined">No</Button>
                                                                    <Button onClick={() => {
                                                                        deleteQuery(item);
                                                                        setOpenDelete(false);
                                                                    }} color="primary" autoFocus variant="contained">Yes</Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No invoice query list added.
                </Typography>
            }
            <Dialog
                fullWidth
                maxWidth="xs"
                open={dialog}
                classes={{ paper: classes.paperContainer }}
            >   <DialogActions style={{ position: 'absolute', marginLeft: 420 }}>
                    <Button color="primary" variant="contained" onClick={handleError}>Continue</Button>
                </DialogActions>
                <DialogTitle >File Upload Summary  -  <span style={{ color: 'blue' }}>{errorList.file_name}</span></DialogTitle>
                <DialogContent classes={{
                    root: { padding: '43px 37px 0px 37px !important' }
                }}>

                    <div style={{ color: 'green', marginBottom: 20 }}>{errorList.success_msg}</div>
                    <span style={{ color: 'grey' }}>Error Details</span>
                    <List>
                        {errorList && errorList.error_list && errorList.error_list.map((item, index) => {
                            return (<ListItem>
                                <ErrorOutline classes={{ root: classes.errorlOutlineColor }} />
                                <ListItemText style={{ color: '#FF1A1A' }}
                                    primary={item}
                                />
                            </ListItem>
                            )
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.customerMasterListData,
        loading: state.dataSetupData.loading,
        queryUploadError: state.dataSetupData.uploadFailureData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterQueryList: () => dispatch(getCustomerMasterQueryList('Employee')),
        uploadCustomerMasterData: (file, currentEditableID) => dispatch(customerMasterUpload(file, currentEditableID, 'Employee')),
        getCustomerMasterQuery: (id) => dispatch(getCustomerMasterQuery(id, 'Employee')),
        deleteMasterQuery: (id, type) => dispatch(deleteMasterQuery(id, type)),
        cloneMasterQuery: (id, type) => dispatch(cloneMasterQuery(id, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterExcelUpload);