import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Breadcrumbs,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getSingleAttributeMapping } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Moment from 'moment';
import { Link } from 'react-router-dom';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const ViewAttributeMapping = props => {
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getSingleAttributeMapping(appId)
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [mappedAttribute, setMappedAttribute] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const [sourceTableName, setSourceTableName] = React.useState('');
    const [sourceAttribute, setSourceAttribute] = React.useState('');
    const [updateCondition, setUpdateCondition] = React.useState('');
    const [applicationType, setApplicationType] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [optionData, setOptionData] = React.useState('');
    useEffect(() => {
        if (props.attributeMapData) {
            setApplicationType(props.attributeMapData.application_type)
            setSourceDataType(props.attributeMapData.source_data_type)
            if (props.attributeMapData.labeled_mapped_attribute)
                setMappedAttribute(props.attributeMapData.labeled_mapped_attribute)
            if (props.attributeMapData.source_table_name)
                setSourceTableName(props.attributeMapData.source_table_name)
            if (props.attributeMapData.labeled_source_attribute || props.attributeMapData.source_attribute)
                setSourceAttribute(props.attributeMapData.labeled_source_attribute ? props.attributeMapData.labeled_source_attribute : props.attributeMapData.source_attribute)
            if (props.attributeMapData.update_condition)
                setUpdateCondition(props.attributeMapData.update_condition)
            if (props.attributeMapData.options)
                setOptionData(props.attributeMapData.options)
        }
    }, [props.attributeMapData]);

    return (
        <div className={classes.root}>
            <Typography color="primary" variant='h1'>View Attribute Mapping</Typography>
            <div className={classes.bodyContainer}>
                {props.attributeMapData ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Table Name</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Attribute </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Option</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Update Condition</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Target Attribute </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>

                                <StyledTableRow  >
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{sourceDataType}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{sourceTableName.replace(/_/g, ' ')}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{sourceAttribute.replace(/_/g, ' ')}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{optionData}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{updateCondition}</TableCell>
                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{mappedAttribute}</TableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>

                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </div>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        attributeMapData: state.AttributeMapData.AttributeMapDataSingle,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSingleAttributeMapping: (id) => dispatch(getSingleAttributeMapping(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAttributeMapping);


