import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    FormLabel,
    MenuItem,
    Select,
    OutlinedInput,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { addAttributeMappingDefaultDesigner, getSingleAttributeMapping, getDefaultValuesAllDataNew, getLblDispDesFieldValue, getTargetSourceAttributeData } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { AttributeArray } from './Attribute'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Link } from 'react-router-dom';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        height: 35
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    inputTwoLine: {
        marginTop: 3,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    addIcon: {
        marginTop: 54,
        marginLeft: 11,
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2)',
            cursor: 'pointer'
        },
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    paper: { minWidth: window.screen.width * .50 },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        marginTop: -1,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    }
}));

const AddAttribute = props => {
    const classes = useStyles();
    const [mappedAttribute, setMappedAttribute] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const [sourceTableName, setSourceTableName] = React.useState('');
    const [sourceAttribute, setSourceAttribute] = React.useState('');
    const [sourceAttributeValues, setSourceAttributeValues] = React.useState([]);
    const [updateCondition, setUpdateCondition] = React.useState('');
    const [targetAttributeValues, setTargetAttributeValues] = React.useState('');
    const [optionData, setOptionData] = React.useState('');
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const format = { source_table_name: '', attribute_name: '', attribute_values: [], options: '' }
    const [allDataNew, setAllDataNew] = React.useState([]);
    const [tableNames, setTableNames] = React.useState([]);
    const [boxCount, setBoxCount] = React.useState(1);
    const sourceTableNameArray = [
        'customer_master', 'employee_master', 'material_master', 'supplier_master', 'xref_customer_master', 'xref_material_master', 'membership_masters', 'staging_direct_sales_data'
    ]
    const sourceDataTypeArray = ['Direct - Purchase Data', 'Direct - Sales Data', 'Indirect - Sales Data'];
    const optionDataArray = [
        { key: 'EQUAL', value: 'EQUAL' },
        { key: 'NOT EQUAL', value: 'NOT EQUAL' },
    ];
    const targetAttributeArray = ['region', 'country', 'base_uom', 'class_of_trade', 'customer_chain', 'customer_classification', 'customer_group', 'customer_group1', 'customer_group2', 'customer_group3',
        'customer_group4', 'customer_group5', 'customer_industry', 'customer_name',
        'material_group', 'material_type', 'material_group1', 'material_group2', 'material_description', 'product_hierarchy',
        'product_hierarchy1', 'product_hierarchy2', 'product_hierarchy3', 'product_hierarchy4', 'product_hierarchy5', 'supplier_number',
        'supplier_name', 'supplier_group', 'supplier_customer_number', 'supplier_type', 'membership_owner_id', 'membership_owner_name', 'direct_parent_customer_number', 'top_parent_customer_number',
        'product_group_id', 'product_group_name', 'employee_name', 'territory_id', 'channel_partner_end_customer_id', 'customer_number', 'channel_partner_material_id', 'material_number',
        'billing_type', 'flex_attribute01', 'flex_attribute02', 'flex_attribute03', 'flex_attribute04', 'flex_attribute05', 'flex_attribute06', 'flex_attribute07', 'flex_attribute08', 'flex_attribute09', 'flex_attribute10'
    ]

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setEditID(appId)
        if (appId)
            props.getSingleAttributeMapping(appId)
    }, []);

    useEffect(() => {
        if (tableNames && tableNames.length > 0) {
            var data1 = {
                "table_name": tableNames,
                "data_type": 'string',
            }
            props.getTargetSourceAttributeDataSA(data1);
        }
    }, [tableNames]);

    useEffect(() => {
        if (props.attributeMapData) {
            // var data = { "data_source": props.attributeMapData.source_data_type }
            // props.getLblDispDesFieldValue(data, 'post');
            if (props.attributeMapData.source_data_type === "Direct - Sales Data") {
                var data = {
                    "table_name": ['direct_sales_data'],
                    "data_type": 'string',
                }
                props.getTargetSourceAttributeData(data);
            }
            else if (props.attributeMapData.source_data_type === "Direct - Purchase Data") {
                var data = {
                    "table_name": ['purchase_data'],
                    "data_type": 'string',
                }
                props.getTargetSourceAttributeData(data);
            }
            else {
                var data = {
                    "table_name": ['indirect_sales_data'],
                    "data_type": 'string',
                }
                props.getTargetSourceAttributeData(data);
            }
            // props.onLoadingLabelDesignerNew('field-label-designer', 'Customer Rebate', props.attributeMapData.source_data_type);
            setSourceDataType(props.attributeMapData.source_data_type)
            if (props.attributeMapData.mapped_attribute)
                setMappedAttribute(props.attributeMapData.mapped_attribute)
            if (props.attributeMapData.multiple_attribute_filters)
                setSourceTableName(props.attributeMapData.multiple_attribute_filters[0].source_table_name)
            if (props.attributeMapData.source_attribute)
                setSourceAttribute(props.attributeMapData.source_attribute)
            if (props.attributeMapData.source_attribute_values)
                setSourceAttributeValues(props.attributeMapData.source_attribute_values)
            if (props.attributeMapData.update_condition)
                setUpdateCondition(props.attributeMapData.update_condition)
            if (props.attributeMapData.target_attribute_values)
                setTargetAttributeValues(props.attributeMapData.target_attribute_values)
            if (props.attributeMapData.options)
                setOptionData(props.attributeMapData.options)
            if (props.attributeMapData.multiple_attribute_filters)
                setAllDataNew(props.attributeMapData.multiple_attribute_filters)
            if (props.attributeMapData.multiple_attribute_filters)
                setBoxCount(props.attributeMapData.multiple_attribute_filters.length)
            // if (props.attributeMapData.multiple_attribute_filters) {
            //     const Names = props.attributeMapData.multiple_attribute_filters.map(filter => filter.source_table_name);
            //     setTableNames(Names);
            // }
            if (props.attributeMapData.multiple_attribute_filters) {
                const uniqueAttributeNames = new Set();
                props.attributeMapData.multiple_attribute_filters.forEach(filter => {
                    uniqueAttributeNames.add(filter.source_table_name);
                });
                const tableNames = Array.from(uniqueAttributeNames);
                setTableNames(tableNames);
            }

        }
    }, [props.attributeMapData]);

    // useEffect(() => {
    //     if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && sourceTableName && AttributeArray) {
    //         AttributeArray[sourceTableName].map((item, index) => {
    //             if (props.labelNewAPIData.field_label_attributes[item]) {
    //                 setKeyValuePairObject((keyValuePairObject) => ({
    //                     ...keyValuePairObject,
    //                     [item]: props.labelNewAPIData.field_label_attributes[item]['current']
    //                 }));
    //             } else {
    //                 setKeyValuePairObject((keyValuePairObject) => ({
    //                     ...keyValuePairObject,
    //                     [item]: AttributeArray[sourceTableName][index]
    //                 }));
    //             }
    //         })
    //     } else {
    //         setKeyValuePairObject([])
    //     }
    // }, [AttributeArray, props.labelNewAPIData, sourceTableName]);
    useEffect(() => {
        if (props.labelSourceAttributeAllDataSa && Object.keys(props.labelSourceAttributeAllDataSa).length > 0) {
            setKeyValuePairObject(props.labelSourceAttributeAllDataSa)
        } else {
            setKeyValuePairObject([])
        }
    }, [props.labelSourceAttributeAllDataSa]);

    // useEffect(() => {
    //     if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && targetAttributeArray) {
    //         targetAttributeArray.map((item, index) => {
    //             if (props.labelNewAPIData.field_label_attributes[item]) {
    //                 setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
    //                     ...keyValuePairObjectNew,
    //                     [item]: props.labelNewAPIData.field_label_attributes[item]['current']
    //                 }));
    //             } else {
    //                 setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
    //                     ...keyValuePairObjectNew,
    //                     [item]: targetAttributeArray[index]
    //                 }));
    //             }
    //         })
    //     } else {
    //         setKeyValuePairObjectNew([])
    //     }
    // }, [props.labelNewAPIData]);
    useEffect(() => {
        if (props.labelSourceAttributeAllData && Object.keys(props.labelSourceAttributeAllData).length > 0) {
            setKeyValuePairObjectNew(props.labelSourceAttributeAllData)
        } else {
            setKeyValuePairObjectNew([])
        }
    }, [props.labelSourceAttributeAllData]);

    function clearAll() {
        setSourceDataType('');
    }

    for (let i = 0; i < boxCount; i++) {
        if (allDataNew && allDataNew.length < boxCount) {
            setAllDataNew([...allDataNew, format])
        }
    }

    function handleOnSubmit() {
        var newArray = []
        allDataNew.map(item => {
            if (item.attribute_name !== '') {
                newArray.push(item)
            }
        })
        var data = {
            "source_data_type": sourceDataType,
            "mapped_attribute": mappedAttribute,
            //"source_table_name": sourceTableName,
            //"source_attribute": sourceAttribute,
            // "source_attribute_values": sourceAttributeValues,
            "update_condition": updateCondition,
            "target_attribute_values": targetAttributeValues,
            //"options": optionData,
            "multiple_attribute_filters": newArray
        };
        props.onSubmit(data, editID);
        handleClear();
    }

    const [sourceDataType, setSourceDataType] = React.useState('');
    function handleClear() {
        setSourceDataType('')
        setOptionData('')
        setSourceTableName('')
        setSourceAttribute('')
        setSourceAttributeValues([])
        setMappedAttribute('')
        setTargetAttributeValues('')
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        setSourceTableName('')
        setSourceAttribute('')
        setSourceAttributeValues([])
        setMappedAttribute('')
        setTargetAttributeValues('')
        // var data = { "data_source": e.target.value }
        // props.getLblDispDesFieldValue(data, 'post');
        if (e.target.value === "Direct - Sales Data") {
            var data = {
                "table_name": ['direct_sales_data'],
                "data_type": 'string',
            }
            props.getTargetSourceAttributeData(data);
        }
        else if (e.target.value === "Direct - Purchase Data") {
            var data = {
                "table_name": ['purchase_data'],
                "data_type": 'string',
            }
            props.getTargetSourceAttributeData(data);
        }
        else {
            var data = {
                "table_name": ['indirect_sales_data'],
                "data_type": 'string',
            }
            props.getTargetSourceAttributeData(data);
        }
    }
    // const handleSourceTableName = (e) => {
    //     setSourceTableName(e.target.value)
    //     setSourceAttribute('')
    //     setMappedAttribute('')
    // }
    function handleSourceAttributeValue(event) {
        setSourceAttributeValues([...sourceAttributeValues, event]);
    }
    function handleSourceDeleteAttributeValue(value) {
        var deleteAttValue = sourceAttributeValues.filter(item => item !== value)
        setSourceAttributeValues(deleteAttValue)
    }

    function handleDeleteAttributeValue(value, index) {
        const deletedAllDataNew = allDataNew[index]['attribute_values'].filter(item => item !== value)
        const updatedAllDataNew = [...allDataNew];
        updatedAllDataNew[index]['attribute_values'] = deletedAllDataNew;
        setAllDataNew(updatedAllDataNew)

    }

    function handleSourceTableName(newValue, index) {
        allDataNew[index]['source_table_name'] = (newValue.target.value)
        setAllDataNew([...allDataNew])
        setSourceTableName(newValue.target.value)
        if (tableNames && !(tableNames.includes(newValue.target.value))) {
            setTableNames([...tableNames, newValue.target.value])
        }
    }

    function handleAttributeName(newValue, index) {
        allDataNew[index]['attribute_name'] = (newValue.target.value)
        setAllDataNew([...allDataNew])
    }

    function handleAttributeValues(newValue, index) {
        // var data = allDataNew[index]['attribute_values'];
        // data.push(newValue[newValue.length - 1])
        allDataNew[index]['attribute_values'] = newValue

    }

    function handleOptions(newValue, index) {
        allDataNew[index]['options'] = (newValue.target.value)
        setAllDataNew([...allDataNew])
    }

    function addBoxCount() {
        setBoxCount(boxCount + 1);
    }

    function removeBoxCount() {
        if (boxCount > 1) {
            setBoxCount(boxCount - 1);
            allDataNew.pop();
        }
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/attribute-mapping'
                >
                    Attribute Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Attribute Mapping</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid item md={11} xs={12} style={{ display: 'flex', gap: 15 }}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} required>
                                                Source Data Type
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    disableUnderline
                                                    value={sourceDataType}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    onChange={handleSourceDataType}
                                                    displayEmpty
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                >
                                                    {sourceDataTypeArray && sourceDataTypeArray
                                                        .map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel required className={classes.formDiv}>
                                                Update Condition
                                            </FormLabel>
                                            <Select
                                                value={updateCondition}
                                                style={{ maxHeight: "50px" }}
                                                onChange={(e) => setUpdateCondition(e.target.value)}
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >

                                                <MenuItem key={'blank'} value={'blank'} >
                                                    Blank Record
                                                </MenuItem>
                                                <MenuItem key={'all'} value={'all'} >
                                                    All Records
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </Grid>
                                    {/* </Grid>
                                <Grid item md={5} xs={12} style={{ display: 'flex', gap: 15 }}> */}
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} required>
                                                Target Attribute
                                            </FormLabel>
                                            <div className={classes.dropdownContainer}>
                                                <Select
                                                    disableUnderline
                                                    value={mappedAttribute}
                                                    onChange={(e) => setMappedAttribute(e.target.value)}
                                                    style={{ textTransform: 'capitalize' }}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                >
                                                    {/* {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                                    Object.entries(keyValuePairObjectNew)
                                                        .sort()
                                                        .map(([key, value]) => {
                                                            return (
                                                                <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                    {value.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            );
                                                        })
                                                    } */}
                                                    {sourceDataType === 'Indirect - Sales Data' && keyValuePairObjectNew && keyValuePairObjectNew['indirect_sales_data'] && keyValuePairObjectNew['indirect_sales_data'].map(({ key, label }) => (
                                                        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                    {sourceDataType === 'Direct - Sales Data' && keyValuePairObjectNew && keyValuePairObjectNew['direct_sales_data'] && keyValuePairObjectNew['direct_sales_data'].map(({ key, label }) => (
                                                        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                    {sourceDataType === 'Direct - Purchase Data' && keyValuePairObjectNew && keyValuePairObjectNew['purchase_data'] && keyValuePairObjectNew['purchase_data'].map(({ key, label }) => (
                                                        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel variant="h5" className={classes.formDiv} >
                                                Target Attribute Values
                                            </FormLabel>
                                            <OutlinedInput
                                                value={targetAttributeValues}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setTargetAttributeValues(e.target.value)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {(() => {
                                    let td = [];
                                    for (let i = 0; i < boxCount; i++) {
                                        if (allDataNew && allDataNew.length < boxCount) {
                                            setAllDataNew([...allDataNew, format])
                                        }
                                        td.push(<>
                                            <Grid item md={11} xs={12} style={{ display: 'flex', gap: 15 }}>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.formDiv}>
                                                            Source Table Name
                                                        </FormLabel>
                                                        <Select
                                                            value={allDataNew[i] && allDataNew[i]['source_table_name']}
                                                            onChange={(e) => handleSourceTableName(e, i)}
                                                            style={{ textTransform: 'capitalize' }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >

                                                            {sourceTableNameArray.map((item) => {
                                                                return (item != 'xref_material_master' && item != 'xref_customer_master') ?
                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                        {item.replace(/_/g, ' ')}
                                                                    </MenuItem>
                                                                    :
                                                                    sourceDataType == 'Indirect - Sales Data' &&
                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                        {item == 'xref_material_master' ? 'Material Master XREF' : 'Customer Master XREF'}
                                                                    </MenuItem>;
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.formDiv}>
                                                            Source Attribute
                                                        </FormLabel>
                                                        <Select
                                                            value={allDataNew[i] && allDataNew[i]['attribute_name']}
                                                            style={{ maxHeight: "50px", textTransform: 'capitalize' }}
                                                            onChange={(e) => handleAttributeName(e, i)}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {allDataNew[i] && allDataNew[i]['source_table_name'] && keyValuePairObject[allDataNew[i]['source_table_name']] && keyValuePairObject[allDataNew[i]['source_table_name']].map(({ key, label }) => (
                                                                <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                    {label}
                                                                </MenuItem>
                                                            ))}
                                                            {/* {
                                                                targetAttributeArray && targetAttributeArray.length > 0 && targetAttributeArray.map((key, index) => (
                                                                    <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                        {key.replace(/_/g, ' ')}
                                                                    </MenuItem>
                                                                ))} */}

                                                            {/* {keyValuePairObject && Object.keys(keyValuePairObject).length > 0 &&
                                                                Object.entries(keyValuePairObject)
                                                                    .sort()
                                                                    ?.map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                                {value.replace(/_/g, ' ')}
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                            } */}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formDiv}>
                                                            Option
                                                        </FormLabel>
                                                        <Select
                                                            value={allDataNew[i] && allDataNew[i]['options']}
                                                            onChange={(e) => handleOptions(e, i)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value='' key=''></MenuItem>
                                                            {optionDataArray?.map((item) => {
                                                                return (
                                                                    <MenuItem value={item.key} key={item.key}>
                                                                        {item.value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.formDiv}>
                                                            Source Attribute Values
                                                        </FormLabel>
                                                        <ChipInput data={allDataNew[i] && allDataNew[i]['attribute_values']} onChange={newValue => handleAttributeValues(newValue, i)} />
                                                        {/* <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                chipContainer: classes.chipContainer,
                                                                label: classes.chipLabel
                                                            }}
                                                            //defaultValue={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                            value={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                            onChange={(e) => handleAttributeValues(e, i)}
                                                            onDelete={(chip) => handleDeleteAttributeValue(chip, i)}
                                                            disableUnderline={true}
                                                            blurBehavior='add'
                                                        /> */}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {i == boxCount - 1 &&
                                                <>
                                                    <RemoveCircleIcon
                                                        className={classes.addIcon}
                                                        color={"primary"}
                                                        onClick={removeBoxCount}
                                                    />
                                                    <AddCircleIcon
                                                        className={classes.addIcon}
                                                        color={"primary"}
                                                        onClick={addBoxCount}
                                                    />
                                                </>
                                            }
                                        </>)
                                    }
                                    return td;
                                })()}
                            </Grid >

                        </div>
                    </form>
                </Card>

                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={sourceDataType && mappedAttribute && sourceTableName && updateCondition ? false : true
                        }>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id) => dispatch(addAttributeMappingDefaultDesigner(data, 'edit', id)),
        getSingleAttributeMapping: (id) => dispatch(getSingleAttributeMapping(id)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getTargetSourceAttributeData: (data) => dispatch(getTargetSourceAttributeData(data)),
        getTargetSourceAttributeDataSA: (data, type) => dispatch(getTargetSourceAttributeData(data, "SA")),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        attributeMapData: state.AttributeMapData.AttributeMapDataSingle,
        labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
        labelSourceAttributeAllData: state.addMultipleConfigurationData.sourceAttributeAllData,
        labelSourceAttributeAllDataSa: state.addMultipleConfigurationData.sourceAttributeAllDataSa,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute);