import {
    ATTRIBUTE_LIST_SUCCESS,
    ATTRIBUTE_LIST_FAIL,
    FETCH_START,
    FETCH_END,
    EDIT_ATTRIBUTES_SUCCESS,
    EDIT_ATTRIBUTES_FAIL,
    ATTRIBUTE_NAME_SUCCESS,
    DROPDOWN_START,
    DROPDOWN_END,
    DROPDOWN_DATA_SUCCESS
} from '../../actions/Attributes/ActionTypes';

const initialState = {
    loading: false,
    AttributeData: [],
    editAttributeData: null,
    attributeNameList: [],
    dropdownLoading: false,
    dropdownData: []
};

const AttributesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ATTRIBUTE_LIST_SUCCESS:
            return {
                ...state,
                AttributeData: action.data,
                loading: false
            };
        case ATTRIBUTE_NAME_SUCCESS:
            return {
                ...state,
                attributeNameList: action.data,
                loading: false
            };
        case ATTRIBUTE_LIST_FAIL:
            return {
                ...state,
                loading: false
            };
        case EDIT_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                editAttributeData: action.data,
                loading: false
            };
        case EDIT_ATTRIBUTES_FAIL:
            return {
                ...state,
                loading: false
            };
        case FETCH_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_END:
            return {
                ...state,
                loading: false
            }
        case DROPDOWN_START:
            return {
                ...state,
                dropdownLoading: true
            }
        case DROPDOWN_END:
            return {
                ...state,
                dropdownLoading: false
            }
        case DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                dropdownData: action.data,
                dropdownLoading: false
            };
        default:
            return state;
    }
};

export default AttributesReducer;