import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton
} from '@mui/material';
import { DeleteForever, Edit } from '@mui/icons-material';
import { tableIcons } from '../../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getMaterialMasterXRefData, getMaterialMasterXRefDetails, deleteCustomerMasterData } from '../../../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { MaterialXMasterToolbar } from '.';
import secureLocalStorage from 'react-secure-storage';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
}));

const MaterialXMasterUserTable = props => {
    useEffect(() => {
        props.getMaterialMasterXRefData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);

    useEffect(() => {
        if (props.materialMasterXRefData) {
            setDataRows(props.materialMasterXRefData.records);
        }
    }, [props.materialMasterXRefData]);

    function editMaterial(id) {
        navigateToEdit(id);
    }

    function navigateToEdit(id) {
        localStorage.setItem('materialMasterXRefEditId', id);
        props.getMaterialMasterXRefDetails(id);
        history.push({
            pathname: '/material-x-master/edit-materialmaster-xref/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteCustomerMasterData(id, 'CustomerXMaster')
    }

    function viewMaterial(id) {
        props.getMaterialMasterXRefData(id);
        history.push({
            pathname: '/material-x-master/view-materialmaster-xref/' + id,
            id: id
        });
    }
    const columns = [
        // {
        //     field: 'channel_partner_type',
        //     title: 'Channel Partner Type',
        //     type: 'string',
        //     editable: 'never',
        //     render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_type}</a>
        // },
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_id}</a>
        }
        ,
        {
            field: 'channel_partner_material_id',
            title: 'Channel Partner Material ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_material_id}</a>
        },
        {
            field: 'material_description',
            title: 'Material Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.material_description}</a>
        },
        {
            field: 'channel_partner_uom',
            title: 'Channel Partner UoM',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_uom}</a>
        },
        {
            field: 'ima_material_id',
            title: 'IMA Material ID',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.ima_material_id}</a>

        },
        {
            field: 'channel_partner_name',
            title: 'Channel Partner Name',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.channel_partner_name
            }</a>

        },
        {
            field: 'ima_uom',
            title: 'IMA UoM',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMaterial(rowData.ID)}> {rowData.ima_uom}</a>

        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editMaterial(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_material_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => deleteData(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading material master x ref. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Material XREF </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_material_xref-' + sessionStorage.getItem('application')) &&
                        <MaterialXMasterToolbar />}
                </div>
                {props.materialMasterXRefData && props.materialMasterXRefData.records && props.materialMasterXRefData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />

                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        materialMasterXRefData: state.customerData.materialMasterXRefData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMaterialMasterXRefData: (pagination, limit) => dispatch(getMaterialMasterXRefData(pagination, limit)),
        getMaterialMasterXRefDetails: (id) => dispatch(getMaterialMasterXRefDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'MaterialXMaster')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialXMasterUserTable);