import React, { useEffect } from 'react';
import {
    Card,
    Grid, CardHeader
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../components/Inputs';
import { connect } from 'react-redux';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
}));
const Miscellaneous = props => {
    const classes = useStyles();
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])
    function checkLabel(name) {
        return Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
            .map(([key, value]) => {
                if (key == name) {
                    return labelNewAPIData[name]['current']
                }
            })
    }
    return (
        <div className={classes.root}>
            <Card>
                <CardHeader
                    title="REPORTING DATA"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {props.miscellaneousData && props.fieldData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {props.miscellaneousData.negotiation_reason && props.fieldData.negotiation_reason &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.negotiation_reason.current} data={props.miscellaneousData.negotiation_reason} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.negotiation_outcome && props.fieldData.negotiation_outcome &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.negotiation_outcome.current} data={props.miscellaneousData.negotiation_outcome} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData && props.miscellaneousData.outcome_date && props.miscellaneousData.outcome_date && props.miscellaneousData.outcome_date != '0001-01-01T00:00:00Z' && props.fieldData.outcome_date &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.outcome_date.current} data={Moment.utc(props.miscellaneousData.outcome_date).format('MM/DD/YYYY')} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.pricing_strategy && props.fieldData.pricing_strategy &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pricing_strategy.current} data={props.miscellaneousData.pricing_strategy} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData && props.miscellaneousData.internal_signed_date && props.miscellaneousData.internal_signed_date != '0001-01-01T00:00:00Z' && props.fieldData.internal_signed_date &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.internal_signed_date.current} data={Moment.utc(props.miscellaneousData.internal_signed_date).format('MM/DD/YYYY')} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData && props.miscellaneousData.external_signed_date && props.miscellaneousData.external_signed_date != '0001-01-01T00:00:00Z' && props.fieldData.external_signed_date &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_signed_date.current} data={Moment.utc(props.miscellaneousData.external_signed_date).format('MM/DD/YYYY')} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData && props.miscellaneousData.contract_start_date && props.miscellaneousData.contract_start_date != '0001-01-01T00:00:00Z' && props.fieldData.contract_start_date &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_start_date.current} data={Moment.utc(props.miscellaneousData.contract_start_date).format('MM/DD/YYYY')} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData && props.miscellaneousData.contract_end_date && props.miscellaneousData.contract_end_date != '0001-01-01T00:00:00Z' && props.fieldData.contract_end_date &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_end_date.current} data={Moment.utc(props.miscellaneousData.contract_end_date).format('MM/DD/YYYY')} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.image_reference_id && props.fieldData.image_reference_id &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.image_reference_id.current} data={props.miscellaneousData.image_reference_id} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.contract_owner && props.fieldData.contract_owner &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_owner.current} data={props.miscellaneousData.contract_owner} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.contract_owner_type && props.fieldData.contract_owner_type &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_owner_type.current} data={props.miscellaneousData.contract_owner_type} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_contract_type && props.fieldData.external_contract_type &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_contract_type.current} data={props.miscellaneousData.external_contract_type} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_contract_number && props.fieldData.external_contract_number &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_contract_number.current} data={props.miscellaneousData.external_contract_number} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.sales_analyst && props.fieldData.sales_analyst &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.sales_analyst.current} data={props.miscellaneousData.sales_analyst} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.sales_manager && props.fieldData.sales_manager &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.sales_manager.current} data={props.miscellaneousData.sales_manager} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.contract_analyst && props.fieldData.contract_analyst &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_analyst.current} data={props.miscellaneousData.contract_analyst} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.contract_manager && props.fieldData.contract_manager &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.contract_manager.current} data={props.miscellaneousData.contract_manager} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.finance_analyst && props.fieldData.finance_analyst &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.finance_analyst.current} data={props.miscellaneousData.finance_analyst} twoline='true' />
                                </Grid>
                            }


                            {props.miscellaneousData.finance_manager && props.fieldData.finance_manager &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.finance_manager.current} data={props.miscellaneousData.finance_manager} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_contact_name && props.fieldData.external_contact_name &&
                                <Grid
                                    item

                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_contact_name.current} data={props.miscellaneousData.external_contact_name} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_delivery_options && props.fieldData.external_delivery_options &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_delivery_options.current} data={props.miscellaneousData.external_delivery_options} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_contact_email && props.fieldData.external_contact_email &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.external_contact_email.current} data={props.miscellaneousData.external_contact_email} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.external_contact_phone && props.fieldData.external_contact_phone &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.external_contact_phone.current} data={props.miscellaneousData.external_contact_phone} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.flex_fields_6 && props.fieldData.flex_fields_6 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_6.current} data={props.miscellaneousData.flex_fields_6} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.flex_fields_7 && props.fieldData.flex_fields_7 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_7.current} data={props.miscellaneousData.flex_fields_7} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.flex_fields_8 && props.fieldData.flex_fields_8 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_8.current} data={props.miscellaneousData.flex_fields_8} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.flex_fields_9 && props.fieldData.flex_fields_9 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_9.current} data={props.miscellaneousData.flex_fields_9} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.flex_fields_10 && props.fieldData.flex_fields_10 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_10.current} data={props.miscellaneousData.flex_fields_10} twoline='true' />
                                </Grid>
                            }
                            {props.miscellaneousData.document_id && props.fieldData.document_id &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                >
                                    <LabelText heading={props.fieldData.document_id.current} data={props.miscellaneousData.document_id} twoline='true' />
                                </Grid>
                            }

                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );

}

const mapStateToProps = state => {
    return {
        //miscellaneousData: state.addContractData.contractGeneralData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    }
}

export default connect(mapStateToProps)(Miscellaneous);