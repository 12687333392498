import React from 'react';
import { ExternalPaymentPostingForm } from './containers';

const ExternalPaymentPosting = props => {

    return (
        <ExternalPaymentPostingForm />
    );

};

export default ExternalPaymentPosting;