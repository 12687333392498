import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs,
    OutlinedInput, FormLabel, MenuItem, Select
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { addPaymentDesigner, getDefaultValues, getAllApprovalDesigner, allowedApps, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 9,
        height: 34
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
}));

const AddPaymentDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [paymentLevelArray, setPaymentLevelArray] = React.useState([]);
    const [approvalContractsContractArray, setApprovalContractsContractArray] = React.useState([]);
    const targetSystemArray = ['SAP', 'Oracle', 'Infor'];
    const documentAndPricingDateArray = ['System Date', 'Posting Date'];
    const [applicationType, setApplicationType] = React.useState('');

    const [primaryAnchor, setPrimaryAnchor] = React.useState('');
    const [dynamicField, setDynamicField] = React.useState([]);
    const [primaryAnchorArray, setPrimaryAnchorArray] = React.useState([]);
    const [flag, setFlag] = React.useState(false)
    const [reversalDocumentType, setReversalDocumentType] = React.useState('');
    const [assignmentNumber, setAssignmentNumber] = React.useState('');
    const [referenceNumber, setReferenceNumber] = React.useState('');
    const [clearingAccount, setClearingAccount] = React.useState('');
    const [clearingProfitCenter, setClearingProfitCenter] = React.useState('');
    const paymentMethodArray = ['Check', 'EFT', 'SD-Credit Memo', 'FI-Credit Memo', 'SD-Debit Memo', 'FI-Debit Memo'];
    const referenceNumberArray = ['Contract Number', 'Contract Internal Description'];
    const assignmentNumberArray = ['Contract Type', 'Contract Number', 'Contract Internal Description'];

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.getAllApprovalDesigner(1, 130);
    }, []);
    useEffect(() => {
        if (props.approvalData && props.approvalData.length > 0) {
            props.approvalData.map((item) => setApprovalContractsContractArray(approvalContractsContractArray => [...approvalContractsContractArray, item.contract_type]));
        }
    }, [props.approvalData]);


    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'payment_level') {
                            setPaymentLevelArray(item);
                        }
                        // if (item.field_id === 'contract_type' && item.app_source_type === applicationType) {
                        //     setContractTypeArray(item);
                        // }
                    })
                }
            }
        }
    });
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && props.dropdownData.field_label_attributes.contract_type) {
            const contractTypeValues = props.dropdownData.field_label_attributes.contract_type.drop_down_values || [];
            setContractTypeArray(contractTypeValues);
        }
    }, [props.dropdownData]);
    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            props.onLoadingDefault(props.allowedAppsData[0]);
            var data = {
                "data_source": "contract_header",
                "application_type": props.allowedAppsData[0]
            }
            props.fieldDesigner(data, 'post');
        }
    }, [props.allowedAppsData])

    const [contractType, setContractType] = React.useState('');
    const [currentContractArray, setCurrentContractArray] = React.useState([]);
    const [targetSystem, setTargetSystem] = React.useState('');
    const [documentType, setDocumentType] = React.useState('');
    const [documentDate, setDocumentDate] = React.useState('');
    const [pricingDate, setPricingDate] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [salesOrg, setSalesOrg] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [orderReason, setOrderReason] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [formFields, setFormFields] = React.useState({});


    function handleClear() {
        setApplicationType('');
        setContractType('');
        setPaymentMethod('');
        // setTargetSystem('');
        setDocumentType('');
        setSalesOrg('');
        setDistributionChannel('');
        setDivision('');
        setOrderReason('');
        setMaterialNumber('');
        setDocumentDate('');
        setPricingDate('');
        setFlag(false);
        setReversalDocumentType('');
        setReferenceNumber('');
        setAssignmentNumber('');
        setClearingProfitCenter('');
        setClearingAccount('');
    }

    function handleOnSubmit() {
        var data = {
            "app_type": applicationType,
            "payment_method": paymentMethod,
            // "target_system": targetSystem,
            "primary_anchor": primaryAnchor,
            [primaryAnchor]: contractType,
            "reversal_document_type": reversalDocumentType.toString(),
            "reference_number": referenceNumber,
            "assignment_number": assignmentNumber,
            "clearing_account": clearingAccount,
            "clearing_profit_center": clearingProfitCenter,

            "document_type": documentType,
            "sales_org": salesOrg,
            "distribution_channel": distributionChannel,
            "division": division,
            "order_reason": orderReason,
            "material_number": materialNumber,
            "document_date": documentDate,
            "pricing_date": pricingDate,

        };
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
    }
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
        setContractType('')
        props.onLoadingDefault(e.target.value, '', '', '', 'Contract Designer');
        var data = {
            "data_source": "contract_header",
            "application_type": e.target.value
        }
        props.fieldDesigner(data, 'post');
    }
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])

    useEffect(() => {
        var newArray = []
        if (props.dropdownData) {
            Object.values(props.dropdownData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    // if (item.mandatory || item.sort_details) {

                    if (item.key == 'contract_objective' || item.key == 'contract_group' || item.key == 'contract_strategy' || item.key == 'contract_type' || item.key == 'contract_sub_group' || item.key == 'calculation_method') {
                        newArray.push(item)
                    }
                    // if (item.key == 'source_data_type') {
                    //     setSourceDataTypeArray(item)
                    // }
                })
            setPrimaryAnchorArray(newArray.sort((a, b) => {
                return a.sort_details - b.sort_details;
            }))
        }
    }, [props.dropdownData]);

    useEffect(() => {
        primaryAnchorArray && Object.values(primaryAnchorArray)
            .map(item => {
                if (item.key == primaryAnchor)
                    setDynamicField(item)
            })
    }, [dynamicField, primaryAnchor])

    const handlePrimaryAnchor = (e) => {
        setPrimaryAnchor(e.target.value)
        setFlag(true)
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/payment-designer'
                >
                    Payment Designer
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Payment</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    {allowedApps.map((item) => {
                                        return (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                className={classes.dropdownContainer}
                                style={{ marginTop: 0 }}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Primary Anchor
                                </FormLabel>
                                <Select
                                    value={primaryAnchor}
                                    onChange={handlePrimaryAnchor}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {primaryAnchorArray && Object.values(primaryAnchorArray)
                                        .map((item, index) => {
                                            return (
                                                <MenuItem value={item.key} key={index}>
                                                    {item.current}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </Grid>
                            {dynamicField &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{ display: flag ? 'block' : 'none' }}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {dynamicField.current}
                                    </FormLabel>
                                    {dynamicField.drop_down_reference_value && dynamicField.drop_down_reference_value.length > 0 ?
                                        <Select
                                            value={contractType}
                                            onChange={(e) => setContractType(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {dynamicField.drop_down_reference_value.map((dynamicField, index) => {
                                                return (
                                                    <MenuItem value={dynamicField.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                        {dynamicField.desc}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                        :
                                        <OutlinedInput
                                            value={contractType}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setContractType(e.target.value)} />}

                                </Grid>
                            }
                            {/* {contractTypeArray &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        Contract Type
                                    </FormLabel>
                                    <Select
                                        value={contractType}
                                        onChange={(e) => setContractType(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {contractTypeArray && contractTypeArray.length > 0 &&
                                            contractTypeArray
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>

                                </Grid>
                            } */}
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required >
                                    Payment Method
                                </FormLabel>
                                <Select
                                    value={paymentMethod}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                >
                                    {paymentMethodArray &&
                                        paymentMethodArray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                </Select>
                            </Grid>
                            {/* <Grid
                                item
                                md={2}
                                xs={12}
                            >

                                <FormLabel className={classes.formLabel} required>
                                    Target System
                                </FormLabel>
                                <Select
                                    value={targetSystem}
                                    onChange={(e) => setTargetSystem(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {targetSystemArray &&
                                        targetSystemArray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                </Select>
                            </Grid>  */}
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Document Type
                                </FormLabel>
                                <OutlinedInput
                                    value={documentType}
                                    inputProps={{ min: "0", step: "1" }}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setDocumentType(e.target.value)} />
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} >
                                    Reversal Document Type
                                </FormLabel>
                                <OutlinedInput
                                    value={reversalDocumentType}
                                    inputProps={{ min: "0", step: "1" }}
                                    classes={{ root: classes.inputTwoLine }}
                                    onChange={(e) => setReversalDocumentType(e.target.value)} />

                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} >
                                    Document Date
                                </FormLabel>
                                <Select
                                    value={documentDate}
                                    onChange={(e) => setDocumentDate(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {documentAndPricingDateArray &&
                                        documentAndPricingDateArray
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                </Select>
                            </Grid>
                            <>
                                {paymentMethod ? (
                                    (paymentMethod === 'SD-Credit Memo' || paymentMethod === 'SD-Debit Memo') ? (
                                        <>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Sales Org
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={salesOrg}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setSalesOrg(e.target.value)} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Distribution Channel
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={distributionChannel}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setDistributionChannel(e.target.value)} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Division
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={division}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setDivision(e.target.value)} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Order Reason
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={orderReason}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setOrderReason(e.target.value)} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Material Number
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={materialNumber}
                                                    inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setMaterialNumber(e.target.value)} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Pricing Date
                                                </FormLabel>
                                                <Select
                                                    value={pricingDate}
                                                    onChange={(e) => setPricingDate(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    {documentAndPricingDateArray &&
                                                        documentAndPricingDateArray
                                                            .map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item} key={index}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                </Select>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel} >
                                                    Reference Number
                                                </FormLabel>
                                                <Select
                                                    value={referenceNumber}
                                                    onChange={(e) => setReferenceNumber(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    {referenceNumberArray &&
                                                        referenceNumberArray
                                                            .map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item} key={index}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                </Select>
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel} >
                                                    Assignment Number
                                                </FormLabel>
                                                <Select
                                                    value={assignmentNumber}
                                                    onChange={(e) => setAssignmentNumber(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    {assignmentNumberArray &&
                                                        assignmentNumberArray
                                                            .map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item} key={index}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                </Select>
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    Clearing Account
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={clearingAccount}
                                                    //inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setClearingAccount(e.target.value)} />
                                            </Grid>

                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <FormLabel className={classes.formLabel} >
                                                    Clearing Profit Center
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={clearingProfitCenter}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => setClearingProfitCenter(e.target.value)} />
                                            </Grid>
                                        </>
                                    )
                                ) : null}
                            </>
                        </Grid>
                    </div>
                </form>


                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button}
                        onClick={props.loadingAPI ? '' : handleOnSubmit}
                        disabled={
                            primaryAnchor && documentType && applicationType && paymentMethod
                                ? false : true
                        }>
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addPaymentDesigner(data)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        allowedApps: () => dispatch(allowedApps()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        allowedAppsData: state.initialData.allowedApps,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentDesigner);