import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, TextInputWithLabel, DropdownArray, DropdownKeyValue } from '../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        // borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    selectRoot: {
        padding: '18px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const AdditionalData = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    //constants
    const [accrualFrequencyData, setAccrualFrequencyData] = React.useState([]);
    const [accrualFrequencyName, setAccrualFrequencyName] = React.useState('');
    const [accrualLevelData, setAccrualLevelData] = React.useState([]);
    const [accrualLevelName, setAccrualLevelName] = React.useState('');
    const [copaLevelData, setCopaLevelData] = React.useState([]);
    const [copaLevelName, setCopaLevelName] = React.useState('');
    //arrays
    const [accrualFrequencyArray, setAccrualFrequencyArray] = React.useState([]);
    const [accrualCalendarArray, setAccrualCalendarArray] = React.useState([]);
    const [accrualLevelArray, setAccrualLevelArray] = React.useState([]);
    const [accruaGLAccountCreditArray, setAccruaGLAccountCreditArray] = React.useState([]);
    const [accruaGLAccountDebitArray, setAccruaGLAccountDebitArray] = React.useState([]);
    const [copaLevelArray, setCopaLevelArray] = React.useState([]);
    const [flexFields4Array, setFlexFields4Array] = React.useState([]);
    const [autoRunPpaArray, setAutoRunPpaArray] = React.useState([]);
    const [accrualCreditProfitCenterArray, setAccrualCreditProfitCenterArray] = React.useState([]);
    const [accrualDebitProfitCenterArray, setAccrualDebitProfitCenterArray] = React.useState([]);

    //new fields
    const [internalReferenceArray, setInternalReferenceArray] = React.useState([]);
    const [internalReference, setInternalReference] = React.useState('');
    const [postingsBlockArray, setPostingsBlockArray] = React.useState([]);
    const [postingsBlock, setPostingsBlock] = React.useState('');
    const [postingApproval, setPostingApproval] = React.useState('');
    const [financialPostingsStartDateArray, setFinancialPostingsStartDateArray] = React.useState([]);
    const [financialPostingsStartDate, setFinancialPostingsStartDate] = React.useState('');
    const [financialPostingsEndDateArray, setFinancialPostingsEndDateArray] = React.useState([]);
    const [financialPostingsEndDate, setFinancialPostingsEndDate] = React.useState('');
    const [autoRunPpa, setAutoRunPpa] = React.useState('');
    const [accrualCreditProfitCenter, setAccrualCreditProfitCenter] = React.useState('');
    const [accrualDebitProfitCenter, setAccrualDebitProfitCenter] = React.useState('');
    const [suppressNegativeRebate, setSuppressNegativeRebate] = React.useState(false);
    const [suppressNegativeRebateName, setsuppressNegativeRebateName] = React.useState(false);

    useEffect(() => {
        props.onChange(accrualValues);
    });


    const [apiResponse, setApiResponse] = React.useState([]);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse(props.queryListData.records);
        }
    }, [props.queryListData]);


    useEffect(() => {
        props.onLoadingDefault();
    }, []);
    const classes = useStyles();
    const { className } = props;
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState('');
    const [accrualGLCredit, setAccrualGLCredit] = React.useState('');
    const [accrualGLDebit, setAccrualGLDebit] = React.useState('');
    var accrualValues = {
        postings_block: postingsBlock,
        posting_approval: postingApproval,
        postings_start_date: financialPostingsStartDate,
        postings_end_date: financialPostingsEndDate,
        auto_run_ppa: autoRunPpa,
        suppress_negative_rebate: suppressNegativeRebate
    };
    var mode = localStorage.getItem('mode');
    const accrualFrequencylRef = useRef(null);
    const accrualLevelRef = useRef(null);
    const copaRef = useRef(null);
    const accrualCalendarRef = useRef(null);
    const accrualCreditRef = useRef(null);
    const accrualDebitRef = useRef(null);

    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (mode === 'edit')
                props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!accrualFrequencylRef.current.value || accrualFrequencylRef.current.value === '')
                setAccrualFrequency(false);
            if (!accrualLevelRef.current.value || accrualLevelRef.current.value === '')
                setAccrualLevel(false);
            if (!accrualCalendarRef.current.value || accrualCalendarRef.current.value === '')
                setAccrualCalendar(false);
            if (!copaRef.current.value || copaRef.current.value === '')
                setCopaLevel(false);
            if (!accrualCreditRef.current.value || accrualCreditRef.current.value === '')
                setAccrualGLCredit(false);
            if (!accrualDebitRef.current.value || accrualDebitRef.current.value === '')
                setAccrualGLDebit(false);
        },
        getValues() {
            accrualValues = {
                accrual_frequency: accrualFrequencylRef.current.value,
                accrual_level: accrualLevelRef.current.value,
                copa_level: copaRef.current.value,
                accrual_calendar: accrualCalendarRef.current.value,
                accrual_gl_account_credit: accrualCreditRef.current.value,
                accrual_gl_account_debit: accrualDebitRef.current.value
            };
            return accrualValues;
        }
    }));

    function handleSuppressNegRebate(e) {
        setSuppressNegativeRebate(e.target.value);
    }


    function handlePostingBlock(newValue) {
        setPostingsBlock(newValue);
    }
    function handlePostingApproval(newValue) {
        setPostingApproval(newValue);
    }
    function handleFinancialPostingsStartDate(newValue) {
        if (newValue && newValue.isValid())
            setFinancialPostingsStartDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setFinancialPostingsStartDate(newValue)
    }
    function handleFinancialPostingsEndDate(newValue) {
        if (newValue && newValue.isValid())
            setFinancialPostingsEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setFinancialPostingsEndDate(newValue);
    }

    function handleAutoRunPpa(newValue) {
        setAutoRunPpa(newValue.target.value);
    }





    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-2rem' }}
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }} >
                        <Grid container >
                            {props.fieldData && props.fieldData['auto_run_ppa'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel>{props.fieldData['auto_run_ppa']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={autoRunPpa}
                                        onChange={handleAutoRunPpa}
                                        displayEmpty
                                    >
                                        <MenuItem value="" className={classes.menuItem}></MenuItem>
                                        {props.fieldData['auto_run_ppa'] && props.fieldData['auto_run_ppa']['drop_down_values']
                                            && props.fieldData['auto_run_ppa']['drop_down_values']
                                                .map(item => (
                                                    <MenuItem value={item} className={classes.menuItem}>{item}</MenuItem>
                                                ))
                                        }
                                    </Select>
                                    {/* </div> */}
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['postings_start_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker heading={props.fieldData['postings_start_date']['current']} twoline='true' onChange={handleFinancialPostingsStartDate} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['postings_end_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker minimum={financialPostingsStartDate} heading={props.fieldData['postings_end_date']['current']} twoline='true' onChange={handleFinancialPostingsEndDate} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['postings_block'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div>
                                        <DropdownKeyValue heading={props.fieldData['postings_block']['current']} data={props.fieldData['postings_block']['drop_down_reference_value']}
                                            placeholder={' '} twoline='true' onChange={handlePostingBlock} labelFormat={'desc'}
                                        />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['posting_approval'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div>
                                        <DropdownKeyValue heading={props.fieldData['posting_approval']['current']} data={props.fieldData['posting_approval']['drop_down_reference_value']}
                                            placeholder={' '} twoline='true' onChange={handlePostingApproval} labelFormat={'desc'}
                                        />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['suppress_negative_rebate'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel>{props.fieldData['suppress_negative_rebate']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={suppressNegativeRebate}
                                        onChange={handleSuppressNegRebate}
                                        displayEmpty
                                    >
                                        <MenuItem value={false} className={classes.menuItem}>No</MenuItem>
                                        <MenuItem value={true} className={classes.menuItem}>Yes</MenuItem>
                                    </Select>
                                    {/* </div> */}
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    tableData: state.addMultipleConfigurationData.multipleConfigurationData,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        onLoadingDefault: () => getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AdditionalData);
