import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {
    Typography,
    Grid,
    FormLabel,
    Select,
    Input,
    MenuItem, OutlinedInput,
    TextField
} from '@mui/material';
import SubmitContainer from '../../../components/Containers/SubmitContainer';
import { runCalculationReports, getDefaultValues, getDefaultValuesCalculationSimulation, getDefaultValuesAllData } from '../../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import { toast } from 'react-toastify';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    container: {
        paddingTop: 18
    },
    leftGridContainer: {
        paddingRight: 10
    },
    calendarLeftGridContainer: {
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        // marginTop: 15,
        padding: '0px 10px 0px 10px'
    },
    rightGridContainer: {
        paddingLeft: 10
    },
    submitContainer: {
        marginTop: 15
    },
    dateColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        //marginRight: 13
    },
    dateSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 17
    },
    dropdownColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    dropdownSecondColumn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        width: '100%',
        marginRight: 15
    },
    multiSelect: {
        marginTop: 10
    },
    textInput: {
        marginTop: 10
    },
    flex: {
        flex: 1
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    textInputHeight: {
        height: 14
    },
    error: {
        border: '1px solid red !important'
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const CalculationReportsTab = props => {
    const classes = useStyles();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [calculationSchemaArray, setCalculationSchemaArray] = React.useState([]);
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [billingTypeArray, setBillingTypeArray] = React.useState([]);
    const [billingDateArray, setBillingDateArray] = React.useState([]);
    const [calculationSimulationNumberArray, setCalculationSimulationNumberArray] = React.useState([]);
    const [billingDocNumberArray, setBillingDocNumberArray] = React.useState([]);
    const [billingDocLineArray, setBillingDocLineArray] = React.useState([]);
    const [contractNumberArray, setContractNumberArray] = React.useState([]);
    const [startDateArray, setStartDateArray] = React.useState([]);
    const [endDateArray, setEndDateArray] = React.useState([]);
    const [itemCategoryArray, setItemCategoryArray] = React.useState([]);
    const [companyCodeArray, setCompanyCodeArray] = React.useState([]);
    const [salesOrgArray, setSalesOrgArray] = React.useState([]);
    const [distributionChannelArray, setDistributionChannelArray] = React.useState([]);
    const [divisionArray, setDivisionArray] = React.useState([]);
    const [fiscalYearMonthArray, setFiscalYearMonthArray] = React.useState([]);
    const [calendarYearMonthArray, setCalendarYearMonthArray] = React.useState([]);
    const [soldToPartyArray, setSoldToPartyArray] = React.useState([]);
    const [customerChainArray, setCustomerChainArray] = React.useState([]);
    const [customerGroupArray, setCustomerGroupArray] = React.useState([]);
    const [vendorGroupArray, setVendorGroupArray] = React.useState([]);
    const [vendorArray, setVendorArray] = React.useState([]);
    const [materialNumberArray, setMaterialNumberArray] = React.useState([]);
    const [materialGroupArray, setMaterialGroupArray] = React.useState([]);
    const [materialTypeArray, setMaterialTypeArray] = React.useState([]);
    const [productHierarchyArray, setProductHierarchyArray] = React.useState([]);
    const [salesOrderTypeArray, setSalesOrderTypeArray] = React.useState([]);
    const [profitCenterArray, setProfitCenterArray] = React.useState([]);
    const [plantArray, setPlantArray] = React.useState([]);
    const [customerNumberArray, setCustomerNumberArray] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
        props.onLoadingDefaultDropdown();
        props.getDefaultValuesAllData();
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'application_type') {
                            setApplicationTypeArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                        if (item.field_id === 'billing_type') {
                            setBillingTypeArray(item);
                        }
                        //new
                        if (item.field_id === 'calculation_simulation_number') {
                            setCalculationSimulationNumberArray(item);
                        }
                        if (item.field_id === 'billing_date') {
                            setBillingDateArray(item);
                        }
                        if (item.field_id === 'billing_doc_number') {
                            setBillingDocNumberArray(item);
                        }
                        if (item.field_id === 'billing_doc_line') {
                            setBillingDocLineArray(item);
                        }
                        if (item.field_id === 'contract_number') {
                            setContractNumberArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                        if (item.field_id === 'contract_type' && item.drop_down_values.length > 0) {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'start_date') {
                            setStartDateArray(item);
                        }
                        if (item.field_id === 'end_date') {
                            setEndDateArray(item);
                        }
                        if (item.field_id === 'item_category') {
                            setItemCategoryArray(item);
                        }
                        if (item.field_id === 'company_code') {
                            setCompanyCodeArray(item);
                        }
                        if (item.field_id === 'sales_org') {
                            setSalesOrgArray(item);
                        }
                        if (item.field_id === 'distribution_channel') {
                            setDistributionChannelArray(item);
                        }
                        if (item.field_id === 'division') {
                            setDivisionArray(item);
                        }
                        if (item.field_id === 'fiscal_year_month') {
                            setFiscalYearMonthArray(item);
                        }
                        if (item.field_id === 'calendar_year_month') {
                            setCalendarYearMonthArray(item);
                        }
                        if (item.field_id === 'sold_to_party') {
                            setSoldToPartyArray(item);
                        }
                        if (item.field_id === 'customer_chain') {
                            //console.log(item)
                        }
                        if (item.field_id === 'customer_group') {
                            setCustomerGroupArray(item);
                        }
                        if (item.field_id === 'supplier_group') {
                            setVendorGroupArray(item);
                        }
                        if (item.field_id === 'supplier_number') {
                            setVendorArray(item);
                        }
                        if (item.field_id === 'material_number') {
                            setMaterialNumberArray(item);
                        }
                        if (item.field_id === 'material_group') {
                            setMaterialGroupArray(item);
                        }
                        if (item.field_id === 'material_type') {
                            setMaterialTypeArray(item);
                        }
                        if (item.field_id === 'product_hierarchy') {
                            setProductHierarchyArray(item);
                        }
                        if (item.field_id === 'sales_order_type') {
                            setSalesOrderTypeArray(item);
                        }
                        if (item.field_id === 'profit_center') {
                            setProfitCenterArray(item);
                        }
                        if (item.field_id === 'plant') {
                            setPlantArray(item);
                        }
                    })
                }
            }
        }
    });
    const [flexAttribute1Array, setFlexAttribute1Array] = React.useState([]);
    const [flexAttribute2Array, setFlexAttribute2Array] = React.useState([]);
    const [netValueArray, setNetValueArray] = React.useState([]);
    const [incentiveBasis1Array, setIncentiveBasis1Array] = React.useState([]);
    const [flexKeyFigure1Array, setFlexKeyFigure1Array] = React.useState([]);
    const [flexKeyFigure2Array, setFlexKeyFigure2Array] = React.useState([]);
    const [billingDocumentCreatedDateArray, setBillingDocumentCreatedDateArray] = React.useState([]);
    const [billingDocumentCreatedTimeArray, setBillingDocumentCreatedTimeArray] = React.useState([]);
    const [billingDocumentExtractedTimeArray, setBillingDocumentExtractedTimeArray] = React.useState([]);
    const [billingDocumentExtractedDateArray, setBillingDocumentExtractedDateArray] = React.useState([]);


    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {
            props.formFieldsAllData.records.map((item) => {
                if (item.field_id === 'flex_attribute1') {
                    setFlexAttribute1Array(item)
                }
                if (item.field_id === 'flex_attribute2') {
                    setFlexAttribute2Array(item)
                }
                if (item.field_id === 'net_value') {
                    setNetValueArray(item)
                }
                if (item.field_id === 'incentive_basis1') {
                    setIncentiveBasis1Array(item)
                }
                if (item.field_id === 'flex_key_figure1') {
                    setFlexKeyFigure1Array(item)
                }
                if (item.field_id === 'billing_document_created_date') {
                    setBillingDocumentCreatedDateArray(item);
                }
                if (item.field_id === 'billing_document_created_time') {
                    setBillingDocumentCreatedTimeArray(item);
                }
                if (item.field_id === 'billing_document_extracted_time') {
                    setBillingDocumentExtractedTimeArray(item);
                }
                if (item.field_id === 'billing_document_extracted_date') {
                    setBillingDocumentExtractedDateArray(item);

                }


            })

        }

    }, [props.formFieldsAllData])

    // useEffect(() => {
    //     if (props.dropdownData2 && props.dropdownData2.records && props.dropdownData2.records.length > 0) {
    //         props.dropdownData2.records.map((item) => {
    //             if (item.field_id === 'contract_type') {
    //                 setContractTypeArray(item);
    //             }
    //         })
    //     }
    // }, [props.dropdownData2])
    const [calculationSimulationNumberMultiple, setCalculationSimulationNumberMultiple] = React.useState([]);
    const [calculationSimulationNumberRange, setCalculationSimulationNumberRange] = React.useState('');
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [contractNumberRange, setContractNumberRange] = React.useState('');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [contractType, setContractType] = React.useState('');
    const [contractSubType, setContractSubType] = React.useState([]);
    const [calculationStartDate, setCalculationStartDate] = React.useState(null);
    const [calculationEndDate, setCalculationEndDate] = React.useState(null);
    const [billingType, setBillingType] = React.useState([]);
    const [itemCategory, setItemCategory] = React.useState([]);
    const [companyCode, setCompanyCode] = React.useState([]);
    const [salesOrg, setSalesOrg] = React.useState([]);
    const [distributionChannel, setDistributionChannel] = React.useState([]);
    const [division, setDivision] = React.useState([]);
    const [fiscalYearMonth, setFiscalYearMonth] = React.useState([]);
    const [calendarYearMonth, setCalendarYearMonth] = React.useState([]);
    const [soldToPartyMultiple, setSoldToPartyMultiple] = React.useState([]);
    const [customerChain, setCustomerChain] = React.useState([]);
    const [customerGroup, setCustomerGroup] = React.useState([]);
    const [calculationSimulationCreatedBy, setCalculationSimulationCreatedBy] = React.useState('');
    const [calculationSimulationCreatedDate, setCalculationSimulationCreatedDate] = React.useState(null);
    const [calculationSimulationCreatedTime, setCalculationSimulationCreatedTime] = React.useState(null);
    const handleCalculationSimulationNumberMultiple = (newValue) => {
        setCalculationSimulationNumberMultiple(newValue);
    };
    const handleContractNumberMultiple = (newValue) => {
        setContractNumberMultiple([...contractNumberMultiple, newValue]);
        if (contractNumberMultiple.length > 0) {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(true);
            setContractNumberRange('');
        } else {
            setContractNumberKey('contract_number.in');
            setDisableContractNumberRange(false);
        }
    };
    const handleDeleteContractNumberMultiple = (value, index) => {
        var deletedContractNumber = contractNumberMultiple.filter(item => item !== value)
        setContractNumberMultiple(deletedContractNumber)
        if (contractNumberMultiple.length === 2)
            setDisableContractNumberRange(false);
    }
    const handleContractNumberRange = (event) => {
        setContractNumberRange(event.target.value);
        if (event.target.value) {
            setContractNumberKey('contract_number.range');
        } else {
            setContractNumberKey('contract_number.in');
        }
    }
    const handleApplicationtype = (event) => {
        setApplicationType(event.target.value);
    }
    const handleContractType = (event) => {
        setContractType(event.target.value);
    }
    const handleContractSubType = (event) => {
        setContractSubType(event.target.value);
    }
    /* const handleBillingType = (event) => {
         setBillingType(event.target.value);
     }*/
    const handleItemCategory = (event) => {
        setItemCategory(event.target.value);
    }
    const handleCompanyCode = (newValue) => {
        setCompanyCode(newValue);
    }
    const handleSalesOrg = (newValue) => {
        setSalesOrg(newValue);
    }
    const handleDistributionChannel = (newValue) => {
        setDistributionChannel(newValue);
    }
    const handleDivision = (newValue) => {
        setDivision(newValue);
    }
    const handleSoldToPartyMultiple = (newValue) => {
        setSoldToPartyMultiple(newValue);
    }
    const handleCustomerChain = (newValue) => {
        setCustomerChain(newValue);
    }
    const handleCustomerGroup = (newValue) => {
        setCustomerGroup(newValue);
    }
    const handleCalculationSimulationCreatedBy = (newValue) => {
        setCalculationSimulationCreatedBy(newValue);
    }
    const [billingDocNumberMultiple, setBillingDocNumberMultiple] = React.useState([]);
    const [billingDocLineMultiple, setBillingDocLineMultiple] = React.useState([]);
    const [vendorMultiple, setVendorMultiple] = React.useState([]);
    const [materialNumberMultiple, setMaterialNumberMultiple] = React.useState([]);
    const [salesOrderTypeMultiple, setSalesOrderTypeMultiple] = React.useState([]);
    const [vendorGroupMultiple, setVendorGroupMultiple] = React.useState([]);
    const [materialGroupMultiple, setMaterialGroupMultiple] = React.useState([]);
    const [materialTypeMultiple, setMaterialTypeMultiple] = React.useState([]);
    const [productHierarchyMultiple, setProductHierarchyMultiple] = React.useState([]);
    const [plantMultiple, setPlantMultiple] = React.useState([]);
    const [profitCenterMultiple, setProfitCenterMultiple] = React.useState([]);
    const [contractNumberKey, setContractNumberKey] = React.useState('');
    const [disableContractNumberRange, setDisableContractNumberRange] = React.useState(false);
    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');

    const handleMultipleBillingDocLine = (newValue) => {
        setBillingDocLineMultiple(newValue);
    };
    const handleMultipleBillingDocNumber = (newValue) => {
        setBillingDocNumberMultiple(newValue);
    };
    const handleVendorMultiple = (newValue) => {
        setVendorMultiple(newValue);
    }
    const handleMaterialNumberMultiple = (newValue) => {
        setMaterialNumberMultiple(newValue);
    }
    const handleSalesOrderTypeMultiple = (newValue) => {
        setSalesOrderTypeMultiple(newValue);
    }
    const handleVendorGroupMultiple = (newValue) => {
        setVendorGroupMultiple(newValue);
    }
    const handleMaterialGroupMultiple = (newValue) => {
        setMaterialGroupMultiple(newValue);
    }
    const handleMaterialTypeMultiple = (newValue) => {
        setMaterialTypeMultiple(newValue);
    }
    const handleProductHierarchyMultiple = (newValue) => {
        setProductHierarchyMultiple(newValue);
    }
    const handlePlantMultiple = (newValue) => {
        setPlantMultiple(newValue);
    }
    const handleProfitCenterMultiple = (newValue) => {
        setProfitCenterMultiple(newValue);
    }
    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const contractTypeDataArray = [
        'CRTYP4',
        'CRTYP1'
    ];
    const applicationTypeData = [
        "Customer Rebate",
        "Supplier Rebate",
        "Sales Commission",
        "Royalty'"
    ];
    const contractTypeData = [
        'Flat Rate',
        'Flat Amount',
        'Volume Tier',
        'Growth Tier'
    ];
    const contractSubtypeData = [
        'Fee for Service',
        'Compliance',
        'Marketing Development Fund',
        'Channel Performance',
        'Price Protection'
    ];

    //new fields
    const [pricingDateStart, setPricingDateStart] = React.useState(null);
    const [pricingDateEnd, setPricingDateEnd] = React.useState(null);
    const [pricingDateRangeKey, setPricingDateRangeKey] = React.useState('pricing_date');
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [customerName, setCustomerName] = React.useState([]);
    const [materialDescription, setMaterialDescription] = React.useState([]);
    const [supplierNumber, setSupplierNumber] = React.useState([]);
    const [supplierName, setSupplierName] = React.useState([]);
    const [supplierGroup, setSupplierGroup] = React.useState([]);
    const [supplierCustomerNumber, setSupplierCustomerNumber] = React.useState([]);
    const [employeeNumber, setEmployeeNumber] = React.useState([]);
    const [employeeName, setEmployeeName] = React.useState([]);
    const [terittoryID, setTerittoryID] = React.useState([]);
    const [region, setRegion] = React.useState([]);
    const [salesOffice, setSalesOffice] = React.useState([]);
    const [salesGroup, setSalesGroup] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState([]);
    const [flexAttribute2, setFlexAttribute2] = React.useState([]);
    const [netValue, setNetValue] = React.useState([]);
    const [incentiveBasis, setIncentiveBasis] = React.useState([]);
    const [incentiveRate, setIncentiveRate] = React.useState([]);
    const [incentiveAmount, setIncentiveAmount] = React.useState([]);
    const [flexKeyFigure1, setFlexKeyFigure1] = React.useState([]);
    const [flexKeyFigure2, setFlexKeyFigure2] = React.useState([]);
    const [salesDistrict, setSalesDistrict] = React.useState([]);
    const [orderReason, setOrderReason] = React.useState([]);
    const [billingDocCreatedStart, setBillingDocCreatedStart] = React.useState(null);
    const [billingDocCreatedEnd, setBillingDocCreatedEnd] = React.useState(null);
    const [billingDocExtractedStart, setBillingDocExtractedStart] = React.useState(null);
    const [billingDocExtractedEnd, setBillingDocExtractedEnd] = React.useState(null);
    const [billingDocCreatedStartTime, setBillingDocCreatedStartTime] = React.useState(null);
    const [billingDocCreatedEndTime, setBillingDocCreatedEndTime] = React.useState(null);
    const [billingDocExtractedStartTime, setBillingDocExtractedStartTime] = React.useState(null);
    const [billingDocExtractedEndTime, setBillingDocExtractedEndTime] = React.useState(null);


    const handlePricingDateStart = (newValue) => {
        setPricingDateStart(newValue);
        if (billingDateEnd) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }
    const handlePricingDateEnd = (newValue) => {
        setPricingDateEnd(newValue);
        if (newValue) {
            setPricingDateRangeKey('pricing_date.range');
        } else {
            setPricingDateRangeKey('pricing_date');
        }
    }

    const onSubmitHandler = () => {
        var formData = {
            "calculation_simulation_number.in": calculationSimulationNumberMultiple.toString(),
            [billingDateRangeKey]: (billingDateStart ? Moment(billingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (billingDateEnd ? ',' + Moment(billingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "billing_doc_number.in": billingDocNumberMultiple.toString(),
            "billing_doc_line.in": billingDocLineMultiple.toString(),
            [contractNumberKey]: contractNumberRange ? contractNumberMultiple.toString() + ',' + contractNumberRange : contractNumberMultiple.toString(),
            "application_type": applicationType,
            "calculation_schema": contractType,
            "contract_type.in": contractSubType.toString(),
            "start_date": calculationStartDate ? convert(calculationStartDate.toString()).concat('T00:00:00.00Z') : '',
            "end_date": calculationEndDate ? convert(calculationEndDate.toString()).concat('T23:59:59.00Z') : '',
            "billing_type.in": billingType.toString(),
            "item_category.in": itemCategory.toString(),
            "company_code.in": companyCode.toString(),
            "sales_org": salesOrg.toString(),
            "distribution_channel": distributionChannel.toString(),
            "division.in": division.toString(),
            "fiscal_year_month.in": fiscalYearMonth.toString(),
            "calendar_year_month.in": calendarYearMonth.toString(),
            "sold_to_party.in": soldToPartyMultiple.toString(),
            "customer_chain.in": customerChain.toString(),
            "customer_group.in": customerGroup.toString(),
            "vendor.in": vendorMultiple.toString(),
            "material_number.in": materialNumberMultiple.toString(),
            "material_group.in": materialGroupMultiple.toString(),
            "material_type.in": materialTypeMultiple.toString(),
            "product_hierarchy.in": productHierarchyMultiple.toString(),
            "sales_order_type.in": salesOrderTypeMultiple.toString(),
            "profit_center.in": profitCenterMultiple.toString(),
            "plant.in": plantMultiple.toString(),
            "calculation_simulation_created_by": calculationSimulationCreatedBy,
            "calculation_simulation_created_date": calculationSimulationCreatedDate ? Moment(calculationSimulationCreatedDate).toISOString().split('T')[0].concat('T00:00:00.00Z') : '',
            "calculation_simulation_created_time": calculationSimulationCreatedTime ? Moment(calculationSimulationCreatedTime).format('01:01:001THH:mm:ss').concat('Z') : '',
            //new fields
            [pricingDateRangeKey]: (pricingDateStart ? Moment(pricingDateStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + (pricingDateEnd ? ',' + Moment(pricingDateEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            "customer_number.in": customerNumber.toString(),
            "customer_name.in": customerName.toString(),
            "material_description.in": materialDescription.toString(),
            "supplier_number.in": supplierNumber.toString(),
            "supplier_name.in": supplierName.toString(),
            "supplier_group.in": supplierGroup.toString(),
            "supplier_customer_number.in": supplierCustomerNumber.toString(),
            "employee_number.in": employeeNumber.toString(),
            "employee_name.in": employeeName.toString(),
            "territory_id.in": terittoryID.toString(),
            "region.in": region.toString(),
            "sales_office.in": salesOffice.toString(),
            "sales_group.in": salesGroup.toString(),
            "flex_attribute1.in": flexAttribute1.toString(),
            "flex_attribute2.in": flexAttribute2.toString(),
            "net_value.in": netValue.toString(),
            "incentive_basis.in": incentiveBasis.toString(),
            "incentive_rate.in": incentiveRate.toString(),
            "incentive_amount.in": incentiveAmount.toString(),
            "flex_key_figure1.in": flexKeyFigure1.toString(),
            "flex_key_figure2.in": flexKeyFigure2.toString(),
            "sales_district.in": salesDistrict.toString(),
            "order_reason.in": orderReason.toString(),
            "billing_document_created_date.range": (billingDocCreatedStart ? Moment(billingDocCreatedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocCreatedEnd ? Moment(billingDocCreatedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            //"billing_document_created_time.range": (billingDocCreatedStartTime ? Moment(billingDocCreatedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocCreatedEndTime ? Moment(billingDocCreatedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
            "billing_document_extracted_date.range": (billingDocExtractedStart ? Moment(billingDocExtractedStart).toISOString().split('T')[0].concat('T00:00:00.00Z') : '') + ',' + (billingDocExtractedEnd ? Moment(billingDocExtractedEnd).toISOString().split('T')[0].concat('T23:59:59.00Z') : ''),
            // "billing_document_extracted_time.range": (billingDocExtractedStartTime ? Moment(billingDocExtractedStartTime).format('01:01:001THH:mm:ss').concat('Z') : '') + ',' + (billingDocExtractedEndTime ? Moment(billingDocExtractedEndTime).format('01:01:001THH:mm:ss').concat('Z') : ''),
        };
        if ((calculationSimulationNumberMultiple.length === 0 && calculationSimulationNumberRange) ||
            (!billingDateStart && billingDateEnd) ||
            (contractNumberMultiple.length === 0 && contractNumberRange)) {
            toast.error("Please fill all the required fields");
        } else {
            localStorage.setItem("CalculationReportsData", JSON.stringify(formData));
            props.onSubmit(0, 10);
        }
    }
    const onCancelHandler = () => {
        setCalculationSimulationNumberMultiple([]);
        setCalculationSimulationNumberRange('');
        setBillingDateStart(null);
        setBillingDateEnd(null);
        setBillingDocNumberMultiple([]);
        setBillingDocLineMultiple([]);
        setContractNumberMultiple([]);
        setContractNumberRange('');
        setApplicationType('');
        setContractType('');
        setContractSubType([]);
        setCalculationStartDate(null);
        setCalculationEndDate(null);
        setBillingType([]);
        setItemCategory([]);
        setCompanyCode([]);
        setSalesOrg([]);
        setDistributionChannel([]);
        setDivision([]);
        setFiscalYearMonth(null);
        setCalendarYearMonth(null);
        setSoldToPartyMultiple([]);
        setCustomerChain([]);
        setCustomerGroup([]);
        setVendorMultiple([]);
        setMaterialNumberMultiple([]);
        setMaterialGroupMultiple([]);
        setMaterialTypeMultiple([]);
        setProductHierarchyMultiple([]);
        setSalesOrderTypeMultiple([]);
        setProfitCenterMultiple([]);
        setPlantMultiple([]);
        setCalculationSimulationCreatedBy('');
        setCalculationSimulationCreatedDate(null);
        setCalculationSimulationCreatedTime(null);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.row} >
                <Typography variant="h1" color='primary'> Calculation Reports </Typography>
                <div className={classes.container}>
                    <Grid container >
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Application Type</FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    value={applicationType}
                                    input={<OutlinedInput />}
                                    onChange={handleApplicationtype}
                                    className={classes.multiSelect}
                                >
                                    <MenuItem value={sessionStorage.getItem('application')}>
                                        {sessionStorage.getItem('application')}
                                    </MenuItem>
                                    {/* {applicationTypeArray.drop_down_values.map((name) => (
                                                <MenuItem key={name} value={sessionStorage.getItem('application')} disabled >
                                                    {sessionStorage.getItem('application')}
                                                </MenuItem>
                                            ))} */}
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Contract Type</FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={contractSubType}
                                    input={<OutlinedInput />}
                                    onChange={handleContractSubType}
                                    className={classes.multiSelect}
                                >
                                    {contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.map((name) => (
                                        <MenuItem key={name} value={name} >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} >
                                <FormLabel classes={{ root: classes.fontSetting }} >Contract Number</FormLabel>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        value={contractNumberMultiple}
                                        onAdd={(chips) => handleContractNumberMultiple(chips)}
                                        onDelete={(chip, index) => handleDeleteContractNumberMultiple(chip, index)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true,
                                            [classes.error]: contractNumberMultiple.length === 0 && contractNumberRange
                                        })}
                                    />
                                    <TextField
                                        className={clsx({
                                            [classes.textInput]: true,
                                        })}
                                        InputProps={{ classes: { input: classes.textInputHeight } }}
                                        variant="outlined"
                                        value={contractNumberRange}
                                        onChange={handleContractNumberRange}
                                        disabled={disableContractNumberRange}
                                        style={{ marginLeft: 5 }}
                                        required
                                    />

                                </div>

                            </div>

                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Calculation Simulation Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleCalculationSimulationNumberMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>



                        {startDateArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Calculation Start Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationStartDate}
                                                onChange={date => setCalculationStartDate(date)}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        }
                        {endDateArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Calculation End Date</FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationEndDate}
                                                onChange={date => setCalculationEndDate(date)}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        }

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Billing Date</FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={billingDateStart}
                                            onChange={handleBillingDateStart}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.dateSecondColumn}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 18,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={billingDateEnd}
                                            onChange={handleBillingDateEnd}
                                            format="MM/DD/YYYY"
                                            minDate={billingDateStart}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Calendar Year Month</FormLabel>
                                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calendarYearMonth}
                                            onChange={date => setCalendarYearMonth(date)}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                        </MuiPickersUtilsProvider>*/}
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setCalendarYearMonth(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Customer Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setCustomerNumber(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Customer Name</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setCustomerName(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Customer Chain</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleCustomerChain(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Customer Group</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleCustomerGroup(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Material Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleMaterialNumberMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Material Description</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setMaterialDescription(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Product Hierarchy</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleProductHierarchyMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Material Group</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleMaterialGroupMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Supplier Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSupplierNumber(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        {/*vendorArray && vendorArray.enabled &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel>{vendorArray.name}</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleVendorMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                     */}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Supplier Name</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSupplierName(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Supplier Group</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSupplierGroup(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        {/*vendorGroupArray && vendorGroupArray.enabled &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel>{vendorGroupArray.name}</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleVendorGroupMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                                    */}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Supplier Customer Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSupplierCustomerNumber(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Billing Document Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleMultipleBillingDocNumber(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }}>Billing Document Line</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleMultipleBillingDocLine(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Billiing Type</FormLabel>
                                {/*<Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={billingType}
                                    input={<OutlinedInput />}
                                    onChange={handleBillingType}
                                    className={classes.multiSelect}
                                >
                                    
                                   {billingTypeArray && billingTypeArray.dropd_down_values && billingTypeArray.drop_down_values.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                </Select>*/}

                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setBillingType(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        {itemCategoryArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Item Category</FormLabel>
                                    {/* <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={itemCategory}
                                        input={<OutlinedInput />}
                                        onChange={handleItemCategory}
                                        className={classes.multiSelect}
                                    >
                                        {itemCategoryArray && itemCategoryArray.dropd_down_values &&itemCategoryArray.dropd_down_values.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                        </Select>*/}
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setItemCategory(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />

                                </div>
                            </Grid>
                        }
                        {companyCodeArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Company Code</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleCompanyCode(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }
                        {salesOrgArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Sales Org</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSalesOrg(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }
                        {distributionChannelArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Distribution Channel</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleDistributionChannel(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }
                        {divisionArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel classes={{ root: classes.fontSetting }} >Division</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleDivision(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Employee Number</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setEmployeeNumber(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Employee Name</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setEmployeeName(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Terittory ID</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setTerittoryID(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Region</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setRegion(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Sales District</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSalesDistrict(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Sales Office</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSalesOffice(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Sales Group</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setSalesGroup(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Order Reason</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setOrderReason(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Plant</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handlePlantMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Profit Center</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleProfitCenterMultiple(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        {/*flexAttribute1Array && flexAttribute1Array.enabled &&*/}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Flex Attribute 1</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setFlexAttribute1(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>

                        {/*flexAttribute2Array && flexAttribute2Array.enabled &&*/}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Flex Attribute 2</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setFlexAttribute2(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Net Value</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setNetValue(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Incentive Basis</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setIncentiveBasis(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Incentive Rate</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setIncentiveRate(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Incentive Amount</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setIncentiveAmount(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        {/*flexKeyFigure1Array && flexKeyFigure1Array.enabled &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                 className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel>Flex Key Figure 1</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexKeyFigure1(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }
                        {flexKeyFigure2Array && flexKeyFigure2Array.enabled &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel>Flex Key Figure 2</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => setFlexKeyFigure2(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        */}
                        {/*billingDocumentCreatedDateArray && billingDocumentCreatedDateArray.enabled &&*/}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Billing Document Created Date</FormLabel>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}
                                    >
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: !billingDocCreatedStart && billingDocCreatedEnd ? '1px solid ted' : '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={billingDocCreatedStart}
                                                onChange={date => setBillingDocCreatedStart(date)}
                                                format="MM/DD/YYYY"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}
                                    >
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10
                                                    }
                                                }}
                                                value={billingDocCreatedEnd}
                                                onChange={date => setBillingDocCreatedEnd(date)}
                                                format="MM/DD/YYYY"
                                                minDate={billingDocCreatedStart ? billingDocCreatedStart : ''}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </div>
                            {/* <div className={classes.dateSecondColumn}>
                                <FormLabel style={{color: 'white'}}>Billing Document Created Date</FormLabel>
                                    
                                </div> */}
                        </Grid>


                        {/*<Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel>Billing Document Created Time</FormLabel>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}

                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !billingDocCreatedStartTime && billingDocCreatedEndTime ? '1px solid ted' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={billingDocCreatedStartTime}
                                                    onChange={date => setBillingDocCreatedStartTime(date)}
                                                    keyboardIcon={<AccessTimeIcon />}
                                                    variant="dialog"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}

                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={billingDocCreatedEndTime}
                                                    onChange={date => setBillingDocCreatedEndTime(date)}
                                                    variant="dialog"
                                                    keyboardIcon={<AccessTimeIcon />}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>*/}

                        {/*billingDocumentExtractedDateArray && billingDocumentExtractedDateArray.enabled &&*/}
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Billing Document Extracted Date</FormLabel>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}

                                    >
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Grid container justifyContent="space-around">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !billingDocExtractedStart && billingDocExtractedEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={billingDocExtractedStart}
                                                    onChange={date => setBillingDocExtractedStart(date)}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}
                                    >
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Grid container justifyContent="space-around">
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={billingDocExtractedEnd}
                                                    onChange={date => setBillingDocExtractedEnd(date)}
                                                    format="MM/DD/YYYY"
                                                    minDate={billingDocCreatedStart ? billingDocCreatedStart : ''}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>

                        {/*
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel>Billing Document Extracted Time</FormLabel>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: !billingDocExtractedStartTime && billingDocExtractedEndTime ? '1px solid ted' : '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={billingDocExtractedStartTime}
                                                    onChange={date => setBillingDocExtractedStartTime(date)}
                                                    keyboardIcon={<AccessTimeIcon />}
                                                    variant="dialog"
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        className={classes.calendarLeftGridContainer}
                                        style={{ padding: 0 }}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardTimePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '1px 0 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 10,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%',
                                                            marginLeft: 10
                                                        }
                                                    }}
                                                    value={billingDocExtractedEndTime}
                                                    onChange={date => setBillingDocExtractedEndTime(date)}
                                                    variant="dialog"
                                                    keyboardIcon={<AccessTimeIcon />}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>*/}

                        < Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Fiscal Year Month</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => setFiscalYearMonth(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dateColumn}>
                                <FormLabel classes={{ root: classes.fontSetting }} >Pricing Date</FormLabel>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: !pricingDateStart && pricingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={pricingDateStart}
                                            onChange={handlePricingDateStart}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.dateSecondColumn}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 17,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    marginLeft: 10
                                                }
                                            }}
                                            value={pricingDateEnd}
                                            onChange={handlePricingDateEnd}
                                            format="MM/DD/YYYY"
                                            minDate={pricingDateStart ? pricingDateStart : ''}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>



                        {/* {calculationSchemaArray && calculationSchemaArray.name &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel>{calculationSchemaArray.name}</FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        value={contractType}
                                        input={<OutlinedInput />}
                                        onChange={handleContractType}
                                        className={classes.multiSelect}
                                    >
                                        {calculationSchemaArray.drop_down_values.map((name) => (
                                            <MenuItem key={name} value={name} >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Grid>
                        }

                        {startDateArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel>{startDateArray.name}</FormLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationStartDate}
                                                onChange={date => setCalculationStartDate(date)}
                                                format="MM/dd/yyyy"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        }
                        {endDateArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel>{endDateArray.name}</FormLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%'
                                                    }
                                                }}
                                                value={calculationEndDate}
                                                onChange={date => setCalculationEndDate(date)}
                                                format="MM/dd/yyyy"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                        }





                        {soldToPartyArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ marginRight: 15 }}>
                                    <FormLabel>{soldToPartyArray.name}</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSoldToPartyMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }





                        {materialTypeArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                    <FormLabel>{materialTypeArray.name}</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleMaterialTypeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }


                        {salesOrderTypeArray &&
                            <Grid
                                item
                                md={3}
                                xs={12}
                                className={classes.calendarLeftGridContainer}
                            >
                                <div className={classes.dropdownColumn} style={{ width: '100%', paddingRight: 15 }}>
                                    <FormLabel>{salesOrderTypeArray.name}</FormLabel>
                                    <ChipInput
                                        classes={{
                                            root: classes.rootContainer,
                                            chip: classes.chip,
                                            input: classes.input,
                                            inputRoot: classes.inputRoot,
                                            chipContainer: classes.chipContainer,
                                            label: classes.chipLabel
                                        }}
                                        onChange={(chips) => handleSalesOrderTypeMultiple(chips)}
                                        disableUnderline={true}
                                        className={clsx({
                                            [classes.textInput]: true
                                        })}
                                    />
                                </div>
                            </Grid>
                        }


                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel>Calculation Simulation Created By</FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    onChange={(chips) => handleCalculationSimulationCreatedBy(chips)}
                                    disableUnderline={true}
                                    className={clsx({
                                        [classes.textInput]: true
                                    })}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel>Calculation Simulation Created Date</FormLabel>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationSimulationCreatedDate}
                                            onChange={date => setCalculationSimulationCreatedDate(date)}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            className={classes.calendarLeftGridContainer}
                        >
                            <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                <FormLabel>Calculation Simulation Created Time</FormLabel>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardTimePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationSimulationCreatedTime}
                                            onChange={date => setCalculationSimulationCreatedTime(date)}
                                            keyboardIcon={<AccessTimeIcon />}
                                            variant="dialog"
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </div>
                        </Grid> */}
                    </Grid>
                </div >
            </div >
            <div className={classes.submitContainer}>
                <SubmitContainer onSubmit={onSubmitHandler} onCancel={onCancelHandler} loadingAPI={props.loading} />
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit) => dispatch(runCalculationReports(pagination, limit, '')),
        onLoadingDefault: () => dispatch(getDefaultValuesCalculationSimulation()),
        onLoadingDefaultDropdown: (applicationType) => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, null, 'Contract Designer')),
        getDefaultValuesAllData: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), localStorage.getItem('operationalReportsSourceDataType'), null, null, 'Display Designer')),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        // dropdownData: state.addMultipleConfigurationData.calcSimulationDefaultData,
        // dropdownData2: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationReportsTab);