import React, { useEffect } from 'react';
import {
    Grid,Button,Typography,Breadcrumbs,Card,
    Table,TableCell,TableHead,TableRow,TableContainer,TableBody,
    TextField,OutlinedInput,FormLabel,Dialog,DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    updatePromoTerritoryFormatData,getPromoTerritoryFormatFields
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
             paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const PromotionTerritoryEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [belt, setBelt] = React.useState('');
    const [country,setCountry] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [state, setState] = React.useState('');

    const requiredFields=[
        'belt','country','region','state'
    ]
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(localStorage.getItem('promotion-territory-query') ? JSON.parse(localStorage.getItem('promotion-territory-query')) : []);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.getPromoTerritoryFormatFields();
    }, []);

    useEffect(() => {
        if (purchaseQueryDataStorage) {
            setQueryName(purchaseQueryDataStorage.format_name)
            setQueryObject(purchaseQueryDataStorage)
            setCountry(purchaseQueryDataStorage.country)
            setRegion(purchaseQueryDataStorage.region)
            setState(purchaseQueryDataStorage.state)
            setBelt(purchaseQueryDataStorage.belt)
        }
    }, [purchaseQueryDataStorage]);
    function handleClear() {
        for (var propName in queryObject) {
            if(propName!='format_name')
            {
                 queryObject[propName] =''
            }
        }
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id, queryObject);
    }
    function handleClearAll() {
        handleClear();
        setCountry('')
        setRegion('')
        setState('')
        setBelt('')
    }
    function handleCancelDialog() {
        history.push('/promotion-territory/promotion-territory-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if(item==='country')
           setCountry(event.target.value)
        if(item==="belt")
           setBelt(event.target.value)
        if(item==="state")
           setState(event.target.value)
        if(item==="region")
           setRegion(event.target.value)
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
            <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/promotion-territory'
                >
                    Promotion Territory
                </Link>
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/promotion-territory/promotion-territory-excel-upload'
                >
                   Promotion Territory Format
                </Link>
                <Typography color="textPrimary" variant='h1'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    { requiredFields.includes(item)? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item]? queryObject[item]:''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('promotion-territory-query'))[item] ? JSON.parse(localStorage.getItem('promotion-territory-query'))[item] : ''}
                                                                            />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                     disabled={!state || !country ||!region ||!belt? true:false}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updatePromoTerritoryFormatData(id, data)),
        getPromoTerritoryFormatFields: () => dispatch(getPromoTerritoryFormatFields()),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        queryListData: state.promotionData.promoTerritoryFormatFieldData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionTerritoryEditQuery);