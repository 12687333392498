import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import SearchIcon from '@mui/icons-material/Search';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import HashLoader from 'react-spinners/HashLoader';
import { SelectedStickers, StickerBankContainer, StickerTableHeader } from './Components';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 8,
        //backgroundColor: '#E8E8E8'
    },

    divContainerLeft: {
        position: 'relative',
        //width: '80%',
        height: '100vh',
        marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '100vh',
        marginLeft: 7,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        border: '1px solid #D8D8D8',
        margin: '6px 10px',
        padding: 3,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    }


}));


const StickerBank = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        props.getConfigData();
    }, []);

    useEffect(() => {
        if (productType && selectedCountry)
            props.getStickers(productType, selectedCountry);
    }, [productType, selectedCountry]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
            if (props.configData[0].promotion_division[0])
                setProductType(props.configData[0].promotion_division[0])
            setSelectedCountry('USA')
        }
    }, [props.configData]);

    useEffect(() => {
        if (props.stickersData && props.stickersData.records) {
            setStickersBankData(props.stickersData.records);
        }
    }, [props.stickersData]);


    function handleChange(e) {
        setProductType(e.target.value);
    }


    function handleSelectedCountry(e) {
        // const country = e == 'CA' ? 'USA' : 'CANADA';
        console.log(e)
        setSelectedCountry(e);
    }


    return (
        <>
            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}

            >
                <div className={classes.root}>
                    <StickerTableHeader productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} stickerName={"Stickers Bank"} />

                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between', border: '1px solid #E8E8E8' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={() => history.push('/dashboard')}>
                                Return
                            </Button>
                            <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }}></CircleIcon>
                                In Progress
                            </Typography>
                        </div>
                        <div className={classes.row} style={{ width: 450, marginRight: 100 }}>
                            <TextField
                                placeholder={"Search Stickers"}
                                onChange={""}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ align: 'center' }}
                            />
                        </div>
                        <div>
                            <HistoryIcon className={classes.historyButton}></HistoryIcon>
                        </div>
                    </div>

                    <div /*style={{ display: 'flex' }}*/>
                        <div className={classes.divContainerLeft}>
                            <StickerBankContainer onChange={handleSelectedCountry} stickerName={"Stickers Bank"} productType={productType} country={selectedCountry} />
                        </div>
                        {/* <div className={classes.divContainerRight}>
                            <SelectedStickers country={selectedCountry} name={'Selected Stickers'} />
                        </div> */}
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        getStickers: (productType, country, id) => dispatch(getPromoStickers(productType, country, id, 1, 10)),
        onAction: (data, type, id) => dispatch(createStickers(data, type, id)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
        configData: state.calculationAnalysisData.budgetData,
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerBank);