import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormLabel, TextField
} from "@mui/material";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { Dropdown, SearchDropdown } from "../../components/Inputs";
import SimpleDialog from "../../components/Dialog";
import {
  getDefaultValues, nextTier
} from "../../redux/actions";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { HashLoader, RotateLoader } from "react-spinners";
import { history } from "../../components/Helpers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "10px 0px 0px 0px",
    marginTop: 5,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    padding: "0px 10px 20px 10px",
    marginTop: 0
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 28,
    paddingBottom: 30,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: 3,
    marginTop: 10,
    height: 33,
    padding: 0,
    marginBottom: 14,
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
  contractContiner: {
    padding: "15px 0px 18px",
  },
  spinner: {
    // height: "100vh",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize
    //fontSize:16
  },
  paper: { minWidth: window.screen.width * .50 },
  inputTwoLine: {
    marginTop: 10,
    width: '100%',
    paddingLeft: 10
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: '1px solid #EEEEEE',
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25
  },
}));
const NextTierForm = (props) => {
  const classes = useStyles();
  const { className } = props;
  const [calculationPeriodStart, setCalculationPeriodStart] = useState(null);
  const [calculationPeriodEnd, setCalculationPeriodEnd] = useState(null);
  const [masterValue, setMasterValue] = React.useState([]);


  useEffect(() => {
    setCalculationPeriodStart(
      Moment.utc().subtract(1, "months").startOf("month")
    );
    setCalculationPeriodEnd(Moment.utc().subtract(1, "months").endOf("month"));
  }, []);

  const [contractNumber, setContractNumber] = useState('');
  const [open, setOpen] = useState(false);
  const [startValid, setStartValid] = useState(true);
  const [endValid, setEndValid] = useState(true);
  var sd = calculationPeriodStart ? Moment.utc(calculationPeriodStart).toISOString()?.split("T")[0].concat("T00:00:00.000Z") : '';
  var ed = calculationPeriodEnd ? Moment.utc(calculationPeriodEnd).toISOString()?.split("T")[0].concat("T23:59:59.000Z") : '';
  // const formData = {
  //   //application_type: sessionStorage.getItem("application"),
  //   "contract_number": Number(contractNumber),
  //   "calculation_period_start": sd,
  //   "calculation_period_end": ed
  // };

  const handleDialog = (bool) => {
    if (bool) {
      setOpen(false);
      var temp = []
      // if (sessionStorage.getItem("application") === 'Customer Rebate') {
      //   masterValue.map(item => {
      //     temp.push({
      //       "attribute_name": "customer_number",
      //       "attribute_value": item,
      //       "attribute_type": "Attribute"
      //     })
      //   })
      // }
      // if (sessionStorage.getItem("application") === 'Supplier Rebate') {
      //   masterValue.map(item => {
      //     temp.push({
      //       "attribute_name": "supplier_number",
      //       "attribute_value": item,
      //       "attribute_type": "Attribute"
      //     })
      //   })
      // }
      const formData = {
        //application_type: sessionStorage.getItem("application"),
        "contract_number": Number(contractNumber),
        "calculation_period_start": sd,
        "calculation_period_end": ed,
      };
      if (masterValue && masterValue.length > 0 && (sessionStorage.getItem("application") === 'Customer Rebate' || sessionStorage.getItem("application") === 'Supplier Rebate')) {
        temp = [
          {
            "attribute_name": sessionStorage.getItem("application") === 'Customer Rebate' ? "customer_number" : "supplier_number",
            "attribute_value": masterValue,
            "attribute_type": "Attribute"
          }
        ]
        formData['eligibility_rules'] = [
          {
            "option": "EQUAL",
            "effective_start_date": sd,
            "effective_end_date": ed,
            "enabled": true,
            "type": "Both",
            "sequence": 0,
            "attribute_key_values": temp
          }
        ]
      }
      props.onSubmit(formData);
      // handleClear();
    } else {
      setOpen(false);
    }
  };
  const handleClear = () => {
    calculationPeriodEnd(null)
    calculationPeriodStart(null)
    masterValue([])
    contractNumber('')
  };
  const handleClickOpen = () => {
    setOpen(true)
  };

  function handleCalculationPeriodStart(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodStart(newValue);
      setStartValid(true);
    } else {
      setCalculationPeriodStart(newValue);
      if (!newValue) setCalculationPeriodEnd(null);

      setStartValid(false);
    }
  }
  function handleCalculationPeriodEnd(newValue) {
    if (newValue && newValue.isValid()) {
      setCalculationPeriodEnd(newValue);
      setEndValid(true);
    } else {
      setCalculationPeriodEnd(newValue);
      setEndValid(false);
    }
  }

  function handleContractNumberAC(value) {
    let newSelected = value;
    if (value.length > 0) {
      setContractNumber(newSelected);
    } else {
      setContractNumber([]);
    }
  }
  function handleMaster(value) {
    setMasterValue(value)
  }

  return (
    <LoadingOverlay
      active={props.loading}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#64C4B4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "#64C4B4",
        }),
      }}
      spinner={<HashLoader />}
      className={classes.spinner}
    >
      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="h1" color="primary" style={{ marginLeft: 16 }}>
            Rebate Estimator
          </Typography>
        </div>
        <form autoComplete="off" noValidate>
          <div className={classes.bodyContainer}>
            <Grid container>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <Dropdown
                  heading={"Application Type"}
                  twoline="true"
                  {...props}
                  disabled={true}
                  classes={{ root: classes.fontSetting }}
                  placeholder={sessionStorage.getItem("application")}
                  style={{ paddingTop: 5 }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <div style={{ paddingTop: 15, marginTop: 1 }}>
                  <SearchDropdown id='contract_number_multiple'
                    required
                    table='contract_header' keyName='contract_number'
                    description={true} heading='Contract Number'
                    onChange={handleContractNumberAC}
                  // prevalue={['100001', '45']}

                  />
                </div>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation Start Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: "1px 11px 1px 11px",
                          alignSelf: "center",
                          alignItems: "center",
                          marginTop: 10,
                          border: "1px solid #E0E0E0",
                          width: "100%",
                        },
                      }}
                      value={calculationPeriodStart}
                      onChange={handleCalculationPeriodStart}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                classes={{ root: classes.gridContainer }}
              >
                <FormLabel required classes={{ root: classes.fontSetting }}>
                  Calculation End Date
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      disableToolbar
                      clearable
                      InputProps={{
                        padding: 0,
                        disableUnderline: true,
                        style: {
                          padding: "1px 11px 1px 11px",
                          alignSelf: "center",
                          alignItems: "center",
                          marginTop: 10,
                          border: "1px solid #E0E0E0",
                          width: "100%",
                        },
                      }}
                      value={calculationPeriodEnd}
                      onChange={handleCalculationPeriodEnd}
                      renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                      minDate={
                        calculationPeriodStart ? calculationPeriodStart : ""
                      }
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              {sessionStorage.getItem('application') == 'Customer Rebate' &&
                <Grid
                  item
                  md={6}
                  xs={12}
                  classes={{ root: classes.gridContainer }}
                >
                  <div style={{ paddingTop: 15 }}>
                    <SearchDropdown id='customer_multiple'
                      required
                      table='customer_master' keyName='customer_number'
                      description={true} heading='Customer Number'
                      multiple
                      onChange={handleMaster}
                    />
                  </div>
                </Grid>
              }
              {sessionStorage.getItem('application') == 'Supplier Rebate' &&
                <Grid
                  item
                  md={6}
                  xs={12}
                  classes={{ root: classes.gridContainer }}
                >
                  <div style={{ paddingTop: 15 }}>
                    <SearchDropdown id='customer_multiple'
                      required
                      table='supplier_master' keyName='supplier_number'
                      description={true} heading='Supplier Number'
                      multiple
                      onChange={handleMaster}
                    />
                  </div>
                </Grid>
              }
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                twoline="true"
                onClick={handleClear}
              >
                Clear
              </Button>
              <Button
                // disabled={
                //   (contractType && startValid && endValid ? false : true) && (calculationMethod && startValid && endValid ? false : true)
                //   && (contractNumber.length > 0 && startValid && endValid ? false : true)
                // }
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClickOpen}
                twoline="true"
              >
                {props.loading ? (
                  <RotateLoader
                    color={"rgb(54, 215, 183)"}
                    loading={props.loading}
                    size={2}
                  />
                ) : (
                  "Run Online"
                )}
              </Button>
            </div>
          </div>


        </form>
        <SimpleDialog
          open={open}
          content="Do you want to simulate the next tier results?"
          handleDialog={handleDialog}
        />
      </div>
    </LoadingOverlay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (formData) => dispatch(nextTier(formData)),
    onLoadingDefault: (app, fields) => dispatch(getDefaultValues(app, null, null, fields, 'filter-fields')),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NextTierForm);