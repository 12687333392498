import React, { useRef, useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Stepper,
    Step,
    StepButton,
    StepConnector,
    StepLabel, Button
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { GeneralData, GroupingIdentifiers, Ima360Data, OrganizationData } from './components';
import { connect } from 'react-redux';
import { Link, } from 'react-router-dom';
import { getLblDispDesFieldValue, getCustomerMasterData } from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        backgroundColor: "#FFFFFF",
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));

function getSteps() {
    return ['General Data', 'Grouping/Identifiers', 'IMA360 Data', 'Organization Data'];
}

const ViewCustomer = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [sortedData, setSortedData] = React.useState([]);
    const [masterData, setMasterData] = React.useState([]);

    const skipped = new Set()
    const steps = getSteps();
    const isStepOptional = (step) => {
        return step === 1;
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    useEffect(() => {
        var data = { "data_source": "customer_master" };
        props.getLblDispDesFieldValue(data, 'post');
    }, [])
    useEffect(() => {
        if (props.tableData) {
            setSortedData(props.tableData.field_label_attributes);
        }
    }, [props.tableData])

    useEffect(() => {
        if (props.customerMasterData && Object.keys((props.customerMasterData).length > 0)) {
            setMasterData(props.customerMasterData);
        }
    }, [props.customerMasterData])


    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        switch (step) {
            case 0:
                return <GeneralData data={sortedData} masdata={masterData} id={props.match.params.id} />
            case 1:
                return <GroupingIdentifiers data={sortedData} masdata={masterData} />
            case 2:
                return <Ima360Data data={sortedData} masdata={masterData} />
            case 3:
                return <OrganizationData />
            default:
                return '';
        }
    }
    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Button
                        style={{ color: "#007bff" }}
                        variant='h4'
                        classes={{ root: classes.link }}
                        onClick={() => props.onSubmit(1, 0, 'search', JSON.parse(localStorage.getItem('search-data')))}
                    >
                        Customer Master
                    </Button>
                    <Typography color="textPrimary" variant='h4'>&ensp;View Customer</Typography>
                </Breadcrumbs>
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            </div>
        </div>
    );

};
const mapStateToProps = state => {
    return {
        eligibilityRulesData: state.addContractData.eligibilityRulesData,
        calculationRulesData: state.addContractData.calculationRulesData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        customerMasterData: state.customerData.customerMasterViewData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        onSubmit: (pagination, limit, keyword, data) => dispatch(getCustomerMasterData(pagination, limit, keyword, data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);