import React, { useEffect, forwardRef, useRef, useState } from "react";
import clsx from "clsx";
import { createTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";

import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import {
  fetchDocumentLevels,
  fetchSingleDocumentLevels,
  addDocumentLevels,
  fetchDistinctDocumentLevels,
} from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LoadingOverlay from "react-loading-overlay";
import { DeleteOutline } from "@mui/icons-material";
import MaterialTable from "material-table";
import Tooltip from "@mui/material/Tooltip";
import HashLoader from "react-spinners/HashLoader";
import { tableIcons } from "../../../components/Icons/TableIcons";
import {
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
} from "@mui/material";
import { LibraryAdd, Visibility } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: "20px 16px",
    marginTop: -2
    // fontSize: theme.typography.h4.fontSize,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  description: {
    textAlign: "left",
    paddingLeft: 28,
  },
  IconButton: {
    padding: 0,
    marginRight: 10,
    //fontSize: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 15,
  },
  header: {
    backgroudColor: "red",
  },
  buttonCreate: {
    // textTransform: 'none', backgroundColor: theme.palette.primary.main, color: 'white'
    marginTop: 10,
    color: "black",
  },
  spinner: {
    // height: '100vh'
  },
  fabContainer: {
    marginRight: 5,
    padding: 3,
    border: "2px solid",
    backgroundColor: "#ffff",
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1,
  },
}));

const DocumentManagementMain = (props) => {
  const { className } = props;
  const classes = useStyles();
  const history = useHistory();
  const [dataRows, setDataRows] = React.useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [currentDeleteLevel, setCurrentDeleteLevel] = React.useState("");
  const [currentDeleteType, setCurrentDeleteType] = React.useState("");
  const theme = useTheme();
  const [dialog, setDialog] = React.useState(false);
  const [application, setApplication] = useState(
    sessionStorage.getItem("application")
      ? sessionStorage.getItem("application")
      : ""
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  useEffect(() => {
    props.fecthDocuments();
  }, []);
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || str.trim() === '') {
      return '';
    }
    return str.replace(/_/g, ' ').replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  };
  const columns = [
    {
      field: "application_type",
      title: "Application Type",
    },
    {
      field: "functionality",
      title: "Functionality",
      render: (rowData) => capitalizeFirstLetter(rowData.functionality),
    },
    {
      field: "workflow_name",
      title: "Workflow Name",
      render: (rowData) => capitalizeFirstLetter(rowData.workflow_name)
    },
    {
      field: "Actions",
      title: "Actions",
      type: "string",
      editable: "never",
      sorting: false,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div>
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => handleDocument(rowData, "view")}
              size={"small"}
            >
              <Visibility color="disabled" style={{ fontSize: 20 }} />
            </IconButton>
            <IconButton
              classes={{ root: classes.IconButton }}
              onClick={() => handleDocument(rowData, "update")}
              disabled={rowData.document_status == "acc" ? true : false}
              size={"small"}
            >
              <EditIcon color="disabled" style={{ fontSize: 20 }} />
            </IconButton>
          </div>
          <div style={{ marginLeft: 5, borderLeft: "1px solid lightgray" }}>
            <IconButton
              onClick={() => deleteCon(rowData)}
              classes={{ root: classes.IconButton }}
              size={"small"}
            >
              <DeleteOutline />
            </IconButton>
          </div>
        </div>
      ),
    },
  ];

  const deleteCon = (item) => {
    setCurrentDeleteLevel(item);
    setCurrentDeleteType(item.document_type);
    setOpenConfirmDelete(true);
  };

  function handleDocument(item, type) {
    props.fecthSingleDocLevels(item, type);
  }

  useEffect(() => {
    if (props.documentLevels) {
      if (props.documentLevels.length > 0) {
        setDataRows(props.documentLevels);
      } else {
        setDataRows([]);
      }
    }
  }, [props.documentLevels]);

  const addDocLevels = () => {
    history.push("/document-workflow/create");
  };

  function handleDialog(bool) {
    if (bool === "No") {
      setOpenConfirmDelete(true);
    } else {
      props.onDelete(currentDeleteLevel, "delete");
      setOpenConfirmDelete(false);
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner={<HashLoader />}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        className={classes.spinner}
      >
        <Dialog open={openConfirmDelete}>
          <DialogContent
            classes={{
              root: classes.dialogContent,
            }}
          >
            <Typography variant="h4">
              <DialogContentText>
                Are you sure you want to delete the document level of type
                {currentDeleteType} ?
              </DialogContentText>
            </Typography>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialog,
            }}
          >
            <Button
              onClick={() => setOpenConfirmDelete(false)}
              autoFocus
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => handleDialog("Yes")}
              color="primary"
              autoFocus
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h1" color="primary">
            Approval Workflow
          </Typography>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}
          >
            {
              <div>
                <Fab
                  aria-label="edit"
                  variant="extended"
                  size="medium"
                  classes={{ root: classes.fabContainer }}
                  className={classes.fabContainer}
                  onClick={addDocLevels}
                >
                  {isDesktop ? (
                    <>
                      <LibraryAdd color="primary" />{" "}
                      <Typography color="primary" style={{ marginLeft: 3 }}>
                        Add
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Fab>
              </div>
            }
          </div>
        </div>
        {/*</div>*/}

        {dataRows && dataRows.length > 0 ? (
          dataRows &&
          dataRows.length > 0 && (
            <div>
              <Typography variant="h2">
                <MaterialTable
                  components={{
                    Toolbar: (props) => (
                      <div
                        style={{
                          height: "0px",
                        }}
                      ></div>
                    ),
                  }}
                  title={" "}
                  editable={true}
                  icons={tableIcons}
                  columns={columns}
                  data={dataRows}
                  style={{
                    // marginTop: "-18px",
                    fontSize: theme.typography.h4.fontSize,
                  }}
                  options={{
                    search: false,
                    sorting: true,
                    emptyRowsWhenPaging: false,
                    headerStyle: theme.mixins.MaterialHeader,
                    cellStyle: theme.mixins.MaterialCell,
                    filtering: true,
                    pageSize: 15,
                    pageSizeOptions: [
                      15,
                      20,
                      50,
                      { value: dataRows.length, label: "Show all" },
                    ],
                  }}
                />
              </Typography>
            </div>
          )
        ) : (
          <div>There is no data to show now.</div>
        )}
      </LoadingOverlay>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    documentLevels: state.DocumentManagement.distinctDocumentLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fecthDocuments: () => dispatch(fetchDistinctDocumentLevels()),
    fecthSingleDocLevels: (item, type) =>
      dispatch(fetchDocumentLevels(item, type)),
    onDelete: (data, type) => dispatch(addDocumentLevels(data, type)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentManagementMain);
