import React, { useEffect } from 'react';
import {
    Typography, FormControl,
    OutlinedInput, Button, Select, MenuItem, InputAdornment
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ErrorOutlineOutlined, NavigateNext, SaveOutlined, SellOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createStickers, getBudgetingConfig, getModels } from '../../../../../redux/actions';
import { Header, DefineMarket } from './Components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import { Menu } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '15px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    container: {
        marginTop: 20,
        height: 44,
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E2E2',
        paddingBottom: 10,
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    subContainer: {
        // backgroundColor: '#F8F8F8',
        display: 'flex',
        marginLeft: 21,

    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const MarketDefinition = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [modelYear, setModelYear] = React.useState([]);
    const [stickerName, setStickerName] = React.useState('');
    const [sticker, setSticker] = React.useState('');
    const [headerData, setHeaderData] = React.useState({});
    const [defineMarket, setDefineMarket] = React.useState({});
    const [defineMarketChild, setDefineMarketChild] = React.useState({});

    useEffect(() => {
        props.getConfigData();
        var model = {
            table_name: 'material_master',
        }
        var location = {
            table_name: 'territory',
            filter: {
                country: ['USA']
            }
        }
        props.getFilterData('material_master', model)
        //props.getFilterData('territory', location)
    }, []);
    useEffect(() => {
        if (modelYear && modelYear.length > 0) {
            var model = {
                table_name: 'material_master',
                filter: {
                    material_group1: modelYear
                }
            }
            props.getFilterData('material_master', model)
        }
    }, [modelYear]);
    useEffect(() => {
        if (props.sticker) {
            setSticker(props.sticker)
        }

    }, [props.sticker]);
    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
        }
    }, [props.configData]);
    function handleHeaderData(value) {
        if (value == 'return')
            props.onChange('return')
        else
            setHeaderData(value)

    }
    function handleStickerData(value, type) {
        if (type == 'material_group1')
            setModelYear(value)
        else
            setStickerData(value)
    }
    function handleStickerDesc(desc) {
        setStickerName(desc)
    }
    function handleApplyGrid() {

        props.onChange({ [sticker]: defineMarket }, { [sticker]: defineMarketChild })

    }
    function handleSelectedModels(value, type) {
        setDefineMarketChild(prev => ({
            ...prev,
            [type]: value
        }))
        // props.onSelectModels({ [sticker]: value })

    }
    function handleDefineMarket(value, checked, type) {
        var label = type + "_checked"
        setDefineMarket(prev => ({
            ...prev,
            [type]: value,
            [label]: checked
        }))

    }
    return (
        <div className={classes.root}>
            <div>
                <Header configData={productLinesList} defaultDivison={productType} onChange={handleHeaderData} stickerName={stickerName} country={props.country}
                    handleBack={props.handleBack}
                />
            </div>
            <div className={classes.container} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.subContainer}>
                    <FormControl variant="outlined" fullWidth>{console.log(sticker)}
                        <Select
                            value={sticker}
                            onChange={(e) => setSticker(e.target.value)}
                            label="Select"
                            input={<OutlinedInput startAdornment={<InputAdornment position="start"><SellOutlined /></InputAdornment>} />}
                            style={{ width: '200px' }}
                        >
                            <MenuItem value={''}></MenuItem>
                            {props.stickers && props.stickers.map((option) => (
                                <MenuItem key={option.sticker_description} value={option.ID} style={{ display: 'flex' }}>
                                    <CircleIcon style={{ color: props.colorPicker && props.colorPicker[option.ID] }} fontSize='12px' />
                                    {option.version > 1 ? `V${option.version} - ${option.sticker_description}` : option.sticker_description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex', marginLeft: 46 }}>
                        <ErrorOutlineOutlined /> <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>You are currently defining market for a {props.country && props.country == 'CA' ? 'CANADA' : 'USA'} sticker</Typography>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button variant='Outlined' color='primary'> Cancel</Button>
                    <Button variant='contained' color='primary' onClick={handleApplyGrid} disabled={!sticker ? true : false}> Apply To Grid</Button>
                </div>

            </div>
            <DefineMarket onChange={handleStickerData} getSticker={handleStickerDesc} filterData={props.filters} active={props.active}
                handleDefineMarket={handleDefineMarket} locationData={props.locations} modelYears={props.modelYears} handleSelectedModels={handleSelectedModels} />

        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        createSticker: (data) => dispatch(createStickers(data, 'create')),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketDefinition);


// ['160_2020_Spyde', '160_2020_Spyde_spyde1', '160_2020_Spyde_mat1', '160_2022_Spyde', '160_2022_Spyde_nkm', '160_2020_def',
//     '161_2020_Spyde', '161_2020_Spyde_spyde1', '162_2020_Spyde_mat1', '163_2022_Spyde', '164_2022_Spyde_nkm', '161_2020_def'
// ]