import React from 'react';
import { ExternalAccuralPostingForm } from './containers';

const ExternalAccuralPosting = props => {

    return (
        <ExternalAccuralPostingForm />
    );

};

export default ExternalAccuralPosting;