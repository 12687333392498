import {
  CALCULATION_SIMULATION_SUCCESS,
  CALCULATION_SIMULATION_FAILED,
  FETCH_START,
  FETCH_END,
  FINANCIAL_POSTINGS_SIMULATION_SUCCESS,
  FINANCIAL_POSTINGS_SIMULATION_FAILED,
  PAYMENT_POSTINGS_SIMULATION_SUCCESS,
  NEXT_TIER,
  CALC_SIMULATION_SUCCESS,
  REBATE_ESTIMATE
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { history } from "../../../../components/Helpers";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import secureLocalStorage from "react-secure-storage";

export const calculationSimulationSuccess = (data, errorList) => {
  return {
    type: CALCULATION_SIMULATION_SUCCESS,
    data,
    errorList,
  };
};

export const calculationSimulationFailed = (data) => {
  return {
    type: CALCULATION_SIMULATION_FAILED,
    data,
  };
};

export const financialPostingsSimulationSuccess = (data, errorList) => {
  return {
    type: FINANCIAL_POSTINGS_SIMULATION_SUCCESS,
    data,
    errorList,
  };
};

export const financialPostingsSimulationFailed = (data) => {
  return {
    type: FINANCIAL_POSTINGS_SIMULATION_FAILED,
    data,
  };
};

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const runCalculationSimulation = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCalculationSimulationAPi(data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === "CustomerRebateForecast") {
          if (
            response.data.response &&
            response.data.response.contract_calculation_simulation_result
          ) {
            dispatch(
              calculationSimulationSuccess(
                response.data.response.contract_calculation_simulation_result,
                response.data
              )
            );
          } else {
            dispatch(calculationSimulationSuccess([], response.data));
          }
          history.push("/customer-rebate-forecast-result");
        } 
        // else if (type == "integration") {
        //   if (
        //     response.data.response &&
        //     response.data.response.contract_calculation_simulation_result
        //   ) {
        //     dispatch(
        //       calcSimulationSuccess(
        //         response.data.response.contract_calculation_simulation_result,
        //         response.data
        //       )
        //     );
        //   } else {
        //     dispatch(calcSimulationSuccess([], response.data));
        //   }
        //   history.push("/calc-maintenance-result");
        // } 
        else {
          if (
            response.data.response &&
            response.data.response.contract_calculation_simulation_result
          ) {
            dispatch(
              calculationSimulationSuccess(
                response.data.response.contract_calculation_simulation_result,
                response.data
              )
            );
          } else {
            dispatch(calculationSimulationSuccess([], response.data));
          }
          history.push("/calculation-results");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(calculationSimulationFailed(error));
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.complete_error_message
        ) {
          //toast.error(error.response.data.error.complete_error_message)
        } else {
          //toast.error(ServerError);
        }
      });
  };
};

const getCalculationSimulationAPi = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
 // if (type == "integration") {
    result = axios.post(
      "calculation_simulation/v2?application_type=" + data.application_type,
      data,
      { headers: headers }
    );
  // } else
  //   result = axios.post(
  //     "calculation_simulation?application_type=" + data.application_type,
  //     data,
  //     { headers: headers }
  //   );
  return result;
};

export const runFinancialPostingsSimulation = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFinancialPostingsSimulationAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(
          financialPostingsSimulationSuccess(
            response.data.response.financial_posting_result,
            response.data
          )
        );
        history.push("/financial-results");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(calculationSimulationFailed(error.response.data));
        if (error.response) {
        } else {
          //toast.error(ServerError);
        }
      });
  };
};

const getFinancialPostingsSimulationAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.post("financial_posting_sim", data, {
    headers: headers,
  });
  return result;
};

export const runPaymentPostingsSimulation = (type, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPaymentPostingsSimulationAPi(type, data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(
          paymentPostingsSimulationSuccess(
            response.data.response.financial_posting_result,
            response.data.response.error_list
          )
        );
        history.push("/financial-results");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (error.response) {
        } else {
          //toast.error(ServerError);
        }
      });
  };
};

const getPaymentPostingsSimulationAPi = (type, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.post("claim-process?posting_type=" + type, data, {
    headers: headers,
  });
  return result;
};
export const paymentPostingsSimulationSuccess = (data, errorList) => {
  return {
    type: PAYMENT_POSTINGS_SIMULATION_SUCCESS,
    data,
    errorList,
  };
};
export const nextTier = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    nextTierAPI(data)
      .then((response) => {
        dispatch(fetchEnd());

        if (type == 'estimate')
          dispatch(RebateEstimateSuccess(response.data.response, response.data.messageLog));
        else
          dispatch(
            nextTierSuccess(response.data.response, response.data.messageLog)
          );
        history.push({ pathname: '/next-tier-simulation-result', formData: data })
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const nextTierAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result = axios.post("calculation_simulation/next-tier", data, {
    headers: headers,
  });
  return result;
};

export const nextTierSuccess = (data, errorList) => {
  return {
    type: NEXT_TIER,
    data,
    errorList,
  };
};
export const RebateEstimateSuccess = (data, errorList) => {
  return {
    type: REBATE_ESTIMATE,
    data,
    errorList,
  };
};
// only for calc simulation maintenabnce

export const calcSimulationSuccess = (data, errorList) => {
  return {
    type: CALC_SIMULATION_SUCCESS,
    data,
    errorList,
  };
};
