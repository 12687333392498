import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { updateCompetitorDataFormatData,getCompetitorDataFormatFields,getCompetitorDataFormatDataById } from "../../redux/actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../components/Helpers';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
             paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));
const CompetitorDataUploadEditQuery = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [netValue, setNetValue] = React.useState('');
    const [billingDate, setBillingDate] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState('');
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(localStorage.getItem('competitor-data-query') ? JSON.parse(localStorage.getItem('competitor-data-query')) : []);
    const [queryObject, setQueryObject] = React.useState({});
    const [defaultValue,setDefaultValue]=React.useState({});
    const [defaultCount,setDefaultCount]=React.useState(0);
    const requiredFields=[
        'billing_date', 'company_id', 'material_number', 'net_value'
    ]
    useEffect(() => {
        setOpen(true);
        props.getCompetitorDataFormatFields();
        props.getCompetitorDataFormatDataById(purchaseQueryDataStorage.id)
    }, []);

    useEffect(() => {
        if (props.competitorDataSingleFormatData) {
            setQueryName(purchaseQueryDataStorage.format_name)
            if(props.competitorDataSingleFormatData.default_values!=null) {
                setDefaultValue(props.competitorDataSingleFormatData.default_values)
                setDefaultCount(Object.entries(props.competitorDataSingleFormatData.default_values).length)
            }
            setQueryObject(props.competitorDataSingleFormatData.format)
            setCompanyId(purchaseQueryDataStorage.company_id)
            setBillingDate(purchaseQueryDataStorage.billing_date)
            setNetValue(purchaseQueryDataStorage.net_value)
            setMaterialNumber(purchaseQueryDataStorage.material_number)
        }
    }, [props.competitorDataSingleFormatData]);
    
    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if(propName!='format_name')
            {
                 queryObject[propName] =''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
       
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(purchaseQueryDataStorage.id,{...queryObject,'format_name':queryName,'default_values':defaultValue });
    }
    function handleClearAll() {
        handleClear();
         setCompanyId('')
         setNetValue('')
         setBillingDate('')
        setMaterialNumber('')
    }
    function handleCancelDialog() {
        history.push('/competitor-data/competitor-data-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if(item==='company_id')
        setCompanyId(event.target.value)
        if(item==="billing_date")
        setBillingDate(event.target.value)
        if(item==="net_value")
        setNetValue(event.target.value)
        if(item==="material_number")
           setMaterialNumber(event.target.value)
    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if(item==='company_id')
        setCompanyId(event.target.value)
        if(item==="billing_date")
        setBillingDate(event.target.value)
        if(item==="net_value")
        setNetValue(event.target.value)
        if(item==="material_number")
           setMaterialNumber(event.target.value)
        }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
               
                <Link variant='h1' classes={{
                    root: classes.link
                }}
                    to='/competitor-data/competitor-data-excel-upload'
                >  Competitor Data Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >   {props.queryListData&&queryObject&&Object.entries(queryObject).length>0 ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.queryListData
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    { requiredFields.includes(item)? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item]? queryObject[item]:''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('competitor-data-query'))[item] ? JSON.parse(localStorage.getItem('competitor-data-query'))[item] : ''}
                                                                            />
                                                                    </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70, paddingLeft: 10  }}>
                                                                        <TextField
                                                                            value={defaultCount>0&&defaultValue[item]?defaultValue[item]:''}
                                                                            type="string"
                                                                             variant="outlined"
                                                                            onChange={(e) => handleChange2(e,item)}  
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                      disabled={ (materialNumber || defaultValue['material_number']) &&(netValue || defaultValue['net_value'])? false:true}
                      >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        queryListData: state.dataSetupData.competitorDataFormatFieldData,
        competitorDataSingleFormatData: state.dataSetupData.competitorDataSingleFormatData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateCompetitorDataFormatData(id, data)),
        getCompetitorDataFormatFields: () => dispatch(getCompetitorDataFormatFields()),
        getCompetitorDataFormatDataById:(id) => dispatch(getCompetitorDataFormatDataById(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompetitorDataUploadEditQuery);