import React, { useEffect } from "react";
import "../../../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import {
    Grid,
    Typography,
    Button
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
    allowedApps,
    getActiveContractsGraph,
    dashboardAnalyticsNames
} from "../../../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    Inject,
    ChartTheme,
    Legend,
    Category,
    Tooltip,
    ColumnSeries,
    ILoadedEventArgs,
    DataLabel,
    Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "15px 0px 15px 0px",
        display: "flex",
        alignItems: "center",
    },
    // rootDiv: {
    //     backgroundColor: theme.palette.white,
    //     borderRadius: 10,
    //     margin: "5px 0px 16px 0px",
    // },
    graphContainer: {
        width: "80%",
        height: "100%",
        marginTop: 10,
        display: "flex",
        alignSelf: "center",
    },
    // container: {
    //     display: "flex",
    //     justifyContent: "center",
    //     flexDirection: "column",
    // },
    container: {
        marginTop: 16,
        [theme.breakpoints.up("md")]: {
            marginTop: 0,
        },
    },
    select: {
        width: "100%",
        color: "#1675e0",
    },
    selectedItem: {
        borderRadius: 5,
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 28,
        paddingBottom: 5,
    },
    button: {
        marginRight: 10,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
    },
    spinner: {
        // height: '100vh'
    },
}));
const MonthlyActiveContracts = (props) => {
    const classes = useStyles();
    const [maximumNumber, setMaximumNumber] = React.useState(50);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [avssGraphData, setAvssGraphData] = React.useState([]);

    // useEffect(() => {
    //     props.allowedAnalyticsApps(sessionStorage.getItem("application"));
    // }, []);

    // useEffect(() => {
    //     if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
    //         const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
    //             item.analytic_name === "Monthly Active Contracts"
    //         );
    //         if (postingsSummaryAnalytic) {
    //             setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
    //         } else {
    //             setAllowedTilesFilter([]);
    //         }
    //     }
    // }, [props.allowedAnalyticsAppsData])
    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Monthly Active Contracts" && item.dashboard_name === "Summarized Dashboard"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedDashBoardData]);

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (startDate && endDate) {
            props.getActiveContractsGraph(sessionStorage.getItem("application"), startDate, endDate);
        }
    }, [allowedTilesFilter]);

    useEffect(() => {

        const xAxis = props.monthlyActiveData ? props.monthlyActiveData.x_axis : [];
        const xAxisValue = props.monthlyActiveData ? props.monthlyActiveData.x_axis_values : [];

        var max = [];
        for (let i = 0; i < xAxisValue.length; i++) {
            max[i] = xAxisValue[i];
        }

        const mappedDataArray = [];

        for (let i = 0; i < xAxis.length; i++) {
            const mappedData = {
                x: xAxis[i],
                y: xAxisValue[i],
                toolTipMappingName: xAxis[i],
            };
            mappedDataArray.push(mappedData);
        }
        setAvssGraphData(mappedDataArray)

        if (props.monthlyActiveData) {
            // setMaximumNumber(Math.max(...max));
            const maximumofnumber = Math.max(...max)
            if (maximumofnumber > 50)
                setMaximumNumber(maximumofnumber);
        }
    }, [props.monthlyActiveData]);


    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])


    const handleStartDate = (e) => {
        setStartDate(e);
    }

    const handleEndDate = (e) => {
        setEndDate(e);
    }

    const handleRun = () => {
        props.getActiveContractsGraph(sessionStorage.getItem("application"), Moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), Moment(endDate).format('YYYY-MM-DDTHH:mm:ss'));
    }

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid
                    container
                    className={classes.root}
                    style={{ justifyContent: "space-between" }}
                >
                    {/* {props.page !== "dashboard" && (
                        <Grid
                            container
                            style={{
                                margin: 10,
                                padding: 5,
                                border: "1px solid #E6E6E6",
                                borderRadius: 5,
                            }}
                        >

                            <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={startDate}
                                                onChange={handleStartDate}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '1px 0 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        borderRadius: 5,
                                                        color: '#1675e0'
                                                    }
                                                }}
                                                value={endDate}
                                                onChange={handleEndDate}
                                                format="MM/DD/YYYY"
                                                minDate={startDate ? startDate : false}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleRun}
                                    twoline="true"
                                    disabled={(!startDate || !endDate) ? true : false}
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                        </Grid>
                    )} */}
                    <Grid container className={classes.root}>
                        <Grid item md={12} xs={6} className={classes.container}>
                            <div className={`area-chart-wrapper${classes.graphContainer}`}>
                                {/* <Typography variant="h3" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" > Monthly Active Contracts </Typography> */}
                                <ChartComponent
                                    id="charts"
                                    style={{ textAlign: "center" }}
                                    legendSettings={{ enableHighlight: true }}
                                    primaryXAxis={{
                                        labelIntersectAction: Browser.isDevice ? "None" : "Trim",
                                        labelRotation: Browser.isDevice ? -45 : 0,
                                        valueType: "Category",
                                        interval: 1,
                                        majorGridLines: { width: 0 },
                                        majorTickLines: { width: 0 },
                                        edgeLabelPlacement: "Shift",

                                    }}
                                    primaryYAxis={{
                                        majorTickLines: { width: 0 },
                                        lineStyle: { width: 0 },
                                        maximum: maximumNumber + maximumNumber / 100,
                                        interval: parseInt(maximumNumber / 10),
                                    }}
                                    chartArea={{ border: { width: 0 } }}
                                    tooltip={{
                                        enable: true,
                                        header: "<b>${point.tooltip}</b>",
                                        shared: true,
                                    }}
                                    width={Browser.isDevice ? "100%" : "95%"}
                                    height={Browser.isDevice ? "180%" : "100%"}
                                    title=""
                                >
                                    <Inject
                                        services={[
                                            ColumnSeries,
                                            Legend,
                                            Tooltip,
                                            Category,
                                            DataLabel,
                                            Highlight,
                                        ]}
                                    />
                                    <SeriesCollectionDirective>
                                        <SeriesDirective
                                            dataSource={avssGraphData}
                                            tooltipMappingName="toolTipMappingName"
                                            xName="x"
                                            columnSpacing={0.1}
                                            yName="y"
                                            name="Contracts"
                                            type="Column"
                                            fill='#FEC200'
                                        ></SeriesDirective>
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </LoadingOverlay>
    );
}


const mapStateToProps = (state) => {
    return {
        loading: state.initialData.loading,
        monthlyActiveData: state.initialData.activeContractsGraphData,
        allowedAppsData: state.initialData.allowedApps,
        // allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getActiveContractsGraph: (type, sd, ed) =>
            dispatch(getActiveContractsGraph(type, sd, ed)),
        allowedApps: () => dispatch(allowedApps()),
        allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyActiveContracts);