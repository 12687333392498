import React, { useEffect } from 'react';
import {
    Typography
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        height: 75,
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },
}));


const StickerTableHeader = (props) => {
    const classes = useStyles();
    const [productLinesList, setProductLinesList] = React.useState();
    const [productType, setProductType] = React.useState('');
    const [stickerName, setStickerName] = React.useState('');

    useEffect(() => {
        if (props.productLineData && props.productLineData.length > 0) {
            setProductLinesList(props.productLineData)
            // setProductType(props.productLineData[0])
        }
    }, [props.productLineData]);

    useEffect(() => {
        setStickerName(props.stickerName);
    }, [props.stickerName]);

    useEffect(() => {
        if (props.defaultDivison)
            setProductType(props.defaultDivison);
    }, [props.defaultDivison]);


    function handleChangeProduct(e) {
        props.onChange(e);
    }


    return (
        <>
            <div className={classes.root}>
                <div className={classes.mainHeader}>
                    <ToggleButtonGroup
                        color="primary"
                        value={productType}
                        exclusive
                        onChange={(e) => handleChangeProduct(e)}
                        aria-label="Platform"
                        style={{ width: '48%' }}
                        disabled={props.isDisabled ? true : false}
                    >
                        {productLinesList && productLinesList.length > 0 && productLinesList.map(item => (
                            <>
                                <ToggleButton value={item} className={classes.toggleBtnContainer} >{item} </ToggleButton>
                                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 3.5 }} />
                            </>
                        ))}
                    </ToggleButtonGroup>
                    <div>
                        <Typography style={{ marginLeft: 5, marginTop: 23 }}>{stickerName}</Typography>
                    </div>

                </div>
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerTableHeader);