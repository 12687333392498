import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getAttributeListData, getDefaultValues,
    getDefaultValuesKeyFigure
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { ChipInput } from '../../../../components/Inputs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AllOtherAttributeMasterComponent, SearchDropdown } from '../../../../components/Inputs';

var deleteFlag = false;
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex'
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 55
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
    },
    caption: {
        fontSize: 12
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 0
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 0,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 38,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 0,
        height: 35,
        padding: 5
    },
    formDiv: {
        height: 40,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    borderRad0: {
        borderRadius: '0px !important'
    },
    conditionID: {
        display: (sessionStorage.getItem('application') === 'Customer Chargeback' ||
            sessionStorage.getItem('application') === 'Supplier Chargeback' ||
            sessionStorage.getItem('application') === 'Pricing')
            ? 'none' : null
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const CalculationModifier = forwardRef((props) => {
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [keyFigureName, setKeyFigureName] = React.useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }

    useEffect(() => {
        props.getAttributeListData({
            attribute_name: ''
        })
    }, [])
    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setMappingValuesSet(props.dropdownDataCR.records[0].label_names);
            }
        }
    }, [props.dropdownDataCR])

    const inputFile = useRef(null)
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [attributeName, setAttributeName] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [modifierData, setModifierData] = React.useState([]);
    const [attributeNameEnableHelper, setAttributeNameEnableHelper] = React.useState(false);
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);
    const [attributeNameValue, setAttributeNameValue] = React.useState('');
    const [editIndex, setEditIndex] = React.useState('');
    const [calcType, setCalcType] = React.useState('');
    const calcTypeData = ['Tier', 'Rebate', 'Both']

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        if (props.data) {
            if (props.data.contract_calculation_rule_modifiers && props.data.contract_calculation_rule_modifiers.length > 0)
                setModifierData(props.data.contract_calculation_rule_modifiers);
        }
    }, [props.data]);
    const editCalcRule = (rule, index) => {
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setAttributeName(rule.attribute_name);
        setAttributeValue(rule.attribute_values);
        setKeyFigureName(rule.key_figure_name);
        setEffectiveStartDate(rule.start_date);
        setEffectiveEndDate(rule.end_date);
        setCalcType(rule.calculation_type)
    }
    function handleAttributename(event) {
        setAttributeValue([]);

        setAttributeNameValue(event.target.value);
        setAttributeName(event.target.value)
        setAttributeNameEnableHelper((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);
    }

    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event);
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }

    function handleSubmit(id) {

        if (attributeName && keyFigureName && effectiveStartDate && effectiveEndDate && attributeValue) {


            var data = {
                "calculation_type": calcType,
                "attribute_name": attributeName,
                "key_figure_name": keyFigureName,
                "attribute_values": attributeValue,
                "start_date": effectiveStartDate,
                "end_date": effectiveEndDate,

            };


            if (id || id === 0) {
                var editedArray = [...modifierData];
                editedArray[editIndex] = data;
                setModifierData(editedArray);
                props.onChange(editedArray)
                localStorage.removeItem('editableRuleMode');
            } else {
                setModifierData([...modifierData, data]);

                props.onChange([...modifierData, data])
            }
            handleRemove();
        } else {
            toast.error("Please fill all the mandatory fields.");
        }
        setAttributeArray([]);
    }
    function handleRemove() {
        setEditMode(false);
        setAttributeValue([]);
        setAttributeName('');
        if (effectiveStartDate != props.startDate)
            setEffectiveStartDate(null);
        if (effectiveEndDate != props.endDate)
            setEffectiveEndDate(null);
        setKeyFigureName('');
        setCalcType('')

    }
    useEffect(() => {
        if (props.sourceDataType) {
            props.onLoadingDefault(sessionStorage.getItem('application'), props.sourceDataType);
            props.onLoadingDefaultCR(sessionStorage.getItem('application'), props.sourceDataType);

        }
    }, [props.sourceDataType])
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
            if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownAttributeData.records[0].label_names);
                setAttributeNameObject(props.dropdownAttributeData.records[0])
            }
        }
    }, [props.dropdownAttributeData])

    useEffect(() => {
        if (props.startDate)
            setEffectiveStartDate(props.startDate)
        if (props.endDate)
            setEffectiveEndDate(props.endDate)

    }, [props.startDate, props.endDate])

    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setModifierData(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...modifierData];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [modifierData])

    //add operation
    const [attributeArray, setAttributeArray] = React.useState([]);

    function handleCacType(event) {
        setCalcType(event.target.value)
    }

    const handleAttributeValueFA = (val, type) => {
        setAttributeValue(val)
    }
    function handleKeyFigurename(event) {
        setKeyFigureName(event.target.value);
    }
    const handleDelete = (value) => {
        setAttributeValue(itemX => itemX.filter(item => item != value))
    };
    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={1} >
                                <Grid
                                    item
                                    md={7}
                                >
                                    {/* for tear calculation */}
                                    <Grid container spacing={1} >
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.formDiv} required>
                                                    Calculation Type
                                                </FormLabel>
                                                <Select
                                                    value={calcType}
                                                    onChange={handleCacType}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {calcTypeData
                                                        .map(item => {
                                                            return (
                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>

                                            </div>
                                            {/* <Typography variant='h6'>A brief description corresponding to the selected attribute.</Typography> */}
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.formDiv}>
                                                    Key Figure Name
                                                </FormLabel>
                                                <Select
                                                    value={keyFigureName}
                                                    onChange={handleKeyFigurename}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}

                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 ? '' : '- Please configure -'}
                                                    </MenuItem>

                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                        Object.entries(mappingValuesSet)
                                                            .sort()
                                                            .map(([key, value]) => {
                                                                return (
                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                        {value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                </Select>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.formDiv}>
                                                    Attribute Name
                                                </FormLabel>
                                                <Select
                                                    value={attributeName}
                                                    onChange={handleAttributename}
                                                    displayEmpty
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                        {attributeNameData && Object.keys(attributeNameData).length > 0 ? '' : '- Please configure -'}
                                                    </MenuItem>
                                                    {//mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                        attributeNameData && Object.keys(attributeNameData).length > 0 &&
                                                        Object.entries(attributeNameData).map(([key, value]) => {
                                                            return (
                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                                {/* } */}

                                            </div>
                                            {/* <Typography variant='h6'>A brief description corresponding to the selected attribute.</Typography> */}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.formDiv}>
                                                    Attribute Value
                                                </FormLabel>
                                                {(attributeName === 'sold_to_party'
                                                    || attributeName === 'ship_to_party'
                                                    || attributeName === 'payer'
                                                    || attributeName === 'bill_to_party'
                                                    || attributeName === 'customer_number')
                                                    ?
                                                    <div className={classes.selectRoot} style={{ marginTop: '-0.3rem' }} >

                                                        <SearchDropdown id='customer_single'
                                                            table={'customer_master'}
                                                            keyName={'customer_number'}
                                                            description={true}
                                                            onChange={(value) => handleAttributeValueFA(value)}
                                                            prevalue={attributeValue}
                                                            multiple
                                                        />
                                                    </div>
                                                    :
                                                    attributeName === 'material_number' ?
                                                        <div style={{ marginTop: '-0.3rem' }}>
                                                            <SearchDropdown id='material_single'
                                                                table={'material_master'}
                                                                keyName={'material_number'}
                                                                description={true}
                                                                onChange={(value) => handleAttributeValueFA(value)}
                                                                prevalue={attributeValue}
                                                                multiple
                                                            />

                                                        </div>
                                                        :
                                                        attributeName === 'supplier_number' ?
                                                            <div style={{ marginTop: '-0.3rem' }}>
                                                                <SearchDropdown id='supplier_single'
                                                                    table={'supplier_master'}
                                                                    keyName={'supplier_number'}
                                                                    description={true}
                                                                    onChange={(value) => handleAttributeValueFA(value)}
                                                                    prevalue={attributeValue}
                                                                    multiple
                                                                />
                                                            </div>
                                                            :
                                                            attributeName === 'employee_number' ?
                                                                <div style={{ marginTop: '-0.3rem' }}>
                                                                    <SearchDropdown id='employee_single'
                                                                        table={'employee_master'}
                                                                        keyName={'employee_number'}
                                                                        description={true}
                                                                        onChange={(value) => handleAttributeValueFA(value)}
                                                                        prevalue={attributeValue}
                                                                        multiple
                                                                    />
                                                                </div>
                                                                : attributeName === 'customer_chain' || attributeName === 'customer_group' || attributeName === 'material_group' || attributeName === 'product_hierarchy' || attributeName === 'company_code' || attributeName === 'sales_org' || attributeName === 'supplier_group' ?
                                                                    <div style={{ marginTop: -10 }}>
                                                                        <AllOtherAttributeMasterComponent attributeName={attributeName} value={attributeValue} onChange={(e) => handleAttributeValueFA(e, 'attr')}
                                                                            prevalue={attributeValue} withOutLabel={true} id={attributeName} multiple />
                                                                    </div>
                                                                    :
                                                                    <ChipInput data={attributeValue} onChange={handleAttributeValueFA} />

                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.formDiv}>
                                                    Start Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={effectiveStartDate}
                                                            onChange={date => handleEffectiveStartDate(date)}
                                                            format="MM/DD/YYYY"
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            minDate={props.startDate ? props.startDate : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.formDiv}>
                                                    End Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={effectiveEndDate}
                                                            onChange={date => handleEffectiveEndDate(date)}
                                                            format="MM/DD/YYYY"
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item md={2} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingBottom: 15, paddingTop: 5, marginTop: '-0.8rem' }} >
                                        {editMode ?
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!attributeName || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true))
                                                    || !(attributeValue && attributeValue.length > 0) || !keyFigureName ? true : false}
                                                onClick={() => handleSubmit(editIndex)}
                                            >
                                            </Button>
                                            :
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<CheckIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!attributeName || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true))
                                                    || !(attributeValue && attributeValue.length > 0) || !keyFigureName ? true : false}
                                                onClick={() => handleSubmit('')}
                                            >
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteForeverIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.deleteIconContainer,
                                            }}
                                            onClick={handleRemove}
                                            style={{ marginLeft: 10 }}
                                        >
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </div>
                    </form>
                </Card >
            </div >
            <Card style={{ marginTop: '-1rem' }}>
                <div className={classes.row} style={{ marginTop: '-1rem' }} >
                    <CardHeader
                        title="CALCULATION RULE MODIFIERS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2} style={{ marginTop: '-1.5rem' }} >
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {modifierData.length > 0 ?
                                <TableContainer>

                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Calculation Type</TableCell>
                                                <TableCell align='center' >KeyFigure Name</TableCell>
                                                <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                <TableCell align='center'>Attribute Values</TableCell>
                                                <TableCell align='center' >Start Date</TableCell>
                                                <TableCell align='center' >End Date</TableCell>
                                                <TableCell align='center'>Options</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {modifierData
                                                .map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={item.ID}>
                                                            <TableCell align='center'>{item.calculation_type}</TableCell>
                                                            <TableCell align='center'>{mappingValuesSet && mappingValuesSet[item.key_figure_name] ? mappingValuesSet[item.key_figure_name] : item.key_figure_name}</TableCell>
                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                {attributeNameData && attributeNameData[item.attribute_name] ? attributeNameData[item.attribute_name] : item.attribute_name.replace(/_/g, ' ')
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center' classes={{ root: classes.leftAlign }}>{item.attribute_values ? item.attribute_values.join(",") : ''}</TableCell>

                                                            <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>

                                                            <TableCell align='center'>
                                                                <IconButton
                                                                    onClick={() => editCalcRule(item, index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => handleDeleteLocally(index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>



                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div >
    );

});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.dataSetupData.loading,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationModifier);