import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Link, FormLabel, OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../components/Dialog';
import { runCalculationSimulation, getDefaultValues, runDynamicQuery } from '../../redux/actions';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import ChipInput from 'material-ui-chip-input';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '10px 45px 10px 45px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    spinner: {
        height: '100vh'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 35
    },
}));

const DynamicQueryForm = props => {
    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const contractCustomisationUpdation = useRef();
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState('');
    const handleDynamicFieldValues = (e, fieldName, type) => {
        if (type === 'range') {
            if (dynamicFieldValues[fieldName] && dynamicFieldValues[fieldName].length > 0) {
                if (e.target.value) {
                    setDynamicFieldValuesRange(e.target.value)
                    setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: { "from": dynamicFieldValues[fieldName][0], "to": e.target.value } })
                } else {
                    setDynamicFieldValuesRange(e.target.value)
                    setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: dynamicFieldValues })
                }
            } else {
                setDynamicFieldValuesRange('');
            }
        } else {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
            setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [fieldName]: e })
        }
    };

    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [applicationTypeArray, setApplicationTypeArray] = React.useState([]);
    const [calculationSchemaArray, setCalculationSchemaArray] = React.useState([]);
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
    const [currentQueryID, setCurrentQueryID] = React.useState('');
    useEffect(() => {
        props.onLoadingDefault();
        if (localStorage.getItem("dynamic_filter_fields")) {
            var object = JSON.parse(localStorage.getItem("dynamic_filter_fields"))
            setFilterFieldsArray(object.fields);
            setCurrentQueryID(object.id);
        }
    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'application_type') {
                            setApplicationTypeArray(item);
                        }
                        if (item.field_id === 'calculation_schema') {
                            setCalculationSchemaArray(item);
                        }
                    })
                }
            }
        }
    });
    const [calculationPeriodStart, setCalculationPeriodStart] = useState('');
    const [calculationPeriodEnd, setCalculationPeriodEnd] = useState('');
    const [applicationType, setApplicationType] = useState('');
    const [contractNumber, setContractNumber] = useState([]);
    const [contractType, setContractType] = useState('');
    const [contractSubtype, setContractSubtype] = useState('');
    const [open, setOpen] = useState(false);
    const formData = {
        "application_type": sessionStorage.getItem('application'),
        "contract_number": contractNumber,
        "contract_type": contractType,
        "calculation_schema": contractSubtype,
        "calculation_period_start": Moment.utc(calculationPeriodStart),
        "calculation_period_end": Moment.utc(calculationPeriodEnd),
    };
    const startRef = useRef(null);
    const endRef = useRef(null);
    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setContractNumber([]);
        startRef.current.reset();
        endRef.current.reset();
        typeRef.current.reset();
        subTypeRef.current.reset();
    }
    const handleRun = () => {
        if (dynamicFieldValuesFormSubmit) {
            var formData = {
                id: currentQueryID.toString(),
                filter_fields: dynamicFieldValuesFormSubmit
            }
            localStorage.setItem('dyamicQueryDownloadData', JSON.stringify(formData));
        } else {
            var formData = {
                id: currentQueryID.toString()
            }
            localStorage.setItem('dyamicQueryDownloadData', JSON.stringify(formData));
        }

        var data = {
            "id": currentQueryID.toString(),
            "filter_fields": dynamicFieldValuesFormSubmit
        }
        props.runDynamicQuery(data, currentQueryID);
    };

    return (
        <LoadingOverlay
            active={props.loading}
            spinner
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            text='Loading results...'
            className={classes.spinner}
        >
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/dynamic-query')}
                >
                    Dynamic Query
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Dynamic Query Form</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h2"> Dynamic Query Form </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            {filterFieldsArray && filterFieldsArray.map(item => {
                                return (
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel style={{ textTransform: 'capitalize' }}>
                                            {item.replace(/_/g, ' ')}
                                        </FormLabel>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '50%', marginRight: 5 }}>
                                                <ChipInput
                                                    classes={{
                                                        root: classes.rootContainer,
                                                        chip: classes.chip,
                                                        input: classes.input,
                                                        inputRoot: classes.inputRoot,
                                                        label: classes.chipLabel,
                                                        chipContainer: classes.chipContainer,
                                                    }}
                                                    onChange={(e) => handleDynamicFieldValues(e, item)}
                                                    disableUnderline={true}
                                                />
                                            </div>
                                            <div style={{ width: '50%', marginLeft: 5 }}>
                                                <OutlinedInput
                                                    classes={{ root: classes.inputTwoLine }}
                                                    type={props.type}
                                                    value={dynamicFieldValues[item] && dynamicFieldValues[item].length > 0 ? dynamicFieldValuesRange[item] : ''}
                                                    onChange={(e) => handleDynamicFieldValues(e, item, 'range')}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={handleRun} twoline='true'>
                                Run
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to simulate the calculation results?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runCalculationSimulation(formData)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        runDynamicQuery: (data) => dispatch(runDynamicQuery(data))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryForm);