import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem, OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, TextInputWithLabel, DropdownArray, DropdownKeyValue } from '../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        //border: '1px solid #EEEEEE',
        // borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        // padding: 10
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    selectRoot: {
        padding: '18px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const Accrual = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();
    //constants
    const [accrualFrequencyData, setAccrualFrequencyData] = React.useState([]);
    const [accrualFrequencyName, setAccrualFrequencyName] = React.useState('');
    const [accrualLevelData, setAccrualLevelData] = React.useState([]);
    const [accrualLevelName, setAccrualLevelName] = React.useState('');
    const [copaLevelData, setCopaLevelData] = React.useState([]);
    const [copaLevelName, setCopaLevelName] = React.useState('');
    //arrays
    const [accrualFrequencyArray, setAccrualFrequencyArray] = React.useState([]);
    const [accrualCalendarArray, setAccrualCalendarArray] = React.useState([]);
    const [accrualLevelArray, setAccrualLevelArray] = React.useState([]);
    const [accruaGLAccountCreditArray, setAccruaGLAccountCreditArray] = React.useState([]);
    const [accruaGLAccountDebitArray, setAccruaGLAccountDebitArray] = React.useState([]);
    const [copaLevelArray, setCopaLevelArray] = React.useState([]);
    const [flexFields4Array, setFlexFields4Array] = React.useState([]);
    const [autoRunPpaArray, setAutoRunPpaArray] = React.useState([]);
    const [accrualCreditProfitCenterArray, setAccrualCreditProfitCenterArray] = React.useState([]);
    const [accrualDebitProfitCenterArray, setAccrualDebitProfitCenterArray] = React.useState([]);

    //new fields
    const [internalReferenceArray, setInternalReferenceArray] = React.useState([]);
    const [internalReference, setInternalReference] = React.useState('');
    const [postingsBlockArray, setPostingsBlockArray] = React.useState([]);
    const [postingsBlock, setPostingsBlock] = React.useState('');
    const [financialPostingsStartDateArray, setFinancialPostingsStartDateArray] = React.useState([]);
    const [financialPostingsStartDate, setFinancialPostingsStartDate] = React.useState('');
    const [financialPostingsEndDateArray, setFinancialPostingsEndDateArray] = React.useState([]);
    const [financialPostingsEndDate, setFinancialPostingsEndDate] = React.useState('');
    const [autoRunPpa, setAutoRunPpa] = React.useState('');
    const [accrualCreditProfitCenter, setAccrualCreditProfitCenter] = React.useState('');
    const [accrualDebitProfitCenter, setAccrualDebitProfitCenter] = React.useState('');
    const [fieldData, setFieldData] = React.useState([]);
    const [shortTermPeriod, setShortTermPeriod] = React.useState(0);
    const [longTermPeriod, setLongTermPeriod] = React.useState(0);
    const [shortTermAccount, setShortTermAccount] = React.useState('');
    const [longTermAccount, setLongTermAccount] = React.useState('');
    const [amortizationValue, setAmortizationValue] = React.useState('');
    const [amortizationValueList, setAmortizationValueList] = React.useState({});
    const [contractData, setContractData] = React.useState({});
    useEffect(() => {
        props.onChange(accrualValues);
    });

    useEffect(() => {
        setFieldData(props.fieldData)
    }, [props.fieldData]);


    const classes = useStyles();
    const { className } = props;
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState('');
    const [accrualGLCredit, setAccrualGLCredit] = React.useState('');
    const [accrualGLDebit, setAccrualGLDebit] = React.useState('');
    const [flexFields4, setFlexFields4] = React.useState('');
    const [accrualRun, setAccrualRun] = React.useState('');
    var accrualValues = {
        accrual_frequency: accrualFrequency,
        accrual_level: accrualLevel,
        copa_level: copaLevel,
        accrual_calendar: accrualCalendar,
        accrual_gl_account_credit: accrualGLCredit,
        accrual_gl_account_debit: accrualGLDebit,
        internal_reference: internalReference,
        flex_fields_4: flexFields4,
        accrual_run: accrualRun,
        accrual_credit_profit_center: accrualCreditProfitCenter,
        accrual_debit_profit_center: accrualDebitProfitCenter,
        amortization_value: amortizationValue,
        short_term_period: shortTermPeriod ? parseInt(shortTermPeriod) : 0,
        long_term_period: longTermPeriod ? parseInt(longTermPeriod) : 0,
        short_term_account: shortTermAccount,
        long_term_account: longTermAccount,
    };
    var mode = localStorage.getItem('mode');
    const accrualFrequencylRef = useRef(null);
    const accrualLevelRef = useRef(null);
    const copaRef = useRef(null);
    const accrualCalendarRef = useRef(null);
    const accrualCreditRef = useRef(null);
    const accrualDebitRef = useRef(null);

    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (!accrualFrequencylRef.current.value || accrualFrequencylRef.current.value === '')
                setAccrualFrequency(false);
            if (!accrualLevelRef.current.value || accrualLevelRef.current.value === '')
                setAccrualLevel(false);
            if (!accrualCalendarRef.current.value || accrualCalendarRef.current.value === '')
                setAccrualCalendar(false);
            if (!copaRef.current.value || copaRef.current.value === '')
                setCopaLevel(false);
            if (!accrualCreditRef.current.value || accrualCreditRef.current.value === '')
                setAccrualGLCredit(false);
            if (!accrualDebitRef.current.value || accrualDebitRef.current.value === '')
                setAccrualGLDebit(false);
        },
        getValues() {
            accrualValues = {
                accrual_frequency: accrualFrequencylRef.current.value,
                accrual_level: accrualLevelRef.current.value,
                copa_level: copaRef.current.value,
                accrual_calendar: accrualCalendarRef.current.value,
                accrual_gl_account_credit: accrualCreditRef.current.value,
                accrual_gl_account_debit: accrualDebitRef.current.value
            };
            return accrualValues;
        }
    }));

    function handleAccrualFrequency(value) {
        setAccrualFrequency(value);
    }
    function handleAccrualLevel(value) {
        setAccrualLevel(value);
    }
    function handleCopaLevel(value) {
        setCopaLevel(value);
    }
    function handleAccrualCalendar(value) {
        setAccrualCalendar(value)
    }
    function handleAccrualGLCredit(newValue) {
        setAccrualGLCredit(newValue);
    }
    function handleAccrualGLDebit(newValue) {
        setAccrualGLDebit(newValue);
    }
    // new fields
    function handleInternalReference(newValue) {
        setInternalReference(newValue);
        props.changeInternalReference(newValue);
    }
    function handlePostingBlock(newValue) {
        setPostingsBlock(newValue);
    }
    function handleFinancialPostingsStartDate(newValue) {
        if (newValue && newValue.isValid())
            setFinancialPostingsStartDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setFinancialPostingsStartDate(newValue)
    }
    function handleFinancialPostingsEndDate(newValue) {
        if (newValue && newValue.isValid())
            setFinancialPostingsEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setFinancialPostingsEndDate(newValue);
    }
    function handleFlexFields4(newValue) {
        setFlexFields4(newValue);
    }
    function handleAccrualRun(newValue) {
        setAccrualRun(newValue)
    }
    function handleAutoRunPpa(newValue) {
        setAutoRunPpa(newValue.target.value);
    }
    function handleAccrualCreditProfitCenter(newValue) {
        setAccrualCreditProfitCenter(newValue);
    }
    function handleAccrualDebitProfitCenter(newValue) {
        setAccrualDebitProfitCenter(newValue);
    }

    //handledefault
    useEffect(() => {
        if (props.contractDefaultData) {
            setAccrualLevel(props.contractDefaultData.accrual_level);
            setCopaLevel(props.contractDefaultData.copa_level);
            setAccrualGLCredit(props.contractDefaultData.accrual_gl_account_credit);
            setAccrualGLDebit(props.contractDefaultData.accrual_gl_account_debit);
            setAccrualCreditProfitCenter(props.contractDefaultData.accrual_credit_profit_center);
            setAccrualDebitProfitCenter(props.contractDefaultData.accrual_debit_profit_center);
            setAccrualFrequency(props.contractDefaultData.accrual_frequency);
            setAccrualCalendar(props.contractDefaultData.accrual_calendar);
            //new
            setAccrualFrequency(props.contractDefaultData.accrual_frequency);
        } else {
            setAccrualLevel('');
            setCopaLevel('');
            setAccrualGLCredit('');
            setAccrualGLDebit('');
            setAccrualCreditProfitCenter('');
            setAccrualDebitProfitCenter('');
            setAccrualFrequency('');
            setAccrualCalendar('');
            setAccrualFrequency('');
        }
    }, [props.contractDefaultData]);


    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-1.5rem' }}
                >
                    <CardHeader
                        title="ACCRUAL"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1.5rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['accrual_level'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['accrual_level']['current']} data={props.fieldData['accrual_level']['drop_down_reference_value']}
                                        twoline='true' onChange={handleAccrualLevel} labelFormat={'desc'} required
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_level ? props.contractDefaultData.accrual_level : ''}
                                    />


                                </Grid>
                            }
                            {props.fieldData && props.fieldData['copa_level'] && (
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['copa_level']['current']} data={props.fieldData['copa_level']['drop_down_reference_value']}
                                        twoline='true' onChange={handleCopaLevel} labelFormat={'desc'} required
                                        prevalue={props.contractDefaultData && props.contractDefaultData.copa_level ? props.contractDefaultData.copa_level : ''}
                                    />

                                </Grid>)
                            }
                            {props.fieldData && props.fieldData['accrual_frequency'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownKeyValue heading={props.fieldData['accrual_frequency']['current']} data={props.fieldData['accrual_frequency']['drop_down_reference_value']}
                                        twoline='true' onChange={handleAccrualFrequency} labelFormat={'desc'} required
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_frequency ? props.contractDefaultData.accrual_frequency : ''}
                                    />

                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_calendar'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['accrual_calendar']['current']} placeholder={' '}
                                        data={props.fieldData['accrual_calendar']['drop_down_values'] ? props.fieldData['accrual_calendar']['drop_down_values'] : []}
                                        twoline='true' onChange={handleAccrualCalendar}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_calendar ? props.contractDefaultData.accrual_calendar : ''} />


                                </Grid>
                            }
                            {props.fieldData && props.fieldData['internal_reference'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={props.fieldData['internal_reference']['current']} twoline='true' onChange={handleInternalReference} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['accrual_gl_account_credit'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={props.fieldData['accrual_gl_account_credit']['current']} twoline='true' onChange={handleAccrualGLCredit} error={typeof accrualGLCredit === 'boolean' && !accrualGLCredit ? true : false}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_gl_account_credit ? props.contractDefaultData.accrual_gl_account_credit : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_gl_account_debit'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={props.fieldData['accrual_gl_account_debit']['current']} twoline='true'
                                        onChange={handleAccrualGLDebit} error={typeof accrualGLDebit === 'boolean' && !accrualGLDebit ? true : false} ref={accrualDebitRef}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_gl_account_debit ? props.contractDefaultData.accrual_gl_account_debit : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_credit_profit_center'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={props.fieldData['accrual_credit_profit_center']['current']} twoline='true' onChange={handleAccrualCreditProfitCenter}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_credit_profit_center ? props.contractDefaultData.accrual_credit_profit_center : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_debit_profit_center'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel heading={props.fieldData['accrual_debit_profit_center']['current']} twoline='true' onChange={handleAccrualDebitProfitCenter}
                                        prevalue={props.contractDefaultData && props.contractDefaultData.accrual_debit_profit_center ? props.contractDefaultData.accrual_debit_profit_center : ''}
                                    />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['accrual_run'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['accrual_run']['drop_down_reference_value'] && props.fieldData['accrual_run']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['accrual_run']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={accrualRun}
                                                onChange={(e) => setAccrualRun(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['accrual_run']['drop_down_reference_value'] && props.fieldData['accrual_run']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel heading={props.fieldData['accrual_run']['current']} twoline='true' onChange={handleAccrualRun} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_4'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_4']['drop_down_reference_value'] && props.fieldData['flex_fields_4']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_4']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexFields4}
                                                onChange={(e) => setFlexFields4(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_4']['drop_down_reference_value'] && props.fieldData['flex_fields_4']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel heading={props.fieldData['flex_fields_4']['current']} twoline='true' onChange={handleFlexFields4} />
                                    }
                                </Grid>
                            }
                            {props.postingSchema == 'Payment then Amortization' &&
                                <>
                                    {props.fieldData && props.fieldData['short_term_period'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel >
                                                {props.fieldData['short_term_period']['current']}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={shortTermPeriod}
                                                type={'number'}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setShortTermPeriod(e.target.value)} />
                                        </Grid>}
                                    {props.fieldData && props.fieldData['long_term_period'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <FormLabel >
                                                {props.fieldData['long_term_period']['current']}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={longTermPeriod}
                                                type={'number'}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => setLongTermPeriod(e.target.value)} />
                                        </Grid>}
                                    {props.fieldData && props.fieldData['short_term_account'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel heading={props.fieldData['short_term_account']['current']} twoline='true' onChange={(value) => setShortTermAccount(value)} />
                                        </Grid>}
                                    {props.fieldData && props.fieldData['long_term_account'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <TextInputWithLabel heading={props.fieldData['long_term_account'][['current']]} twoline='true' onChange={(value) => setLongTermAccount(value)} />
                                        </Grid>}
                                    {props.fieldData && props.fieldData['amortization_value'] &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            <div style={{
                                                padding: '0px 0px 5px 0px',
                                                display: 'flex',
                                                width: '100%',
                                                flexDirection: 'column'
                                            }}>
                                                <FormLabel >
                                                    {props.fieldData['amortization_value']['current']}
                                                </FormLabel>
                                                <Select
                                                    disableUnderline
                                                    value={amortizationValue}
                                                    onChange={(e) => setAmortizationValue(e.target.value)}
                                                    displayEmpty
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    style={{ marginTop: 5 }}
                                                >
                                                    <MenuItem value='' style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {props.fieldData['amortization_value']['drop_down_reference_value'] && props.fieldData['amortization_value']['drop_down_reference_value']
                                                        .map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                    {item.key}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    }
                                </>

                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

// const mapStateToProps = state => ({
//     //data: state.addContractData.contractGeneralData,
//     //dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
//    // labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
//    // tableData: state.addMultipleConfigurationData.multipleConfigurationData,
// });

// const mapDispatchToProps = dispatch => {
//     return {
//         getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
//         onLoadingDefault: () => getDefaultValues(localStorage.getItem('application'), null, null, null, 'Contract Designer')
//     }
// }

export default connect(null, null, null, { forwardRef: true })(Accrual);
