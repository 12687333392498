import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Button, Fab, Dialog, OutlinedInput, DialogActions, FormLabel
} from '@mui/material';
import { Chat } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { getAllCommunityChatData, createQuestionChannel } from '../../../redux/actions';
import { history } from '../../../components/Helpers';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    answer: {
        margin: '10px 11px 20px',
        wordWrap: 'break-word',
    },
    bodyContainer: {
        // backgroundColor: theme.palette.secondary.main,
        // border: '2px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 31
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    paper: { minWidth: window.screen.width * .50 },
    bgColor: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        width: '11rem',
        height: '0rem',
        textAlign: "center",
        borderRadius: 10,
        padding: 15,
        marginTop: '0.2rem',
        display: 'flex'
        // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
    }
}));
const CommunityChatQA = props => {
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [queryAnswer, setQueryAnswer] = React.useState('');
    const [data, setData] = React.useState([]);
    const [questionID, setQuestionID] = useState(0);
    useEffect(() => {
        props.onLoadingDefault(localStorage.getItem('community-id'))
    }, [])
    useEffect(() => {
        if (props.queryData && props.queryData.records && props.queryData.records.length > 0) {
            setData(props.queryData.records)
        }
    }, [props.queryData]);
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const handleQueryAnswer = (e) => {
        setQueryAnswer(e.target.value)
    }
    function handleCancelDialog() {
        history.push('/community-chat');
        setOpen(false)
        setOpen2(false)
    }
    function handleCancelDialog2() {
        history.push('/community-chat');
        setOpen2(false)
    }
    const handleInitialSetup = () => {
        var data = {
            'question': queryName
        }
        props.createQuestionChannel(localStorage.getItem('community-id'), data, 'question')
        setOpen(false);
        setQueryName('');
        props.onLoadingDefault(localStorage.getItem('community-id'));
        props.onLoadingDefault(localStorage.getItem('community-id'));
    }
    const handleComment = (item) => {
        setQuestionID(item.ID)
        setOpen2(true)
    }
    const handleQuestionAnswered = () => {
        var data = {
            "question_id": questionID,
            "answer": queryAnswer
        }
        props.createQuestionChannel(localStorage.getItem('community-id'), data, 'answer')
        setOpen2(false);
        setQueryAnswer('');
        props.onLoadingDefault(localStorage.getItem('community-id'));
        props.onLoadingDefault(localStorage.getItem('community-id'));
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" style={{ marginTop: 10, color: 'black' }} > {localStorage.getItem('community-name')} </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={() => {
                                setOpen(true)
                            }}

                        >
                            <Chat color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Ask Querstion</Typography>
                        </Fab>
                    </div>

                </div>
                <div className={classes.bodyContainer}>

                    <div className={classes.answer}>
                        <div style={{ marginBottom: '12px' }}>
                            {
                                data
                                    .filter(item => item.question !== '')
                                    .map(item => {
                                        return <>
                                            <Typography variant="h3" color='primary' style={{ marginTop: '1.5rem' }}>Q: {item.question.includes('?') ? item.question : item.question + ' ?'} </Typography>
                                            <FormLabel style={{ fontSize: '0.8rem' }} >Asked by: {item.question_created_by_name} at {Moment(item.question_created_at).format('MM-DD-YYYY: HH:mm:ss')}</FormLabel>
                                            <div><FormLabel variant="h3" style={{ marginTop: '0.5rem' }} >A: {item.answer}</FormLabel></div>

                                            <div>
                                                <FormLabel style={{ fontSize: '0.8rem' }} >Answerd by: {item.answer_created_by_name} at {Moment(item.answer_created_at).format('MM-DD-YYYY: HH:mm:ss')}</FormLabel>
                                                <Button
                                                    onClick={() => { handleComment(item) }}
                                                    className={classes.bgColor}
                                                >
                                                    Provide Answer
                                                </Button>
                                            </div>

                                        </>
                                    })
                            }


                        </div>




                    </div>

                </div>

            </LoadingOverlay>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel}>
                        Enter A Question
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel}>
                        Enter Comment
                    </FormLabel>
                    <OutlinedInput
                        value={queryAnswer}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryAnswer} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog2}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleQuestionAnswered} disabled={queryAnswer ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: (id) => dispatch(getAllCommunityChatData(id)),
        createQuestionChannel: (id, data, type) => dispatch(createQuestionChannel(id, data, type))

    }
}
const mapStateToProps = state => {
    return {
        queryData: state.ChatGptData.allCommunityChat,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityChatQA);