import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, TextInputWithLabel, DropdownArray, DropdownKeyValue } from '../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    selectRoot: {
        padding: '18px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2.5rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const Accrual = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();

    //new values
    const internalReferenceRef = useRef(null);
    const [internalReference, setInternalReference] = React.useState('');
    const postingsBlockRef = useRef(null);
    const postingApprovalRef = useRef(null);
    const [postingsBlock, setPostingsBlock] = React.useState('');
    const [postingApproval, setPostingApproval] = React.useState('');
    const [postingsBlockArray, setPostingsBlockArray] = React.useState([]);
    const financialPostingsStartDateRef = useRef(null);
    const [financialPostingsStartDate, setFinancialPostingsStartDate] = React.useState('');
    const financialPostingsEndDateRef = useRef(null);
    const [financialPostingsEndDate, setFinancialPostingsEndDate] = React.useState('');
    const [suppressNegativeRebate, setSuppressNegativeRebate] = React.useState(false);

    const classes = useStyles();
    const { className } = props;
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState('');
    const [accrualGLCredit, setAccrualGLCredit] = React.useState('');
    const [accrualGLDebit, setAccrualGLDebit] = React.useState('');
    const [autoRunPpa, setAutoRunPpa] = React.useState('');
    var accrualValues = {
        postings_block: postingsBlock,
        posting_approval: postingApproval,
        postings_start_date: financialPostingsStartDate,
        postings_end_date: financialPostingsEndDate,
        auto_run_ppa: autoRunPpa,
        suppress_negative_rebate: suppressNegativeRebate
    };
    var mode = localStorage.getItem('mode');
    const accrualFrequencylRef = useRef(null);
    const accrualLevelRef = useRef(null);
    const copaRef = useRef(null);
    const accrualCalendarRef = useRef(null);
    const accrualCreditRef = useRef(null);
    const accrualDebitRef = useRef(null);

    useImperativeHandle(ref, () => ({
        validationCheck() {
            // if (mode === 'edit')
            //     props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!accrualFrequencylRef.current.value || accrualFrequencylRef.current.value === '')
                setAccrualFrequency(false);
            if (!accrualLevelRef.current.value || accrualLevelRef.current.value === '')
                setAccrualLevel(false);
            if (!accrualCalendarRef.current.value || accrualCalendarRef.current.value === '')
                setAccrualCalendar(false);
            if (!copaRef.current.value || copaRef.current.value === '')
                setCopaLevel(false);
            if (!accrualCreditRef.current.value || accrualCreditRef.current.value === '')
                setAccrualGLCredit(false);
            if (!accrualDebitRef.current.value || accrualDebitRef.current.value === '')
                setAccrualGLDebit(false);
            //new values
            if (!internalReferenceRef.current.value || internalReferenceRef.current.value === '')
                setInternalReference(false);
            if (!postingsBlockRef.current.value || postingsBlockRef.current.value === '')
                setPostingsBlock(false);
            if (!postingApprovalRef.current.calue || postingApprovalRef.current.value === '')
                setPostingApproval(false)
            if (!financialPostingsStartDateRef.current.value || financialPostingsStartDateRef.current.value === '')
                setFinancialPostingsStartDate(false);
            if (!financialPostingsEndDateRef.current.value || financialPostingsEndDateRef.current.value === '')
                setFinancialPostingsEndDate(false);
        },
        getValues() {
            accrualValues = {
                postings_block: postingsBlockRef.current.value,
                posting_approval: postingApprovalRef.current.value,
                postings_start_date: financialPostingsStartDateRef.current.value,
                postings_end_date: financialPostingsEndDateRef.current.value,
            };
            return accrualValues;
        }
    }));


    function handlePostingsBlock(newValue) {
        setPostingsBlock(newValue);
        props.onChange('postings_block', newValue);
    }
    function handlePostingApproval(newValue) {
        setPostingApproval(newValue);
        props.onChange('posting_approval', newValue);
    }
    function handleFinancialPostingsStartDate(newValue) {
        setFinancialPostingsStartDate(newValue);
        props.onChange('postings_start_date', newValue);
    }
    function handleFinancialPostingsEndDate(newValue) {
        setFinancialPostingsEndDate(newValue);
        props.onChange('postings_end_date', newValue);
    }
    function handleAutoRunPpa(newValue) {
        setAutoRunPpa(newValue.target.value);
        props.onChange('auto_run_ppa', newValue.target.value);
    }
    function handleSuppressNegRebate(e) {
        setSuppressNegativeRebate(e.target.value);
        props.onChange('suppress_negative_rebate', e.target.value);
    }
    useEffect(() => {
        if (props.data && props.data.auto_run_ppa)
            setAutoRunPpa(props.data.auto_run_ppa)
        if (props.data && props.data.suppress_negative_rebate)
            setSuppressNegativeRebate(props.data.suppress_negative_rebate)
    }, [props.data])

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                    style={{ marginTop: '-2rem' }}
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1rem' }}>
                        <Grid container >
                            {props.fieldData && props.fieldData['auto_run_ppa'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-0.4rem' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        {props.fieldData['auto_run_ppa']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={autoRunPpa}
                                        onChange={handleAutoRunPpa}
                                        displayEmpty
                                        style={{ marginTop: 6 }}
                                    >
                                        <MenuItem value="" className={classes.menuItem}></MenuItem>
                                        {props.fieldData['auto_run_ppa'] && props.fieldData['auto_run_ppa']['drop_down_values']
                                            && props.fieldData['auto_run_ppa']['drop_down_values']
                                                .map(item => (
                                                    <MenuItem value={item} className={classes.menuItem}>{item}</MenuItem>
                                                ))
                                        }
                                    </Select>
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['postings_start_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['postings_start_date']['current']}
                                        twoline='true' onChange={handleFinancialPostingsStartDate} ref={financialPostingsStartDateRef}
                                        prevalue={props.data && props.data.postings_start_date != '0001-01-01T00:00:00Z' ? props.data.postings_start_date : ''} required={false} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['postings_end_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} minimum={financialPostingsStartDate}
                                        heading={props.fieldData['postings_end_date']['current']}
                                        twoline='true' onChange={handleFinancialPostingsEndDate}
                                        ref={financialPostingsEndDateRef} prevalue={props.data && props.data.postings_end_date != '0001-01-01T00:00:00Z' ? props.data.postings_end_date : ''} required={false} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['postings_block'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ marginTop: -5 }}>
                                        <DropdownKeyValue classes={{ root: classes.fontSetting }} required={false}
                                            heading={props.fieldData['postings_block']['current']}
                                            data={props.fieldData['postings_block']['drop_down_reference_value']} labelFormat={'desc'}
                                            placeholder={' '} twoline='true' onChange={handlePostingsBlock} ref={postingsBlockRef}
                                            prevalue={props.data ? props.data.postings_block : ''} />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['posting_approval'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ marginTop: -5 }}>
                                        <DropdownKeyValue classes={{ root: classes.fontSetting }} required={false}
                                            heading={props.fieldData['posting_approval']['current']}
                                            data={props.fieldData['posting_approval']['drop_down_reference_value']} labelFormat={'desc'}
                                            placeholder={' '} twoline='true' onChange={handlePostingApproval} ref={postingApprovalRef}
                                            prevalue={props.data ? props.data.posting_approval : ''} />
                                    </div>
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['suppress_negative_rebate'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-0.8rem' }}
                                >
                                    <FormLabel>{props.fieldData['suppress_negative_rebate']['current']}
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={suppressNegativeRebate}
                                        onChange={handleSuppressNegRebate}
                                        displayEmpty
                                        style={{ marginTop: 6 }}
                                    >
                                        <MenuItem value={false} className={classes.menuItem}>No</MenuItem>
                                        <MenuItem value={true} className={classes.menuItem}>Yes</MenuItem>
                                    </Select>
                                </Grid>
                            }

                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});


export default connect(null, null, null, { forwardRef: true })(Accrual);
