import React from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createPromotionMapping } from '../../../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";

import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    }
}));

const AddPromotion = props => {

    const classes = useStyles();
    const [imaFieldName, setImaFieldName] = React.useState('');
    const [imaFieldValue, setImaFieldValue] = React.useState('');
    const [targetFieldName, setTargetFieldName] = React.useState('');
    const [targetFieldValue, setTargetFieldValue] = React.useState('');

    function handleSubmit() {
        var data = [{
            "ima_field_name": imaFieldName,
            "ima_field_value": imaFieldValue,
            "target_field_name": targetFieldName,
            "target_field_value": targetFieldValue,
        }];
        props.onSubmit(data);
    }

    function handleClearAll() {
        setImaFieldName('')
        setImaFieldValue('')
        setTargetFieldName('')
        setTargetFieldValue('')
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/promo-mapping'
                >
                    Promotion Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Promotion</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>

                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Field Name
                                        </FormLabel>
                                        <OutlinedInput
                                            onChange={(e) => setImaFieldName(e.target.value)}
                                            value={imaFieldName}
                                            classes={{ root: classes.inputTwoLine }}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            IMA Field Value
                                        </FormLabel>
                                        <OutlinedInput
                                            value={imaFieldValue}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setImaFieldValue(e.target.value)} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Target Field Name
                                        </FormLabel>
                                        <OutlinedInput
                                            onChange={(e) => setTargetFieldName(e.target.value)}
                                            value={targetFieldName}
                                            classes={{ root: classes.inputTwoLine }}
                                        />
                                    </div>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel} required>
                                            Target Field Value
                                        </FormLabel>
                                        <OutlinedInput
                                            value={targetFieldValue}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={(e) => setTargetFieldValue(e.target.value)} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                        disabled={imaFieldName && imaFieldValue && targetFieldName && targetFieldValue ? false : true}
                    >
                        {props.loading ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                            :
                            'Save'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPromotionMapping(data))
    }
}

const mapStateToProps = state => {
    return {
        loading: state.promotionData.loading,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromotion);