import React, { useEffect } from 'react';
import {
    Typography, Card, Table, TableCell,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    OutlinedInput, Grid, Button, Select, MenuItem, FormLabel, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { DeleteForever, AddBoxOutlined, Close } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getLblDispDesFieldValue } from '../../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // borderRadius: 10,
        // padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: '0px'
    },
    gridContainer: {
        padding: '15px 16px 0px 16px',
        justifyContent: 'flex-start',
        marginLeft: 20
        // display: 'flex'
    },
    subContainer: {
        padding: '10px 16px 0px 16px',
        marginTop: 2
    },
    iconContainer: {
        // marginTop: 35
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    IconAddButton: {
        marginTop: 4,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    stickerLabel: {
        textTransform: 'capitalize',
        fontSize: theme.typography.h4.fontSize,
        // height: 41,
        marginTop: 9
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize,
        cursor: 'pointer'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.text.grey
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },
    inputTwoLine: {
        width: '91%'
    },
    togglebBtn: {
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        '&.MuiToggleButton-root': {
            color: 'white !important',
        }

    }
}));

const StickerContainer = (props) => {

    const classes = useStyles();
    const { className } = props;
    const [promoFields, setPromoFields] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([{ promotion_type: '' }]);
    const [promoTypes, setPromoTypes] = React.useState({});
    const [promoTypeData, setPromoTypeData] = React.useState({});
    const [stickerDesc, setStickerDesc] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);


    useEffect(() => {
        props.getPromotionTypes();
        var data = {
            "data_source": "Promotion Scenarios",
        }
        props.fieldDesigner(data, 'post');

    }, []);
    useEffect(() => {
        if (props.promotionTypes && Object.keys(props.promotionTypes).length > 0) {
            setPromoTypeData(props.promotionTypes)
            setPromoTypes(Object.keys(props.promotionTypes))
        }

    }, [props.promotionTypes]);
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setPromoFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])

    useEffect(() => {
        props.onChange(stickerData)
    }, [stickerData])

    useEffect(() => {
        if (props.data && props.data.sticker_type) {
            setStickerData(props.data.sticker_type)
            if (props.data.sticker_description) {
                const elementsArray = props.data.sticker_description.split(/\s*(\+|OR)\s*/).map(item => {
                    return item.replace(/[\(\)]/g, '').trim();
                });
                setStickerDesc(elementsArray)
            }

        }
    }, [props.data])

    useEffect(() => {
        if (stickerDesc && stickerDesc.length > 0) {
            const inputString = stickerDesc.join("")
            const splitArray = inputString.split("OR");
            const resultArray = splitArray
                .filter(item => item !== null)
                .map((item) => {
                    const trimmedItem = item.trim();
                    return trimmedItem.length > 0 ? `(${trimmedItem})` : null;
                });
            const filteredResultArray = resultArray.filter(item => item !== null);
            const finalString = filteredResultArray.join("OR");
            if (stickerDesc && stickerDesc[stickerDesc.length - 1] == 'OR')
                finalString.concat("OR")

            props.getSticker(finalString)
        }
    }, [stickerDesc])
    function handleChange(event, index, item) {
        let sticker = [...stickerDesc];
        if (stickerData && stickerData[index]) {
            if (item) {
                stickerData[index][item] = promoFields && promoFields[item] && promoFields[item]['data_type'] == 'numeric' ? parseFloat(event.target.value) : event.target.value;
                if (item.includes('benefit')) {
                    var arrIndx = index + index
                    if (item == 'benefit_coverage_length' || item == ' benefit_promo_finance_duration') {
                        // sticker = stickerDesc.concat(event.target.value + 'M')
                        if (sticker[arrIndx])
                            sticker[arrIndx] = event.target.value ? event.target.value + 'M' : '0M'
                        else
                            sticker.push(event.target.value ? event.target.value + 'M' : '0M')
                    }
                    else {
                        if (item != 'benefit_promo_finance_interest') {
                            //sticker = stickerDesc.concat('$' + event.target.value)
                            if (sticker[arrIndx])
                                sticker[arrIndx] = event.target.value ? '$' + event.target.value : '$0'
                            else
                                sticker.push(event.target.value ? '$' + event.target.value : '$0')
                        }
                    }
                    setStickerDesc(sticker)
                }
            }
            else {
                stickerData[index]['promotion_type'] = event.target.value;
            }
            setStickerData([...stickerData])
        }
        else
            setStickerData([...stickerData, { 'promotion_type': event.target.value }])
    }

    function handleOnAdd() {
        setStickerData([...stickerData, { promotion_type: '' }]);
    }
    const handleOperation = (value, index) => {
        stickerData[index]['operation'] = value
        var arrIndx = index + index + 1
        if (stickerDesc[arrIndx] !== value) {
            stickerDesc[arrIndx] = value
            setStickerDesc([...stickerDesc])
        }
        else
            setStickerDesc([...stickerDesc, value])

        setStickerData([...stickerData])
    };

    function handleDelete(index) {
        var newData = stickerData.filter((item, i) => i != index)
        setStickerData(newData)
    }
    return (
        <div className={classes.root}>
            <Grid item
                container spacing={1}

            >
                {stickerData && stickerData.map((item, index) => (
                    <Grid
                        item
                        md={2.7}
                        xs={12}
                        spacing={1}
                        container
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            container
                            md={10}
                            xs={12}
                            spacing={1}
                            style={{ border: '1px solid #eeeeee', marginTop: 0, backgroundColor: '#e8e8e8' }}
                        >

                            <Grid
                                item
                                md={4.8}
                                xs={12}
                                className={classes.subContainer}
                            >
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <FormLabel className={classes.formLabel} >OPTION {index + 1}</FormLabel>
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={6.5}
                                xs={12}
                            ><Select
                                labelId="demo-customized-select-label"
                                id={'promotion_type' + index}
                                value={stickerData[index] ? stickerData[index]['promotion_type'] : ''}
                                onChange={(e) => handleChange(e, index)}
                                style={{ marginBottom: 10, backgroundColor: 'white' }}
                                renderValue={(value) => promoFields && promoFields[value] ?
                                    promoFields[value]['current'] : value.replace(/_/g, ' ')}
                            // input={<OutlinedInput />}
                            >
                                    <MenuItem value="">
                                    </MenuItem>
                                    {promoTypes && promoTypes.length > 0 &&
                                        promoTypes.map(item =>
                                            <MenuItem value={item} style={{ textTransform: 'capitalize' }}>
                                                {promoFields && promoFields[item] ? promoFields[item]['current'] : item.replace(/_/g, ' ')}
                                            </MenuItem>
                                        )}
                                </Select>

                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            className={classes.iconContainer}
                        >
                            {(stickerData.length > index + 1) ?
                                <ToggleButtonGroup
                                    color="primary"
                                    value={stickerData[index] ? stickerData[index]['operation'] : ''}
                                    exclusive
                                    onChange={(e, value) => handleOperation(value, index)}
                                    aria-label="Platform"
                                    id={'operation' + index}
                                    size='small'
                                    style={{ margin: '0px 10px 0px 20px' }}
                                >
                                    <ToggleButton value="+"
                                        style={{ color: stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == '+' ? 'white !important' : 'initial' }}
                                        className={stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == '+' && classes.togglebBtn}>
                                        + </ToggleButton>
                                    <ToggleButton value="OR" className={stickerData[index] && stickerData[index]['operation'] && stickerData[index]['operation'] == 'OR' && classes.togglebBtn}>
                                        OR </ToggleButton>
                                </ToggleButtonGroup>
                                : <div style={{ display: "flex" }}>
                                    <IconButton
                                        classes={{ root: classes.IconAddButton }}
                                        onClick={handleOnAdd}
                                        size="large">
                                        <AddBoxOutlined />
                                    </IconButton>
                                    {index != 0 &&
                                        <IconButton
                                            classes={{ root: classes.IconButton }}
                                            onClick={() => handleDelete(index)}
                                            size="large">
                                            <Close style={{ fontSize: 20 }} />
                                        </IconButton>
                                    }
                                </div>}
                        </Grid>
                        <div style={{ height: 450, marginLeft: -6, marginBottom: 15, width: '85%', backgroundColor: stickerData[index] && stickerData[index]['promotion_type'] ? 'white' : '#f5f5f5', paddingLeft: 15 }}>
                            {stickerData[index] && stickerData[index]['promotion_type'] &&
                                promoTypeData && promoTypeData[stickerData[index]['promotion_type']] &&
                                promoTypeData[stickerData[index]['promotion_type']]
                                    .filter(itemx => promoFields ? (promoFields[itemx] && itemx) : itemx)
                                    .map(itemx => (
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            container
                                            style={{ alignContent: 'flex-start' }}
                                            className={classes.subContfainer}
                                        >
                                            <FormLabel className={classes.stickerLabel}>{promoFields && promoFields[itemx] ? promoFields[itemx]['current'] : itemx.replace(/_/g, ' ')}</FormLabel>

                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <OutlinedInput
                                                    value={stickerData[index][itemx]}
                                                    type={promoFields && promoFields[itemx] && promoFields[itemx]['data_type'] == 'numeric' ? 'number' : 'string'}
                                                    //inputProps={{ min: "0", step: "1" }}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    onChange={(e) => handleChange(e, index, itemx)} />


                                            </Grid>
                                        </Grid>
                                    ))
                            }
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type) => dispatch(createGlobalSetup(data, null, type)),
        getPromotionTypes: () => dispatch(getPromoTypes()),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerContainer);