import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { fpHistory, runContractTrends } from '../../redux/actions';
import { connect } from 'react-redux';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Typography,
    Breadcrumbs,
    //Link
} from '@mui/material';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { history } from '../../components/Helpers';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        // marginTop: 5,
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    spinner: {
        height: '100vh'
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const FinancialPostingsHistory = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [currentID, setCurrentID] = React.useState('');
    const { className } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [contractDataArray, setContractDataArray] = React.useState([]);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [historyData, setHistoryData] = React.useState([])

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        setCurrentID(appId)
        if (appId)
            // props.fpHistory(appId, 1, 10);
            localStorage.removeItem('contractTrendsQuery')

    }, []);


    useEffect(() => {
        if (props.contractCompleteData) {
            setContractDataArray(props.contractCompleteData);
        }
    }, [props.contractCompleteData]);
    useEffect(() => {
        if (props.fpHistoryData && props.fpHistoryData.records) {
            setHistoryData(props.fpHistoryData.records)
        }
    }, [props.fpHistoryData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.fpHistory(currentID, newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.fpHistory(currentID, 1, event.target.value);
    };
    const handleCalculationAnalysis = (item) => {
        var formData = {
            "start_date": Moment.utc(item.start_date).format('MM/DD/YYYY'),
            "end_date": Moment.utc(item.end_date).format('MM/DD/YYYY'),
            "application_type": sessionStorage.getItem('application'),
            "data_source_type": 'Direct - Sales Data',
            "contract_number": item.contract_number,
            "posting_number": item.posting_number,
            "posting_type": item.posting_type === 'Delta Payment' ? 'payment' :
                item.posting_type === 'Delta Accrual' ? 'accrual' :
                    item.posting_type === 'Accrual Reversal' ? 'accrual' :
                        item.posting_type === 'Payment Reversal' ? 'payment' :
                            item.posting_type
        };
        localStorage.setItem('contractTrendsQuery', JSON.stringify(formData));
        props.runContractTrends(formData);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div>
                <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '1rem', marginLeft: 20 }}>
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to={'/contract-setup'}
                    >
                        Contract Setup
                    </Link>
                    <Typography color="textPrimary" variant='h4'>&ensp;Financial Postings History</Typography>
                </Breadcrumbs>

                <div className={clsx(classes.root, className)}>
                    {historyData && historyData.length > 0 ?
                        <TableContainer className={classes.tableContaier}>
                            <Table className={classes.table}>
                                <TableHead >
                                    <TableRow >
                                        <TableCell>Contract ID</TableCell>
                                        <TableCell>Posting Type</TableCell>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell>
                                        <TableCell>Posting Number</TableCell>
                                        <TableCell>Posting Date</TableCell>
                                        <TableCell>Incentive Amount</TableCell>
                                        <TableCell>Approval Status</TableCell>
                                        {Array.apply(null, {
                                            length: historyData.filter(item => (item.posting_type == 'Delta Accrual' || item.posting_type == 'Delta Payment')).length > 0 ? 1 : 0
                                        }).map((e, i) => (
                                            <TableCell align='center' >Delta Reason</TableCell>

                                        ))}
                                        <TableCell>Created Date</TableCell>
                                        <TableCell>Created Time</TableCell>
                                        <TableCell>Created By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }} >
                                    {historyData && historyData.map((item) => {
                                        return (
                                            <StyledTableRow key={item.id}>
                                                <TableCell>{item.contract_number}</TableCell>
                                                <TableCell>{item.posting_type}</TableCell>
                                                <TableCell>{item.start_date && Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell>{item.end_date && Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell onClick={() => handleCalculationAnalysis(item)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }} >{item.posting_number}</TableCell>
                                                <TableCell>{item.posting_date && Moment.utc(item.posting_date).format('MM/DD/YYYY')}</TableCell>
                                                <TableCell>{item.incentive_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                <TableCell>{item.approval_status === 'acc' ? 'Accepted' : item.approval_status === 'rej' ? 'Rejected' : item.approval_status === 'submit_approval' ? 'Submit For Approval' : item.approval_status}</TableCell>
                                                {Array.apply(null, {
                                                    length: historyData.filter(item => (item.posting_type == 'Delta Accrual' || item.posting_type == 'Delta Payment')).length > 0 ? 1 : 0
                                                }).map((e, i) => (
                                                    <TableCell align='center' >{item.delta_reason}</TableCell>

                                                ))}
                                                <TableCell>{item.created_date}</TableCell>
                                                <TableCell>{item.created_time ? Moment.utc(item.created_time, 'h:mm a').local().format('h:mm a') : ''}</TableCell>
                                                <TableCell>{item.created_by && item.created_by ? item.created_by : ''}</TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={props.fpHistoryData && props.fpHistoryData.total_record ? props.fpHistoryData.total_record : 0}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                classes={{
                                    caption: classes.caption,
                                    root: classes.paginationRoot
                                }}
                            />
                        </TableContainer>
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </div >

            </div >
        </LoadingOverlay>
    );

};

const mapStateToProps = state => {
    return {
        fpHistoryData: state.addContractData.fpHistoryData,
        loading: state.simulationData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fpHistory: (id, page, limit) => dispatch(fpHistory(id, page, limit)),
        runContractTrends: (formData) => dispatch(runContractTrends(formData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingsHistory);