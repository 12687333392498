import {
  ANALYTICS_FILTER_SUCCESS,
  ANALYSIS_DESI_ALL,
  ANALYSIS_DESI_SINGLE,
  FETCH_START,
  FETCH_END,
  ANALYSIS_DESI_LIST_SUCCESS,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const addAnalysisDesigner = (data, edit, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addAnalysisDesignerAPI(data, edit, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        if (type === "dashboard") {
          history.push("/dashboard-designer");
        }
        else {
          history.push("/analytics-designer");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addAnalysisDesignerAPI = (data, edit, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  for (var propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  if (edit === "edit")
    result = axios.put("dashboard/config?functionality=" + type, + id, data, { headers: headers });
  else result = axios.post("dashboard/config?functionality=" + type, data, { headers: headers });
  return result;
};

export const getAnalysisDesignerData = (pagination, limit, type) => {
  return (dispatch) => {
    getAnalysisDesignerAPI(pagination, limit, type)
      .then((response) => {
        dispatch(getAnalysisDesignerSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAnalysisDesignerAPI = (pagination, limit, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (pagination === "nofilter") {
    result = axios.get(
      "dashboard/config?application_type=" +
      sessionStorage.getItem("application"),
      { headers: headers }
    );
  } else {
    result = axios.get(
      "dashboard/config?functionality=" + type + "&page=" + pagination + "&&limit=" + limit,
      { headers: headers }
    );
  }
  return result;
};

export const getAnalysisDesignerSuccess = (data) => {
  return {
    type: ANALYSIS_DESI_ALL,
    data,
  };
};

export const getSingleAnalysisDesigner = (id) => {
  return (dispatch) => {
    getSingleAnalysisDesignerAPI(id)
      .then((response) => {
        dispatch(getAnalysisDesignerSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};

const getSingleAnalysisDesignerAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("dashboard/config/" + id, { headers: headers });
  return result;
};

export const getAnalysisDesignerSuccessSingle = (data) => {
  return {
    type: ANALYSIS_DESI_SINGLE,
    data,
  };
};

export const deleteAnalysisDesigner = (id, type) => {
  return (dispatch) => {
    deleteAnalysisDesignerApi(id)
      .then((response) => {
        dispatch(getAnalysisDesignerData(1, 10, type));
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};

const deleteAnalysisDesignerApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("dashboard/config/" + id, { headers: headers });
  return result;
};
export const getAnalysisDesignerList = () => {
  return (dispatch) => {
    getAnalysisDesignerListAPI()
      .then((response) => {
        dispatch(getAnalysisDesignerListSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAnalysisDesignerListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("dashboard/config-fields", { headers: headers });
  return result;
};
export const getAnalysisDesignerListSuccess = (data) => {
  return {
    type: ANALYSIS_DESI_LIST_SUCCESS,
    data,
  };
};
export const getAnalyticsFilters = (applicationType, Functionality, apps) => {
  return (dispatch) => {
    getAnalyticsFiltersAPI(applicationType, Functionality, apps)
      .then((response) => {
        dispatch(getAnalyticsFiltersSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAnalyticsFiltersAPI = (applicationType, Functionality, apps) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (Functionality === "dashboard") {
    result = axios.get(
      "dashboard/config-fields?application_type=" +
      applicationType +
      "&functionality=" +
      Functionality +
      "&security__role=" +
      apps,
      { headers: headers }
    );

  }
  else {
    result = axios.get(
      "dashboard/config-fields?application_type=" +
      applicationType +
      "&security__role=" +
      apps,
      { headers: headers }
    );

  }

  return result;
};

export const getAnalyticsFiltersSuccess = (data) => {
  return {
    type: ANALYTICS_FILTER_SUCCESS,
    data,
  };
};
