import React, { useEffect } from 'react';
import {
    Grid,Button,Typography,Breadcrumbs,Link,
    Card,Table,TableCell,TableHead,TableRow,
    TableContainer,TableBody,TextField,OutlinedInput,
    FormLabel,Dialog,DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getMembershipFormatFields,
    updateMembershipFormatData,
    getMembershipFormatDataById,getLblDispDesFieldValue
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
             paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const MembershipEditQuery = props => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [currentId,setCurrentId] = React.useState(0);
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(localStorage.getItem('membership-query') ? JSON.parse(localStorage.getItem('membership-query')) : []);
    const [membershipOwnerId, setMembershipOwnerId] = React.useState('');
    const [memberName, setMemberName] = React.useState('');
    const [memberNumber, setMemberNumber] = React.useState('');
    const [classOfTrade, setClassOfTrade] = React.useState(false);
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [membershipStartDate, setMembershipStartDate] = React.useState(null);
    const [memberStatus, setMemberStatus] = React.useState('');
    const [gpoName,setGPOName] = React.useState('');
    const [gpoMemberNumber,setGPOMemberNumber] = React.useState('');
    const [memberStartDate,setMemberStartDate] = React.useState('');
    const [contractStartDate,setContractStartDate] = React.useState('');
    const [tierActivationDate,setTierActivationDate] = React.useState('');
    const [contractEndDate,setContractEndDate] = React.useState('');
    const [contractNumber,setContractNumber] = React.useState('');
    const [requiredFields, setRequiredFields] = React.useState([
        'customer_number','GPO_name', 'GPO_member_number'
    ]);
    const requiredFields2 = ['GPO_name','member_start_date','GPO_member_number']
    const requiredFields3 = ['GPO_name','GPO_member_number','contract_number','tier_activation_date']
    const requiredFields4 = ['contract_number','contract_start_date','contract_end_date']
    const type = history.location.state;
    const [queryObject, setQueryObject] = React.useState({});
    const [defaultValue,setDefaultValue] = React.useState({});
    const [allowedField, setAllowedField] = React.useState(false);
    const [sortedData,setSortedData] = React.useState([]);
    useEffect(() => {
        setOpen(true);
        props.getMembershipFormatFields(type);
        props.getMembershipFormatDataById(purchaseQueryDataStorage.id,type)
        if(type==='MembershipGPORosterFormats') {
            setRequiredFields(requiredFields2)
        }
        else if(type==='MembershipGPOTierActivationFormats') {
            setRequiredFields(requiredFields3)
        }
        else if(type==='MembershipContractXREFFormats') {
            setRequiredFields(requiredFields4)
            props.getLblDispDesFieldValue({"data_source":"Membership Data"}, 'post');
        }
        else {
            props.getLblDispDesFieldValue({"data_source":"Membership Data"}, 'post');
        }
    }, []);
    useEffect(() => {
        if (props.configData) {
            setCurrentId(props.configData.format.id)
            setQueryName(props.configData.format.format_name)
            setQueryObject(props.configData.format)
            if(props.configData.default_values != null){
                setDefaultValue(props.configData.default_values)
                setMembershipOwnerId(props.configData.default_values.membership_owner_id)
                setMemberName(props.configData.default_values.GPO_member_name)
                setClassOfTrade(props.configData.default_values.ima_class_of_trade)
                setCustomerNumber(props.configData.default_values.customer_number)
                setMembershipStartDate(props.configData.default_values.membership_start_date)
                setMemberStatus(props.configData.default_values.member_status)
                setGPOName(props.configData.default_values.GPO_name);
                setGPOMemberNumber(props.configData.default_values.GPO_member_number);
                setMemberStartDate(props.configData.default_values.member_start_date);
                setContractStartDate(props.configData.default_values.contract_start_date);
                setTierActivationDate(props.configData.default_values.tier_activation_date);
                setContractEndDate(props.configData.default_values.contract_end_date);
                setContractNumber(props.configData.default_values.contract_number);
                }
            if(props.configData.format.membership_owner_id!==0)
                setMembershipOwnerId(props.configData.format.membership_owner_id)
            if(props.configData.format.GPO_member_name!==0)
                setMemberName(props.configData.format.GPO_member_name)
            if(props.configData.format.ima_class_of_trade!==0)
                setClassOfTrade(props.configData.format.ima_class_of_trade)
            if(props.configData.format.customer_number!==0)
                setCustomerNumber(props.configData.format.customer_number)
            if(props.configData.format.membership_start_date!==0)
                setMembershipStartDate(props.configData.format.membership_start_date)
            if(props.configData.format.member_status!==0)
                setMemberStatus(props.configData.format.member_status)
            if(props.configData.format.GPO_name!==0)
                setGPOName(props.configData.format.GPO_name);
            if(props.configData.format.GPO_member_number!==0)
                setGPOMemberNumber(props.configData.format.GPO_member_number);
            if(props.configData.format.member_start_date!==0)
                setMemberStartDate(props.configData.format.member_start_date);
            if(props.configData.format.contract_start_date!==0)
                setContractStartDate(props.configData.format.contract_start_date);
            if(props.configData.format.tier_activation_date!==0)
                setTierActivationDate(props.configData.format.tier_activation_date);
            if(props.configData.format.contract_end_date!==0)
                setContractEndDate(props.configData.format.contract_end_date);
            if(props.configData.format.contract_number!==0)
                setContractNumber(props.configData.format.contract_number);
        }
    }, [props.configData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes).map(item => {
                if (item.mandatory) {
                    newArray.push(item.current)
                }
            })
            setRequiredFields(newArray)
        }
    }, [props.tableData])
    useEffect(()=>{
        var newArray=[]
        if(sortedData && Object.values(sortedData).length>0){
            Object.values(sortedData).map(item =>{
                if(requiredFields.includes(item.current))
                {
                    if(queryObject.hasOwnProperty(item.key)||defaultValue.hasOwnProperty(item.key))
                    {
                        newArray.push(true)
                    }
                }
                if(newArray.length===requiredFields.length) {
                    setAllowedField(true)
                }
            })
            
        }
    },[queryObject,defaultValue])
    useEffect(()=>{
        var tempObj=[]
        if(props.tableData)
        { Object.values(props.tableData.field_label_attributes).map(e => { 
            if(e.sort_details) {
                tempObj.push(e)
            }
        })
        setSortedData(tempObj.sort((a, b) => a.sort_details > b.sort_details ? 1 : -1))
        }
    },[props.queryListData,props.tableData])
    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    function handleClear() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setQueryObject({ ...queryObject });
        setDefaultValue({ ...defaultValue });
        setMembershipOwnerId('');
        setMemberName('');
        setMemberNumber('');
        setClassOfTrade('');
        setCustomerNumber('');
        setMembershipStartDate('');
        setMemberStatus('');
        setGPOName('');
        setGPOMemberNumber('');
        setMemberStartDate('');
        setContractStartDate('');
        setTierActivationDate('');
        setContractEndDate('');
        setContractNumber('');
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(currentId, {...queryObject,'format_name': queryName,'default_values':defaultValue},type);
    }
    function handleClearAll() {
        handleClear();
    }
    function handleCancelDialog() {
        history.push({pathname:'/membership/membership-excel-upload',state:type});
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if(item==='membership_owner_id'){
            setMembershipOwnerId(event.target.value);
        }
        if(item==='GPO_member_name'){
            setMemberName(event.target.value);
        }
        if(item==='ima_class_of_trade'){
            setClassOfTrade(event.target.value);
        }
        if(item==='customer_number'){
            setCustomerNumber(event.target.value);
        }
        if(item==='membership_start_date'){
            setMembershipStartDate(event.target.value);
        }
        if(item==='member_status'){
            setMemberStatus(event.target.value);
        }
        if(item==='GPO_name'){
            setGPOName(event.target.value);
        }
        if(item==='GPO_member_number'){
            setGPOMemberNumber(event.target.value);
        }
        if(item==='member_start_date'){
            setMemberStartDate(event.target.value);
        }
        if(item==='contract_start_date'){
            setContractStartDate(event.target.value);
        }
        if(item==='tier_activation_date'){
            setTierActivationDate(event.target.value);
        }
        if(item === 'contract_number'){
            setContractNumber(event.target.value);
        }
        if(item==='contract_end_date'){
            setContractEndDate(event.target.value);
        }
    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if(item==='GPO_member_name'){
            setMemberName(event.target.value);
        }
        if(item==='customer_number'){
            setCustomerNumber(event.target.value);
        }
        if(item==='GPO_name'){
            setGPOName(event.target.value);
        }
        if(item==='GPO_member_number'){
            setGPOMemberNumber(event.target.value);
        }
        if(item==='member_start_date'){
            setMemberStartDate(event.target.value);
        }
        if(item==='contract_start_date'){
            setContractStartDate(event.target.value);
        }
        if(item==='tier_activation_date'){
            setTierActivationDate(event.target.value);
        }
        if(item === 'contract_number'){
            setContractNumber(event.target.value);
        }
        if(item==='contract_end_date'){
            setContractEndDate(event.target.value);
        }
    };
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>Edit Format</Typography>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {sortedData && Object.values(sortedData).length > 0 ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.values(sortedData)
                                                        // .filter(item => item != 'format_name'&& item != 'default_values' && item != 'organization_id' && item!= 'created_by_id' && item !='updated_by_id' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    {requiredFields.includes(item.current) ? <p>{item.current}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.current}
                                                                        </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                        value={queryObject[item.key]? queryObject[item.key]:''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item.key)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('membership-query'))[item] ? JSON.parse(localStorage.getItem('membership-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                           value={defaultValue[item.key] ? defaultValue[item.key]:''}
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item.key)}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    disabled={!allowedField}
                    // disabled = {(customerNumber && gpoName && gpoMemberNumber)||(gpoName && gpoMemberNumber && memberStartDate) 
                    //     || (gpoName&& gpoMemberNumber && contractNumber && tierActivationDate ) || (contractStartDate && contractEndDate && contractNumber) ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data,type) => dispatch(updateMembershipFormatData(id, data,type)),
        getMembershipFormatFields: (type) => dispatch(getMembershipFormatFields(type)),
        getMembershipFormatDataById:(id,type) => dispatch(getMembershipFormatDataById(id,type)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

const mapStateToProps = state => {
    return {
        queryListData: state.customerData.membershipFormatFieldData,
        configData:state.customerData.membershipFormatDataById,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipEditQuery);