import React, { useEffect, useRef } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, IconButton, OutlinedInput, FormLabel, Switch, Dialog, DialogActions, TextField, Select, MenuItem
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { getDynamicQueryFields, postDynamicQuery, getDynamicQuerySalesDataFields, getDefaultValuesAllData } from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'flex-end'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const EditDynamicQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [queryName, setQueryName] = React.useState('');
    const [bomPartName, setBomPartName] = React.useState('');
    const [bomDescription, setBomDescription] = React.useState('');
    const [bomPartNumber, setBomPartNumber] = React.useState('');
    const [quantity, setQuantity] = React.useState('');
    const [uom, setUom] = React.useState('');
    const [incomingRoyalty, setIncomingRoyalty] = React.useState('');
    const [outgoingRoyalty, setOutgoingRoyalty] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
    const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
    const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
    const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [sortOrderArray, setSortOrderArray] = React.useState([]);
    const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    useEffect(() => {
        props.getDefaultValuesAllData();
        setOpen(true);
    }, []);
    useEffect(() => {
        if (props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields) {
            var concatinated = props.dynamicQueryFieldsData.numeric_fields.concat(props.dynamicQueryFieldsData.string_fields)
                .concat(props.dynamicQueryFieldsData.date_fields)
            setApiArrayFieldsConcatinated(concatinated);
        }
    }, [props.dynamicQueryFieldsData]);
    useEffect(() => {
        var tempObj = {};
        if (apiArrayFieldsConcatinated.length > 0 && props.formFieldsAllData && props.formFieldsAllData.records) {
            apiArrayFieldsConcatinated.map((item1) => {
                props.formFieldsAllData.records.map((item2) => {
                    if (item1 === item2.field_id) {
                        tempObj[item2.field_id] = item2.name;
                    }
                    else if (item1 != item2.field_id && !tempObj.hasOwnProperty(item1)) {
                        tempObj[item1] = item1;
                    }
                })
            })
        }
        setKeyValuePairObject(tempObj);
    }, [props.formFieldsAllData, apiArrayFieldsConcatinated]);
    useEffect(() => {
        if (props.dynamicQueryDetailsData) {
            setDescription(props.dynamicQueryDetailsData.query_description);
            setQueryName(props.dynamicQueryDetailsData.format_name);
            setFilterFieldsArray(props.dynamicQueryDetailsData.filter_fields);
            setSumFieldsArray(props.dynamicQueryDetailsData.sum_fields);
            setAggregateFieldsArray(props.dynamicQueryDetailsData.groupby_fields);
            setOutputFieldsArray(props.dynamicQueryDetailsData.sort_fields);
            setCurrentEditId(props.dynamicQueryDetailsData.id);
            props.dynamicQueryDetailsData &&
                props.dynamicQueryDetailsData.output_fields && props.dynamicQueryDetailsData.output_fields.map(item => {
                    if (!props.dynamicQueryDetailsData.sort_fields.hasOwnProperty(item)) {
                        setOutputFieldsArray({ ...props.dynamicQueryDetailsData.sort_fields, [item]: 0 })
                    }
                })

        }
    }, [props.dynamicQueryDetailsData]);
    const [editIndex, setEditIndex] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const [userStore, setUserStore] = React.useState([]);
    function handleClear() {
        setEditIndex('')
        setEditMode('');
        setBomPartNumber('');
        setBomPartName('');
        setQuantity('');
        setUom('');
        setIncomingRoyalty('');
        setOutgoingRoyalty('');
        setNotes('');
    }
    function handleOnSubmit() {
        var unsortedFields = [];
        if (outputFieldsArray || (sumFieldsArray.length > 0 && filterFieldsArray.length === 0
            && outputFieldsArray.length === 0 && aggregateFieldsArray.length === 0)) {
            for (var propName in outputFieldsArray) {
                unsortedFields.push(propName);
                if (outputFieldsArray[propName] === 0) {
                    delete outputFieldsArray[propName];
                }
            }
            var allFields = {
                "filter_fields": filterFieldsArray,
                "sum_fields": sumFieldsArray,
                "sort_fields": outputFieldsArray,
                "output_fields": unsortedFields,
                "groupby_fields": aggregateFieldsArray,
                "format_name": queryName,
                "table_name": localStorage.getItem('dq_table_name'),
                "id": currentEditId,
                "query_description": description
            };
            props.onSubmit(allFields, 'edit');
        } else {
            alert('Please select atleast one output or only sum field')
        }
    }
    function handleClearAll() {
        handleClear();
        setEditMode(false);
        setUserStore([]);
    }
    function handleCancelDialog() {
        history.push('/dynamic-query');
    }
    const handleChange = (event, item, type) => {
        if (type === 'filter_fields') {
            if (filterFieldsArray.includes(item)) {
                setFilterFieldsArray(filterFieldsArray.filter(item1 => item1 !== item));
            } else {
                setFilterFieldsArray([...filterFieldsArray, item]);
            }
        }
        if (type === 'aggregate_fields') {
            if (aggregateFieldsArray.includes(item)) {
                if (sumFieldsArray.includes(item)) {

                } else {
                    setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                }
            } else {
                setAggregateFieldsArray([...aggregateFieldsArray, item]);
                setOutputFieldsArray({ ...outputFieldsArray, [item]: 0 });//aggregate and output should be in sync
            }
        }
        if (type === 'sum_fields') {
            if (sumFieldsArray.includes(item)) {
                setSumFieldsArray(sumFieldsArray.filter(item1 => item1 !== item));
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: 0 });
                setSumFieldsArray([...sumFieldsArray, item]);
            }
        }
        if (type === 'output_fields') {
            if (outputFieldsArray.hasOwnProperty(item)) {
                if (sumFieldsArray.includes(item)) {

                } else {
                    var tempArray = outputFieldsArray;
                    delete tempArray[item];
                    setOutputFieldsArray(tempArray)
                    setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                }
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: 0 });
                setAggregateFieldsArray([...aggregateFieldsArray, item])//aggregate and output should be in sync
            }
        }
        if (type === 'sort_order') {
            setSortOrderArray(event.target.value)
            setOutputFieldsArray({ ...outputFieldsArray, [item]: event.target.value ? parseInt(event.target.value) : 0 });
            event.preventDefault();
        }
        event.preventDefault();
    };
    //functionality name
    const [functionalityName, setFunctionalityName] = React.useState('');
    const handleFunctionalityName = (e) => {
        setFunctionalityName(e.target.value);
        if (e.target.value === 'Financial Postings Data') {
            props.getDynamicQueryFields();
        } else {
            props.getDynamicQuerySalesDataFields();
        }
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/dynamic-query'
                >
                    Dynamic Query
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Query</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Query Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Description
                    </FormLabel>
                    <OutlinedInput
                        value={description}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields ?
                                <div
                                >
                                    <div style={{ margin: 15 }}>
                                        <TableContainer style={{ overflowX: "initial" }}>
                                            <Table stickyHeader >
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center'>Filter</TableCell>
                                                        <TableCell align='center'>Aggregate</TableCell>
                                                        <TableCell align='center'>Sum</TableCell>
                                                        <TableCell align='center'>Output</TableCell>
                                                        <TableCell align='center'>Sort Order</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.entries(keyValuePairObject)
                                                        .sort()
                                                        .map(([key, value]) => {
                                                            return (
                                                                <TableRow key={key}>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{value.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={filterFieldsArray && filterFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'filter_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={aggregateFieldsArray && aggregateFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'aggregate_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={sumFieldsArray && sumFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'sum_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={outputFieldsArray && outputFieldsArray.hasOwnProperty(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'output_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        <TextField
                                                                            key={['recipient', key].join('_')}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            value={outputFieldsArray[key]}
                                                                            onChange={(e) => handleChange(e, key, 'sort_order')}
                                                                            disabled={outputFieldsArray && outputFieldsArray.hasOwnProperty(key) ? false : true}
                                                                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getDynamicQueryFields: () => dispatch(getDynamicQueryFields()),
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        onSubmit: (data, edit) => dispatch(postDynamicQuery(data, edit)),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),

    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsData,
        dynamicQueryDetailsData: state.operationalReportsData.dynamicQueryDetailsData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDynamicQuery);