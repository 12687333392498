import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton, Fab
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getEmployeeData, getEmployeeMasterViewDetails, deleteCustomerMasterData, getLblDispDesFieldValue } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { EmployeeMasterToolbar } from '.';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../../components/Dialog';
import Moment from 'moment';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const EmployeeMasterUserTable = props => {
    useEffect(() => {
        var data = { "data_source": "employee_master" }
        props.getEmployeeData(1, 10);
        props.loadSourceDataType(data, 'post');
    }, []);

    const [dataRows, setDataRows] = React.useState([]);
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            setDataRows(props.employeeMasterData.records);
        }
    }, [props.employeeMasterData]);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [columns, setColumns] = React.useState([]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [employeeMasterData, setEmployeeMasterData] = React.useState([]);

    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")

    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            setEmployeeMasterData(props.employeeMasterData.records);
        }
    }, [props.employeeMasterData]);
    function editMember(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/employee-master/edit-employee/' + id,
            state: 'editContract',
            id: id
        });
        localStorage.setItem('employeeMasterEditId', id);
    }


    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteCustomerMasterData(currentId, 'EmployeeMaster');
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };

    function viewMembership(id) {
        props.getEmployeeMasterViewDetails(id);
        history.push({
            pathname: '/employee/view-employee/' + id,
            id: id
        });
    }

    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_employee-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editMember(rowData.ID)}
                        size="large">
                        <Edit color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_employee-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleClickOpen(rowData.ID)}
                        size="large">
                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
            </div>

    }

    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Employee Master </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_employee-' + sessionStorage.getItem('application')) &&
                        <EmployeeMasterToolbar />}
                </div>
                {employeeMasterData && employeeMasterData.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            search: false,
                            sorting: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            filtering: true,
                            pageSize: 15,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
                <SimpleDialog open={open} content='Do you want to delete Employee data?' handleDialog={handleDialog} />
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        employeeMasterData: state.customerData.employeeMasterData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit, '')),
        getEmployeeMasterViewDetails: (id) => dispatch(getEmployeeMasterViewDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'EmployeeMaster')),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMasterUserTable);