import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button, TablePagination
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { getBudgetingConfig, getPromoStickers, createStickers } from '../../../../redux/actions';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    divContainerLeft: {
        position: 'relative',
        width: '80%',
        height: '80vh',
        marginLeft: 5,
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    mainHeader: {
        marginLeft: '5px',
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        // backgroundColor: '#E8E8E8',
        border: '1px solid #E8E8E8',
        borderRadius: 2,
    },

    headerLeft: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#E8E8E8',
        borderRadius: 2,
    },

    toggleButton: {
        marginTop: 22,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },

    icons: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    name: {
        display: 'flex',
        cursor: 'pointer',
    },

    filter: {
        display: 'flex',
        justifyContent: 'space-around',
        height: 50,
        border: '1px solid #EEEEEE',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 15,
    },

    addIcon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderRadius: '50%',
        padding: '9px',
        position: 'absolute',
        // bottom: '20px',
        marginTop: 2,
        right: '50px',
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        fontSize: 60,
        zIndex: 1
    },

    shareIcon: {
        position: 'absolute',
        bottom: '56px',
        right: '140px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    nextIcon: {
        position: 'absolute',
        bottom: '10px',
        right: '122px',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        border: '1px solid #D8D8D8'
    },

    historyButton: {
        border: '1px solid #D8D8D8',
        margin: '6px 10px',
        padding: 3,
        width: 35,
        height: 35,
    },

    tabHead: {
        fontSize: theme.typography.h4.fontSize,
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1,
    },

    toggleBtnContainer: {
        border: '0px',
        '&:hover': {
            color: 'black',
        },
    },

    actionIcon: {
        cursor: 'pointer',
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },


}));


const StickerBankContainer = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [productType, setProductType] = React.useState('');
    const [stickersBankData, setStickersBankData] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [stickerName, setStickerName] = React.useState('');
    const [stickersAPIData, setStickersAPIData] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10]);
    const [iconClicked, setIconClicked] = React.useState({});

    useEffect(() => {
        if (props.stickersData && props.stickersData.records) {
            setStickersBankData(props.stickersData.records);
            setStickersAPIData(props.stickersData);
        }
    }, [props.stickersData]);

    useEffect(() => {
        if (props.country) {
            if (props.country === 'CA')
                setChecked(true);
            setSelectedCountry(props.country);
        }
    }, [props.country]);

    useEffect(() => {
        if (props.stickerName) {
            setStickerName(props.stickerName);
        }
    }, [props.stickerName]);
    useEffect(() => {
        if (props.productType) {
            setProductType(props.productType);
        }
    }, [props.productType]);

    useEffect(() => {
        if (props.iconClicked) {
            setIconClicked(props.iconClicked);
        }
    }, [props.iconClicked]);

    function handleAdd() {
        history.push({
            pathname: '/promotion-sticker-setup/add',
            data: { variable: selectedCountry }
        });
    }


    function handleToggle(e) {
        setChecked(e.target.checked)
        if (e.target.checked)
            setSelectedCountry('CA')
        else
            setSelectedCountry('USA')
        props.onChange(e.target.checked ? 'CA' : 'USA');
    };

    function handleEdit(id) {
        props.getStickers(productType, selectedCountry, id)
    }

    function handleDelete(item) {
        var param = {
            ID: item.ID,
            product_line: productType,
            country: item.country
        }
        if (item && item.product_lines.length > 1) {
            var data = {
                product_lines: productType
            };

            props.onAction(data, 'delete', param);
        }
        else {
            props.onAction(null, 'delete', param);
        }

    }
    function handleCopy(item) {
        var param = {
            ID: item.ID,
            product_line: productType,
            country: item.country
        }
        props.onAction(null, 'clone', param)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getStickers(productType, selectedCountry, null, newPage + 1, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getStickers(productType, selectedCountry, null, 1, event.target.value);
    };

    function handleTableData(item) {
        const newIconClicked = { ...iconClicked, [item.ID]: true };
        setIconClicked(newIconClicked);
        var selectedData = {
            'item': item,
            'iconClicked': newIconClicked,
        }
        props.data(selectedData);
    }

    return (
        <>
            <div className={classes.root}>
                {/* <div style={{ display: 'flex' }}>
                    <div className={classes.divContainerLeft}> */}
                <div className={classes.headerLeft}>
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ marginLeft: 5, marginTop: 23 }}>USA</Typography>
                        <span className={classes.toggleButton} >
                            <Switch
                                checked={checked}
                                onClick={handleToggle}
                                disabled={props.page === "Scenario" ? true : false} />
                        </span>
                        <Typography style={{ marginTop: 23 }}>CANADA</Typography>
                    </div>
                    <div>
                        <Typography style={{ color: 'rgb(182 175 175)', marginTop: 10, marginRight: 10 }}>{stickerName}</Typography>
                    </div>
                </div>
                <Table style={{ marginTop: 5 }}>
                    <TableHead style={{ backgroundColor: "#E8E8E8", position: "sticky", top: 0, zIndex: 1 }}>
                        <TableRow>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >Stickers Name</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Take Rate</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Creator</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Related PL</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >Last update</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >Status</TableCell>
                            <TableCell align='center' className={classes.tabHead} style={{ minWidth: 120 }}  >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <div className={classes.filter}>
                        <Typography style={{ marginLeft: -8, marginTop: 7 }}>Filter</Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Promotion Type <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Status <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Version <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                        <Typography style={{ color: '#D6D6D6' }}>Take Rate <ArrowDropDownOutlinedIcon></ArrowDropDownOutlinedIcon></Typography>
                    </div>
                    {stickersBankData && stickersBankData.length > 0 ? (
                        <TableBody>
                            {stickersBankData && stickersBankData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 200 }}  >
                                        <div className={classes.name} onClick={() => handleTableData(item, index)}>
                                            <LibraryAddIcon style={{ color: iconClicked[item.ID] ? '#ffdd42' : 'grey', marginRight: 4 }}></LibraryAddIcon>
                                            {item.version > 1 ? `V${item.version} - ${item.sticker_description}` : item.sticker_description}
                                        </div>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >X%</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.user_details && item.user_details.created_user.name}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >{item.product_lines && item.product_lines.join(', ')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >{moment.utc(item.UpdatedAt).local().format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 60 }}  >
                                        <CheckCircleOutlineIcon style={{ color: 'green' }}></CheckCircleOutlineIcon>
                                    </TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ minWidth: 100 }}  >
                                        <div className={classes.icons}>
                                            <ContentCopyIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleCopy(item)}
                                            >
                                            </ContentCopyIcon>
                                            <EditIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleEdit(item.ID)}>
                                            </EditIcon>
                                            <DeleteIcon
                                                className={classes.actionIcon}
                                                onClick={() => handleDelete(item)}>
                                            </DeleteIcon>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} align='center'>No data available</TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
                <TablePagination
                    component="div"
                    count={props.stickersData && props.stickersData.total_record ? props.stickersData.total_record : 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                        root: classes.paginationRoot
                    }}
                />
                <AddIcon onClick={handleAdd}
                    className={classes.addIcon}>
                </AddIcon>
                {/* </div >
                </div> */}
            </div>
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getStickers: (productType, country, id, pagination, limit) => dispatch(getPromoStickers(productType, country, id, pagination, limit)),
        onAction: (data, type, id) => dispatch(createStickers(data, type, id)),
    }
}

const mapStateToProps = state => {
    return {
        stickersData: state.promotionData.promoStickers,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerBankContainer);