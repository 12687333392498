import React, { useEffect } from "react";
import "../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  FormLabel,
  MenuItem,
  Select,
  Button, TextField
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  allowedApps,
  getDashboardCount,
  loadValuesBasedOnAppTypeAndField,
  dashboardAnalyticsNames
} from "../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ChartTheme,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
  ILoadedEventArgs,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: "5px 0px 16px 0px",
  },
  graphContainer: {
    width: "80%",
    height: "100%",
    marginTop: 10,
    display: "flex",
    alignSelf: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },
  selectedItem: {
    borderRadius: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 34,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
const ContractStats = (props) => {
  const classes = useStyles();
  const [maximumNumber, setMaximumNumber] = React.useState(50);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  useEffect(() => {
    props.allowedAnalyticsApps(sessionStorage.getItem("application"));
  }, []);

  useEffect(() => {
    if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
      const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
        item.analytic_name === "Contract Stats"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedAnalyticsAppsData])

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate) {
      props.loadDashboardCount(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'));
    }
  }, [allowedTilesFilter]);



  const actualData = props.dashboardCountData;

  var max = [actualData.active_contracts];
  var max2 = [actualData.expired_contracts];
  var max3 = [actualData.new_contracts];
  var max4 = [actualData.total_contracts];
  var max5 = [actualData.waiting_for_approval];


  const chartData = [
    [{ x: 'Active Contracts', y: actualData.active_contracts, toolTipMappingName: 'Active Contracts' },],
    [{ x: 'Expired Contracts', y: actualData.expired_contracts, toolTipMappingName: 'Expired Contracts' },],
    [{ x: 'New Contracts', y: actualData.new_contracts, toolTipMappingName: 'New Contracts' },],
    [{ x: 'Total Contracts', y: actualData.total_contracts, toolTipMappingName: 'Total Contracts' },],
    [{ x: 'Waiting For Approval', y: actualData.waiting_for_approval, toolTipMappingName: 'Waiting For Approval' },]
  ];


  useEffect(() => {
    const maximumofnumber = Math.max(max, max2, max3, max4, max5)
    if (maximumofnumber > 50)
      setMaximumNumber(maximumofnumber);
  }, [props.dashboardCountData]);

  useEffect(() => {

    props.allowedApps();
  }, [])

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData])


  const handleStartDate = (e) => {
    setStartDate(e);
  }

  const handleEndDate = (e) => {
    setEndDate(e);
  }

  const handleRun = () => {
    if (startDate && endDate) {
      props.loadDashboardCount(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'));
    }
  }

  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div className={classes.rootDiv}>
        <Grid
          container
          className={classes.root}
          style={{ justifyContent: "space-between" }}
        >
          {props.page !== "dashboard" && (
            <Grid
              container
              style={{
                margin: 10,
                padding: 5,
                border: "1px solid #E6E6E6",
                borderRadius: 5,
              }}
            >

              <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: '1px 0 1px 11px',
                            alignSelf: 'center',
                            alignItems: 'center',
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderRadius: 5,
                            height: 37,
                            color: '#1675e0'
                          }
                        }}
                        value={startDate}
                        onChange={handleStartDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: '1px 0 1px 11px',
                            alignSelf: 'center',
                            alignItems: 'center',
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderRadius: 5,
                            height: 37,
                            color: '#1675e0'
                          }
                        }}
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                        minDate={startDate ? startDate : false}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleRun}
                  twoline="true"
                  disabled={(!startDate || !endDate) ? true : false}
                >
                  <DirectionsRunIcon />
                </Button>
              </div>
            </Grid>
          )}
          <Grid container className={classes.root}>
            <Grid item md={12} xs={6} className={classes.container}>
              <div className={`area-chart-wrapper${classes.graphContainer}`}>
                <Typography variant="h3" style={{ textAlign: 'center', marginBottom: 15 }} color="primary" > Contract Stats </Typography>
                <ChartComponent
                  id="charts"
                  style={{ textAlign: "center" }}
                  legendSettings={{ enableHighlight: true }}
                  primaryXAxis={{
                    labelIntersectAction: Browser.isDevice ? "None" : "Trim",
                    labelRotation: Browser.isDevice ? -45 : 0,
                    valueType: "Category",
                    interval: 1,
                    majorGridLines: { width: 0 },
                    majorTickLines: { width: 0 },
                    edgeLabelPlacement: "Shift",

                  }}
                  primaryYAxis={{
                    majorTickLines: { width: 0 },
                    lineStyle: { width: 0 },
                    maximum: maximumNumber + maximumNumber / 100,
                    interval: parseInt(maximumNumber / 10),
                  }}
                  chartArea={{ border: { width: 0 } }}
                  tooltip={{
                    enable: true,
                    header: "<b>${point.tooltip}</b>",
                    shared: true,
                  }}
                  width={Browser.isDevice ? "100%" : "75%"}
                  height={Browser.isDevice ? "180%" : "100%"}
                  title=""
                >
                  <Inject
                    services={[
                      ColumnSeries,
                      Legend,
                      Tooltip,
                      Category,
                      DataLabel,
                      Highlight,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={chartData[0]}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Active Contracts"
                      type="Column"
                      fill='#2485FA'
                    ></SeriesDirective>
                    <SeriesDirective
                      dataSource={chartData[1]}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Expired Contracts"
                      type="Column"
                      fill='#FEC200'
                    ></SeriesDirective>
                    <SeriesDirective
                      dataSource={chartData[2]}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="New Contracts"
                      type="Column"
                      fill='#FF4233'
                    ></SeriesDirective>
                    <SeriesDirective
                      dataSource={chartData[3]}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Total Contracts"
                      type="Column"
                      fill='#55FF33'
                    ></SeriesDirective>
                    <SeriesDirective
                      dataSource={chartData[4]}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Waiting For Approval"
                      type="Column"
                      fill='#33FFD1'
                    ></SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LoadingOverlay>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.initialData.loading,
    dashboardCountData: state.initialData.dashboardCountData,
    allowedAppsData: state.initialData.allowedApps,
    allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDashboardCount: (sd, ed) => dispatch(getDashboardCount(sd, ed, "Contract Stats")),
    allowedApps: () => dispatch(allowedApps()),
    allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractStats);