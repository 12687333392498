import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton,
} from '@mui/material';
import { Edit, DeleteForever } from '@mui/icons-material';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { connect } from 'react-redux';
import { getCustomerMasterXRefData, getCustomerMasterXRefDetails, deleteCustomerMasterData } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { CustomerMasterXRefToolbar } from './';
import { useHistory } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    }
}));

const CustomerXMasterUserTable = props => {
    useEffect(() => {
        props.getCustomerMasterXRef(1, 10);
    }, []);
    useEffect(() => {
        if (props.customerMasterXRefData && props.customerMasterXRefData.records && props.customerMasterXRefData.records.length > 0) {
            setDataRows(props.customerMasterXRefData.records);
        }
    }, [props.customerMasterXRefData]);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [dataRows, setDataRows] = React.useState([]);
    const deleteData = (id) => {
        props.deleteCustomerMasterData(id, 'CustomerXMaster')
    }

    function editCustomer(id) {
        navigateToEdit(id);
    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/customer-x-master/edit-customermaster-xref/' + id,
            id: id
        });
    }
    function viewMembership(id) {
        props.getCustomerMasterXRefDetails(id);
        history.push({
            pathname: '/customer-x-master/view-customermaster-xref/' + id,
            id: id
        });
    }
    const columns = [
        {
            field: 'channel_partner_type',
            title: 'Channel Partner Type',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_type}</a>
        },
        {
            field: 'channel_partner_id',
            title: 'Channel Partner ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_id}</a>
        },
        {
            field: 'channel_partner_name',
            title: 'Channel Partner Name',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_name
            }</a>
        },
        {
            field: 'channel_partner_end_customer_id',
            title: 'Channel Partner End Customer ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.channel_partner_end_customer_id}</a>
        },
        {
            field: 'ima_end_customer_id',
            title: 'IMA End Customer ID',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.ima_end_customer_id}</a>
        },
        {
            field: 'customer_name',
            title: 'Customer Name',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.customer_name}</a>
        },
        {
            field: 'street_address',
            title: 'Street Address',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.street_address}</a>
        },
        {
            field: 'city',
            title: 'City',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.city}</a>
        },
        {
            field: 'region',
            title: 'Region',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.region}</a>
        },
        {
            field: 'postal_code',
            title: 'Postal Code',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.postal_code}</a>
        },
        {
            field: 'country',
            title: 'Country',
            type: 'string',
            render: rowData => <a className={classes.hover} onClick={() => viewMembership(rowData.ID)}> {rowData.country}</a>
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editCustomer(rowData.ID)}
                            size="large">
                            <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_xref-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => deleteData(rowData.ID)}
                            size="large">
                            <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>
        },
    ];

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'> Customer XREF </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_xref-' + sessionStorage.getItem('application')) &&
                        <CustomerMasterXRefToolbar />}
                </div>
                {props.customerMasterXRefData && props.customerMasterXRefData.records && props.customerMasterXRefData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        customerMasterXRefData: state.customerData.customerMasterXRefData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterXRef: (pagination, limit) => dispatch(getCustomerMasterXRefData(pagination, limit, '')),
        getCustomerMasterXRefDetails: (id) => dispatch(getCustomerMasterXRefDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'CustomerXMaster')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerXMasterUserTable);