import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    Select,
    Input,
    MenuItem, FormLabel, CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown, Datepicker, TextInputWithLabel, DropdownArray } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, getDefaultValuesAllDataNew, getAllOrganizationUsers } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.white,
        // border: '1px solid #EEEEEE',
        borderRadius: 6,
        // marginBottom: 23
    },
    container: {
        padding: 10,
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    multiSelect: {
        marginTop: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 8,
    },
    titleSetting: {
        background: theme.palette.primary.main,
        textAlign: 'center',
        height: '1rem',
        marginTop: '2rem',
        marginBottom: '1.5rem',
        marginLeft: '1rem',
        marginRight: '1rem'
    }
}));

const Miscellaneous = forwardRef((props, ref) => {
    const history = useHistory();
    const contractCustomisationUpdation = useRef();
    //constants
    const [negotiatingReasonData, setNegotiatingReasonData] = React.useState([]);
    const [negotiatingReasonName, setNegotiatingReasonName] = React.useState('');
    const [negotiatingOutcomeData, setNegotiatingOutcomeData] = React.useState([]);
    const [negotiatingOutcomeName, setNegotiatingOutcomeName] = React.useState('');
    const [contractOwnerTypeData, setContractOwnerTypeData] = React.useState([]);
    const [contractOwnerTypeName, setContractOwnerTypeName] = React.useState('');

    //arrays
    const [negotiationReasonArray, setNegotiationReasonArray] = React.useState([]);
    const [negotiationOutcomeArray, setNegotiationOutcomeArray] = React.useState([]);
    const [outcomeDateArray, setOutcomeDateArray] = React.useState([]);
    const [contractOwnerTypeArray, setContractOwnerTypeArray] = React.useState([]);
    const [contractOwnerArray, setContractOwnerArray] = React.useState([]);
    const [contractAnalystArray, setContractAnalystArray] = React.useState([]);
    const [contractManagerArray, setContractManagerArray] = React.useState([]);
    const [salesAnalystArray, setSalesAnalystArray] = React.useState([]);
    const [salesManagerArray, setSalesManagerArray] = React.useState([]);
    const [financeAnalystArray, setFinanceAnalystArray] = React.useState([]);
    const [financeManagerArray, setFinanceManagerArray] = React.useState([]);
    const [externalDeliveryOptionArray, setExternalDeliveryOptionArray] = React.useState([]);
    const [internalSignedDateArray, setInternalSignedDateArray] = React.useState([]);
    const [externalSignedDateArray, setExternalSignedDateArray] = React.useState([]);
    const [externalContactEmailArray, setExternalContactEmailArray] = React.useState([]);
    const [externalContactPhoneArray, setExternalContactPhoneArray] = React.useState([]);
    //new fields
    // const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    // const [externalReference, setExternalReference] = React.useState('');
    const [externalDescriptionArray, setExternalDescriptionArray] = React.useState([]);
    const [externalDescription, setExternalDescription] = React.useState('');
    const [imageReferenceIdArray, setImageReferenceIdArray] = React.useState([]);
    const [imageReferenceId, setImageReferenceId] = React.useState('');
    const [pricingStratergyArray, setPricingStratergyArray] = React.useState([]);
    const [pricingStratergy, setPricingStratergy] = React.useState('');
    const [flexField6Array, setFlexField6Array] = React.useState([]);
    const [flexField6, setFlexField6] = React.useState('');
    const [flexField7Array, setFlexField7Array] = React.useState([]);
    const [flexField7, setFlexField7] = React.useState('');
    const [flexField8Array, setFlexField8Array] = React.useState([]);
    const [flexField8, setFlexField8] = React.useState('');
    const [flexField9Array, setFlexField9Array] = React.useState([]);
    const [flexField9, setFlexField9] = React.useState('');
    const [flexField10Array, setFlexField10Array] = React.useState([]);
    const [flexField10, setFlexField10] = React.useState('');
    const [documentId, setDocumentID] = React.useState('');
    useImperativeHandle(ref, () => ({
        submit() {
            // if (mode === 'edit')
            //     props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            props.submitValues(miscellaneousValues);
        }
    }));
    useEffect(() => {
        // props.onLoadingDefault();
        props.getUsers();
    }, []);

    useEffect(() => {
        if (history.location && history.location.documentID) {
            setDocumentID(history.location.documentID.toString())
        }
    }, [history])

    useEffect(() => {
        props.submitValues(miscellaneousValues);
    });

    var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const { className } = props;
    const [negotiatingReason, setNegotiatingReason] = React.useState('');
    const [outcomeDate, setOutcomeDate] = React.useState('');
    const [contractOwner, setContractOwner] = React.useState('');
    const [contractManager, setContactManager] = React.useState('');
    const [salesManager, setSalesManager] = React.useState('');
    const [financialManager, setFinancialManager] = React.useState('');
    const [internalSignedDate, setInternalSignedDate] = React.useState('');
    const [externalContactEmail, setExternalContactEmail] = React.useState('');
    const [negotiatingOutcome, setNegotiatingOutcome] = React.useState('');
    const [contractOwnerType, setContractOwnerType] = React.useState('');
    const [contractAnalyst, setContractAnalyst] = React.useState('');
    const [salesAnalyst, setSalesAnalyst] = React.useState('');
    const [financialAnalyst, setFinancialAnalyst] = React.useState('');
    const [externalDeliveryOption, setExternalDeliveryOptions] = React.useState('');
    const [externalSignedDate, setExternalSignedDate] = React.useState('');
    const [externalContactPhone, setExternalContactPhone] = React.useState('');
    const [externalContractType, setExternalContractType] = React.useState('');
    const [externalContractNumber, setExternalContractNumber] = React.useState('');
    const [externalContractName, setExternalContractName] = React.useState('');
    const [externalContractNameArray, setExternalContractNameArray] = React.useState([]);
    const [externalContractNumberArray, setExternalContractNumberArray] = React.useState([]);
    const [externalContractTypeArray, setExternalContractTypeArray] = React.useState('');
    const [contractStartDate, setContractStartDate] = React.useState('');
    const [contractEndDate, setContractEndDate] = React.useState('');
    const miscellaneousValues = {
        // external_reference: externalReference,
        // external_description: externalDescription,
        image_reference_id: imageReferenceId,
        pricing_strategy: pricingStratergy,
        negotiation_reason: negotiatingReason,
        outcome_date: outcomeDate,
        contract_owner: contractOwner,
        contract_manager: contractManager,
        sales_manager: salesManager,
        finance_manager: financialManager,
        internal_signed_date: internalSignedDate,
        external_contact_email: externalContactEmail,
        negotiation_outcome: negotiatingOutcome,
        contract_owner_type: contractOwnerType,
        contract_analyst: contractAnalyst,
        sales_analyst: salesAnalyst,
        finance_analyst: financialAnalyst,
        external_delivery_options: externalDeliveryOption,
        external_signed_date: externalSignedDate,
        external_contact_phone: externalContactPhone,
        flex_fields_6: flexField6,
        flex_fields_7: flexField7,
        flex_fields_8: flexField8,
        flex_fields_9: flexField9,
        flex_fields_10: flexField10,
        document_id: documentId,
        external_contact_name: externalContractName,
        external_contract_type: externalContractType,
        external_contract_number: externalContractNumber,
        contract_start_date: contractStartDate,
        contract_end_date: contractEndDate,
    };
    function handleNegotiatingReason(newValue) {
        setNegotiatingReason(newValue);
    }
    function handleOutcomeDate(newValue) {
        if (newValue && newValue.isValid())
            setOutcomeDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setOutcomeDate(newValue);
    }
    function handleContractOwner(newValue) {
        setContractOwner(newValue);
    }
    function handleContractManager(newValue) {
        setContactManager(newValue);
    }
    function handleSalesManager(newValue) {
        setSalesManager(newValue);
    }
    function handleFinanceManager(newValue) {
        setFinancialManager(newValue);
    }
    function handleInternalSignedDate(newValue) {
        if (newValue && newValue.isValid())
            setInternalSignedDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setInternalSignedDate(newValue);
    }
    function handleExternalContactEmail(newValue) {
        setExternalContactEmail(newValue);
    }
    function handleContractOwnerType(newValue) {
        setContractOwnerType(newValue);
    }
    function handleContractAnalyst(e) {
        setContractAnalyst(e.target.value);
    }
    function handleSalesAnalyst(newValue) {
        setSalesAnalyst(newValue);
    }
    function handleFinanceAnalyst(newValue) {
        setFinancialAnalyst(newValue);
    }
    function handleExternalDeliveryOptions(newValue) {
        setExternalDeliveryOptions(newValue);
    }
    function handleExternalSignedDate(newValue) {
        if (newValue && newValue.isValid())
            setExternalSignedDate(newValue.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setExternalSignedDate(newValue);
    }
    function handleExternalContactPhone(newValue) {
        setExternalContactPhone(newValue);
    }
    function handleNegotiatingOutcome(newValue) {
        setNegotiatingOutcome(newValue);
    }
    //new fields
    // function handleExternalReference(newValue) {
    //     setExternalReference(newValue);
    // }
    function handleExternalDescription(newValue) {
        setExternalDescription(newValue);
    }
    function handleImageReferenceId(newValue) {
        setImageReferenceId(newValue);
    }
    function handlePricingStratergy(newValue) {
        setPricingStratergy(newValue);
    }
    function handleFlexField6(newValue) {
        setFlexField6(newValue);
    }
    function handleFlexField7(newValue) {
        setFlexField7(newValue);
    }
    function handleFlexField8(newValue) {
        setFlexField8(newValue);
    }
    function handleFlexField9(newValue) {
        setFlexField9(newValue);
    }
    function handleFlexField10(newValue) {
        setFlexField10(newValue);
    }
    function handleDocumentID(newValue) {
        setDocumentID(newValue);
    }
    function handleContractStartDate(newValue) {
        setContractStartDate(newValue);
    }
    function handleContractEndDate(newValue) {
        setContractEndDate(newValue);
    }
    const [orgUsersAarray, setOrgUsersArray] = React.useState([]);
    useEffect(() => {
        if (props.orgUsers && props.orgUsers.length > 0) {
            var temp = [];
            props.orgUsers.map(item => {
                temp.push(item.username);
            })
            setOrgUsersArray(temp);
        }
    }, [props.orgUsers]);

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="CONTRACT DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        style={{ marginTop: '0.5rem' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1.7rem' }} >
                        <Grid container >
                            {props.fieldData && props.fieldData['contract_start_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['contract_start_date']['current']}
                                        twoline='true' onChange={handleContractStartDate} prevalue={props.data && props.data.contract_start_date && props.data.contract_start_date != '0001-01-01T00:00:00Z' ? props.data.contract_start_date : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_end_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['contract_end_date']['current']} twoline='true' onChange={handleContractEndDate} prevalue={props.data && props.data.contract_end_date && props.data.contract_end_date != '0001-01-01T00:00:00Z' ? props.data.contract_end_date : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['document_id'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['document_id']['current']} twoline='true' prevalue={documentId} onChange={handleDocumentID} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_analyst'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            {props.fieldData['contract_analyst']['current']}
                                        </FormLabel>
                                        <Select
                                            value={contractAnalyst}
                                            onChange={(e) => setContractAnalyst(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ marginTop: 0, height: 36 }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {orgUsersAarray &&
                                                orgUsersAarray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['contract_manager'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.dropdownColumn} style={{ width: '100%', marginRight: 15 }}>
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            {props.fieldData['contract_manager']['current']}
                                        </FormLabel>
                                        <Select
                                            value={contractManager}
                                            onChange={(e) => setContactManager(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ marginTop: 0 }}
                                        >
                                            <MenuItem value="" style={{ height: 30 }}>
                                            </MenuItem>
                                            {orgUsersAarray &&
                                                orgUsersAarray
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                        </Select>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="REPORTING DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        style={{ marginTop: '0.5rem' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1.7rem' }} >
                        <Grid container >
                            {props.fieldData && props.fieldData['negotiation_reason'] &&
                                < Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['negotiation_reason']['current']} placeholder={' '} data={props.fieldData['negotiation_reason']['drop_down_values']} twoline='true'
                                        onChange={handleNegotiatingReason} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.negotiation_reason : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['negotiation_outcome'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['negotiation_outcome']['current']}
                                        placeholder={' '} data={props.fieldData['negotiation_outcome']['drop_down_values']} twoline='true' onChange={handleNegotiatingOutcome}
                                        prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.negotiation_outcome : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['pricing_strategy'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={props.fieldData['pricing_strategy']['current']} placeholder={' '} twoline='true'
                                        data={props.fieldData['pricing_strategy']['drop_down_values']} onChange={handlePricingStratergy} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['outcome_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['outcome_date']['current']} twoline='true'
                                        onChange={handleOutcomeDate} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.outcome_date : ''} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['internal_signed_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['internal_signed_date']['current']} twoline='true' onChange={handleInternalSignedDate}
                                        prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.internal_signed_date : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_signed_date'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <Datepicker classes={{ root: classes.fontSetting }} heading={props.fieldData['external_signed_date']['current']} twoline='true' onChange={handleExternalSignedDate}
                                        prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.external_signed_date : ''} />
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['image_reference_id'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['image_reference_id']['current']} placeholder={' '}
                                        twoline='true' onChange={handleImageReferenceId} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['contract_owner'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['contract_owner']['drop_down_reference_value'] && props.fieldData['contract_owner']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['contract_owner']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={contractOwner}
                                                onChange={(e) => setContractOwner(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['contract_owner']['drop_down_reference_value'] && props.fieldData['contract_owner']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['contract_owner']['current']}
                                            twoline='true' onChange={handleContractOwner} />
                                    }
                                </Grid>
                            }

                            {props.fieldData && props.fieldData['contract_owner_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['contract_owner_type']['current']} placeholder={' '}
                                        twoline='true' onChange={handleContractOwnerType}
                                        prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.contract_owner_type : ''}
                                        data={props.fieldData['contract_owner_type']['drop_down_values']} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_contract_type'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['external_contract_type']['drop_down_reference_value'] && props.fieldData['external_contract_type']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['external_contract_type']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={externalContractType}
                                                onChange={(e) => setExternalContractType(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['external_contract_type']['drop_down_reference_value'] && props.fieldData['external_contract_type']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['external_contract_type']['current']}
                                            twoline='true' onChange={(e) => setExternalContractType(e)} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_contract_number'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_contract_number']['current']} twoline='true'
                                        onChange={(e) => setExternalContractNumber(e)} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['sales_analyst'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['sales_analyst']['current']} twoline='true'
                                        onChange={handleSalesAnalyst} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.sales_analyst : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['sales_manager'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['sales_manager']['current']} twoline='true'
                                        onChange={handleSalesManager} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.sales_manager : ''} />
                                </Grid>
                            }


                            {props.fieldData && props.fieldData['finance_analyst'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['finance_analyst']['current']} twoline='true'
                                        onChange={handleFinanceAnalyst} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.finance_analyst : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['finance_manager'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['finance_manager']['current']} twoline='true'
                                        onChange={handleFinanceManager} prevalue={props.data && props.data.miscellaneous ? props.data.miscellaneous.finance_manager : ''} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_contact_name'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_contact_name']['current']} twoline='true'
                                        onChange={(e) => setExternalContractName(e)} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_delivery_options'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_delivery_options']['current']} placeholder={' '}
                                        data={props.fieldData['external_delivery_options']['drop_down_values']} twoline='true'
                                        onChange={handleExternalDeliveryOptions} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_contact_email'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_contact_email']['current']} twoline='true'
                                        onChange={handleExternalContactEmail} />
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['external_contact_phone'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                        heading={props.fieldData['external_contact_phone']['current']} twoline='true'
                                        onChange={handleExternalContactPhone} />
                                </Grid>
                            }</Grid>
                    </div>
                </form>
            </Card>
            <Card className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3', color: 'secondary' }}
                        style={{ marginTop: '0.5rem' }}
                        className={classes.titleSetting}
                    />
                    <div className={classes.container} style={{ marginTop: '-1.7rem' }} >
                        <Grid container >
                            {props.fieldData && props.fieldData['flex_fields_6'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_6']['drop_down_reference_value'] && props.fieldData['flex_fields_6']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_6']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexField6}
                                                onChange={(e) => setFlexField6(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_6']['drop_down_reference_value'] && props.fieldData['flex_fields_6']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_6']['current']} twoline='true'
                                            onChange={handleFlexField6} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_7'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_7']['drop_down_reference_value'] && props.fieldData['flex_fields_7']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_7']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexField7}
                                                onChange={(e) => setFlexField7(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_7']['drop_down_reference_value'] && props.fieldData['flex_fields_7']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_7']['current']} twoline='true'
                                            onChange={handleFlexField7} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_8'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_8']['drop_down_reference_value'] && props.fieldData['flex_fields_8']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_8']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexField8}
                                                onChange={(e) => setFlexField8(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_8']['drop_down_reference_value'] && props.fieldData['flex_fields_8']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_8']['current']} twoline='true'
                                            onChange={handleFlexField8} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_9'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_9']['drop_down_reference_value'] && props.fieldData['flex_fields_9']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_9']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexField9}
                                                onChange={(e) => setFlexField9(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_9']['drop_down_reference_value'] && props.fieldData['flex_fields_9']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_9']['current']} twoline='true' onChange={handleFlexField9} />
                                    }
                                </Grid>
                            }
                            {props.fieldData && props.fieldData['flex_fields_10'] &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {props.fieldData['flex_fields_10']['drop_down_reference_value'] && props.fieldData['flex_fields_10']['drop_down_reference_value'].length > 0 ?
                                        <>
                                            <FormLabel >
                                                {props.fieldData['flex_fields_10']['current']}
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={flexField10}
                                                onChange={(e) => setFlexField10(e.target.value)}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ marginTop: 5 }}
                                            >
                                                <MenuItem value='' style={{ height: 30 }}>
                                                </MenuItem>
                                                {props.fieldData['flex_fields_10']['drop_down_reference_value'] && props.fieldData['flex_fields_10']['drop_down_reference_value']
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                                {item.key}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </> :
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }}
                                            heading={props.fieldData['flex_fields_10']['current']} twoline='true'
                                            onChange={handleFlexField10} />
                                    }
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

});

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    orgUsers: state.operationalReportsData.orgUsers
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getUsers: () => dispatch(getAllOrganizationUsers()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Miscellaneous);
