import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Box, IconButton, Paper } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
const PromoMultiSelect = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [tempSelectedOptions, setTempSelectedOptions] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        // Reset tempSelectedOptions on close
        setTempSelectedOptions(selectedOptions);
    };

    const handleMenuItemClick = (option) => () => {
        const selectedIndex = tempSelectedOptions.indexOf(option);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...tempSelectedOptions, option];
        } else if (selectedIndex === 0) {
            newSelected = tempSelectedOptions.slice(1);
        } else if (selectedIndex === tempSelectedOptions.length - 1) {
            newSelected = tempSelectedOptions.slice(0, -1);
        } else if (selectedIndex > 0) {
            newSelected = [
                ...tempSelectedOptions.slice(0, selectedIndex),
                ...tempSelectedOptions.slice(selectedIndex + 1),
            ];
        }

        setTempSelectedOptions(newSelected);
    };

    const handleConfirm = () => {
        setSelectedOptions(tempSelectedOptions);
        handleClose();
        props.onChange(tempSelectedOptions)
    };

    const handleCancel = () => {
        setTempSelectedOptions(selectedOptions);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                id={props.id}
                PaperProps={{
                    style: {
                        maxHeight: '350px', // Set maximum height
                        overflowY: 'auto', // Enable vertical scrolling
                    },
                }}
            >
                {props.options && props.options.length > 0 &&
                    props.options.map(item => (
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={tempSelectedOptions.indexOf(item) > -1}
                                        onChange={handleMenuItemClick(item)}
                                    />
                                }
                                label={item}
                            />
                        </MenuItem>
                    ))
                }
                <div style={{ flex: '1 1 auto' }} />

                {/* Confirm and Cancel buttons */}
                <Paper style={{ padding: '10px', position: 'sticky', bottom: 0, backgroundColor: 'white', justifyContent: 'flex-end', display: 'flex' }}>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} variant="contained" sx={{ ml: 1 }}>
                        Confirm
                    </Button>
                </Paper>

            </Menu>
        </div>
    );
};

export default PromoMultiSelect;
