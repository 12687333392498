import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { getAnalysisDesignerData, deleteReportTypeData, getReportTypeData } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import EditIcon from '@mui/icons-material/Edit';
import { AddBoxOutlined, LibraryAdd, DeleteForever, Edit } from '@mui/icons-material';
import SimpleDialog from '../../components/Dialog/SimpleDialog';
import Table from '../../components/Inputs/Table';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const AnalyticsDesignerMain = props => {
    useEffect(() => {
        props.getReportTypeData();
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [currentId, setCurrentId] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getReportTypeData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getReportTypeData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.analysisData) {
            // const filteredData = props.analysisData.filter(item => item.dashboard_name === "");
            // filteredData.sort((a, b) => (a.application_type > b.application_type ? 1 : -1));
            setAttributeData(props.analysisData);
        }
    }, [props.analysisData]);
    function editAttribute(id) {
        history.push({
            pathname: '/report-by-function/edit-report/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/report-by-function/view-report/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteReportTypeData(id);
    }

    const handleClickOpen = (Id) => {
        setOpen(true);
        setCurrentId(Id);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            deleteData(currentId);
        } else {
            setOpen(false);
        }
    }
    const arrayItem = [];
    const columns = [
        {
            field: "application_type",
            title: "Application Type",
            editable: 'never',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.application_type}
                </div>
            ),
        },
        {
            field: 'persona',
            title: 'Persona',
            type: 'string',
            render: rowData => {
                const personas = Array.isArray(rowData.persona)
                    ? rowData.persona.map(persona =>
                        persona.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                    )
                    : rowData.persona.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                return (
                    <div
                        onClick={() => viewAttribute(rowData.ID)} // Assuming this ID is for security_role
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        {Array.isArray(rowData.persona) ? personas.join(', ') : personas}
                    </div>
                );
            },
        },
        {
            field: 'reporting_category',
            title: 'Reporting Category',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.reporting_category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </div>
            ),
        },
        {
            field: 'report_type',
            title: 'Report Type',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer' }}
                >
                    {item.report_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </div>
            ),
        },
        // {
        //     field: 'report_details',
        //     title: 'Report Details',
        //     type: 'string',
        //     render: rowData => {
        //         const roles = Array.isArray(rowData.report_details) ? rowData.report_details.join(', ') : rowData.report_details;
        //         return (
        //             <div
        //                 onClick={() => viewAttribute(rowData.ID)} // Assuming this ID is for security_role
        //                 style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
        //             >
        //                 {roles}
        //             </div>
        //         );
        //     },
        // },
        {
            field: 'report_details',
            title: 'Report Details',
            type: 'string',
            render: rowData => {
                // Extracting the first element of the array
                const firstElement = rowData.report_details[0];

                // Extracting the first two words of the first element
                const firstTwoWords = firstElement.split(' ').slice(0, 2).join(' ');

                // Adding ellipsis
                const displayText = firstTwoWords + '.......';

                return (
                    <div
                        onClick={() => viewAttribute(rowData.ID)} // Assuming this ID is for security_role
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        {displayText}
                    </div>
                );
            },
        },




        {
            field: 'action',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editAttribute(rowData.ID)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleClickOpen(rowData.ID)}
                        size="large">
                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>

        },
    ];

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Process Reporting Designer </Typography>
                    <div>

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={() => history.push('/reporting-by-function/add-reporting')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                    </div>

                </div>
                {attributeData && attributeData.length > 0 ?
                    <Table columns={columns} dataRows={attributeData} />
                    :
                    <div>
                        There is no data to be shown
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        analysisData: state.addMultipleConfigurationData.reportypedataAll,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getReportTypeData: () => dispatch(getReportTypeData()),
        deleteReportTypeData: (id) => dispatch(deleteReportTypeData(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDesignerMain);