import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button, Breadcrumbs
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getIncomingClaimsQueryList,
    incomingClaimDataUpload,deleteIncomingClaimsQuery, incomingClaimEDIUpload
} from '../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import BeatLoader from "react-spinners/BeatLoader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { MessageDialogue } from '../MessageDialogue';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
}));
const SalesInvoiceQueryList = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [errorList, setErrorList] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    useEffect(() => {
        props.getSalesInvoiceQueryList();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.contractTabledataComplete) {
            setContractsTableComplete(props.contractTabledataComplete);
        }
    }, [props.contractTabledataComplete]);
    useEffect(() => {
        if (props.incomingClaimSuccess) {
            setErrorList(props.incomingClaimSuccess);
            if (Object.entries(props.incomingClaimSuccess).length > 0 && props.incomingClaimSuccess.messageLog != null && listOpen)
                setDialog(true)
        }
    }, [props.incomingClaimSuccess]);
    const handleDrop = (event, type) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        if(type=='EDI')
        props.uploadEDI(data)
        else
        props.uploadSalesData(data, currentEditableID);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
        setListOpen(true)
    }
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('incoming-claims-upload', JSON.stringify(item))
        history.push('/supplier-incoming-claims-upload/edit-claims-query/' + item.id);
    }
    function viewQuery(item) {
        localStorage.setItem('incoming-claims-upload', JSON.stringify(item))
        history.push('/supplier-incoming-claims-upload/view-claims/' + item.id);
    }
    function handleError() {
        setDialog(false)
        setListOpen(false)
    }
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/supplier-incoming-claims'
                >
                    Incoming Claims
                </Link>
                <Typography color="textPrimary" variant='h4'>Incoming Claims Upload</Typography>
            </Breadcrumbs>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h2"> </Typography>
                <div>
                    <input type='file'
                        accept=".xlsx, .xls, .csv"
                        id='file' ref={inputFile} style={{ display: 'none' }}
                        onChange={(e) => handleDrop(e,'EDI')} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                        }}
                        onClick={() => inputFile.current.click()}
                    >
                        {isDesktop ? 'EDI Upload' : ''}
                    </Button> <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxOutlinedIcon />}
                        classes={{
                            startIcon: !isDesktop && classes.startIcon,
                            root: !isDesktop && classes.container
                        }}
                        onClick={() => history.push('/supplier-incoming-claims-upload/add-claim')}
                    >
                        {isDesktop ? 'Add Claim' : ''}
                    </Button>
                </div>
            </div>
            {props.queryListData && props.queryListData.length > 0 ?
                <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow >
                                <TableCell align='center'>Format ID</TableCell>
                                <TableCell align='center'>Format</TableCell>
                                <TableCell align='center'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {props.queryListData.map(item => {
                                return (
                                    <StyledTableRow key={item.id} >
                                        <TableCell align='center' style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                        <TableCell align='center' style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                        {props.loading ?
                                            <TableCell width={300} align='center'>
                                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                            </TableCell>
                                            :
                                            <TableCell align='center' width={300}>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editQuery(item)}
                                                    size="large">
                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => viewQuery(item)}
                                                    size="large">
                                                    <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <input type='file'
                                                    accept=".xlsx, .xls, .csv"
                                                    id='file' ref={inputFile} style={{ display: 'none' }}
                                                    onChange={(e) => handleDrop(e, item)} />
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => runQuery(item)}
                                                    size="large">
                                                    <PublishIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => props.onDelete(item.id)}
                                                    size="large">
                                                    <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography variant='h4'>
                    No data to display.
                </Typography>
            }
        {dialog&&
               <MessageDialogue  open={dialog} messageList={errorList} onChange={handleError}/>
             }
        </div >
    );
};
const mapStateToProps = state => {
    return {
        queryListData: state.dataSetupData.incomingClaimsAllData,
        loading: state.dataSetupData.loading,
        incomingClaimSuccess:state.dataSetupData.incomingClaimSuccess,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getSalesInvoiceQueryList: () => dispatch(getIncomingClaimsQueryList()),
        uploadSalesData: (file, currentEditableID) => dispatch(incomingClaimDataUpload(file, currentEditableID)),
        onDelete: (id) => dispatch(deleteIncomingClaimsQuery(id)),
        uploadEDI: (file) => dispatch(incomingClaimEDIUpload(file)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceQueryList);