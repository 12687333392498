export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const PROMOTION_DATA_SUCCESS = "PROMOTION_DATA_SUCCESS"
export const PROMO_FINANCE_COST_DATA_SUCCESS = "PROMO_FINANCE_COST_DATA_SUCCESS"
export const PROMO_TERRITORY_DATA_SUCCESS = "PROMO_TERRITORY_DATA_SUCCESS"
export const PROMO_TERRITORY_FORMAT_SUCCESS = "PROMO_TERRITORY_FORMAT_SUCCESS"
export const PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS = "PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS"
export const PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS = "PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS"
export const PROMO_TERRITORY_UPLOAD_SUCCESS = "PROMO_TERRITORY_UPLOAD_SUCCESS"
export const PROMOTION_SETUP_SUCCESS = "PROMOTION_SETUP_SUCCESS"
export const PROMOTION_SETUP_BY_ID = "PROMOTION_SETUP_BY_ID"
export const PROMOTION_MATERIAL_SUCCESS = "PROMOTION_MATERIAL_SUCCESS"
export const PROMO_FINANCE_COST_DETAIL_SUCCESS = "PROMO_FINANCE_COST_DETAIL_SUCCESS"
export const FINANCE_COST_FORMAT_SUCCESS = "FINANCE_COST_FORMAT_SUCCESS"
export const FINANCE_COST_FORMAT_BY_ID_SUCCESS = "FINANCE_COST_FORMAT_BY_ID_SUCCESS"
export const FINANCE_COST_FORMAT_FIELDS_SUCCESS = "FINANCE_COST_FORMAT_FIELDS_SUCCESS"
export const PROMO_FINANCE_COST_UPLOAD_SUCCESS = "PROMO_FINANCE_COST_UPLOAD_SUCCESS"
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS"
export const POSTING_FAILED = "POSTING_FAILED"
export const GLOBAL_SETUP_BY_ID = "GLOBAL_SETUP_BY_ID"
export const GLOBAL_SETUP_SUCCESS = "GLOBAL_SETUP_SUCCESS"
export const PROMO_MAP_SUCCESS = "PROMO_MAP_SUCCESS"
export const PROMO_MAP_SINGLE_SUCCESS = "PROMO_MAP_SINGLE_SUCCESS"
export const PROMO_MAPPING_FORMAT_SUCCESS = "PROMO_MAPPING_FORMAT_SUCCESS"
export const PROMO_MAP_FORMAT_BY_ID = "PROMO_MAP_FORMAT_BY_ID"
export const PROMO_MAP_FORMAT_FIELDS = "PROMO_MAP_FORMAT_FIELDS"
export const PROMO_SIM_FAILURE = "PROMO_SIM_FAILURE"
export const PROMO_TYPES = "PROMO_TYPES"
export const PROMO_STICKERS = "PROMO_STICKERS"
export const PROMO_STICKER_BY_ID = "PROMO_STICKER_BY_ID"
export const MARKET_FILTERS = "MARKET_FILTERS"
export const PROMO_FILTERS = "PROMO_FILTERS"
export const MODEL_GROUP = "MODEL_GROUP"
export const LOCATION_GROUP = "LOCATION_GROUP"
export const MODEL_LIST_GROUP = "MODEL_LIST_GROUP"
