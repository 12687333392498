import React, { useEffect } from 'react';
import {
    Grid,Typography,Breadcrumbs,Link,Card,
    Table,TableCell,TableHead,TableRow,TableContainer,TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {getMembershipContractGroupFormatFields,getMembershipContractGroupFormatDataById,getLblDispDesFieldValue} from '../../redux/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
}));

const MembershipContractGroupViewQuery = props => {
    const classes = useStyles();
    const history = useHistory();
    const [salesQueryData, setSalesQueryData] = React.useState(localStorage.getItem('membership-query') ? JSON.parse(localStorage.getItem('membership-query')) : []);
    const type = history.location.state;
    useEffect(() => {
        props.getMembershipContractGroupFormatFields();
    }, []);

    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>View Format</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.queryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center'>Field Name</TableCell>
                                                        <TableCell align='center' style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)
                                                    
                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name'&& k != 'organization_id' && v>0)
                                                        .sort(function (a, b) {
                                                            return a[1] > b[1] ? 1 : -1;
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow >
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        {v}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getMembershipContractGroupFormatFields: () => dispatch(getMembershipContractGroupFormatFields()),
        getMembershipContractGroupFormatDataById:(id) => dispatch(getMembershipContractGroupFormatDataById(id)),
    }
}

const mapStateToProps = state => {
    return {
        queryListData: state.customerData.membershipContractGroupFormatFieldData,
        configData:state.customerData.membershipContractGroupFormatDataById,
        
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipContractGroupViewQuery);