// import React, { useEffect} from 'react';
// import {
//     Grid,
//     Button,
//     Typography,
//     Breadcrumbs,
//     Card,
//     OutlinedInput,
//     FormLabel,
//     Select,
//     MenuItem
// } from '@mui/material';
// import clsx from 'clsx';
// import { makeStyles} from '@mui/styles';
// import { createQuota, getQuotaSingleItem, getEmployeeData,getDefaultValues ,getCustomerMasterData,getMaterialMasterData,getSupplierMasterData } from '../../../redux/actions';
// import { connect } from 'react-redux';
// import { TextInputWithLabel } from '../../../components/Inputs';
// import Moment from 'moment';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import BeatLoader from "react-spinners/BeatLoader";
// import { Link } from 'react-router-dom';
// import MomentUtils from '@date-io/moment';
// var editableId = '';
// const useStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.secondary.main,
//         marginTop: 10,
//         borderRadius: 10,
//         padding: '27px 29px 27px 29px'
//     },
//     bodyContainer: {
//         backgroundColor: theme.palette.white,
//         border: '1px solid #EEEEEE',
//         borderRadius: 6,
//         marginBottom: 23,
//         marginTop: 25
//     },
//     link: {
//         color: theme.palette.text.primary,
//         opacity: 0.3,
//         textDecoration: 'none',
//         border: 0,
//         fontSize: 16,
//         paddingRight: 10
//     },
//     container: {
//         padding: 10
//     },
//     gridContainer: {
//         padding: '0px 16px 0px 16px'
//     },
//     button: {
//         marginRight: 25,
//         width: 140
//     },
//     input: {
//         padding: 0,
//         height: 21,
//         fontFamily: 'ProximaNova',
//         fontSize: 13
//     },
//     selectRoot: {
//         padding: '0px 0px 15px 0px',
//         display: 'flex',
//         width: '100%',
//         flexDirection: 'column'
//     },
//     select: {
//         width: '100%',
//         marginTop: 10
//     },
//     selectedItem: {
//         color: theme.palette.text.grey,
//         marginTop: 10
//     },
//     inputTwoLine: {
//         marginTop: 10,
//         width: '100%',
//         paddingLeft: 10
//     },
//     formLabel: {
//         height: 50,
//         display: 'flex',
//         alignItems: 'flex-end',
//         fontSize:theme.typography.h3.fontSize
//     },
//     buttonRoot: {
//         display: 'flex',
//         justifyContent: 'center',
//         paddingBottom: 28,
//         paddingTop: 30
//     },
//     fontSetting:{
//         fontSize:theme.typography.h3.fontSize,
//         marginTop:'1.6rem'
//     }
// }));

// const EditProductGroup = props => {
//     const classes = useStyles();
//     const [employeeNumber, setEmployeeNumber] = React.useState('');
//     const [employeeName, setEmployeeName] = React.useState('');
//     const [quotaType, setQuotaType] = React.useState('');
//     const [quotaValue, setQuotaValue] = React.useState('');
//     const [territoryID, setTerritoryID] = React.useState('');
//     const [startDate, setStartDate] = React.useState(null);
//     const [endDate, setEndDate] = React.useState(null);
//     const [sourceDataType, setSourceDataType] = React.useState('');
//     const [quotaLevel, setQuotaLevel] = React.useState('');
//     const [quotaLevelData, setQuotaLevelData] = React.useState([]);
//     const [customerNumber, setCustomerNumber] = React.useState('');
//     const [customerName, setCustomerName] = React.useState('');
//     const [supplierNumber, setSupplierNumber] = React.useState('');
//     const [supplierName, setSupplierName] = React.useState('');
//     const [materialNumber, setMaterialNumber] = React.useState('');
//     const [materialDescription,setMaterialDescription] = React.useState('');
//     const [editID, setEditID] = React.useState(0);
//     const quotaTypeArray = ["Revenue", "Profit", "New Customer"]
//     const sourceDataTypeArray = ["Direct - Sales Data", "Indirect - Sales Data", "All - Sales Data"]

//     useEffect(() => {
//         var pathname = window.location.pathname;
//         var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
//         editableId = appId;
//         setEditID(appId)
//         if (appId)
//             props.quotaSingleItem(appId);
//         props.getDefaultValues('Sales Commission')
//         props.getEmployeeData();
//         props.getCustomerMasterData();
//         props.getMaterialMasterData(1, 10);
//         props.getSupplierMasterData(1, 10);
//     }, []);

//     useEffect(()=>{
//         if(props.quotaLevelData &&props.quotaLevelData.records && props.quotaLevelData.records.length>0 &&props.quotaLevelData.records[0].drop_down_values)
//           setQuotaLevelData(props.quotaLevelData.records[0].drop_down_values)
//     },[props.quotaLevelData])

//     useEffect(() => {

//         if (props.quotaData) {
//             setEditID(props.quotaData.ID)
//             if (props.quotaData.employee_number)
//                 setEmployeeNumber(props.quotaData.employee_number)
//             if (props.quotaData.employee_name)
//                 setEmployeeName(props.quotaData.employee_name)
//             if (props.quotaData.start_date)
//                 setStartDate(props.quotaData.start_date)
//             if (props.quotaData.end_date)
//                 setEndDate(props.quotaData.end_date)
//             if (props.quotaData.quota_type)
//                 setQuotaType(props.quotaData.quota_type)
//             if (props.quotaData.quota_value)
//                 setQuotaValue(props.quotaData.quota_value)
//             if (props.quotaData.territory_id)
//                 setTerritoryID(props.quotaData.territory_id)
//             if (props.quotaData.source_data_type)
//                 setSourceDataType(props.quotaData.source_data_type)
//             if (props.quotaData.quota_level)
//                 setQuotaLevel(props.quotaData.quota_level)
//             if (props.quotaData.customer_number)
//                 setCustomerNumber(props.quotaData.customer_number)
//             if (props.quotaData.customer_name)
//                 setCustomerName(props.quotaData.customer_name)
//             if (props.quotaData.supplier_name)
//                 setSupplierName(props.quotaData.supplier_name)
//             if (props.quotaData.supplier_number)
//                 setSupplierNumber(props.quotaData.supplier_number)
//             if (props.quotaData.material_number)
//                 setMaterialNumber(props.quotaData.material_number)
//             if (props.quotaData.material_description)
//                 setMaterialDescription(props.quotaData.material_description)
//         }
//     }, [props.quotaData])
//     const handleEmployeeNumber = (value) => {
//         setEmployeeNumber(value);
//         handleEmployeeName(value)
//     }
//     const handleCustomerNumber = (value) => {
//         setCustomerNumber(value);
//         handleCustomerName(value)
//     }
//     const handleSupplierNumber = (value) => {
//         setSupplierNumber(value);
//         handleSupplierName(value)
//     }
//     const handleMaterialNumber = (value) => {
//         setMaterialNumber(value);
//         handleMaterialDescription(value)
//     }
//     const handleStartDate = (value) => {
//         setStartDate(value);
//     }
//     const handleEndDate = (value) => {
//         setEndDate(value);
//     }

//     const [userStore, setUserStore] = React.useState([]);
//     function handleSubmit() {

//         var data = {
//             "employee_number": employeeNumber,
//             "employee_name": employeeName,
//             "start_date": startDate ? Moment(startDate).local().format('YYYY-MM-DD') : '',
//             "end_date": endDate ? Moment(endDate).local().format('YYYY-MM-DD') : '',
//             "quota_type": quotaType,
//             "quota_value": parseFloat(quotaValue),
//             "territory_id": territoryID,
//             "source_data_type": sourceDataType,
//             "quota_level": quotaLevel,
//             "customer_number":customerNumber,
//             "customer_name":customerName,
//             "supplier_name":supplierName,
//             "supplier_number":supplierNumber,
//             "material_number":materialNumber,
//             "material_description":materialDescription
//         };

//         props.onSubmitEdit(data, editID)
//         handleClear();
//     }
//     function handleClear() {
//         setEmployeeName('')
//         setEmployeeNumber('')
//         setQuotaType('')
//         setQuotaValue('')
//         setTerritoryID('')
//         setStartDate(null)
//         setEndDate(null)
//         setSourceDataType('')
//         setCustomerNumber('')
//         setCustomerName('')
//         setMaterialNumber('')
//         setMaterialDescription('')
//         setSupplierNumber('')
//         setSupplierName('')
//     }

//     function handleClearAll() {
//         handleClear();
//         setUserStore([]);
//     }
//     function handleEmployeeName(id) {
//         let data;
//         if (props.employeeMasterData && props.employeeMasterData.total_record > 0) {
//             data = props.employeeMasterData.records.filter((item, index) => item.employee_number == id)
//             setEmployeeName(data[0].employee_name)
//         }
//     }
//     function handleCustomerName(id) {
//         let data;
//         if (props.customerMasterData && props.customerMasterData.records&& props.customerMasterData.records.length > 0) {
//             data = props.customerMasterData.records.filter((item, index) => item.customer_number == id)
//             setCustomerName(data[0].customer_name)
//         }
//     }
//     function handleMaterialDescription(id) {
//         let data;
//         if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
//             data = props.materialMasterData.records.filter((item, index) => item.material_number == id)
//             setMaterialDescription(data[0].material_description)
//         }
//     }
//     function handleSupplierName(id) {
//         let data;
//         if (props.supplierMasterData && props.supplierMasterData.records&& props.supplierMasterData.records.length > 0) {
//             data = props.supplierMasterData.records.filter((item, index) => item.supplier_number == id)
//             setSupplierName(data[0].supplier_name)
//         }
//     }


//     return (
//         <div className={classes.root}>
//             <Breadcrumbs aria-label="breadcrumb">
//                 <Link variant='h4' classes={{
//                     root: classes.link
//                 }}
//                     to='/quota-management'
//                 >
//                     Quota Management
//                 </Link>
//                 <Typography color="textPrimary" variant='h4'>Edit Quota </Typography>
//             </Breadcrumbs>

//             <div className={classes.bodyContainer}>
//                 <Card>
//                     <form
//                         autoComplete="off"
//                         noValidate
//                     >
//                         <div className={classes.container}>
//                             <Grid container spacing={2}>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     className={classes.gridContainer}
//                                 >
//                                     <div style={{ marginTop: '-0.6rem',display: 'flex', flexDirection: 'column' }}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Quota Level
//                                         </FormLabel>
//                                         <Select
//                                             value={quotaLevel}
//                                             onChange={(event) => setQuotaLevel(event.target.value)}
//                                             className={clsx({
//                                                 [classes.select]: true
//                                             })}
//                                             classes={{
//                                                 selectMenu: classes.selectedItem
//                                             }}
//                                         > {quotaLevelData
//                                             .map(item => {
//                                                 return (
//                                                     <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
//                                                         {item}
//                                                     </MenuItem>
//                                                 )
//                                             })}
//                                         </Select>
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Employee Number' ? 'block' : 'none' }}
//                                 >
//                                     <div>
//                                         <TextInputWithLabel className={classes.fontSetting} heading={'Employee Number'} twoline='true' data={employeeNumber} onChange={handleEmployeeNumber} required={territoryID && supplierNumber && materialNumber&& customerNumber ? false : true} prevalue = { props.quotaData?.employee_number ? props.quotaData.employee_number:''} />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Employee Number' ? 'block' : 'none' }}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Employee Name
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             readOnly
//                                             value={employeeName}
//                                             classes={{ root: classes.inputTwoLine }} 
//                                         />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Territory ID' ? 'block' : 'none' }}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel} required={employeeNumber && customerNumber && materialNumber && supplierNumber ? false : true}>
//                                             Territory ID
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             value={territoryID}
//                                             classes={{ root: classes.inputTwoLine }}
//                                             onChange={(e) => setTerritoryID(e.target.value)} />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Customer Number' ? 'block' : 'none' }}
//                                 >
//                                     <div>
//                                         <TextInputWithLabel className={classes.fontSetting} heading={'Customer Number'} twoline='true' data={customerNumber} onChange={handleCustomerNumber} required={territoryID && supplierNumber && materialNumber && employeeNumber ? false : true} prevalue = { props.quotaData?.customer_number ? props.quotaData.customer_number:''} />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Customer Number' ? 'block' : 'none' }}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Customer Name
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             readOnly
//                                             value={customerName}
//                                             classes={{ root: classes.inputTwoLine }} 
//                                         />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Supplier Number' ? 'block' : 'none' }}
//                                 >
//                                     <div>
//                                         <TextInputWithLabel className={classes.fontSetting} heading={'Supplier Number'} twoline='true' data={supplierNumber} onChange={handleSupplierNumber} required={territoryID && customerNumber && materialNumber && employeeNumber ? false : true} prevalue = { props.quotaData?.supplier_number ? props.quotaData.supplier_number:''} />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Supplier Number' ? 'block' : 'none' }}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Supplier Name
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             readOnly
//                                             value={supplierName}
//                                             classes={{ root: classes.inputTwoLine }} 
//                                         />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Material Number' ? 'block' : 'none' }}
//                                 >
//                                     <div>
//                                         <TextInputWithLabel className={classes.fontSetting} heading={'Material Number'} twoline='true' data={materialNumber} onChange={handleMaterialNumber} required={territoryID && customerNumber && supplierNumber && employeeNumber ? false : true} prevalue = { props.quotaData?.material_number ? props.quotaData.material_number:''} />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                     style={{ display: quotaLevel == 'Material Number' ? 'block' : 'none' }}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Material Description
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             readOnly
//                                             value={materialDescription}
//                                             classes={{ root: classes.inputTwoLine }} 
//                                         />
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel} required>
//                                             Quota Type
//                                         </FormLabel>
//                                         <Select
//                                             value={quotaType}
//                                             onChange={(e) => setQuotaType(e.target.value)}
//                                             style={{ marginTop: 0 }}
//                                             displayEmpty
//                                             className={clsx({
//                                                 [classes.select]: true
//                                             })}
//                                             classes={{
//                                                 selectMenu: classes.selectedItem
//                                             }}
//                                         >
//                                             <MenuItem value="">
//                                             </MenuItem>
//                                             {quotaTypeArray
//                                                 .map(item => {
//                                                     return (
//                                                         <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
//                                                             {item}
//                                                         </MenuItem>
//                                                     )
//                                                 })}
//                                         </Select>
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel} required>
//                                             Quota Value
//                                         </FormLabel>
//                                         <OutlinedInput
//                                             value={quotaValue}
//                                             classes={{ root: classes.inputTwoLine }}
//                                             onChange={(e) => setQuotaValue(e.target.value)} />
//                                     </div>
//                                 </Grid>

//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel}>
//                                             Source Data Type
//                                         </FormLabel>
//                                         <Select
//                                             value={sourceDataType}
//                                             onChange={(e) => setSourceDataType(e.target.value)}
//                                             style={{ marginTop: 0 }}
//                                             displayEmpty
//                                             className={clsx({
//                                                 [classes.select]: true
//                                             })}
//                                             classes={{
//                                                 selectMenu: classes.selectedItem
//                                             }}
//                                         >
//                                             <MenuItem value="">
//                                             </MenuItem>
//                                             {sourceDataTypeArray
//                                                 .map(item => {
//                                                     return (
//                                                         <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
//                                                             {item}
//                                                         </MenuItem>
//                                                     )
//                                                 })}
//                                         </Select>
//                                     </div>
//                                 </Grid>

//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel} required>
//                                             Start Date
//                                         </FormLabel>
//                                         <Grid container justify="space-around">
//                                             <MuiPickersUtilsProvider utils={MomentUtils}>
//                                                 <KeyboardDatePicker
//                                                     disableToolbar
//                                                     clearable
//                                                     InputProps={{
//                                                         padding: 0,
//                                                         disableUnderline: true,
//                                                         style: {
//                                                             padding: '1px 0 1px 11px',
//                                                             alignSelf: 'center',
//                                                             alignItems: 'center',
//                                                             marginTop: 10,
//                                                             border: '1px solid #E0E0E0',
//                                                             width: '100%'
//                                                         }
//                                                     }}
//                                                     value={startDate}
//                                                     onChange={date => handleStartDate(date)}
//                                                     format="MM/DD/YYYY"
//                                                 />
//                                             </MuiPickersUtilsProvider>

//                                         </Grid>
//                                     </div>
//                                 </Grid>
//                                 <Grid
//                                     item
//                                     md={3}
//                                     xs={12}
//                                 >
//                                     <div className={classes.selectRoot}>
//                                         <FormLabel className={classes.formLabel} required>
//                                             End Date
//                                         </FormLabel>
//                                         <Grid container justify="space-around">
//                                             <MuiPickersUtilsProvider utils={MomentUtils}>
//                                                 <KeyboardDatePicker
//                                                     disableToolbar
//                                                     clearable
//                                                     InputProps={{
//                                                         padding: 0,
//                                                         disableUnderline: true,
//                                                         style: {
//                                                             padding: '1px 0 1px 11px',
//                                                             alignSelf: 'center',
//                                                             alignItems: 'center',
//                                                             marginTop: 10,
//                                                             border: '1px solid #E0E0E0',
//                                                             width: '100%'
//                                                         }
//                                                     }}
//                                                     value={endDate}
//                                                     onChange={date => handleEndDate(date)}
//                                                     minDate={startDate ? startDate : ''}
//                                                     format="MM/DD/YYYY"
//                                                 />
//                                             </MuiPickersUtilsProvider>
//                                         </Grid>
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </div>
//                     </form>
//                 </Card>
//                 <div className={classes.buttonRoot}>
//                     <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
//                         Clear
//                     </Button>
//                     <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
//                          disabled={quotaType && quotaValue && startDate && endDate && (territoryID || employeeNumber||supplierNumber || materialNumber || customerNumber) ? false : true}
//                     >
//                         {props.loadingAPI ?
//                             <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
//                             :
//                             'Save'
//                         }
//                     </Button>
//                 </div>
//             </div >
//         </div>
//     );
// };
// const mapStateToProps = state => {
//     return {
//         quotaData: state.quotaManagementData.quotaSingleData,
//         employeeMasterData: state.customerData.employeeMasterData,
//         quotaLevelData: state.addMultipleConfigurationData.quotaLevelData,
//         customerMasterData: state.customerData.customerMasterData,
//         materialMasterData: state.customerData.materialMasterData,
//         supplierMasterData: state.customerData.supplierMasterData,
//     }
// };
// const mapDispatchToProps = dispatch => {
//     return {
//         quotaSingleItem: (id) => dispatch(getQuotaSingleItem(id)),
//         onSubmitEdit: (data, editID) => dispatch(createQuota(data, 'edit', editID)),
//         getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
//         getDefaultValues: (applicationType) => dispatch(getDefaultValues(applicationType, null, 'on', 'quota_level')),
//         getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData(pagination, limit)),
//         getMaterialMasterData: (pagination, limit) => dispatch(getMaterialMasterData('NoLimit', limit)),
//         getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),

//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(EditProductGroup);

import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { createQuota, getQuotaSingleItem, getLblDispDesFieldValue } from '../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import BeatLoader from "react-spinners/BeatLoader";
import MasterDataComponent from '../../../components/Inputs/FilledComponents/MasterDataComponent';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import LoadingOverlay from "react-loading-overlay";
import { HashLoader } from 'react-spinners';
import { Datepicker } from '../../../components/Inputs'
var editableId = '';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 4,
        height: 37,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        marginTop: '1.6rem'
    }
}));

const EditProductGroup = props => {
    const classes = useStyles();
    const [editID, setEditID] = React.useState(0);
    const [externalFields, setExternalFields] = React.useState([]);
    const [externalData, setExternalData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);

    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        editableId = appId;
        setEditID(appId)
        if (appId)
            props.quotaSingleItem(appId);
        var data = { "data_source": "quota_data" };
        props.loadSourceDataType(data, 'post');
    }, []);

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_details && item.display)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])
    useEffect(() => {
        if (props.quotaData) {
            setEditID(props.quotaData.ID)
            setExternalData(props.quotaData)
        }
    }, [props.quotaData])

    function handleSubmit() {
        if (externalData['start_date'])
            externalData['start_date'] = Moment.utc(externalData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (externalData['end_date'])
            externalData['end_date'] = Moment.utc(externalData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        props.onSubmitEdit(externalData, editID)
        handleClearAll();
    }

    function handleClearAll() {
        setExternalData({})
    }
    const handleExternalData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value.key
        }
        else {
            if (key == 'quota_value' && value)
                value = parseFloat(value)
        }
        setExternalData({ ...externalData, [key]: value })
    }
    return (
        <LoadingOverlay
            active={props.loadingAPI}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: "50px",
                    "& svg circle": {
                        stroke: "#64C4B4",
                    },
                }),
                overlay: (base) => ({
                    ...base,
                    background: "rgba(52, 52, 52, 0)",
                }),
                content: (base) => ({
                    ...base,
                    color: "#64C4B4",
                }),
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link variant='h4' classes={{
                        root: classes.link
                    }}
                        to='/quota-management'
                    >
                        Quota Management
                    </Link>
                    <Typography color="textPrimary" variant='h4'>Edit Quota </Typography>
                </Breadcrumbs>

                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >

                            <div className={classes.container}>
                                <Grid container spacing={2}>
                                    {externalFields && externalFields.length > 0 &&
                                        externalFields.map((item, index) => (

                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel} required={requiredField.includes(item.current)}>
                                                        {item.current}
                                                    </FormLabel>
                                                    {item.key == 'start_date' || item.key == 'end_date' ?
                                                        <Datepicker
                                                            twoline='true' {...props} onChange={date => handleExternalData(date, item.key)}
                                                            minimum={item.key == 'end_date' && externalData['start_date'] ? externalData['start_date'] : ''}
                                                            prevalue={externalData[item.key] ? Moment(externalData[item.key]) : null} />

                                                        :
                                                        item.drop_down_values && item.drop_down_values.length > 0 ?
                                                            <Select
                                                                value={externalData[item.key] ? externalData[item.key] : ''}
                                                                onChange={(e) => handleExternalData(e.target.value, item.key)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                style={{ marginTop: 4 }}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >  <MenuItem></MenuItem>
                                                                {item.drop_down_values.map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                            :
                                                            item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []}
                                                                    onChange={(value) => handleExternalData(value, item.key, 'reference')}
                                                                    prevalue={{ 'key': externalData[item.key] ? externalData[item.key] : '', 'desc': '' }}
                                                                />
                                                                :
                                                                <OutlinedInput
                                                                    value={externalData[item.key] ? externalData[item.key] : ''}
                                                                    type={item.key == 'formula_result' ? 'Number' : 'String'}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => handleExternalData(e.target.value, item.key)} />
                                                    }
                                                </div>
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                            disabled={Object.entries(externalData).filter(([k, v]) => allowedField.includes(k) && v).length == allowedField.length ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                    </div>
                </div >
            </div>
        </LoadingOverlay>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.addMultipleConfigurationData.loading,
        quotaData: state.quotaManagementData.quotaSingleData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        quotaSingleItem: (id) => dispatch(getQuotaSingleItem(id)),
        onSubmitEdit: (data, editID) => dispatch(createQuota(data, 'edit', editID)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProductGroup);