import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import { connect } from 'react-redux';
import { getContractDetailsData, getDefaultValuesAllDataNew } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 30px 0px 30px'
    },
    gridStyle: {
        paddingLeft: 16,
        paddingRight: 16
    },
}));

const Payment = forwardRef((props, ref) => {
    const contractCustomisationUpdation = useRef();

    const [paymentFrequencyData, setPaymentFrequencyData] = React.useState([]);
    const [paymentFrequencyName, setPaymentFrequencyName] = React.useState('');
    const [paymentLevelData, setPaymentLevelData] = React.useState([]);
    const [paymentLevelName, setPaymentLevelName] = React.useState('');
    const [paymentMethodData, setPaymentMethodData] = React.useState([]);
    const [paymentMethodName, setPaymentMethodName] = React.useState('');
    const [paymentPartnerRoleData, setPaymentPartnerRoleData] = React.useState([]);
    const [paymentPartnerRoleName, setPaymentPartnerRoleName] = React.useState('');

    const [paymentCalendarArray, setPaymentCalendarArray] = React.useState([]);
    const [paymentTermsArray, setPaymentTermsArray] = React.useState([]);
    const [paymentPartnerArray, setPaymentPartnerArray] = React.useState([]);
    const [externalReferenceArray, setExternalReferenceArray] = React.useState([]);
    const [externalReference, setExternalReference] = React.useState('');
    const [flexFields5, setFlexFields5] = React.useState('');
    const [flexFields5Array, setFlexFields5Array] = React.useState([]);
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const [paymentDocumentType, setPaymentDocumentType] = React.useState('');
    const [paymentRun, setPyamentRun] = React.useState('');
    useEffect(() => {
        var appType = sessionStorage.getItem('application');
    }, [])


    const classes = useStyles();
    const { className } = props;
    const [paymentFrequency, setPaymentFrequency] = React.useState('');
    const [paymentLevel, setPaymentLevel] = React.useState('');
    const [paymentTerms, setPaymentTerms] = React.useState('');
    const [paymentCalendar, setPaymentCalendar] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerRole, setPaymentPartnerRole] = React.useState('');
    const [paymentPartner, setPaymentPartner] = React.useState('');
    const paymentFrequencyRef = useRef(null);
    const paymentLevelRef = useRef(null);
    const paymentTermsRef = useRef(null);
    const paymentCalenderRef = useRef(null);
    const paymentMethodRef = useRef(null);
    const paymentPartnerRoleRef = useRef(null);
    const paymentPartnerRef = useRef(null);
    var mode = localStorage.getItem('mode');
    var paymentValues = {
        payment_frequency: paymentFrequency,
        payment_level: paymentLevel,
        payment_terms: paymentTerms,
        payment_calendar: paymentCalendar,
        payment_method: paymentMethod,
        payment_partner_role: paymentPartnerRole,
        payment_partner: paymentPartner,
        external_reference: externalReference,
        flex_fields_5: flexFields5,
        payment_document_type: paymentDocumentType,
        payment_run: paymentRun
    };
    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (mode === 'edit')
                props.getcontractdetails(localStorage.getItem('currentAddContractID'));
            if (!paymentFrequencyRef.current.value || paymentFrequencyRef.current.value === '')
                setPaymentFrequency(false);
            if (!paymentLevelRef.current.value || paymentLevelRef.current.value === '')
                setPaymentLevel(false);
            if (!paymentTermsRef.current.value || paymentTermsRef.current.value === '')
                setPaymentTerms(false);
            if (!paymentCalenderRef.current.value || paymentCalenderRef.current.value === '')
                setPaymentCalendar(false);
            if (!paymentMethodRef.current.value || paymentMethodRef.current.value === '')
                setPaymentMethod(false);
            if (!paymentPartnerRoleRef.current.value || paymentPartnerRoleRef.current.value === '')
                setPaymentPartnerRole(false);
            if (!paymentPartnerRef.current.value || paymentPartnerRef.current.value === '')
                setPaymentPartner(false);
        },
        getValues() {
            paymentValues = {
                payment_frequency: paymentFrequencyRef.current.value,
                payment_level: paymentLevelRef.current.value,
                payment_terms: paymentTermsRef.current.value,
                payment_calendar: paymentCalenderRef.current.value,
                payment_method: paymentMethodRef.current.value,
                payment_partner_role: paymentPartnerRoleRef.current.value,
                payment_partner: paymentPartnerRef.current.value
            };
            return paymentValues;
        }
    }));

    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])


    return (
        <div className={classes.root}>
            <Card>
                <CardHeader
                    title="PAYMENT"
                    titleTypographyProps={{ variant: 'h3' }}
                />
                {props.data && props.fieldData &&
                    <div className={classes.container}>
                        <Grid container className={classes.gridContainer}>
                            {props.data && props.data.payment_level && props.fieldData.payment_level &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_level.current} data={props.data.payment_level} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_level && !(props.data.payment_level === 'Single Payment') && props.fieldData.payment_aggregation_level &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_aggregation_level.current} data={props.data.payment_aggregation_level} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_partner_role && (props.data.payment_level === 'Single Payment') && props.fieldData.payment_partner_role &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_partner_role.current} data={props.data.payment_partner_role} twoline='true' />
                                </Grid>
                            }

                            {props.data && props.data.payment_partner && (props.data.payment_level === 'Single Payment') && props.fieldData.payment_partner &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_partner.current} data={props.data.payment_partner} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_frequency && props.fieldData.payment_frequency &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_frequency.current} data={props.data.payment_frequency} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_calendar && props.fieldData.payment_calendar &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_calendar.current} data={props.data.payment_calendar} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_method && props.fieldData.payment_method &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_method.current} data={props.data.payment_method} twoline='true' />
                                </Grid>
                            }


                            {props.data && props.data.payment_terms && props.fieldData.payment_terms &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_terms.current} data={props.data.payment_terms} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_due_date && props.fieldData.payment_due_date &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_due_date.current} data={props.data.payment_due_date} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.external_reference && props.fieldData.external_reference &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.external_reference.current} data={props.data.external_reference} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.flex_fields_5 && props.fieldData.flex_fields_5 &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.flex_fields_5.current} data={props.data.flex_fields_5} twoline='true' />
                                </Grid>
                            }
                            {props.data.pass_through && props.fieldData.pass_through &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through.current} data={props.data.pass_through} twoline='true' />
                                </Grid>
                            }
                            {(props.data.pass_through_percentage && props.data.pass_through_percentage != 0 &&
                                props.data.pass_through_percentage !== '0')
                                && props.fieldData.pass_through_percentage ?
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through_percentage.current} data={props.data.pass_through_percentage} twoline='true' />
                                </Grid>
                                : ''
                            }
                            {props.data.pass_through_payment_partner_role && props.fieldData.pass_through_payment_partner_role &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.pass_through_payment_partner_role.current} data={props.data.pass_through_payment_partner_role} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_document_type && props.fieldData.payment_document_type &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_document_type.current} data={props.data.payment_document_type} twoline='true' />
                                </Grid>
                            }
                            {props.data && props.data.payment_run && props.fieldData.payment_run &&
                                <Grid
                                    item
                                    md={2.4}
                                    xs={6}
                                >
                                    <LabelText heading={props.fieldData.payment_run.current} data={props.data.payment_run} twoline='true' />
                                </Grid>
                            }
                        </Grid>
                    </div>
                }
            </Card>
        </div>
    );

});

const mapStateToProps = state => ({
    //data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Payment);