import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button, Tooltip, Fab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    getExternalFormulaFormatData,
    deleteExternalFormulaFormatData,
    uploadExternalFormulaFile
} from '../../redux/actions';
import { LibraryAdd } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px',
        height: '100%'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    spinner: {
        height: '100vh'
    }
}));
const ExternalFormulaResultsExcelUpload = props => {

    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [quota, setQuota] = useState([]);
    useEffect(() => {
        props.getExternalFormulaFormatData();
    }, []);
    const handleDrop = (event, id) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadExternalFormulaFile(id, data);
    }
    useEffect(() => {
        props.getExternalFormulaFormatData();
        localStorage.removeItem('dynamic_filter_fields');
    }, []);
    useEffect(() => {
        if (props.quotaFormatData) {
            setQuota(props.quotaFormatData)
        }
    }, [props.quotaFormatData]);
    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        history.push({
            pathname: '/external-formula-results/external-formula-results-excel-upload/edit/' + item.id,
            format: item
        });

    }
    function viewQuery(item) {
        history.push({
            pathname: '/external-formula-results/external-formula-results-excel-upload/view/' + item.id,
            format: item
        });
    }
    function deleteQuery(item) {
        props.deleteExternalFormulaFormatData(item.id);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<HashLoader />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary'>
                        External Formula Results Excel Upload</Typography>
                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        onClick={() => {
                            history.push('/external-formula-results/external-formula-results-excel-upload/add-query')
                        }}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add Format</Typography>
                    </Fab>
                </div>
                {
                    quota && quota.length > 0 ?
                        <TableContainer style={{ marginTop: -10 }} >
                            <Table>
                                <TableHead >
                                    <TableRow >
                                        <TableCell align='center' className={classes.fontSetting} >Format ID</TableCell>
                                        <TableCell align='center' className={classes.fontSetting} >Format</TableCell>
                                        <TableCell align='center' className={classes.fontSetting} >Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody classes={{ root: classes.table }}>
                                    {quota
                                        .map(item => {
                                            return (
                                                <StyledTableRow key={item.id} >
                                                    <TableCell align='center' className={classes.fontSetting} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                    <TableCell align='center' className={classes.fontSetting} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                    {props.loading ?
                                                        <TableCell width={300} align='center'>
                                                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                        </TableCell>
                                                        :
                                                        <TableCell align='center' width={300}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => editQuery(item)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => viewQuery(item)}
                                                                size="large">
                                                                <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <input type='file'
                                                                accept=".xlsx, .xls, .csv"
                                                                id='file' ref={inputFile} style={{ display: 'none' }}
                                                                onChange={(e) => handleDrop(e, item.id)} />
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => runQuery(item)}
                                                                size="large">
                                                                <PublishIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => deleteQuery(item)}
                                                                size="large">
                                                                <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                            </IconButton>
                                                        </TableCell>
                                                    }
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :

                        <Typography variant='h4' color='textPrimary' style={{ marginLeft: 16, marginTop: 16 }}>
                            No  query list added.
                        </Typography>
                }
            </div >
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        quotaFormatData: state.externalFormulaResultsData.externalFormulaFormatData,
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadExternalFormulaFile: (id, file) => dispatch(uploadExternalFormulaFile(id, file)),
        deleteExternalFormulaFormatData: (id) => dispatch(deleteExternalFormulaFormatData(id)),
        getExternalFormulaFormatData: () => dispatch(getExternalFormulaFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFormulaResultsExcelUpload);