import React, { useEffect } from "react";
import "../../library/scss/rsuite-default.css";
import { makeStyles, withStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  OutlinedInput,
  Select,
  Button,
  FormLabel,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell, TextField
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  loadRebateTrendsSecondAPI,
  loadSalesVsPaymentGraph,
  loadAccrualVarianceGraph,
  loadValuesBasedOnAppTypeAndField,
  allowedApps,
  getDefaultValuesAllDataNew, getLblDispDesFieldValue,
  dashboardAnalyticsNames
} from "../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import { endOfMonth, subMonths, startOfMonth } from "date-fns";
import clsx from "clsx";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: "5px 0px 16px 0px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },

  selectedItem: {
    borderRadius: 5,
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 34,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
const RebateTrends = (props) => {
  const classes = useStyles();
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.table.row,
        height: 46,
      },
      "&:nth-of-type(even)": {
        height: 40,
      },
    },
  }))(TableRow);
  const [startDate, setStartDate] = React.useState(null);
  const [startDatee, setStartDatee] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [postingType, setPostingType] = React.useState("accrual");
  const [accrualFilter, setAccrualFilter] = React.useState();
  const [accrualFilterEnd, setAccrualFilterEnd] = React.useState();
  const options = ["=", '>', '<', '>=', '<='];
  const [varienceType, setVarienceType] = React.useState();
  const [dateRangeValue, setDateRangeValue] = React.useState([
    new Date("2020-06-01T05:30:00"),
    new Date("2020-10-01T05:30:00"),
  ]);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState("Direct - Sales Data");

  useEffect(() => {
    props.allowedAnalyticsApps(sessionStorage.getItem("application"));
  }, []);

  useEffect(() => {
    if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
      const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
        item.analytic_name === "Postings Variance by Attribute"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedAnalyticsAppsData])



  const handlePostingType = (e) => {
    setPostingType(e.target.value);
  };

  // useEffect(() => {
  //   setDateRangeValue([
  //     startOfMonth(subMonths(new Date(), 2)),
  //     endOfMonth(subMonths(new Date(), 1)),
  //   ]);
  //   setStartDate(Moment().subtract(2, "months").startOf("month"));
  //   setEndDate(Moment().subtract(1, "months").endOf("month"));
  // }, []);

  useEffect(() => {
    props.allowedApps();
    props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
  }, []);

  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
      setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy))
      setSourceDataType(allowedTilesFilter[0].data_source_type)
      setPostingType(allowedTilesFilter[0].posting_type)
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (props.page !== "dashboard" && postingType && sourceDataType && startDate && endDate) {
      props.loadAccrualVarianceGraph(
        Moment(startDate).format("MM/DD/YYYY"),
        Moment(endDate).format("MM/DD/YYYY"),
        postingType,
        sessionStorage.getItem("application"),
        sourceDataType,
        groupBy
      );
    }
  }, [allowedTilesFilter]);

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }



  const handleApplicationtype = (e) => {
    setSourceDataType("");
    setSourceDataTypeDataFromAPI([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    setApplicationType(e.target.value);
  };

  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
  };

  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);


  const handleTrendsAPI = (id) => {
    var data = { "data_source": sourceDataType }
    props.getLblDispDesFieldValue(data, 'post');
    props.loadRebateTrendsAPI(
      Moment(startDate).subtract(4, "months").startOf("month").format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      id
    );
  };

  const [groupBy, setGroupBy] = React.useState('contract_number');

  const handleGroupBy = (e) => {
    setGroupBy(e.target.value);
  }

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleVarience = (e) => {
    setVarienceType(e.target.value);
  }

  const filterData = (item) => {
    if (varienceType === "=") {
      return (
        item.tier_basis_variance == accrualFilterEnd ||
        item.incentive_basis_variance == accrualFilterEnd ||
        item.incent_amount_variance == accrualFilterEnd
      );
    } else if (varienceType === ">") {
      return (
        item.tier_basis_variance > accrualFilterEnd ||
        item.incentive_basis_variance > accrualFilterEnd ||
        item.incent_amount_variance > accrualFilterEnd
      );
    } else if (varienceType === "<") {
      return (
        item.tier_basis_variance < accrualFilterEnd ||
        item.incentive_basis_variance < accrualFilterEnd ||
        item.incent_amount_variance < accrualFilterEnd
      );
    } else if (varienceType === ">=") {
      return (
        item.tier_basis_variance >= accrualFilterEnd ||
        item.incentive_basis_variance >= accrualFilterEnd ||
        item.incent_amount_variance >= accrualFilterEnd
      );
    } else if (varienceType === "<=") {
      return (
        item.tier_basis_variance <= accrualFilterEnd ||
        item.incentive_basis_variance <= accrualFilterEnd ||
        item.incent_amount_variance <= accrualFilterEnd
      );
    }
    return true;
  };
  // const filteredData = props.accrualVarianceGraphData.filter((item) =>
  //   accrualFilterEnd !== undefined ? filterData(item) : true
  // );
  const filteredData = props.accrualVarianceGraphData
    ? props.accrualVarianceGraphData.filter((item) =>
      accrualFilterEnd !== undefined ? filterData(item) : true
    )
    : [];

  const handleRun = () => {
    props.loadAccrualVarianceGraph(
      Moment(startDate).format("MM/DD/YYYY"),
      Moment(endDate).format("MM/DD/YYYY"),
      postingType,
      applicationType,
      sourceDataType,
      groupBy
    );
  }

  return (
    <div className={classes.rootDiv}>
      <Grid container className={classes.root}>
        {props.page !== "dashboard" && (
          <Grid
            container
            style={{
              margin: 10,
              padding: 5,
              border: "1px solid #E6E6E6",
              borderRadius: 5,
            }}
          >
            {/* <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Application Type
                </Typography>
                <Select
                  value={applicationType}
                  onChange={handleApplicationtype}
                  displayEmpty
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  {allowedApps
                    .filter(
                      (item) =>
                        item !== "Customer Chargeback" &&
                        item !== "Promotions" &&
                        item !== "Supplier Chargeback" &&
                        item !== "Pricing" &&
                        item !== "Profit Optimization"
                    )
                    .map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </Grid> */}

            <Grid container md={11} xs={12} style={{ paddingRight: 15 }}>
              <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography classes={{ root: classes.fontSetting }}>
                    Source Data Type
                  </Typography>
                  <Select
                    value={sourceDataType}
                    onChange={handleSourceDataType}
                    displayEmpty
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    {sourceDataTypeDataFromAPI.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </Grid>
              <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                <Typography classes={{ root: classes.fontSetting }}>
                  Posting Type
                </Typography>
                <Select
                  value={postingType}
                  onChange={handlePostingType}
                  className={clsx({
                    [classes.select]: true,
                  })}
                  classes={{
                    selectMenu: classes.selectedItem,
                  }}
                >
                  <MenuItem value={"accrual"}>Accrual</MenuItem>
                  <MenuItem value={"payment"}>Payment</MenuItem>
                </Select>
              </Grid>

              <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography classes={{ root: classes.fontSetting }}>
                    Start Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: "1px 0 1px 11px",
                            alignSelf: "center",
                            alignItems: "center",
                            border: "1px solid #E0E0E0",
                            width: "100%",
                            borderRadius: 5,
                            height: 37,
                            color: "#1675e0",
                          },
                        }}
                        value={startDate}
                        onChange={handleStartDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography classes={{ root: classes.fontSetting }}>
                    End date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: "1px 0 1px 11px",
                            alignSelf: "center",
                            alignItems: "center",
                            border: "1px solid #E0E0E0",
                            width: "100%",
                            borderRadius: 5,
                            height: 37,
                            color: "#1675e0",
                          },
                        }}
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                        minDate={startDate ? startDate : false}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>

              <Grid
                item
                md={2}
                xs={12}
              //style={{ paddingRight: 15 }}
              >
                <Typography classes={{ root: classes.fontSetting }} >Aggregation Field</Typography>
                <Select
                  value={groupBy}
                  onChange={handleGroupBy}
                  className={clsx({
                    [classes.select]: true
                  })}
                  classes={{
                    selectMenu: classes.selectedItem
                  }}
                >

                  <MenuItem value={'contract_number'}>
                    Contract Number
                  </MenuItem>
                  <MenuItem value={'customer_number'}>
                    Customer Number
                  </MenuItem>
                  <MenuItem value={'material_number'}>
                    Material Number
                  </MenuItem>
                  <MenuItem value={'supplier_number'}>
                    Supplier Number
                  </MenuItem>
                </Select>
              </Grid>

              <Grid item md={2} xs={12} >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ marginLeft: 50 }} classes={{ root: classes.fontSetting }}>
                    Variance
                  </Typography>

                  <div
                    style={{
                      width: "86%",
                      display: "flex",
                      flexDirection: "row",
                      height: 37,
                      marginLeft: 23,
                    }}
                  >
                    <Select
                      value={varienceType}
                      onChange={handleVarience}
                      className={clsx({
                        [classes.select]: true,
                      })}
                      classes={{
                        selectMenu: classes.selectedItem,
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    <OutlinedInput
                      value={accrualFilterEnd}
                      type={"number"}
                      inputProps={{ min: "0", step: "1" }}
                      onChange={(e) => setAccrualFilterEnd(e.target.value)}
                      style={{ borderRadius: 5, height: 36, marginLeft: 10 }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleRun}
                twoline="true"
                disabled={(!startDate || !endDate || !postingType || !sourceDataType) ? true : false}
              >
                <DirectionsRunIcon />
              </Button>
            </div>
          </Grid>
        )}
        <Grid item md={12} xs={6} className={classes.container}>
          {props.accrualVarianceGraphData &&
            props.accrualVarianceGraphData.length > 0 && (
              <div style={{ margin: 15 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Contract Number</TableCell>
                        <TableCell align="center">
                          Tier Basis Variance(%)
                        </TableCell>
                        <TableCell align="center">
                          Incentive Basis Variance(%)
                        </TableCell>
                        <TableCell align="center">
                          Incentive Amount Variance(%)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody classes={{ root: classes.table }}>
                      {filteredData.map((item) => {
                        return (
                          <StyledTableRow>
                            <TableCell
                              align="center"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "blue",
                              }}
                              onClick={() =>
                                handleTrendsAPI(item.aggregation_field)
                              }
                            >
                              {item.aggregation_field}
                            </TableCell>
                            <TableCell align="center">
                              {item.tier_basis_variance}
                            </TableCell>
                            <TableCell align="center">
                              {item.incentive_basis_variance}
                            </TableCell>
                            <TableCell align="center">
                              {item.incentive_amount_variance}
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
        </Grid>
      </Grid>


    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accrualVarianceGraphData: state.initialData.loadAccrualVarianceGraph,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSalesVsPaymentGraph: () => dispatch(loadSalesVsPaymentGraph()),
    loadAccrualVarianceGraph: (s, e, type, applicationType, sourceDataType, groupBy) =>
      dispatch(
        loadAccrualVarianceGraph(
          s,
          e,
          type,
          applicationType,
          sourceDataType,
          "postingType",
          groupBy
        )
      ),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    loadRebateTrendsAPI: (s, e, type, applicationType, sourceDataType, id) =>
      dispatch(
        loadRebateTrendsSecondAPI(
          s,
          e,
          type,
          applicationType,
          sourceDataType,
          id
        )
      ),
    allowedApps: () => dispatch(allowedApps()),
    allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics")),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);
