import React, { useEffect } from 'react';
import {
    Grid, Typography, Breadcrumbs,
    Card, Table, TableCell, TableHead,
    TableRow, TableContainer, TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    error: {
        border: '1px solid red !important'
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const ViewSalesInvoiceQuery = props => {
    const classes = useStyles();
    const [salesQueryData, setSalesQueryData] = React.useState(localStorage.getItem('customer-master-query') ? JSON.parse(localStorage.getItem('customer-master-query')) : []);
    const [defaultValue, setDefaultValue] = React.useState({});
    const [sequenceNumber, setSequenceNumber] = React.useState({});

    useEffect(() => {
        if (props.customerMasterSingleData) {
            setDefaultValue(props.customerMasterSingleData.default_values || {});
            setSequenceNumber(props.customerMasterSingleData.format);
        }
    }, [props.customerMasterSingleData]);


    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/customer-master/customer-master-excel-upload'
                >
                    Customer Master Format
                </Link>
                <Typography color="textPrimary" variant='h4'>View Query</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="h3" color={'primary'}>
                                    {salesQueryData ? salesQueryData['format_name'] : ''}
                                </Typography>
                            </div>
                            {salesQueryData ?
                                <div
                                >
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead}>Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}>Default Values</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }}>
                                                    {Object.keys({ ...defaultValue, ...sequenceNumber })
                                                        .filter(k => k !== 'id' && k !== 'format_name' && k !== 'organization_id' && k !== 'ID' && ((sequenceNumber[k] || defaultValue[k]) > 0))
                                                        .sort((a, b) => (sequenceNumber[a] || defaultValue[a]) > (sequenceNumber[b] || defaultValue[b]) ? 1 : -1)
                                                        .map((k, key) => (
                                                            <TableRow key={key}>
                                                                <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }} classes={{ root: classes.fontSetting }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{sequenceNumber[k]}</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>{defaultValue[k]}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                                {/* <TableBody classes={{ root: classes.table }} >
                                                    {Object.keys(salesQueryData).length > 0 && Object.entries(salesQueryData)
                                                        .filter(([k, v], key) => k != 'id' && k != 'format_name' && k != 'organization_id' && v > 0 && k != 'ID')
                                                        .sort(function (a, b) {
                                                            return a[1] > b[1] ? 1 : -1;
                                                        })
                                                        .map(([k, v], key) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }} classes={{ root: classes.fontSetting }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}> {v} </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}

                                                </TableBody> */}
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div >
    );

};

const mapStateToProps = state => {
    return {
        customerMasterSingleData: state.dataSetupData.customerMasterSingleData,
    }
};
export default connect(mapStateToProps, null)(ViewSalesInvoiceQuery);