import React, { useEffect, forwardRef, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { createTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";

import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import {
  getLblDispDesFieldValue,
  fetchDocumentData,
  fetchSingleDocumentData, addDocumentData, getAllOrganizationUsers
} from "../../redux/actions";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { getContractDetailsData } from "../../redux/actions";
import LoadingOverlay from "react-loading-overlay";
import { DeleteOutline, Announcement } from "@mui/icons-material";
import PacmanLoader from "react-spinners/PacmanLoader";
import BeatLoader from "react-spinners/BeatLoader";
import MaterialTable from "material-table";
import ChangeCircleIcon from "@mui/icons-material/BubbleChart";
import * as moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
import HashLoader from "react-spinners/HashLoader";
import { tableIcons } from "../../components/Icons/TableIcons";
import {
  SearchSharp,
} from "@mui/icons-material";
import {
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions, Fab
} from "@mui/material";
import { CloudUpload, LibraryAdd, Visibility, PostAdd } from '@mui/icons-material';
import { MessageDialogue } from "../MessageDialogue";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
    // fontSize: theme.typography.h4.fontSize,
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  description: {
    textAlign: "left",
    paddingLeft: 28,
  },
  IconButton: {
    padding: 0,
    marginRight: 10
    //fontSize: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 15,
  },
  header: {
    backgroudColor: "red",
  },
  buttonCreate: {
    // textTransform: 'none', backgroundColor: theme.palette.primary.main, color: 'white'
    marginTop: 10,
    color: "black",
  },
  spinner: {
    // height: '100vh'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    // position:'absolute',
    zIndex: 1
  },
}));

const DocumentManagementMain = (props) => {
  const { className } = props;
  const classes = useStyles();
  const history = useHistory();
  const [dataRows, setDataRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [contractsHaveCR, setContractsHaveCR] = React.useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [contractsTableDataAPI, setContractsTableDataAPI] = React.useState([]);
  const [currentDeleteContractNumber, setCurrentDeleteContractNumber] = React.useState("");
  const [currentDeleteContractNumberDisplay, setCurrentDeleteContractNumberDisplay] = React.useState("");
  const theme = useTheme();
  const [dialog, setDialog] = React.useState(false)
  const [orgUsersAarray, setOrgUsersArray] = React.useState({});
  const [errorList, setErrorList] = React.useState([])
  const [application, setApplication] = useState(sessionStorage.getItem('application') ? sessionStorage.getItem('application') : '')
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  useEffect(() => {
    props.loadListControlData({ "data_source": "document_header", "application_type": "Customer Rebate" }, 'post');
    props.getUsers()
    if (history.location.state !== "searchByDocuments") {
      props.fecthDocuments()
    }
  }, [history])

  function handleError() {
    setDialog(false)
  }
  useEffect(() => {
    if (props.orgUsers && props.orgUsers.length > 0) {
      var temp = [];
      props.orgUsers.map(item => {
        temp = { ...temp, [item.id]: item.username }
      })
      setOrgUsersArray(temp);
    }
  }, [props.orgUsers]);
  const deleteCon = (id, number) => {
    setCurrentDeleteContractNumber(id);
    setCurrentDeleteContractNumberDisplay(number);
    setOpenConfirmDelete(true);
  };


  function handleDocument(id, type) {
    props.fecthSingleDoc(id, type);
  }
  function handleCreateContract(id) {
    history.push({ pathname: '/contract-setup/add-contract', documentID: id })
  }
  useEffect(() => {
    if (props.documentsData) {
      if (props.documentsData.length > 0) {
        setDataRows(props.documentsData)
      }
      else {
        setDataRows([])
      }
    }
  }, [props.documentsData]);
  useEffect(() => {
    var temp = []
    if (props.tableData && props.tableData.field_label_attributes) {
      Object.values(props.tableData.field_label_attributes)
        .filter(item => item.sort_list)
        .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
        .map((item, i) => {
          temp.push({
            field: item.key,
            title: item.current,
            render: (rowData) => (
              <div>
                {rowData[item.key] && (item.key == 'valid_from' || item.key == 'valid_to' || item.key == 'internal_signed_date' || item.key == 'external_signed_date') ?
                  moment.utc(rowData[item.key]).format('MM/DD/YYYY')
                  :
                  (item.key == 'document_analyst' || item.key == 'document_manager') ?
                    rowData[item.key] && orgUsersAarray && orgUsersAarray[rowData[item.key]] ?
                      orgUsersAarray[rowData[item.key]] : ''
                    :
                    rowData[item.key]
                }
              </div>
            )
          })
        })
      temp.push({
        field: "Actions",
        title: "Actions",
        type: "string",
        editable: "never",
        sorting: false,
        render: (rowData) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div>
              {rowData.level_completed == rowData.total_level &&
                <Tooltip title='Create Contract'>
                  <IconButton
                    classes={{ root: classes.IconButton }}
                    onClick={() =>
                      handleCreateContract(rowData.document_id)
                    }
                    size={"small"}
                  >
                    <PostAdd color="disabled" style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              }
              <IconButton
                classes={{ root: classes.IconButton }}
                onClick={() =>
                  handleDocument(rowData.ID, 'view')
                }

                size={"small"}
              >
                <Visibility color="disabled" style={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                classes={{ root: classes.IconButton }}
                onClick={() =>
                  handleDocument(rowData.ID, rowData.approval_status)
                }
                disabled={
                  rowData.approval_status == 'acc' ? true : false
                }
                size={"small"}
              >
                <EditIcon color="disabled" style={{ fontSize: 20 }} />
              </IconButton>

            </div>
            <div style={{ marginLeft: 5, borderLeft: "1px solid lightgray" }}>
              <IconButton
                onClick={() => props.onDelete(rowData.ID, 'delete')}
                classes={{ root: classes.IconButton }}
                size={"small"}
              >
                <DeleteOutline />
              </IconButton>
            </div>
          </div>
        ),
      })
      setColumns(temp)
    }

  }, [props.tableData && props.tableData.field_label_attributes])
  const addContract = () => {
    //props.uploadEligibilityRulesClear();
    localStorage.removeItem("currentAddContractID");
    localStorage.setItem("mode", "add");
    history.push("/documents/create");
  };
  const searchContract = () => {
    history.push("/documents-search");
  };

  function handleItemClick(id) {
    localStorage.setItem("currentAddContractID", id);
    // props.getcontractViewdetails(id);
  }

  function handleDialog(bool) {
    if (bool === "No") {
      setOpenConfirmDelete(true);
    } else {
      props.deleteContract(currentDeleteContractNumber);
      setOpenConfirmDelete(false);
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner={<HashLoader />}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        className={classes.spinner}
      >

        <Dialog open={openConfirmDelete}>
          <DialogContent
            classes={{
              root: classes.dialogContent,
            }}
          >
            <Typography variant="h4">
              <DialogContentText>
                Are you sure you want to delete contract number{" "}
                {currentDeleteContractNumberDisplay} ?
              </DialogContentText>
            </Typography>
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialog,
            }}
          >
            <Button
              onClick={() => setOpenConfirmDelete(false)}
              autoFocus
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={() => handleDialog("Yes")}
              color="primary"
              autoFocus
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h1" color='primary' >
            Document Management
          </Typography>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}
          >
            {(
              <div>

                {
                  //  secureLocalStorage.getItem("dYtz").role &&
                  //  secureLocalStorage.getItem("dYtz").role.rights &&
                  //  secureLocalStorage
                  //    .getItem("dYtz")
                  //    .role.rights.includes(
                  //      "get_contracts-"+sessionStorage.getItem("application")
                  //   )&&
                  <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    onClick={searchContract}
                  >
                    <SearchSharp color='primary' />
                    <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                  </Fab>
                }

                <Fab
                  aria-label="edit" variant="extended"
                  size='medium'
                  classes={{ root: classes.fabContainer }}
                  className={classes.fabContainer}
                  onClick={addContract}
                >
                  {isDesktop ? (
                    <><LibraryAdd color='primary' />
                      <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Fab>

              </div>
            )}
          </div>
        </div>
        {/*</div>*/}

        {dataRows && dataRows.length > 0 && columns ? (
          dataRows &&
          dataRows.length > 0 && (
            <div>
              <MaterialTable
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "0px",
                      }}
                    ></div>
                  ),
                }}
                title={" "}
                editable={true}
                icons={tableIcons}
                columns={columns}
                data={dataRows}
                options={{
                  search: false,
                  sorting: true,
                  emptyRowsWhenPaging: false,
                  headerStyle: theme.mixins.MaterialHeader,
                  cellStyle: theme.mixins.MaterialCell,
                  filtering: true,
                  // cellStyle: {
                  //   fontSize: theme.typography.h4.fontSize,
                  //   textAlign: "center",
                  //   borderRight: "1px solid #EEEEEE",
                  //   color: "black !important",
                  //   padding: '5px 0px',
                  //   whiteSpace: 'nowrap',
                  //   overflow: 'hidden',
                  //   textOverflow: 'ellipsis',
                  //   maxWidth: 150,
                  //   width: 80,
                  //   minWidth: 50,

                  // },
                  pageSize: 15,
                  pageSizeOptions: [
                    15,
                    20,
                    50,
                    { value: dataRows.length, label: "Show all" },
                  ],
                }}
              />

            </div>
          )
        ) : (
          <div>There is no data to show now.</div>
        )}
        {/*</LoadingOverlay>*/}
        {dialog &&
          <MessageDialogue open={dialog} messageList={errorList} onChange={handleError} />
        }
      </LoadingOverlay>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    documentsData: state.DocumentManagement.documentData,
    tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    orgUsers: state.operationalReportsData.orgUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fecthDocuments: () => dispatch(fetchDocumentData()),
    fecthSingleDoc: (id, type) => dispatch(fetchSingleDocumentData(id, type)),
    loadListControlData: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    onDelete: (id, type) => dispatch(addDocumentData(null, id, type)),
    getUsers: () => dispatch(getAllOrganizationUsers()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagementMain);
