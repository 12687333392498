import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { AddBoxOutlined, CloudUpload, LibraryAdd } from '@mui/icons-material';
import { Button, useMediaQuery, Fab, Typography } from '@mui/material';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomColor: theme.palette.table.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const Toolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    localStorage.setItem('mode', 'add');
    history.push('/default-page');
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >

      <Fab aria-label="edit" variant="extended"
        size='medium'
        classes={{ root: classes.fabContainer }}
        className={classes.fabContainer}
        component={CustomRouterLink}
        to='/terittory/add-terittory'
        onClick={addContract}
      >
        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
      </Fab>

      <Fab aria-label="edit" variant="extended"
        size='medium'
        className={classes.fabContainer}
        component={CustomRouterLink}
        to='/territory/territory-excel-upload'
      >
        <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
      </Fab>

      {/* <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/terittory/add-terittory'
        onClick={addContract}
      >
        {isDesktop ? 'Add Territory' : ''}
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/territory/territory-excel-upload'
        style={{ marginLeft: 10 }}
      >
        Upload Excel
      </Button> */}
    </div >
  );

};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default connect(null, null)(Toolbar);