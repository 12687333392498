import React, { useEffect } from 'react';
import {
    Button
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import MarketDefinition from '../MarketDefinition/MarketDefinition';
import { connect } from 'react-redux';
import { StickerBank, StickerBankSelected } from '../../../GlobalSetup'
import { getMaterialFilters } from '../../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1
    }
}))
const Stickers = (props) => {

    const classes = useStyles();
    const { className } = props;
    const [defineMarketEnable, setDefineMarketEnable] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);
    const [sticker, setSticker] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [selectedStickers, setSelectedStickers] = React.useState([]);
    useEffect(() => {
        props.getFilters()
    }, [])
    useEffect(() => {
        if (props.filterData) {
            setFilters(props.filterData)
        }
    }, [props.filterData])
    function hanleSticker(value, type) {
        if (value == 'return') {
            props.handleBack()
            setIsClicked(false)
        }
        else if (type == 'selected_stickers') {
            setSelectedStickers(value)
            // props.onChange(value, null)
        }
        else {
            setSticker(value)
            setDefineMarketEnable(true)
            props.onChange(selectedStickers, value)
        }

    }
    function handleDefine(value) {
        setDefineMarketEnable(false)
    }

    function handleClick(e) {
        setIsClicked(e);
    }
    function handleNext() {
        var stickerId = selectedStickers && selectedStickers[0] && selectedStickers[0].ID
        props.onChange(selectedStickers, stickerId)
        setDefineMarketEnable(true)
    }

    return (
        <div>
            <StickerBankSelected clicked={handleClick} country={props.country} productLine={props.productLine}
                onChange={hanleSticker} handleNext={handleNext} stickers={selectedStickers} handleColor={props.handleColor} />

            <div className={classes.buttonContainer}>
                {isClicked &&
                    <Button variant='contained' color='primary' onClick={handleNext}> Apply Stickers</Button>}
            </div>
        </div>
    )

}
const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters())
    }
}

const mapStateToProps = state => {
    return {
        filterData: state.promotionData.promoFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stickers);
