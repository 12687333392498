import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid, MenuItem,
    FormLabel, Select,
} from '@mui/material';
import { AllOtherAttributeMasterComponent } from '../../../../components/Inputs';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { getDefaultValuesSupplier, getAttributeListData, getCustomerMasterViewDetails, getSupplierMasterDropDown } from '../../../../redux/actions';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    selectedItemDropdown: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));

const Ima360Data = props => {
    const classes = useStyles();
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }
    const [supplierMasterData, setSupplierMasterData] = React.useState({});
    const [claimSubmissionExcel, setClaimSubmissionExcel] = React.useState('');
    const [claimResponseExcel, setClaimResponseExcel] = React.useState('');
    const [claimSubmissionEDI, setClaimSubmissionEDI] = React.useState('');
    const [claimResponseEDI, setClaimResponseEDI] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [claimSubmissionExcelFormat, setClaimSubmissionExcelFormat] = React.useState([]);
    const [claimResponseExcelFormat, setClaimResponseExcelFormat] = React.useState([]);
    const [claimSubmissionEDIFormat, setClaimSubmissionEDIFormat] = React.useState([]);
    const [claimResponseEDIFormat, setClaimResponseEDIFormat] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    // console.log(fieldLabel)

    useEffect(() => {
        props.onChange(formData);
    });

    useEffect(() => {
        props.getSupplierMasterDropDown();
    }, []);


    useEffect(() => {
        if (props.supplierDropDown) {
            if (props.supplierDropDown.claim_response_excel_format !== null) {
                setClaimResponseExcelFormat(props.supplierDropDown.claim_response_excel_format.map(item => item.FormatName));
            }
            if (props.supplierDropDown.claim_submission_excel_format !== null) {
                setClaimSubmissionExcelFormat(props.supplierDropDown.claim_submission_excel_format.map(item => item.FormatName))
            }
            if (props.supplierDropDown.claim_submission_edi_mappings !== null) {
                setClaimSubmissionEDIFormat(props.supplierDropDown.claim_submission_edi_mappings.map(item => item.FormatName))
            }
            if (props.supplierDropDown.claim_response_edi_mappings !== null) {
                setClaimResponseEDIFormat(props.supplierDropDown.claim_response_edi_mappings.map(item => item.FormatName))
            }
        }
    }, [props.supplierDropDown]);
    useEffect(() => {
        if (props.supplierMasterData) {
            setSupplierMasterData(props.supplierMasterData)
            setClaimSubmissionExcel(props.supplierMasterData.claim_submission_excel_format);
            setClaimResponseExcel(props.supplierMasterData.claim_response_excel_format)
            setClaimSubmissionEDI(props.supplierMasterData.claim_submission_edi_map);
            setClaimResponseEDI(props.supplierMasterData.claim_response_edi_map);
            setEmailAddress(props.supplierMasterData.claim_email_address);
            setFlexAttribute1(props.supplierMasterData.flex_attribute_1);
            setFlexAttribute2(props.supplierMasterData.flex_attribute_2);
        }

    }, [props.supplierMasterData]);
    var formData = {
        claim_submission_excel_format: claimSubmissionExcel,
        claim_response_excel_format: claimResponseExcel,
        claim_submission_edi_map: claimSubmissionEDI,
        claim_response_edi_map: claimResponseEDI,
        claim_email_address: emailAddress,
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2
    }
    //functions 
    function handleClaimSubmissionExcelFormat(newValue) {
        setClaimSubmissionExcel(newValue);
        props.onChange(formData);
    }
    function handleClaimResponseExcelFormat(newValue) {
        setClaimResponseExcel(newValue);
        props.onChange(formData);
    }
    function handleClaimSubmissionEDIFormat(newValue) {
        setClaimSubmissionEDI(newValue);
        props.onChange(formData);
    }
    function handleClaimResponseEDIFormat(newValue) {
        setClaimResponseEDI(newValue);
        props.onChange(formData);
    }
    function handleClaimEmailAddress(newValue) {
        setEmailAddress(newValue);
        props.onChange(formData);
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue);
    }
    return (
        <div className={classes.root}>
            {supplierMasterData &&
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <CardHeader
                                title="IMA360 DATA"
                                titleTypographyProps={{ variant: 'h3' }}
                            />
                            <Grid container
                                style={{ marginBottom: 16, margin: 10, marginTop: "5px" }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel className={classes.formLabel} required={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['mandatory'] : false}>
                                        {fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['current'] : 'Claim Submission - Excel Format'}
                                    </FormLabel>
                                    <Select
                                        value={claimSubmissionExcel}
                                        onChange={(e) => handleClaimSubmissionExcelFormat(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {claimSubmissionExcelFormat && claimSubmissionExcelFormat.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['mandatory'] : false} heading={fieldLabel['claim_submission_excel_format'] ? fieldLabel['claim_submission_excel_format']['current'] : 'Claim Submission - Excel Format'} twoline='true' onChange={handleClaimExcelFormat} prevalue={supplierMasterData.claim_submission_excel_format} /> */}
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel className={classes.formLabel} required={fieldLabel['claim_submission_edi_map'] ? fieldLabel['claim_submission_edi_map']['mandatory'] : false}>
                                        {fieldLabel['claim_submission_edi_map'] ? fieldLabel['claim_submission_edi_map']['current'] : 'Claim Submission - EDI Map'}
                                    </FormLabel>
                                    <Select
                                        value={claimSubmissionEDI}
                                        onChange={(e) => handleClaimSubmissionEDIFormat(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {claimSubmissionEDIFormat && claimSubmissionEDIFormat.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel className={classes.formLabel} required={fieldLabel['claim_response_excel_format'] ? fieldLabel['claim_response_excel_format']['mandatory'] : false}>
                                        {fieldLabel['claim_response_excel_format'] ? fieldLabel['claim_response_excel_format']['current'] : 'Claim Response - Excel Format'}
                                    </FormLabel>
                                    <Select
                                        value={claimResponseExcel}
                                        onChange={(e) => handleClaimResponseExcelFormat(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {claimResponseExcelFormat && claimResponseExcelFormat.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel className={classes.formLabel} required={fieldLabel['claim_response_edi_map'] ? fieldLabel['claim_response_edi_map']['mandatory'] : false}>
                                        {fieldLabel['claim_response_edi_map'] ? fieldLabel['claim_response_edi_map']['current'] : 'Claim Response - EDI Map'}
                                    </FormLabel>
                                    <Select
                                        value={claimResponseEDI}
                                        onChange={(e) => handleClaimResponseEDIFormat(e.target.value)}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        {claimResponseEDIFormat && claimResponseEDIFormat.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "18px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['mandatory'] : false}>
                                        {fieldLabel['claim_email_address'] ? fieldLabel['claim_email_address']['current'] : 'Claim Email Address'}
                                    </FormLabel>

                                    <AllOtherAttributeMasterComponent
                                        twoline='true'
                                        withOutLabel
                                        attributeName="claim_email_address"
                                        value={emailAddress}
                                        onChange={handleClaimEmailAddress}
                                        prevalue={supplierMasterData.claim_email_address} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "18px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        twoline='true'
                                        withOutLabel
                                        attributeName="flex_attribute1"
                                        value={flexAttribute1}
                                        onChange={handleFlexAttribute1}
                                        prevalue={supplierMasterData.flex_attribute1} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: "18px" }}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false}>
                                        {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                    </FormLabel>

                                    <AllOtherAttributeMasterComponent
                                        twoline='true'
                                        withOutLabel
                                        attributeName="flex_attribute2"
                                        value={flexAttribute2}
                                        onChange={handleFlexAttribute2} prevalue={supplierMasterData.flex_attribute2} />


                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        supplierMasterData: state.customerData.supplierMasterViewData,
        supplierDropDown: state.customerData.supplierMasterDropDown,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesSupplier()),
        getSupplierMasterDropDown: () => dispatch(getSupplierMasterDropDown()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(Ima360Data);