import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown, ExpandMore } from '@mui/icons-material';
import { getModels } from '../../../../../../redux/actions';
const useStyles = makeStyles(theme => ({
    accordionMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        width: 250,
        paddingLeft: 10
    },
    gridButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 36,
        width: 54,
        margin: 12,
        backgroundColor: "#E2E2E2"
    },
    locButton: {
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        Padding: "8px 12px 8px 12px",
        height: 36,
        width: '100%',
        color: 'black !important',
        margin: 2,
        backgroundColor: "#E2E2E2",
        '&:hover': {
            backgroundColor: '#E2E2E2'
        }

    },
    locMenu: {
        backgroundColor: '#EEEEEE',
        border: "0.8px #E2E2E2 ",
        borderRadius: 4,
        fontSize: 12,
        height: 36,
        maxHeight: 36,
        width: '100%',
        margin: "10px 10px 0px 0px",
        // display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        webkitFlexWrap: 'nowrap',
        "&.Mui-expanded": {
            minHeight: 48
        }
    },
    locDetail: {
        width: '100%',
        paddingRight: 0,
        height: 36,
        backgroundColor: 'white'
    },
}))

const PromoRegion = (props) => {

    const classes = useStyles();
    const [expandedRows, setExpandedRows] = useState([]);
    const [data, setData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [localFilter, setLocalFilter] = React.useState([]);
    const [modelFilter, setModelFilter] = React.useState([]);
    useEffect(() => {
        var model = {
            table_name: 'material_master',
        }

        props.getFilterData('material_master', model)
        // props.getFilterData('territory', location)
    }, [])

    useEffect(() => {
        if (props.modelData)
            setData(props.modelData)
    }, [props.modelData])

    useEffect(() => {
        if (props.locationData)
            setLocations(props.locationData)
    }, [props.locationData])
    useEffect(() => {
        const temp = []
        const locate = []
        console.log(props.gridFilter)
        if (props.gridFilter && props.activeSticker) {
            props.activeSticker.map(item => {
                if (props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['model']) {
                    temp.push(...props.gridFilter[item.ID]['model'])
                }
                if (props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['location']) {
                    locate.push(...props.gridFilter[item.ID]['location'])
                }
            })
            console.log(locate)
            setModelFilter(temp)
            setLocalFilter(locate)
        }
    }, [props.gridFilter, props.activeSticker])

    const handleAccordionChange = (panel, type) => (event, isExpanded) => {
        setMarketExpanded(isExpanded ? [...marketExpanded, panel + "-" + type] : marketExpanded.filter(item => item != panel + "-" + type));
    };

    const renderNestedItems = (items) => {
        return items && items.filter((item, index) =>
            modelFilter && modelFilter.length > 0 ? modelFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
            .map((item, index) => (
                <>

                    {item.type == 'material_group1' ?
                        renderNestedItems(item.children)
                        :
                        item.children && item.children.length > 0 ? (
                            <div key={index} style={{ flexGrow: 1, width: '100%', height: 'auto' }} >
                                <Accordion
                                    expanded={marketExpanded.includes(item.category + "-" + item.type)}
                                    onChange={handleAccordionChange(item.category, item.type)}
                                    style={{ margin: 0, zindex: 1 }}
                                    fullWidth
                                    slotProps={{ transition: { unmountOnExit: true } }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            className={classes.accordionMenu}
                                            style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10, }}

                                        >
                                            <Typography variant='h4' color={'black'} style={{ marginLeft: 10 }} >
                                                {item.category}
                                            </Typography>
                                        </AccordionSummary>
                                        {renderStates(item)}
                                    </div>
                                    <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                        className={(item.type == 'material_group3') && classes.chipContainer}
                                    >
                                        {renderNestedItems(item.children)}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ) :
                            (
                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <div key={item.category} className={classes.accordionMenu} style={{ alignItems: 'flex-end' }}>
                                        <Typography variant='h4' color={'black'} >{item.category}</Typography>
                                    </div>
                                    {renderStates(item)}
                                </div>
                            )
                    }
                </>
            ));
    };

    const renderStates = (data, type) => {
        return type && type == 'head' ? locations && locations.filter((item, index) =>
            localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
            .map((parent, index) =>

            (
                <div key={parent.category} style={{ width: '100%', justifyContent: 'center' }}>
                    <Accordion style={{ marginRight: 3 }}>
                        <AccordionSummary expandIcon={<ExpandMore />}
                            className={classes.locMenu}
                        >
                            <Typography variant='h4' color={'black'} >{parent.category}</Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{ width: '100%', paddingRight: 0, marginTop: 2, height: 36 }}>
                            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', webkitFlexWrap: 'nowrap' }} >
                                {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                                    .map((child) => (
                                        <Button variant="outlined" className={classes.locButton}>{child.category}</Button>
                                    ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))
            :
            locations && locations.filter((item, index) =>
                localFilter && localFilter.length > 0 ? localFilter.some(filterItem => filterItem.startsWith(Object.values(item.relationship).join("_").toString())) : item)
                .map((parent, index) =>
                (<div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', webkitFlexWrap: 'nowrap' }} >
                    {parent.children.filter((itemx, i) => localFilter && localFilter.length > 0 ?
                        localFilter.some(filterItem => filterItem.startsWith(Object.values(itemx.relationship).join("_").toString())) : itemx)
                        .map((child) => {
                            var clr;
                            props.activeSticker && props.activeSticker.length > 0 && props.activeSticker.map((item, index) => {
                                if (props.gridFilter && props.gridFilter[item.ID] && props.gridFilter[item.ID]['model'] && props.gridFilter[item.ID]['model'].includes(Object.values(data.relationship).join("_").toString())) {
                                    if (props.gridFilter[item.ID]['location'] && props.gridFilter[item.ID]['location'].includes(Object.values(child.relationship).join("_").toString())) {
                                        clr = props.colorPicker && props.colorPicker[item.ID];
                                    }
                                }
                            });

                            return (
                                <Button className={classes.locButton} style={{ backgroundColor: clr }}></Button>
                            )
                        })}
                </div>))
    }
    //     )


    // }


    return (
        <Grid container spacing={2} style={{ width: 'fit-content', maxHeight: 500, overflowY: 'scroll', justifyContent: 'flex_start', marginTop: 0 }}>
            <div style={{ display: 'flex' }}>
                <Grid xs={12} md={3} style={{ backgroundColor: 'white', width: '250px', justifyContent: 'center' }}>
                </Grid>
                <div style={{ display: 'flex', position: 'sticky', paddingLeft: 2 }}>

                    {renderStates(null, 'head')}
                </div>
            </div>
            {data && data.length > 0 &&
                renderNestedItems(data.filter((item, index) => item.category == props.modelYear))
            }
        </Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getFilterData: (table, data) => dispatch(getModels(table, data))
    }
}

const mapStateToProps = state => {
    return {
        modelData: state.promotionData.modelList,
        //  locationData: state.promotionData.locationFilters,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoRegion);
