import React, { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { connect } from "react-redux";
import momentt from "moment-timezone";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Breadcrumbs,
  Typography,
  Button,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { downloadContractTrendsResltsSheet } from "../../redux/actions";
import MaterialTable from "material-table";
import clsx from "clsx";
// import { tableIcons } from "../../../components/Icons/TableIcons";
import { Link } from "react-router-dom";
import { single } from "validate.js";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";

momentt.tz.setDefault("Etc/UTC");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 29,
    // marginTop: 16,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    // marginTop: 25,
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

const theme2 = createTheme({
  overrides: {
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#CBD1E3 !important",
        "& .MuiTableCell-head": {
          padding: 5,
        },
      },
    },
    MuiTableCell: {
      root: {
        textAlign: "center !important",
      },
    },
  },
});
const ContractTrendsResult = (props) => {
  const [value, setValue] = React.useState(0);
  const [contractHeads, setContractHeads] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(0);
  const [apiData, setApiData] = React.useState(0);
  const classes = useStyles();
  const rebateHeading = [
    "Rebate Formula",
    "Rebate Formula Value",
    "Incentive Basis",
    "Incentive Rate",
    "Incentive Calculated",
    "Incentive Amount",
  ];
  const handleSheetDownload = () => {
    var formData = JSON.parse(localStorage.getItem("contractTrendsQuery"));
    if (formData) {
      console.log("formd", formData);
      props.downloadSheet(formData);
    }
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (props.contractTrendsResult && props.contractTrendsResult.length > 0) {
      var heading = [];
      props.contractTrendsResult.map((item) => {
        heading.push(Object.keys(item)[0] ? Object.keys(item)[0] : null);
      });
      setContractHeads(heading);
      setApiData(props.contractTrendsResult);
    }
  }, [props.contractTrendsResult]);

  return <>
    <Fab
      variant="extended"
      color="primary"
      onClick={handleSheetDownload}
      style={{
        marginLeft: 10,
        position: "absolute",
        zIndex: 2000,
        bottom: 20,
        right: 20,
      }}
    >
      <GetAppIcon />
      Download
    </Fab>
    {apiData && Object.keys(apiData).length > 0 && (
      <Box sx={{ width: "100%", background: "white" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {contractHeads.map((item) => {
              return (
                <Tab label={item} {...a11yProps(0)} style={{ padding: 20 }} />
              );
            })}
          </Tabs>
        </Box>
        {contractHeads.map((singleItem, ix) => {
          return (
            <TabPanel value={activeTab} index={ix}>
              <GridComponent dataSource={apiData}>
                <ColumnsDirective>
                  {apiData[ix] &&
                    apiData[ix][singleItem] &&
                    apiData[ix][singleItem][0] &&
                    Object.keys(apiData[ix][singleItem][0]).map(
                      (key, index) => {
                        if (apiData[ix][singleItem][0][key]["attribute"]) {
                          return Object.keys(
                            apiData[ix][singleItem][0][key]["attribute"]
                          ).map((item) => {
                            return (
                              <ColumnDirective
                                field={item}
                                headerText={item.replace(/_/g, " ")}
                                width="150"
                              />
                            );
                          });
                        }
                        return null;
                      }
                    )}

                  {apiData[ix] &&
                    apiData[ix][singleItem] &&
                    apiData[ix][singleItem][0] &&
                    Object.keys(apiData[ix][singleItem][0]).map(
                      (key, index) => {
                        if (apiData[ix][singleItem][0][key]["tier"]) {
                          return Object.keys(
                            apiData[ix][singleItem][0][key]["tier"]
                          ).map((item) => {
                            return (
                              <ColumnDirective
                                field={item}
                                headerText={item.replace(/_/g, " ")}
                                width="150"
                              />
                            );
                          });
                        }
                        return null;
                      }
                    )}

                  {rebateHeading.map((item) => {
                    return <ColumnDirective headerText={item} width="150" />;
                  })}
                </ColumnsDirective>
              </GridComponent>
            </TabPanel>
          );
        })}
      </Box>
    )}
  </>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadSheet: (formData) =>
      dispatch(downloadContractTrendsResltsSheet(formData)),
  };
};

const mapStateToProps = (state) => {
  return {
    contractTrendsResult:
      state.addMultipleConfigurationData.contractTrendsResult,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractTrendsResult);
