import React, { useEffect, forwardRef } from 'react';
import {
    Grid,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,   
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { getMaterialUoMData } from '../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },  

}));

const UoMData = forwardRef((props) => {
    useEffect(() => {
        props.getUomdata()
    }, []);
    const [materialNumber, setMaterialNumber] = React.useState(localStorage.getItem('MaterialNumber'));
    const classes = useStyles();

    const [organizationStore, setOrganizationStore] = React.useState([]);
   
   

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

   
    useEffect(() => {
        
       if(props.uomData&&props.uomData.total_record>0){
        setOrganizationStore(props.uomData.records.filter(item=>item.material_number===materialNumber))
       }
    }, [props.uomData]);
   
    

    return (
        <div>
         
            
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="UoM DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                               <TableCell align='center'className={classes.tabHead}>Material Number</TableCell>
                                                <TableCell align='center'className={classes.tabHead}>Source UoM</TableCell>
                                                <TableCell align='center'className={classes.tabHead}>Target UoM</TableCell>
                                                <TableCell align='center'className={classes.tabHead}>Conversion Factor</TableCell>
                                               
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore
                                            .map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                       { <TableCell align='center'classes={{root:classes.fontSetting}}>{item.material_number}</TableCell>}
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.source_uom}</TableCell>
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.target_uom}</TableCell>
                                                        <TableCell align='center'classes={{root:classes.fontSetting}}>{item.conversion_factor}</TableCell>
                                                        
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
           
        </div>
    );

});

const mapDispatchToProps = dispatch => {
    return {
        getUomdata: () => dispatch(getMaterialUoMData()),
    }
}

const mapStateToProps = state => {
    return {
        uomData: state.customerData.materialUomData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UoMData);