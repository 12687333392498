import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab, Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { connect } from 'react-redux';
import { getHelpDeskData, addHelpDesk } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { AddBoxOutlined } from '@mui/icons-material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import { SmartDisplay } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const HelpDeskMainCP = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 10);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [selectedUrl, setSelectedUrl] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const allowedApps = [sessionStorage.getItem("application")];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    useEffect(() => {
        var newArray = []
        if (props.HelpDeskData && props.HelpDeskData.total_record > 0) {
            props.HelpDeskData.records.map((item) => {
                if (allowedApps.includes(item.application_type)) {
                    newArray.push(item);
                }
            })
            setAttributeData(newArray);
        }
    }, [props.HelpDeskData]);

    function editAttribute(id) {
        history.push({
            pathname: '/help-desk-cp/edit-help-cp/' + id,
            id: id
        });
    }
    function setLink(link) {
        setSelectedUrl(link)
        setOpenModal(true)
    }
    const arrayItem = [];
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > On Demand Videos </Typography>

                    {secureLocalStorage.getItem("dYtz").collaboration_id == '0' && <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        //    component={CustomRouterLink}
                        onClick={() => history.push('/help-desk-cp/add-help-cp')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>}
                </div>
                {attributeData && attributeData.length > 0 ?
                    <TableContainer style={{ marginTop: -25 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Business Process</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Topic</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Video Link </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {attributeData.map(item => {
                                    arrayItem.push(item.application_type)
                                    arrayItem.sort(function (a, b) {
                                        return a.localeCompare(b); //using String.prototype.localCompare()
                                    });
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.application_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.business_process}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.topic}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.link}</TableCell>
                                            <TableCell align='center'>
                                                {secureLocalStorage.getItem("dYtz").collaboration_id == '0' && <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editAttribute(item.ID)}
                                                    size="large">
                                                    <Edit color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>}
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => setLink(item.link)}
                                                    size="large">
                                                    <SmartDisplay color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                {secureLocalStorage.getItem("dYtz").collaboration_id == '0' && <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => props.delete(null, item.ID)}
                                                    size="large">
                                                    <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            // count={props.HelpDeskData && props.HelpDeskData.total_record > 0 ? props.HelpDeskData.total_record : 0}
                            count={attributeData && attributeData.length > 0 ? attributeData.length : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        />
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
            <Dialog open={openModal} fullScreen>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                {/* {currentPage === 'customer-master' ?
                                    <iframe src="https://www.loom.com/embed/73ec272073984a91b6dc826ff0251041" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                                    :
                                    <iframe src="https://www.loom.com/embed/076f1d86a11f46d2b803c0622d2da740" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>

                                } */}
                                <iframe src={selectedUrl} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
                            </div>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                }}>
                    <Button onClick={() => setOpenModal(false)} color="primary" autoFocus variant="contained"  >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        HelpDeskData: state.HelpDeskData.HelpDeskData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getHelpDeskData(pagination, limit)),
        delete: (data, id) => dispatch(addHelpDesk(data, 'delete', id, 'cp')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskMainCP);