import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    CardHeader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { createIntegrationMapping, deleteIntegrationMapping, getDefaultValuesCustomerXRef, getIntegrationMappingFields, getIntegrationSetupData } from '../../../redux/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { history } from '../../../components/Helpers';
import { DeleteForever, Check, Edit } from '@mui/icons-material';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 18
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    paperContainer: {
        minWidth: window.screen.width * .50,
        padding: "10px 30px 10px 30px"

    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const AddIntegrationMapping = props => {
    const classes = useStyles();
    const [activity, setActivity] = React.useState('');
    const [integrationName, setIntegrationName] = React.useState('');
    const [sourceField, setSourceField] = React.useState('');
    const [targetField, setTargetField] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [integrationFields, setIntegrationFields] = React.useState([]);
    const [mapID, setMapID] = React.useState(0);
    const activityArray = ["Direct - Sales Data", "Customer Master", "Material Master", "Supplier Master", "Membership", "Direct - Purchase Data", "Accrual", "COPA", "Payment"];
    const [integrationMapData, setIntegrationMapData] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [integrationNameArray, setIntegrationNameArray] = React.useState([]);
    const [editMode, setEditMode] = React.useState(false);
    const [kafkaArray, setKafkaArray] = React.useState({});
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                //width: 250
            }
        }
    }
    useEffect(() => {
        setOpen(true)
        props.getIntegrationSetupData(1, 1000)
    }, []);
    useEffect(() => {
        if (activity)
            props.getIntegrationFields(activity);
    }, [activity]);
    useEffect(() => {
        if (props.integrationFields) {

            setIntegrationFields(props.integrationFields);
        }
    }, [props.integrationFields]);
    useEffect(() => {
        var temp = []
        var tempKafka = []
        if (props.integrationList && props.integrationList.records && props.integrationList.total_record > 0) {
            props.integrationList.records.map((item, index) => {
                temp.push({ 'integration': item.name, 'activity': item.activity })
                var kafka = {
                    'name': item.name,
                    'dataSource': item.datasource,
                }
                tempKafka.push(kafka)
            })
        }
        setKafkaArray(tempKafka)
        setIntegrationNameArray(temp)
    }, [props.integrationList]);
    function handleActivity(e) {
        setActivity(e.target.value);
        setSourceField('')
        setTargetField('')
    }
    function handleContinue() {
        setOpen(false)
    }
    function handleCancelDialog() {
        history.push('/integration-mapping')
    }
    function handleSubmit(index) {
        var data = {
            "integration_name": integrationName,
            "activity": activity,
            "source_field_name": sourceField,
            "target_field_name": targetField,
        };
        if (editMode) {
            props.onSubmit(data, mapID, 'edit')
        }
        else {
            props.onSubmit(data)
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setEditMode(false);
        setEditIndex('');
        setSourceField('')
        setTargetField('')
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setMapID(item.ID)
        setActivity(item.activity);
        setSourceField(item.source_field_name)
        setTargetField(item.target_field_name)
        setIntegrationName(item.integration_name)
    }
    const handleDeleteLocally = (index) => {

        setIntegrationMapData(item => item.filter((item, i) => i !== index));
    }
    function handleIntegrationName(value) {
        setIntegrationName(value)
        setActivity('')
    }
    useEffect(() => {
        if (props.integrationData) {
            if (props.integrationData[0].integration_name == integrationName && props.integrationData[0].activity == activity)
                setIntegrationMapData(props.integrationData)
        }
    }, [props.integrationData]);
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/integration-mapping'
                >
                    Integration Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Integration Mapping</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {kafkaArray && integrationName && kafkaArray.some(item => item.name === integrationName && item.dataSource === "Kafka Producer") ? 'Target' : 'Source'} Field Name
                                    </FormLabel>
                                    <OutlinedInput
                                        value={sourceField}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setSourceField(e.target.value)} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel className={classes.formLabel} required>
                                        {kafkaArray && integrationName && kafkaArray.some(item => item.name === integrationName && item.dataSource === "Kafka Producer") ? 'Source' : 'Target'} Field Name
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => setTargetField(e.target.value)}
                                        value={targetField}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >
                                        <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {integrationFields.map(item => {
                                            return (
                                                <MenuItem value={item} key={item} name={item} style={{ textTransform: 'capitalize' }}>
                                                    {item.replace(/_/g, ' ')}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 23, marginTop: 15 }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Edit />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit(editIndex)}
                                                    disabled={sourceField && targetField ? false : true}
                                                    style={{ marginTop: 9 }}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Check />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={sourceField && targetField ? false : true}
                                                    onClick={handleSubmit}
                                                    style={{ marginTop: 9 }}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >   <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteForever />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.deleteIconContainer,
                                            }}
                                            style={{ marginTop: 9 }}
                                            onClick={handleRemove}
                                        >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div style={{ marginLeft: "-8px" }} className={classes.row} >
                    <CardHeader
                        title="INTEGRATION MAPPING DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {integrationMapData.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead} >Integration Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead}  >Activity</TableCell>
                                                <TableCell align='center' className={classes.tabHead} >{kafkaArray && integrationName && kafkaArray.some(item => item.name === integrationName && item.dataSource === "Kafka Producer") ? 'Target' : 'Source'} Field Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead} >{kafkaArray && integrationName && kafkaArray.some(item => item.name === integrationName && item.dataSource === "Kafka Producer") ? 'Source' : 'Target'} Field Name</TableCell>
                                                <TableCell align='center' className={classes.tabHead} style={{ width: 70 }}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {integrationMapData.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>{console.log(item.integration_id)}
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.integration_name}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.activity}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.source_field_name}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.target_field_name.replace(/_/g, ' ')}</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <Edit color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => props.onDelete(item.ID, item.integration_id)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteForever />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paperContainer }}>
                <div className={classes.container}>
                    <Grid container spacing={2} style={{ padding: '16px 25px 16px 25px' }}>

                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormLabel className={classes.formLabel} required>
                                Integration Name
                            </FormLabel>
                            <Select
                                onChange={(e) => handleIntegrationName(e.target.value)}
                                value={integrationName}

                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                })}
                            >
                                {integrationNameArray.map((item, index) => {
                                    return (
                                        <MenuItem value={item.integration} key={index} name={item.integration}>
                                            {item.integration}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <FormLabel className={classes.formLabel} required>
                                Activity
                            </FormLabel>
                            <Select
                                onChange={handleActivity}
                                value={activity}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                                className={clsx({
                                    [classes.select]: true,
                                })}
                            >  <MenuItem value="">
                                    {props.placeholder}
                                </MenuItem>
                                {integrationName && integrationNameArray
                                    .filter(item => item.integration == integrationName)
                                    .map((item, index) => {
                                        return (
                                            <MenuItem value={item.activity} key={index} name={item.activity}>
                                                {item.activity}
                                            </MenuItem>
                                        )
                                    })}
                            </Select>
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained"
                        onClick={handleContinue} disabled={integrationName && activity ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        integrationData: state.customerData.integrationMappingSingleData,
        integrationFields: state.customerData.integrationMappingFields,
        integrationList: state.customerData.integrationSetupData,
        savedMapData: state.customerData.savedMapData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, id, type) => dispatch(createIntegrationMapping(data, id, type)),
        onDelete: (id, intID) => dispatch(deleteIntegrationMapping(id, intID)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef()),
        getIntegrationFields: (activity) => dispatch(getIntegrationMappingFields(activity)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddIntegrationMapping);