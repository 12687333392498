import React, { useState, useEffect } from 'react';
import secureLocalStorage from "react-secure-storage";
function IdleTimerContainer() {
    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        let idleTimeout;

        const handleIdle = () => {
            setIsIdle(true);
            idleTimeout = window.setTimeout(logout, 15 * 60 * 1000); // 20 minutes 20 * 60 * 1000
        };

        const handleActive = () => {
            setIsIdle(false);
            clearTimeout(idleTimeout);
        };

        const logout = () => {
            // Perform logout action here, such as redirecting to the login page
            //window.location.href = '/login';
            localStorage.setItem('loggedIn', false)
            localStorage.clear();
            secureLocalStorage.clear();
            window.location.href = '/login';
        };

        const timeout = window.setTimeout(handleIdle, 5000); // Set the time for idle

        window.addEventListener('mousemove', handleActive);
        window.addEventListener('keydown', handleActive);
        window.addEventListener('click', handleActive);

        return () => {
            window.clearTimeout(timeout);
            window.clearTimeout(idleTimeout);
            window.removeEventListener('mousemove', handleActive);
            window.removeEventListener('keydown', handleActive);
            window.removeEventListener('click', handleActive);
        };
    }, []);
}
export default IdleTimerContainer;
// import React, { useState, useRef } from 'react'
// import { useIdleTimer } from 'react-idle-timer'
// import Modal from 'react-modal'
// import { history } from './Helpers';
// import { Dialog, DialogActions, DialogContent } from '@mui/material';
// import Button from '@mui/material/Button';
// import secureLocalStorage from "react-secure-storage";


// Modal.setAppElement('#root')

// function IdleTimerContainer() {
//     const onPrompt = () => {
//         setModalIsOpen(true)
//     }

//     const onActive = (event) => {
//     }

//     const onAction = (event) => {
//         // Do something when a user triggers a watched event
//     }
//     const onIdle = () => {
//         if (history.location.pathname != '/login') {
//             clearTimeout(sessionTimeoutRef.current)
//             localStorage.setItem('loggedIn', false)
//             localStorage.clear();
//             secureLocalStorage.clear();
//             history.push('/login');
//         }
//     }

//     const { } = useIdleTimer({
//         onPrompt,
//         onIdle,
//         onActive,
//         onAction,
//         timeout: 1000 * 60 * 30,
//         promptTimeout: 0,
//         events: [
//             'mousemove',
//             'keydown',
//             'wheel',
//             'DOMMouseScroll',
//             'mousewheel',
//             'mousedown',
//             'touchstart',
//             'touchmove',
//             'MSPointerDown',
//             'MSPointerMove',
//             'visibilitychange'
//         ],
//         immediateEvents: [],
//         debounce: 0,
//         throttle: 0,
//         eventsThrottle: 200,
//         element: document,
//         startOnMount: true,
//         startManually: false,
//         stopOnIdle: false,
//         crossTab: false,
//         syncTimers: 0
//     })
//     const [modalIsOpen, setModalIsOpen] = useState(false)
//     const sessionTimeoutRef = useRef(null)
//     const logOut = () => {
//         setModalIsOpen(false)
//         clearTimeout(sessionTimeoutRef.current)
//         localStorage.setItem('loggedIn', false)
//         localStorage.clear();
//         secureLocalStorage.clear();
//         history.push('/login');
//     }
//     const stayActive = () => {
//     }

//     return (
//         <div>
//             <Dialog
//                 open={modalIsOpen}
//             >
//                 <DialogContent classes={{
//                     root: { padding: '43px 37px 0px 37px !important' }
//                 }}>
//                     <h2>You've been idle for a while!</h2>
//                     <p>You will be logged out soon</p>
//                     <DialogActions>
//                         <Button onClick={logOut} color="primary" variant="outlined" >Log me out</Button>
//                         <Button onClick={stayActive} color="primary" variant="outlined" >Keep me signed in</Button>
//                     </DialogActions>
//                 </DialogContent>
//             </Dialog>
//         </div>
//     )
// }

// export default IdleTimerContainer
// import React, { useState, useEffect } from 'react';
// const IdleTimerContainer = () => {
//     const [idleTimeout, setIdleTimeout] = useState(null);

//     useEffect(() => {
//         // Reset idle timeout whenever there's user activity
//         const resetIdleTimeout = () => {
//             clearTimeout(idleTimeout);
//             setIdleTimeout(setTimeout(redirectToLogin, 1000 * 60 * 30)); // 30 minutes timeout
//         };

//         // Function to redirect to login page
//         const redirectToLogin = () => {
//             // Redirect to the login page
//             window.location.href = '/login';
//         };

//         // Set up initial idle timeout
//         const initialIdleTimeout = setTimeout(redirectToLogin, 1000 * 60 * 30); // 30 minutes timeout
//         setIdleTimeout(initialIdleTimeout);

//         // Add event listeners for user activity
//         window.addEventListener('mousemove', resetIdleTimeout);
//         window.addEventListener('keypress', resetIdleTimeout);

//         return () => {
//             // Cleanup function to remove event listeners and clear timeout
//             clearTimeout(idleTimeout);
//             window.removeEventListener('mousemove', resetIdleTimeout);
//             window.removeEventListener('keypress', resetIdleTimeout);
//         };
//     }, [idleTimeout]); // Add idleTimeout to the dependency array to avoid stale closure
//     return (
//         <div>
//         </div>
//     )
// }

// export default IdleTimerContainer


//     return (
//         <div>
//             <Dialog
//                 open={modalIsOpen}
//             >
//                 <DialogContent classes={{
//                     root: { padding: '43px 37px 0px 37px !important' }
//                 }}>
//                     <h2>You've been idle for a while!</h2>
//                     <p>You will be logged out soon</p>
//                     <DialogActions>
//                         <Button onClick={logOut} color="primary" variant="outlined" >Log me out</Button>
//                         <Button onClick={stayActive} color="primary" variant="outlined" >Keep me signed in</Button>
//                     </DialogActions>
//                 </DialogContent>
//             </Dialog>
//         </div>
//     )
// }

// export default IdleTimerContainer
// import React, { useState, useEffect } from 'react';
// const IdleTimerContainer = () => {
//     const [idleTimeout, setIdleTimeout] = useState(null);

//     useEffect(() => {
//         // Reset idle timeout whenever there's user activity
//         const resetIdleTimeout = () => {
//             clearTimeout(idleTimeout);
//             setIdleTimeout(setTimeout(redirectToLogin, 1000 * 60 * 30)); // 30 minutes timeout
//         };

//         // Function to redirect to login page
//         const redirectToLogin = () => {
//             // Redirect to the login page
//             window.location.href = '/login';
//         };

//         // Set up initial idle timeout
//         const initialIdleTimeout = setTimeout(redirectToLogin, 1000 * 60 * 30); // 30 minutes timeout
//         setIdleTimeout(initialIdleTimeout);

//         // Add event listeners for user activity
//         window.addEventListener('mousemove', resetIdleTimeout);
//         window.addEventListener('keypress', resetIdleTimeout);

//         return () => {
//             // Cleanup function to remove event listeners and clear timeout
//             clearTimeout(idleTimeout);
//             window.removeEventListener('mousemove', resetIdleTimeout);
//             window.removeEventListener('keypress', resetIdleTimeout);
//         };
//     }, [idleTimeout]); // Add idleTimeout to the dependency array to avoid stale closure
//     return (
//         <div>
//         </div>
//     );
// }

// export default IdleTimerContainer
