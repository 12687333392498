import React, { useEffect, forwardRef, useRef, useState } from 'react';
import {
    Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton, OutlinedInput, Select, MenuItem, FormLabel, TextField, Chip
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import {
    getAttributeListData, getDefaultValues,
    getDefaultValuesKeyFigure
} from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import momentt from 'moment-timezone';
import { SearchDropdown, DropdownKeyValue } from '../../../../components/Inputs';

var deleteFlag = false;
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    centerButton: {
        display: 'flex',
        marginTop: 50
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        marginTop: 15,
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        marginTop: 15
    },
    inputTwoLineAddTiming: {
        paddingLeft: 10,
        textTransform: 'capitalize',
        marginTop: 10
    }
}));

const MultiplePayment = (props) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
            }
        }
    }


    const inputFile = useRef(null)
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [paymentData, setPaymentData] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [paymentPartnerType, setPaymentPartnerType] = React.useState('');
    const [rebateRecipient, setRebateRecipient] = React.useState('');
    const [paymentpartner, setPaymentPartner] = React.useState('');
    const [paymentMethod, setPaymentMethod] = React.useState('');

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);

    useEffect(() => {
        if (props.payment_method)
            setPaymentMethod(props.payment_method)

    }, [props.payment_method])
    useEffect(() => {
        if (props.data) {
            if (props.data.contract_multiple_payment_rules && props.data.contract_multiple_payment_rules.length > 0)
                setPaymentData(props.data.contract_multiple_payment_rules);
        }
    }, [props.data]);
    const editPaymentData = (rule, index) => {
        setEditIndex(index);
        setEditMode(true);
        setEditableData(rule)
        setRebateRecipient(rule.rebate_partner)
        setPaymentPartnerType(rule.payment_partner_type)
        setPaymentPartner(rule.payment_partner)
        setPaymentMethod(rule.payment_method)
    }

    function handleSubmit(id) {

        var data = {
            "rebate_partner_type": props.partnerType,
            "rebate_partner": rebateRecipient,
            "payment_partner_type": paymentPartnerType,
            "payment_partner": paymentpartner,
            "payment_method": paymentMethod,

        };


        if (id || id === 0) {
            var editedArray = [...paymentData];
            editedArray[editIndex] = data;
            setPaymentData(editedArray);
            props.onChange(editedArray)
        } else {
            setPaymentData([...paymentData, data]);

            props.onChange([...paymentData, data])
        }
        handleRemove();

    }
    function handleRemove() {
        setEditMode(false);
        setRebateRecipient(' ');
        // if (paymentMethod != props.payment_method)
        setPaymentMethod(' ');
        setPaymentPartner(' ');
        setPaymentPartnerType(' ')

    }


    const handleDeleteLocally = (index) => {
        deleteFlag = true;
        setPaymentData(item => item.filter((item, i) => i !== index));
    }

    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...paymentData];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [paymentData])

    function handlePaymentPartnerType(newValue) {
        setPaymentPartnerType(newValue);
    }
    function handlePaymentMethod(newValue) {
        setPaymentMethod(newValue);
    }
    return (
        <div>
            {/* Eligibility Rules Add */}
            <div className={clsx(classes.root, className)}>
                <Card>
                    {/* <form
                        autoComplete="off"
                        noValidate
                    > */}
                    <div className={classes.container}>

                        <Grid container spacing={1} >
                            <Grid
                                item
                                md={9}
                                xs={12}
                                container spacing={1}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel required className={classes.formDiv}>
                                            Rebate Partner Type
                                        </FormLabel>
                                        <OutlinedInput
                                            value={props.partnerType}
                                            classes={{ root: classes.inputTwoLineAddTiming }}
                                            disabled
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.dropdownContainer}>
                                        <FormLabel required>
                                            Rebate Recipient
                                        </FormLabel>
                                        <SearchDropdown id='rebate-recipient'
                                            table={props.partnerType == 'supplier' ? 'supplier_master' : props.partnerType == 'employee' ? 'employee_master' : 'customer_master'}
                                            keyName={props.partnerType == 'supplier' ? 'supplier_number' : props.partnerType == 'employee' ? 'employee_number' : 'customer_number'}
                                            description={true}
                                            onChange={(value) => setRebateRecipient(value)}
                                            prevalue={rebateRecipient}
                                        />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '0.1rem' }}
                                >
                                    <div className={classes.dropdownContainer}>
                                        <FormLabel required style={{ marginBottom: 8 }}>
                                            Payment Partner Type
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <DropdownKeyValue data={props.fieldData && props.fieldData['payment_partner_role'] && props.fieldData['payment_partner_role']['drop_down_reference_value']}
                                                onChange={handlePaymentPartnerType} labelFormat={'desc'} id="type"
                                                twoline="true" placeholder={' '} prevalue={paymentPartnerType ? paymentPartnerType : ''}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div className={classes.dropdownContainer}>
                                        <FormLabel required>
                                            Payment Partner
                                        </FormLabel>
                                        <SearchDropdown id='payment_partner'
                                            table={paymentPartnerType == 'supplier' ? 'supplier_master' : paymentPartnerType == 'employee' ? 'employee_master' : 'customer_master'}
                                            keyName={paymentPartnerType == 'supplier' ? 'supplier_number' : paymentPartnerType == 'employee' ? 'employee_number' : 'customer_number'}
                                            description={true}
                                            onChange={(value) => setPaymentPartner(value)}
                                            prevalue={paymentpartner}
                                        />
                                    </div>
                                </Grid>
                            </Grid>


                            <Grid item md={3} xs={12} container spacing={1}>
                                <Grid item md={8} xs={12} style={{ marginTop: '0.1rem' }} >
                                    <div className={classes.dropdownContainer}>
                                        <FormLabel required style={{ marginBottom: 8 }}>
                                            {props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['current']}
                                        </FormLabel>
                                        <div style={{ marginTop: -10 }}>
                                            <DropdownKeyValue
                                                data={props.fieldData && props.fieldData['payment_method'] && props.fieldData['payment_method']['drop_down_reference_value']}
                                                onChange={handlePaymentMethod} placeholder={' '} twoline='true' id={'payment_method'}
                                                prevalue={paymentMethod ? paymentMethod : ''} labelFormat={'desc'}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingBottom: 15, paddingTop: 4 }} >
                                        {editMode ?
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!paymentPartnerType || !paymentpartner
                                                    || !paymentMethod || !rebateRecipient ? true : false}
                                                onClick={() => handleSubmit(editIndex)}
                                            >
                                            </Button>
                                            :
                                            <Button
                                                color='secondary'
                                                variant="contained"
                                                startIcon={<CheckIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.checkIconContainer
                                                }}
                                                disabled={!paymentPartnerType || !paymentpartner
                                                    || !paymentMethod || !rebateRecipient ? true : false}
                                                onClick={() => handleSubmit('')}
                                            >
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DeleteForeverIcon />}
                                            classes={{
                                                startIcon: classes.startIcon,
                                                root: classes.deleteIconContainer,
                                            }}
                                            onClick={handleRemove}
                                            style={{ marginLeft: 10 }}
                                        >
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                    {/* </form> */}
                </Card >
            </div >
            <Card style={{ marginTop: '-1rem' }}>
                <div className={classes.row} style={{ marginTop: '-1rem' }} >
                    <CardHeader
                        title="MULTIPLE PAYMENTS"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2} style={{ marginTop: '-1.5rem' }}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {paymentData.length > 0 ?
                                <TableContainer>

                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' >Rebate Partner Type</TableCell>
                                                <TableCell align='center'>Rebate Recipient</TableCell>
                                                <TableCell align='center'>Payment Partner Type</TableCell>
                                                <TableCell align='center' >Payment Partner</TableCell>
                                                <TableCell align='center' >Payment Method</TableCell>
                                                <TableCell align='center' >Options</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {paymentData
                                                .map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={item.ID}>
                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.rebate_partner_type?.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell align='center' >{item.rebate_partner}</TableCell>
                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>{item.payment_partner_type?.replace(/_/g, ' ')}</TableCell>

                                                            <TableCell align='center'>{item.payment_partner}</TableCell>
                                                            <TableCell align='center'>{item.payment_method}</TableCell>

                                                            <TableCell align='center'>
                                                                <IconButton
                                                                    onClick={() => editPaymentData(item, index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => handleDeleteLocally(index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>



                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {/* Eligibility Rules Table Ends here */}
        </div >
    );

}



export default connect(null, null)(MultiplePayment);