import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button
} from '@mui/material';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import Moment from 'moment';
import { runCalculationReports, downloadSheet, getDefaultValues, getDefaultValuesCalculationSimulation } from '../../../redux/actions';
import CheckIcon from '@mui/icons-material/Check';
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        marginBottom: 15
    },
    rootSearch: {
        borderBottomColor: theme.palette.table.border,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'flex-end',
        borderRadius: 0,
        padding: 0,
        minWidth: 200,
        [theme.breakpoints.down('lg')]: {
            minWidth: 0,
            marginTop: 10,
            marginRight: 15,
        },
    },
    input: {
        fontSize: 14,
        flexGrow: 1,
        lineHeight: '16px',
        letterSpacing: '-0.05px',
        paddingLeft: 10,
    },
    iconButton: {
        padding: 0,
    },
    icon: {
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

var enabledFormFields = [];
const CalculationSimulationReportsTable = props => {
    var newArray = [];
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [tabHeads, setTabHeads] = React.useState([]);
    const [tabKeys, setTabKeys] = React.useState([]);
    const [calculationSimulationResultsArray, setCalculationSimulationResultsArray] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.runCalculationReports(newPage + 1, rowsPerPage, '');
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.runCalculationReports(1, event.target.value, '');
    };
    const handleSheetDownload = () => {
        props.downloadSheet(calculationSimulationResultsArray.records);
    }
    const [searchText, setSearchText] = React.useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    };
    const contractCustomisationUpdation = useRef();
    useEffect(() => {
        if (props.calculationSimulationCompleteResults) {
            setCalculationSimulationResultsArray(props.calculationSimulationCompleteResults);
        }
    }, [props.calculationSimulationCompleteResults]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(() => {
        var keywordsArray = [ 'calculation_simulation_number', 'calendar_year_month', 'start_date', 'end_date', 'contract_number', 'contract_type',
            'billing_date', 'billing_doc_number', 'billing_doc_line', 'customer_number', 'net_value', 'quantity', 'billed_uom', 'tier_basis',
            'incentive_basis', 'incentive_rate', 'incentive_amount', 'material_number', 'tier_period', 'tier_start_date', 'tier_end_date', 'supplier_number',
            'employee_number', 'customer_name', 'material_description', 'supplier_name', 'employee_name', 'tags', 'unit_price', 'currency', 'fiscal_year_month',
            'base_uom', 'pricing_date', 'profit_center', 'order_reason', 'maximum_incentive_amount', 'company_code', 'sales_org', 'distribution_channel', 'division',
            'flex_attribute1', 'flex_attribute2', 'flex_attribute3', 'flex_attribute4', 'flex_attribute5', 'flex_key_figure1', 'flex_key_figure2', 'flex_key_figure3',
            'flex_key_figure4','flex_key_figure5','flex_key_figure6','flex_key_figure7','flex_key_figure8','flex_key_figure9','flex_key_figure10','incentive_basis1', 'incentive_basis2', 'incentive_basis3', 'incentive_basis4', 'incentive_basis5',
            'sold_to_party', 'ship_to_party', 'bill_to_party', 'payer', 'customer_group', 'customer_chain', 'customer_group1', 'customer_group2', 'customer_group3',
            'customer_group4', 'material_group', 'material_group1', 'material_group2', 'material_type', 'product_hierarchy', 'product_hierarchy_level1',
            'product_hierarchy_level2', 'product_hierarchy_level3', 'product_hierarchy_level4', 'product_hierarchy_level5', 'supplier_group', 'supplier_customer_number','sales_district',
            'sales_group', 'sales_office', 'region', 'country', 'territory_id', 'sales_order_type', 'sales_order_number', 'sales_order_line', 'delivery_type',
            'delivery_doc_number', 'delivery_doc_line', 'document_category', 'billing_type', 'item_category', 'calculation_method', 'contract_group',
            'contract_sub_group', 'contract_internal_description', 'accrual_frequency', 'payment_frequency', 'posting_schema', 'incentive_rate_type',
            'debit_credit_indicator', 'billing_doc_cancelled', 'cancelled_billing_doc', 'original_invoice_for_return', 'original_invoice_line_for_return', 'controlling_area',
            'cost_center', 'mfr_part_number', 'pa_segment_number_copa', 'pricing_procedure', 'payment_terms', 'plant', 'higher_level_bom_item', 'source_system',
            'ima_ineligible', 'ima_ineligible_application', 'zero_value_item', 'accounting_doc_line', 'accounting_doc_number', 'billing_document_created_by',
            'billing_document_created_date', 'billing_document_changed_by', 'billing_document_changed_date','billingDocument_extracted_by',
            'billing_document_extracted_date', 'deleted', 'accrual_created_by','created_by_id'
        ];

        if (!contractCustomisationUpdation.current) {
            if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
                enabledFormFields = props.tableData.records.filter(itemY => itemY.enabled != false).map(itemY => { return itemY.field_id });
            }
            /*setTabHeads(tabHeads => [...tabHeads, 'Contract Number']);
            setTabKeys(tabKeys => [...tabKeys, 'contract_number']);
            setTabHeads(tabHeads => [...tabHeads, 'Tags']);
            setTabKeys(tabKeys => [...tabKeys, 'tags']);*/

            if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
                keywordsArray.forEach(function (elem) {
                    if(elem ==='contract_number'){
                        setTabHeads(tabHeads => [...tabHeads, 'Contract Number']);
                        setTabKeys(tabKeys => [...tabKeys, 'contract_number']);  
                    }
                    else if(elem ==='supplier_customer_number'){
                        setTabHeads(tabHeads => [...tabHeads, 'Supplier Customer Number']);
                        setTabKeys(tabKeys => [...tabKeys, 'supplier_customer_number']);  
                    }
                    else if(elem ==='created_by_id'){
                        setTabHeads(tabHeads => [...tabHeads, 'Created By ID']);
                        setTabKeys(tabKeys => [...tabKeys, 'created_by_id']);  
                    }
                    else{
                         props.tableData.records
                        .filter(itemY => itemY.field_id === elem)
                        .filter(itemY => itemY.enabled == true)
                        .map(itemY => {
                            if(itemY.field_id ==='accrual_created_by'){
                                setTabHeads(tabHeads => [...tabHeads, 'Created By']); 
                            }
                            else{
                                setTabHeads(tabHeads => [...tabHeads, itemY.name]);
                            }
                            
                            setTabKeys(tabKeys => [...tabKeys, itemY.field_id]);
                        });
                    }
                   
                });
            }
            // props.calculationSimulationResults.records
            //     .map(itemY => {
            //         Object.entries(itemY).forEach(([key, value]) => {
            //             if (keywordsArray.includes(key)) {
            //                 console.log(key + value)
            //             }
            //         });
            //         for (var key of keywordsArray) {
            //             c[key] = itemY[key];
            //         }
            //     });
        }
    }, [props.tableData]);

    useEffect(() => {
        setTabHeads(tabHeads.concat(newArray))
    }, []);

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/calculation-simulation-reports'
                >
                    Calculation Simulation Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Calculation Simulation Report Results </Typography>
                    <div className={clsx({ [classes.searchInput]: isDesktop })}>
                        <Paper
                            className={clsx(classes.rootSearch, className)}
                        >
                            <SearchIcon className={classes.icon} />
                            <Input
                                className={classes.input}
                                disableUnderline
                                placeholder="Search"
                                onChange={handleSearchOnChange}
                            />
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="Close"
                                onClick={() => props.runCalculationReports(0, 10, searchText)}
                                size="large">
                                <CheckIcon className={classes.icon} />
                            </IconButton>
                        </Paper>
                    </div>
                </div>
                {props.calculationSimulationResults && props.calculationSimulationResults.records && props.calculationSimulationResults.records.length > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    {tabHeads.map(item => {
                                        return (
                                            <TableCell align={item.align ? 'left' : 'center'} className={classes.tableCell} key={item.ID}>
                                                {item}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }} >
                                {props.calculationSimulationResults && props.calculationSimulationResults.records && props.calculationSimulationResults.records.length > 0 && props.calculationSimulationResults.records
                                    .map(item => {
                                        {
                                            return (
                                                <StyledTableRow>
                                                    {tabKeys.includes('calculation_simulation_number') ?
                                                        <TableCell align='center'>{item.calculation_simulation_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('calendar_year_month') ?
                                                        <TableCell align='center'>{item.calendar_year_month}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('start_date') ?
                                                        <TableCell align='center'>{Moment.utc(item.start_date).format('MM/DD/YYYY')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('end_date') ?
                                                        <TableCell align='center'>{Moment.utc(item.end_date).format('MM/DD/YYYY')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('contract_number') ?
                                                        <TableCell className={classes.tableCell}> {item.contract_number}</TableCell> 
                                                        : ''}
                                                    {tabKeys.includes('contract_type') ?
                                                        <TableCell align='center'>{item.contract_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_date') ?
                                                        <TableCell align='center'>{item.billing_date && Moment.utc(item.billing_date).format('MM/DD/YYYY')}</TableCell> : ''}

                                                    {tabKeys.includes('billing_doc_number') ?
                                                        <TableCell align='center'>{item.billing_doc_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_doc_line') ?
                                                        <TableCell align='center'>{item.billing_doc_line}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_number') ?
                                                        <TableCell align='center'>{item.customer_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('net_value') ?
                                                        <TableCell align='center'>{item.net_value.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('quantity') ?
                                                        <TableCell align='center'>{item.quantity}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billed_uom') ?
                                                        <TableCell align='center'>{item.billed_uom}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('tier_basis') ?
                                                        <TableCell align='center'>{item.tier_basis}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_basis') ?
                                                        <TableCell align='center'>{item.incentive_basis.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_rate') ?
                                                        <TableCell align='center'>{item.incentive_rate.toFixed(4)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_amount') ?
                                                        <TableCell align='center'>{item.incentive_amount.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_number') ?
                                                        <TableCell align='center'>{item.material_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('tier_period') ?
                                                        <TableCell align='center'>{item.tier_period}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('tier_start_date') ?
                                                        <TableCell align='center'>{Moment.utc(item.tier_start_date).format('MM/DD/YYYY')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('tier_end_date') ?
                                                        <TableCell align='center'>{Moment.utc(item.tier_end_date).format('MM/DD/YYYY')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('supplier_number') ?
                                                        <TableCell align='center'>{item.supplier_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('employee_number') ?
                                                        <TableCell align='center'>{item.employee_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_name') ?
                                                        <TableCell align='center'>{item.customer_name}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_description') ?
                                                        <TableCell align='center'>{item.material_description}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('supplier_name') ?
                                                        <TableCell align='center'>{item.supplier_name}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('employee_name') ?
                                                        <TableCell align='center'>{item.employee_name}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('tags') ?
                                                        <TableCell className={classes.tableCell}> {item['tags']}</TableCell> : ''
                                                    }
                                                    {tabKeys.includes('unit_price') ?
                                                        <TableCell align='center'>{item.unit_price && item.unit_price.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('currency') ?
                                                        <TableCell align='center'>{item.currency}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('fiscal_year_month') ?
                                                        <TableCell align='center'>{item.fiscal_year_month}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('base_uom') ?
                                                        <TableCell align='center'>{item.base_uom}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('pricing_date') ?
                                                        <TableCell align='center'>{Moment.utc(item.pricing_date).format('MM/DD/YYYY')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('profit_center') ?
                                                        <TableCell align='center'>{item.profit_center}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('order_reason') ?
                                                        <TableCell align='center'>{item.order_reason}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('maximum_incentive_amount') ?
                                                        <TableCell align='center'>{item.maximum_incentive_amount}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('company_code') ?
                                                        <TableCell align='center'>{item.company_code}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_org') ?
                                                        <TableCell align='center'>{item.sales_org}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('distribution_channel') ?
                                                        <TableCell align='center'>{item.distribution_channel}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('division') ?
                                                        <TableCell align='center'>{item.division}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_attribute1') ?
                                                        <TableCell align='center'>{item.flex_attribute1}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_attribute2') ?
                                                        <TableCell align='center'>{item.flex_attribute2}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_attribute3') ?
                                                        <TableCell align='center'>{item.flex_attribute3}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_attribute4') ?
                                                        <TableCell align='center'>{item.flex_attribute4}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_attribute5') ?
                                                        <TableCell align='center'>{item.flex_attribute5}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure1') ?
                                                        <TableCell align='center'>{item.flex_key_figure1 && item.flex_key_figure1.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure2') ?
                                                        <TableCell align='center'>{item.flex_key_figure2 && item.flex_key_figure2.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure3') ?
                                                        <TableCell align='center'>{item.flex_key_figure3 && item.flex_key_figure3.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure4') ?
                                                        <TableCell align='center'>{item.flex_key_figure4 && item.flex_key_figure4.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure5') ?
                                                        <TableCell align='center'>{item.flex_key_figure5 && item.flex_key_figure5.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('flex_key_figure6') ?
                                                        <TableCell>{item.flex_key_figure6 && item.flex_key_figure6.toFixed(2)}</TableCell>
                                                        :''}
                                                    {tabKeys.includes('flex_key_figure7') ?
                                                        <TableCell>{item.flex_key_figure7 && item.flex_key_figure7.toFixed(2)}</TableCell>
                                                         :''}
                                                    {tabKeys.includes('flex_key_figure8') ?
                                                        <TableCell>{item.flex_key_figure8 && item.flex_key_figure8.toFixed(2)}</TableCell>
                                                        :''}
                                                    {tabKeys.includes('flex_key_figure9') ?
                                                        <TableCell>{item.flex_key_figure9 && item.flex_key_figure9.toFixed(2)}</TableCell>
                                                         :''}
                                                    {tabKeys.includes('flex_key_figure10') ?
                                                        <TableCell>{item.flex_key_figure10 && item.flex_key_figure10.toFixed(2)}</TableCell>
                                                        :''}
                                                    {tabKeys.includes('incentive_basis1') ?
                                                        <TableCell align='center'>{item.incentive_basis1 && item.incentive_basis1.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_basis2') ?
                                                        <TableCell align='center'>{item.incentive_basis2 && item.incentive_basis2.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_basis3') ?
                                                        <TableCell align='center'>{item.incentive_basis3 && item.incentive_basis3.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_basis4') ?
                                                        <TableCell align='center'>{item.incentive_basis4 && item.incentive_basis4.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('incentive_basis5') ?
                                                        <TableCell align='center'>{item.incentive_basis5 && item.incentive_basis5.toFixed(2)}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sold_to_party') ?
                                                        <TableCell align='center'>{item.sold_to_party}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('ship_to_party') ?
                                                        <TableCell align='center'>{item.ship_to_party}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('bill_to_party') ?
                                                        <TableCell align='center'>{item.bill_to_party}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('payer') ?
                                                        <TableCell align='center'>{item.payer}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_group') ?
                                                        <TableCell align='center'>{item.customer_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_chain') ?
                                                        <TableCell align='center'>{item.customer_chain}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_group1') ?
                                                        <TableCell align='center'>{item.customer_group1}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_group2') ?
                                                        <TableCell align='center'>{item.customer_group2}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_group3') ?
                                                        <TableCell align='center'>{item.customer_group3}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('customer_group4') ?
                                                        <TableCell align='center'>{item.customer_group4}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_group') ?
                                                        <TableCell align='center'>{item.material_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_group1') ?
                                                        <TableCell align='center'>{item.material_group1}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_group2') ?
                                                        <TableCell align='center'>{item.material_group2}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('material_type') ?
                                                        <TableCell align='center'>{item.material_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy') ?
                                                        <TableCell align='center'>{item.product_hierarchy}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy_level1') ?
                                                        <TableCell align='center'>{item.product_hierarchy_level1}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy_level2') ?
                                                        <TableCell align='center'>{item.product_hierarchy_level2}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy_level3') ?
                                                        <TableCell align='center'>{item.product_hierarchy_level3}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy_level4') ?
                                                        <TableCell align='center'>{item.product_hierarchy_level4}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('product_hierarchy_level5') ?
                                                        <TableCell align='center'>{item.product_hierarchy_level5}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('supplier_group') ?
                                                        <TableCell align='center'>{item.supplier_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('supplier_customer_number') ?
                                                        <TableCell align='center'>{item.supplier_customer_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_district') ?
                                                        <TableCell align='center'>{item.sales_district}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_group') ?
                                                        <TableCell align='center'>{item.sales_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_office') ?
                                                        <TableCell align='center'>{item.sales_office}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('region') ?
                                                        <TableCell align='center'>{item.region}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('country') ?
                                                        <TableCell align='center'>{item.country}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('territory_id') ?
                                                        <TableCell align='center'>{item.territory_id}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_order_type') ?
                                                        <TableCell align='center'>{item.sales_order_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_order_number') ?
                                                        <TableCell align='center'>{item.sales_order_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('sales_order_line') ?
                                                        <TableCell align='center'>{item.sales_order_line}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('delivery_type') ?
                                                        <TableCell align='center'>{item.delivery_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('delivery_doc_number') ?
                                                        <TableCell align='center'>{item.delivery_doc_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('delivery_doc_line') ?
                                                        <TableCell align='center'>{item.delivery_doc_line}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('document_category') ?
                                                        <TableCell align='center'>{item.document_category}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_type') ?
                                                        <TableCell align='center'>{item.billing_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('item_category') ?
                                                        <TableCell align='center'>{item.item_category}</TableCell>
                                                        : ''}
                                                    
                                                    {tabKeys.includes('calculation_method') ?
                                                        <TableCell align='center'>{item.calculation_method}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('contract_group') ?
                                                        <TableCell align='center'>{item.contract_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('contract_sub_group') ?
                                                        <TableCell align='center'>{item.contract_sub_group}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('contract_internal_description') ?
                                                        <TableCell align='center'>{item.contract_internal_description}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('accrual_frequency') ?
                                                        <TableCell align='center'>{item.accrual_frequency}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('payment_frequency') ?
                                                        <TableCell align='center'>{item.payment_frequency}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('posting_schema') ?
                                                        <TableCell align='center'>{item.posting_schema}</TableCell>
                                                        : ''}
                                                   
                                                    {tabKeys.includes('incentive_rate_type') ?
                                                        <TableCell align='center'>{item.incentive_rate_type}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('debit_credit_indicator') ?
                                                        <TableCell align='center'>{item.debit_credit_indicator}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_doc_cancelled') ?
                                                        <TableCell align='center'>{item.billing_doc_cancelled}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('cancelled_billing_doc') ?
                                                        <TableCell align='center'>{item.cancelled_billing_doc}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('original_invoice_for_return') ?
                                                        <TableCell align='center'>{item.original_invoice_for_return}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('original_invoice_line_for_return') ?
                                                        <TableCell align='center'>{item.original_invoice_line_for_return}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('controlling_area') ?
                                                        <TableCell align='center'>{item.controlling_area}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('cost_center') ?
                                                        <TableCell align='center'>{item.cost_center}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('mfr_part_number') ?
                                                        <TableCell align='center'>{item.mfr_part_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('pa_segment_number_copa') ?
                                                        <TableCell align='center'>{item.pa_segment_number_copa}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('pricing_procedure') ?
                                                        <TableCell align='center'>{item.pricing_procedure}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('payment_terms') ?
                                                        <TableCell align='center'>{item.payment_terms}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('plant') ?
                                                        <TableCell align='center'>{item.plant}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('higher_level_bom_item') ?
                                                        <TableCell align='center'>{item.higher_level_bom_item}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('source_system') ?
                                                        <TableCell align='center'>{item.source_system}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('ima_ineligible') ?
                                                        <TableCell align='center'>{item.ima_ineligible}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('ima_ineligible_application') ?
                                                        <TableCell align='center'>{item.ima_ineligible_application}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('zero_value_item') ?
                                                        <TableCell align='center'>{item.zero_value_item}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('accounting_doc_number') ?
                                                        <TableCell align='center'>{item.accounting_doc_number}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('accounting_doc_line') ?
                                                        <TableCell align='center'>{item.accounting_doc_line}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_document_created_by') ?
                                                        <TableCell align='center'>{item.billing_document_created_by}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_document_created_date') ?
                                                        <TableCell align='center'>{item.billing_document_created_date != '0001-01-01T00:00:00Z' && Moment(item.billing_document_created_date).format('MM/DD/YYYY hh:mm')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_document_changed_by') ?
                                                        <TableCell align='center'>{item.billing_document_changed_by}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_document_changed_date') ?
                                                        <TableCell align='center'>{item.billing_document_changed_date != '0001-01-01T00:00:00Z' && Moment(item.billing_document_changed_date).format('MM/DD/YYYY hh:mm')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billingDocument_extracted_by') ?
                                                        <TableCell align='center'>{item.billingDocument_extracted_by}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('billing_document_extracted_date') ?
                                                        <TableCell align='center'>{item.billing_document_extracted_date != '0001-01-01T00:00:00Z' && Moment(item.billing_document_extracted_date).format('MM/DD/YYYY hh:mm')}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('deleted') ?
                                                        <TableCell align='center'>{item.deleted}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('accrual_created_by') ?
                                                        <TableCell align='center'>{item.created_by['email']}</TableCell>
                                                        : ''}
                                                    {tabKeys.includes('created_by_id') ?
                                                        <TableCell align='center'>{item.created_by_id}</TableCell>
                                                        : ''}
                                                </StyledTableRow>
                                            );
                                        }
                                    })}
                            </TableBody>
                        </Table>
                     
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Results
                    </Typography>
                }
            </div>
            <TablePagination
                    component="div"
                    count={props.calculationSimulationResults.total_record}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                        root: classes.paginationRoot
                    }}
             />
            {props.calculationSimulationCompleteResults &&
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                </div>
            }
        </div>
    );

};

const mapStateToProps = state => {
    return {
        calculationSimulationResults: state.operationalReportsData.calculationReportsData,
        calculationSimulationCompleteResults: state.operationalReportsData.calculationReportsCompleteData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        runCalculationReports: (pagination, limit, keyword) => dispatch(runCalculationReports(pagination, limit, keyword)),
        downloadSheet: (data) => dispatch(downloadSheet(data, 'calculation-simulations')),
        onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), localStorage.getItem('operationalReportsSourceDataType'), null, null, 'Display Designer')),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationSimulationReportsTable);