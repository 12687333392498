import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Typography,
  useMediaQuery,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  TablePagination,
  Button, Fab
} from "@mui/material";
import { connect } from "react-redux";
import {
  getAggregationLevel,
  deleteAggregationLevel,
} from "../../redux/actions";
import LoadingOverlay from "react-loading-overlay";
import { AddBoxOutlined, Check } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: '20px 16px',
  },
  container: {
    padding: 10,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10
    // paddingRight: 26,
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column",
    //   paddingLeft: 26,
    //   marginRight: -15,
    // },
  }, caption: {
    fontSize: 12
  },
  paginationRoot: {
    border: '1px solid #EEEEEE',
    borderTop: 0
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 10
    }
  },
  tabHead: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  },
  fontSetting: {
    fontSize: theme.typography.h4.fontSize
  }
}));

const CalculationAnalysisTab = (props) => {
  useEffect(() => {
    props.getAggregationLevelData(1, 100);
  }, []);
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const [aggregationLevelData, setAggregationLevelData] = React.useState([]);
  const classes = useStyles();
  const { className } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([
    5, 10, 15,
  ]);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.table.row,
        height: 46,
      },
      "&:nth-of-type(even)": {
        height: 40,
      },
    },
  }))(TableRow);
  useEffect(() => {
    if (props.aggregationLevelData) {
      setAggregationLevelData(props.aggregationLevelData);
    }
  }, [props.aggregationLevelData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.getAggregationLevelData(newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event, newValue) => {
    setPage(0);
    console.log(event.target.value);
    setRowsPerPage(event.target.value);
    props.getAggregationLevelData(1, event.target.value);
  };
  function editCalculation(id) {
    history.push({
      pathname: "/edit-calculation-analysis/" + id,
      id: id,
    });
  }

  function viewCalculation(id) {
    history.push({
      pathname: "/view-calculation-analysis/" + id,
      id: id,
    });
  }
  const deleteData = (id) => {
    props.onDelete(id);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        text="Loading Calculation Analysis. Please wait ..."
        className={classes.spinner}
      >
        <div
          className={clsx({
            [classes.row]: isDesktop,
          })}
        >
          <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Calculation Analysis </Typography>

          <Fab aria-label="edit" variant="extended"
            size='medium'
            classes={{ root: classes.fabContainer }}
            className={classes.fabContainer}
            //    component={CustomRouterLink}
            onClick={() => history.push("/add-calculation-analysis")}
          >
            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
          </Fab>
        </div>
        {aggregationLevelData && aggregationLevelData.length > 0 ? (
          <TableContainer style={{ marginTop: -25 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tabHead}>Application Type</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Source Data Type</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Contract Type</TableCell>
                  <TableCell align="center" className={classes.tabHead}>First Level Name</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Second Level</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Second Level Name</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Third Level</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Third Level Name</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Fourth Level</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Fourth Level Name</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Fifth Level</TableCell>
                  <TableCell align="center" className={classes.tabHead}>Fifth Level Name</TableCell>
                  <TableCell align="center" className={classes.tabHead} style={{ width: '6%' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody classes={{ root: classes.table }}>
                {aggregationLevelData.map((item) => {
                  return (
                    <StyledTableRow key={item.id}>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.application_type}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.data_source_type}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.contract_type}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.first_level_name ? item.first_level_name : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.second_level
                          ? item.second_level.toString().replace(/_/g, " ")
                          : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.second_level_name ? item.second_level_name : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.third_level
                          ? item.third_level.toString().replace(/_/g, " ")
                          : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.third_level_name ? item.third_level_name : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.fourth_level
                          ? item.fourth_level.toString().replace(/_/g, " ")
                          : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.fourth_level_name ? item.fourth_level_name : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.fifth_level
                          ? item.fifth_level.toString().replace(/_/g, " ")
                          : ""}
                      </TableCell>
                      <TableCell
                        align="center"
                        classes={{ root: classes.fontSetting }}
                        onClick={() => viewCalculation(item.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.fifth_level_name ? item.fifth_level_name : ""}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          classes={{ root: classes.IconButton }}
                          onClick={() => editCalculation(item.id)}
                          size="large">
                          <Edit color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                          classes={{ root: classes.IconButton }}
                          onClick={() => deleteData(item.id)}
                          size="large">
                          <DeleteForever
                            color="disabled"
                            style={{ fontSize: 20 }}
                          />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* <TablePagination
              component="div"
              count={
                props.aggregationLevelData &&
                props.aggregationLevelData.length > 0
                  ? props.aggregationLevelData.length
                  : 0
              }
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              classes={{
                caption: classes.caption,
                root: classes.paginationRoot,
              }}
            /> */}
          </TableContainer>
        ) : (
          <Typography variant="h4">There is no data to show now.</Typography>
        )}
      </LoadingOverlay>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    aggregationLevelData: state.calculationAnalysisData.aggregationLevelData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAggregationLevelData: (pagination, limit) => dispatch(getAggregationLevel(pagination, limit)),
    onDelete: (id) => dispatch(deleteAggregationLevel(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CalculationAnalysisTab);
