import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody,
    TablePagination, Button, IconButton, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import {
    getAllPaymentDesigner, deletePaymentDesigner
} from '../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { Toolbar } from './components';
import { useHistory } from "react-router-dom";
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const PaymentDesignerMain = props => {
    useEffect(() => {
        props.getAllPaymentDesigner(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [approvalData, setApprovalData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAllPaymentDesigner(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAllPaymentDesigner(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.approvalData)
            setApprovalData(props.approvalData)
    }, [props.approvalData])


    useEffect(() => {
        if (props.customerMasterXRefData) {
            setContractsTableComplete(props.customerMasterXRefData);
        }
    }, [props.customerMasterXRefData]);

    function editCustomer(id) {
        history.push({
            pathname: '/payment-designer/edit-payment/' + id,
            id: id
        });
    }
    function viewCustomer(id) {
        history.push({
            pathname: '/payment-designer/view-payment/' + id,
            id: id
        });
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Payment Designer</Typography>
                    <Toolbar />
                </div>
                {approvalData && approvalData.total_record > 0 ?
                    <TableContainer style={{ marginTop: -47 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Primary Anchor</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Payment Method</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Document Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Reversal Document Type</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Document Date</TableCell>
                                    <TableCell align='center' className={classes.tabHead} style={{ width: 62 }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {approvalData.records
                                    .sort((a, b) => a.ID < b.ID ? 1 : -1)
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.contract_number}>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.app_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.primary_anchor.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.payment_method}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.document_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.reversal_document_type}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewCustomer(item.ID)} style={{ cursor: 'pointer' }}>{item.document_date}</TableCell>
                                                <TableCell align='center'>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editCustomer(item.ID)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => props.deletePaymentDesigner(item.ID)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.approvalData && props.approvalData.records ? props.approvalData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                        {props.approvalData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>

        </div >
    );
};

const mapStateToProps = state => {
    return {
        approvalData: state.customerData.paymentDesignerGetAll
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllPaymentDesigner: (pagination, limit) => dispatch(getAllPaymentDesigner(pagination, limit)),
        deletePaymentDesigner: (id) => dispatch(deletePaymentDesigner(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDesignerMain);