import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery, Dialog, DialogActions, Button, Typography, Fab } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getAllHolidayData, createHolidayData, editHolidayData } from '../../redux/actions';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const HolidayCalendar = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEvents, setCurrentEvents] = React.useState([]);
    const [values, setValues] = React.useState([]);
    const [currentValues, setCurrentValues] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [isEdit, setEdit] = React.useState(false);
    useEffect(() => {
        props.getAllHolidayData();
    }, []);
    useEffect(() => {
        if (props.holidayData > 0) {
            setCurrentEvents(props.holidayData.holiday);
        } else {
            setCurrentEvents(props.holidayData);
        }
    }, [props.holidayData]);
    const handleSubmit = (event) => {
        event.preventDefault();
        var tempHolidays = [];
        if (isEdit) {
            currentValues.map((item) => {
                tempHolidays.push(item.year + '-' + item.month.number + '-' + item.day);
            });
            props.editHolidayData({
                ID: props.holidayData.ID,
                holiday: tempHolidays
            });
        } else {
            values.map((item) => {
                tempHolidays.push(item.year + '-' + item.month.number + '-' + item.day);
            });
            props.createHolidayData({
                holiday: tempHolidays
            });
        }
        setOpen(false);

    }
    const cancelEdit = () => {
        setOpen(false);
    }
    const handleCreateEvent = (event) => {
        event.preventDefault();
        setValues([]);
        setOpen(true);
        setEdit(false);
    }
    const handleEditEvent = (event) => {
        event.preventDefault();
        var tempData = [];
        if (props.holidayData && props.holidayData.holiday && props.holidayData.holiday.length > 0) {
            props.holidayData.holiday.map((item) => {
                tempData.push(item.start);
            });
        }
        setCurrentValues(tempData);
        setOpen(true);
        setEdit(true);
    }
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading contracts. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} >  Holiday Calendar </Typography>
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={(event) => handleCreateEvent(event)}
                        >
                            <Typography color='primary' style={{ marginLeft: 3 }}>Create Holiday</Typography>
                        </Fab>

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            //    component={CustomRouterLink}
                            onClick={(event) => handleEditEvent(event)}
                        >
                            <Typography color='primary' style={{ marginLeft: 3 }}>Update Holiday</Typography>
                        </Fab>
                    </div>
                </div>
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Dialog open={open} maxWidth="md">
                        <Calendar
                            value={isEdit ? currentValues : values}
                            onChange={isEdit ? setCurrentValues : setValues}
                            multiple
                            plugins={[
                                <DatePanel sort="date" />,
                                <DatePickerHeader position="left" />
                            ]}
                        />
                        <DialogActions>
                            <Button onClick={cancelEdit} color="primary">
                                Cancel
                            </Button>
                            <Button type='submit' onClick={(event) => handleSubmit(event)} color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            right: 'prev,next today',
                            center: 'title',
                            left: ''
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        events={props.holidayData && props.holidayData.holiday && props.holidayData.holiday.length > 0 ? props.holidayData.holiday : []}
                    // initialEvents={props.holidayData && props.holidayData.holiday && props.holidayData.holiday.length > 0 ? props.holidayData.holiday : []}
                    // initialEvents={currentEvents.length > 0 ? currentEvents : props.holidayData.holiday}
                    />
                </div>
            </LoadingOverlay>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        holidayData: state.holidayCalendarData.holidayData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAllHolidayData: () => dispatch(getAllHolidayData()),
        createHolidayData: (data) => dispatch(createHolidayData(data)),
        editHolidayData: (data) => dispatch(editHolidayData(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HolidayCalendar);