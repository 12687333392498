import React, { useEffect } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stickers, GeneralData, GridMain } from './components';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { createPromotionSetup, getCountryData, getPromotionMaterialData, getMaterialFilters, getModels } from '../../../redux/actions';
import MarketDefinition from './components/MarketDefinition/MarketDefinition';
import { Mms } from '@mui/icons-material';
import ScenarioSetting from './ScenarioSetting';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
        height: '138vh'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },

    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },

    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },

}));
const tabs = {
    ScenarioSetting: 0,
    Stickers: 1,
    Region: 2,
};

function getSteps() {
    return ['ScenarioSetting', 'Stickers', 'Region'];
}

const PromotionSetup = props => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [completed, setCompleted] = React.useState(new Set());
    const [promoData, setPromoData] = React.useState([])
    const [promoStickerData, setPromoStickerData] = React.useState([])
    const [promoRegionData, setPromoRegionData] = React.useState([])
    const [promoMaterials, setPromoMaterials] = React.useState([]);
    const [promoFilter, setPromoFilter] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [promotion, setPromotion] = React.useState([]);
    const [valid, setValid] = React.useState(false);
    const [country, setCountry] = React.useState('');
    const skipped = new Set()
    const [materialFilter, setMaterialFilter] = React.useState([])
    const [sticker, setSticker] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [defineMarket, setDefineMarket] = React.useState({});
    const [colorPicker, setColorPicker] = React.useState({});
    const [locations, setLocations] = React.useState([]);
    const [promoLoc, setPromoLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [active, setActive] = React.useState(true);
    const [promoModelFilter, setPromoModelFilter] = React.useState({});
    const [promoModels, setPromoModels] = React.useState([]);

    useEffect(() => {
        // props.getMaterialData()
        props.getFilters()
        var location = {
            table_name: 'territory'
        }
        props.getFilterData('territory', location)
    }, [])

    useEffect(() => {
        if (props.materialData && props.materialData.length > 0) {
            setPromoMaterials(props.materialData)
            setPromoFilter(props.materialData)
        }
    }, [props.materialData])
    useEffect(() => {
        if (props.locationData)
            setLocations(props.locationData)
    }, [props.locationData])
    useEffect(() => {
        if (props.filterData) {
            setFilters(props.filterData)
        }
    }, [props.filterData])
    // useEffect(() => {
    //     if (promoMaterials && promoMaterials.length > 0 && states && states.length > 0) {
    //         promoMaterials.map((item, index) => {
    //             var temp = []
    //             promoFilter[index]['ID'] = index
    //             promoFilter[index]['model_level'] = 'Market Segment'
    //             states && states.map(x => {
    //                 temp.push({ 'promotion_name': '', 'promotion_description': '', 'state': x.state })
    //             })
    //             promoFilter[index]['promotion_state'] = temp

    //         })
    //         setPromoFilter(promoFilter)
    //     }
    // }, [promoMaterials, states])

    // useEffect(() => {

    //     if (props.countryData && props.countryData.length > 0) {
    //         setStates(props.countryData)
    //     }
    // }, [props.countryData])

    const totalSteps = () => {
        return getSteps().length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleNext = () => {
        // if (isLastStep())
        //     history.push("/promotion-setup");
        const newActiveStep = activeStep + 1
        //isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function handleCheckedLoc(selected) {
        setSelectedLocItems(selected)
        const array2 = [];
        selected.forEach(obj => {
            const { country, region, state } = obj;
            const existingCategoryA = array2.find(item => item.category === region);
            if (existingCategoryA) {
                existingCategoryA.children.push({ category: state, type: 'state', relationship: { country: country, region: region, state: state }, children: [] });

            } else {
                array2.push({
                    category: region, type: 'region',
                    relationship: { country: country, region: region },
                    children: [{
                        category: state, type: 'state',
                        relationship: { country: country, region: region, state: state },
                        children: []
                    }]
                });
            }
        });
        // array2
        console.log(array2)
        setPromoLoc([...array2])
    };
    function handleGeneralDataChange(values, model) {
        setPromoData(values);
        if (values.country)
            setCountry(values.country)
        handleNext()
        handleCheckedLoc(model)
    };
    function handlePromoStickers(selected, item) {
        setPromoStickerData(selected)
        setSticker(item)
        handleNext()
    }
    function handleDefine(value, child) {
        setDefineMarket(prev => ({
            ...prev, ...value
        }))
        setPromoModelFilter(prev => ({
            ...prev, ...child
        }))
        setActive(false)
        handleNext()
    }
    function handleSubmit() {
        var eligibility = []
        if (promoRegionData && promoRegionData.length > 0) {
            promoRegionData.map((itemX, i) => {
                var array = []
                promoRegionData[i] && promoRegionData[i]['promotion_state'] && promoRegionData[i]['promotion_state'].map((item, idx) => {
                    if (item.promotion_name != '' && item.promotion_description != ''
                        && promotion.findIndex(({ promotion_name }) => promotion_name == item.promotion_name) != -1)
                        array.push(item)
                })
                if (array.length > 0) {
                    var keyData = {
                        'material_group1': itemX.material_group1,
                        'material_group2': itemX.material_group2,
                        'material_group3': itemX.material_group3,
                        'country': localStorage.getItem('Country'),
                        'promotion_state': array
                    }
                    eligibility.push(keyData)

                }
            })
        }

        var data = {
            "promotion": promotion,
            "promotion_budget_benefits": promoStickerData,
            "promotion_budget_eligibility": eligibility
        }
        data = { ...data, ...promoData }
        props.onSubmit(data)
    }
    function handleRegionData(values, filter) {
        setPromoRegionData(values)
        setPromoFilter(values)
        setMaterialFilter(filter)
    };
    function onSelectModels(values) {
        setPromoModelFilter({ ...promoModelFilter, ...values })
    };
    // useEffect(() => {
    //     if (promoModelFilter) {
    //         var data = []
    //         Object.values(promoModelFilter).map((item, i) => {
    //             data = [...data, ...item]
    //         })//.length > 0 ? ...(Object.values(promoModelFilter)) : [];
    //         setPromoModels(data)
    //     }
    // }, [promoModelFilter])
    const handleColorPicker = (value) => {
        setColorPicker(value)
    }
    const skippedSteps = () => {
        return skipped.size;
    };
    const completedSteps = () => {
        return completed.size;
    };
    function getStepContent(step) {
        const allSteps = [
            // <GeneralData onChange={handleGeneralDataChange} enable={valid} handleNext={handleNext} />,
            <ScenarioSetting onChange={handleGeneralDataChange} locations={locations} />,
            <Stickers onChange={handlePromoStickers}
                promoData={promotion}
                country={promoData.country === 'Canada' ? 'CA' : 'USA'}
                productLine={promoData.division}
                handleBack={handleBack}
                handleColor={handleColorPicker}
            />,
            <MarketDefinition
                sticker={sticker}
                onChange={handleDefine}
                filters={filters}
                stickers={promoStickerData}
                country={promoData.country === 'Canada' ? 'CA' : 'USA'}
                handleBack={handleBack}
                colorPicker={colorPicker}
                locations={promoLoc}
                modelYears={promoData.model_year}
                active={active}
                onSelectModels={onSelectModels}
            />,
            <GridMain data={filters} country={promoData.country === 'Canada' ? 'CA' : 'USA'}
                definedData={defineMarket} stickers={promoStickerData}
                handleBack={handleBack}
                colorPicker={colorPicker}
                locations={promoLoc}
                modelYears={promoData.model_year}
                // modelsArray={promoModels}
                gridFilter={promoModelFilter}
            />

        ];

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }



    return (
        <div>
            <div className={classes.root}>{console.log(promoModelFilter)}
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        materialData: state.promotionData.promotionMaterialData,
        countryData: state.promotionData.countryData,
        locationData: state.promotionData.locationFilters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(createPromotionSetup(data)),
        getFilters: () => dispatch(getMaterialFilters()),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSetup);