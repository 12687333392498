import React, { useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    IconButton,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select
} from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { allowedApps, getWorkFlowAnchors, getApprovalDesignerDetails, addApprovalDesigner, getDefaultValues, getAllApprovalDesigner, getApprovalGroupData } from '../../redux/actions';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { DropdownArray } from '../../components/Inputs';
import BeatLoader from "react-spinners/BeatLoader";
import { ApplicationTypes } from '../../library/constants'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 19,
        height: 34
    },
    inputTwoLine: {
        marginTop: 18,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectedItem: {
        color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
    selectContainer: {
        paddingTop: '23px !important'
    },
    selectedItem2: {
        color: 'black',
        height: 40,
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 9,
    },
    errorMsg: {
        color: '#f44336',
        fontSize: 12,
        paddingTop: 5
    }
}));

const AddApprovalDesigner = props => {
    const classes = useStyles();
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [currencyArray, setCurrencyArray] = React.useState([]);
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [approvalGroupData, setApprovalGroupData] = React.useState([]);
    useEffect(() => {
        props.onLoadingDefault(applicationType);
        props.getAllApprovalDesigner(1, 130);
        props.getApprovalGroupData();
        props.getWorkFlowAnchors();
        props.getApprovalGroupData();
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getApprovalDesignerDetails(appId)
    }, []);
    useEffect(() => {
        if (props.approvalGroupData && props.approvalGroupData.records && props.approvalGroupData.records.length > 0) {
            setApprovalGroupData(props.approvalGroupData)
        }
    }, [props.approvalGroupData]);

    const [workflowAnchors, setWorkflowAnchors] = React.useState([]);

    const [primaryAnchorArr, setPrimaryAnchorArr] = React.useState([]);
    const [secondaryAnchorArr, setSecondaryAnchorArr] = React.useState([]);
    const [incentiveThresholdTypeArr, setTncentiveThresholdTypeArr] = React.useState([]);
    const handleFunctionality = (e) => {
        setFunctionality(e.target.value);
        workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.filter(function (v) {
            if (v.functionality === e.target.value) {
                setPrimaryAnchorArr(v.primary_anchors)
                setSecondaryAnchorArr(v.secondary_anchors)
                setTncentiveThresholdTypeArr(v.incentive_threshold_type)
            }

        })
    }
    useEffect(() => {
        if (props.workflowAnchors) {
            setWorkflowAnchors(props.workflowAnchors)
        }
    }, [props.workflowAnchors])
    useEffect(() => {
        if (props.approvalData && props.workflowAnchors) {
            props.workflowAnchors.length > 0 && props.workflowAnchors.filter(function (v) {

                if (v.functionality === props.approvalData['functionality'].toLowerCase()) {
                    setPrimaryAnchorArr(v.primary_anchors)
                    setSecondaryAnchorArr(v.secondary_anchors)
                    setTncentiveThresholdTypeArr(v.incentive_threshold_type)
                }

            })
        }

    }, [props.approvalData, props.workflowAnchors])

    const [applicationType, setApplicationType] = React.useState('Supplier Rebate');
    const [applicationTypeArray, setApplicationTypeArray] = React.useState(['Supplier Rebate', 'Supplier Rebate']);
    const handleApplicationtype = (e) => {
        setApplicationTypeValue(e.target.value);
        props.onLoadingDefault(e.target.value, '', '', '', 'Contract Designer');
    }

    useEffect(() => {
        if (props.approvalData) {
            if (props.approvalData && props.approvalData['ID']) {
                setEditId(props.approvalData['ID'])
            }
            if (props.approvalData && props.approvalData['application_type']) {
                setApplicationTypeValue(props.approvalData['application_type'])
            }
            if (props.approvalData && props.approvalData['approval_group']) {
                setApprovalGroup(props.approvalData['approval_group'])
            }
            if (props.approvalData && props.approvalData['currency']) {
                setCurrency(props.approvalData['currency'])
            }
            if (props.approvalData && props.approvalData['functionality']) {
                setFunctionality(props.approvalData['functionality'].toLowerCase())

            }
            if (props.approvalData && props.approvalData['primary_anchor']) {
                setPrimaryAnchor(props.approvalData['primary_anchor'])
            }
            if (props.approvalData && props.approvalData['primary_anchor_value']) {
                setPrimaryAnchorValue(props.approvalData['primary_anchor_value'])
            }
            if (props.approvalData && props.approvalData['secondary_anchor']) {
                setSecondaryAnchor(props.approvalData['secondary_anchor'])
            }
            if (props.approvalData && props.approvalData['secondary_anchor_value']) {
                setSecondaryAnchorValue(props.approvalData['secondary_anchor_value'])
            }
            if (props.approvalData && props.approvalData['step_number']) {
                setStepNumber(props.approvalData['step_number'])
            }
            if (props.approvalData && props.approvalData['step_description']) {
                setStepDescription(props.approvalData['step_description'])
            }
            if (props.approvalData && props.approvalData['incentive_threshold_type']) {
                setIncentiveThresholdType(props.approvalData['incentive_threshold_type'])
            }
            if (props.approvalData && props.approvalData['incentive_amount'] != 0) {
                setIncentiveAmount(props.approvalData['incentive_amount'])
                setPositiveValid(false)
            }
            if (props.approvalData && props.approvalData['negative_threshold_value'] && props.approvalData['negative_threshold_value'] != 0) {
                setNegativeThresholdValue(props.approvalData['negative_threshold_value'])
            }
            if (props.approvalData && props.approvalData['notification_group']) {
                setNotificationGroup(props.approvalData['notification_group'])
            }
            if (props.approvalData && props.approvalData['external_approval']) {
                setExternalApproval(props.approvalData['external_approval'])
            }
        }
    }, [props.approvalData]);


    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'currency') {
                            setCurrencyArray(item);
                        }
                    })
                }
            }
        }
    });
    const [editId, setEditId] = React.useState('');



    const [applicationTypeValue, setApplicationTypeValue] = React.useState('');
    const [functionality, setFunctionality] = React.useState('');
    const [primaryAnchor, setPrimaryAnchor] = React.useState('');
    const [primaryAnchorValue, setPrimaryAnchorValue] = React.useState('');
    const [secondaryAnchor, setSecondaryAnchor] = React.useState('');
    const [secondaryAnchorValue, setSecondaryAnchorValue] = React.useState('');
    const [stepNumber, setStepNumber] = React.useState('');
    const [stepDescription, setStepDescription] = React.useState('');
    const [incentiveThresholdType, setIncentiveThresholdType] = React.useState('');
    const [incentiveAmount, setIncentiveAmount] = React.useState([]);
    const [currency, setCurrency] = React.useState('');
    const [notificationGroup, setNotificationGroup] = React.useState('');
    const [externalApproval, setExternalApproval] = React.useState('');
    const [negativeThresholdValue, setNegativeThresholdValue] = React.useState('');
    const [positiveValid, setPositiveValid] = React.useState(true);
    const [positiveErrorMessage, setPositiveErrorMessage] = React.useState('');
    const [stepValid, setStepValid] = React.useState(false);
    const [stepErrorMessage, setStepErrorMessage] = React.useState('');
    const [negativeErrorMessage, setNegativeErrorMessage] = React.useState('');

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])


    function handleClear() {
        setApplicationTypeValue('')
        setFunctionality('')
        setPrimaryAnchor('')
        setSecondaryAnchor('')
        setPrimaryAnchorValue('')
        setSecondaryAnchorValue('')
        setStepNumber('')
        setStepDescription('')
        setIncentiveThresholdType('')
        setIncentiveAmount([])
        setCurrency([])
        setNotificationGroup('')
        setExternalApproval('')
        setNegativeThresholdValue('')
        setApprovalGroup('')
    }
    function handleOnSubmit() {

        var data = {
            "ID": editId,
            "application_type": applicationTypeValue,
            "functionality": functionality,
            "primary_anchor": primaryAnchor,
            "primary_anchor_value": primaryAnchorValue,
            "secondary_anchor": secondaryAnchor,
            "secondary_anchor_value": secondaryAnchorValue,
            "step_number": parseInt(stepNumber),
            "step_description": stepDescription,
            "incentive_threshold_type": incentiveThresholdType,
            "incentive_amount": parseFloat(incentiveAmount) > 0 ? parseFloat(incentiveAmount) : 0,
            "negative_threshold_value": parseFloat(negativeThresholdValue) <= 0 ? parseFloat(negativeThresholdValue) : 0,
            "currency": currency.toString(),
            "approval_group": parseInt(approvalGroup),
            "notification_group": notificationGroup,
            "external_approval": externalApproval

        };
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.onSubmit(data, 'put', appId)

    }
    function handleClearAll() {
        handleClear();
    }
    const handleIncentiveAmount = (e) => {

        if (e.target.value) {
            if (e.target.value <= 0) {
                setIncentiveAmount(e.target.value)
                setPositiveValid(true)
                setPositiveErrorMessage('Value should be positive')

            }
            else {

                setPositiveValid(false)
                setPositiveErrorMessage('')
                setIncentiveAmount(e.target.value)
            }
        }
        else {
            setPositiveValid(true)
            setPositiveErrorMessage('')
            setIncentiveAmount(e.target.value)

        }

    }
    const handleNegativeThresholdValue = (e) => {
        if (e.target.value && e.target.value >= 0) {
            setNegativeThresholdValue(e.target.value)
            setNegativeErrorMessage('Value should be negative')
        }
        else {
            setNegativeThresholdValue(e.target.value)
            setNegativeErrorMessage('')

        }


    }
    const handleStepNumber = (e) => {

        if (e.target.value) {
            if (e.target.value <= 0) {
                setStepNumber(parseInt(e.target.value))
                setStepValid(true)
                setStepErrorMessage('Value should be positive')
            }
            else {

                setStepValid(false)
                setStepErrorMessage('')
                setStepNumber(parseInt(e.target.value))
            }
        }
        else {
            setStepValid(true)
            setStepErrorMessage('')
            setStepNumber(e.target.value)
        }
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/approval-limits'
                >
                    Approval Levels
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Approval</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                {/* <Card> */}
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2}>

                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Application Type
                                </FormLabel>
                                <Select
                                    value={applicationTypeValue}
                                    style={{ textTransform: 'capitalize', height: 36, marginTop: 27 }}
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {allowedApps.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel} required>
                                        Functionality
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                        value={functionality}
                                        onChange={handleFunctionality}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {workflowAnchors && workflowAnchors.length > 0 && workflowAnchors.map(item => {
                                            return (
                                                <MenuItem value={item['functionality']} key={item['functionality']} style={{ textTransform: 'capitalize' }}>
                                                    {item['functionality'].replace(/_/g, ' ')}
                                                </MenuItem>
                                            );
                                        })
                                        }
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Primary Anchor
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={primaryAnchor}
                                        style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                        onChange={(e) => setPrimaryAnchor(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={!functionality ? true : false}
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {primaryAnchorArr && primaryAnchorArr.length > 0 &&
                                            primaryAnchorArr
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item.replace(/_/g, ' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}
                                        required={primaryAnchor ? true : false}>
                                        Primary Anchor Value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={primaryAnchorValue}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setPrimaryAnchorValue(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Secondary Anchor
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={secondaryAnchor}
                                        style={{ textTransform: 'capitalize', height: 36, marginTop: 18 }}
                                        onChange={(e) => setSecondaryAnchor(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={!functionality ? true : false}
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {secondaryAnchorArr && secondaryAnchorArr.length > 0 &&
                                            secondaryAnchorArr
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item.replace(/_/g, ' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}
                                        required={secondaryAnchor ? true : false}>
                                        Secondary Anchor Value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={secondaryAnchorValue}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setSecondaryAnchorValue(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel} required>
                                        Step Number
                                    </FormLabel>
                                    <OutlinedInput
                                        value={stepNumber}
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleStepNumber} />
                                    <FormLabel style={{ display: stepErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                        {stepErrorMessage}
                                    </FormLabel>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Step Description
                                    </FormLabel>
                                    <OutlinedInput
                                        value={stepDescription}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setStepDescription(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel} required={(functionality != 'contract' && functionality != 'contract document') ? true : false}>
                                        Threshold Type
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={incentiveThresholdType}
                                        style={{ textTransform: 'capitalize' }}
                                        onChange={(e) => setIncentiveThresholdType(e.target.value)}
                                        displayEmpty
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                        disabled={!functionality ? true : false}
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {incentiveThresholdTypeArr && incentiveThresholdTypeArr.length > 0 &&
                                            incentiveThresholdTypeArr
                                                .filter(item => (functionality === 'accrual' || functionality === 'payment') ? item != 'incentive_adjustment' : true)
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item.replace(/_/g, ' ')}
                                                        </MenuItem>
                                                    );
                                                })}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel} >
                                        Positive Threshold value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={incentiveAmount}
                                        type={'number'}
                                        inputProps={{ min: 0 }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleIncentiveAmount} />
                                    <FormLabel style={{ display: positiveErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                        {positiveErrorMessage}
                                    </FormLabel>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Negative Threshold Value
                                    </FormLabel>
                                    <OutlinedInput
                                        value={negativeThresholdValue}
                                        type={'number'}
                                        inputProps={{ max: 0 }}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleNegativeThresholdValue} />
                                    <FormLabel style={{ display: negativeErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                        {negativeErrorMessage}
                                    </FormLabel>
                                </div>
                            </Grid>
                            {currencyArray &&
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                    <FormLabel className={classes.formLabel} required={functionality != 'contract' ? true : false}>
                                        Threshold Unit
                                    </FormLabel>

                                    <Select
                                        disableUnderline
                                        value={currency}
                                        onChange={(e) => setCurrency(e.target.value)}
                                        displayEmpty
                                        style={{ textTransform: 'capitalize', marginTop: 26 }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                            [classes.error]: props.error
                                        })}
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        {currencyArray && currencyArray.drop_down_values && currencyArray.drop_down_values
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        <MenuItem value="%">
                                            %
                                        </MenuItem>
                                    </Select>

                                </Grid>
                            }
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel className={classes.formLabel} required>
                                    Approval Group
                                </FormLabel>
                                <Select
                                    disableUnderline
                                    onChange={(e) => setApprovalGroup(e.target.value)}
                                    displayEmpty
                                    style={{ textTransform: 'capitalize', marginTop: 27 }}
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    value={approvalGroup}
                                >
                                    <MenuItem value={''} key={''}>

                                    </MenuItem>
                                    {approvalGroupData && approvalGroupData.records
                                        && approvalGroupData.records.length > 0
                                        && approvalGroupData.records.map((item) => {
                                            return (
                                                <MenuItem value={item.ID} key={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>

                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        Notification Group
                                    </FormLabel>
                                    <OutlinedInput
                                        value={notificationGroup}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setNotificationGroup(e.target.value)} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel className={classes.formLabel}>
                                        External Approval
                                    </FormLabel>
                                    <OutlinedInput
                                        value={externalApproval}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setExternalApproval(e.target.value)} />
                                </div>
                            </Grid>


                        </Grid>
                    </div>
                </form>
                {/* </Card> */}



                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                        disabled={!functionality || !applicationTypeValue || stepValid || !approvalGroup || (primaryAnchor && !primaryAnchorValue)
                            || (secondaryAnchor && !secondaryAnchorValue)
                            ? true : (functionality != 'contract' && functionality != 'contract document') ? !incentiveThresholdType || positiveValid || !currency ? true : false : false}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, type, id) => dispatch(addApprovalDesigner(data, type, id)),
        onLoadingDefault: (applicationType) => dispatch(getDefaultValues(applicationType, null, null, null, 'Contract Designer')),
        getAllApprovalDesigner: (pagination, limit) => dispatch(getAllApprovalDesigner(pagination, limit)),
        getApprovalGroupData: () => dispatch(getApprovalGroupData()),
        getApprovalDesignerDetails: (id) => dispatch(getApprovalDesignerDetails(id)),
        getWorkFlowAnchors: () => dispatch(getWorkFlowAnchors()),
        allowedApps: () => dispatch(allowedApps())
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingDropdownValues: state.addMultipleConfigurationData.loading,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetDetail,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        workflowAnchors: state.customerData.workflowAnchors,
        allowedAppsData: state.initialData.allowedApps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddApprovalDesigner);