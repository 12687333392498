import {
  FETCH_START,
  FETCH_END,
  PROMOTION_DATA_SUCCESS,
  PROMO_FINANCE_COST_DATA_SUCCESS,
  PROMO_TERRITORY_DATA_SUCCESS,
  PROMO_TERRITORY_FORMAT_SUCCESS,
  PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
  PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
  PROMO_TERRITORY_UPLOAD_SUCCESS,
  PROMOTION_SETUP_BY_ID,
  PROMOTION_SETUP_SUCCESS,
  PROMOTION_MATERIAL_SUCCESS,
  PROMO_FINANCE_COST_DETAIL_SUCCESS,
  FINANCE_COST_FORMAT_SUCCESS,
  FINANCE_COST_FORMAT_BY_ID_SUCCESS,
  FINANCE_COST_FORMAT_FIELDS_SUCCESS,
  PROMO_FINANCE_COST_UPLOAD_SUCCESS,
  COUNTRY_SUCCESS,
  POSTING_FAILED,
  GLOBAL_SETUP_BY_ID,
  GLOBAL_SETUP_SUCCESS,
  PROMO_MAP_SUCCESS,
  PROMO_MAP_SINGLE_SUCCESS,
  PROMO_MAPPING_FORMAT_SUCCESS,
  PROMO_MAP_FORMAT_BY_ID,
  PROMO_MAP_FORMAT_FIELDS,
  PROMO_SIM_FAILURE,
  PROMO_TYPES,
  PROMO_STICKERS,
  PROMO_STICKER_BY_ID,
  PROMO_FILTERS,
  MARKET_FILTERS,
  MODEL_GROUP,
  LOCATION_GROUP,
  MODEL_LIST_GROUP
} from "./ActionTypes";
import secureLocalStorage from "react-secure-storage";
import axios from "../../../axios.conf";
import { toast } from "react-toastify";
import { history } from "../../../components/Helpers";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const getCreatePromotionPlanSuccess = (data) => {
  return {
    type: PROMOTION_DATA_SUCCESS,
    data,
  };
};

export const createPromotionPlan = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCreatePromotionPlanAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getCreatePromotionPlanSuccess(response.data.response));
        history.push("/promotion-trends-results");
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCreatePromotionPlanAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("plan-promotions", data, { headers: headers });
  return result;
};

//save
export const savePromotionPlan = (data) => {
  return (dispatch) => {
    getSavePromotionPlanAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        history.push("/promotion-trends");
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

const getSavePromotionPlanAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("/plan-promotions-save", data, { headers: headers });
  return result;
};

// //   Promo Finance Cost
export const getPromoFinanceCostData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoFinanceCostDataApi().then((response) => {
      dispatch(fetchEnd());
      dispatch(getPromoFinanceCostDataSuccess(response.data.response));
    })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromoFinanceCostDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promo-finance-cost?page=1&&limit=0", {
    headers: headers,
  });
  return result;
};

export const getPromoFinanceCostDataSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_DATA_SUCCESS,
    data,
  };
};
export const deleteFinanceCostData = (id) => {
  return (dispatch) => {
    deleteFinanceCostDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromoFinanceCostData());
    });
  };
};
const deleteFinanceCostDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("promo-finance-cost/" + id, { headers: headers });
  return result;
};
// Finance Cost format
export const getFinanceCostFormatData = () => {
  return (dispatch) => {
    getFinanceCostFormatDataApi()
      .then((response) => {
        dispatch(getFinanceCostFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format?app-functionality=PromotionBudgetFinanceCostsFormat",
    { headers: headers }
  );
  return result;
};

export const getFinanceCostFormatDataSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_SUCCESS,
    data,
  };
};

export const getFinanceCostFormatDataById = (id) => {
  return (dispatch) => {
    getFinanceCostFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getFinanceCostFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format/" +
    id +
    "?app-functionality=PromotionBudgetFinanceCostsFormat",
    { headers: headers }
  );
  return result;
};

export const getFinanceCostFormatDataByIdSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get Finance Cost format fields
export const getFinanceCostFormatFields = () => {
  return (dispatch) => {
    getFinanceCostFormatFieldsApi()
      .then((response) => {
        dispatch(getFinanceCostFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getFinanceCostFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format-fields?app-functionality=PromotionBudgetFinanceCostsFormat",
    { headers: headers }
  );
  return result;
};

export const getFinanceCostFormatFieldsSuccess = (data) => {
  return {
    type: FINANCE_COST_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update Finance Cost format
export const updateFinanceCostFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateFinanceCostFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push("/finance-cost/finance-cost-excel-upload");
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updateFinanceCostFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" +
    id +
    "?app-functionality=PromotionBudgetFinanceCostsFormat",
    data,
    { headers: headers }
  );
  return result;
};

//add Finance Cost format
export const addFinanceCostFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addFinanceCostFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push("/finance-cost/finance-cost-excel-upload");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addFinanceCostFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "upload-format?app-functionality=PromotionBudgetFinanceCostsFormat",
    data,
    { headers: headers }
  );
  return result;
};

// delete Coverage Cost format
export const deleteFinanceCostFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteFinanceCostFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getFinanceCostFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deleteFinanceCostFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};
export const uploadPromoFinanceCostDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromoFinanceCostDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromoFinanceCostSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromoFinanceCostDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("upload-file?format_id=" + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadPromoFinanceCostSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_UPLOAD_SUCCESS,
    data,
  };
};

//get Promo Finance Cost details
export const getPromoFinanceCostDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoFinanceCostDetailsApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromoFinanceCostDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
        dispatch(fetchEnd());
      });
  };
};

const getPromoFinanceCostDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promo-finance-cost/" + id, { headers: headers });
  return result;
};

export const getPromoFinanceCostDetailsSuccess = (data) => {
  return {
    type: PROMO_FINANCE_COST_DETAIL_SUCCESS,
    data,
  };
};

// Promo Territory

export const getPromoTerritoryData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoTerritoryDataApi().then((response) => {
      dispatch(fetchEnd());
      dispatch(getPromoTerritoryDataSuccess(response.data.response));
    })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getPromoTerritoryDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promo-territory?page=1&&limit=0", { headers: headers });
  return result;
};

export const getPromoTerritoryDataSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_DATA_SUCCESS,
    data,
  };
};
export const deletePromoTerritoryData = (id) => {
  return (dispatch) => {
    deletePromoTerritoryDataApi(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getPromoTerritoryData());
    });
  };
};
const deletePromoTerritoryDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("promo-territory/" + id, { headers: headers });
  return result;
};
// Promo Territory format
export const getPromoTerritoryFormatData = () => {
  return (dispatch) => {
    getPromoTerritoryFormatDataApi()
      .then((response) => {
        dispatch(getPromoTerritoryFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format?app-functionality=PromotionBudgetTerritoryFormat",
    { headers: headers }
  );
  return result;
};

export const getPromoTerritoryFormatDataSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_SUCCESS,
    data,
  };
};

export const getPromoTerritoryFormatDataById = (id) => {
  return (dispatch) => {
    getPromoTerritoryFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPromoTerritoryFormatDataByIdSuccess(response.data.response)
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format/" + id + "?app-functionality=PromotionBudgetTerritoryFormat",
    { headers: headers }
  );
  return result;
};

export const getPromoTerritoryFormatDataByIdSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get Finance Cost format fields
export const getPromoTerritoryFormatFields = () => {
  return (dispatch) => {
    getPromoTerritoryFormatFieldsApi()
      .then((response) => {
        dispatch(getPromoTerritoryFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTerritoryFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format-fields?app-functionality=PromotionBudgetTerritoryFormat",
    { headers: headers }
  );
  return result;
};

export const getPromoTerritoryFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update Promo Territory format
export const updatePromoTerritoryFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePromoTerritoryFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push("/promotion-territory/promotion-territory-excel-upload");
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const updatePromoTerritoryFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" + id + "?app-functionality=PromotionBudgetTerritoryFormat",
    data,
    { headers: headers }
  );
  return result;
};

//add Finance Cost format
export const addPromoTerritoryFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromoTerritoryFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push("/promotion-territory/promotion-territory-excel-upload");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromoTerritoryFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "upload-format?app-functionality=PromotionBudgetTerritoryFormat",
    data,
    { headers: headers }
  );
  return result;
};

// delete Promo Territory format
export const deletePromoTerritoryFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromoTerritoryFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getPromoTerritoryFormatData());
        } else {
          //toast.error(response.data.error.message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const deletePromoTerritoryFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};
export const uploadPromoTerritoryDataFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadPromoTerritoryDataFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadPromoTerritorySuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadPromoTerritoryDataFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("upload-file?format_id=" + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadPromoTerritorySuccess = (data) => {
  return {
    type: PROMO_TERRITORY_UPLOAD_SUCCESS,
    data,
  };
};

//promotion setup

export const createPromotionSetup = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionSetupAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/promotion-setup");
        dispatch(getPromotionSetup());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionSetupAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "edit") {
    result = axios.put("promotion-cost-header/" + id, data, {
      headers: headers,
    });
  } else if (type === "delete") {
    result = axios.delete("promotion-cost-header/" + id, { headers: headers });
  } else {
    result = axios.post("promotion-cost-header", data, { headers: headers });
  }
  return result;
};

export const getPromotionSetupById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromotionSetupByIdAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPromotionSetupByIdSuccess(response.data.response));
        //history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromotionSetupByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promotion-cost-header/" + id, { headers: headers });
  return result;
};

export const getPromotionSetupByIdSuccess = (data) => {
  return {
    type: PROMOTION_SETUP_BY_ID,
    data,
  };
};

export const getPromotionSetup = () => {
  return (dispatch) => {
    getPromotionSetupAPI()
      .then((response) => {
        dispatch(getPromotionSetupSuccess(response.data.response));
        //history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => { });
  };
};

const getPromotionSetupAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promotion-cost-header?page=1&&limit=0", {
    headers: headers,
  });
  return result;
};

export const getPromotionSetupSuccess = (data) => {
  return {
    type: PROMOTION_SETUP_SUCCESS,
    data,
  };
};
export const getPromotionMaterialData = () => {
  return (dispatch) => {
    getPromotionMaterialDataAPI()
      .then((response) => {
        dispatch(getPromotionMaterialDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPromotionMaterialDataAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promotion-cost-material", { headers: headers });
  return result;
};

export const getPromotionMaterialDataSuccess = (data) => {
  return {
    type: PROMOTION_MATERIAL_SUCCESS,
    data,
  };
};
//get country
export const getCountryData = (country) => {
  return (dispatch) => {
    getCountryDataAPI(country)
      .then((response) => {
        dispatch(getCountryDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getCountryDataAPI = (country) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promo-territory/" + country, { headers: headers });
  return result;
};

export const getCountryDataSuccess = (data) => {
  return {
    type: COUNTRY_SUCCESS,
    data,
  };
};

//promo-simulation
export const runPromotionSimulation = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPromotionSimulationAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.messageLog) {
          dispatch(promotionSimulationFailure(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (error.response.status == "422")
          dispatch(promotionSimulationFailure(error.response.data));
      });
  };
};

const runPromotionSimulationAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("promotion-simulation", data, { headers: headers });
  return result;
};
export const promotionSimulationFailure = (data) => {
  return {
    type: PROMO_SIM_FAILURE,
    data,
  };
};
//promo-posting

export const runPromotionPosting = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPromotionPostingAPI(data)
      .then((response) => {
        dispatch(fetchEnd());

        if (
          response.data.response.error_list &&
          response.data.response.error_list.length > 0
        ) {
          dispatch(promotionPostingFailed(response.data.response));
        } else toast.success("Success");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const runPromotionPostingAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("promotion-posting", data, { headers: headers });
  return result;
};
export const promotionPostingFailed = (data) => {
  return {
    type: POSTING_FAILED,
    data,
  };
};

// global setup
export const createGlobalSetup = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createGlobalSetupAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/promotion-global-setup");
        dispatch(getGlobalSetupData());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createGlobalSetupAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "edit") {
    result = axios.put("global-promotion", data, { headers: headers });
  } else if (type === "delete") {
    result = axios.delete("global-promotion/" + id, { headers: headers });
  } else {
    result = axios.post("global-promotion", data, { headers: headers });
  }
  return result;
};

export const getGlobalSetupData = (country) => {
  return (dispatch) => {
    getGlobalSetupDataAPI(country)
      .then((response) => {
        dispatch(getGlobalSetupSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getGlobalSetupDataAPI = (country) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (country)
    result = axios.get("global-promotion?country=" + country, {
      headers: headers,
    });
  else result = axios.get("global-promotion", { headers: headers });
  return result;
};

export const getGlobalSetupByIdSuccess = (data) => {
  return {
    type: GLOBAL_SETUP_BY_ID,
    data,
  };
};
export const getGlobalSetupSuccess = (data) => {
  return {
    type: GLOBAL_SETUP_SUCCESS,
    data,
  };
};

//clone promo setup
export const clonePromotionSetup = (id) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getClonePromotionSetupApi(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getPromotionSetup());
      })
      .catch((error) => {
        //toast.error('Something went wrong.');;
      });
  };
};

const getClonePromotionSetupApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("promotion-cost-header/" + id + "/clone", {
    headers: headers,
  });
  return result;
};
//promotion mapping

export const createPromotionMapping = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPromotionMappingAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        dispatch(getPromotionMappingData(1, 0));

        history.push("/promo-mapping");
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPromotionMappingAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "delete") {
    result = axios.delete("promotion-mapping/" + id, { headers: headers });
  } else if (type === "edit") {
    result = axios.put("promotion-mapping/" + id, data, { headers: headers });
  } else {
    result = axios.post("promotion-mapping", data, { headers: headers });
  }
  return result;
};
export const getPromotionMappingData = (pagination, limit) => {
  return (dispatch) => {
    getPromotionMappingDataApi(pagination, limit).then((response) => {
      dispatch(getPromotionMappingDataSuccess(response.data.response));
    });
  };
};
const getPromotionMappingDataApi = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "promotion-mapping?page=" + pagination + "&&limit=" + limit,
    { headers: headers }
  );
  return result;
};
export const getPromotionMappingDataSuccess = (data) => {
  return {
    type: PROMO_MAP_SUCCESS,
    data,
  };
};
export const getPromotionMappingSingleItem = (id) => {
  return (dispatch) => {
    getPromotionMappingSingleItemAPI(id).then((response) => {
      dispatch(getPromotionMappingSingleItemSuccess(response.data.response));
    });
  };
};
const getPromotionMappingSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("promotion-mapping/" + id, { headers: headers });
  return result;
};

export const getPromotionMappingSingleItemSuccess = (data) => {
  return {
    type: PROMO_MAP_SINGLE_SUCCESS,
    data,
  };
};

//promo-mapping-format
export const getPromotionMappingFormatData = () => {
  return (dispatch) => {
    getPromotionMappingFormatDataApi()
      .then((response) => {
        dispatch(getPromotionMappingFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format?app-functionality=PromotionBudgetIntegrationMappingFormat ",
    { headers: headers }
  );
  return result;
};

export const getPromotionMappingFormatDataSuccess = (data) => {
  return {
    type: PROMO_MAPPING_FORMAT_SUCCESS,
    data,
  };
};

export const getPromotionMappingFormatDataById = (id) => {
  return (dispatch) => {
    getPromotionMappingFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getPromotionMappingFormatDataByIdSuccess(response.data.response)
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("upload-format/" + id, { headers: headers });
  return result;
};

export const getPromotionMappingFormatDataByIdSuccess = (data) => {
  return {
    type: PROMO_MAP_FORMAT_BY_ID,
    data,
  };
};

export const getPromotionMappingFormatFields = () => {
  return (dispatch) => {
    getPromotionMappingFormatFieldsApi()
      .then((response) => {
        dispatch(
          getPromotionMappingFormatFieldsSuccess(response.data.response)
        );
      })
      .catch((error) => {
        ////toast.error(error.response.data.error.message);
      });
  };
};

const getPromotionMappingFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format-fields?app-functionality=PromotionBudgetIntegrationMappingFormat ",
    { headers: headers }
  );
  return result;
};

export const getPromotionMappingFormatFieldsSuccess = (data) => {
  return {
    type: PROMO_MAP_FORMAT_FIELDS,
    data,
  };
};

export const updatePromotionMappingFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updatePromotionMappingFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push("/promo-mapping/promo-mapping-excel-upload");
        } else {
          ////toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const updatePromotionMappingFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" +
    id +
    "?app-functionality=PromotionBudgetIntegrationMappingFormat",
    data,
    { headers: headers }
  );
  return result;
};
export const addPromotionMappingFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPromotionMappingFormatDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        //dispatch(getPromotionMappingFormatData())
        toast.success(response.data.response);
        history.push("/promo-mapping/promo-mapping-excel-upload");
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addPromotionMappingFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "upload-format?app-functionality=PromotionBudgetIntegrationMappingFormat",
    data,
    { headers: headers }
  );
  return result;
};

export const deletePromotionMappingFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deletePromotionMappingFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success("Successfully deleted");
          dispatch(getPromotionMappingFormatData());
        } else {
          ////toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        ////toast.error(ServerError);
      });
  };
};

const deletePromotionMappingFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};

export const uploadPromotionMappingFile = (id, file) => {
  return (dispatch) => {
    uploadPromotionMappingFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          toast.success("File has been successfully uploaded.");
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadPromotionMappingFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("promotion-mapping-upload?format_id=" + id, file, {
    headers: headers,
  });
  return result;
};
export const getPromoTypes = () => {
  return (dispatch) => {
    getPromoTypesApi()
      .then((response) => {
        dispatch(
          getPromoTypesSuccess(response.data.response)
        );
      })
      .catch((error) => {
        ////toast.error(error.response.data.error.message);
      });
  };
};

const getPromoTypesApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "/promotion-sticker-types",
    { headers: headers }
  );
  return result;
};

export const getPromoTypesSuccess = (data) => {
  return {
    type: PROMO_TYPES,
    data,
  };
};


export const getPromoStickers = (productLines, country, id, pagination, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPromoStickersApi(productLines, country, id, pagination, limit)
      .then((response) => {
        dispatch(fetchEnd());
        if (id) {
          dispatch(
            getPromoStickerByIdSuccess(response.data.response)

          );
          history.push('/promotion-sticker-setup/edit/' + id)
        }
        else
          dispatch(
            getPromoStickersSuccess(response.data.response)

          );
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPromoStickersApi = (productLines, country, id, pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (id)
    result = axios.get(
      "/promotion-sticker/" + id, { headers: headers }
    );
  else if (pagination)
    result = axios.get(
      "/promotion-stickers?page=" + pagination + "&&limit=" + limit + "&product_lines.any=" + productLines + "&country=" + country,
      { headers: headers }
    );
  else
    result = axios.get(
      "/promotion-stickers?product_lines.any=" + productLines + "&country=" + country,
      { headers: headers }
    );
  return result;
};

export const getPromoStickersSuccess = (data) => {
  return {
    type: PROMO_STICKERS,
    data,
  };
};
export const getPromoStickerByIdSuccess = (data) => {
  return {
    type: PROMO_STICKER_BY_ID,
    data,
  };
};
export const createStickers = (data, type, value) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createStickersAPI(data, type, value)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        history.push("/promotion-sticker-setup");
        if (type == 'delete' || type == 'clone')
          dispatch(getPromoStickers(value.product_line, value.country, null, 1, 10));

      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createStickersAPI = (data, type, value) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "create")
    result = axios.post("/promotion-sticker", data, {
      headers: headers,
    });
  else if (type === "delete")
    result = axios.delete("/promotion-sticker/" + value.ID, { params: data }, {
      headers: headers,
    });
  else if (type == 'clone')
    result = axios.post("/promotion-sticker/" + value.ID + '/clone', {
      headers: headers,
    });
  else
    result = axios.put("/promotion-sticker/" + value, data, {
      headers: headers,
    });
  return result;
};

export const getMaterialFilters = (table) => {
  return (dispatch) => {
    getMaterialFiltersApi(table)
      .then((response) => {

        dispatch(
          getMaterialFiltersSuccess(response.data.response)

        );
      })
      .catch((error) => {

      });
  };
};

const getMaterialFiltersApi = (table) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;

  result = axios.get("promotion-filter-group", { headers: headers });
  return result;
};

export const getMaterialFiltersSuccess = (data) => {
  return {
    type: PROMO_FILTERS,
    data,
  };
};

export const getMarketFilters = (table, data) => {
  return (dispatch) => {
    getMarketFiltersApi(table, data)
      .then((response) => {

        dispatch(
          getMarketFiltersSuccess(response.data.response)

        );
      })
      .catch((error) => {

      });
  };
};

const getMarketFiltersApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;

  result = axios.post("promotion-market-filters?table_name=" + table, data, { headers: headers });
  return result;
};

export const getMarketFiltersSuccess = (data) => {
  return {
    type: MARKET_FILTERS,
    data,
  };
};

export const getModels = (table, data, type) => {
  return (dispatch) => {
    getModelsApi(table, data)
      .then((response) => {
        if (table == 'material_master'){
          if (type == 'filter')
            dispatch(
              getModelsSuccess(response.data.response)
            );
          else
            dispatch(
              getModelsListSuccess(response.data.response)
            );}
        else{
         // if (type == 'filter')
            dispatch(getLocationSuccess(response.data.response));
        }
      })
      .catch((error) => {

      });
  };
};

const getModelsApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;

  result = axios.post("promotion-define-market", data, { headers: headers });
  return result;
};

export const getModelsSuccess = (data) => {
  return {
    type: MODEL_GROUP,
    data,
  };
};
export const getModelsListSuccess = (data) => {
  return {
    type: MODEL_LIST_GROUP,
    data,
  };
};
export const getLocationSuccess = (data) => {
  return {
    type: LOCATION_GROUP,
    data,
  };
};