import {
    EXTERNAL_FORMULA_SUCCESS,
    EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS,
    EXTERNAL_FORMULA_SINGLE_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS,
    EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS,
    FETCH_START,
    FETCH_END,
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
    return {
        type: FETCH_START
    }
}

export const fetchEnd = () => {
    return {
        type: FETCH_END
    }
}
export const getExternalFormula = (pagination, limit, keyword, data) => {
    return dispatch => {
        dispatch(fetchStart())
        getExternalFormulaApi(pagination, limit, keyword, data)
            .then(response => {
                if (keyword === 'search') {
                    dispatch(fetchEnd())
                    dispatch(getExternalFormulaSuccess(response.data.response));
                    history.push({ pathname: '/external-formula-results', type: 'edit' })
                } else {
                    dispatch(fetchEnd())
                    dispatch(getExternalFormulaSuccess(response.data.response));
                }
            })
            .catch((error) => {
                dispatch(fetchEnd());
            });

    }
}

const getExternalFormulaApi = (pagination, limit, keyword, data) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    if (keyword === 'search') {
        result = axios.get("external-formula-results?page=" + pagination + "&limit=" + limit, { params: data }, { headers: headers });
    }
    else {
        result = axios.get("external-formula-results?page=" + pagination + "&limit=" + limit, { params: data }, { headers: headers });
    }
    return result;
}

export const getExternalFormulaSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_SUCCESS,
        data
    }
}
//
export const getExternalFormulaViewDetails = (id, mode) => {
    return (dispatch) => {
        getExternalFormulaViewDetailsApi(id).then((response) => {
            if (mode != "edit") history.push("/external-formula-results/view-external/" + id);
            dispatch(getExternalFormulaViewDetailsApiSuccess(response.data.response));
        });
    };
};
const getExternalFormulaViewDetailsApi = (id) => {
    const headers = {
        Authorization: secureLocalStorage.getItem("aTk"),
    };
    var result;
    result = axios.get("external-formula-results/" + id, { headers: headers });
    return result;
};

export const getExternalFormulaViewDetailsApiSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_VIEW_DETAIL_SUCCESS,
        data,
    };
};
//


export const createExternalFormula = (data, type, id) => {
    return dispatch => {
        dispatch(fetchStart())
        createExternalFormulaAPI(data, type, id)
            .then(response => {
                dispatch(fetchEnd())
                toast.success(response.data.response)
                dispatch(getExternalFormula(1, 0, 'search', JSON.parse(localStorage.getItem('search-data-external-formula'))))
                history.push({ pathname: '/external-formula-results', type: 'edit' })

            })
            .catch(error => {
                dispatch(fetchEnd())
                // if (error.response && error.response.data && error.response.data.error && error.response.data.error.message)
                toast.error(error.response.data.complete_error_message);
            });
    }
}

const createExternalFormulaAPI = (data, type, id) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    if (type === 'delete') {
        result = axios.delete('external-formula-results/' + id, { headers: headers });
    }
    else if (type === 'edit') {
        result = axios.put('external-formula-results/' + id, data, { headers: headers });
    }
    else {
        result = axios.post('external-formula-results', data, { headers: headers });
    }
    return result;
}

export const getExternalFormulaSingleItem = (id) => {
    return dispatch => {
        getExternalFormulaSingleItemAPI(id)
            .then(response => {
                dispatch(getExternalFormulaSingleItemSuccess(response.data.response));
            })

    }
}
const getExternalFormulaSingleItemAPI = (id) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.get('external-formula-results/' + id, { headers: headers });
    return result;
}

export const getExternalFormulaSingleItemSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_SINGLE_SUCCESS,
        data
    }
}

//format


export const getExternalFormulaFormatData = () => {
    return dispatch => {
        getExternalFormulaFormatDataApi()
            .then(response => {
                dispatch(getExternalFormulaFormatDataSuccess(response.data.response));
            })
            .catch(error => {
                toast.error(error.response.data.complete_error_message);
            });
    }
}
const getExternalFormulaFormatDataApi = () => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.get('upload-format?app-functionality=ExternalFormulaResultFormat', { headers: headers });
    return result;
}
export const getExternalFormulaFormatDataSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_FORMAT_SUCCESS,
        data
    }
}
export const getExternalFormulaFormatDataById = (id) => {
    return dispatch => {
        getExternalFormulaFormatDataByIdApi(id)
            .then(response => {
                dispatch(getExternalFormulaFormatDataByIdSuccess(response.data.response));
            })
            .catch(error => {
                toast.error(error.response.data.error.message);
            });
    }
}
const getExternalFormulaFormatDataByIdApi = (id) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.get('external-formula-results-format/' + id, { headers: headers });
    return result;
}
export const getExternalFormulaFormatDataByIdSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_FORMAT_BY_ID_SUCCESS,
        data
    }
}
//get membership format fields
export const getExternalFormulaFormatFields = () => {
    return dispatch => {
        getExternalFormulaFormatFieldsApi()
            .then(response => {
                dispatch(getExternalFormulaFormatFieldsSuccess(response.data.response));
            })
            .catch(error => {
                toast.error(error.response.data.error.message);
            });
    }
}
const getExternalFormulaFormatFieldsApi = () => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.get('upload-format-fields?app-functionality=ExternalFormulaResultFormat', { headers: headers });
    return result;
}
export const getExternalFormulaFormatFieldsSuccess = (data) => {
    return {
        type: EXTERNAL_FORMULA_FORMAT_FIELDS_SUCCESS,
        data
    }
}
export const updateExternalFormulaFormatData = (id, data) => {
    return dispatch => {
        dispatch(fetchStart());
        updateExternalFormulaFormatDataApi(id, data)
            .then(response => {
                dispatch(fetchEnd());
                if (response.status === 201) {
                    toast.success(response.data.response);
                    history.push('/external-formula-results/external-formula-results-excel-upload');
                } else {
                    toast.error(ServerError);
                }
            })
            .catch(error => {
                dispatch(fetchEnd());
                toast.error(ServerError);
            });
    }
}
const updateExternalFormulaFormatDataApi = (id, data) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.put('upload-format/' + id + '?app-functionality=ExternalFormulaResultFormat', data, { headers: headers });
    return result;
}
//add membership format
export const addExternalFormulaFormatData = (data) => {
    return dispatch => {
        dispatch(fetchStart());
        addExternalFormulaFormatDataApi(data)
            .then(response => {

                dispatch(fetchEnd());
                //dispatch(getExternalFormulaFormatData())
                toast.success(response.data.response);
                history.push('/external-formula-results/external-formula-results-excel-upload');
            })
            .catch(error => {
                dispatch(fetchEnd());
                toast.error(error.response.data.error.message);
            });
    }
}
const addExternalFormulaFormatDataApi = (data) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.post('upload-format?app-functionality=ExternalFormulaResultFormat', data, { headers: headers });
    return result;
}
// delete membership format
export const deleteExternalFormulaFormatData = (id) => {
    return dispatch => {
        dispatch(fetchStart());
        deleteExternalFormulaFormatDataApi(id)
            .then(response => {
                dispatch(fetchEnd());
                if (response.status === 201) {
                    toast.success('Successfully deleted');
                    dispatch(getExternalFormulaFormatData())
                } else {
                    toast.error(ServerError);
                }
            })
            .catch(error => {
                dispatch(fetchEnd());
                toast.error(ServerError);
            });
    }
}
const deleteExternalFormulaFormatDataApi = (id) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk")
    };
    var result;
    result = axios.delete('upload-format/' + id, { headers: headers });
    return result;
}
export const uploadExternalFormulaFile = (id, file) => {
    return dispatch => {
        dispatch(fetchStart())
        uploadExternalFormulaFileApi(id, file)
            .then(response => {
                dispatch(fetchEnd());
                if (response.status === 200) {
                    //window.location.reload();
                    toast.success('File has been successfully uploaded.');
                } else {
                    toast.error(response.data.complete_error_message);
                }
            })
            .catch(error => {
                dispatch(fetchEnd());
                toast.error(error.response.data.complete_error_message);
            });
    }
}
const uploadExternalFormulaFileApi = (id, file) => {
    const headers = {
        'Authorization': secureLocalStorage.getItem("aTk"),
        'Content-Type': 'multipart/form-data;'
    };
    var result;
    result = axios.post('external-formula-results/upload?format_id=' + id, file, { headers: headers });
    return result;
}