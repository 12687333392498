import React, { useRef, useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Stepper,
    Step,
    StepButton,
    StepConnector,
    StepLabel,
    Button,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { GeneralData, OrganizationData, ProductFeature, UoMData } from './components';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { editMaterialMasterUser, getMaterialMasterViewDetails, getLblDispDesFieldValue } from '../../redux/actions';
import { Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    },


}));
const tabs = {
    generalData: 0,
    // groupingIdentifiers: 1,
    uomData: 1,
    productFeature: 2,
    // ima360Data: 4,
    OrganizationData: 3
};
// function getSteps() {
//     return ['General Data', 'Grouping/Identifiers', 'UoM', 'Product Feature', 'IMA360 Data', 'Organization Data'];
// }
function getSteps() {
    var application = sessionStorage.getItem("application");
    const steps = ['General Data'];

    if (application === 'Master Data') {
        steps.splice(1, 0, 'UoM');
        steps.splice(2, 0, 'Product Feature');
        steps.splice(3, 0, 'Organization Data');
    }

    return steps;
}
const EditMaterial = props => {
    const classes = useStyles();
    var generalData = {};
    var groupingIdentifiersData = {};
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const childRef4 = useRef();
    const [saveTriggered, setSaveTriggered] = React.useState('');
    const [organizationData, setOrganizationData] = React.useState([]);
    const [uomData, setUomData] = React.useState([]);
    var ima360Data = {};
    const [productFeature, setProductFeature] = React.useState([]);
    // var internalNotesData = {};
    const [generalDataRequired, setGeneralDataRequired] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [sortedData, setSortedData] = React.useState([]);
    const skipped = new Set()
    const steps = getSteps();
    const history = useHistory();
    const totalSteps = () => {
        return getSteps().length;
    };
    const theme = useTheme();
    const application = sessionStorage.getItem("application");
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        props.getMaterialMasterViewDetails(localStorage.getItem('materialMasterEditId'));
    }, []);

    useEffect(() => {
        var data = { "data_source": "material_master" };
        props.getLblDispDesFieldValue(data, 'post');
    }, [])
    useEffect(() => {
        if (props.tableData) {
            setSortedData(props.tableData.field_label_attributes);
        }
    }, [props.tableData])

    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        if (step === tabs.eligibilityRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckEligibillityRulesTable(localStorage.getItem('currentAddContractID'));
        } else if (step === tabs.calculationRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckCalculationRulesTable(localStorage.getItem('currentAddContractID'));
        }
        setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleComplete = () => {
        var organizationDataArray = { "organizations": organizationData };
        var productFeatureArray = { "product_features": productFeature }
        var completeData = Object.assign(generalData, groupingIdentifiersData, productFeatureArray, ima360Data, organizationDataArray);
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId === 'material-master-promo') {
            props.onSubmit(completeData, uomData);
        } else {
            props.onSubmit(completeData, uomData);
        }
    };
    const handleDiscard = () => {
        history.push("/material-master");
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/material-master");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    function handleGeneralDataChange(values) {
        generalData = values;
        checkValues();
    };
    function handleGroupingIdentifiers(values) {
        groupingIdentifiersData = values;
        checkValues();
    };
    function handleOrganizationData(values) {
        setOrganizationData(values);
        checkValues();
    };
    function handleUomData(values) {
        setUomData(values);
        checkValues();
    };
    function handleIma360Data(values) {
        ima360Data = values;
        checkValues();
    };
    function handleProductFeature(values) {
        setProductFeature(values);
        checkValues();
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        const allSteps = [
            <GeneralData onChange={handleGeneralDataChange} data={sortedData} savetriggered={saveTriggered} ref={childRef1} />,
            // <GroupingIdentifiers onChange={handleGroupingIdentifiers} data={sortedData} savetriggered={saveTriggered} ref={childRef2} />,
            <UoMData onChange={handleUomData} data={sortedData} />,
            <ProductFeature onChange={handleProductFeature} data={sortedData} />,
            // <Ima360Data onChange={handleIma360Data} data={sortedData} />,
            <OrganizationData onChange={handleOrganizationData} data={sortedData} savetriggered={saveTriggered} ref={childRef3} />,
        ];
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }
    const checkValues = () => {
        const mandatoryFields = Object.keys(sortedData).filter(key => sortedData[key].mandatory);
        const hasMissingMandatoryFields = ((mandatoryFields.some(key => !generalData[key])))
        setSaveDisabled(hasMissingMandatoryFields);
    };
    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link variant='h1' classes={{
                        root: classes.link
                    }}
                        to='/material-master'
                    >
                        Material Master
                </Link> */}
                    <Typography color="textPrimary" variant='h1'>&ensp;{props.location && props.location.state ? 'Edit Contract' : 'Edit Material'}</Typography>
                </Breadcrumbs>
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep !== 0 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button variant="outlined" color="primary" onClick={handleComplete}
                            className={clsx({
                                [classes.button]: true,
                                [classes.shiftContent]: !isDesktop,
                            })}
                            disabled={saveDisabled}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                            Discard
                        </Button>
                        {activeStep != tabs.OrganizationData && application === 'Master Data' &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}>
                                {activeStep === tabs.OrganizationData ? 'Done' : 'Next Step'}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};
const mapStateToProps = state => {
    return {
        materialMasterData: state.customerData.materialMasterViewData,
        loadingAPI: state.customerData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMaterialMasterViewDetails: (id) => dispatch(getMaterialMasterViewDetails(id, 'edit')),
        onSubmit: (data, uomData) => dispatch(editMaterialMasterUser(data, localStorage.getItem('materialMasterEditId'), uomData)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMaterial);