import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    FormLabel,
    OutlinedInput,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText, TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getUserEmailLink, postUserRoleAccess, onLoadingUserRoleAccessList, getAccessData, getCollaborationPortal, resendUserEmail, deleteUser } from '../../redux/actions';
import Moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import momentTZ from 'moment-timezone';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CountryDropdown } from 'react-country-region-selector';
import secureLocalStorage from "react-secure-storage";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '10px 45px 0px 45px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        width: '100%',
        paddingLeft: 10
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    table: {
        marginRight: 15
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    IconButton: {
        padding: 10
    },
    borderRad0: {
        borderRadius: '0px !important'
    },
    region: {
        color: '#828282',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 7,
        height: 38,
        fontSize: ' 1rem',
        fontFamily: 'Roboto Slab, serif'

    },
    copyLinkColor: {
        color: theme.palette.primary.main
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fontSetting1: {
        fontSize: theme.typography.h3.fontSize
    },
    spinner: {
        height: '100vh'
    },
    timezones: {
        height: 37,
        marginTop: 9,
        borderRadius: '0px !important'
    }
}));

const UserAccess = props => {
    useEffect(() => {
        props.onLoadingUserRoleAccessList();
        props.getAccessData();
        props.getCollaborationPortal(1, 100);
    }, []);
    const [showEmailPopup, setShowEmailPopup] = React.useState(false);
    const [userData, setUserData] = React.useState([]);


    useEffect(() => {
        if (props.userRoleAccessData) {
            setUserData(props.userRoleAccessData)
        }
    }, [props.userRoleAccessData])
    const userStatusData = [
        { key: 'Not Validated', value: 'Not Validated' },
        { key: 'Validated', value: 'Validated' },
    ];
    const classes = useStyles();
    const { className } = props;
    const [userName, setUserName] = React.useState('');
    const [organizationId, setOrganizationId] = React.useState('');
    const [securityRole, setSecurityRole] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [userPhone, setUserPhone] = React.useState('');
    const [userStatus, setUserStatus] = React.useState('');
    const [applicationType, setApplicationType] = React.useState([]);
    const [externalCheckbox, setExternalCheckbox] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [currentEditId, setCurrentEditId] = React.useState('');
    const [collaborationID, setCollaborationID] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [language, setLanguage] = React.useState('');
    const [timezone, setTimezone] = React.useState('');
    const [collaborationData, setCollaborationData] = React.useState([]);
    const [accessData, setAccessData] = React.useState([]);
    const languageArray = [
        ' Arabic', 'English', 'French', 'German', 'Hindi', 'Spanish', 'Japanese'
    ]
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    const timeZonesList = momentTZ.tz.names() ? momentTZ.tz.names() : '';
    function handleSecurityRole(event) {
        setSecurityRole(event.target.value);
    }
    const handleExternalCheckbox = (event) => {
        setExternalCheckbox(event.target.checked);
    }
    const handleClear = () => {
    }
    const handleSubmit = () => {
        var formData = {
            "name": userName,
            "role": securityRole,
            "email": userEmail,
            "phone": userPhone,
            "status": 'Not Validated',
            "app_types": applicationType,
            "external": externalCheckbox,
            "country": country,
            "language": language,
            "timezone": timezone
        }
        if (externalCheckbox) {
            formData['collaboration_id'] = collaborationID;
        }
        props.postUserRoleAccess(formData, editMode ? 'edit' : '', currentEditId);
        setEditMode(false);
        clearAll();
    }
    const editUserSetup = (item) => {
        setUserName(item.name);
        setSecurityRole(item.role);
        setUserEmail(item.email);
        setUserPhone(item.phone);
        setCountry(item.country);
        setLanguage(item.language);
        setTimezone(item.timezone);
        setCollaborationID(item.collaboration_id)
        setUserStatus(item.email_verified_status === "false" ? 'Not Validated' : 'Validated');
        setExternalCheckbox(item.external);
        setCurrentEditId(item.id);
        setEditMode(true);
    }
    const resendEmail = (item) => {
        props.resendUserEmail(item.id);
    }
    const deleteUser = (item) => {
        props.deleteUser(item.id);
    }
    const clearAll = () => {
        setUserName('');
        setSecurityRole('');
        setUserEmail('');
        setUserPhone('');
        setUserStatus('');
        setApplicationType([]);
        setExternalCheckbox(false);
        setCurrentEditId('');
        setEditMode(false);
        setCollaborationID('');
        setCountry('');
        setLanguage('')
        setTimezone('')
    }
    const handleGetEmailLink = (id) => {
        setShowEmailPopup(true)
        props.getUserEmailLink(id);
    }
    const handleCopyLink = () => {
        toast.success('URL copied successfully.')
        setShowEmailPopup(false)
    }

    const [copyText, setCopyText] = React.useState('');
    useEffect(() => {
        if (props.emailLinkResponse) {
            setCopyText(props.emailLinkResponse)
        }
    }, [props.emailLinkResponse])
    useEffect(() => {
        if (props.accessData) {
            setAccessData(props.accessData)
        }
    }, [props.accessData])
    useEffect(() => {
        if (props.collaborationPortalData) {
            setCollaborationData(props.collaborationPortalData)
        }
    }, [props.collaborationPortalData])
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div>
                <Dialog open={
                    showEmailPopup}
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                Your link for reseting user password is generated.
                                <CopyToClipboard text={copyText}
                                >
                                    <Button className={classes.copyLinkColor} onClick={handleCopyLink}>Click to copy text</Button>
                                </CopyToClipboard>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                    </DialogActions>
                </Dialog>

                <div
                    className={clsx(classes.root, className)}>
                    <div style={{ marginLeft: '20px' }}>
                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}> User Setup </Typography>
                    </div>
                    {(secureLocalStorage.getItem('dYtz').role.name === 'admin' || secureLocalStorage.getItem('dYtz').role.name === 'org_admin' || secureLocalStorage.getItem('dYtz').role.authorized_apps.indexOf('Security Cockpit') > -1) &&
                        < form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }} required>
                                            Name
                                        </FormLabel>
                                        <OutlinedInput
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setUserName(e.target.value)}
                                            value={userName}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }} required>
                                            Security Role
                                        </FormLabel>
                                        {accessData && accessData.records &&
                                            <Select
                                                value={securityRole}
                                                onChange={handleSecurityRole}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Select Security role
                                                </MenuItem>
                                                {accessData.records.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.name} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        }
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }} required>
                                            User Email
                                        </FormLabel>
                                        <OutlinedInput
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setUserEmail(e.target.value)}
                                            value={userEmail}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }}>
                                            User Phone
                                        </FormLabel>
                                        <OutlinedInput
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => e.target.value.length < 13 ? setUserPhone(e.target.value) : ''}
                                            inputProps={{
                                                type: 'number'
                                            }}
                                            value={userPhone}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }}>
                                            User Status
                                        </FormLabel>

                                        <Select
                                            value={userStatus}
                                            onChange={e => setUserStatus(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            defaultValue={'Not Validated'}
                                            disabled={'true'}
                                        >
                                            <MenuItem value="">Not Validated</MenuItem>
                                            {userStatusData.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Grid>

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }}>
                                            Country
                                        </FormLabel>
                                        <CountryDropdown
                                            value={country}
                                            onChange={(val) => setCountry(val)}
                                            classes={
                                                classes.region
                                            }
                                            priorityOptions={['US']}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }} >
                                            Language
                                        </FormLabel>
                                        {/*<OutlinedInput
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setLanguage(e.target.value)}
                                        value={language}
                                    />*/}
                                        <Select
                                            value={language}
                                            onChange={e => setLanguage(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            style={{ height: 42 }}
                                        >
                                            <MenuItem value="">
                                                Select Language
                                            </MenuItem>
                                            {languageArray.map(item => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>

                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }} >
                                            Timezone
                                        </FormLabel>

                                        <Autocomplete
                                            id="checkboxes-tags-demo"
                                            options={timeZonesList}
                                            getOptionLabel={(option) => option}
                                            defaultValue={timezone}
                                            value={timezone}
                                            renderInput={params => (
                                                <div className={classes.timezones}>
                                                    <TextField {...params} variant="outlined" size="small" />
                                                </div>



                                            )}
                                            classes={{
                                                inputRoot: classes.borderRad0
                                            }}

                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    {option}
                                                </li>
                                            )}
                                            style={{ width: "100%", marginBottom: 15 }}
                                            onChange={(event, newValue) => { setTimezone(newValue) }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <FormControlLabel
                                            value="external"
                                            control={<Checkbox color="primary"
                                                onChange={handleExternalCheckbox}
                                                checked={externalCheckbox}
                                            />}
                                            classes={{ root: classes.fontSetting1 }}
                                            label="External"
                                            labelPlacement="right"
                                            style={{ display: 'flex', height: '100%' }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 10 }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting1 }}
                                            disabled={externalCheckbox ? false : true}
                                        >
                                            Collaboration ID
                                        </FormLabel>
                                        <Select
                                            value={collaborationID}
                                            onChange={e => setCollaborationID(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            disabled={externalCheckbox ? false : true}
                                        >
                                            {collaborationData && collaborationData.records && collaborationData.records && collaborationData.records.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.ID} key={item.ID}>
                                                        {item.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <div className={classes.buttonContainer}>
                                    <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={clearAll} >
                                        Clear
                                    </Button>
                                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                                        disabled={userName && securityRole && userEmail ? false : true}
                                    >
                                        {editMode ? 'Edit' : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    }
                    {userData && userData.length > 0 &&
                        <div style={{ margin: 15 }}>
                            <TableContainer>
                                <Table>
                                    <TableHead   >
                                        <TableRow >
                                            <TableCell align='center' className={classes.tabHead}>User ID</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Name</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>User Phone</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>User Status</TableCell>
                                            {/* <TableCell align='center'>Application Type</TableCell>*/}
                                            <TableCell align='center' className={classes.tabHead}>External</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Organization ID</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Security Role</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>User Email</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Country</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Language</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Timezone</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Created On</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Last Update</TableCell>
                                            <TableCell align='center' className={classes.tabHead}>Generate Link</TableCell>
                                            <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody classes={{ root: classes.table }} >
                                        {userData && userData.map(item => {
                                            return (
                                                <StyledTableRow>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.id}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.name}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.phone}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.email_verified_status === "true" ? 'Verified' : 'Not Verified'}</TableCell>
                                                    {/* <TableCell align='center'>{item.app_types}</TableCell>*/}
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.external === true ? 'Yes' : 'No'}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.organization_id}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.role}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.email}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.country}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.language}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.timezone}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.created_at != '0001-01-01T00:00:00Z' ? Moment.utc(item.created_at).format('MM/DD/YYYY') : ''}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.updated_at != '0001-01-01T00:00:00Z' ? Moment.utc(item.updated_at).format('MM/DD/YYYY') : ''}</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}
                                                        className={classes.copyLinkColor}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleGetEmailLink(item.id)} >Generate Link</TableCell>
                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ width: 140 }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                            <IconButton
                                                                onClick={() => editUserSetup(item)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => resendEmail(item)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <EmailIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => deleteUser(item)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                <DeleteIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                </div>
            </div >
        </LoadingOverlay>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        postUserRoleAccess: (data, mode, id) => dispatch(postUserRoleAccess(data, mode, id)),
        onLoadingUserRoleAccessList: () => dispatch(onLoadingUserRoleAccessList()),
        getAccessData: () => dispatch(getAccessData()),
        getCollaborationPortal: (pagination, item) => dispatch(getCollaborationPortal(pagination, item)),
        resendUserEmail: (id) => dispatch(resendUserEmail(id)),
        deleteUser: (id) => dispatch(deleteUser(id)),
        getUserEmailLink: (id) => dispatch(getUserEmailLink(id)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        userRoleAccessData: state.addMultipleConfigurationData.userRoleAccessData,
        accessData: state.addMultipleConfigurationData.accessData,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        emailLinkResponse: state.addMultipleConfigurationData.emailLinkResponse,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);