import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    IconButton,
    Typography,
    Dialog, DialogActions, DialogContent, DialogContentText,
    Grid,
    Button
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { triggerIntegration, getIntegrationSetupData, getFilterById, imHistory } from '../../redux/actions';
import MaterialTable from 'material-table';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import Moment from 'moment';
import ScaleLoader from "react-spinners/ScaleLoader";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { TextInputWithLabel, DropdownArray } from '../../components/Inputs';
import { tableIcons } from '../../components/Icons/TableIcons';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    }
}));
const OndemandIntegration = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [integrationSetupData, setIntegrationSetupData] = React.useState([]);
    const [currentID, setCurrentID] = React.useState(0);
    const [filterN, setFilterN] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [filterName, setFilterName] = React.useState([]);
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterV, setFilterv] = React.useState([]);
    const [dataRows, setDataRows] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        props.getIntegrationSetupData(1, 10);
    }, []);
    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records && props.integrationSetupData.records.length > 0) {
            setIntegrationSetupData(props.integrationSetupData)
        }
    }, [props.integrationSetupData]);
    useEffect(() => {
        if (props.filterNameById) {
            setFilterNameArray(props.filterNameById);
        }
    }, [props.filterNameById]);
    function editIntegration(item) {
        setOpen(true);
        setCurrentID(item)
        setFilterName(item.filter_name)
        setFiltervalue(item.filter_value)
        props.getFilterById(item);
    }
    function handleSubmit(id) {
        var data = {
            "filter_name": filterN,
            "filter_value": filterV,
        };
        props.triggerIntegration(id, data);
        setOpen(false);
    }
    function handleClear() {
        setFilterName('');
        setFiltervalue('');
    }
    function handleDelete(id) {
        props.onDelete(id);
    }
    function handleFilterName(newValue, index) {
        filterN[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterV[index] = newValue;
    }
    function handleOnAdd() {
        filterN[addMember] = ''
        filterV[addMember] = ''
        setAddMember(addMember + 1)
    }
    function handleItem(id) {
        props.getIntegrationSetupData(id);
    }
    useEffect(() => {
        var tempRows = [];
        if (integrationSetupData && integrationSetupData.records && integrationSetupData.records.length > 0)
            integrationSetupData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        name: e.name,
                        activity: e.activity,
                        datasource: e.datasource,
                        last_data_loaded_on: e.last_data_loaded_on,
                        filter_value_type: e.filter_value_type,
                        filter_datatype: e.filter_datatype,
                        filter_name: e.filter_name,
                        filter_value: e.filter_value,
                        password: e.password,
                        url: e.url,
                        user_id: e.user_id,
                        last_load_success: e.last_load_success
                    });
                })
        setDataRows(tempRows);
    }, [integrationSetupData]);
    const columns = [
        {
            field: 'name',
            title: 'Integration Name',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <a className={classes.hover}
                    onClick={() =>
                        props.imHistory(rowData.id)}
                    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}> {rowData.name}</a>
        },
        {
            field: 'activity',
            title: 'Activity',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.activity}</a>
        },
        {
            field: 'datasource',
            title: 'Data Source',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> {rowData.datasource}</a>
        },
        {
            field: 'name',
            title: 'Actions',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} onClick={() => handleItem(rowData.id)}> <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => editIntegration(rowData.id)}
                size="large">
                {props.loading && currentID == rowData.id ?
                    <ScaleLoader color="blue" height={8} />
                    :
                    <LoopIcon fontSize="small" style={{ color: 'green' }} />
                }
            </IconButton></a>
        },
    ];
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <LoadingOverlay
                    active={props.loading}
                    spinner
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '50px',
                            '& svg circle': {
                                stroke: '#045FB4'
                            }
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(52, 52, 52, 0)'
                        }),
                        content: (base) => ({
                            ...base,
                            color: 'black'
                        })
                    }}
                    text='Loading Data. Please wait ...'
                    className={classes.spinner}
                >
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Typography variant="h1" color='primary'>On-Demand Integration</Typography>
                    </div>
                    {integrationSetupData && integrationSetupData.records && integrationSetupData.records.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={dataRows}
                            // style={{marginTop:'-18px'}}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        /> :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                    }
                </LoadingOverlay>
            </div >
            <Dialog open={open}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <Grid container spacing={2}
                                style={{ marginBottom: 16, padding: '16px 25px 0px 25px' }}
                            >   {(() => {
                                let td = [];
                                for (let i = 0; i < addMember; i++) {
                                    td.push(<>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >  <DropdownArray heading={i == 0 && 'Filter Name'}
                                            placeholder={'Please select'}
                                            twoline='true'
                                            onChange={(e) => handleFilterName(e, i)}
                                            data={filterNameArray}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        ><TextInputWithLabel heading={i == 0 && 'Value'} twoline='true' onChange={(e) => handleFilterValue(e, i)} />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ paddingTop: 20 }}
                                        >
                                            <IconButton
                                                style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 40 : 25 }}
                                                onClick={handleOnAdd}
                                                classes={{ root: classes.IconButton }}
                                                size="large">
                                                <AddBoxOutlinedIcon />
                                            </IconButton>
                                        </Grid>
                                    </>)
                                }
                                return td;
                            })()}
                            </Grid>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={() => setOpen(false)} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit(currentID)} color="primary" autoFocus variant="contained"
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        integrationSetupData: state.customerData.integrationSetupData,
        loading: state.customerData.loading,
        filterNameById: state.customerData.filterNameById,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        triggerIntegration: (id, data) => dispatch(triggerIntegration(id, data)),
        getIntegrationSetupData: () => dispatch(getIntegrationSetupData(1, 20)),
        getFilterById: (id) => dispatch(getFilterById(id)),
        imHistory: (id) => dispatch(imHistory(id, 1, 10))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OndemandIntegration);