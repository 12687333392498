import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    Select,
    MenuItem,
    FormLabel,
    Button, Checkbox, OutlinedInput, TextField
} from '@mui/material';
import { TextInputWithLabel } from '../../../../../components/Inputs';
import clsx from 'clsx';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import { getBudgetingConfig, getCountryData, getGlobalSetupData } from '../../../../../redux/actions';
import { color } from 'jsuites';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '5px 25px 0px 25px !important'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    outlined: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    checkBoxContainer: {
        paddingLeft: 0,
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const GeneralData = props => {
    const classes = useStyles();
    const history = useHistory();
    const [promotionNumber, setPromotionNumber] = React.useState('');
    const [promotionTheme, setPromotionTheme] = React.useState('');
    const [promotionCategory, setPromotionCategory] = React.useState('');
    const [promotionType, setPromotionType] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [promotionStartDate, setPromotionStartDate] = React.useState(null);
    const [promotionEndDate, setPromotionEndDate] = React.useState(null);
    const [reservationStartDate, setReservationStartDate] = React.useState(null);
    const [reservationEndDate, setReservationEndDate] = React.useState(null);
    const [registrationStartDate, setRegistrationStartDate] = React.useState(null);
    const [registrationEndDate, setRegistrationEndDate] = React.useState(null);


    const [data, setData] = React.useState([]);
    const [promoCategoryArray, setPromoCategoryArray] = React.useState([]);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoThemeArray, setPromoThemeArray] = React.useState([]);
    const [promoTypeArray, setPromoTypeArray] = React.useState([]);
    const [promoCurrency, setPromoCurrency] = React.useState('');
    const [costingCurrency, setCostingCurrency] = React.useState('');
    const [costingDesc, setCostingDesc] = React.useState('');
    const [exclusivePromotion, setExclusivePromotion] = React.useState(false);
    const [depositAmount, setDepositAmount] = React.useState(0);

    const currency = ['USD', 'CAD', 'EUR']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,


    }
    var formData
    useEffect(() => {
        props.getConfigData();
    }, []);


    useEffect(() => {

        if (props.editMode && props.promoData) {

            setPromotionNumber(props.promoData.costing_number)
            setPromotionTheme(props.promoData.promotion_theme)
            setPromotionCategory(props.promoData.promotion_category)
            setPromotionType(props.promoData.promotion_type)
            setCountry(props.promoData.country)
            props.getCountryData(props.promoData.country)
            props.getGlobalSetupData(props.promoData.country)
            localStorage.setItem("Country", props.promoData.country);
            setDivision(props.promoData.division)
            setPromotionStartDate(props.promoData.promotion_start_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.promotion_start_date) : null)
            setPromotionEndDate(props.promoData.promotion_end_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.promotion_end_date) : null)
            setReservationStartDate(props.promoData.reservation_start_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.reservation_start_date) : null)
            setReservationEndDate(props.promoData.reservation_end_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.reservation_end_date) : null)
            setRegistrationStartDate(props.promoData.registration_start_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.registration_start_date) : null)
            setRegistrationEndDate(props.promoData.registration_end_date != '0001-01-01T00:00:00Z' ? Moment.utc(props.promoData.registration_end_date) : null)
            setPromoCurrency(props.promoData.promotion_currency)
            setCostingCurrency(props.promoData.costing_currency)
            setCostingDesc(props.promoData.costing_description)
            setDepositAmount(props.promoData.deposit_amount != 0 ? props.promoData.deposit_amount : '')
            setExclusivePromotion(props.promoData.exclusive_promotion)
        }
        props.getConfigData()
    }, [props.promoData]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setPromoCategoryArray(props.configData[0].promotion_category)
            setPromoDivisionArray(props.configData[0].promotion_division)
            setPromoThemeArray(props.configData[0].promotion_theme)
            setPromoTypeArray(props.configData[0].promotion_type)
        }
    }, [props.configData]);

    useEffect(() => {
        props.onChange(data);
    }, [data]);
    function handleFormData() {
        formData = {
            promotion_theme: promotionTheme,
            promotion_category: promotionCategory,
            promotion_type: promotionType,
            country: country,
            division: division,
            promotion_start_date: promotionStartDate ? new Date(Moment(promotionStartDate).format('YYYY-MM-DD')).toISOString() : null,
            promotion_end_date: promotionEndDate ? new Date(Moment(promotionEndDate).format('YYYY-MM-DD')).toISOString() : null,
            reservation_start_date: reservationStartDate ? new Date(Moment(reservationStartDate).format('YYYY-MM-DD')).toISOString() : null,
            reservation_end_date: reservationEndDate ? new Date(Moment(reservationEndDate).format('YYYY-MM-DD')).toISOString() : null,
            registration_start_date: registrationStartDate ? new Date(Moment(registrationStartDate).format('YYYY-MM-DD')).toISOString() : null,
            registration_end_date: registrationEndDate ? new Date(Moment(registrationEndDate).format('YYYY-MM-DD')).toISOString() : null,
            promotion_currency: promoCurrency,
            costing_currency: costingCurrency,
            costing_description: costingDesc,
            deposit_amount: parseFloat(depositAmount),
            exclusive_promotion: exclusivePromotion
        }
        if (props.editMode)
            setData({ ...formData, costing_number: parseInt(promotionNumber) })
        else
            setData(formData)
        props.handleNext()
    }

    function handlePromotionNumber(value) {
        setPromotionNumber(value);
    }
    function handlePromotionTheme(event) {
        setPromotionTheme(event.target.value);
    }
    function handlePromotionType(event) {
        setPromotionType(event.target.value);
    }
    function handleCountry(newValue) {
        setCountry(newValue);
        localStorage.setItem("Country", newValue);
    }
    function handleDivision(event) {
        setDivision(event.target.value);
    }
    function handlePromotionCategory(event) {
        setPromotionCategory(event.target.value);
    }
    const handlePromotionStartDate = (value) => {
        setPromotionStartDate(value);
    }
    const handlePromotionEndDate = (value) => {
        setPromotionEndDate(value);
    }
    const handleReservationStartDate = (value) => {
        setReservationStartDate(value);
    }
    const handleReservationEndDate = (value) => {
        setReservationEndDate(value);
    }
    const handleRegistrationStartDate = (value) => {
        setRegistrationStartDate(value);
    }
    const handleRegistrationEndDate = (value) => {
        setRegistrationEndDate(value);
    }

    useEffect(() => {
        props.getCountryData(country)
        props.getGlobalSetupData(country)
    }, [country])
    return (
        <div>
            <div className={classes.root}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="HEADER FIELDS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                {props.editMode &&
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                    >
                                        {/* <TextInputWithLabel heading={'Costing Number'} type={'number'} twoline='true' onChange={handlePromotionNumber} prevalue={promotionNumber} required disabled={true} /> */}
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 18 }} required>
                                            Costing Number
                                        </FormLabel>
                                        <OutlinedInput twoline='true'
                                            type={'number'}
                                            disabled={true}
                                            value={promotionNumber}
                                            onChange={handlePromotionNumber}
                                            className={classes.outlined} />
                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Promotion Theme
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={promotionTheme}
                                        onChange={handlePromotionTheme}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        {promoThemeArray && promoThemeArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Promotion Category
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={promotionCategory}
                                        onChange={handlePromotionCategory}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        {promoCategoryArray && promoCategoryArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>

                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 15, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Promotion Type
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={promotionType}
                                        onChange={handlePromotionType}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        {promoTypeArray && promoTypeArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid> */}
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 18 }} required>
                                        Costing Description
                                    </FormLabel>
                                    <OutlinedInput twoline='true'
                                        value={costingDesc}
                                        onChange={(e) => setCostingDesc(e.target.value)}
                                        className={classes.outlined} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Country
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={country}
                                        onChange={(e) => handleCountry(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                        disabled={props.editMode || props.enable ? true : false}
                                    >
                                        <MenuItem value={'Canada'} key={'Canada'}>
                                            Canada
                                        </MenuItem>
                                        <MenuItem value={'US'} key={'US'}>
                                            US
                                        </MenuItem>
                                    </Select>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Product Line
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={division}
                                        onChange={handleDivision}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                        disabled={props.editMode || props.enable ? true : false}
                                    >

                                        {promoDivisionArray && promoDivisionArray
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 20, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 16 }} required>
                                        Promotion Start Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={promotionStartDate}
                                                onChange={handlePromotionStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 20, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required>
                                        Promotion End Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={promotionEndDate}
                                                minDate={promotionStartDate ? promotionStartDate : ''}
                                                onChange={handlePromotionEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 15, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Promotion Currency
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={promoCurrency}
                                        onChange={(e) => setPromoCurrency(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        {currency && currency
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 15, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ paddingBottom: 10 }} required>
                                        Costing Currency
                                    </FormLabel>
                                    <Select
                                        disableUnderline
                                        value={costingCurrency}
                                        onChange={(e) => setCostingCurrency(e.target.value)}
                                        displayEmpty
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        style={{ textTransform: 'capitalize' }}
                                    >

                                        {currency && currency
                                            .map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 20, marginTop: 15 }}

                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 16 }}>
                                        Reservation Start Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={reservationStartDate}
                                                onChange={handleReservationStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 20, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>
                                        Reservation End Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={reservationEndDate}
                                                minDate={reservationStartDate ? reservationStartDate : ''}
                                                onChange={handleReservationEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginBottom: 20, marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 16 }}>
                                        Registration Start Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={registrationStartDate}
                                                onChange={handleRegistrationStartDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 16 }}>
                                        Registration End Date
                                    </FormLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="space-around">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        padding: '0px 11px',
                                                        height: 37,
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginTop: 10
                                                    }
                                                }}
                                                value={registrationEndDate}
                                                minDate={registrationStartDate ? registrationStartDate : ''}
                                                onChange={handleRegistrationEndDate}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>
                                        Deposit Amount
                                    </FormLabel>
                                    <OutlinedInput twoline='true'
                                        type={'number'}
                                        value={depositAmount != 0 ? depositAmount : ''}
                                        onChange={(e) => setDepositAmount(e.target.value)}
                                        className={classes.outlined} />
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}
                                >
                                    <div>
                                        <Checkbox
                                            checked={exclusivePromotion}
                                            onChange={(e) => setExclusivePromotion(e.target.checked)}
                                            color='primary'
                                            classes={{
                                                root: classes.formControlRoot
                                            }}
                                            className={classes.checkBoxContainer}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <FormLabel classes={{ root: classes.fontSetting }} >
                                            Exclusive Promotion
                                        </FormLabel>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>

            </div>
            <Grid
                item
                md={12}
                xs={12}
                classes={{ root: classes.gridContainer }}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Button variant="outlined" color='primary' className={classes.button} onClick={() => history.push('/promotion-setup')}>
                    Cancel
                </Button>
                <Button variant="contained" color='primary' className={classes.button} onClick={handleFormData}
                // disabled={
                //     promotionCategory && promotionTheme && division && country &&
                //         promotionStartDate && promotionEndDate && promoCurrency && costingCurrency && costingDesc ? false : true
                // }
                >
                    Next Step
                </Button>


            </Grid>
        </div>
    );

};
const mapStateToProps = state => {
    return {

        configData: state.calculationAnalysisData.budgetData,
        promoData: state.promotionData.promotionSetupSingleData,
        countryData: state.promotionData.countryData,
        materialData: state.promotionData.promotionMaterialData,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        getCountryData: (country) => dispatch(getCountryData(country)),
        getGlobalSetupData: (country) => dispatch(getGlobalSetupData(country)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);