import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography, TablePagination } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { AddBoxOutlined, Edit, DeleteForever, LibraryAdd, CloudUpload, SearchSharp } from '@mui/icons-material';
import { connect } from 'react-redux';
import { getCustomerMasterData, deleteCustomerMasterData, getLblDispDesFieldValue } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { getCustomerMasterViewDetails } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../../components/Dialog';
import HashLoader from "react-spinners/HashLoader";
import RingLoader from "react-spinners/RingLoader";
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }

    },

    buttonCreate: {
        textTransform: 'none', backgroundColor: theme.palette.primary.main, color: 'black'

    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }

    },
    buttonStyle2: {
        marginLeft: 10, backgroundColor: theme.palette.primary.main, textTransform: 'none'
    },

    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const CustomerMasterUserTable = props => {
    useEffect(() => {
        // props.getCustomerMasterData(1, 500);
    }, []);
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [count, setCount] = React.useState(0);
    const [currentId, setCurrentId] = React.useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [columns2, setColumns2] = React.useState([]);
    // const [columns, setColumns] = React.useState([
    //     {
    //         field: 'account_group',
    //         title: 'Account Group',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.account_group}</a>
    //     },
    //     {
    //         field: 'claim_reponse_edi_format',
    //         title: 'Claim Reponse EDI Format',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.claim_reponse_edi_format}</a>
    //     },
    //     {
    //         field: 'claim_reponse_excel_format',
    //         title: 'Claim Reponse Excel Format',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.claim_reponse_excel_format}</a>
    //     },
    //     {
    //         field: 'class_of_trade',
    //         title: 'Class Of Trade',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.class_of_trade}</a>
    //     },
    //     {
    //         field: 'customer_chain',
    //         title: 'Customer Chain',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_chain}</a>
    //     },
    //     {
    //         field: 'customer_classification',
    //         title: 'Customer Classification',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_classification}</a>
    //     },
    //     {
    //         field: 'customer_group',
    //         title: 'Customer Group',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group}</a>
    //     },
    //     {
    //         field: 'customer_group1',
    //         title: 'Customer Group1',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group1}</a>
    //     },
    //     {
    //         field: 'customer_group2',
    //         title: 'Customer Group2',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group2}</a>
    //     },
    //     {
    //         field: 'customer_group3',
    //         title: 'Customer Group3',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group3}</a>
    //     },
    //     {
    //         field: 'customer_group4',
    //         title: 'Customer Group4',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group4}</a>
    //     },
    //     {
    //         field: 'customer_group5',
    //         title: 'Customer Group5',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_group5}</a>
    //     },
    //     {
    //         field: 'customer_hierarchy',
    //         title: 'Customer Hierarchy',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_hierarchy}</a>
    //     },
    //     {
    //         field: 'customer_id_number',
    //         title: 'Customer ID Number',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_id_number.toString()}</a>
    //     },
    //     {
    //         field: 'customer_id_type',
    //         title: 'Customer ID Type',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_id_type.toString()}</a>
    //     },
    //     {
    //         field: 'customer_industry',
    //         title: 'Customer Industry',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_industry}</a>
    //     },
    //     {
    //         field: 'customer_number',
    //         title: 'Customer Number',
    //         editable: 'never',
    //         type: 'string',
    //         render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_number}</a>
    //     },
    //     {
    //         field: 'customer_name',
    //         title: 'Customer Name',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_name}</a>
    //     },
    //     {
    //         field: 'customer_type',
    //         title: 'Customer Type',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_type}</a>

    //     },
    //     {
    //         field: 'street',
    //         title: 'Street',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.street}</a>
    //     },
    //     {
    //         field: 'city',
    //         title: 'City',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.city}</a>

    //     },
    //     {
    //         field: 'region',
    //         title: 'Region',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.region}</a>

    //     },
    //     {
    //         field: 'postal_code',
    //         title: 'Postal Code',
    //         type: 'number',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.postal_code}</a>

    //     },
    //     {
    //         field: 'country',
    //         title: 'Country',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.country}</a>

    //     },
    //     {
    //         field: 'customer_status',
    //         title: 'Customer Status',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_status}</a>

    //     },
    //     {
    //         field: 'customer_supplier_number',
    //         title: 'Customer Supplier Number',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.customer_supplier_number}</a>

    //     },
    //     {
    //         field: 'email_address',
    //         title: 'Email Address',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.email_address}</a>

    //     },
    //     {
    //         field: 'deletion_flag',
    //         title: 'Deletion Flag',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.deletion_flag}</a>

    //     },
    //     {
    //         field: 'flex_attribute1',
    //         title: 'Flex Attribute1',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute1}</a>

    //     },
    //     {
    //         field: 'flex_attribute2',
    //         title: 'Flex Attribute2',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute2}</a>

    //     },
    //     {
    //         field: 'flex_attribute3',
    //         title: 'Flex Attribute3',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute3}</a>

    //     },
    //     {
    //         field: 'flex_attribute4',
    //         title: 'Flex Attribute4',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.flex_attribute4}</a>

    //     },
    //     {
    //         field: 'id_number01',
    //         title: 'Id Number01',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number01}</a>

    //     },
    //     {
    //         field: 'id_number02',
    //         title: 'Id Number02',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number02}</a>

    //     },
    //     {
    //         field: 'id_number03',
    //         title: 'Id Number03',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number03}</a>

    //     },
    //     {
    //         field: 'id_number04',
    //         title: 'Id Number04',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number04}</a>

    //     },
    //     {
    //         field: 'id_number05',
    //         title: 'Id Number05',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number05}</a>

    //     },
    //     {
    //         field: 'id_number06',
    //         title: 'Id Number06',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.id_number06}</a>

    //     },
    //     {
    //         field: 'head_office',
    //         title: 'Head Office',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.head_office}</a>

    //     },
    //     {
    //         field: 'payment_terms',
    //         title: 'Payment Terms',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.payment_terms}</a>

    //     },
    //     {
    //         field: 'external',
    //         title: 'External',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.external}</a>

    //     },
    //     {
    //         field: 'language',
    //         title: 'Language',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.language}</a>

    //     },
    //     {
    //         field: 'standard_address',
    //         title: 'Standard Address',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.standard_address}</a>

    //     },
    //     {
    //         field: 'price_list',
    //         title: 'Price List',
    //         editable: 'never',
    //         type: 'string',
    //         render: rowData => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID)}> {rowData.price_list}</a>

    //     },
    //     {
    //         field: 'Actions',
    //         title: 'Actions',
    //         type: 'string',
    //         editable: 'never',
    //         render: rowData =>
    //             <div style={{ whiteSpace: 'nowrap' }}>
    //                 {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-' + sessionStorage.getItem('application')) &&
    //                     <IconButton classes={{ root: classes.IconButton }} onClick={() => editContract(rowData.ID)}>
    //                         <Edit color="disabled" style={{ fontSize: 20 }} />
    //                     </IconButton>}
    //                 {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-' + sessionStorage.getItem('application')) &&
    //                     <IconButton classes={{ root: classes.IconButton }} onClick={() => handleClickOpen(rowData.ID)}>
    //                         <DeleteForever color="disabled" style={{ fontSize: 20 }} />
    //                     </IconButton>}
    //             </div>

    //     },
    // ]
    // );

    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length >= 0) {
            setDataRows(props.customerMasterData.records);
            setCount(props.customerMasterData.total_record)
            var data = { "data_source": "customer_master" }
            props.getLblDispDesFieldValue(data, 'post');
        }
    }, [props.customerMasterData]);

    useEffect(() => {
        var newArray = []
        var newArray2 = ['Actions']
        var tempObj = []

        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter((item, i) => item.sort_list)
                .sort((a, b) => {
                    return a.sort_list - b.sort_list;
                })
                .map((item, index) => {
                    if (item.sort_list) {
                        newArray2.push(item.default)
                        if (item.key === 'product_features') {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {""}</a>
                            });
                        }
                        else {
                            tempObj.push({
                                field: item.key,
                                title: item.current,
                                type: 'string',
                                editable: 'never',
                                render: (rowData) => <a className={classes.hover} onClick={() => handleItemClick(rowData.ID, rowData.material_number)}> {rowData[item.key]}</a>
                            });
                        }

                    }

                })
            tempObj.push({
                field: 'Actions',
                title: 'Actions',
                type: 'string',
                editable: 'never',
                render: rowData =>
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => editContract(rowData.ID)}
                                size="large">
                                <Edit color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                        {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_customer_master-' + sessionStorage.getItem('application')) &&
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleClickOpen(rowData.ID)}
                                size="large">
                                <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                            </IconButton>}
                    </div>
            })
            setColumns2(tempObj)
        }
    }, [props.tableData])
    // useEffect(() => {
    //     var newArray = []
    //     var newArray2 = ['Actions']
    //     var tempObj = []
    //     if (props.tableData) {
    //         Object.values(props.tableData.field_label_attributes).map((item, index) => {

    //             if (item.sort_list) {
    //                 newArray2.push(item.default)
    //                 tempObj.push(item)
    //             }

    //         })
    //         {
    //             columns && columns.length > 0 && columns
    //                 .filter(item => newArray2.includes(item.title))
    //                 .map((item, index) => {
    //                     newArray.push(item)
    //                     Object.values(tempObj).map(e => {
    //                         if (e.default == item.title) {
    //                             item['title'] = e.current
    //                             item['sort_order'] = e.sort_list
    //                         }
    //                     })

    //                 })
    //         }
    //         if (newArray.length > 1) {
    //             setColumns2(newArray.sort((a, b) => {
    //                 return a.sort_order - b.sort_order;
    //             }))
    //         }
    //     }
    // }, [props.tableData])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getCustomerMasterData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        props.getCustomerMasterData(1, event.target.value);
    };
    function editContract(id) {
        navigateToEdit(id);
    }

    function navigateToEdit(id) {
        history.push({
            pathname: '/customer-master/edit-customer/' + id,
            state: 'editContract',
            id: id
        });
        localStorage.setItem('customerMasterEditId', id);
    }
    function handleItemClick(id) {
        props.getCustomerMasterViewDetails(id);
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteCustomerMasterData(currentId);
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Customer Master </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_customer_master-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/customer-master/add-customer'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/customer-master/customer-master-excel-upload'
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                component={CustomRouterLink}
                                to='/customer-master/search-customer'
                            >
                                <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                            </Fab>
                        </div>}
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns2}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />

                    :
                    <div className={classes.noDataContainer}>
                        Display Customers by clicking on Search Icon.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >

    );
};

const mapStateToProps = state => {
    return {
        customerMasterData: state.customerData.customerMasterData,
        loading: state.customerData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData(pagination, limit)),
        getCustomerMasterViewDetails: (id) => dispatch(getCustomerMasterViewDetails(id)),
        deleteCustomerMasterData: (id) => dispatch(deleteCustomerMasterData(id, 'CustomerMaster')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMasterUserTable);