import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import { loadTop25Chart, loadPaymentGraph } from "../../../../redux/actions";
import PieChart from "../../Charts/PieChart";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: 13, //theme.typography.h4.fontSize,
    textTransform: "capitalize",
  },
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
}));
const RebateObjective = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [attribute, setAttribute] = React.useState("contract_type");
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const dimension = {
    xName: "GroupBy",
    yName: "Total",
    tooltipMappingName: "GroupBy",
  };

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Postings Summary by Contract Groupings" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && sourceDataType && postingType) {
      var data = {
        groupBy: attribute,
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        posting_type: postingType,
        form_id: "IMA_CONFIG_MULTIPLE",
        application_type: "Customer Rebate",
        data_source_type: sourceDataType,
      };
      props.loadPaymentGraph(data);
    }
  }, [allowedTilesFilter]);




  useEffect(() => {
    if (props.incentiveData) {
      if (props.incentiveData.payment_result)
        setGraphData(props.incentiveData.payment_result);
    }
  }, [props.incentiveData]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    var data = {
      groupBy: attribute,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: e.target.value,
      form_id: "IMA_CONFIG_MULTIPLE",
      application_type: "Customer Rebate",
      data_source_type: sourceDataType,
    };
    props.loadPaymentGraph(data);
  };

  const handleAttribute = (e) => {
    setAttribute(e.target.value);
    var data = {
      groupBy: e.target.value,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      form_id: "IMA_CONFIG_MULTIPLE",
      application_type: "Customer Rebate",
      data_source_type: sourceDataType,
    };
    props.loadPaymentGraph(data);
  };

  const handlePeriod = (type) => {
    var sDate;
    var eDate;
    if (type == "month") {
      sDate = Moment.utc()
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else if (type == "quarter") {
      sDate = Moment.utc()
        .subtract(3, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(3, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else {
      sDate = Moment.utc().startOf("year").format("MM/DD/YYYY");
      eDate = Moment.utc().endOf("year").format("MM/DD/YYYY");
    }

    var data = {
      groupBy: attribute,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      form_id: "IMA_CONFIG_MULTIPLE",
      application_type: "Customer Rebate",
      data_source_type: sourceDataType,
    };

    props.loadPaymentGraph(data);
  };

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }


  return (
    <div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <FormControl sx={{ m: 1, maxWidth: 150 }} size="small">
          <InputLabel id="posting-type" color="primary">
            Posting Type
          </InputLabel>
          <Select
            labelId="posting-type"
            id="posting-type-select"
            value={postingType}
            label="Posting Type"
            onChange={handlePostingType}
            color="primary"
            width="100%"
          >
            <MenuItem value={"accrual"}>Accrual</MenuItem>
            <MenuItem value={"payment"}>Payment</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab
            size="small"
            color="primary"
            aria-label="monthly"
            onClick={() => handlePeriod("month")}
          >
            <span style={{ marginLeft: 6 }}>M</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="quarterly"
            onClick={() => handlePeriod("quarter")}
          >
            <span style={{ marginLeft: 9 }}>Q</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="yearly"
            onClick={() => handlePeriod("year")}
          >
            <span style={{ marginLeft: 9 }}>Y</span>
          </Fab>
        </Box>
      </div>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={attribute}
          exclusive
          onChange={handleAttribute}
          aria-label="Platform"
          size="small"
        >
          <ToggleButton
            className={classes.formLabel}
            size="small"
            value="contract_group"
            style={{ textTransform: "capitalize" }}
          >
            Contract Group
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            style={{ textTransform: "capitalize" }}
            value="contract_type"
          >
            Contract Type
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            value="calculation_method"
            style={{ textTransform: "capitalize" }}
          >
            Calculation Method
          </ToggleButton>
          <ToggleButton
            className={classes.formLabel}
            value="contract_sub_group"
            style={{ textTransform: "capitalize" }}
          >
            Contract Objective
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div>
        <PieChart data={graphData} dimension={dimension} />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    incentiveData: state.initialData.incentiveData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPaymentGraph: (data) =>
      dispatch(loadPaymentGraph(data, null, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateObjective);
