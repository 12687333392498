import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    Select,
    MenuItem,
    FormLabel, OutlinedInput
} from '@mui/material';
import { TextInputWithLabel } from '../../../../components/Inputs';
import { AllOtherAttributeMasterComponent } from "../../../../components/Inputs";
import { getDefaultValuesMaterial, getLblDispDesFieldValue } from '../../../../redux/actions';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 45px 0px 45px !important'
    },
    select: {
        width: '100%',
        marginTop: 5,
        height: 37
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    }
}));

const GeneralData = props => {
    const classes = useStyles();
    useEffect(() => {
        props.onChange(formData);
    });

    //variable declarations
    const [materialDescription, setMaterialDescription] = React.useState('');
    const [materialType, setMaterialType] = React.useState('');
    const [supplier, setSupplier] = React.useState('');
    const [supplierMaterialNumber, setSupplierMaterialNumber] = React.useState('');
    const [baseUom, setBaseUom] = React.useState('');
    const [externalMaterialGroup, setExternalMaterialGroup] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [active, setActive] = React.useState('');
    const { className } = props;
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [productHierarchy, setProductHierarchy] = React.useState('');
    const [materialGroup, setMaterialGroup] = React.useState('');
    const [materialGroup1, setMaterialGroup1] = React.useState('');
    const [materialGroup2, setMaterialGroup2] = React.useState('');
    const [materialGroup3, setMaterialGroup3] = React.useState('');
    const [materialGroup4, setMaterialGroup4] = React.useState('');
    const [materialGroup5, setMaterialGroup5] = React.useState('');
    const [idNumber1, setIdNumber1] = React.useState('');
    const [idNumber2, setIdNumber2] = React.useState('');
    const [idNumber3, setIdNumber3] = React.useState('');
    const [idNumber4, setIdNumber4] = React.useState('');
    const [idNumber5, setIdNumber5] = React.useState('');
    const [idNumber6, setIdNumber6] = React.useState('');
    const [cost, setCost] = React.useState('');
    const [msrp, setMsrp] = React.useState('');
    const [pricingInventory, setPricingInventory] = React.useState('');
    const [flexAttribute1, setFlexAttribute1] = React.useState('');
    const [flexAttribute2, setFlexAttribute2] = React.useState('');
    const [flexAttribute3, setFlexAttribute3] = React.useState('');
    const [flexAttribute4, setFlexAttribute4] = React.useState('');
    const [materialIDTypeArray, setMaterialIDTypeArray] = React.useState([]);
    var formData = {
        material_description: materialDescription,
        material_type: materialType,
        supplier_name: supplier,
        supplier_material_number: supplierMaterialNumber,
        base_uom: baseUom,
        external_material_group: externalMaterialGroup,
        // flex_attribute1: flexAttribute1,
        // flex_attribute2: flexAttribute2,
        supplier_number: supplierNumber,
        material_status: active,
        material_number: '12',
        product_hierarchy: productHierarchy,
        material_group: materialGroup,
        material_group1: materialGroup1,
        material_group2: materialGroup2,
        material_group3: materialGroup3,
        material_group4: materialGroup4,
        material_group5: materialGroup5,
        id_number01: idNumber1,
        id_number02: idNumber2,
        id_number03: idNumber3,
        id_number04: idNumber4,
        id_number05: idNumber5,
        id_number06: idNumber6,
        cost: parseFloat(cost),
        msrp: parseFloat(msrp),
        pricing_inventory_alert_qty: parseFloat(pricingInventory),
        flex_attribute1: flexAttribute1,
        flex_attribute2: flexAttribute2,
        flex_attribute3: flexAttribute3,
        flex_attribute4: flexAttribute4
    }
    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);

    console.log(fieldLabel)

    useEffect(() => {
        props.onLoadingDefault()
    }, [])

    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            props.dropdownData.records.map((item) => {
                if (item.field_id === 'material_id_type' && item.type === 'DROPDOWN.STRING') {
                    setMaterialIDTypeArray(item);
                }

            })
        }

    }, [props.dropdownData]);
    //functions 
    function handleActive(event) {
        setActive(event.target.value);
    }
    function handleMaterialDescription(newValue) {
        setMaterialDescription(newValue);
    }
    function handleMaterialType(newValue) {
        if (newValue && newValue.length > 0) {
            setMaterialType(newValue);
        } else setMaterialType("");
        props.onChange(formData);
    }
    function handleSupplier(newValue) {
        setSupplier(newValue);
    }
    function handleSupplierMaterialNumber(newValue) {
        setSupplierMaterialNumber(newValue);
    }
    function handleBaseUom(newValue) {
        setBaseUom(newValue);
    }
    function handletExternalMaterialGroup(newValue) {
        setExternalMaterialGroup(newValue);
    }
    // function handleFlexAttribute1(newValue) {
    //     setFlexAttribute1(newValue);
    // }
    // function handleFlexAttribute2(newValue) {
    //     setFlexAttribute2(newValue);
    // }
    function handleSupplierNumber(e) {
        setSupplierNumber(e.target.value);
    }
    function handleProductHierarchy(newValue) {
        setProductHierarchy(newValue);
    }
    function handleMaterialGroup(newValue) {
        setMaterialGroup(newValue);
    }
    function handleMaterialGroup1(newValue) {
        setMaterialGroup1(newValue);
    }
    function handleMaterialGroup2(newValue) {
        setMaterialGroup2(newValue);
    }
    function handleMaterialGroup3(newValue) {
        setMaterialGroup3(newValue);
    }
    function handleMaterialGroup4(newValue) {
        setMaterialGroup4(newValue);
    }
    function handleMaterialGroup5(newValue) {
        setMaterialGroup5(newValue);
    }
    function handleIdNumber1(newValue) {
        setIdNumber1(newValue);
    }
    function handleIdNumber2(newValue) {
        setIdNumber2(newValue);
    }
    function handleIdNumber3(newValue) {
        setIdNumber3(newValue);
    }
    function handleIdNumber4(newValue) {
        setIdNumber4(newValue);
    }
    function handleIdNumber5(newValue) {
        setIdNumber5(newValue);
    }
    function handleIdNumber6(newValue) {
        setIdNumber6(newValue);
    }
    function handleCost(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setCost(e.target.value);
        }
    }
    function handleMsrp(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setMsrp(e.target.value);
        }
    }
    function handlePricingInventory(e) {
        if (e.target.value < 0) {
            e.target.value = 0
        } else {
            setPricingInventory(e.target.value);
        }
    }
    function handleFlexAttribute1(newValue) {
        setFlexAttribute1(newValue.target.value);
    }
    function handleFlexAttribute2(newValue) {
        setFlexAttribute2(newValue.target.value);
    }
    function handleFlexAttribute3(newValue) {
        setFlexAttribute3(newValue.target.value);
    }
    function handleFlexAttribute4(newValue) {
        setFlexAttribute4(newValue.target.value);
    }
    return (
        <div>
            <div className={classes.root}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="HEADER FIELDS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                {fieldLabel['material_description'] && fieldLabel['material_description']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} mandatory heading={fieldLabel['material_description'] ? fieldLabel['material_description']['current'] : 'Material Description'} twoline='true' onChange={handleMaterialDescription} />
                                    </Grid>
                                )}
                                {fieldLabel['material_type'] && fieldLabel['material_type']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        {/* <FormLabel required={fieldLabel['material_type'] ? fieldLabel['material_type']['mandatory'] : false} style={{ marginTop: '11px' }}>{fieldLabel['material_type'] ? fieldLabel['material_type']['current'] : 'Material Type'}</FormLabel>
                                <AllOtherAttributeMasterComponent
                                    classes={{ root: classes.fontSetting }} attributeName="material_type"
                                    withOutLabel
                                    value={materialType}
                                    prevalue={materialType}
                                    onChange={handleMaterialType} /> */}
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_type'] ? fieldLabel['material_type']['mandatory'] : false} heading={fieldLabel['material_type'] ? fieldLabel['material_type']['current'] : 'Material Type'} twoline='true' onChange={handleMaterialType} />
                                    </Grid>
                                )}
                                {fieldLabel['supplier_name'] && fieldLabel['supplier_name']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['mandatory'] : false} heading={fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'} twoline='true' onChange={handleSupplier} />
                                    </Grid>
                                )}
                                {fieldLabel['supplier_material_number'] && fieldLabel['supplier_material_number']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}

                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['supplier_material_number'] ? fieldLabel['supplier_material_number']['mandatory'] : false} heading={fieldLabel['supplier_material_number'] ? fieldLabel['supplier_material_number']['current'] : 'Supplier Material Number'} twoline='true' onChange={handleSupplierMaterialNumber} />
                                    </Grid>
                                )}
                                {fieldLabel['base_uom'] && fieldLabel['base_uom']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}

                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['base_uom'] ? fieldLabel['base_uom']['mandatory'] : false} heading={fieldLabel['base_uom'] ? fieldLabel['base_uom']['current'] : 'Base UoM'} twoline='true' onChange={handleBaseUom} />
                                    </Grid>
                                )}
                                {fieldLabel['external_material_group'] && fieldLabel['external_material_group']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}

                                    >
                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['external_material_group'] ? fieldLabel['external_material_group']['mandatory'] : false} heading={fieldLabel['external_material_group'] ? fieldLabel['external_material_group']['current'] : 'External Material Group'} twoline='true' onChange={handletExternalMaterialGroup} />
                                    </Grid>
                                )}
                                {/* <Grid
                                item
                                md={2.4}
                                xs={12}
                                 
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} />
                            </Grid>
                            <Grid
                                item
                                md={2.4}
                                xs={12}
                                 
                            >
                                <TextInputWithLabel classes={{ root: classes.fontSetting }} heading={'Flex Attribute 2'} twoline='true' onChange={handleFlexAttribute2} />
                            </Grid> */}
                                {fieldLabel['material_status'] && fieldLabel['material_status']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}

                                        style={{ marginTop: 15 }}
                                    >
                                        <FormLabel required={fieldLabel['material_status'] ? fieldLabel['material_status']['mandatory'] : false} classes={{ root: classes.fontSetting }} style={{ marginBottom: "2px" }}>
                                            {fieldLabel['material_status'] ? fieldLabel['material_status']['current'] : 'Material Status'}
                                        </FormLabel>
                                        <Select
                                            value={active}
                                            onChange={handleActive}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            <MenuItem value={"Active"}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={"Inactive"}>
                                                Inactive
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                )}
                                {fieldLabel['supplier_number'] && fieldLabel['supplier_number']['display'] && (
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={12}
                                        style={{ marginTop: "-1px " }}

                                    >
                                        <FormLabel required={fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['mandatory'] : false} style={{ marginTop: '15px' }}>{fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['current'] : 'Supplier Number'}</FormLabel>
                                        <OutlinedInput
                                            value={supplierNumber}
                                            classes={{ root: classes.inputTwoLine }}
                                            style={{ marginTop: "0px" }}
                                            onChange={handleSupplierNumber} />
                                        {/* <TextInputWithLabel classes={{ root: classes.fontSetting }}  twoline='true' onChange={handleSupplierNumber} /> */}
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div>
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="GROUPINGS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px", padding: '0px 25px 0px 25px' }}>
                                    {fieldLabel['product_hierarchy'] && fieldLabel['product_hierarchy']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['mandatory'] : false} heading={fieldLabel['product_hierarchy'] ? fieldLabel['product_hierarchy']['current'] : 'Product Hierarchy'} twoline='true' onChange={handleProductHierarchy} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group'] && fieldLabel['material_group']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group'] ? fieldLabel['material_group']['mandatory'] : false} heading={fieldLabel['material_group'] ? fieldLabel['material_group']['current'] : 'Material Group'} twoline='true' onChange={handleMaterialGroup} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group1'] && fieldLabel['material_group1']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group1'] ? fieldLabel['material_group1']['mandatory'] : false} heading={fieldLabel['material_group1'] ? fieldLabel['material_group1']['current'] : 'Material Group 1'} twoline='true' onChange={handleMaterialGroup1} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group2'] && fieldLabel['material_group2']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group2'] ? fieldLabel['material_group2']['mandatory'] : false} heading={fieldLabel['material_group2'] ? fieldLabel['material_group2']['current'] : 'Material Group 2'} twoline='true' onChange={handleMaterialGroup2} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group3'] && fieldLabel['material_group3']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group3'] ? fieldLabel['material_group3']['mandatory'] : false} heading={fieldLabel['material_group3'] ? fieldLabel['material_group3']['current'] : 'Material Group 3'} twoline='true' onChange={handleMaterialGroup3} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group4'] && fieldLabel['material_group4']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group4'] ? fieldLabel['material_group4']['mandatory'] : false} heading={fieldLabel['material_group4'] ? fieldLabel['material_group4']['current'] : 'Material Group 4'} twoline='true' onChange={handleMaterialGroup4} />
                                        </Grid>
                                    )}
                                    {fieldLabel['material_group5'] && fieldLabel['material_group5']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['material_group5'] ? fieldLabel['material_group5']['mandatory'] : false} heading={fieldLabel['material_group5'] ? fieldLabel['material_group5']['current'] : 'Material Group 5'} twoline='true' onChange={handleMaterialGroup5} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            </div >
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IDENTIFIERS"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px", padding: '0px 25px 0px 25px' }}>
                                    {fieldLabel['id_number01'] && fieldLabel['id_number01']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number01'] ? fieldLabel['id_number01']['mandatory'] : false} heading={fieldLabel['id_number01'] ? fieldLabel['id_number01']['current'] : 'ID Number 1'} twoline='true' onChange={handleIdNumber1} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number02'] && fieldLabel['id_number02']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number02'] ? fieldLabel['id_number02']['mandatory'] : false} heading={fieldLabel['id_number02'] ? fieldLabel['id_number02']['current'] : 'ID Number 2'} twoline='true' onChange={handleIdNumber2} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number03'] && fieldLabel['id_number03']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number03'] ? fieldLabel['id_number03']['mandatory'] : false} heading={fieldLabel['id_number03'] ? fieldLabel['id_number03']['current'] : 'ID Number 3'} twoline='true' onChange={handleIdNumber3} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number04'] && fieldLabel['id_number04']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number04'] ? fieldLabel['id_number04']['mandatory'] : false} heading={fieldLabel['id_number04'] ? fieldLabel['id_number04']['current'] : 'ID Number 4'} twoline='true' onChange={handleIdNumber4} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number05'] && fieldLabel['id_number05']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number05'] ? fieldLabel['id_number05']['mandatory'] : false} heading={fieldLabel['id_number05'] ? fieldLabel['id_number05']['current'] : 'ID Number 5'} twoline='true' onChange={handleIdNumber5} />
                                        </Grid>
                                    )}
                                    {fieldLabel['id_number06'] && fieldLabel['id_number06']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <TextInputWithLabel classes={{ root: classes.fontSetting }} required={fieldLabel['id_number06'] ? fieldLabel['id_number06']['mandatory'] : false} heading={fieldLabel['id_number06'] ? fieldLabel['id_number06']['current'] : 'ID Number 6'} twoline='true' onChange={handleIdNumber6} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            </div >
            <div>
                <div className={clsx(classes.root, className)}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <CardHeader
                                    title="IMA360 DATA"
                                    titleTypographyProps={{ variant: 'h3' }}

                                />
                                <Grid container spacing={2} style={{ marginTop: "10px", marginBottom: "15px", padding: '0px 25px 0px 25px' }}>
                                    {fieldLabel['cost'] && fieldLabel['cost']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <FormLabel required={fieldLabel['cost'] ? fieldLabel['cost']['mandatory'] : false} >
                                                {fieldLabel['cost'] ? fieldLabel['cost']['current'] : 'Cost'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={cost}
                                                inputProps={{ type: "number", step: "1", min: "0" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleCost} />
                                        </Grid>
                                    )}
                                    {fieldLabel['msrp'] && fieldLabel['msrp']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <FormLabel required={fieldLabel['msrp'] ? fieldLabel['msrp']['mandatory'] : false} classes={{ root: classes.fontSetting }}>
                                                {fieldLabel['msrp'] ? fieldLabel['msrp']['current'] : 'MSRP'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={msrp}
                                                inputProps={{ type: "number", step: "1", min: "0" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleMsrp} />
                                        </Grid>
                                    )}
                                    {fieldLabel['pricing_inventory_alert_qty'] && fieldLabel['pricing_inventory_alert_qty']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}

                                        >
                                            <FormLabel required={fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['mandatory'] : false} classes={{ root: classes.fontSetting }}>
                                                {fieldLabel['pricing_inventory_alert_qty'] ? fieldLabel['pricing_inventory_alert_qty']['current'] : 'Pricing Inventory Alert Qty'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={pricingInventory}
                                                inputProps={{ type: "number", step: "1", min: "0" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handlePricingInventory} />
                                        </Grid>
                                    )}
                                    {fieldLabel['flex_attribute1'] && fieldLabel['flex_attribute1']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            style={{ marginTop: 0 }}

                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false}>
                                                {fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={flexAttribute1}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleFlexAttribute1} />
                                            {/* <TextInputWithLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['mandatory'] : false} heading={fieldLabel['flex_attribute1'] ? fieldLabel['flex_attribute1']['current'] : 'Flex Attribute 1'} twoline='true' onChange={handleFlexAttribute1} /> */}
                                        </Grid>
                                    )}
                                    {fieldLabel['flex_attribute2'] && fieldLabel['flex_attribute2']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                        // style={{ marginTop: 25 }}

                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['mandatory'] : false}>
                                                {fieldLabel['flex_attribute2'] ? fieldLabel['flex_attribute2']['current'] : 'Flex Attribute 2'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={flexAttribute2}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleFlexAttribute2} />
                                        </Grid>
                                    )}
                                    {fieldLabel['flex_attribute3'] && fieldLabel['flex_attribute3']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            style={{ marginTop: 25 }}

                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['mandatory'] : false}>
                                                {fieldLabel['flex_attribute3'] ? fieldLabel['flex_attribute3']['current'] : 'Flex Attribute 3'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={flexAttribute3}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleFlexAttribute3} />
                                        </Grid>
                                    )}
                                    {fieldLabel['flex_attribute4'] && fieldLabel['flex_attribute4']['display'] && (
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={12}
                                            style={{ marginTop: 25 }}

                                        >
                                            <FormLabel classes={{ root: classes.formLabel }} required={fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['mandatory'] : false}>
                                                {fieldLabel['flex_attribute4'] ? fieldLabel['flex_attribute4']['current'] : 'Flex Attribute 4'}
                                            </FormLabel>
                                            <OutlinedInput
                                                value={flexAttribute4}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleFlexAttribute4} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        </form>
                    </Card>
                </div >
            </div >
        </div>

    );
};
const mapStateToProps = state => ({
    dropdownData: state.customerData.dropdownDataMaterial,
    tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
});

const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefault: () => dispatch(getDefaultValuesMaterial()),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GeneralData);