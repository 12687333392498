import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    useMediaQuery,
    TableRow,
    Button,
    TablePagination, Tooltip, Typography, Fab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getQuotaData, createQuota, getLblDispDesFieldValue } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MaterialTable from 'material-table';
import { CloudUpload, LibraryAdd } from '@mui/icons-material';
import { NavLink as RouterLink } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import Moment from 'moment';
import SimpleDialog from '../../components/Dialog';
import secureLocalStorage from 'react-secure-storage';
import { tableIcons } from '../../components/Icons/TableIcons';
import RingLoader from "react-spinners/RingLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        },
        color: 'black'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    }
}));
const QuotManagementMain = props => {
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataRows, setDataRows] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("");
    const [count, setCount] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const action = {

        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_quota-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => viewQuota(rowData.ID)}
                        size="large">
                        <VisibilityIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_quota-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editQuota(rowData.ID)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_quota-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleClickOpen(rowData.ID)}
                        size="large">
                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                }
            </div >
    }

    // useEffect(() => {
    //     props.getQuotaData(1, 10);
    // }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getQuotaData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setRowsPerPage(event.target.value);
        props.getQuotaData(1, event.target.value);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteQuotaData(null, currentId);
        } else {
            setOpen(false);

        }
    }
    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };
    function editQuota(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/quota-management/edit-quota/' + id,
            id: id
        });
    }
    function viewQuota(id) {
        props.getLblDispDesFieldValue(id)
        history.push({
            pathname: '/quota-management/view-quota/' + id,
            id: id
        })
    }
    useEffect(() => {
        var data = { "data_source": "quota_data" };
        props.getQuotaData(1, 30);
        props.loadSourceDataType(data, 'post');
    }, []);
    useEffect(() => {
        var tempRows = [];
        if (props.quotaData && props.quotaData.records && props.quotaData.total_record > 0) {
            setCount(props.quotaData.total_record)
            setDataRows(props.quotaData.records);
        }
    }, [props.quotaData]);
    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list && item.display)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    newArray.push({
                        field: item.key,
                        title: item.current,
                        type: item.key != 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                        render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
                            Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
                    })

                })
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])
    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));
    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<RingLoader size={100} />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h1" color='primary' style={{ marginTop: 5 }} > Quota Management</Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_quota-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/quota-management/add-quota'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/quota-management/quota-management-excel-upload'
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>

                        </div>}
                </div>
                {props.quotaData && props.quotaData.records && props.quotaData.records.length > 0 ?
                    <MaterialTable
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            search: false,
                            filtering: true,
                            // pageSize: rowsPerPage,
                            // count: count,
                            // page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            // pageSize: 10,
                            // pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>

        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.quotaManagementData.loading,
        quotaData: state.quotaManagementData.quotaData,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getQuotaData: (pagination, limit) => dispatch(getQuotaData(pagination, limit)),
        deleteQuotaData: (data, id) => dispatch(createQuota(data, 'delete', id)),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getLblDispDesFieldValue: (id) => dispatch(getLblDispDesFieldValue(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotManagementMain);