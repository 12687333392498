import React, { useEffect, forwardRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    OutlinedInput,
    FormLabel,
    IconButton
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Check, DeleteForever, Edit } from '@mui/icons-material';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { getDefaultValues, getMaterialUoMData } from '../../../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    centerButton: {
        display: 'flex',
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
}));

const UoMData = forwardRef((props) => {
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    const [materialNumber, setMaterialNumber] = React.useState(localStorage.getItem('MaterialNumber'));
    const [sourceUom, setSourceUom] = React.useState('');
    const [targetUom, setTargetUom] = React.useState('');
    const [conversionFactor, setConversionFactor] = React.useState('');
    const [editID, setEditID] = React.useState(0);
    const [organizationStore, setOrganizationStore] = React.useState([]);
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    useEffect(() => {
        props.getUomdata()
        props.onLoadingDefault();
    }, []);
    useEffect(() => {
        if (props.uomData && props.uomData.total_record > 0) {
            setOrganizationStore(props.uomData.records.filter(item => item.material_number === materialNumber))
        }
    }, [props.uomData]);
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditID(item.ID)
        setMaterialNumber(item.material_number)
        setSourceUom(item.source_uom)
        setTargetUom(item.target_uom)
        setConversionFactor(item.conversion_factor)
    }
    function handleSubmit() {
        var data =
        {
            "material_number": materialNumber,
            "source_uom": sourceUom,
            "target_uom": targetUom,
            "conversion_factor": Number(conversionFactor)
        }
        if (editIndex || editIndex === 0) {
            var editedArray = [...organizationStore];
            editedArray[editIndex] = data;
            setOrganizationStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setOrganizationStore([...organizationStore, data]);
            props.onChange([...organizationStore, data])
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove(deleteId) {
        setSourceUom('')
        setTargetUom('')
        setConversionFactor('')
    }
    const handleDeleteLocally = (index) => {
        setOrganizationStore(item => item.filter((item, i) => i !== index));
        props.onChange(organizationStore.filter((item, i) => i !== index))
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Source UoM
                                        </FormLabel>
                                        <OutlinedInput
                                            value={sourceUom}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setSourceUom(e.target.value)}
                                            placeholder={props.placeholder} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formLabel}>
                                            Target UoM
                                        </FormLabel>
                                        <OutlinedInput
                                            value={targetUom}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setTargetUom(e.target.value)}
                                            placeholder={props.placeholder} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel variant="h5" className={classes.formLabel}>
                                            Conversion Factor
                                        </FormLabel>
                                        <OutlinedInput
                                            value={conversionFactor}
                                            type={"number"}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={e => setConversionFactor(e.target.value)}
                                            placeholder={props.placeholder} />
                                    </div>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 36, marginTop: '0.5rem' }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Edit />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={sourceUom && targetUom && conversionFactor ? false : true}
                                                    onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<Check />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={sourceUom && targetUom && conversionFactor ? false : true} onClick={() => handleSubmit()}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForever />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="UoM DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center' className={classes.tabHead}>Source UoM</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Target UoM</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Conversion Factor</TableCell>
                                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore
                                                .map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.source_uom}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.target_uom}</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>{item.conversion_factor}</TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton
                                                                    classes={{ root: classes.IconButton }}
                                                                    onClick={() => handleEditRow(item, index)}
                                                                    size="large">
                                                                    <Edit color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => handleDeleteLocally(index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <DeleteForever />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>

        </div>
    );
});
const mapStateToProps = state => {
    return {
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        uomData: state.customerData.materialUomData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getUomdata: () => dispatch(getMaterialUoMData()),
        onLoadingDefault: () => dispatch(getDefaultValues())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UoMData);