import React, { useEffect } from 'react';
import {
    Typography, Breadcrumbs, Divider, Link, Fab,
    TableHead, TableRow, TableContainer, TableBody, IconButton,
    OutlinedInput, Grid, Button, Select, MenuItem, FormLabel, ToggleButton, ToggleButtonGroup, Checkbox
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ErrorOutlineOutlined, NavigateNext, SaveOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createStickers, getBudgetingConfig } from '../../../redux/actions';
import { StickerContainer, StickerHeader } from './Components'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 10
    },
    container: {
        // margin: 20
        backgroundColor: '#f5f5f5'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    link: {
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10,
        cursor: 'pointer'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    checkBox: {
        display: 'flex',
        marginLeft: 10,
        marginBottom: 25,
        padding: 5
    },
}));

const AddStickers = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const passedCountry = location.data && location.data.variable;
    const { className } = props;
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [stickerData, setStickerData] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [productLines, setProductLines] = React.useState([]);
    const [stickerName, setStickerName] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [headerData, setHeaderData] = React.useState({});
    const [isCopy, setIsCopy] = React.useState(false);

    useEffect(() => {
        props.getConfigData();
    }, []);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
        }
    }, [props.configData]);
    function handleHeaderData(value) {
        setHeaderData(value)
        if (value.country != country)
            setCountry(value.country)
        if (value.product_lines)
            setProductLines(value.product_lines)
    }
    function handleStickerData(value) {
        setStickerData(value)
    }
    function handleStickerDesc(desc) {
        setStickerName(desc)
    }
    function handleSave() {
        // "product_lines": productLines,
        //   "sticker_description": "hey",
        var formData = {
            ...headerData,
            "sticker_type": stickerData
        }
        //      "country": "US"
        props.createSticker(formData)
    }

    return (
        <div className={classes.root}>
            <div>
                <StickerHeader onSave={handleSave} configData={productLinesList} country={passedCountry} defaultDivison={productType} onChange={handleHeaderData} stickerName={stickerName} />
            </div>
            <div className={classes.container} >
                <div className={classes.checkBox}>
                    <Checkbox
                        checked={isCopy}
                        onChange={(e) => setIsCopy(e.target.checked)}
                        color='primary'
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Typography style={{ marginTop: 8 }} variant='h4' color='black'>Copy Options(OR)</Typography>
                </div>
                <StickerContainer onChange={handleStickerData} getSticker={handleStickerDesc} />
            </div>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        createSticker: (data) => dispatch(createStickers(data, 'create'))
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStickers);