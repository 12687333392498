import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@mui/styles";
import {
    Card,
    CardHeader,
    Grid,
    MenuItem,
    Select,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { TextInputWithLabel, DropdownKeyValue } from '../../../../components/Inputs';
import { getDefaultValuesSupplier } from '../../../../redux/actions';
import { AllOtherAttributeMasterComponent } from '../../../../components/Inputs';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 37
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: "37px"
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
    },
}));
const GeneralData = props => {
    const classes = useStyles();
    const [industryTypeArray, setIndustryTypeArray] = React.useState([]);
    const supplierTypeData = [
        { key: 'Direct', value: 'Direct' },
        { key: 'Indirect', value: 'Indirect' },
        { key: 'GPO', value: 'GPO' },
    ];
    //variable declarations
    const [supplierMasterData, setSupplierMasterData] = React.useState({});
    const [supplierName, setSupplierName] = React.useState('');
    const [supplierNumber, setSupplierNumber] = React.useState('');
    const [supplierType, setSupplierType] = React.useState('');
    const [street, setStreet] = React.useState('test');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [industrialType, setIndustrialType] = React.useState('');
    const [customerNumber, setCustomerNumber] = React.useState('');
    const [deletionFlag, setDeletionFlag] = React.useState(false);
    const [active, setActive] = React.useState('');
    const [fieldLabel, setFieldLabel] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData) {
            setSupplierMasterData(props.supplierMasterData)
            setDeletionFlag(props.supplierMasterData.deletion_flag);
            setCountry(props.supplierMasterData.country);
            setState(props.supplierMasterData.region);
            setCity(props.supplierMasterData.city);
            setStreet(props.supplierMasterData.street);
            setIndustrialType(props.supplierMasterData.supplier_industry);
            setSupplierName(props.supplierMasterData.supplier_name);
            setSupplierNumber(props.supplierMasterData.supplier_number);
            setSupplierType(props.supplierMasterData.supplier_type);
            setPostalCode(props.supplierMasterData.postal_code);
            setCustomerNumber(props.supplierMasterData.supplier_customer_number);
            setActive(props.supplierMasterData.supplier_status);
        }
    }, [props.supplierMasterData]);
    var formData = {
        supplier_name: supplierName,
        supplier_number: supplierNumber,
        supplier_type: supplierType,
        street: street,
        city: city,
        region: state,
        postal_code: postalCode,
        country: country,
        supplier_industry: industrialType,
        supplier_customer_number: customerNumber,
        // supplier_number: props.supplierMasterData && props.supplierMasterData.supplier_number ? props.supplierMasterData.supplier_number : '',
        supplier_status: active,
    };
    useEffect(() => {
        props.onChange(formData);
    }, [formData]);
    useEffect(() => {
        props.onLoadingDefaultMaterialXRef();
    }, []);
    useEffect(() => {
        if (props.dropdownDataCustomer && props.dropdownDataCustomer.records && props.dropdownDataCustomer.records.length > 0) {
            props.dropdownDataCustomer.records.map((item) => {
                if (item.field_id === 'supplier_industry') {
                    setIndustryTypeArray(item.drop_down_value_keys);
                }
            })
        }
    }, [props.dropdownDataCustomer]);

    useEffect(() => {
        setFieldLabel(props.data);
    }, [props.data]);
    //functions 
    function handleSupplierName(newValue) {
        setSupplierName(newValue);
    }
    function handleSupplierNumber(newValue) {
        setSupplierNumber(newValue);
    }
    function handleSupplierType(newValue) {
        if (newValue && newValue.length > 0) {
            setSupplierType(newValue);
        }
        else
            setSupplierType('')
        props.onChange(formData);
    }
    function handleStreet(event) {
        setStreet(event);
    }
    function handleCity(newValue) {
        setCity(newValue.target.value);
    }
    function handleState(newValue) {
        setState(newValue.target.value);
    }
    function handlePostalCode(newValue) {
        setPostalCode(newValue);
    }
    function handleCountry(e) {
        setCountry(e.target.value);
    }
    function handleIndustrialType(newValue) {
        if (newValue && newValue.length > 0) {

            setIndustrialType(newValue);
        }
        else
            setIndustrialType('')
        props.onChange(formData);
    }


    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue);
    }
    function handleActive(event) {
        setActive(event.target.value);
    }
    return (
        <div className={classes.root}>
            {supplierMasterData &&
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="HEADER FIELDS"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required >

                                        {fieldLabel['supplier_name'] ? fieldLabel['supplier_name']['current'] : 'Supplier Name'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        withOutLabel
                                        twoline='true'
                                        attributeName="supplier_name"
                                        value={supplierName}
                                        onChange={handleSupplierName}
                                        prevalue={supplierMasterData.supplier_name} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required >
                                        {fieldLabel['supplier_number'] ? fieldLabel['supplier_number']['current'] : 'Supplier Number'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        withOutLabel
                                        twoline='true'
                                        attributeName="supplier_number"
                                        value={supplierNumber}
                                        onChange={handleSupplierNumber}
                                        prevalue={supplierMasterData.supplier_number} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <FormLabel
                                        required={fieldLabel['supplier_type'] ? fieldLabel['supplier_type']['mandatory'] : false}
                                        style={{ marginTop: '-17px' }}>
                                        {fieldLabel['supplier_type'] ? fieldLabel['supplier_type']['current'] : 'supplier_type'}</FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        style={{ marginTop: "1px" }}
                                        classes={{ root: classes.formLabel }}
                                        withOutLabel
                                        attributeName="supplier_type"
                                        value={supplierType}
                                        onChange={handleSupplierType}
                                        prevalue={supplierMasterData.supplier_type} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: '15px !important', marginTop: "-3px" }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required >
                                        {fieldLabel['street'] ? fieldLabel['street']['current'] : 'Street Address'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        style={{ marginTop: "-2px" }}
                                        twoline='true'
                                        withOutLabel
                                        attributeName="street"
                                        value={street}
                                        onChange={handleStreet}
                                        prevalue={supplierMasterData.street} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-8px' }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        {fieldLabel['city'] ? fieldLabel['city']['current'] : 'City'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={city}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCity} />
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 7 }}
                                >
                                    <FormLabel
                                        classes={{ root: classes.formLabel }}
                                        required >
                                        {fieldLabel['postal_code'] ? fieldLabel['postal_code']['current'] : 'Postal Code'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        twoline='true'
                                        withOutLabel
                                        attributeName="postal_code"
                                        value={postalCode}
                                        onChange={handlePostalCode}
                                        prevalue={supplierMasterData.postal_code} />


                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-8px' }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        {fieldLabel['country'] ? fieldLabel['country']['current'] : 'Country'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={country}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleCountry} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-8px' }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }} required>
                                        {fieldLabel['region'] ? fieldLabel['region']['current'] : 'State'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={state}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={handleState} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{ marginTop: '-17px' }}
                                >
                                    <FormLabel
                                        required={fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['mandatory'] : false}
                                        style={{ marginTop: '12px' }}>
                                        {fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['current'] : 'supplier_industry'}</FormLabel>
                                    <AllOtherAttributeMasterComponent
                                        classes={{ root: classes.formLabel }}
                                        withOutLabel
                                        attributeName="supplier_industry"
                                        value={industrialType}
                                        onChange={handleIndustrialType}
                                        prevalue={industrialType} />


                                </Grid>
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <AllOtherAttributeMasterComponent 
                                    required={fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['mandatory'] : false} 
                                    classes={{ root: classes.formLabel }} 
                                    attributeName={fieldLabel['supplier_industry'] ? fieldLabel['supplier_industry']['current'] : 'supplier_industry'} 
                                    value={industrialType} 
                                    onChange={handleIndustrialType} 
                                    prevalue={industrialType} />
                                </Grid> */}
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ paddingTop: 0, marginTop: '10px' }}
                                >
                                    <FormLabel classes={{ root: classes.formLabel }}
                                        required={fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['mandatory'] : false}>
                                        {fieldLabel['supplier_customer_number'] ? fieldLabel['supplier_customer_number']['current'] : 'Customer Number'}
                                    </FormLabel>
                                    <AllOtherAttributeMasterComponent

                                        withOutLabel
                                        attributeName="supplier_customer_number"
                                        value={customerNumber}
                                        twoline='true' onChange={handleCustomerNumber}
                                        prevalue={supplierMasterData.supplier_customer_number} />

                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: '-6px' }}
                                >
                                    <FormLabel
                                        required={fieldLabel['supplier_status'] ? fieldLabel['supplier_status']['mandatory'] : false}
                                        classes={{ root: classes.formLabel }}>
                                        {fieldLabel['supplier_status'] ? fieldLabel['supplier_status']['current'] : 'Supplier Status'}
                                    </FormLabel>
                                    <Select
                                        value={active}
                                        onChange={handleActive}
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                    >
                                        <MenuItem value={"Active"}>
                                            Active
                                        </MenuItem>
                                        <MenuItem value={"Inactive"}>
                                            Inactive
                                        </MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        supplierMasterData: state.customerData.supplierMasterViewData,
        dropdownDataCustomer: state.customerData.dropdownDataSupplier
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoadingDefaultMaterialXRef: () => dispatch(getDefaultValuesSupplier()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null)(GeneralData);