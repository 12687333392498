import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography, Radio, RadioGroup,
    FormControlLabel, FormLabel, Select, MenuItem,
    OutlinedInput, TextField, Checkbox,
    ListItemText, Input, useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import SimpleDialog from '../../../components/Dialog';
import { getContractsTableDataList, getCalculationSimulationNumbers, getCustomerMasterData, postCollaborationPortal, runContractTrends, loadValuesBasedOnAppTypeAndField, allowedApps, getCollaboratedData } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../../components/Inputs";
import { connect } from 'react-redux';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import momentt from 'moment-timezone';
import RotateLoader from "react-spinners/RotateLoader";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader } from 'react-spinners';
import secureLocalStorage from 'react-secure-storage';
import { MasterDataComponent } from '../../../components/Inputs';
momentt.tz.setDefault('Etc/UTC');
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px',
        marginTop: '1rem'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        fontSize: theme.typography.h3.fontSize,
        marginTop: '1.7rem'
    },
    spinner: {
        height: '100vh'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll",
    },
    dropdownAction: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: "1px solid #ddd",
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
    },
    btn: {
        marginRight: 25,
        padding: "10px,15px",
    },
    menuItem: {
        height: '500px'
    },

    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    tag: {
        "& .MuiOutlinedInput-root": {
            padding: 0
        },
        "&.MuiAutocomplete-tag": {
            margin: 0,
            height: 30,
        },
        "&.MuiChip-root": {
            height: '21px !important'
        }
    },
}));

const ContractTrendsCP = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [calculationSimulationNumber, setCalculationSimulationNumber] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [displayLevel, setDisplayLevel] = React.useState([]);
    const [open2, setOpen2] = React.useState("");
    const [selected2, setSelected2] = React.useState([]);
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState('');
    const [radioGroupValue, setRadioGroupValue] = React.useState('calculation_simulation');
    const [open, setOpen] = React.useState(false);
    const [postingNumber, setPostingNumber] = React.useState('');
    const [calculationSimulationNumbers, setCalculationSimulationNumbers] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [tags, setTags] = useState([]);
    const [customerNumber, setCustomerNumber] = React.useState([]);
    const [customerNumberValue, setCustomerNumberValue] = React.useState([]);
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const filterOptions2 = createFilterOptions({
        stringify: (option) => customerNameListArray[customerNumberList.indexOf(option)] + option
    });
    const AggregationLevelArray = [
        "bill_to_party",
        "customer_number",
        "customer_group",
        "customer_chain",
        "flex_attribute1",
        "material_number",
        "material_group",
        "payer",
        "product_hierarchy",
        "profit_center",
        "plant",
        "sold_to_party",
        "supplier_number",
        "parent_customer_number",
        "top_parent_customer_number",
        "employee_number",
        "territory_id",
    ];
    const isAllSelected2 =
        AggregationLevelArray &&
        AggregationLevelArray.length > 0 && selected2.length === AggregationLevelArray.length;
    useEffect(() => {
        props.onCheckContractsTable();
        localStorage.removeItem('contractTrendsQuery')
    }, []);
    useEffect(() => {
        props.allowedApps();
        //props.onCheckCustomerTable();
        if (secureLocalStorage.getItem("dYtz").collaboration_id != '0') {
            props.postCollaborationPortal(null, secureLocalStorage.getItem("dYtz").collaboration_id, 'get')
        }
    }, [])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    useEffect(() => {
        props.loadSourceDataType('Customer Rebate', 'source_data_type');
        props.getCollaboration('customer_number')
    }, []);
    useEffect(() => {
        console.log(props.collaboratedData)
        if (props.collaboratedData)
            setCustomerNumberList(props.collaboratedData)
    }, [props.collaboratedData]);
    useEffect(() => {
        if (contractNumber && radioGroupValue && calculationPeriodStart && calculationPeriodEnd)
            props.getCalculationSimulationNumbers(contractNumber, Moment(calculationPeriodStart).format('MM/DD/YYYY'), Moment(calculationPeriodEnd).format('MM/DD/YYYY'), radioGroupValue)
        else
            setCalculationSimulationNumbers([])
    }, [contractNumber, radioGroupValue, calculationPeriodStart, calculationPeriodEnd])
    // useEffect(() => {
    //     var tempArray = [];
    //     var tempNameArray = [];
    //     var temp1=[];
    //     if( props.collaborationPortalData && props.collaborationPortalData.customer_number) {
    //         temp1 = props.collaborationPortalData.customer_number
    //     } 
    //     if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {    
    //         props.customerMasterData.records
    //         .filter(item=> temp1.length > 0 ? temp1.includes(item.customer_number) : item)
    //         .map((item) => {
    //             tempArray.push(item.customer_number);
    //             tempNameArray.push(item.customer_name);
    //         })
    //         setCustomerNameListArray(tempNameArray);
    //         setCustomerNumberList(tempArray)
    //     }
    // }, [props.customerMasterData,props.collaborationPortalData])
    // console.log(props.collaborationPortalData)
    // console.log(props.customerMasterData)
    // console.log(customerNumberList)
    // useEffect(() => {
    //     var tempArray = [];
    //     var tempNameArray = [];
    //     if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
    //         props.customerMasterData.records
    //         .map((item) => {
    //             tempArray.push(item.customer_number);
    //             tempNameArray.push(item.customer_name);
    //         })

    //         setCustomerNameListArray(tempNameArray);
    //         // else {
    //             setCustomerNumberList(tempArray)
    //         // }
    //     }
    // }, [props.customerMasterData])
    function handleTagsAdd(newValue) {
        setTags(newValue);
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    var formData = {
        "customer_number": customerNumberValue.toString(),
        "start_date": Moment(calculationPeriodStart).format('MM/DD/YYYY'),
        "end_date": Moment(calculationPeriodEnd).format('MM/DD/YYYY'),
        "contract_number": contractNumber,
        "posting_number": postingNumber,//radioGroupValue === 'calculation_simulation' ? calculationSimulationNumber : postingNumber,
        "posting_type": radioGroupValue === 'calculation_simulation' ? '' : radioGroupValue,
        "display_level": displayLevel.toString()
    };
    function handleOpen2() {
        setSelected2(displayLevel);
        setOpen2(true);
    }
    const handleSelectValues2 = (event, value) => {
        let newSelected = [];
        const selectedIndex = selected2.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected2, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected2.slice(1));
        } else if (selectedIndex === selected2.length - 1) {
            newSelected = newSelected.concat(selected2.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected2.slice(0, selectedIndex),
                selected2.slice(selectedIndex + 1)
            );
        }
        if (value === "all") {
            if (sourceDataType == "Direct - Sales Data")
                setSelected2(
                    selected2.length ===
                        AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
            else
                setSelected2(
                    selected2.length === AggregationLevelArray.length
                        ? []
                        : AggregationLevelArray
                );
        } else setSelected2(newSelected);
    };
    function onAdd2() {
        setDisplayLevel(selected2);
        setOpen2(false);
        setSelected2([]);
    }
    function onCancel2() {
        setOpen2(false);
        setSelected2([]);
    }
    const handleClickOpen = () => {
        localStorage.setItem('contractTrendsQuery', JSON.stringify(formData));
        props.runContractTrends(formData);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        setCalculationPeriodStart(newValue);
        // if (calculationPeriodEnd && contractNumber && newValue && newValue.isValid()) {
        //     props.getCalculationSimulationNumbers(contractNumber, Moment(newValue).format('MM/DD/YYYY'), Moment(calculationPeriodEnd).format('MM/DD/YYYY'))
        // }
    }
    function handleCalculationPeriodEnd(newValue) {
        setCalculationPeriodEnd(newValue);
        // if (calculationPeriodStart && contractNumber && newValue && newValue.isValid()) {
        //     props.getCalculationSimulationNumbers(contractNumber, Moment(calculationPeriodStart).format('MM/DD/YYYY'), Moment(newValue).format('MM/DD/YYYY'))
        // }
    }
    function handleContractNumber(e) {
        setContractNumber(e);
        // if (e)
        //     props.getCalculationSimulationNumbers(e, Moment(calculationPeriodStart).format('MM/DD/YYYY'), Moment(calculationPeriodEnd).format('MM/DD/YYYY'))
        // else
        //     setCalculationSimulationNumbers([])
    }
    function handleRadioGroup(newValue) {
        setPostingNumber('');
        setRadioGroupValue(newValue.target.value);
    }
    function handleCustomerNumber(newValue) {
        setCustomerNumber(newValue)
        setCustomerNumberValue(newValue.map(e => e.key))
    }
    useEffect(() => {
        if (props.calculationSimulationNumbers)
            setCalculationSimulationNumbers(props.calculationSimulationNumbers)
        else
            setCalculationSimulationNumbers([])
    }, [props.calculationSimulationNumbers])
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    useEffect(() => {
        var contracts = [];
        if (props.contractablesdata && props.contractablesdata.length > 0) {
            props.contractablesdata.map(item => {
                contracts.push(item.contract_number.toString())
            })
            setContractNumbersList(contracts);
        }
        var tempNameArray = []
        props.contractablesdata && props.contractablesdata.length > 0 && props.contractablesdata.map((item) => {
            tempNameArray.push(item.internal_description);
        })
        setInternalDescriptionArray(tempNameArray);

    }, [props.contractablesdata])
    const handleClear = () => {
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setContractNumber('');
        setTags([]);
        setRadioGroupValue('calculation_simulation');
        setPostingNumber('');
        setCalculationSimulationNumbers([]);
        setDisplayLevel([]);
        setCustomerNumber([]);
    }

    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }} >Calculation Analysis</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }}>
                                    Calculation Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodStart}
                                            onChange={handleCalculationPeriodStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }}>
                                    Calculation End Date
                                </FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodEnd}
                                            onChange={handleCalculationPeriodEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required classes={{ root: classes.fontSetting }}>
                                    Contract Number
                                </FormLabel>
                                <Autocomplete
                                    options={contractNumbersList}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" size="small" classes={{ root: classes.tag }} style={{ borderRadius: '0px !important' }} />
                                    )}
                                    filterOptions={filterOptions}
                                    value={contractNumber}
                                    onChange={(event, newValue) => { handleContractNumber(newValue) }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            {internalDescriptionArray[contractNumbersList.indexOf(option)] ? internalDescriptionArray[contractNumbersList.indexOf(option)] : ''} - ({option})
                                        </li>
                                    )}
                                    disabled={(!calculationPeriodEnd || !calculationPeriodStart) ? true : false}
                                    classes={{
                                        option: {
                                            borderBottom: `1px solid red`,
                                            '&[data-focus="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                            '&[aria-selected="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                        },
                                        listbox: {
                                            padding: 0,
                                        },
                                        inputRoot: {
                                            borderRadius: '0px !important'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }} required>
                                    Customer Number
                                </FormLabel>
                                <Autocomplete
                                    multiple
                                    options={customerNumberList}
                                    getOptionLabel={(option) => option.key}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" size="small" classes={{ root: classes.tag }} style={{ borderRadius: '0px !important' }} />
                                    )}
                                    filterOptions={filterOptions2}
                                    value={customerNumber}
                                    onChange={(event, newValue) => handleCustomerNumber(newValue)}
                                    renderOption={(props, option) => (
                                        <li {...props}> {option.desc}
                                        </li>)
                                    }
                                    classes={{
                                        option: {
                                            borderBottom: `1px solid red`,
                                            // Hover
                                            '&[data-focus="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                            // Selected
                                            '&[aria-selected="true"]': {
                                                backgroundColor: 'red',
                                                borderColor: 'transparent',
                                            },
                                        },
                                        listbox: {
                                            padding: 0,
                                        },
                                        inputRoot: {
                                            borderRadius: '0px !important'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '0.2rem' }}
                            >
                                <FormLabel className={classes.contractContiner}
                                    required={radioGroupValue === 'calculation_simulation' ? true : false}
                                >
                                    {radioGroupValue && radioGroupValue === 'calculation_simulation' ? 'Calculation Simulation Number' : 'Posting Number'}
                                </FormLabel>
                                <Select
                                    value={postingNumber}
                                    onChange={(e) => setPostingNumber(e.target.value)}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                >
                                    {calculationSimulationNumbers && Object.entries(calculationSimulationNumbers).sort(([a1, b1], [a2, b2]) => a2 - a1).map(([key, value]) => {
                                        return (
                                            <MenuItem value={key} key={key}>
                                                {value ? key + " - " + value : key}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            {/* {radioGroupValue && radioGroupValue === 'calculation_simulation' &&
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{marginTop:'0.2rem'}}
                        >
                            <FormLabel className={classes.contractContiner} required> Calculation Simulation Number </FormLabel>
                            <Select
                                value={calculationSimulationNumber}
                                onChange={(e) => setCalculationSimulationNumber(e.target.value)}
                                displayEmpty
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                {calculationSimulationNumbers&&Object.entries(calculationSimulationNumbers).sort(([a1,b1],[a2,b2]) => a2 - a1).map(([key,value]) => {
                                            return (
                                                <MenuItem value={key} key={key}>
                                                    {value? key +" - " + value:key}
                                                </MenuItem>
                                            )
                                        })}
                            </Select>
                        </Grid>
                    }
                   
                    {(radioGroupValue === 'payment' || radioGroupValue === 'accrual') &&
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{marginTop:'0.2rem'}}
                        >
                            <FormLabel className={classes.contractContiner} required > Posting Number </FormLabel>
                            <OutlinedInput
                                value={postingNumber}
                                classes={{ root: classes.inputTwoLine }}
                                onChange={(e) => setPostingNumber(e.target.value)} />
                        </Grid>
                    } */}
                            <Grid item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ marginTop: '2.3rem' }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Display Level
                                </FormLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={displayLevel}
                                    style={{ width: "100%", textTransform: "capitalize", height: 38, marginTop: 2 }}
                                    input={<OutlinedInput />}
                                    onOpen={() => handleOpen2()}
                                    onClose={() => setOpen2(false)}
                                    open={open2}
                                    MenuProps={{ autoFocus: true }}
                                    renderValue={(appType) => {
                                        var priceType = [];
                                        appType.map((item) => {
                                            priceType.push(item.replace(/_/g, " "));
                                        });
                                        return priceType.join(", ");
                                    }}
                                >
                                    <MenuItem
                                        value="all"
                                        onClick={(event) => handleSelectValues2(event, "all")}
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        <Checkbox color="primary" checked={isAllSelected2} />
                                        <ListItemText primary="Select All" />
                                    </MenuItem>
                                    {AggregationLevelArray &&
                                        AggregationLevelArray.map((item) => {
                                            return (
                                                <MenuItem
                                                    onClick={(event) =>
                                                        handleSelectValues2(event, item)
                                                    }
                                                    value={item}
                                                    key={item}
                                                    style={{ textTransform: "capitalize" }}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={selected2.indexOf(item) > -1}
                                                    />
                                                    <ListItemText primary={item.replace(/_/g, " ")} />
                                                </MenuItem>
                                            );
                                        })}
                                    <div className={classes.dropdownAction}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.btn}
                                            onClick={onCancel2}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn}
                                            onClick={onAdd2}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </Select>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel classes={{ root: classes.fontSetting }}>
                                    Tags
                                </FormLabel>
                                <ChipInput data={tags} onChange={handleTagsAdd} />
                                {/* <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}
                                    disableUnderline={true}
                                    blurBehavior='add'
                                /> */}
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{ display: 'flex' }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="calculation_simulation"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Calculation Simulation </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="payment"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Payment </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear}>
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    radioGroupValue === 'calculation_simulation' ?
                                        !postingNumber || !contractNumber || !calculationPeriodStart || !calculationPeriodEnd || !customerNumberValue || customerNumberValue.length == 0 ? true : false
                                        :
                                /*!postingNumber ||*/ !contractNumber || !calculationPeriodStart || !calculationPeriodEnd || !customerNumberValue || customerNumberValue.length == 0 ? true : false}
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form >
                <SimpleDialog open={open} content='Do you want to submit financial postings?' handleDialog={handleDialog} />
            </div >
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        runContractTrends: (formData) => dispatch(runContractTrends(formData)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getCalculationSimulationNumbers: (e, sd, ed, postingType) => dispatch(getCalculationSimulationNumbers(e, sd, ed, postingType)),
        onCheckContractsTable: () => dispatch(getContractsTableDataList('AllApps')),
        allowedApps: () => dispatch(allowedApps()),
        onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 0)),
        postCollaborationPortal: (data, editId, type) => dispatch(postCollaborationPortal(data, editId, type)),
        getCollaboration: (key) => dispatch(getCollaboratedData(key))
    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        calculationSimulationNumbers: state.addMultipleConfigurationData.calculationSimulationNumbers,
        contractablesdata: state.addContractData.contractAllListData,
        allowedAppsData: state.initialData.allowedApps,
        customerMasterData: state.customerData.customerMasterData,
        collaborationPortalData: state.addMultipleConfigurationData.collaborationPortalData,
        collaboratedData: state.addMultipleConfigurationData.collaboratedData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTrendsCP);