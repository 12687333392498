import * as React from "react";
import { useEffect } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }`;
const BarChart = (props) => {
  let chart;
  const [graphData, setGraphData] = React.useState([]);
  useEffect(() => {
    if (props.data && props.attribute && props.data[props.attribute]) {
      setGraphData(props.data[props.attribute]);
    }
  }, [props.data, props.attribute]);
  const loaded = (args) => {
    let chart = document.getElementById("top-stat");
    if (chart) {
      chart.setAttribute("title", "");
    }
    // chart.setAttribute("title", "");
  };
  const load = (args) => {
    let selectedTheme = "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast");
    if (selectedTheme === "highcontrast") {
      args.chart.series[0].marker.dataLabel.font.color = "#000000";
      args.chart.series[1].marker.dataLabel.font.color = "#000000";
      args.chart.series[2].marker.dataLabel.font.color = "#000000";
    }
  };
  const margin = { left: 0, right: 0, top: 10, bottom: 0 };
  const palette = [
    "#cc5008",
    "#1ddde0",
    "#55047d",
    "#32cd32",
    "#F6B53F",
    "#FF0060",
  ];
  return (
    <div className="control-pane">
      {props.data && props.data.length > 0 ? (
        <div className="control-section">
          <ChartComponent
            id="top-stat"
            ref={(chart) => (chart = chart)}
            height="100%"
            width="90%"
            palettes={palette}
            style={{ textTransform: "capitalize" }}
            legendSettings={{ enableHighlight: true }}
            margin={margin}
            useGroupingSeparator={true}
            primaryXAxis={{
              // title: props.attribute.replace(/_/g, " "),
              labelIntersectAction: Browser.isDevice ? "None" : "Trim",
              labelRotation: Browser.isDevice ? -45 : 0,
              valueType: "Category",
              interval: 1,
              majorGridLines: { width: 0 },
              majorTickLines: { width: 0 },
            }}
            primaryYAxis={{
              title: props.dimension && props.dimension.yAxisTitle,
              majorTickLines: { width: 0 },
              lineStyle: { width: 0 },
              labelFormat: "n",
            }}
            chartArea={{ border: { width: 0 } }}
            tooltip={{
              enable: true,
              // header: "<b>${point.tooltip}</b>",
              // shared: true,
            }}
            title={" "}
            loaded={loaded.bind(this)}
            load={load.bind(this)}
          >
            <Inject
              services={[
                ColumnSeries,
                Legend,
                Tooltip,
                Category,
                DataLabel,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {props.data &&
                props.data.length > 0 &&
                props.data.map((item, i) => (
                  <SeriesDirective
                    dataSource={item.data}
                    //tooltipMappingName={props.attribute}
                    xName={item.xName}
                    columnSpacing={0.1}
                    yName={item.yName}
                    name={item.legend}
                    type={item.type}
                  />
                ))}
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
            fontSize: 13,
            color: "grey",
          }}
        >
          No Data to Display !
        </div>
      )}
    </div>
  );
};
export default BarChart;
