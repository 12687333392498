import React, { useEffect } from 'react';
import '../../../../library/scss/rsuite-default.css';
import { makeStyles } from "@mui/styles";
import { Grid, Typography, FormLabel, MenuItem, Select, FormControl, InputLabel, Button, TextField } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ChartComponent, SeriesCollectionDirective, AxesDirective, AxisDirective, SeriesDirective, Inject, ColumnSeries, Legend, Category, Tooltip, DataLabel, LineSeries } from '@syncfusion/ej2-react-charts';
import { loadSalesVsPaymentGraph, loadValuesBasedOnAppTypeAndField, allowedApps } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Chart } from "react-google-charts";
import clsx from 'clsx';
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";
import { Browser } from "@syncfusion/ej2-base";
const useStyles = makeStyles(theme => ({
    root: {
        padding: '15px 0px 15px 0px',
        display: 'flex',
        alignItems: 'center'
    },
    rootDiv: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        margin: '5px 0px 16px 0px'
    },
    graphContainer: {
        width: "80%",
        height: "100%",
        marginTop: 10,
        display: "flex",
        alignSelf: "center",
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        color: '#1675e0',
    },
    selectedItem: {
        borderRadius: 5
    },
    fontSetting: {
        fontSize: "13px",
        lineHeight: 1.3
    },
    spinner: {
        // height: '100vh'
    },
    buttonContainer: {
        justifyContent: "center",
        display: "flex",
        paddingTop: 24,
        paddingBottom: 5,
    },
    button: {
        marginRight: 10,
        // width: 80,
    },
}));
const MonthlyGrossToNet = (props) => {
    const classes = useStyles();
    const [avssGraphData, setAvssGraphData] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
    const [aggregationField, setAggregationField] = React.useState('contract_type')

    useEffect(() => {
        if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
            const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
                item.analytic_name === "Monthly Gross to Net" && item.dashboard_name === "Postings Summary Analysis"
            );
            if (postingsSummaryAnalytic) {
                setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
            } else {
                setAllowedTilesFilter([]);
            }
        }
    }, [props.allowedDashBoardData])

    useEffect(() => {
        if (allowedTilesFilter && allowedTilesFilter.length > 0) {
            setSourceDataType(allowedTilesFilter[0].data_source_type);
            setStartDate(allowedTilesFilter[0].start_date);
            setEndDate(allowedTilesFilter[0].end_date);
            setAggregationField(convertToSnakeCase(allowedTilesFilter[0].groupBy));
        }
    }, [allowedTilesFilter]);

    useEffect(() => {
        if (sourceDataType && startDate && endDate && aggregationField) {
            props.loadSalesVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, startDate, endDate, aggregationField);
        }
    }, [allowedTilesFilter]);

    const handleApplicationtype = (e) => {
        setSourceDataType('');
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
        setApplicationType(e.target.value);
    }
    const handleStartDate = (e) => {
        setStartDate(e);
    }
    const handleEndDate = (e) => {
        setEndDate(e);
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }
    const handleAggregationField = (e) => {
        setAggregationField(e.target.value);
    }
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        // props.loadSalesVsPaymentGraph(sessionStorage.getItem("application"), 'Direct - Sales Data');
        props.loadSourceDataType(sessionStorage.getItem("application"), 'source_data_type');
    }, []);

    useEffect(() => {

        const months = [];
        var i;
        const label = ['Month', 'Sales', 'Payment', 'Incentive Basis'];
        const accrualData = props.salesPaymentGraphData.sales_result;
        const paymentData = props.salesPaymentGraphData.payment_result;
        const incentiveData = props.salesPaymentGraphData.payment_result;
        const arr1 = accrualData ? accrualData.map(e => e.Total) : [];
        const arr2 = incentiveData ? incentiveData.map(e => e.IncentiveBasis) : [];
        const dateEnd = Moment();
        const dateStart = Moment().subtract(11, 'month');

        while (dateEnd.diff(dateStart, 'months') >= 0) {
            const monthObject = {
                x: dateStart.format('MMM YYYY'),
                y: 0,
                y1: 0,
                y2: 0
            };
            months.push(monthObject);
            dateStart.add(1, 'month');
        }

        months.unshift(label);

        if (props.salesPaymentGraphData &&
            props.salesPaymentGraphData.sales_result && props.salesPaymentGraphData.sales_result.length > 0
        ) {
            for (i = 1; i <= 12; i++) {
                accrualData.forEach(e => {
                    if (Moment.utc(e.StartDate).format('MMM YYYY') === months[i].x) {
                        months[i].y = e.Total
                    }
                })
                paymentData.forEach(e => {
                    if (Moment.utc(e.StartDate).format('MMM YYYY') === months[i].x) {
                        months[i].y1 = e.Total
                    }
                })
                incentiveData.forEach(e => {
                    if (Moment.utc(e.StartDate).format('MMM YYYY') === months[i].x) {
                        months[i].y2 = e.IncentiveBasis
                    }
                })
            }
        }

        months.shift();
        setAvssGraphData(months);
    }, [props.salesPaymentGraphData]);

    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    const primaryxAxis = { valueType: 'Category' };
    const primaryyAxis = {
        title: 'Sales and Incentive Basis',
        lineStyle: { width: 0 }, labelFormat: '{value}'
    };
    const marker = { visible: true, width: 10, height: 10, border: { width: 2, color: '#F8AB1D' } };
    const lines = { width: 0 };

    function convertToSnakeCase(string) {
        if (string) {
            return string.replace(/\s+/g, '_').toLowerCase();
        }
        return "";
    }
    function handleSubmit() {
        props.loadSalesVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, Moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), Moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), aggregationField);
    }
    return (
        <LoadingOverlay
            active={props.loading}
            spinner={<RingLoader size={100} />}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#045FB4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: 'black'
                })
            }}

            className={classes.spinner}
        >
            <div className={classes.rootDiv}>
                <Grid container className={classes.root} style={{ justifyContent: 'space-between' }}>

                    <Grid container style={{ margin: 10, padding: 5, border: '1px solid #E6E6E6', borderRadius: 5 }}>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            // label={'Start Date'}
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={startDate}
                                            onChange={handleStartDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ paddingRight: 15 }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            // label={'End Date'}
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '1px 0 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    borderRadius: 5,
                                                    height: 37,
                                                    color: '#1675e0'
                                                }
                                            }}
                                            value={endDate}
                                            onChange={handleEndDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDate={startDate ? startDate : false}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography classes={{ root: classes.fontSetting }}>
                                    Source Data Type
                                </Typography>
                                <Select
                                    value={sourceDataType}
                                    onChange={handleSourceDataType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true,
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem,
                                    }}
                                >
                                    {sourceDataTypeDataFromAPI.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={12}
                            style={{ paddingRight: 13 }}
                        >
                            <Typography classes={{ root: classes.fontSetting }} >Aggregation Field</Typography>
                            <Select
                                value={aggregationField}
                                onChange={handleAggregationField}
                                className={clsx({
                                    [classes.select]: true
                                })}
                                classes={{
                                    selectMenu: classes.selectedItem
                                }}
                            >
                                <MenuItem value={'contract_type'}>
                                    Contract Type
                                </MenuItem>
                                <MenuItem value={'contract_group'}>
                                    Contract Group
                                </MenuItem>
                                <MenuItem value={'contract_sub_group'}>
                                    Contract Sub Group
                                </MenuItem>
                                <MenuItem value={'calculation_method'}>
                                    Calculation Method
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid
                            item
                            md={1}
                            xs={12}
                        // style={{ paddingRight: 18 }}
                        >
                            <div className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleSubmit}
                                    twoline="true"
                                    disabled={sourceDataType && startDate && endDate && aggregationField ? false : true}
                                >
                                    <DirectionsRunIcon />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root}>
                        <Grid
                            item
                            md={12}
                            xs={6}
                            className={classes.container}
                        >
                            <div className={`area-chart-wrapper${classes.graphContainer}`}>
                                <ChartComponent id='chart' style={{ height: '90%' }} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={{ enable: true }} title=''>
                                    <Inject services={[ColumnSeries, LineSeries, Legend, Tooltip, DataLabel, Category]} />
                                    <AxesDirective>
                                        <AxisDirective rowIndex={0} name='yAxis1' opposedPosition={true} title='Payment' majorGridLines={lines} lineStyle={lines}>
                                        </AxisDirective>
                                    </AxesDirective>
                                    <SeriesCollectionDirective>
                                        <SeriesDirective dataSource={avssGraphData} xName='x' yName='y' name='Sales' type='Column'>
                                        </SeriesDirective>
                                        <SeriesDirective dataSource={avssGraphData} xName='x' yName='y2' name='Incentive Basis' type='Column' fill='#4caf50'>
                                        </SeriesDirective>
                                        <SeriesDirective dataSource={avssGraphData} xName='x' yName='y1' name='Payment' type='Line' fill='#000000' marker={marker} yAxisName='yAxis1'>
                                        </SeriesDirective>
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
        salesPaymentGraphData: state.initialData.salesPaymentGraphData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        allowedAppsData: state.initialData.allowedApps,
        allowedDashBoardData: state.initialData.dashboardashboardname,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSalesVsPaymentGraph: (applicationType, sourceDataType, startDate, endDate, groupBy) => dispatch(loadSalesVsPaymentGraph(applicationType, sourceDataType, startDate, endDate, groupBy)),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyGrossToNet);