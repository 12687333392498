import React, { useEffect, forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createTheme } from "@mui/material/styles";
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Button, IconButton, Tooltip,
    Dialog, DialogContent, DialogContentText, DialogActions, Fab
} from '@mui/material';
import { Edit, DeleteOutline, PictureAsPdf, AccountBalance, LowPriority, CallSplit, CloudDownload, Send, SendOutlined, LibraryAdd } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { connect } from 'react-redux';
import {
    getComprehensiveQuote, getComprehensiveQuoteById, downloadAllContracts,
    cloneContract, ediUpload, createComprehensiveQuote
} from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import PacmanLoader from 'react-spinners/PacmanLoader'
import { tableIcons } from '../../../components/Icons/TableIcons';
import MaterialTable from 'material-table';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Moment from 'moment';
import HashLoader from 'react-spinners/HashLoader'
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import { getEnv } from '../../../env';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    IconButton: {
        padding: 0,
        fontSize: 10
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    header: {
        backgroudColor: 'red'
    },
    buttonCreate: {
        textTransform: 'none', backgroundColor: theme.palette.primary.main, color: 'white'
    },
    spinner: {
        height: '100vh'
    },
    actionStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    }
}));

const ContractSetupTable = props => {
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const inputFile = useRef(null)
    const history = useHistory();
    var baseUrl = getEnv()
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
    const [quoteData, setQuoteData] = React.useState([]);
    const [comparingLoad, setComparingLoad] = React.useState(false);
    const [currentDeleteContractNumber, setCurrentDeleteContractNumber] = React.useState('');

    // const theme = createTheme({
    //     overrides: {
    //         MuiFormControl: {
    //             root: {
    //             },
    //         },
    //         MuiToolbar: {
    //             root: {
    //                 minHeight: 0
    //             },
    //         },
    //     }
    // });
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {

        props.getAllQuoteData(1, 0);

    }, []);
    const clone = (id) => {
        props.cloneContract(id);
    }
    const deleteCon = (id) => {
        setCurrentDeleteContractNumber(id);
        setOpenConfirmDelete(true);
    }

    const handleSheetDownload = () => {
        props.downloadAllContracts();
    }
    function editQuote(id) {
        props.getQuoteDataById(id);
        navigateToEdit(id);

    }
    function navigateToEdit(id) {
        history.push({
            pathname: '/price-simulation-detailed-quote/edit-quote/' + id,
            state: 'editQuote',
            id: id
        });
        localStorage.setItem('mode', 'edit');
        localStorage.setItem('currentAddContractID', id);
    }

    useEffect(() => {
        if (props.quoteData && props.quoteData.records && props.quoteData.records.length > 0) {
            setQuoteData(props.quoteData.records)
        }
    }, [props.quoteData])
    const addQuote = () => {
        history.push('/price-simulation-detailed-quote/create-quote');
    }

    function handleItemClick(id) {
        props.getQuoteDataById(id);
        history.push({
            pathname: '/price-simulation-detailed-quote/view-quote/' + id,
            state: { type: 'viewQuote' },
            id: id
        });

    }
    const handleDownloadExcel = (item) => {
        fetch(baseUrl['BASE_URL'] + 'download-comprehensive-quote-excel/' + item.ID, {
            // fetch('https://stage-api.ima360.app/v1/api/download-comprehensive-quote-excel/' + item.ID, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = "QuoteNumber_" + item.quote_number;
                link.setAttribute('download', file + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }
    const handleDownloadPdf = (item) => {
        fetch(baseUrl['BASE_URL'] + '/download-comprehensive-quote-pdf/' + item.ID, {
            //fetch('https://stage-api.ima360.app/v1/api/download-comprehensive-quote-pdf/' + item.ID, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                var date = Date.now();
                var file = "QuoteNumber_" + item.quote_number;
                link.setAttribute('download', file + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
    }

    const handleSendEmail = (item) => {
        fetch(baseUrl['BASE_URL'] + '/download-comprehensive-quote-excel/' + item.ID + '?send_mail=true', {
            // fetch('https://demo-api.ima360.app/v1/api/download-outgoing-claims-edi?claim_number=' + item.claim_number+'&send_mail=true', {

            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
                'Authorization': secureLocalStorage.getItem("aTk")
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                }
                return response.json()
            })
            .then(response => {
                if (response && response.message) {
                    toast.error(response.message)
                }
                else {
                    toast.success('Email Sent Successfully.')
                }
            })
    }

    const columns = [
        {
            field: 'quote_number',
            title: 'Quote Number',
            editable: 'never',
            type: 'numeric',
            render: rowData =>
                <div onClick={() => handleItemClick(rowData.ID)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#2877B1' }}>
                    {rowData.quote_number}
                </div>
        },
        {
            field: 'quote_type',
            title: 'Quote Type',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'internal_description',
            title: 'Internal Description',
            editable: 'never',
            type: 'string',
        },
        {
            field: 'approval_status',
            title: 'Approval Status',
            editable: 'never',
            type: 'string',
            render: rowData =>
                <div>
                    {rowData.approval_status === 'new' ? 'New' : rowData.approval_status === 'acc' ? 'Accepted' : rowData.approval_status === 'rej' ? 'Rejected'
                        : rowData.approval_status === 'changes_acc' ? 'Changes Accepted' :
                            'Submit for Approval'}
                </div>
        },
        {
            field: 'quote_amount',
            title: 'Quote Amount',
            editable: 'never',
            type: 'string',

        },

        {
            field: 'valid_from',
            title: 'Start Date',
            editable: 'never',
            render: rowData =>
                <div>{rowData.valid_from === '0001-01-01T00:00:00Z' ? '' : Moment.utc(rowData.valid_from).format('MM/DD/YYYY')} </div>
        },
        {
            field: 'valid_to',
            title: 'End Date',
            editable: 'never',
            render: rowData => <div>{rowData.valid_to === '0001-01-01T00:00:00Z' ? '' : Moment.utc(rowData.valid_to).format('MM/DD/YYYY')}</div>
        },
        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div className={classes.actionStyle}>
                    <div className={classes.actionStyle} style={{ width: ' 100%' }}>
                        {//secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_contract-'+localStorage.getItem('application')) &&
                            <IconButton classes={{ root: classes.IconButton }} onClick={() => editQuote(rowData.ID)}
                                disabled={rowData.hereIsTheUpdatedContractIDToShow ? true : false}
                                size={'small'}
                            >
                                <Edit color="disabled" style={{ fontSize: 15 }}
                                />
                            </IconButton>
                        }
                        {//secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+localStorage.getItem('application')) &&
                            <IconButton onClick={() => clone(rowData.id)} classes={{ root: classes.IconButton }} size={'small'}>
                                <img src={require('../../../library/images/Copy.png')} alt='' />
                            </IconButton>
                        }
                        <Tooltip title="Email">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleSendEmail(rowData)}
                                size="large">
                                <Send color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="PDF Download">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleDownloadPdf(rowData)}
                                size="large">
                                <PictureAsPdf color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Excel Download">
                            <IconButton
                                classes={{ root: classes.IconButton }}
                                onClick={() => handleDownloadExcel(rowData)}
                                size="large">
                                <TextSnippetIcon color="disabled" style={{ fontSize: 20, color: '#636363' }} />
                            </IconButton>
                        </Tooltip>


                    </div>
                    <div style={{ marginLeft: 5, borderLeft: '1px solid lightgray' }}>
                        {//secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+localStorage.getItem('application')) &&
                            <IconButton onClick={() => deleteCon(rowData.ID)} classes={{ root: classes.IconButton }} size={'small'}

                            >
                                <DeleteOutline />
                            </IconButton>
                        }


                    </div>

                </div>
        }
    ];
    function handleDialog(bool) {
        if (bool === 'No') {
            setOpenConfirmDelete(true)
        } else {
            props.deleteQuote(currentDeleteContractNumber);
            setOpenConfirmDelete(false);
        }
    }
    return (

        <div className={clsx(classes.root, className)}>

            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)',
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black',
                    })
                }}

                className={classes.spinner}
            >

                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Dialog open={openConfirmDelete}>
                        <DialogContent classes={{
                            root: classes.dialogContent
                        }}>
                            <Typography variant="h4">
                                <DialogContentText>
                                    Are you sure you want to delete the Quote  ?
                                </DialogContentText>
                            </Typography>
                        </DialogContent>
                        <DialogActions classes={{
                            root: classes.dialog
                        }}>
                            <Button onClick={() => setOpenConfirmDelete(false)} autoFocus color="primary" variant="outlined" >
                                No
                            </Button>
                            <Button onClick={() => handleDialog('Yes')} color="primary" autoFocus variant="contained"  >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* <div style={{ fontSize: 22 }}> Comprehensive Quote </div> */}
                    <Typography variant="h1" color='primary'  > Comprehensive Quote</Typography>

                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={clsx({
                                [classes.row]: isDesktop
                            })} >


                                {//secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_contract-'+localStorage.getItem('application')) &&
                                    <div>
                                        <Fab aria-label="edit" variant="extended"
                                            size='medium'
                                            classes={{ root: classes.fabContainer }}
                                            className={classes.fabContainer}
                                            onClick={addQuote}
                                        >
                                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                                        </Fab>
                                        {/* <Button
                                                variant="contained"
                                                startIcon={<AddBoxOutlinedIcon />}
                                                classes={{
                                                    startIcon: !isDesktop && classes.startIcon,
                                                    root: !isDesktop && classes.container
                                                }}
                                                size={'small'}
                                                onClick={addQuote}
                                                className={classes.buttonCreate}
                                            >
                                                {isDesktop ?
                                                    'Create Quote' : ''}
                                            </Button> */}

                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                {quoteData && quoteData.length > 0 ?
                    <div style={{ marginTop: "-1.875rem" }}>
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                            }}
                            title={' '}
                            editable={true}
                            icons={tableIcons}
                            columns={columns}
                            data={quoteData}
                            options={{
                                search: false,
                                sorting: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                filtering: true,
                                pageSize: 10,
                            }}
                        />
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined"
                                color="primary" className={classes.button} onClick={handleSheetDownload}
                                style={{ textTransform: 'none', color: '#0B3662' }}
                                size={'small'}
                            > Download </Button>
                        </div>
                    </div>
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </LoadingOverlay>

        </div >
    );

};

ContractSetupTable.propTypes = {
    className: PropTypes.string
};

const mapStateToProps = state => {
    return {
        // loading: state.addContractData.loading,
        quoteData: state.pricingData.comprehensiveQuoteData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllQuoteData: (page, limit) => dispatch(getComprehensiveQuote(page, limit)),
        getQuoteDataById: (id) => dispatch(getComprehensiveQuoteById(id)),
        deleteQuote: (id) => dispatch(createComprehensiveQuote(null, id, 'delete')),


        cloneContract: (id) => dispatch(cloneContract(id)),
        downloadAllContracts: () => dispatch(downloadAllContracts()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSetupTable);