import React, { useRef, useState, useEffect } from "react";
import {
  Chip,
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Link,
  FormLabel,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Input,
  ListItemText,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import SimpleDialog from "../../components/Dialog";
import {
  runCalculationSimulation,
  getDefaultValues,
  getDefaultValuesAllDataNew,
  runDynamicQuery,
  getContractsTableDataList,
  getCustomerMasterData,
  getMaterialMasterData,
  getSupplierMasterData,
  getAttributeListData,
} from "../../redux/actions";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import ChipInput from "material-ui-chip-input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import momentt from "moment-timezone";
import MomentUtils from "@date-io/moment";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { useLocation } from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: 29,
    marginTop: 16,
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  container: {
    padding: 10,
    border: '1px solid #EEEEEE',
  },
  gridContainer: {
    padding: "10px 45px 10px 45px",
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 28,
    paddingBottom: 30,
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: 3,
    marginTop: 10,
    height: 33,
    padding: 0,
    marginBottom: 14,
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
    paddingBottom: 25,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
  },
  deleteIcon: {
    height: 13,
  },
  chipLabel: {
    fontFamily: "ProximaNova",
  },
  contractContiner: {
    marginTop: 17,
  },
  spinner: {
    height: "100vh",
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: "none",
    border: 0,
    fontSize: 16,
    paddingRight: 10,
  },
  inputTwoLine: {
    marginTop: 10,
    width: "100%",
    paddingLeft: 10,
    height: 35,
  },
  selectRoot: {
    // padding: "0px 0px 15px 15px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
},
  select: {
    width: "100%",
    color: "#1675e0",
    backgroundColor: "white",
  },
  selectedItem: {
    borderRadius: 5,
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ddd",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
  chips: {
    display: "flex",
    flexWrap: "nowrap",
  },
  fontSetting: {
    fontSize: theme.typography.h3.fontSize
  }
}));

const DynamicQueryForm = (props) => {
  const classes = useStyles();
  const { className } = props;
  const history = useHistory();
  const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
  const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] =
    useState([]);
  const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
  const [description, setDescription] = React.useState([]);
  const [internalDescriptionArray, setInternalDescriptionArray] =
    React.useState([]);
  const [optionsList, setOptionsList] = React.useState([]);
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [customerNumbersList, setCustomerNumbersList] = React.useState([]);
  const [materialNumbersList, setMaterialNumbersList] = React.useState([]);
  const [supplierNumbersList, setSupplierNumbersList] = React.useState([]);
  const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
  const [materialDesc, setmaterialDesc] = React.useState([]);
  const [supplierNameArray, setSupplierNameArray] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [listArray, setListArray] = React.useState([]);
  const [allSelect, setAllSelect] = React.useState(false);
  const [attributeData, setAttributeData] = React.useState([]);
  const [dynamicFieldValuesTemp, setDynamicFieldValuesTemp] = React.useState(
    []
  );

  const postingTypeArray = [
    "Accrual",
    "Accrual Reversal",
    "Payment",
    "Payment Reversal",
    "Delta Payment",
    "Delta Accrual",
  ];
  const calculationTypeArray = ["Tier", "Rebate"];
  const customerList = [
    "Top Parent Customer Number",
    "Sold To Party",
    "Bill To Party",
    "Payer",
  ];
  const handleDynamicFieldValues = (e, fieldName, type) => {
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: { from: dynamicFieldValues[fieldName][0], to: e },
          });
        } else {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: dynamicFieldValues,
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    } else {
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: e,
      });
    }
  };
  const handleDynamicFieldValuesTempDelete = (newValue) => {
    var deletedTags = dynamicFieldValuesTemp.filter(
      (item) => item !== newValue
    );
    setDynamicFieldValuesTemp(deletedTags);
  };
  const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: { from: dynamicFieldValues[fieldName][0], to: e },
          });
        } else {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: dynamicFieldValues,
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    } else {
      var temp = dynamicFieldValues[fieldName]
        ? dynamicFieldValues[fieldName]
        : [];
      if (type === "pastedItem") {
        e.map((arg) => {
          if (arg) temp.push(arg.trim());
        });
      } else {
        temp.push(e);
      }
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: temp,
      });
    }
  };
  const handleDynamicFieldValuesDelete = (e, fieldName, type) => {
    if (type === "range") {
      if (
        dynamicFieldValues[fieldName] &&
        dynamicFieldValues[fieldName].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: { from: dynamicFieldValues[fieldName][0], to: e },
          });
        } else {
          setDynamicFieldValuesRange(e);
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            [fieldName]: dynamicFieldValues,
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    } else {
      var temp = dynamicFieldValues[fieldName].filter((item) => item !== e);
      setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        [fieldName]: temp,
      });
    }
  };
  const [billingDateStart, setBillingDateStart] = React.useState(null);
  const [billingDateEnd, setBillingDateEnd] = React.useState(null);
  const [CYMS, setCYMS] = React.useState(null);
  const [CYME, setCYME] = React.useState(null);
  const handleBillingDate = (e, field) => {
    if (field == "start") {
      setBillingDateStart(e);
      setDynamicFieldValues({
        ...dynamicFieldValues,
        ["billing_date"]: Moment(e).format("YYYY-MM-DD"),
      });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        ["billing_date"]: Moment(e).format("YYYY-MM-DD"),
      });
    } else {
      setBillingDateEnd(e);
      if (
        dynamicFieldValues["billing_date"] &&
        dynamicFieldValues["billing_date"].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            ["billing_date"]: {
              from: dynamicFieldValues["billing_date"],
              to: Moment(e).format("YYYY-MM-DD"),
            },
          });
        } else {
          setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            ["billing_date"]: dynamicFieldValues,
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    }
  };
  const handleCalendarYearMonth = (e, field) => {
    if (field == "start") {
      setCYMS(e);
      setDynamicFieldValues({
        ...dynamicFieldValues,
        ["calendar_year_month"]: Moment(e).format("YYYYMM"),
      });
      setDynamicFieldValuesFormSubmit({
        ...dynamicFieldValuesFormSubmit,
        ["calendar_year_month"]: Moment(e).format("YYYYMM"),
      });
    } else {
      setCYME(e);
      if (
        dynamicFieldValues["calendar_year_month"] &&
        dynamicFieldValues["calendar_year_month"].length > 0
      ) {
        if (e) {
          setDynamicFieldValuesRange(Moment(e).format("YYYYMM"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            ["calendar_year_month"]: {
              from: dynamicFieldValues["calendar_year_month"],
              to: Moment(e).format("YYYYMM"),
            },
          });
        } else {
          setDynamicFieldValuesRange(Moment(e).format("YYYYMM"));
          setDynamicFieldValuesFormSubmit({
            ...dynamicFieldValuesFormSubmit,
            ["calendar_year_month"]: dynamicFieldValues,
          });
        }
      } else {
        setDynamicFieldValuesRange("");
      }
    }
  };
  useEffect(() => {
    var contracts = [];
    var tempNameArray = [];
    if (props.contractTabledataComplete) {
      props.contractTabledataComplete.map((item) => {
        contracts.push(item.contract_number.toString());
        tempNameArray.push(item.internal_description);
      });
      setContractNumbersList(contracts);
      setDescription(tempNameArray);
    }
  }, [props.contractTabledataComplete]);
  const location = useLocation();
  const [queryListID, setQueryListID] = React.useState(1);
  const [filterFieldsArray, setFilterFieldsArray] = React.useState([]);
  const [currentQueryID, setCurrentQueryID] = React.useState("");
  const [sortOrderObj, setSortOrderObj] = React.useState({});
  const [sortOrderArray, setSortOrderArray] = React.useState([]);
  const [level, setLevel] = React.useState("");
  const [postingType, setPostingType] = React.useState([]);
  const [calculationType, setCalculationType] = React.useState([]);
  const [contractData, setContractData] = React.useState([]);
  const [contractGroup, setContractGroup] = React.useState([]);
  const [contractSubGroup, setContractSubGroup] = React.useState([]);
  // useEffect(() => {
  //   if (
  //     props.dynamicQueryDetailsData &&
  //     Object.keys(props.dynamicQueryDetailsData).length > 0
  //   ) {
  //     props.onLoadingLabelDesignerNew(
  //       "field-label-designer",
  //       props.dynamicQueryDetailsData.app_type,
  //       props.dynamicQueryDetailsData.data_source_type
  //     );
  //   }
  // }, [props.dynamicQueryDetailsData]);
  useEffect(() => {
    props.onLoadingDefault(localStorage.getItem("dynamic_filter_fields"));
    if (localStorage.getItem("dynamic_filter_fields")) {
      var object = JSON.parse(localStorage.getItem("dynamic_filter_fields"));
      setFilterFieldsArray(object.fields);
      setCurrentQueryID(object.id);
      setSortOrderObj(object.sort_fields);
    }
    props.onCheckContractsTable();
    props.onCheckCustomerTable();
    props.onCheckMaterialTable();
    props.onCheckSupplierTable();
    props.getAttributeListData({ attribute_name: "" });
  }, []);
  useEffect(() => {
    if (sortOrderObj && sortOrderObj.length > 0) {
      var temp = [];
      sortOrderObj.map((item) => {
        temp.push(item);
      });
      setSortOrderArray(temp);
    }
  }, [sortOrderObj]);
  useEffect(() => {
    if (props.attributeData && props.attributeData.length > 0)
      setAttributeData(props.attributeData);
  }, [props.attributeData]);
  useEffect(() => {
    if (
      props.customerMasterData &&
      props.customerMasterData.records &&
      props.customerMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.customerMasterData.records.map((item) => {
        tempArray.push(item.customer_number);
        tempNameArray.push(item.customer_name);
      });
      tempArray.push(
        "Top Parent Customer Number",
        "Sold To Party",
        "Bill To Party",
        "Payer"
      );
      setCustomerNumbersList(tempArray);
      setCustomerNameListArray(tempNameArray);
    }
  }, [props.customerMasterData]);
  useEffect(() => {
    if (
      props.materialMasterData &&
      props.materialMasterData.records &&
      props.materialMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.materialMasterData.records.map((item) => {
        tempArray.push(item.material_number);
        tempNameArray.push(item.customer_name);
      });
      setMaterialNumbersList(tempArray);
      setmaterialDesc(tempArray);
    }
  }, [props.materialMasterData]);
  useEffect(() => {
    if (
      props.supplierMasterData &&
      props.supplierMasterData.records &&
      props.supplierMasterData.records.length > 0
    ) {
      var tempArray = [];
      var tempNameArray = [];
      props.supplierMasterData.records.map((item) => {
        tempArray.push(item.supplier_number);
        tempNameArray.push(item.supplier_name);
      });
      setSupplierNumbersList(tempArray);
      setSupplierNameArray(tempNameArray);
    }
  }, [props.supplierMasterData]);
  useEffect(() => {
    if (
      props.dropdownData &&
      props.dropdownData.records &&
      props.dropdownData.records.length > 0
    ) {
      setContractData(props.dropdownData.records);
    }
  }, [props.dropdownData]);
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        overflowY: "scroll",
        scrollBehaviour: " smooth",
        MarginTop: 0,
        menuPlacement: "top",
      },
    },
    getContentAnchorEl: null,
  };
  const [contractType, setContractType] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [SelectOpen, setSelectOpen] = useState(false);
  const [classOfTrade, setClassOfTrade] = useState([]);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [customerChain, setCustomerChain] = useState([]);
  const [materialGroup, setMaterialGroup] = useState([]);
  const [productHierarchy, setProductHierarchy] = useState([]);
  const [memberOwner, setMemberOwner] = useState([]);
  const [currentPage, setCurrentPage] = React.useState("");
  useEffect(() => {
    setCurrentPage(
      location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    );
  }, [location]);

  useEffect(() => {
    if (currentPage) {
      if (currentPage === "partner-statement-form") {
        setQueryListID(3);
      } else if (currentPage === "collaboration-portal-form") {
        setQueryListID(3);
      } else {
        setQueryListID(1);
      }
    }
  }, [currentPage]);

  const handleRun = () => {
    //saving for download sheet
    if (dynamicFieldValuesFormSubmit) {
      var formData = {
        id: currentQueryID.toString(),
        filter_fields: dynamicFieldValuesFormSubmit,
      };
      localStorage.setItem("dyamicQueryDownloadData", JSON.stringify(formData));
    } else {
      var formData = {
        id: currentQueryID.toString(),
      };
      localStorage.setItem("dyamicQueryDownloadData", JSON.stringify(formData));
    }

    if (dynamicFieldValuesFormSubmit.length === 0) {
      var data = {
        id: currentQueryID.toString(),
      };
    } else {
      var data = {
        id: currentQueryID.toString(),
        filter_fields: dynamicFieldValuesFormSubmit,
      };
    }

    props.runDynamicQuery(data, currentQueryID, "dynamicAnalytics");
  };
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      internalDescriptionArray[optionsList.indexOf(option)] + option,
  });
  const handleOpen = (item, type) => {
    if (type == "select_box") {
      setSelectOpen(true);
      setLevel(item);
      if (item === "posting_type") {
        setSelected(postingType);
        setListArray(postingTypeArray);
        setAllSelect(
          postingTypeArray &&
          postingTypeArray.length > 0 &&
          postingType.length === postingTypeArray.length
        );
      }
      if (item === "calculation_type") {
        setSelected(calculationType);
        setListArray(calculationTypeArray);
        setAllSelect(
          calculationTypeArray &&
          calculationTypeArray.length > 0 &&
          calculationType.length === calculationTypeArray.length
        );
      }
      if (item === "contract_type") {
        setSelected(contractType);
        setListArray(
          contractData.filter((item) => item.field_id == "contract_type")[0][
          "drop_down_value_keys"
          ]
        );
        setAllSelect(
          contractData.filter((item) => item.field_id == "contract_type") &&
          contractData
            .filter((item) => item.field_id == "contract_type")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          contractType.length ===
          contractData
            .filter((item) => item.field_id == "contract_type")
            .map((e) => e.drop_down_value_keys).length
        );
      }
      if (item === "contract_group") {
        setSelected(contractGroup);
        setListArray(
          contractData.filter((item) => item.field_id == "contract_group")[0][
          "drop_down_value_keys"
          ]
        );
        setAllSelect(
          contractData.filter((item) => item.field_id == "contract_group") &&
          contractData
            .filter((item) => item.field_id == "contract_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          contractGroup.length ===
          contractData
            .filter((item) => item.field_id == "contract_group")
            .map((e) => e.drop_down_value_keys).length
        );
      }
      if (item === "contract_sub_group") {
        setSelected(contractSubGroup);
        setListArray(
          contractData.filter(
            (item) => item.field_id == "contract_sub_group"
          )[0]["drop_down_value_keys"]
        );
        setAllSelect(
          contractData.filter(
            (item) => item.field_id == "contract_sub_group"
          ) &&
          contractData
            .filter((item) => item.field_id == "contract_sub_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          contractSubGroup.length ===
          contractData
            .filter((item) => item.field_id == "contract_sub_group")
            .map((e) => e.drop_down_value_keys).length
        );
      }
      if (item === "class_of_trade") {
        setSelected(classOfTrade);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "class_of_trade"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          classOfTrade.length ===
          attributeData
            .filter((item) => item.attribute_name == "class_of_trade")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "customer_group") {
        setSelected(customerGroup);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "customer_group"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          customerGroup.length ===
          attributeData
            .filter((item) => item.attribute_name == "customer_group")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "customer_chain") {
        setSelected(customerChain);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "customer_chain"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          customerChain.length ===
          attributeData
            .filter((item) => item.attribute_name == "customer_chain")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "material_group") {
        setSelected(materialGroup);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "material_group"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          materialGroup.length ===
          attributeData
            .filter((item) => item.attribute_name == "material_group")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "product_hierarchy") {
        setSelected(productHierarchy);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "product_hierarchy"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          productHierarchy.length ===
          attributeData
            .filter((item) => item.attribute_name == "product_hierarchy")
            .map((e) => e.attribute_value).length
        );
      }
      if (item === "membership_owner_id") {
        setSelected(memberOwner);
        setListArray(
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.attribute_value)
        );
        setAllSelect(
          attributeData.filter(
            (item) => item.attribute_name == "membership_owner_id"
          ) &&
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.drop_down_value_keys).length > 0 &&
          memberOwner.length ===
          attributeData
            .filter((item) => item.attribute_name == "membership_owner_id")
            .map((e) => e.attribute_value).length
        );
      }
    } else {
      if (item === "contract_number") {
        setOptionsList(contractNumbersList);
        setInternalDescriptionArray(description);
      }
      if (item === "material_number") {
        setOptionsList(materialNumbersList);
        setInternalDescriptionArray(materialDesc);
      }
      if (item === "customer_number") {
        setOptionsList(customerNumbersList);
        setInternalDescriptionArray(customerNameListArray);
      }
      if (item === "supplier_number") {
        setOptionsList(supplierNumbersList);
        setInternalDescriptionArray(supplierNameArray);
      }
    }
  };

  const handleSelectValues = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (value === "all") {
      if (level === "posting_type") {
        setSelected(
          selected.length === postingTypeArray.length ? [] : postingTypeArray
        );
        setAllSelect(
          selected.length === postingTypeArray.length ? false : true
        );
      } else if (level === "calculation_type") {
        setSelected(
          selected.length === calculationTypeArray.length
            ? []
            : calculationTypeArray
        );
        setAllSelect(
          selected.length === calculationTypeArray.length ? false : true
        );
      } else {
        setSelected(selected.length === listArray.length ? [] : listArray);
        setAllSelect(selected.length === listArray.length ? false : true);
      }
    } else {
      setSelected(newSelected);
      setAllSelect(newSelected.length === listArray.length ? true : false);
    }
  };
  function onAdd() {
    setSelectOpen(false);
    if (level == "posting_type") {
      setPostingType(selected);
    }
    if (level == "calculation_type") {
      setCalculationType(selected);
    }
    if (level == "contract_type") {
      setContractType(selected);
    }
    if (level == "contract_group") {
      setContractGroup(selected);
    }
    if (level == "contract_sub_group") {
      setContractSubGroup(selected);
    }
    if (level == "class_of_trade") {
      setClassOfTrade(selected);
    }
    if (level == "customer_group") {
      setCustomerGroup(selected);
    }
    if (level == "customer_chain") {
      setCustomerChain(selected);
    }
    if (level == "material_group") {
      setMaterialGroup(selected);
    }
    if (level == "product_hierarchy") {
      setProductHierarchy(selected);
    }
    if (level == "membership_owner_id") {
      setMemberOwner(selected);
    }
    setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
    setDynamicFieldValuesFormSubmit({
      ...dynamicFieldValuesFormSubmit,
      [level]: selected,
    });
  }
  function onCancel() {
    setSelectOpen(false);
    setSelected([]);
    setLevel("");
  }
  const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
  useEffect(() => {
    if (
      props.labelNewAPIData &&
      Object.keys(props.labelNewAPIData).length > 0
    ) {
      setLabelNewAPIData(props.labelNewAPIData);
    }
  }, [props.labelNewAPIData]);

  function checkLabel(name) {
    if (labelNewAPIData && Object.keys(labelNewAPIData).length > 0) {
      return Object.entries(labelNewAPIData).map(([key, value]) => {
        if (key == name) {
          return labelNewAPIData[name]["current"];
        }
      });
    } else return name.replace(/_/g, " ");
  }

  return (
    <LoadingOverlay
      active={props.loading}
      spinner
      styles={{
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: "#045FB4",
          },
        }),
        overlay: (base) => ({
          ...base,
          background: "rgba(52, 52, 52, 0)",
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}
      text="Loading results..."
      className={classes.spinner}
    >
      {/* <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/dynamic-query')}
                >
                    On Demand Query
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;
                    {currentPage === 'partner-statement-form' ? 'Partner Statement Form' :
                        currentPage === 'collaboration-portal-form' ? 'Collaboration Portal' :
                            'On Demand Query'
                    }
                </Typography>
            </Breadcrumbs> */}
      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="h1" color='primary'>Dynamic Analytics Form</Typography>
        </div>
        <form autoComplete="off" noValidate>
          <div className={classes.container}>
            <Grid container>
              {filterFieldsArray &&
                filterFieldsArray
                  .filter((item) => filterFieldsArray.includes(item))
                  .map((item, ix) => {
                    return item === "billing_date" ? (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        // classes={{ root: classes.gridContainer }}
                        style={{ padding: "10px 5px 10px 45px" }}
                      >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ textTransform: "capitalize" }}>
                          {checkLabel("billing_date")
                            ? checkLabel("billing_date")
                            : "Billing Date"}
                        </FormLabel>
                        <div style={{ display: "flex", marginTop: 10 }}>
                          <div className={classes.selectRoot} style={{ paddingRight: "5px" }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="flex-start">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        width: '100%',
                                                        height: "37px"
                                                    }
                                                }}
                                                value={billingDateStart}
                                                onChange={(e) =>handleBillingDate(e, "start")
                                  }
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    </div>
                                    <div className={classes.selectRoot}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="flex-start">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10,
                                                        height: "37px"
                                                        
                                                    }
                                                }}
                                                value={billingDateEnd}
                                                onChange={(e) => handleBillingDate(e, "end")}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </div>
                        </div>
                      </Grid>
                    ) : item === "calendar_year_month" ? (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        // classes={{ root: classes.gridContainer }}
                        style={{ padding: "10px 5px 10px 45px" }}
                      >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ textTransform: "capitalize" }}>
                          {checkLabel("calendar_year_month")
                            ? checkLabel("calendar_year_month")
                            : "Calendar Year Month"}
                        </FormLabel>
                        <div style={{ display: "flex", marginTop: 10 }}>
                          <div className={classes.selectRoot}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="flex-start">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                        width: '100%',
                                                        height: "37px"
                                                    }
                                                }}
                                                value={CYMS}
                                                onChange={(e) =>handleCalendarYearMonth(e, "start")
                                  }
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                    </div>
                                    <div className={classes.selectRoot}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Grid container justifyContent="flex-start">
                                            <DatePicker
                                                disableToolbar
                                                clearable
                                                InputProps={{
                                                    padding: 0,
                                                    disableUnderline: true,
                                                    style: {
                                                        // padding: '1px 4px 1px 11px',
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        //marginTop: 10,
                                                        border: '1px solid #E0E0E0',
                                                        width: '100%',
                                                        marginLeft: 10,
                                                        height: "37px"
                                                    }
                                                }}
                                                value={CYME}
                                                onChange={(e) => handleCalendarYearMonth(e, "end")}
                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                format="MM/DD/YYYY"
                                                minDate={billingDateStart ? billingDateStart : ''}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                          </div>
                        </div>
                      </Grid>
                    ) : item === "posting_type" ||
                      item === "calculation_type" ||
                      item === "contract_type" ||
                      item === "contract_group" ||
                      item === "contract_sub_group" ||
                      item === "class_of_trade" ||
                      item === "customer_group" ||
                      item === "customer_chain" ||
                      item === "material_group" ||
                      item === "product_hierarchy" ||
                      item === "membership_owner_id" ? (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        // classes={{ root: classes.gridContainer }}
                        style={{ padding: "10px 45px 10px 10px" }}
                      >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ textTransform: "capitalize" }}>
                          {checkLabel(item)
                            ? checkLabel(item)
                            : item.replace(/_/g, " ")}
                        </FormLabel>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            multiple
                            value={
                              dynamicFieldValues[item]
                                ? dynamicFieldValues[item]
                                : []
                            }
                            style={{
                              height: "37px",
                              maxHeight: "50px",
                              width: "100%",
                              marginTop: 10,
                              textTransform: "capitalize",
                            }}
                            input={<OutlinedInput />}
                            MenuProps={{ ...MenuProps, autoFocus: true }}
                            onOpen={() => handleOpen(item, "select_box")}
                            onClose={() => setSelectOpen(false)}
                            open={SelectOpen && level == item}
                            renderValue={(appType) => appType.join(", ")}
                            className={classes.multiSelect}
                          >
                            <MenuItem
                              value="all"
                              onClick={(event) =>
                                handleSelectValues(event, "all")
                              }
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox color="primary" checked={allSelect} />
                              <ListItemText primary="Select All" />
                            </MenuItem>
                            {listArray &&
                              listArray.length > 0 &&
                              listArray.map((item) => {
                                return (
                                  <MenuItem
                                    onClick={(event) =>
                                      handleSelectValues(event, item)
                                    }
                                    value={item}
                                    key={item}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={selected.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                );
                              })}

                            <div className={classes.dropdownAction}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.btn}
                                onClick={onCancel}
                              >
                                Cancel
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.btn}
                                onClick={onAdd}
                              >
                                Apply
                              </Button>
                            </div>
                          </Select>
                        </div>
                      </Grid>
                    ) : item === "contract_number" ||
                      item === "material_number" ||
                      item === "customer_number" ||
                      item === "supplier_number" ? (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        // classes={{ root: classes.gridContainer }}
                        style={{ padding: "10px 45px 10px 10px" }}
                      >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ textTransform: "capitalize" }}>
                          {checkLabel(item)
                            ? checkLabel(item)
                            : item.replace(/_/g, " ")}
                        </FormLabel>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "50%",
                              marginRight: 5,
                              marginTop: 10,
                            }}
                          >
                            <Autocomplete
                              id="tags-standard"
                              freeSolo
                              multiple
                              value={
                                dynamicFieldValues[item]
                                  ? dynamicFieldValues[item]
                                  : []
                              }
                              options={optionsList}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  onKeyDown={(e) => {
                                    if (e.code === "enter" && e.target.value) {
                                      setDynamicFieldValues[item](
                                        dynamicFieldValues[item].concat(
                                          e.target.value
                                        )
                                      );
                                    }
                                  }}
                                  // style={{ height: 20, borderRadius: '0px !important' }}
                                  InputProps={{
                                    ...params.InputProps,
                                    onPaste: (event) => {
                                      const clipboardText = event.clipboardData
                                        .getData("Text")
                                        .split("\n");

                                      event.preventDefault();
                                      handleDynamicFieldValuesAdd(
                                        clipboardText,
                                        item,
                                        "pastedItem",
                                        ix
                                      );
                                      if (props.onPaste) {
                                        props.onPaste(event);
                                      }
                                    },
                                  }}
                                />
                              )}
                              filterOptions={filterOptions}
                              disableCloseOnSelect
                              // style={{ marginBottom: 10 }}
                              onOpen={() => handleOpen(item, "auto_complete")}
                              onClose={() => setListOpen(false)}
                              style={{
                                width: "100%",
                                marginBottom: 15,
                                marginTop: 5,
                              }}
                              onChange={(e, newValue) =>
                                handleDynamicFieldValues(newValue, item)
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    color="primary"
                                    checked={selected}
                                  />
                                  {item == "customer_number" &&
                                    customerList.includes(option)
                                    ? option
                                    : (internalDescriptionArray[
                                      optionsList.indexOf(option)
                                    ]
                                      ? internalDescriptionArray[
                                      optionsList.indexOf(option)
                                      ]
                                      : "") +
                                    " - (" +
                                    option +
                                    ")"}
                                </li>
                              )}
                            />
                          </div>

                          <div
                            style={{
                              width: "50%",
                              marginLeft: 5,
                              marginTop: 0,
                            }}
                          >
                            <Autocomplete
                              options={optionsList}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    height: 20,
                                    borderRadius: "0px !important",
                                  }}
                                />
                              )}
                              filterOptions={filterOptions}
                              style={{
                                marginTop: 10,
                                borderRadius: "0px !important",
                              }}
                              onChange={(e, newValue) =>
                                handleDynamicFieldValues(
                                  newValue,
                                  item,
                                  "range"
                                )
                              }
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {item == "customer_number" &&
                                    customerList.includes(option)
                                    ? option
                                    : (internalDescriptionArray[
                                      optionsList.indexOf(option)
                                    ]
                                      ? internalDescriptionArray[
                                      optionsList.indexOf(option)
                                      ]
                                      : "") +
                                    " - (" +
                                    option +
                                    ")"}{" "}
                                </li>
                              )}
                              disabled={
                                dynamicFieldValues[item] &&
                                  dynamicFieldValues[item].length > 1
                                  ? true
                                  : false
                              }
                              classes={{
                                inputRoot: { borderRadius: "0px !important" },
                                option: {
                                  borderBottom: `1px solid red`,
                                  // Hover
                                  '&[data-focus="true"]': {
                                    backgroundColor: "red",
                                    borderColor: "transparent",
                                  },
                                  // Selected
                                  '&[aria-selected="true"]': {
                                    backgroundColor: "red",
                                    borderColor: "transparent",
                                  },
                                },
                                listbox: {
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        // classes={{ root: classes.gridContainer }}
                        style={{ padding: "10px 45px 10px 10px" }}
                        
                      >
                        <FormLabel classes={{ root: classes.fontSetting }} style={{ textTransform: "capitalize" }}>
                          {checkLabel(item)
                            ? checkLabel(item)
                            : item.replace(/_/g, " ")}
                        </FormLabel>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%", marginRight: 5 }}>
                            <ChipInput
                              classes={{
                                root: classes.rootContainer,
                                chip: classes.chip,
                                input: classes.input,
                                inputRoot: classes.inputRoot,
                                label: classes.chipLabel,
                                chipContainer: classes.chipContainer,
                              }}
                              value={
                                dynamicFieldValues[item]
                                  ? dynamicFieldValues[item]
                                  : []
                              }
                              onAdd={(e) =>
                                handleDynamicFieldValuesAdd(e, item, null, ix)
                              }
                              onDelete={(e) =>
                                handleDynamicFieldValuesDelete(
                                  e,
                                  item,
                                  null,
                                  ix
                                )
                              }
                              onPaste={(event) => {
                                const clipboardText = event.clipboardData
                                  .getData("Text")
                                  .split("\n");
                                console.log("cppp", clipboardText);
                                event.preventDefault();
                                handleDynamicFieldValuesAdd(
                                  clipboardText,
                                  item,
                                  "pastedItem",
                                  ix
                                );
                                if (props.onPaste) {
                                  props.onPaste(event);
                                }
                              }}
                              disableUnderline={true}
                              blurBehavior="add"
                            />
                          </div>
                          <div style={{ width: "50%", marginLeft: 5 }}>
                            <OutlinedInput
                              classes={{ root: classes.inputTwoLine }}
                              type={props.type}
                              value={
                                dynamicFieldValues[item] &&
                                  dynamicFieldValues[item].length > 0
                                  ? dynamicFieldValuesRange[item]
                                  : ""
                              }
                              onChange={(e) =>
                                handleDynamicFieldValues(
                                  e.target.value,
                                  item,
                                  "range"
                                )
                              }
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleRun}
                twoline="true"
              >
                Run
              </Button>
            </div>
          </div>
        </form>
      </div >
    </LoadingOverlay >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadingDefault: () => dispatch(getDefaultValues()),
    runDynamicQuery: (data) =>
      dispatch(runDynamicQuery(data, null, "dynamicAnalytics")),
    onCheckContractsTable: () => dispatch(getContractsTableDataList()),
    onCheckCustomerTable: () => dispatch(getCustomerMasterData(1, 10000)),
    onCheckMaterialTable: () => dispatch(getMaterialMasterData("NoLimit")),
    onCheckSupplierTable: () => dispatch(getSupplierMasterData()),
    onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) =>
      dispatch(
        getDefaultValuesAllDataNew(command, applicationType, sourceDataType)
      ),
    getAttributeListData: (data) => dispatch(getAttributeListData(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    contractTabledataComplete: state.addContractData.contractAllListData,
    customerMasterData: state.customerData.customerMasterData,
    materialMasterData: state.customerData.materialMasterData,
    supplierMasterData: state.customerData.supplierMasterData,
    labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    dynamicQueryDetailsData:
      state.operationalReportsData.dynamicQueryDetailsData,
    attributeData: state.attributeListData.AttributeData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicQueryForm);
