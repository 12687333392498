import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { DropdownKeyValue, DropdownArray, TextInputWithLabel } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, getApprovalGroupData, getApprovalLevelData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 0 0'
    },
    cardRoot: {
        backgroundColor: theme.palette.white,
        boxShadow: '1px 2px 4px #9E9E9E',
        borderRadius: 6,
        margin: '10px 20px 10px 20px',
        padding: 15,
        overflow: 'hidden'
    },
}));

const ApprovalStatus = (props, ref) => {
    const contractCustomisationUpdation = useRef();
    const [approvalStatusArray, setApprovalStatusArray] = React.useState([]);
    const [approvalStatus, setApprovalStatus] = React.useState('');
    const [approvalGroup, setApprovalGroup] = React.useState(0);
    const [approvalDetails, setApprovalDetails] = React.useState([]);


    useEffect(() => {
        props.getApprovalGroupData();
    }, []);
    useEffect(() => {
        if (props.workflowStatus && props.workflowStatus[0]) {
            setApprovalDetails(props.workflowStatus[0])
            setApprovalGroup(props.workflowStatus[0].approval_group_details)
        }
    }, [props.workflowStatus])
    useEffect(() => {
        for (var propName in approvalStatusValues) {
            if (approvalStatusValues[propName] === '' || approvalStatusValues[propName] === null
            ) {
                delete approvalStatusValues[propName];
            }
        }
        props.submitValues(approvalStatusValues);
        // if (!contractCustomisationUpdation.current) {
        //     {
        //         if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
        //             props.dropdownData.records.map((item) => {
        //                 if (item.field_id === 'approval_status') {
        //                     setApprovalStatusArray(item);
        //                 }
        //             })
        //         }
        //     }
        // }
    });
    var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const { className } = props;
    const approvalStatusValues = {
        contract_status: approvalDetails && approvalDetails.status_description,
        approval_group_id: approvalGroup.key ? approvalGroup.key : 0,
        approval_status: approvalDetails && approvalDetails.approval_status
    };
    const [approvalGroupAPIData, setApprovalGroupAPIData] = React.useState([]);

    //new fields
    function handleApprovalStatus(newValue) {
        setApprovalStatus(newValue);

    }
    function handleApprovalGroup(e) {
        setApprovalGroup(e.target.value);
    }
    // useEffect(() => {
    //     var level = []
    //     if (props.approvalGroupData && props.approvalGroupData.length > 0) {
    //         setApprovalGroupAPIData(props.approvalGroupData)
    //         props.approvalGroupData
    //             .sort((a, b) => a.level > b.level ? 1 : -1)
    //             .map(item => {
    //                 level.push(item.approval_group + " Approved")
    //                 if (item.level == 1)
    //                     setApprovalGroup(item.approval_group_id)
    //             })
    //     }

    // }, [props.approvalGroupData]);


    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>

                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {approvalStatusArray &&
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ width: '50%', padding: 10 }}>
                                                <CardHeader
                                                    title="APPROVAL STATUS"
                                                    titleTypographyProps={{ variant: 'h3' }}
                                                    classes={{ root: classes.headerRoot }}
                                                />
                                                <DropdownArray heading={''} placeholder={''} twoline='true' onChange={handleApprovalStatus}
                                                    data={approvalDetails && approvalDetails.status_description ? [approvalDetails.status_description] : []}
                                                    prevalue={approvalDetails && approvalDetails.status_description} />
                                            </div>
                                            {approvalGroup && approvalGroup.name &&
                                                <div style={{ width: '50%', padding: 10 }}>
                                                    <CardHeader
                                                        title={"APPROVAL GROUP"}
                                                        titleTypographyProps={{ variant: 'h3' }}
                                                        classes={{ root: classes.headerRoot }}
                                                    // style={{ paddingBottom: 20 }}
                                                    />
                                                    <FormControl >
                                                        <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline="true" mandatory
                                                            prevalue={approvalGroup.name} disabled />
                                                        {/* <Select
                                                            disableUnderline
                                                            onChange={handleApprovalGroup}
                                                            // displayEmpty
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            value={approvalGroup}
                                                        >
                                                            {approvalDetails && approvalDetails.approval_group_details && approvalDetails.approval_group_details
                                                                .map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={item.key}>
                                                                            {item.name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select> */}
                                                    </FormControl>

                                                </div>
                                            }
                                        </div>

                                    </Grid>
                                }
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

}

const mapStateToProps = state => ({
    data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    approvalGroupData: state.addContractData.approvalLevelData,
    workflowStatus: state.DocumentManagement.documentStatus,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        getApprovalGroupData: () => dispatch(getApprovalLevelData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ApprovalStatus);