import React, { useEffect } from 'react';
import {
    Typography, Switch, Table, TableCell,
    TableHead, TableRow, TableBody, TextField,
    InputAdornment, IconButton, Button, ToggleButton, ToggleButtonGroup, Chip
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, DetailRow } from '@syncfusion/ej2-react-grids';
import { StickerTableHeader } from '../../../GlobalSetup/Components';
import { getBudgetingConfig, getMaterialFilters } from '../../../../../redux/actions';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PromoMultiSelect, NestedDropdown } from '../../../Components';
import { PromoRegion } from './Components';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 16px',
    },

    divContainerRight: {
        position: 'relative',
        width: '20%',
        height: '80vh',
        marginLeft: 7,
    },

    headerRight: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        height: 75,
        width: '100%',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
    },

    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    mainHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
    },
    tableHeaderTop: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 40,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },
    tableHeaderBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 75,
        border: '1px solid #f5f5f5',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5'
    },

    sellButton: {
        border: '1px solid #D8D8D8',
        margin: '-5px 10px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    refreshButton: {
        border: '1px solid #D8D8D8',
        margin: '-1px 45px',
        padding: 3,
        width: 35,
        height: 35,
        cursor: 'pointer',
    },

    span: {
        marginLeft: 5,
        fontSize: '.9rem'
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 19,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42,
        backgroundColor: theme.palette.white,
        marginLeft: 10,
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        display: 'flex',
        flexDirection: 'column'
    },
    gridContainer: {
        marginTop: 30,
        marginLeft: '5px',
        width: 'auto',
        overflowX: 'scroll'
    },
    chipHighlighter: {
        borderColor: theme.palette.primary.main
    }

}));






const GridMain = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [productType, setProductType] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState('2020');
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [expandedRows, setExpandedRows] = React.useState([]);
    const [activeSticker, setActiveSticker] = React.useState([]);
    const [activeStickerID, setActiveStickerID] = React.useState([]);

    const toggleExpand = (row) => {
        let expandedRowsCopy = [...expandedRows];
        if (expandedRows.includes(row)) {
            expandedRowsCopy = expandedRowsCopy.filter(r => r !== row);
        } else {
            expandedRowsCopy.push(row);
        }
        setExpandedRows(expandedRowsCopy);
    };

    const isRowExpanded = (row) => {
        return expandedRows.includes(row);
    };

    useEffect(() => {
        props.getConfigData();
    }, []);

    useEffect(() => {
        // props.getFilters()
        if (props.modelYears && props.modelYears.length > 0)
            setModelYear(props.modelYears[0])
    }, [props.modelYears]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData[0].promotion_division)
            if (props.configData[0].promotion_division[0])
                setProductType(props.configData[0].promotion_division[0])
            setSelectedCountry('USA')
        }
    }, [props.configData]);

    function handleChange(e) {
        setProductType(e.target.value);
    }

    function handleModelYear(event) {
        setModelYear(event.target.value)
    }

    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        else {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };

    const handleFilters = (value, field, type) => {
        // setFilterData(value)
    }
    const handleActiveSticker = (e, value) => {
        if (activeSticker.includes(value)) {
            setActiveSticker(activeSticker.filter(item => item != value))
            setActiveStickerID(activeSticker.filter(item => item != value.ID))
        }
        else {
            setActiveSticker([...activeSticker, value])
            setActiveStickerID([...activeStickerID, value.ID])
        }
    }
    return (
        <>
            <div className={classes.root}>
                <StickerTableHeader productLineData={productLinesList} defaultDivison={productType} onChange={handleChange} />

                <div className={classes.mainHeader}>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={props.handleBack}>
                                Return
                            </Button>
                            <Typography style={{ fontSize: 13, marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                    </div>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <div className={classes.row}>
                            <Typography>
                                <MonetizationOnOutlinedIcon />
                            </Typography>
                            <Typography style={{ marginRight: 80 }}>
                                (In use) Currency Rate 0.73
                            </Typography>
                            <Typography>
                                <SellOutlinedIcon className={classes.sellButton} />
                            </Typography>
                        </div>

                    </div>
                </div>
                <div className={classes.mainHeader}>
                    <div className={classes.row}>
                        <AutorenewIcon className={classes.refreshButton} />
                        <Typography>Tot. Promo Cost XXXXX CAD | Tot Vol. 15% | Tot Avg/Unit. XXX CAD  Breakdown {" >"}</Typography>
                    </div>
                    <div className={classes.row}>
                        <Button variant="outlined">Cancel</Button>
                        <Button style={{ marginLeft: 10 }} variant="outlined">Review Scenario</Button>
                    </div>
                </div>
                <div style={{ backgroundColor: '#f5f5f5', marginLeft: 5 }}>
                    <div className={classes.tableHeaderTop}>
                        <div style={{ marginLeft: 35, marginTop: 20, color: 'grey', display: 'flex' }}>
                            In Use Stickers
                            <div style={{ marginLeft: 5 }}>
                                {props.stickers && props.stickers.map((option, i) => (
                                    <Chip
                                        label={option.version > 1 ? `V${option.version} - ${option.sticker_description}` : option.sticker_description}
                                        variant="outlined"
                                        onClick={(e) => handleActiveSticker(e, option)}
                                        sx={{ backgroundColor: 'white' }}
                                        icon={<CircleIcon style={{ color: props.colorPicker && props.colorPicker[option.ID] }} size='small' />}
                                        className={activeStickerID.includes(option.ID) && classes.chipHighlighter}
                                    />

                                ))

                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: 35, marginTop: 20 }}>
                            <MoreVertIcon className={classes.refreshButton} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 75 }}>
                        <div className={classes.tableHeaderBottom}>
                            <ToggleButtonGroup
                                color="primary"
                                value={modelYear}
                                exclusive
                                onChange={handleModelYear}
                                aria-label="Platform"
                                style={{ position: 'relative', marginTop: 38, zindex: -1 }}
                            >
                                {props.modelYears && props.modelYears.map(item =>
                                    <ToggleButton
                                        value={item}
                                        sx={{
                                            '&.Mui-selected': {
                                                backgroundColor: 'white',
                                                height: 37,
                                                borderRadius: 5,
                                                marginLeft: -1,
                                                border: '1px solid',
                                                marginTop: 2
                                            },
                                            '&:not(.Mui-selected)': {
                                                border: 'none',
                                            },
                                        }}

                                    >{item}</ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 44 }}>
                            <div className={classes.filterBox} >
                                <Typography variant='h4' color='black' style={{ marginRight: 12 }}>Filter</Typography>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Model Year</Typography>
                                    <PromoMultiSelect id='model-year' onChange={(value) => handleFilters(value, 'material_group1', 'material_master')}
                                        options={props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0] && props.filterData['material_master'][0]['material_group1']} />
                                </div>
                                <div className={classes.filterContainer}>
                                    <div className={classes.filterItem}>
                                        <Typography variant='h4' color='grey'>Model Selection</Typography>
                                        <IconButton onClick={() => handleClick('model')}>
                                            {anchorEl ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </div>
                                    {anchorEl &&
                                        <NestedDropdown head='Model Selection' id={'model'} expand={expanded} type='model_filter' />}
                                </div>
                                <div className={classes.filterContainer}>
                                    <div className={classes.filterItem}>
                                        <Typography variant='h4' color='grey'>Location Selection</Typography>
                                        <IconButton onClick={() => handleClick('location')}>
                                            {anchorloc ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                    </div>
                                    {anchorloc &&
                                        <NestedDropdown head='Location Selection' id={'locations'} expand={expanded} type='loc_filter' />
                                    }
                                </div>

                            </div>
                            <div className={classes.filterBox} >
                                <GroupsIcon />
                                <Typography variant='h4' color='grey'>Custom Groups</Typography>
                                <IconButton onClick={() => handleClick('model')}>
                                    {anchorEl ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={classes.gridContainer}>
                    <PromoRegion country={props.country} modelYear={modelYear} definedData={props.definedData}
                        gridFilter={props.gridFilter}//modelData={props.modelsArray}
                        activeSticker={activeSticker} colorPicker={props.colorPicker} locationData={props.locations} />
                </div>
            </div >
        </>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        getConfigData: () => dispatch(getBudgetingConfig()),
        getFilters: () => dispatch(getMaterialFilters()),
    }
}

const mapStateToProps = state => {
    return {
        configData: state.calculationAnalysisData.budgetData,
        // filterData: state.promotionData.promoFilters,
        modelData: state.promotionData.modelFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GridMain);