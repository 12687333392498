import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Divider, Grid, Button, Typography, Card, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, CardHeader, IconButton,
    OutlinedInput, Select, MenuItem, FormLabel, Dialog, DialogActions, DialogContent, DialogContentText, TextField
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { LabelText, SearchDropdown } from '../../../../components/Inputs';
import { connect } from 'react-redux';
import { getGeneralUOM, getPriceFormula, getEmployeeData, getMaterialMasterData, getSupplierMasterData, getCustomerMasterData, getCalculationRulesTableData, getDefaultValuesAllDataNew, editCalculationRules, getDefaultValues, getDefaultValuesKeyFigure, getFormulasList, getDefaultValuesAllData, getInitialDataFromSourceDataType, getAttributeListData, uploadCalculationRules } from '../../../../redux/actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import AddIcon from '@mui/icons-material/Add';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip from "@mui/material/Tooltip";
import { AllOtherAttributeMasterComponent } from '../../../../components/Inputs';
import { CloudUpload } from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 36,
        display: 'flex',
        justifyContent: 'center'
    },
    dollorIconActive: {
        minWidth: 0,
        padding: '6px 9px',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: 'green'
        },
        height: 36,
        display: 'flex',
        backgroundColor: 'green'
    },
    dollorIconDeactive: {
        minWidth: 0,
        padding: '6px 9px',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: '#CEF6CE'
        },
        height: 36,
        display: 'flex',
        backgroundColor: '#CEF6CE'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    inputTwoLineAddTiming: {
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    error: {
        border: '1px solid red !important'
    },
    chargeBackLabel: {
        // fontSize: 13,
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    removeButton: {
        color: theme.palette.button.red,
        padding: '0px 2px'
    },
    formDiv: {
        height: 41,
        fontSize: 14,
        display: 'flex',
        alignItems: 'flex-end'
    },
    borderRad0: {
        borderRadius: '0px !important'
    },
    uploadIconContainer: {
        minWidth: 0,
        padding: '6px 9px',
        height: 36,
        display: 'flex',
        justifyContent: 'center',
    },
}));
var deleteFlag = false;
const CalculationRules = forwardRef((props) => {

    const [calculationStore, setCalculationStore] = React.useState([]);
    const [keyFigureArrayMXS, setKeyFigureArrayMXS] = React.useState([]);
    const [attributeArrayMXS, setAttributeArrayMXS] = React.useState([]);
    const [formulaArrayMXS, setFormulaArrayMXS] = React.useState([]);

    const [keyFigureArrayMXSAPI, setKeyFigureArrayMXSAPI] = React.useState([]);
    const [attributeArrayMXSAPI, setAttributeArrayMXSAPI] = React.useState([]);
    const [formulaArrayMXSAPI, setFormulaArrayMXSAPI] = React.useState([]);
    const [fieldData, setFieldData] = React.useState([]);
    // useEffect(() => {
    //     //props.getMaterialMasterData();
    //     // props.getEmployeeData();
    //     //props.onLoadingDefault(sessionStorage.getItem('application'), props.sourceDataType);
    //    // props.onLoadingDefault()
    // }, [])
    const [keyFigureObject, setKeyFigureObject] = React.useState([]);
    const [editIndex, setEditIndex] = React.useState('');
    const [attributeNameData, setAttributeNameData] = React.useState([]);
    const [attributeNameArray, setAttributeNameArray] = React.useState([]);
    const [attributeNameObject, setAttributeNameObject] = React.useState([]);
    const [keyFigureKeyArray, setKeyFigureKeyArray] = React.useState([]);
    const [calculationTypeTier, setCalculationTypeTier] = React.useState('');
    const [formulaTypeTier, setFormulaTypeTier] = React.useState('');
    const [generalUOMAll, setGeneralUOMAll] = React.useState([]);
    const [keyFigureArrayMXSColumns, setKeyFigureArrayMXSColumns] = React.useState([]);
    const [attributeArrayMXSColumns, setAttributeArrayMXSColumns] = React.useState([]);
    const [formulaArrayMXSColumns, setFormulaArrayMXSColumns] = React.useState([]);
    const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
    const calculationTypeData = ['Tier', 'Rebate', 'Both']
    const calculationTypeDataMXS = ['Tier', 'Rebate']
    const inputFile = useRef(null)
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [currentFileName, setCurrentFileName] = React.useState('');
    const [maximumAmount, setMaximumAmount] = React.useState('');
    const [activeDropdown, setActiveDropdown] = React.useState(false);
    const formulaTypeDataTier = [
        { key: 'Key Figure', value: 'Key Figure' },
        { key: 'Formula', value: 'Formula' }
    ];

    const [timingRow, setTimingRow] = React.useState([]);
    const [timingData, setTimingData] = React.useState([]);

    const handleScaleDate = (e, index) => {
        const { name, value } = e.target;
        const rows = [...timingRow];
        rows[index][name] = value;
        setTimingRow(rows);
    };
    const addTiming = () => {
        if (!editMode)
            handleAddRow()
        setOpenTiming(true);
    }
    const handleAddRow = () => {
        const item = {
            from: "",
            rate: "",
            //maximum_amount:""
        };
        if (props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate')
            item["maximum_amount"] = ""
        setTimingRow([...timingRow, item]);
    };
    const handleRemoveSpecificRow = (index) => () => {
        const rows = [...timingRow];
        rows.splice(index, 1);
        setTimingRow(rows);
    }
    useEffect(() => {
        props.getAttributeListData({
            attribute_name: ''
        })
    }, [])
    // useEffect(() => {
    //     if (props.activeDropdown)
    //         setActiveDropdown(props.activeDropdown)
    // }, [props.activeDropdown])
    useEffect(() => {
        if (props.generalUOMAll && props.generalUOMAll.length > 0) {
            setGeneralUOMAll(props.generalUOMAll);
        }
    }, [props.generalUOMAll])

    useEffect(() => {
        props.getGeneralUOM();
        props.getFormulasList();
        if (localStorage.getItem('mode') === 'edit')
            props.onCheckCalculationRulesTable();
        // props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), 'Indirect - Sales Data')
    }, []);
    useEffect(() => {
        if (props.sourceDataType && attributeNameData && attributeNameData.length == 0) {
            props.onLoadingAttribute(sessionStorage.getItem('application'), props.sourceDataType);
        }
    }, [props.sourceDataType])
    useEffect(() => {
        if (props.startDate) {
            setEffectiveStartDate(props.startDate);
            setStartDateCB(props.startDate);
            setStartDateMR(props.startDate);
            setStartDateScale(props.startDate);
        }
        if (props.endDate) {
            setEffectiveEndDate(props.endDate);
            setEndDateCB(props.endDate);
            setEndDateMR(props.endDate);
            setEndDateScale(props.endDate);
        }

    }, [props.startDate, props.endDate])
    useEffect(() => {
        if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
            setFieldData(props.dropdownData.records)
        }
    }, [props.dropdownData])
    useEffect(() => {
        var dropdownValue = []
        if (fieldData && fieldData.length > 0) {
            fieldData
                .filter(item => item.app_source_type == sessionStorage.getItem('application') && item.data_source_type == props.sourceDataType && item.field_id === 'attribute_name')
                .map((item) => {
                    setAttributeNameData(item.drop_down_value_keys);
                    dropdownValue = item.drop_down_value_keys
                    //setAttributeNameObject(item);
                })
            dropdownValue && dropdownValue.length > 0 && dropdownValue.map((item1) => {
                labelNewAPIData && Object.keys(labelNewAPIData).length > 0 && Object.entries(labelNewAPIData)
                    .map(([key, value]) => {
                        if (item1 === key) {
                            setMappingValuesSetAttribute((mappingValuesSet) => ({
                                ...mappingValuesSet,
                                [item1]: value['current'],
                            }));
                        }
                    })
            });
        }
    }, [props.sourceDataType, fieldData, labelNewAPIData])

    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [mappingValuesSetAttribute, setMappingValuesSetAttribute] = React.useState({});


    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
            if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownAttributeData.records[0].drop_down_value_keys);
                setAttributeNameArray(props.dropdownAttributeData.records[0].label_names);
                setAttributeNameObject(props.dropdownAttributeData.records[0])
            }
        }
    }, [props.dropdownAttributeData])

    useEffect(() => {
        if (props.calculationSchema === 'BOM Explosion') {
            setAttributeNameMR('material_number');
        }
        if (props.calculationMethod === 'Price Difference') {
            // if (activeDropdown && activeDropdown['material_number'])
            //     props.getMaterialMasterData();
            setAttributeNameCB('material_number');
            setAttributeNameMR('material_number');
            setRateTypeCB('$');
        }
        if (props.calculationMethod === 'Price Point') {
            props.getPricingCalculationFormula();
        }
    }, [props.calculationMethod]);
    const [operationArray, setOperationArray] = React.useState([]);
    const [editableData, setEditableData] = React.useState('');
    const classes = useStyles();
    const { className } = props;
    const [keyfigurename, setKeyfigurename] = React.useState('');
    const [calculation, setCalculation] = React.useState('');
    const [effectiveStartDate, setEffectiveStartDate] = React.useState(null);
    const [effectiveEndDate, setEffectiveEndDate] = React.useState(null);
    const [rate, setRate] = React.useState('');
    const [uom, setUom] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [keyFigureData, setKeyFigureData] = React.useState([]);
    const [keyfigureEnableHelper, setKeyfigureEnableHelper] = React.useState(false);
    const [attributeNameMR, setAttributeNameMR] = React.useState('');
    const [attributeValueMR, setAttributeValueMR] = React.useState('');
    const [keyFigureNameMR, setKeyFigureNameMR] = React.useState('');
    const [rateMR, setRateMR] = React.useState('');
    const [uomMR, setUomMR] = React.useState('');
    const [rateTypeMR, setRateTypeMR] = React.useState('');
    const [startDateMR, setStartDateMR] = React.useState(null);
    const [endDateMR, setEndDateMR] = React.useState(null);
    const [keyfigureEnableHelperMR, setKeyfigureEnableHelperMR] = React.useState(false);
    const [keyfigureValueMR, setKeyfigureValueMR] = React.useState([]);

    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

    function handleAttributeNameMR(event) {
        // if (activeDropdown && activeDropdown[event.target.value]) {
        //     if (event.target.value === 'customer_number') {
        //         props.getCustomerMasterData();
        //     } else if (event.target.value === 'material_number') {
        //         props.getMaterialMasterData();
        //     } else if (event.target.value === 'supplier_number') {
        //         props.getSupplierMasterData();
        //     }
        //     else
        //         props.getEmployeeData()
        // }
        setAttributeNameMR(event.target.value);
        setAttributeValueMR('')
    }
    function handleAttributeValueMR(e, type) {
        if (type === 'attr') {
            var val = e
            if (e && e.length > 0) {
                var index = e.indexOf('-');
                if (index != -1)
                    val = e.substring(0, index - 1)
            }
            setAttributeValueMR(val)
        }
        else if (type === 'attr-mat') {
            setAttributeValueMR(e)
        }
        else
            setAttributeValueMR(e.target.value);
    }
    function handleKeyFigurenameMR(event) {
        setKeyfigureValueMR(keyFigureObject.drop_down_value_keys[event.target.value]);
        setKeyFigureNameMR(event.target.value);
        setKeyfigureEnableHelperMR((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);

    }
    function handleRateMR(event) {
        if (event.target.value != 0 || event.target.value === '') {
            setRateMR(event.target.value);
            checkRate(event.target.value);
        }
    }
    function handleUomMR(event) {
        setUomMR(event.target.value);
    }
    function handleStartDateMR(event) {
        setStartDateMR(event);
    }
    function handleEndDateMR(event) {
        setEndDateMR(event);
    }
    function checkRate(e) {
        if (e > 100) {
            setTooltipIsOpen(true)
            setTimeout(
                () => setTooltipIsOpen(false),
                4000
            );
        }
    }
    const currentApplication = sessionStorage.getItem('application');
    const [attributeNameCB, setAttributeNameCB] = React.useState('');
    const [attributeValueCB, setAttributeValueCB] = React.useState('');
    const [acquisitionPriceCB, setAcquisitionPriceCB] = React.useState('');
    const [contractPriceCB, setContractPriceCB] = React.useState('');
    const [uomCB, setUomCB] = React.useState('');
    const [rateType, setRateType] = React.useState('');
    const [rateTypeCB, setRateTypeCB] = React.useState(props.calculationMethod === 'Price Difference' ? '$' : '');
    const [rateTypeScale, setRateTypeScale] = React.useState('');
    const [startDateCB, setStartDateCB] = React.useState(null);
    const [endDateCB, setEndDateCB] = React.useState(null);
    const [openTiming, setOpenTiming] = React.useState(false);
    const [keyFigureNameAPI, setKeyFigureNameAPI] = React.useState('');
    const [keyFigureValue, setKeyFigureValue] = React.useState('');


    function handleAcquisitionPriceCB(event) {
        setAcquisitionPriceCB(event.target.value);
    }
    function handleContractPriceCB(event) {
        setContractPriceCB(event.target.value);
    }
    function handleUomCB(event) {
        setUomCB(event.target.value);
    }
    function handleStartDateCB(event) {
        setStartDateCB(event);
    }
    function handleEndDateCB(event) {
        setEndDateCB(event);
    }
    const [keyFigureNameScale, setKeyFigureNameScale] = React.useState('');
    const [keyfigureValueScale, setKeyfigureValueScale] = React.useState('');
    const [rateScale, setRateScale] = React.useState('');
    const [attributeCount, setAttributeCount] = React.useState('');
    const [uomScale, setUomScale] = React.useState('');
    const [startDateScale, setStartDateScale] = React.useState(null);
    const [endDateScale, setEndDateScale] = React.useState(null);
    function handleKeyFigurenameScale(event) {
        setKeyfigureValueScale(keyFigureObject.drop_down_value_keys[event.target.value]);
        setKeyFigureNameScale(event.target.value);
    }
    function handleUomScale(event) {
        setUomScale(event.target.value);
    }
    function handleStartDateScale(event) {
        setStartDateScale(event);
    }
    function handleEndDateScale(event) {
        setEndDateScale(event);
    }
    const handleDeleteLocally = (index, type) => {
        deleteFlag = true;
        if (type != 'tier') {
            setCalculationStore(item => item.filter((item, i) => i !== index));
            props.onChange(item => item.filter((item, i) => i !== index))
        } else {
            setCalculationStore(item => item.filter((item, i) => i !== index));
            var calculationStoreDel = calculationStore.splice(index, 1);


            var APIArray = [];
            calculationStoreDel.map(item => {
                if (item.type === 'Rebate') {
                    if (item.formula_id) {
                        var dataScaleAPI = {
                            "formula_id": parseInt(item.formula_id),
                            "formula_type": 'Formula',
                            "effective_start_date": item.effective_start_date,
                            "effective_end_date": item.effective_end_date,
                            "type": item.type,
                            "operations": item.operations
                        };
                        APIArray.push(dataScaleAPI);
                    } else {
                        var dataScaleAPI = {
                            "key_figure_names": item.key_figure_names,
                            "effective_start_date": item.effective_start_date,
                            "effective_end_date": item.effective_end_date,
                            "formula_type": 'Key Figure',
                            "type": item.type,
                            "operations": item.operations
                        };
                    }
                } else {

                    if (item.formula_id) {
                        item.timing && item.timing.forEach(function (single) {
                            var dataScaleAPI = {
                                "attribute_values": [parseFloat(single.from).toString()],
                                "attribute_names": [],
                                "rate": parseFloat(single.rate),
                                "formula_id": parseInt(item.formula_id),
                                "formula_type": 'Formula',
                                "incentive_uom": item.incentive_uom,
                                "rate_type": item.rateType,
                                "effective_start_date": item.effective_start_date,
                                "effective_end_date": item.effective_end_date,
                                "type": item.type,
                                "operations": item.operations
                            };
                            APIArray.push(dataScaleAPI);
                        })
                    } else {
                        item.timing && item.timing.forEach(function (single) {
                            var dataScaleAPI = {
                                "attribute_values": [parseFloat(single.from).toString()],
                                "attribute_names": item.key_figure_names,
                                "rate": parseFloat(single.rate),
                                "key_figure_names": item.key_figure_names,
                                "incentive_uom": item.incentive_uom,
                                "rate_type": item.rateType,
                                "effective_start_date": item.effective_start_date,
                                "effective_end_date": item.effective_end_date,
                                "formula_type": 'Key Figure',
                                "type": item.type,
                                "operations": item.operations
                            };
                            APIArray.push(dataScaleAPI);
                        })
                    }
                }

            })
            props.onChange(APIArray);
        }
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        if (deleteFlag === true) {
            var edited = [...calculationStore];
            props.onChange(edited);
            deleteFlag = false;
        }
    }, [calculationStore])

    const uomData = [
        { key: '$', value: '$' },
        { key: '%', value: '%' }
    ];
    const operationData = [
        { key: '+', value: '+' },
        { key: '-', value: '-' }
    ];
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    const clone = (item) => {
        var temp = item
        setCalculationStore([...calculationStore, temp])
        const index = calculationStore.map(el => el.type).lastIndexOf("Tier");
        editcalculationRule(item, index + 1, 'Multi Axis')
        toast.success("Calculation rule copied successfully")
    }

    const editcalculationRule = (rule, index, type) => {

        if (editIndex !== index) {
            setEditIndex(index);
            setEditMode(true);
            setEditableData(rule)
            if (type === 'MR') {
                setAttributeNameMR(rule.attribute_names[0]);
                setAttributeValueMR(rule.attribute_values[0]);
                if (rule.key_figure_names && rule.key_figure_names[0])
                    setKeyFigureNameMR(rule.key_figure_names[0]);
                setRateMR(rule.rate);
                setUomMR(rule.incentive_uom);
                setRateTypeMR(rule.rate_type);
                setStartDateMR(rule.effective_start_date);
                setEndDateMR(rule.effective_end_date);
                setFormulaName(rule.formula_id)
                setFormulaTypeTier(rule.formula_type)
            }
            if (type === 'Quota') {
                var result;

                setKeyfigurename(rule.key_figure_names[0])
                setRate(rule.rate);
                setUom(rule.incentive_uom);
                setRateType(rule.rate_type);
                setEffectiveStartDate(rule.effective_start_date);
                setEffectiveEndDate(rule.effective_end_date);
                setTimingRow(rule.timing);


                if (rule && rule.key_figure_names) {
                    result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                }
                setOperationArray(result);
            }
            if (type === 'CB') {
                setAttributeNameCB('material_number');
                setAttributeValueCB(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].attribute_value);
                setAcquisitionPriceCB(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].acquisition_price);
                setContractPriceCB(rule.attribute_key_values && rule.attribute_key_values[0] && rule.attribute_key_values[0].contract_price);
                setRateTypeCB(rule.rate_type);
                setUomCB(rule.incentive_uom);
                setStartDateCB(rule.effective_start_date);
                setEndDateCB(rule.effective_end_date);
            }
            if (type === 'Multi Axis') {
                setKeyFigureNameScale(rule.key_figure_names && rule.key_figure_names[0] ? rule.key_figure_names[0] : '')
                setRateScale(rule.rate);
                setUomScale(rule.incentive_uom);
                setRateTypeScale(rule.rate_type);
                setStartDateScale(rule.effective_start_date);
                setEndDateScale(rule.effective_end_date);
                setCalculationTypeTier(rule.type);
                if (rule.maximum_amount)
                    setMaximumAmount(rule.maximum_amount)
                if (rule && rule.key_figure_values) {
                    const result = rule.key_figure_values
                        .map((name, index) => {
                            return {
                                'key': name.key,
                                'value': name.value,
                                'type': name.type
                            }
                        })
                    setKeyFigureArrayMXS(result);
                    setKeyFigureArrayMXSAPI(result);
                }
                if (rule && rule.attribute_values) {
                    const result = rule.attribute_values
                        .map((name, index) => {
                            return {
                                'attribute_names': name.attribute_names,
                                'attribute_values': name.attribute_values,
                                'type': name.type
                            }
                        })
                    setAttributeArrayMXS(result);
                    setAttributeArrayMXSAPI(result);
                    setAttributeNameMR(rule.attribute_name)
                    setAttributeCount(rule.attribute_count)
                }
                if (rule && rule.formula_values) {
                    const result = rule.formula_values
                        .map((name, index) => {
                            return {
                                'formula_names': name.formula_names,
                                'formula_values': name.formula_values,
                                'type': name.type
                            }
                        })
                    setFormulaArrayMXS(result);
                    setFormulaArrayMXSAPI(result);
                }
                if (rule.formula_id) {
                    setFormulaName(rule.formula_id)
                    setFormulaTypeTier('Formula');
                }
                else
                    setFormulaTypeTier('Key Figure');

            }
            if (type === 'Tiered Volume - Simple') {
                setKeyFigureNameScale(rule.key_figure_names && rule.key_figure_names.length > 0 && rule.key_figure_names[0])
                setRateScale(rule.rate);
                setUomScale(rule.incentive_uom);
                setRateTypeScale(rule.rate_type);
                setStartDateScale(rule.effective_start_date);
                setEndDateScale(rule.effective_end_date);
                setTimingRow(rule.timing);
                setTimingData(rule.timing);
                setCalculationTypeTier(rule.type);
                if (rule.maximum_amount)
                    setMaximumAmount(rule.maximum_amount)
                if (rule.formula_id) {
                    setFormulaName(rule.formula_id)
                    setFormulaTypeTier('Formula');
                }
                else
                    setFormulaTypeTier('Key Figure');
                var result;

                if (rule && rule.key_figure_names) {
                    result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                }
                setOperationArray(result);
            }
            else {
                if (rule.attribute_names && rule.attribute_names[0])
                    setAttributeNameMR(rule.attribute_names[0]);
                if (rule.attribute_values && rule.attribute_values[0])
                    setAttributeValueMR(rule.attribute_values[0]);
                if (rule.key_figure_names && rule.key_figure_names[0])
                    setKeyfigurename(rule.key_figure_names[0]);
                var result;
                if (rule && rule.key_figure_names) {
                    result = rule.key_figure_names
                        .filter((item, index) => index != 0)
                        .map((name, index) => {
                            return {
                                'key_figure_names': name,
                                'operation': rule.operations[index]
                            }
                        })
                }
                if (props.calculationMethod === 'Price Point' || rule.formula_id) {
                    setFormulaName(rule.formula_id)
                }
                setOperationArray(result);
                setCalculation(rule.type);
                setEffectiveStartDate(rule.effective_start_date);
                setEffectiveEndDate(rule.effective_end_date);
                setRate(rule.rate);
                setUom(rule.incentive_uom);
                setRateType(rule.rate_type);
                setFormulaTypeTier(rule.formula_type)
            }
        } else {
            alert('Already editing the same rule.')
        }
    }
    function handleKeyfigurename(event) {
        setKeyFigureNameAPI(keyFigureKeyArray[keyFigureData.indexOf(event.target.value)])
        setKeyfigurename(event.target.value);
        setKeyfigureEnableHelper((event.target.value || event.target.value === 0) ? (event.target.value + 1) : 0);
    }
    function handleEffectiveStartDate(event) {
        if (event && event.isValid())
            setEffectiveStartDate(event.toISOString().split('T')[0].concat('T00:00:00.000Z'));
        else
            setEffectiveStartDate(event)
    }
    function handleEffectiveEndDate(newValue) {
        if (newValue && newValue.isValid())
            setEffectiveEndDate(newValue.toISOString().split('T')[0].concat('T23:59:59.000Z'));
        else
            setEffectiveEndDate(newValue)
    }
    function handleRate(event) {
        if (event.target.value != 0 || event.target.value === '') {
            checkRate(event.target.value);
            setRate(event.target.value);
        }
    }
    function handleUom(event) {
        setUom(event.target.value);
    }
    function handleSubmit(id, type) {
        if (type === 'CB') {
            if (attributeValueCB && (currentApplication != 'Pricing' ? acquisitionPriceCB : true)
                && contractPriceCB && startDateCB && endDateCB) {
                var data = {
                    "attribute_name": 'material_number',
                    "incentive_uom": uomCB,
                    "rate_type": rateTypeCB,
                    "effective_start_date": startDateCB,
                    "effective_end_date": endDateCB,
                    "attribute_key_values": [
                        {
                            "attribute_type": 'Attribute',
                            "attribute_name": 'material_number',
                            "attribute_value": attributeValueCB,
                            "acquisition_price": acquisitionPriceCB ? parseFloat(acquisitionPriceCB) : '',
                            "contract_price": parseFloat(contractPriceCB),
                        }
                    ]


                };
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                } else {
                    setCalculationStore([...calculationStore, data]);
                    var duplicated = [...calculationStore, data];


                    props.onChange(duplicated)
                }
                handleRemoveCB();
            } else {
            }
        } else if (type === 'MR') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameMR)])
            keyfigArr.push(keyFigureNameMR);

            var data = {
                "attribute_names": [attributeNameMR],
                "attribute_values": [attributeValueMR],
                "key_figure_names": formulaTypeTier !== 'Formula' ? keyfigArrAPI : [],
                "rate": parseFloat(rateMR),
                "incentive_uom": uomMR,
                "rate_type": rateTypeMR,
                "effective_start_date": startDateMR,
                "effective_end_date": endDateMR,
                "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                "formula_type": formulaTypeTier
            };
            if (id || id === 0) {
                var editedArray = [...calculationStore];
                editedArray[editIndex] = data;
                setCalculationStore(editedArray);
                var duplicated = editedArray;
                duplicated.map((item, ix) => {
                    duplicated[ix].attribute_key_values = []
                    item.attribute_names?.map((single, ix2) => {
                        temp = {};
                        temp.attribute_type = 'Attribute';
                        temp.attribute_name = single;
                        temp.attribute_value = duplicated[ix].attribute_values[ix2];
                        duplicated[ix].attribute_key_values.push(temp);
                    })
                })
                props.onChange(duplicated)
                localStorage.removeItem('editableRuleMode');
            } else {
                var duplicated = [...calculationStore, data];
                duplicated.map((item, ix) => {
                    duplicated[ix].attribute_key_values = []
                    item.attribute_names?.map((single, ix2) => {
                        temp = {};
                        temp.attribute_type = 'Attribute';
                        temp.attribute_name = single;
                        temp.attribute_value = duplicated[ix].attribute_values[ix2];
                        duplicated[ix].attribute_key_values.push(temp);
                    })
                })
                setCalculationStore([...calculationStore, data]);
                props.onChange(duplicated)
            }
            handleRemoveMR();

        }

        else if (type === 'FA') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArr.push(keyfigurename);
            keyfigArrAPI.push(keyFigureNameAPI);;
            operationArray && operationArray.forEach(function (item, index) {
                keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });

            if (effectiveStartDate && effectiveEndDate && rate) {
                var data = {
                    "type": calculation,
                    "effective_start_date": effectiveStartDate,
                    "effective_end_date": effectiveEndDate,
                    "rate": parseFloat(rate),
                    "incentive_uom": uom,
                    "rate_type": rateType,
                    "enabled": true,
                    "key_figure_names": keyfigArr,
                    "operations": opArr,
                    "attribute_names": [attributeNameMR],
                    "attribute_values": [attributeValueMR]
                };

                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    var duplicated = editedArray;
                    duplicated.map((item, ix) => {
                        duplicated[ix].attribute_key_values = []
                        item.attribute_names?.map((single, ix2) => {
                            temp = {};
                            temp.attribute_type = 'Attribute';
                            temp.attribute_name = single;
                            temp.attribute_value = duplicated[ix] && duplicated[ix]['attribute_values'] && duplicated[ix]['attribute_values'][ix2] ? duplicated[ix]['attribute_values'][ix2].toString() : '';
                            if (temp.attribute_value > 0)
                                duplicated[ix].attribute_key_values.push(temp);
                        })
                    })

                    props.onChange(duplicated)
                    localStorage.removeItem('editableRuleMode');
                } else {

                    setCalculationStore([...calculationStore, data]);
                    var duplicated = [...calculationStore, data];
                    duplicated.map((item, ix) => {
                        duplicated[ix].attribute_key_values = []
                        item.attribute_names?.map((single, ix2) => {
                            temp = {};
                            temp.attribute_type = 'Attribute';
                            temp.attribute_name = single;
                            temp.attribute_value = duplicated[ix] && duplicated[ix]['attribute_values'] && duplicated[ix]['attribute_values'][ix2] ? duplicated[ix]['attribute_values'][ix2].toString() : '';
                            duplicated[ix].attribute_key_values.push(temp);
                        })
                    })
                    props.onChange([...calculationStore, data])
                }
                handleRemove();
            } else {
                toast.error("Please fill all the mandatory fields.");
                if (!keyfigurename || keyfigurename === '')
                    setKeyfigurename(false);
                if (!calculation || calculation === '')
                    setCalculation(false);
                if (!effectiveStartDate || effectiveStartDate === '' || effectiveStartDate === 'null')
                    setEffectiveStartDate(false);
                if (!effectiveEndDate || effectiveEndDate === '' || effectiveEndDate === 'null')
                    setEffectiveEndDate(false);
                if (!rate || rate === '')
                    setRate(false);
                if (!uom || uom === '')
                    setUom(false);
            }

        }
        else if (type === 'Quota') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyfigurename)])
            keyfigArr.push(keyfigurename);
            operationArray && operationArray.forEach(function (item, index) {
                keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });
            if ((keyfigurename)
                && effectiveStartDate && effectiveEndDate) {
                var data = {
                    "key_figure_names": keyfigArr,
                    "incentive_uom": uom,
                    "effective_start_date": effectiveStartDate,
                    "effective_end_date": effectiveEndDate,
                    "timing": timingRow,
                    "operations": opArr,
                    "rate_type": rateType,
                };

                if (id || id === 0) {
                    var editedArray = calculationStore;
                    calculationStore[id] = data;
                } else {
                    setCalculationStore([...calculationStore, data]);
                }

                var APIArray = [];
                [...calculationStore, data].map(item => {
                    item.timing && item.timing.forEach(function (single) {
                        var dataScaleAPI = {
                            "rate": parseFloat(single.rate),
                            "key_figure_names": item.key_figure_names,
                            "incentive_uom": item.incentive_uom,
                            "effective_start_date": item.effective_start_date,
                            "effective_end_date": item.effective_end_date,
                            "operations": item.operations,
                            "attribute_values": [parseFloat(single.from).toString()],
                            "rate_type": item.rate_type
                        };
                        APIArray.push(dataScaleAPI);
                    })

                })
                var duplicated = APIArray;
                duplicated.map((item, ix) => {
                    duplicated[ix].attribute_key_values = []
                    item.attribute_values?.map((single, ix2) => {
                        temp = {};
                        temp.attribute_type = 'Key Figure';
                        temp.attribute_name = 'attainment_percentage';
                        temp.attribute_value = parseFloat(single);
                        duplicated[ix].attribute_key_values.push(temp);
                    })
                    delete duplicated[ix]['attribute_values'];
                })
                props.onChange(duplicated);
            }
            handleRemoveQuota();
        }
        else if (type === 'Tiered Volume - Simple') {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(keyFigureNameScale)])
            keyfigArr.push(keyFigureNameScale);

            operationArray && operationArray.forEach(function (item, index) {
                keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });

            if ((keyFigureNameScale || formulaName)
                && startDateScale && endDateScale) {

                var data = {
                    "key_figure_names": formulaTypeTier === 'Formula' ? [] : keyfigArr,
                    "incentive_uom": uomScale,
                    "rate_type": calculationTypeTier === 'Rebate' ? '' : rateTypeScale,
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "timing": timingRow,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "type": calculationTypeTier,
                    "operations": opArr,
                    //"maximum_amount": maximumAmount ? parseFloat(maximumAmount) : 0
                };
                var duplicateArray = []
                if (id || id === 0) {
                    var editedArray = calculationStore;
                    calculationStore[id] = data;
                    duplicateArray = calculationStore
                } else {
                    setCalculationStore([...calculationStore, data]);
                    duplicateArray = [...calculationStore, data]
                }
                var APIArray = [];
                duplicateArray.map(item => {
                    if (item.type === 'Rebate') {
                        if (item.formula_id) {
                            var dataScaleAPI = {
                                "formula_id": parseInt(item.formula_id),
                                "formula_type": 'Formula',
                                "effective_start_date": item.effective_start_date,
                                "effective_end_date": item.effective_end_date,
                                "type": item.type,
                                "operations": item.operations,
                                "rate_type": item.type === 'Rebate' ? '' : item.rate_type
                            };
                            APIArray.push(dataScaleAPI);
                        } else {
                            var attributeKey = []
                            var temp = {}
                            item.key_figure_names?.map((key, ix2) => {
                                temp = {};
                                temp.attribute_type = 'Key Figure';
                                temp.attribute_name = key;
                                temp.attribute_value = 0;
                                attributeKey.push(temp);
                            })
                            //attributeKey.push({ 'priority': '' })
                            var dataScaleAPI = {
                                "key_figure_names": item.key_figure_names,
                                "effective_start_date": item.effective_start_date,
                                "effective_end_date": item.effective_end_date,
                                "formula_type": 'Key Figure',
                                "type": item.type,
                                "operations": item.operations,
                                "attribute_key_values": attributeKey

                            };
                            APIArray.push(dataScaleAPI);
                        }
                    } else {

                        if (item.formula_id) {
                            item.timing && item.timing.forEach(function (single) {
                                var dataScaleAPI = {
                                    "rate": parseFloat(single.rate),
                                    "formula_id": parseInt(item.formula_id),
                                    "formula_type": 'Formula',
                                    "incentive_uom": item.incentive_uom,
                                    "rate_type": item.type === 'Rebate' ? '' : item.rate_type,
                                    "effective_start_date": item.effective_start_date,
                                    "effective_end_date": item.effective_end_date,
                                    "type": item.type,
                                    "operations": item.operations,
                                    //"maximum_amount": parseFloat(item.maximum_amount),
                                    "maximum_amount": parseFloat(single.maximum_amount),
                                    "attribute_key_values": [
                                        {
                                            'attribute_type': 'Formula',
                                            'attribute_name': item.formula_id.toString(),
                                            'attribute_value': single.from ? Number(single.from) : 0

                                        },
                                        //  {
                                        //     'priority': ''
                                        // }
                                    ]
                                };
                                APIArray.push(dataScaleAPI);
                            })
                        } else {
                            item.timing && item.timing.forEach(function (single) {
                                var attributeKey = []
                                var temp = {}
                                item.key_figure_names?.map((key, ix2) => {
                                    temp = {};
                                    temp.attribute_type = 'Key Figure';
                                    temp.attribute_name = key;
                                    temp.attribute_value = single.from ? Number(single.from) : 0;
                                    attributeKey.push(temp);
                                })
                                //attributeKey.push({ 'priority': '' })
                                var dataScaleAPI = {
                                    "attribute_names": item.key_figure_names,
                                    "rate": parseFloat(single.rate),
                                    "key_figure_names": item.key_figure_names,
                                    "incentive_uom": item.incentive_uom,
                                    "rate_type": item.type === 'Rebate' ? '' : item.rate_type,
                                    "effective_start_date": item.effective_start_date,
                                    "effective_end_date": item.effective_end_date,
                                    "formula_type": 'Key Figure',
                                    "type": item.type,
                                    "operations": item.operations,
                                    "attribute_key_values": attributeKey,
                                    //"maximum_amount": parseFloat(item.maximum_amount),
                                    "maximum_amount": parseFloat(single.maximum_amount),
                                };
                                APIArray.push(dataScaleAPI);
                            })
                        }
                    }

                })
                APIArray.sort((a, b) => (a.type.localeCompare(b.type) || a.attribute_values && b.attribute_values && (parseFloat(a.attribute_values) - parseFloat(b.attribute_values)) || a.rate - b.rate || new Date(a.effective_start_date) - new Date(b.effective_start_date)))
                var newCR = [];
                var temp = {};
                //var priority = 0
                // APIArray.sort((a, b) => (a.attribute_key_values && a.attribute_key_values[0] && a.attribute_key_values[0]['attribute_value']) > (b.attribute_key_values && b.attribute_key_values[0] && b.attribute_key_values[0]['attribute_value']) ? 1 : -1)
                //     .map((item, ix) => {
                //         if (item.attribute_key_values && item.attribute_key_values.length > 0 && type != 'Rebate') {
                //             priority = priority + 1
                //             APIArray[ix]['attribute_key_values'][item.attribute_key_values.length - 1] = { 'priority': priority }
                //         }
                //     })
                // APIArray.attribute_key_values = newCR;
                props.onChange(APIArray);

            }
            handleRemoveScale();
        }

        else if (type === 'Multi Axis') {

            var keyfigArr = [];
            var keyfigValArr = [];
            var calcStor = calculationStore;
            var data = {}
            if (calculationTypeTier === 'Rebate') {
                data = {
                    "key_figure_names": formulaTypeTier === 'Formula' ? [] : [keyFigureNameScale],
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "type": "Rebate",
                    "rate_type": '%',
                    "formula_type": formulaTypeTier,
                };
            } else {
                data = {
                    "key_figure_values": keyFigureArrayMXS,
                    "attribute_values": attributeArrayMXS,
                    "formula_values": formulaArrayMXS,
                    "rate": parseFloat(rateScale),
                    "effective_start_date": startDateScale,
                    "effective_end_date": endDateScale,
                    "type": "Tier",
                    "rate_type": '%',
                    'maximum_amount': maximumAmount ? parseFloat(maximumAmount) : 0,
                    'attribute_name': attributeNameMR,
                    'attribute_count': attributeCount ? parseFloat(attributeCount) : 0
                    //"key_fig_api": keyFigureArrayMXSAPI,
                    //"keyFigureArrayMXS": keyFigureArrayMXSAPI,
                    //"attributeArrayMXS": attributeArrayMXSAPI,
                    //"formulaArrayMXS": formulaArrayMXSAPI,
                };
            }
            var duplicateArray = []
            if (id || id === 0) {
                var editedArray = calculationStore;
                duplicateArray = calculationStore;
                calculationStore[id] = data;
            } else {
                setCalculationStore([...calculationStore, data]);
                duplicateArray = [...calculationStore, data]
            }
            var apiArray = [];
            var apiArray = [];
            var tempKF = 0;
            var tempA = 0;
            var tempF = 0;
            duplicateArray.map((itemMain, index) => {
                if (itemMain.key_figure_values && itemMain.key_figure_values.length > tempKF) {
                    tempKF = itemMain.key_figure_values.length
                    setKeyFigureArrayMXSColumns(itemMain.key_figure_values.length);
                }
                if (itemMain.attribute_values && itemMain.attribute_values.length > tempA) {
                    tempA = itemMain.attribute_values.length
                    setAttributeArrayMXSColumns(itemMain.attribute_values.length);
                }
                if (itemMain.formula_values && itemMain.formula_values.length > tempF) {
                    tempF = itemMain.formula_values.length
                    setFormulaArrayMXSColumns(itemMain.formula_values.length);
                }

                if (itemMain.type === "Tier") {
                    var tempKFN = [];
                    var tempCondition = []

                    itemMain.key_figure_values && itemMain.key_figure_values.map(item => {
                        tempKFN.push({ 'attribute_name': item.key, 'attribute_value': item.value ? parseFloat(item.value) : 0, 'attribute_type': item.type })
                    })
                    itemMain.attribute_values && itemMain.attribute_values.map(item => {
                        tempKFN.push({ 'attribute_name': item.attribute_names, 'attribute_value': item.attribute_values, 'attribute_type': item.type })
                    })
                    itemMain.formula_values && itemMain.formula_values.map(item => {
                        tempKFN.push({ 'attribute_name': item.formula_names ? item.formula_names.toString() : '', 'attribute_value': item.formula_values ? parseInt(item.formula_values) : 0, 'attribute_type': item.type })

                    })
                    if (props.incentiveRateType === 'Conditional Attribute') {
                        tempCondition.push({
                            "attribute_name": itemMain.attribute_name,
                            "attribute_type": "Counter",
                            "attribute_value": itemMain.attribute_count,
                            "attribute_conditions": tempKFN
                        },
                            // { 'priority': index + 1 }
                        )
                    }
                    // else
                    //     tempKFN.push({ 'priority': index + 1 })
                    var temp = {
                        "attribute_key_values": props.incentiveRateType === 'Conditional Attribute' ? tempCondition : tempKFN,
                        "rate": parseFloat(itemMain.rate),
                        "effective_start_date": itemMain.effective_start_date,
                        "effective_end_date": itemMain.effective_end_date,
                        "type": "Tier",
                        "rate_type": '%',
                        'maximum_amount': parseFloat(itemMain.maximum_amount)
                    }
                    apiArray.push(temp)
                } else {
                    apiArray.push(itemMain)

                }
                // var withIndex = apiArray;
                // withIndex.map((itemIndex, ix) => {
                //     withIndex[ix]['attribute_name'] = (ix + 10).toString();
                // })
                props.onChange(apiArray);
            })


            handleRemoveMXS();
        }
        else {
            var keyfigArr = [];
            var keyfigArrAPI = [];
            var opArr = [];
            keyfigArr.push(keyfigurename);
            keyfigArrAPI.push(keyFigureNameAPI);;
            operationArray && operationArray.forEach(function (item, index) {
                keyfigArrAPI.push(keyFigureKeyArray[keyFigureData.indexOf(item['key_figure_names'])])
                keyfigArr.push(item['key_figure_names']);
                opArr.push(item['operation']);
            });

            if (effectiveStartDate && effectiveEndDate && rate) {
                var data = {
                    "type": calculation,
                    "effective_start_date": effectiveStartDate,
                    "effective_end_date": effectiveEndDate,
                    "rate": parseFloat(rate),
                    "incentive_uom": uom,
                    "rate_type": rateType,
                    "enabled": true,
                    // "key_figure_names": keyfigArr,
                    "operations": opArr,
                    "formula_id": formulaTypeTier === 'Formula' ? formulaName : null,
                    "formula_type": formulaTypeTier
                };
                if (props.calculationMethod === 'Price Point') {
                    data.formula_id = formulaName
                } else {
                    data.key_figure_names = formulaTypeTier !== 'Formula' ? keyfigArr : []
                }
                if (id || id === 0) {
                    var editedArray = [...calculationStore];
                    editedArray[editIndex] = data;
                    setCalculationStore(editedArray);
                    props.onChange(editedArray)
                    localStorage.removeItem('editableRuleMode');
                } else {
                    var duplicated = [...calculationStore, data];

                    setCalculationStore([...calculationStore, data]);
                    props.onChange(duplicated)
                }
                handleRemove();
            } else {
                toast.error("Please fill all the mandatory fields.");
                if (!keyfigurename || keyfigurename === '')
                    setKeyfigurename(false);
                if (!calculation || calculation === '')
                    setCalculation(false);
                if (!effectiveStartDate || effectiveStartDate === '' || effectiveStartDate === 'null')
                    setEffectiveStartDate(false);
                if (!effectiveEndDate || effectiveEndDate === '' || effectiveEndDate === 'null')
                    setEffectiveEndDate(false);
                if (!rate || rate === '')
                    setRate(false);
                if (!uom || uom === '')
                    setUom(false);
            }
        }
    }
    function handleRemove() {
        setEditIndex('');
        setEditMode(false);
        setKeyfigurename('');
        setCalculation('');
        if (effectiveStartDate != props.startDate)
            setEffectiveStartDate(null);
        if (effectiveEndDate != props.endDate)
            setEffectiveEndDate(null);
        setRate('');
        setUom('');
        setRateType('');
        setOperationArray([]);
        setAttributeNameMR('');
        setAttributeValueMR('');
        setFormulaName('')
        setFormulaTypeTier('')
    }
    function handleRemoveCB() {
        setEditIndex('');
        setEditMode(false);
        setEditIndex('');
        setAttributeNameCB('');
        setAttributeValueCB('');
        setAcquisitionPriceCB('');
        setContractPriceCB('');
        setUomCB('');
        if (props.calculationMethod != 'Price Difference')
            setRateTypeCB('');

        if (startDateCB != props.startDate)
            setStartDateCB(null);
        if (endDateCB != props.endDate)
            setEndDateCB(null);

    }
    function handleRemoveMR() {
        setAttributeNameMR('');
        setAttributeValueMR('');
        setKeyFigureNameMR('');
        setRateMR('');
        setUomMR('');
        setRateTypeMR('');
        if (startDateMR != props.startDate)
            setStartDateMR(null);
        if (endDateMR != props.endDate)
            setEndDateMR(null);
        setEditIndex('');
        setEditMode(false);
        setFormulaName('')
        setFormulaTypeTier('')
    }
    function handleRemoveScale() {
        setKeyFigureNameScale('');
        setUomScale('');
        setRateTypeScale('');
        if (startDateScale != props.startDate)
            setStartDateScale(null);
        if (endDateScale != props.endDate)
            setEndDateScale(null);
        setEditIndex('');
        setEditMode(false);
        setTimingRow([]);
        setTimingData([]);
        setCalculationTypeTier('');
        setFormulaTypeTier('');
        setFormulaName('');
        setOperationArray([]);
        setMaximumAmount('')

    }
    function handleRemoveMXS() {
        setRateScale('');
        setKeyFigureNameScale('');
        setUomScale('');
        setRateTypeScale('');
        setEditIndex('');
        setEditMode(false);
        setTimingRow([{}]);
        setFormulaName('');
        setOperationArray([]);
        setKeyFigureArrayMXS([]);
        setAttributeArrayMXS([]);
        setFormulaArrayMXS([]);
        setKeyFigureArrayMXSAPI([])
        setAttributeArrayMXSAPI([])
        setFormulaArrayMXSAPI([])
        setCalculationTypeTier('');
        setMaximumAmount('');
        if (startDateScale != props.startDate)
            setStartDateScale(null);
        if (endDateScale != props.endDate)
            setEndDateScale(null);
        setAttributeNameMR('');
        setAttributeCount('')
    }
    function handleRemoveQuota() {
        setKeyfigurename('');
        setUom('');
        setRateType('');
        setRate('');
        setTimingRow([]);
        setTimingData([]);
        if (startDateScale != props.startDate)
            setEffectiveStartDate(null);
        if (endDateScale != props.endDate)
            setEffectiveEndDate(null);
        setEditIndex('');
        setEditMode(false);
        setOperationArray([]);
        setKeyFigureData([]);
    }

    const handleAddOperation = (type) => {
        if (type == 'keyFigure') {
            var data = {
                //key_figure_names: "",
                //key_figure_values: "",
                key: '',
                value: '',
                type: 'Key Figure'
            };
            setKeyFigureArrayMXS([...keyFigureArrayMXS, data]);
            setKeyFigureArrayMXSAPI([...keyFigureArrayMXSAPI, data]);
        } else if (type === 'attribute') {
            var data = {
                attribute_names: "",
                attribute_values: "",
                type: 'Attribute'
            };
            setAttributeArrayMXS([...attributeArrayMXS, data]);
            setAttributeArrayMXSAPI([...attributeArrayMXSAPI, data]);
        } else if (type === 'formula') {
            var data = {
                formula_names: "",
                formula_values: "",
                type: 'Formula'
            };
            setFormulaArrayMXS([...formulaArrayMXS, data]);
            setFormulaArrayMXSAPI([...formulaArrayMXSAPI, data]);
        } else {
            var data = {
                key_figure_names: "",
                operation: ""
            };
            setOperationArray([...operationArray, data]);
        }

    }
    const handleOperationChange = (e, index, type, name) => {
        console.log(e)
        if (type == 'keyFigure') {
            const { name, value } = e.target;
            const l = [...keyFigureArrayMXS];
            l[index][name] = value;
            if (name == 'key') {
                l[index]['value'] = ''
            }
            setKeyFigureArrayMXS(l);
            setKeyFigureArrayMXSAPI(l);
        } else if (type === 'attribute') {
            const list = [...attributeArrayMXS];
            var item = ''
            if (name) {
                list[index][name] = e;
                item = e
            }
            else {
                const { name, value } = e.target;
                list[index][name] = value;
                if (name == 'attribute_names') {
                    list[index]['attribute_values'] = ''
                }
                item = value
                addAttributeValue(value)
            }

            // if ((item === 'customer_number' || item == 'bill_to_party' || item == 'sold_to_party')
            //     && activeDropdown && activeDropdown['customer_number']) {
            //     props.getCustomerMasterData();
            // }
            // else if (activeDropdown && activeDropdown[item]) {
            //     if (item === 'material_number') {
            //         props.getMaterialMasterData();
            //     } else if (item === 'supplier_number') {
            //         props.getSupplierMasterData();
            //     }
            //     else
            //         props.getEmployeeData()
            // }

            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);
        } else if (type === 'formula') {
            const { name, value } = e.target;
            const list = [...formulaArrayMXS];
            list[index][name] = value;
            if (name == 'formula_names') {
                list[index]['formula_values'] = ''
            }
            setFormulaArrayMXS(list);
            setFormulaArrayMXSAPI(list);
        }
        else if (type === 'attr') {
            const list = [...attributeArrayMXS];
            var val = e
            if (e && e.length > 0) {
                var i = e.indexOf('-');
                if (i != -1)
                    val = e.substring(0, i - 1)
            }
            list[index][name] = val;
            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);
        }
        else {
            const { name, value } = e.target;
            const list = [...operationArray];
            list[index][name] = value;
            setOperationArray(list);
        }
    };
    function addAttributeValue(value) {
        if (value == 'customer_number' || value == 'bill_to_party' || value == 'sold_to_party') {
            setAttributeValuesList(attributeValuesArray)
            setAttributeNameList(customerNameListArray)
        }
        if (value == 'material_number') {
            setAttributeValuesList(attributeValuesArrayMaterialNumber)
            setAttributeNameList(materialNameListArray)
        }
        if (value == 'supplier_number') {
            setAttributeValuesList(supplierMasterArrayMaterialNumber)
            setAttributeNameList(supplierNameArray)
        }
        if (value == 'employee_number') {
            setAttributeValuesList(employeeMasterArrayMaterialNumber)
            setAttributeNameList(employeeNameListArray)
        }
    }
    function hasNull(element, index, array) {
        return Object.keys(element).some(
            function (key) {
                return element[key] === "";
            }
        );
    }
    const handleRemoveOperation = (index, type) => {
        if (type === 'keyFigure') {
            const list = [...keyFigureArrayMXS];
            list.splice(index, 1);
            setKeyFigureArrayMXS(list);
            setKeyFigureArrayMXSAPI(list);
        } else if (type === 'attribute') {
            const list = [...attributeArrayMXS];
            list.splice(index, 1);
            setAttributeArrayMXS(list);
            setAttributeArrayMXSAPI(list);
        } else if (type === 'formula') {
            const list = [...formulaArrayMXS];
            list.splice(index, 1);
            setFormulaArrayMXS(list);
            setFormulaArrayMXSAPI(list);
        } else {
            const list = [...operationArray];
            list.splice(index, 1);
            setOperationArray(list);
        }
    };
    const handleCancelTimingDialog = () => {
        setOpenTiming(false);
        setTimingRow([]);
    }
    const setTimingDone = () => {
        setOpenTiming(false);
        setTimingData([...timingData, ...timingRow])
    }


    const [formulaName, setFormulaName] = React.useState('');

    const [keyValuePairObjectKeyFigure, setKeyValuePairObjectKeyFigure] = React.useState([]);
    useEffect(() => {
        var tempObj = {};
        if (props.dropdownData && props.dropdownData.records && props.formFieldsAllData && props.formFieldsAllData.records) {
            props.dropdownData.records.map((item1) => {
                if (item1.field_id === 'key_figure_name') {
                    item1.drop_down_value_keys && item1.drop_down_value_keys.length > 0 && item1.drop_down_value_keys.map((item3) => {
                        props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item4) => {
                            if (item3 === item4.field_id) {
                                tempObj[item4.field_id] = item4.name;
                            }
                        })
                    })
                }
            })
        }
        setKeyValuePairObjectKeyFigure(tempObj);
    }, [props.formFieldsAllData, props.dropdownData]);

    const [attributeNameObjectKeyFigure, setAttributeNameObjectKeyFigure] = React.useState([]);
    useEffect(() => {
        var tempObj = {};
        if (props.dropdownData && props.dropdownData.records && props.formFieldsAllData && props.formFieldsAllData.records) {
            props.dropdownData.records.map((item1) => {
                if (item1.field_id === 'attribute_name') {
                    item1.drop_down_value_keys.map((item3) => {
                        props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item4) => {
                            if (item3 === item4.field_id) {
                                tempObj[item4.field_id] = item4.name;
                            }
                        })
                    })
                }
            })
        }
        setAttributeNameObjectKeyFigure(tempObj);
    }, [props.formFieldsAllData, props.dropdownData]);



    useEffect(() => {
        if (props.sourceDataType) {
            props.getDefaultValuesAllDataCR('key_figure_name', props.sourceDataType, sessionStorage.getItem('application'));
            props.onLoadingDefaultCR(sessionStorage.getItem('application'), props.sourceDataType);
            // props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), props.sourceDataType)
        }
    }, [props.sourceDataType])

    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setKeyFigureKeyArray(props.dropdownDataCR.records[0].drop_down_value_keys)
                setKeyFigureData(props.dropdownDataCR.records[0].drop_down_value_keys);
                setKeyFigureObject(props.dropdownDataCR.records[0])
                setMappingValuesSet(props.dropdownDataCR.records[0].label_names);
            }
        }
    }, [props.dropdownDataCR])

    const handleCalculationTypeTier = (e, type) => {
        setUomScale('');
        setCalculationTypeTier(e.target.value);
        if (type == 'Multi Axis' && e.target.value == 'Tier') {
            const index = calculationStore.map(el => el.type).lastIndexOf("Tier");
            if (index > -1) {
                editcalculationRule(calculationStore[index], -1, 'Multi Axis')
                setEditMode(false)
            }
        }
    }
    const filter = createFilterOptions()
    const filterOptions = createFilterOptions({
        stringify: (option) => customerNameListArray[attributeValuesArray.indexOf(option)] + option
    });

    const [attributeValuesList, setAttributeValuesList] = React.useState([]);
    const [attributeNameList, setAttributeNameList] = React.useState([]);

    const [attributeValuesArray, setAttributeValuesArray] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            var customerTempArray = []
            props.customerMasterData.records.map((item) => {
                tempArray.push(item.customer_number);
                tempNameArray.push(item.customer_name);
                customerTempArray.push(item.customer_name)
            })
            setAttributeValuesArray(tempArray);
            setCustomerNameListArray(tempNameArray);
        }
    }, [props.customerMasterData])
    const [attributeValuesArrayMaterialNumber, setAttributeValuesArrayMaterialNumber] = React.useState([]);
    const [employeeMasterArrayMaterialNumber, setEmployeeMasterArrayMaterialNumber] = React.useState([]);
    const [supplierMasterArrayMaterialNumber, setSupplierMasterArrayMaterialNumber] = React.useState([]);
    const [employeeNameListArray, setEmployeeNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.employeeMasterData.records.map((item) => {
                tempArray.push(item.employee_number);
                tempNameArray.push(item.employee_name);
            })
            setEmployeeMasterArrayMaterialNumber(tempArray)
            setEmployeeNameListArray(tempNameArray);
        }
    }, [props.employeeMasterData])

    const [materialNameListArray, setMaterialNameListArray] = React.useState([]);
    useEffect(() => {
        if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.materialMasterData.records.map((item) => {
                tempArray.push(item.material_number);
                tempNameArray.push(item.material_description);
            })
            setAttributeValuesArrayMaterialNumber(tempArray)
            setMaterialNameListArray(tempNameArray);
        }
    }, [props.materialMasterData])
    const [supplierNameArray, seSupplierNameArray] = React.useState([]);
    useEffect(() => {
        if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0) {
            var tempArray = []
            var tempNameArray = []
            props.supplierMasterData.records.map((item) => {
                tempArray.push(item.supplier_number);
                tempNameArray.push(item.supplier_name);
            })
            setSupplierMasterArrayMaterialNumber(tempArray)
            seSupplierNameArray(tempNameArray)
        }
    }, [props.supplierMasterData])


    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0) {
            setLabelNewAPIData(props.labelNewAPIData)
        }
    }, [props.labelNewAPIData])

    function checkLabel(name) {
        if (Object.keys(labelNewAPIData).length > 0) {
            var data = Object.entries(labelNewAPIData).filter(([key, value]) => key == name).map(([key, value]) => value.current)
            if (data && data.length > 0)
                return data[0]
            else
                return false

        }

    }
    const handleDrop = (event) => {
        setCurrentFileName(event.target.files[0].name);
        const data = new FormData();
        data.append('file', event.target.files[0]);
        setCurrentFile(data);
        props.uploadCalculationRules(data, props.calculationMethod, sessionStorage.getItem('application'), props.sourceDataType);
        setCurrentFile(undefined);
        setCurrentFileName('');
        event.target.value = '';
    }
    function uploadMX() {
        inputFile.current.click();
    }
    useEffect(() => {
        if (props.uploadedRules && props.uploadedRules.data) {
            console.log(calculationStore)
            var priority = 1
            var x = props.uploadedRules.data;
            x.forEach(function (v) { delete v.ID });
            var calcRules = [...calculationStore, ...props.uploadedRules.data]
            console.log(calculationStore)
            calcRules && calcRules
                .map((itemMain, index) => {
                    var tempKF = 0;
                    var tempA = 0;
                    var tempF = 0;
                    var tempKFArr = []
                    var tempAArr = [];
                    var tempFArr = [];
                    itemMain.type === 'Tier' && itemMain.attribute_key_values && itemMain.attribute_key_values.map((it, i) => {
                        if (it.attribute_type === 'Key Figure') {
                            tempKF = tempKF + 1;
                            var tempA = {
                                key: it.attribute_name,
                                value: it.attribute_value,
                                type: it.attribute_type
                            }
                            tempKFArr.push(tempA);
                        } else if (it.attribute_type === 'Attribute') {
                            tempA = tempA + 1;
                            var tempB = {
                                attribute_names: it.attribute_name,
                                attribute_values: it.attribute_value,
                                type: it.attribute_type
                            }
                            tempAArr.push(tempB);
                        } else {
                            if (it.attribute_type === 'Formula') {
                                tempF = tempF + 1;
                                var tempC = {
                                    formula_names: it.attribute_name ? parseInt(it.attribute_name) : -1,
                                    formula_values: it.attribute_value,
                                    type: it.attribute_type
                                }
                                tempFArr.push(tempC);
                            }
                        }
                        // if (it.priority) {
                        //     calcRules[index]['attribute_key_values'][i]['priority'] = priority
                        //     priority++
                        // }
                    })
                    calcRules[index]['key_figure_values'] = calcRules[index]['key_figure_values'] ? [...calcRules[index]['key_figure_values'], ...tempKFArr] : tempKFArr;
                    calcRules[index]['attribute_values'] = calcRules[index]['attribute_values'] ? [...calcRules[index]['attribute_values'], ...tempAArr] : tempAArr;
                    calcRules[index]['formula_values'] = calcRules[index]['formula_values'] ? [...calcRules[index]['formula_values'], ...tempFArr] : tempFArr;

                })
            console.log(calcRules)
            setCalculationStore(calcRules)
            props.onChange(calcRules)
            calcRules.map(itemMain => {
                var tempKL = 0;
                var tempAL = 0;
                var tempFL = 0;
                if (itemMain.key_figure_values && itemMain.key_figure_values.length > tempKL) {
                    setKeyFigureArrayMXSColumns(itemMain.key_figure_values.length);
                }
                if (itemMain.attribute_values && itemMain.attribute_values.length > tempAL) {
                    setAttributeArrayMXSColumns(itemMain.attribute_values.length);
                }
                if (itemMain.formula_values && itemMain.formula_values.length > tempFL) {
                    setFormulaArrayMXSColumns(itemMain.formula_values.length);
                }
            })
        }
    }, [props.uploadedRules])

    return (
        <div>
            {/* add timing dialog */}
            <Dialog open={openTiming}>
                <DialogContent classes={{
                    root: classes.dialogContent
                }}>
                    <Typography variant="h4">
                        <DialogContentText>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableCell align='center'>From(Qty/Value/%)</TableCell>
                                        <TableCell align='center'>Rate(%)</TableCell>
                                        {props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate' &&
                                            <TableCell align='center'>Maximum Amount</TableCell>}
                                        <TableCell align='center'></TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {timingRow && timingRow.length > 0 && timingRow.map((item, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>
                                                        <OutlinedInput
                                                            name={'from'}
                                                            value={timingRow[index].from}
                                                            classes={{ root: classes.inputTwoLineAddTiming }}
                                                            onChange={(e) => (handleScaleDate(e, index))} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <OutlinedInput
                                                            name={'rate'}
                                                            value={timingRow[index].rate}
                                                            classes={{ root: classes.inputTwoLineAddTiming }}
                                                            onChange={(e) => (handleScaleDate(e, index))} />
                                                    </TableCell>
                                                    {props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate' &&
                                                        <TableCell>
                                                            <OutlinedInput
                                                                name={'maximum_amount'}
                                                                value={timingRow[index].maximum_amount}
                                                                classes={{ root: classes.inputTwoLineAddTiming }}
                                                                onChange={(e) => (handleScaleDate(e, index))} />
                                                        </TableCell>}
                                                    <TableCell>
                                                        <Button
                                                            onClick={handleRemoveSpecificRow(index)}
                                                            variant="contained" color="secondary"
                                                            disabled={Object.keys(timingRow).length > 1 ? false : true}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button onClick={handleAddRow} variant="contained" color="primary" size="small" style={{ marginTop: 10 }}>
                                Add Row
                            </Button>
                        </DialogContentText>
                    </Typography>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.dialog
                }}>
                    <Button onClick={handleCancelTimingDialog} autoFocus color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <Button onClick={setTimingDone} color="primary" autoFocus variant="contained"
                        disabled={!(timingRow.length > 0 && timingRow.filter(e => (e.rate == '' || e.from == '' || (props.maximumAmountPeriod === 'Payment Period - Tier' && e.maximum_amount == ''))).length == 0) ? true : false}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            {/* calculation Rules Add */}
            <div
                className={clsx(classes.root, className)}>
                {props.calculationMethod === 'Price Difference' ?

                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>
                                <Grid container spacing={1} >
                                    <Grid
                                        container
                                        md={4}
                                        style={{ width: '100%', paddingTop: 5 }}
                                        spacing={1}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.chargeBackLabel}>
                                                    Attribute Name
                                                </FormLabel>
                                                <OutlinedInput
                                                    value={'Material Number'}
                                                    classes={{ root: classes.inputTwoLine }}
                                                    style={{ fontSize: 13 }}
                                                    disabled
                                                />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={7}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.chargeBackLabel}>
                                                    Attribute Value
                                                </FormLabel>
                                                <div style={{ marginTop: -10 }}>
                                                    <SearchDropdown id='material_single'
                                                        table={'material_master'}
                                                        keyName={'material_number'}
                                                        description={true}
                                                        onChange={(value) => setAttributeValueCB(value)}
                                                        prevalue={attributeValueCB}
                                                    />
                                                </div>

                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        style={{ width: '100%' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel
                                                required={currentApplication === 'Pricing' ? false : true}
                                                className={classes.chargeBackLabel}>
                                                Acquisition Price
                                            </FormLabel>
                                            <OutlinedInput
                                                value={acquisitionPriceCB}
                                                type={'number'}
                                                inputProps={{ min: "0", step: "1" }}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleAcquisitionPriceCB} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        style={{ width: '100%' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Contract Price
                                            </FormLabel>
                                            <OutlinedInput
                                                value={contractPriceCB}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={handleContractPriceCB} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        style={{ width: '100%' }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel required className={classes.chargeBackLabel}>
                                                Rate Type
                                            </FormLabel>
                                            <Select
                                                value={rateTypeCB}
                                                onChange={(e) => setRateTypeCB(e.target.value)}
                                                displayEmpty
                                                disabled={props.calculationMethod === 'Price Difference' ? true : false}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                <MenuItem value="" style={{ height: 30 }}>
                                                </MenuItem>
                                                {uomData.map((item) => {
                                                    return (
                                                        <MenuItem value={item.key} key={item.key}>
                                                            {item.value}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    {rateTypeCB === '$' &&
                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel className={classes.chargeBackLabel}>
                                                    UoM
                                                </FormLabel>
                                                <Select
                                                    value={uomCB}
                                                    onChange={handleUomCB}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map(item => {
                                                        return (
                                                            <MenuItem value={item} key={item}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </Grid>
                                    }
                                    <Grid
                                        item
                                        md={3}
                                        style={{ width: '100%' }}
                                        container
                                        spacing={1}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.chargeBackLabel}>
                                                    Start Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={startDateCB}
                                                            onChange={date => handleStartDateCB(date)}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={props.startDate ? props.startDate : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            style={{ width: '100%' }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.chargeBackLabel}>
                                                    End Date
                                                </FormLabel>
                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                    <Grid container justifyContent="space-around">
                                                        <DatePicker
                                                            disableToolbar
                                                            clearable
                                                            InputProps={{
                                                                padding: 0,
                                                                disableUnderline: true,
                                                                style: {
                                                                    padding: '1px 10 1px 11px',
                                                                    alignSelf: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #E0E0E0',
                                                                    width: '100%'
                                                                }
                                                            }}
                                                            value={endDateCB}
                                                            onChange={date => handleEndDateCB(date)}
                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                            format="MM/DD/YYYY"
                                                            minDate={startDateCB ? startDateCB : ''}
                                                        />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={1}
                                        xs={12}
                                    >
                                        <Grid container style={{ paddingTop: 54 }} >
                                            {/* <Grid item md={6} > */}
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={(!contractPriceCB || !attributeValueCB ||
                                                        (currentApplication === 'Pricing' ? null : (!acquisitionPriceCB))
                                                        || !(startDateCB && Moment(startDateCB).isValid() && endDateCB && (Moment(endDateCB).isValid()) && (Moment(endDateCB) >= Moment(startDateCB)) && (props.startDate ? Moment(startDateCB) >= Moment(props.startDate) : true))
                                                        || !rateTypeCB)
                                                        ? true : false}
                                                    onClick={() => handleSubmit(editIndex, 'CB')}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={(!contractPriceCB || !attributeValueCB ||
                                                        (currentApplication === 'Pricing' ? null : (!acquisitionPriceCB))
                                                        || !(startDateCB && Moment(startDateCB).isValid() && endDateCB && (Moment(endDateCB).isValid()) && (Moment(endDateCB) >= Moment(startDateCB)) && (props.startDate ? Moment(startDateCB) >= Moment(props.startDate) : true))
                                                        //!(startDateCB && Moment(startDateCB).isValid())|| !(endDateCB && (Moment(endDateCB).isValid()))
                                                        || !rateTypeCB)
                                                        ? true : false}
                                                    onClick={() => handleSubmit('', 'CB')}
                                                >
                                                </Button>
                                            }
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={() => handleRemoveCB()}
                                                style={{ marginLeft: 5 }}
                                            >
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    :

                    props.calculationSchema === 'Flat % - Multiple Rates per Contract'
                        || props.calculationSchema === 'Flat $ - Multiple Amounts per Contract' ?
                        <Card>
                            <form
                                autoComplete="off"
                                noValidate
                            >
                                <div className={classes.container}>
                                    <Grid container spacing={1} >
                                        <Grid
                                            container
                                            spacing={1}
                                            md={7}
                                        //style={{ marginTop: 0, paddingRight: 2 }}
                                        >

                                            <Grid
                                                item
                                                md={3}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Attribute Name
                                                    </FormLabel>
                                                    {props.calculationMethod === 'Price Difference' ?
                                                        <Select
                                                            value={attributeNameMR}
                                                            onChange={handleAttributeNameMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            <MenuItem value="material_number" style={{ height: 30 }}>
                                                                Material Number
                                                            </MenuItem>
                                                        </Select>
                                                        :



                                                        <Select
                                                            value={attributeNameMR}
                                                            onChange={handleAttributeNameMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            style={{ textTransform: 'capitalize' }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                            </MenuItem>{console.log(attributeNameArray)}
                                                            {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                Object.entries(attributeNameArray).map(([key, value]) => {
                                                                    return (
                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                            {value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Attribute Value
                                                    </FormLabel>
                                                    {(attributeNameMR === 'sold_to_party' || attributeNameMR === 'bill_to_party' || attributeNameMR === 'customer_number')
                                                        ?
                                                        <div style={{ marginTop: -10 }}>
                                                            <SearchDropdown id='customer_single'
                                                                table={'customer_master'}
                                                                keyName={'customer_number'}
                                                                description={true}
                                                                onChange={(value) => setAttributeValueMR(value)}
                                                                prevalue={attributeValueMR}
                                                            />
                                                        </div>
                                                        :
                                                        (attributeNameMR === 'material_number' || attributeNameMR === 'supplier_number' || attributeNameMR === 'employee_number')
                                                            ?
                                                            <div style={{ marginTop: -10 }}>
                                                                <SearchDropdown id='material_single'
                                                                    table={(attributeNameMR === 'supplier_number') ? 'supplier_master' :
                                                                        (attributeNameMR === 'material_number') ? 'material_master' : 'employee_master'}
                                                                    keyName={attributeNameMR}
                                                                    description={true}
                                                                    onChange={(value) => setAttributeValueMR(value)}
                                                                    prevalue={attributeValueMR}
                                                                />
                                                            </div>
                                                            :

                                                            attributeNameMR === 'customer_chain' || attributeNameMR === 'customer_group' || attributeNameMR === 'material_group' || attributeNameMR === 'product_hierarchy' || attributeNameMR === 'company_code' || attributeNameMR === 'sales_org' || attributeNameMR === 'supplier_group' ?
                                                                <div style={{ marginTop: -10 }}>
                                                                    <AllOtherAttributeMasterComponent attributeName={attributeNameMR} value={attributeValueMR} onChange={(e) => handleAttributeValueMR(e, 'attr')} prevalue={attributeValueMR} withOutLabel={true} id={attributeNameMR} />
                                                                </div>
                                                                :
                                                                <OutlinedInput
                                                                    value={attributeValueMR}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={handleAttributeValueMR} />
                                                    }
                                                </div>
                                            </Grid>

                                            {props.calculationMethod == 'Flat Rate' && props.incentiveRateType == 'Attribute' &&
                                                < Grid
                                                    item
                                                    md={2}
                                                //style={{ padding: '0px 10px 0px 10px' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.chargeBackLabel} required>
                                                            Formula Type
                                                        </FormLabel>
                                                        <Select
                                                            value={formulaTypeTier}
                                                            onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                            //displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {formulaTypeDataTier
                                                                .map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    )
                                                                })}


                                                        </Select>
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                md={3}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        {formulaTypeTier === 'Formula' ? 'Formula' : 'Key Figure Name'}
                                                    </FormLabel>
                                                    {formulaTypeTier === 'Formula' ?
                                                        <Select
                                                            value={formulaName}
                                                            onChange={(e) => setFormulaName(e.target.value)}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            style={{ textTransform: 'capitalize' }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                        {item.formula_name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                        :
                                                        <Select
                                                            value={keyFigureNameMR}
                                                            onChange={handleKeyFigurenameMR}
                                                            displayEmpty
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}

                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                            </MenuItem>

                                                            {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                Object.entries(mappingValuesSet)
                                                                    .sort()
                                                                    .map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                {value}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                        </Select>
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Rate
                                                    </FormLabel>
                                                    <Tooltip
                                                        open={tooltipIsOpen}
                                                        title="Rate should be less than or equal to 100"
                                                    >
                                                        <OutlinedInput
                                                            value={rateMR}
                                                            type={'number'}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={handleRateMR} />
                                                    </Tooltip>
                                                </div>
                                            </Grid>
                                        </Grid>


                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%', marginTop: -10 }}
                                        >
                                            <div className={classes.selectRoot}>
                                                <FormLabel required className={classes.chargeBackLabel}>
                                                    Rate Type
                                                </FormLabel>
                                                <Select
                                                    value={rateTypeMR}
                                                    onChange={(e) => setRateTypeMR(e.target.value)}
                                                    displayEmpty
                                                    className={clsx({
                                                        [classes.select]: true
                                                    })}
                                                    classes={{
                                                        selectMenu: classes.selectedItem
                                                    }}
                                                    style={{ textTransform: 'capitalize' }}
                                                >
                                                    <MenuItem value="" style={{ height: 30 }}>
                                                    </MenuItem>
                                                    {uomData.map((item) => {
                                                        return (
                                                            <MenuItem value={item.key} key={item.key}>
                                                                {item.value}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </Grid>
                                        {rateTypeMR === '$' &&
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%', marginTop: -10 }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.chargeBackLabel}>
                                                        UoM
                                                    </FormLabel>
                                                    <Select
                                                        value={uomMR}
                                                        onChange={handleUomMR}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}

                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>
                                                        </MenuItem>
                                                        {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                            return (
                                                                <MenuItem value={item} key={item}
                                                                    disabled={keyFigureNameMR != 'quantity' && item === '$' ? true : false}
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                        }
                                        <Grid
                                            container
                                            spacing={1}
                                            md={3}
                                            style={{ marginLeft: 3 }}
                                        >
                                            <Grid
                                                item
                                                md={6}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Start Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={startDateMR}
                                                                onChange={date => handleStartDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={props.startDate ? props.startDate : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        End Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={endDateMR}
                                                                onChange={date => handleEndDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={startDateMR ? startDateMR : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>

                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            md={1}
                                            style={{ width: '100%', display: 'flex', paddingTop: 40 }}
                                        >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    size={'small'}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}

                                                    disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !formulaTypeTier || (formulaTypeTier == 'Formula' ? !formulaName : !keyfigureEnableHelperMR) ||
                                                        !rateMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (props.startDate ? Moment(startDateMR) >= Moment(props.startDate) : true)) || !rateTypeMR
                                                        ? true : false}
                                                    onClick={() => handleSubmit(editIndex, 'MR')}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    size={'small'}
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !formulaTypeTier || (formulaTypeTier == 'Formula' ? !formulaName : !keyfigureEnableHelperMR) ||
                                                        !rateMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (props.startDate ? Moment(startDateMR) >= Moment(props.startDate) : true)) || !rateTypeMR
                                                        ? true : false}
                                                    onClick={() => handleSubmit('', 'MR')}
                                                >
                                                </Button>
                                            }
                                            {/* </Grid> */}
                                            {/* <Grid item md={6}> */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={() => handleRemoveMR()}
                                                style={{ marginLeft: 5 }}
                                            >
                                            </Button>
                                            {/* </Grid> */}
                                        </Grid>

                                    </Grid>
                                </div>
                            </form>
                        </Card>

                        :
                        props.calculationSchema === 'BOM Explosion' ?
                            <Card>
                                <form
                                    autoComplete="off"
                                    noValidate
                                >
                                    <div className={classes.container}>
                                        <Grid container spacing={1} >
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.formDiv}>
                                                        Attribute Name
                                                    </FormLabel>
                                                    <div style={{ marginTop: 20 }}>
                                                        Material Number
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Attribute Value
                                                    </FormLabel>
                                                    <div style={{ marginTop: -10 }}>
                                                        <SearchDropdown id='material_single'
                                                            table={'material_master'}
                                                            keyName={'material_number'}
                                                            description={true}
                                                            onChange={(value) => handleAttributeValueMR(value, 'attr-mat')}
                                                            prevalue={attributeValueMR}
                                                        />
                                                    </div>
                                                    {/* <OutlinedInput
                                                        value={attributeValueMR}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={handleAttributeValueMR} /> */}
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                    </FormLabel>
                                                    <Select
                                                        value={keyFigureNameMR}
                                                        onChange={handleKeyFigurenameMR}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        style={{ textTransform: 'capitalize' }}
                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>
                                                            {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                        </MenuItem>
                                                        {/* {keyFigureData.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                    {item.replace(/_/g, ' ')}
                                                                </MenuItem>
                                                            )
                                                        })} */}
                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                            Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                return (
                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                        {value}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Rate
                                                    </FormLabel>
                                                    <Tooltip
                                                        open={tooltipIsOpen}
                                                        title="Rate should be less than or equal to 100."
                                                    >
                                                        <OutlinedInput
                                                            value={rateMR}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={handleRateMR} />
                                                    </Tooltip>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Rate Type
                                                    </FormLabel>
                                                    <Select
                                                        value={rateTypeMR}
                                                        onChange={(e) => setRateTypeMR(e.target.value)}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                    >
                                                        <MenuItem value="" style={{ height: 30 }}>
                                                        </MenuItem>
                                                        {uomData.map((item) => {
                                                            return (
                                                                <MenuItem value={item.key} key={item.key}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </Grid>
                                            {rateTypeMR === '$' &&
                                                <Grid
                                                    item
                                                    md={1}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.chargeBackLabel}>
                                                            UoM
                                                        </FormLabel>
                                                        <Select
                                                            value={uomMR}
                                                            onChange={handleUomMR}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        >
                                                            <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem>
                                                            {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid
                                                item
                                                md={2}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        Start Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={startDateMR}
                                                                onChange={date => handleStartDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={props.startDate ? props.startDate : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                md={2}
                                                style={{ width: '100%' }}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                        End Date
                                                    </FormLabel>
                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                        <Grid container justifyContent="space-around">
                                                            <DatePicker
                                                                disableToolbar
                                                                clearable
                                                                InputProps={{
                                                                    padding: 0,
                                                                    disableUnderline: true,
                                                                    style: {
                                                                        padding: '1px 10 1px 11px',
                                                                        alignSelf: 'center',
                                                                        alignItems: 'center',
                                                                        border: '1px solid #E0E0E0',
                                                                        width: '100%'
                                                                    }
                                                                }}
                                                                value={endDateMR}
                                                                onChange={date => handleEndDateMR(date)}
                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                format="MM/DD/YYYY"
                                                                minDate={startDateMR ? startDateMR : ''}
                                                            />
                                                        </Grid>
                                                    </LocalizationProvider>
                                                </div>

                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                style={{ width: '100%', display: 'flex', paddingTop: 54 }}
                                            >
                                                {editMode ?
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        startIcon={<EditIcon />}
                                                        size={'small'}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={!(attributeValueMR && attributeValueMR.length > 0) ||
                                                            !rateMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (props.startDate ? Moment(startDateMR) >= Moment(props.startDate) : true))
                                                            || !keyfigureEnableHelperMR
                                                            ? true : false}
                                                        onClick={() => handleSubmit(editIndex, 'MR')}
                                                    >
                                                    </Button>
                                                    :
                                                    <Button
                                                        color='secondary'
                                                        variant="contained"
                                                        size={'small'}
                                                        startIcon={<CheckIcon />}
                                                        classes={{
                                                            startIcon: classes.startIcon,
                                                            root: classes.checkIconContainer
                                                        }}
                                                        disabled={!(attributeValueMR && attributeValueMR.length > 0) || !keyfigureEnableHelperMR ||
                                                            !rateMR || !(startDateMR && Moment(startDateMR).isValid() && endDateMR && (Moment(endDateMR).isValid()) && (Moment(endDateMR) >= Moment(startDateMR)) && (props.startDate ? Moment(startDateMR) >= Moment(props.startDate) : true))
                                                            ? true : false}
                                                        onClick={() => handleSubmit('', 'MR')}
                                                    >
                                                    </Button>
                                                }
                                                {/* </Grid> */}
                                                {/* <Grid item md={6}> */}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<DeleteForeverIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.deleteIconContainer,
                                                    }}
                                                    onClick={() => handleRemoveMR()}
                                                    style={{ marginLeft: 5 }}
                                                >
                                                </Button>
                                                {/* </Grid> */}
                                            </Grid>

                                        </Grid>
                                    </div>
                                </form>
                            </Card>
                            :
                            props.calculationMethod === 'Multi Axis' ?
                                <div>
                                    <Card>
                                        <div className={classes.container}>
                                            <Grid container spacing={1} >
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}

                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel className={classes.chargeBackLabel} required>
                                                            Calculation Type
                                                        </FormLabel>
                                                        <Select
                                                            value={calculationTypeTier}
                                                            onChange={(e) => handleCalculationTypeTier(e, 'Multi Axis')}
                                                            displayEmpty
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {/* <MenuItem value="" style={{ height: 30 }}>
                                                            </MenuItem> */}
                                                            {calculationTypeDataMXS
                                                                .map(item => {
                                                                    return (
                                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </div>
                                                </Grid>

                                                {calculationTypeTier === 'Rebate' ?
                                                    <>

                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel className={classes.chargeBackLabel} required>
                                                                    Formula Type
                                                                </FormLabel>
                                                                <Select
                                                                    value={formulaTypeTier}
                                                                    onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>
                                                                    {formulaTypeDataTier
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}


                                                                </Select>
                                                            </div>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    {formulaTypeTier === 'Formula' ? 'Formula' : keyFigureObject.name}
                                                                </FormLabel>
                                                                {formulaTypeTier === 'Formula' ?
                                                                    <Select
                                                                        value={formulaName}
                                                                        onChange={(e) => setFormulaName(e.target.value)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        style={{ textTransform: 'capitalize' }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.formula_name}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                    :
                                                                    <Select
                                                                        value={keyFigureNameScale}
                                                                        onChange={handleKeyFigurenameScale}
                                                                        displayEmpty
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        style={{ textTransform: 'capitalize' }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                            {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                        </MenuItem>
                                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                            Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                return (
                                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                        {value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                }
                                                            </div>
                                                        </Grid>


                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    Start Date
                                                                </FormLabel>

                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={startDateScale}
                                                                            onChange={date => handleStartDateScale(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={props.startDate ? props.startDate : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ padding: '0px 10px 0px 10px', marginTop: '0.5rem' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    End Date
                                                                </FormLabel>
                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={endDateScale}
                                                                            onChange={date => handleEndDateScale(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={startDateScale ? startDateScale : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>

                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <FormLabel >
                                                                {' '}
                                                            </FormLabel>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 21 }}>

                                                                    {editMode ?
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            startIcon={<EditIcon />}
                                                                            size={'small'}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}
                                                                            disabled={
                                                                                calculationTypeTier == 'Rebate' ?
                                                                                    formulaTypeTier === 'Formula' ?
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false) :
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)

                                                                                    : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true)) || !rateScale) ? true : false)
                                                                            }
                                                                            onClick={() => handleSubmit(editIndex, 'Multi Axis')}
                                                                        >
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            size={'small'}
                                                                            startIcon={<CheckIcon />}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}
                                                                            disabled={
                                                                                calculationTypeTier == 'Rebate' ?
                                                                                    formulaTypeTier === 'Formula' ?
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false) :
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)

                                                                                    : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true)) || !rateScale) ? true : false)
                                                                            }

                                                                            onClick={() => handleSubmit('', 'Multi Axis')}
                                                                        >
                                                                        </Button>
                                                                    }
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<DeleteForeverIcon />}
                                                                        classes={{
                                                                            startIcon: classes.startIcon,
                                                                            root: classes.deleteIconContainer,
                                                                        }}
                                                                        onClick={() => handleRemoveMXS()}
                                                                        style={{ marginLeft: 5 }}
                                                                    >
                                                                    </Button>
                                                                    <input type='file'
                                                                        accept=".xlsx, .xls, .csv"
                                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                                        onChange={(e) => handleDrop(e)} />
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        startIcon={<CloudUpload />}
                                                                        classes={{
                                                                            startIcon: classes.startIcon,
                                                                            root: classes.uploadIconContainer
                                                                        }}
                                                                        onClick={() => uploadMX()}
                                                                        style={{ marginLeft: 10 }}
                                                                    >
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        {props.incentiveRateType === 'Conditional Attribute' &&
                                                            <>
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                >
                                                                    <FormLabel className={classes.chargeBackLabel} required>
                                                                        Attribute Name
                                                                    </FormLabel>
                                                                    <Select
                                                                        name="attribute"
                                                                        value={attributeNameMR}
                                                                        onChange={e => setAttributeNameMR(e.target.value)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        style={{ marginTop: 20 }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                            {attributeNameArray && Object.entries(attributeNameArray).length > 0 ? '' : '- Please configure -'}
                                                                        </MenuItem>

                                                                        {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                            Object.entries(attributeNameArray).map(([key, value]) => {
                                                                                return (
                                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                        {value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={1}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                            Attribute Count
                                                                        </FormLabel>
                                                                        <OutlinedInput
                                                                            value={attributeCount}
                                                                            classes={{ root: classes.inputTwoLine }}
                                                                            onChange={(e) => setAttributeCount(e.target.value)} />
                                                                    </div>
                                                                </Grid>
                                                            </>
                                                        }
                                                        {props.maximumAmountPeriod == 'Payment Period - Tier' &&
                                                            <Grid
                                                                item
                                                                md={1}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Maximum Amount
                                                                    </FormLabel>
                                                                    <OutlinedInput
                                                                        value={maximumAmount}
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        onChange={(e) => setMaximumAmount(e.target.value)} />
                                                                </div>
                                                            </Grid>
                                                        }
                                                        <Grid
                                                            item
                                                            md={1}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    Rate
                                                                </FormLabel>
                                                                <OutlinedInput
                                                                    value={rateScale}
                                                                    classes={{ root: classes.inputTwoLine }}
                                                                    onChange={(e) => setRateScale(e.target.value)} />
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    Start Date
                                                                </FormLabel>
                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={startDateScale}
                                                                            onChange={date => handleStartDateScale(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={props.startDate ? props.startDate : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    End Date
                                                                </FormLabel>
                                                                <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                    <Grid container justifyContent="space-around">
                                                                        <DatePicker
                                                                            disableToolbar
                                                                            clearable
                                                                            InputProps={{
                                                                                padding: 0,
                                                                                disableUnderline: true,
                                                                                style: {
                                                                                    padding: '1px 10 1px 11px',
                                                                                    alignSelf: 'center',
                                                                                    alignItems: 'center',
                                                                                    border: '1px solid #E0E0E0',
                                                                                    width: '100%'
                                                                                }
                                                                            }}
                                                                            value={endDateScale}
                                                                            onChange={date => handleEndDateScale(date)}
                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                            format="MM/DD/YYYY"
                                                                            minDate={startDateScale ? startDateScale : ''}
                                                                        />
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            </div>

                                                        </Grid>


                                                        {keyFigureArrayMXS && keyFigureArrayMXS.length > 0 &&
                                                            keyFigureArrayMXS.map((x, i) => {
                                                                return (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                        >
                                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                                {props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Key Figure Name' : keyFigureObject.name ? keyFigureObject.name : 'Key Figure Name'}
                                                                            </FormLabel>
                                                                            <Select
                                                                                // name="key_figure_names"
                                                                                //value={x.key_figure_names}
                                                                                name="key"
                                                                                value={x.key}
                                                                                onChange={e => handleOperationChange(e, i, 'keyFigure')}
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                </MenuItem>
                                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                    Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                        return (
                                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                {value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                            style={{ display: 'flex', flexDirection: 'column' }}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel className={classes.chargeBackLabel}>
                                                                                    Key Figure Value
                                                                                </FormLabel>

                                                                                <OutlinedInput
                                                                                    //value={x.key_figure_values}
                                                                                    value={x.value}
                                                                                    name="value"
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    // name="key_figure_values"
                                                                                    onChange={e => handleOperationChange(e, i, 'keyFigure')}
                                                                                    displayEmpty
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}

                                                                                />

                                                                            </div>
                                                                            {//calculationStore.length === 0 &&
                                                                                <Button color="primary" size="small"
                                                                                    classes={{
                                                                                        root: classes.removeButton
                                                                                    }}
                                                                                    startIcon={<DeleteForeverIcon />}
                                                                                    onClick={() => handleRemoveOperation(i, 'keyFigure')}>Remove</Button>
                                                                            }
                                                                        </Grid>


                                                                    </>
                                                                );
                                                            })

                                                        }

                                                        {attributeArrayMXS && attributeArrayMXS.length > 0 &&
                                                            attributeArrayMXS.map((x, i) => {
                                                                return (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                        >
                                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                                Attribute Name
                                                                            </FormLabel>
                                                                            <Select
                                                                                name="attribute_names"
                                                                                value={x.attribute_names}
                                                                                onChange={e => handleOperationChange(e, i, 'attribute')}
                                                                                displayEmpty
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                    {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                                                </MenuItem>

                                                                                {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                                    Object.entries(attributeNameArray).map(([key, value]) => {
                                                                                        return (
                                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                {value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                            style={{ display: 'flex', flexDirection: 'column' }}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel className={classes.chargeBackLabel}>
                                                                                    Attribute Value
                                                                                </FormLabel>
                                                                                {(x.attribute_names === 'customer_number' || x.attribute_names === 'sold_to_party' || x.attribute_names === 'bill_to_party') ?
                                                                                    <div style={{ marginTop: -10 }}>
                                                                                        <SearchDropdown id='customer_single'
                                                                                            table={'customer_master'}
                                                                                            keyName={'customer_number'}
                                                                                            description={true}
                                                                                            onChange={(value) => handleOperationChange(value, i, 'attribute', "attribute_values")}
                                                                                            prevalue={x.attribute_values}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    (x.attribute_names === 'material_number' || x.attribute_names === 'supplier_number' || x.attribute_names === 'employee_number') ?
                                                                                        <div style={{ marginTop: -10 }}>
                                                                                            <SearchDropdown id='material_single'
                                                                                                table={(x.attribute_names === 'supplier_number') ? 'supplier_master' :
                                                                                                    (x.attribute_names === 'material_number') ? 'material_master' : 'employee_master'}
                                                                                                keyName={x.attribute_names}
                                                                                                description={true}
                                                                                                onChange={(value) => handleOperationChange(value, i, 'attribute', "attribute_values")}
                                                                                                prevalue={x.attribute_values}
                                                                                            />
                                                                                        </div>
                                                                                        :

                                                                                        x.attribute_names === 'customer_chain' || x.attribute_names === 'customer_group' || x.attribute_names === 'material_group' || x.attribute_names === 'product_hierarchy' || x.attribute_names === 'company_code' || x.attribute_names === 'sales_org' || x.attribute_names === 'supplier_group' ?
                                                                                            <div style={{ marginTop: -10 }}>
                                                                                                <AllOtherAttributeMasterComponent attributeName={x.attribute_names} value={x.attribute_values} onChange={(e) => handleOperationChange(e, i, 'attr', "attribute_values")} prevalue={x.attribute_values} withOutLabel={true} id={x.attribute_names} />
                                                                                            </div>
                                                                                            :
                                                                                            <OutlinedInput
                                                                                                value={x.attribute_values}
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                name="attribute_values"
                                                                                                onChange={e => handleOperationChange(e, i, 'attribute')}
                                                                                                displayEmpty
                                                                                                className={clsx({
                                                                                                    [classes.select]: true
                                                                                                })}
                                                                                            />
                                                                                }

                                                                            </div>
                                                                            {//calculationStore.length === 0 &&
                                                                                <Button color="primary" size="small"
                                                                                    classes={{
                                                                                        root: classes.removeButton
                                                                                    }}
                                                                                    startIcon={<DeleteForeverIcon />}
                                                                                    onClick={() => handleRemoveOperation(i, 'attribute')}>Remove</Button>
                                                                            }
                                                                        </Grid>
                                                                    </>
                                                                );
                                                            })

                                                        }
                                                        {formulaArrayMXS && formulaArrayMXS.length > 0 &&
                                                            formulaArrayMXS.map((x, i) => {
                                                                return (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                        >
                                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                                {props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Formula Name' : 'Formula Name'}
                                                                            </FormLabel>


                                                                            <Select
                                                                                name="formula_names"
                                                                                value={x.formula_names}
                                                                                onChange={e => handleOperationChange(e, i, 'formula')}
                                                                                displayEmpty
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                    {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                                                </MenuItem>

                                                                                {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                            {item.formula_name}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            md={2}
                                                                            style={{ display: 'flex', flexDirection: 'column' }}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel className={classes.chargeBackLabel}>
                                                                                    Formula Value
                                                                                </FormLabel>
                                                                                <OutlinedInput
                                                                                    value={x.formula_values}
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    name="formula_values"
                                                                                    onChange={e => handleOperationChange(e, i, 'formula')}
                                                                                    displayEmpty
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            {//calculationStore.length === 0 &&
                                                                                <Button color="primary" size="small"
                                                                                    classes={{
                                                                                        root: classes.removeButton
                                                                                    }}
                                                                                    startIcon={<DeleteForeverIcon />}
                                                                                    onClick={() => handleRemoveOperation(i, 'formula')}>Remove</Button>
                                                                            }
                                                                        </Grid>


                                                                    </>
                                                                );
                                                            })

                                                        }
                                                        <Grid
                                                            item
                                                            md={2}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                {' '}
                                                            </FormLabel>
                                                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
                                                                    {editMode ?
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            startIcon={<EditIcon />}
                                                                            size={'small'}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}
                                                                            disabled={
                                                                                calculationTypeTier == 'Rebate' ?
                                                                                    formulaTypeTier === 'Formula' ?
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false) :
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)

                                                                                    : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true)) || !rateScale ||
                                                                                        (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false) || (props.incentiveRateType === 'Conditional Attribute' ? (!attributeNameMR && !attributeCount) : false) ||
                                                                                        ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.length == 0) && (attributeArrayMXSAPI && attributeArrayMXSAPI.length == 0) && (formulaArrayMXSAPI && formulaArrayMXSAPI.length == 0)) ? true
                                                                                        :
                                                                                        ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.filter((k, i) => k.key == '' || k.value == '').length != 0) ||
                                                                                            (attributeArrayMXSAPI && attributeArrayMXSAPI.filter((k, i) => k.attribute_names == '' || k.attribute_values == '').length != 0) ||
                                                                                            (formulaArrayMXSAPI && formulaArrayMXSAPI.filter((k, i) => k.formula_names == '' || k.formula_values == '').length != 0))) ? true : false)
                                                                            }
                                                                            onClick={() => handleSubmit(editIndex, 'Multi Axis')}
                                                                        >
                                                                        </Button>
                                                                        :
                                                                        <Button
                                                                            color='secondary'
                                                                            variant="contained"
                                                                            size={'small'}
                                                                            startIcon={<CheckIcon />}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.checkIconContainer
                                                                            }}

                                                                            disabled={
                                                                                calculationTypeTier == 'Rebate' ?
                                                                                    formulaTypeTier === 'Formula' ?
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false) :
                                                                                        ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)

                                                                                    : ((!calculationTypeTier || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true)) || !rateScale ||
                                                                                        (props.maximumAmountPeriod === 'Payment Period - Tier' ? !maximumAmount : false) || (props.incentiveRateType === 'Conditional Attribute' ? (!attributeNameMR && !attributeCount) : false) ||
                                                                                        ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.length == 0) && (attributeArrayMXSAPI && attributeArrayMXSAPI.length == 0) && (formulaArrayMXSAPI && formulaArrayMXSAPI.length == 0)) ? true
                                                                                        :
                                                                                        ((keyFigureArrayMXSAPI && keyFigureArrayMXSAPI.filter((k, i) => k.key == '' || k.value == '').length != 0) ||
                                                                                            (attributeArrayMXSAPI && attributeArrayMXSAPI.filter((k, i) => k.attribute_names == '' || k.attribute_values == '').length != 0) ||
                                                                                            (formulaArrayMXSAPI && formulaArrayMXSAPI.filter((k, i) => k.formula_names == '' || k.formula_values == '').length != 0))) ? true : false)
                                                                            }
                                                                            onClick={() => handleSubmit('', 'Multi Axis')}
                                                                        >

                                                                        </Button>
                                                                    }
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        startIcon={<DeleteForeverIcon />}
                                                                        classes={{
                                                                            startIcon: classes.startIcon,
                                                                            root: classes.deleteIconContainer,
                                                                        }}
                                                                        onClick={() => handleRemoveMXS()}
                                                                        style={{ marginLeft: 5 }}
                                                                    >
                                                                    </Button>
                                                                    <input type='file'
                                                                        accept=".xlsx, .xls, .csv"
                                                                        id='file' ref={inputFile} style={{ display: 'none' }}
                                                                        onChange={(e) => handleDrop(e)} />
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        startIcon={<CloudUpload />}
                                                                        classes={{
                                                                            startIcon: classes.startIcon,
                                                                            root: classes.uploadIconContainer
                                                                        }}
                                                                        onClick={() => uploadMX()}
                                                                        style={{ marginLeft: 10 }}
                                                                    >
                                                                    </Button>

                                                                </div>

                                                            </div>
                                                        </Grid>


                                                    </>
                                                }
                                            </Grid>
                                        </div>
                                    </Card >
                                    {/* mxs */}
                                    {calculationTypeTier === 'Tier' &&
                                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 20 }}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Tooltip title={props.incentiveRateType === 'Conditional Attribute' ? 'Add Conditional KeyFigure' : 'Add Key Figure'}>
                                                    <Button color="primary" size="small"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => handleAddOperation('keyFigure')}>{props.incentiveRateType === 'Conditional Attribute' ? 'Conditional KeyFigure' : 'Key Figure'}</Button>
                                                </Tooltip>
                                            </div>
                                            {props.incentiveRateType !== 'Conditional Attribute' &&
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Tooltip title='Add Attribute'>
                                                        <Button color="primary" size="small"
                                                            startIcon={<AddIcon />}
                                                            onClick={() => handleAddOperation('attribute')}>Attribute</Button>
                                                    </Tooltip>
                                                </div>
                                            }
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Tooltip title={props.incentiveRateType === 'Conditional Attribute' ? 'Add Conditional Formula' : 'Add Formula'}>
                                                    <Button color="primary" size="small"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => handleAddOperation('formula')}>{props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Formula' : 'Formula'}</Button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                </div>

                                :
                                (props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth') ?
                                    <Card>
                                        <div className={classes.container}>
                                            <Grid container spacing={1} >
                                                <Grid
                                                    item
                                                    md={3}
                                                    style={{ width: '100%' }}
                                                >
                                                    <Grid container spacing={1} >
                                                        <Grid
                                                            item
                                                            md={6}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel className={classes.chargeBackLabel} required>
                                                                    Calculation Type
                                                                </FormLabel>
                                                                <Select
                                                                    value={calculationTypeTier}
                                                                    onChange={handleCalculationTypeTier}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>
                                                                    {calculationTypeData
                                                                        .map(item => {
                                                                            return (
                                                                                <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                </Select>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={6}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot}>
                                                                <FormLabel className={classes.chargeBackLabel} required>
                                                                    Formula Type
                                                                </FormLabel>
                                                                <Select
                                                                    value={formulaTypeTier}
                                                                    onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>

                                                                    {formulaTypeDataTier
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}


                                                                </Select>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                            {formulaTypeTier === 'Formula' ? 'Formula' : keyFigureObject.name}
                                                        </FormLabel>
                                                        {formulaTypeTier === 'Formula' ?
                                                            <Select
                                                                value={formulaName}
                                                                onChange={(e) => setFormulaName(e.target.value)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                </MenuItem>
                                                                {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                    return (
                                                                        <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                            {item.formula_name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                            :
                                                            <Select
                                                                value={keyFigureNameScale}
                                                                onChange={handleKeyFigurenameScale}
                                                                displayEmpty
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                </MenuItem>
                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                    Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                        return (
                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                {value}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                            </Select>
                                                        }
                                                    </div>
                                                </Grid>
                                                {operationArray.length > 0 &&
                                                    operationArray.map((x, i) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                md={3}
                                                                xs={12}
                                                                spacing={1}
                                                                style={{ margin: 0 }}
                                                            >

                                                                <Grid
                                                                    item
                                                                    md={4}
                                                                >
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                            Operation
                                                                        </FormLabel>
                                                                        <Select
                                                                            name="operation"
                                                                            value={x.operation}
                                                                            onChange={e => handleOperationChange(e, i)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                {/* Select operation */}
                                                                            </MenuItem>
                                                                            {operationData.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item.value} key={item.value}>
                                                                                        {item.value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={8}
                                                                >
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                    </FormLabel>
                                                                    <Select
                                                                        name="key_figure_names"
                                                                        value={x.key_figure_names}
                                                                        onChange={e => handleOperationChange(e, i)}
                                                                        displayEmpty
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                            {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                        </MenuItem>
                                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                            Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                return (
                                                                                    <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                        {value}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </Grid>
                                                                <Button color="primary" size="small"
                                                                    classes={{
                                                                        root: classes.removeButton
                                                                    }}
                                                                    startIcon={<DeleteForeverIcon />}
                                                                    onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                            </Grid>
                                                        );
                                                    })

                                                }


                                                {/* {props.maximumAmountPeriod == 'Payment Period - Tier' && calculationTypeTier && calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel required className={classes.chargeBackLabel}>
                                                                Maximum Amount
                                                            </FormLabel>
                                                            <OutlinedInput
                                                                value={maximumAmount}
                                                                classes={{ root: classes.inputTwoLine }}
                                                                onChange={(e) => setMaximumAmount(e.target.value)} />
                                                        </div>
                                                    </Grid>
                                                } */}


                                                {calculationTypeTier != 'Rebate' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel required className={classes.chargeBackLabel}>
                                                                Rate Type
                                                            </FormLabel>
                                                            <Select
                                                                value={rateTypeScale}
                                                                onChange={(e) => setRateTypeScale(e.target.value)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                </MenuItem>
                                                                {uomData.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.key} key={item.key}>
                                                                            {item.value}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                }


                                                {calculationTypeTier != 'Rebate' && rateTypeScale === '$' &&
                                                    <Grid
                                                        item
                                                        md={1}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <div className={classes.selectRoot}>
                                                            <FormLabel className={classes.chargeBackLabel}>
                                                                UoM
                                                            </FormLabel>
                                                            <Select
                                                                value={uomScale}
                                                                onChange={handleUomScale}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            >
                                                                <MenuItem value="" style={{ height: 30 }}>

                                                                </MenuItem>
                                                                {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item} key={item}
                                                                            disabled={(item === '$' && keyFigureNameScale != 'quantity') ? true : false}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                            Start Date
                                                        </FormLabel>

                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={startDateScale}
                                                                    onChange={date => handleStartDateScale(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={props.startDate ? props.startDate : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div className={classes.selectRoot}>
                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                            End Date
                                                        </FormLabel>
                                                        <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                            <Grid container justifyContent="space-around">
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '1px 10 1px 11px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={endDateScale}
                                                                    onChange={date => handleEndDateScale(date)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    format="MM/DD/YYYY"
                                                                    minDate={startDateScale ? startDateScale : ''}
                                                                />
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    </div>

                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    style={{ width: '100%' }}
                                                >
                                                    <FormLabel className={classes.chargeBackLabel}>
                                                        {''}
                                                    </FormLabel>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            {editMode ?
                                                                <Button
                                                                    color='secondary'
                                                                    variant="contained"
                                                                    startIcon={<EditIcon />}
                                                                    size={'small'}
                                                                    classes={{
                                                                        startIcon: classes.startIcon,
                                                                        root: classes.checkIconContainer
                                                                    }}
                                                                    disabled={
                                                                        calculationTypeTier != 'Rebate' ?
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))
                                                                                    || !timingData[0] || !rateTypeScale || !(timingData.length > 0 && timingData.filter(e => (e.rate == '' || e.from == '' || (props.maximumAmountPeriod === 'Payment Period - Tier' && e.maximum_amount == ''))).length == 0)
                                                                                ) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))
                                                                                    || !rateTypeScale || !timingData[0] || !(timingData.length > 0 && timingData.filter(e => (e.rate == '' || e.from == '' || (props.maximumAmountPeriod === 'Payment Period - Tier' && e.maximum_amount == ''))).length == 0)
                                                                                ) ? true : false)

                                                                            :
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)
                                                                                :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)
                                                                    }
                                                                    onClick={() => handleSubmit(editIndex, 'Tiered Volume - Simple')}
                                                                >
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color='secondary'
                                                                    variant="contained"
                                                                    size={'small'}
                                                                    startIcon={<CheckIcon />}
                                                                    classes={{
                                                                        startIcon: classes.startIcon,
                                                                        root: classes.checkIconContainer
                                                                    }}
                                                                    disabled={
                                                                        calculationTypeTier != 'Rebate' ?
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))
                                                                                    || !rateTypeScale || !timingData[0] ||
                                                                                    !(timingData.length > 0 && timingData.filter(e => (e.rate == '' || e.from == '' || (props.maximumAmountPeriod === 'Payment Period - Tier' && e.maximum_amount == ''))).length == 0)
                                                                                )
                                                                                    ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))
                                                                                    || !rateTypeScale || !timingData[0] ||
                                                                                    !(timingData.length > 0 && timingData.filter(e => (e.rate == '' || e.from == '' || (props.maximumAmountPeriod === 'Payment Period - Tier' && e.maximum_amount == ''))).length == 0)
                                                                                ) ? true : false)

                                                                            :
                                                                            formulaTypeTier === 'Formula' ?
                                                                                ((!calculationTypeTier || !formulaTypeTier || !formulaName || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false) :
                                                                                ((!calculationTypeTier || !formulaTypeTier || !keyFigureNameScale || !(startDateScale && Moment(startDateScale).isValid() && endDateScale && (Moment(endDateScale).isValid()) && (Moment(endDateScale) >= Moment(startDateScale)) && (props.startDate ? Moment(startDateScale) >= Moment(props.startDate) : true))) ? true : false)
                                                                    }
                                                                    onClick={() => handleSubmit('', 'Tiered Volume - Simple')}
                                                                >
                                                                </Button>
                                                            }
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<DeleteForeverIcon />}
                                                                classes={{
                                                                    startIcon: classes.startIcon,
                                                                    root: classes.deleteIconContainer,
                                                                }}
                                                                onClick={() => handleRemoveScale()}
                                                                style={{ marginLeft: 5 }}
                                                            >
                                                            </Button>
                                                            {calculationTypeTier != 'Rebate' &&
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    startIcon={<MonetizationOnIcon />}
                                                                    classes={{
                                                                        startIcon: classes.startIcon,
                                                                        root: timingRow && timingRow.length > 0 && (!timingRow[0]['from'] || !timingRow[0]['rate']) ? classes.dollorIconDeactive : classes.dollorIconActive,
                                                                    }}
                                                                    onClick={() => addTiming()}
                                                                    style={{ marginLeft: 5 }}
                                                                >
                                                                </Button>
                                                            }
                                                            {/* </Grid> */}
                                                        </div>

                                                        {formulaTypeTier != 'Formula' &&
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <Tooltip title='Add Key Figure'>
                                                                    <Button color="primary" size="small"
                                                                        startIcon={<AddIcon />}
                                                                        onClick={handleAddOperation}>Key Figure</Button>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>


                                            </Grid>
                                        </div>
                                    </Card >
                                    :
                                    props.calculationMethod === 'Fixed Amount' && props.incentiveRateType === 'Attribute' ?
                                        <Card>
                                            <form
                                                autoComplete="off"
                                                noValidate
                                            >
                                                <div className={classes.container}>
                                                    <Grid container spacing={1} >
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            md={5}
                                                        // style={{ marginTop: 0, paddingRight: 2 }}
                                                        >
                                                            <Grid
                                                                item
                                                                md={3}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Attribute Name
                                                                    </FormLabel>
                                                                    {props.calculationMethod === 'Price Difference' ?
                                                                        <Select
                                                                            value={attributeNameMR}
                                                                            onChange={handleAttributeNameMR}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            style={{ textTransform: 'capitalize' }}
                                                                        >
                                                                            <MenuItem value="material_number" style={{ height: 30 }}>
                                                                                Material Number
                                                                            </MenuItem>
                                                                        </Select>
                                                                        :
                                                                        <Select
                                                                            value={attributeNameMR}
                                                                            onChange={handleAttributeNameMR}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                                {attributeNameData && attributeNameData.length > 0 ? '' : '- Please configure -'}
                                                                            </MenuItem>

                                                                            {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                                Object.entries(attributeNameArray).map(([key, value]) => {
                                                                                    return (
                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                            {value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}
                                                                        </Select>


                                                                    }

                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={5}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Attribute Value
                                                                    </FormLabel>
                                                                    {(attributeNameMR === 'customer_number' || attributeNameMR === 'sold_to_party' || attributeNameMR === 'bill_to_party')
                                                                        ?
                                                                        <div style={{ marginTop: -10 }}>
                                                                            <SearchDropdown id='customer_single'
                                                                                table={'customer_master'}
                                                                                keyName={'customer_number'}
                                                                                description={true}
                                                                                onChange={(value) => setAttributeValueMR(value)}
                                                                                prevalue={attributeValueMR}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        attributeNameMR === 'material_number' ?
                                                                            <div style={{ marginTop: -10 }}>
                                                                                <SearchDropdown id='material_single'
                                                                                    table={'material_master'}
                                                                                    keyName={'material_number'}
                                                                                    description={true}
                                                                                    onChange={(value) => setAttributeValueMR(value)}
                                                                                    prevalue={attributeValueMR}
                                                                                />

                                                                            </div>
                                                                            :
                                                                            attributeNameMR === 'supplier_number' && activeDropdown && activeDropdown[attributeNameMR] ?
                                                                                <div style={{ marginTop: -10 }}>
                                                                                    <SearchDropdown id='supplier_single'
                                                                                        table={'supplier_master'}
                                                                                        keyName={'supplier_number'}
                                                                                        description={true}
                                                                                        onChange={(value) => setAttributeValueMR(value)}
                                                                                        prevalue={attributeValueMR}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                attributeNameMR === 'employee_number' && activeDropdown && activeDropdown[attributeNameMR] ?
                                                                                    <div style={{ marginTop: -10 }}>
                                                                                        <SearchDropdown id='employee_single'
                                                                                            table={'employee_master'}
                                                                                            keyName={'employee_number'}
                                                                                            description={true}
                                                                                            onChange={(value) => setAttributeValueMR(value)}
                                                                                            prevalue={attributeValueMR}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    attributeNameMR === 'customer_chain' || attributeNameMR === 'customer_group' || attributeNameMR === 'material_group' || attributeNameMR === 'product_hierarchy' || attributeNameMR === 'company_code' || attributeNameMR === 'sales_org' || attributeNameMR === 'supplier_group' ?
                                                                                        <div style={{ marginTop: -10 }}>
                                                                                            <AllOtherAttributeMasterComponent attributeName={attributeNameMR} value={attributeValueMR} onChange={(e) => handleAttributeValueMR(e, 'attr')} prevalue={attributeValueMR} withOutLabel={true} id={attributeNameMR} />
                                                                                        </div>
                                                                                        :

                                                                                        <OutlinedInput
                                                                                            value={attributeValueMR}
                                                                                            classes={{ root: classes.inputTwoLine }}
                                                                                            onChange={handleAttributeValueMR} />
                                                                    }
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={4}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Key Figure Name
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={keyfigurename}
                                                                        onChange={handleKeyfigurename}
                                                                        displayEmpty
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        style={{ textTransform: 'capitalize' }}
                                                                    >

                                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                            Object.entries(mappingValuesSet)
                                                                                .sort()
                                                                                .map(([key, value]) => {
                                                                                    return (
                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                            {value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        {operationArray.length > 0 &&
                                                            operationArray.map((x, i) => {
                                                                return (
                                                                    <Grid
                                                                        container
                                                                        md={3}
                                                                        xs={12}
                                                                        spacing={1}
                                                                    //style={{ margin: 0 }}
                                                                    >

                                                                        <Grid
                                                                            item
                                                                            md={4}
                                                                        >
                                                                            <div className={classes.selectRoot}>
                                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                                    Operation
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="operation"
                                                                                    value={x.operation}
                                                                                    onChange={e => handleOperationChange(e, i)}
                                                                                    displayEmpty
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    disabled
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                        {/* Select operation */}
                                                                                    </MenuItem>
                                                                                    {operationData.map((item) => {
                                                                                        return (
                                                                                            <MenuItem value={item.value} key={item.value}>
                                                                                                {item.value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            md={8}
                                                                        >
                                                                            <FormLabel required className={classes.chargeBackLabel}>
                                                                                {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                            </FormLabel>
                                                                            <Select
                                                                                name="key_figure_names"
                                                                                value={x.key_figure_names}
                                                                                onChange={e => handleOperationChange(e, i)}
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}

                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                    {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                </MenuItem>
                                                                                {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                    Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                        return (
                                                                                            <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                {value}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })}
                                                                            </Select>
                                                                        </Grid>
                                                                        <Button color="primary" size="small"
                                                                            classes={{
                                                                                root: classes.removeButton
                                                                            }}
                                                                            startIcon={<DeleteForeverIcon />}
                                                                            onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                    </Grid>
                                                                );
                                                            })

                                                        }

                                                        <Grid
                                                            item
                                                            md={1}
                                                            xs={12}
                                                        >
                                                            <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    Rate
                                                                </FormLabel>
                                                                <Tooltip
                                                                    open={tooltipIsOpen}
                                                                    title="Rate should be less than or equal to 100."
                                                                >
                                                                    <OutlinedInput
                                                                        value={rate}
                                                                        type={'number'}
                                                                        inputProps={{ min: "0", step: "1" }}
                                                                        classes={{ root: classes.inputTwoLine }}
                                                                        onChange={handleRate} />
                                                                </Tooltip>
                                                            </div>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={1}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                    Rate Type
                                                                </FormLabel>
                                                                <Select
                                                                    value={rateType}
                                                                    onChange={(e) => setRateType(e.target.value)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                    </MenuItem>
                                                                    {uomData.map((item) => {
                                                                        return (
                                                                            <MenuItem value={item.key} key={item.key}>
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        </Grid>
                                                        {rateType === '$' &&
                                                            <Grid
                                                                item
                                                                md={1}
                                                                xs={12}>
                                                                <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                                    <FormLabel className={classes.chargeBackLabel}>
                                                                        UoM
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={uom}
                                                                        onChange={handleUom}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item} key={item}
                                                                                    disabled={item.value === '%' ? true : false}
                                                                                >
                                                                                    {item}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                        }
                                                        <Grid
                                                            item
                                                            md={3}
                                                            xs={12}
                                                            container spacing={1}
                                                        >
                                                            <Grid
                                                                item
                                                                md={6}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Start Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10px 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveStartDate}
                                                                                onChange={date => handleEffectiveStartDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={props.startDate ? props.startDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={6}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot} style={{ marginTop: -4 }}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        End Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveEndDate}
                                                                                onChange={date => handleEffectiveEndDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            md={1}
                                                            xs={12}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid
                                                                    item
                                                                    md={12}
                                                                >
                                                                    <FormLabel className={classes.chargeBackLabel}>
                                                                        {''}
                                                                    </FormLabel>
                                                                    <Grid container spacing={1} >
                                                                        <Grid item md={12} >
                                                                            <Grid container style={{ paddingBottom: 15, marginTop: 5 }} >
                                                                                <Grid item md={12} style={{ display: 'flex' }}>
                                                                                    {editMode ?
                                                                                        <Button
                                                                                            color='secondary'
                                                                                            variant="contained"
                                                                                            startIcon={<EditIcon />}
                                                                                            classes={{
                                                                                                startIcon: classes.startIcon,
                                                                                                root: classes.checkIconContainer
                                                                                            }}
                                                                                            disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !keyfigurename || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !rate || !rateType ? true : false}
                                                                                            onClick={() => handleSubmit(editIndex, 'FA')}
                                                                                        >
                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            color='secondary'
                                                                                            variant="contained"
                                                                                            startIcon={<CheckIcon />}
                                                                                            classes={{
                                                                                                startIcon: classes.startIcon,
                                                                                                root: classes.checkIconContainer
                                                                                            }}
                                                                                            disabled={!attributeNameMR || !(attributeValueMR && attributeValueMR.length > 0) || !keyfigurename || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !rate || !rateType ? true : false}

                                                                                            onClick={() => handleSubmit('', 'FA')}
                                                                                        >
                                                                                        </Button>
                                                                                    }
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        startIcon={<DeleteForeverIcon />}
                                                                                        classes={{
                                                                                            startIcon: classes.startIcon,
                                                                                            root: classes.deleteIconContainer,
                                                                                        }}
                                                                                        onClick={handleRemove}
                                                                                        style={{ marginLeft: 10 }}
                                                                                    >
                                                                                    </Button>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </form>
                                        </Card>
                                        :
                                        (props.calculationMethod === 'Quota Achievement' || props.calculationMethod === 'PPM') ?
                                            <Card>
                                                <form
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    <div className={classes.container}>
                                                        <Grid container spacing={1} >
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        {keyFigureObject.name ? keyFigureObject.name : 'Key Figure Name'}
                                                                    </FormLabel>

                                                                    <Select
                                                                        value={keyfigurename}
                                                                        onChange={handleKeyfigurename}
                                                                        displayEmpty
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                        style={{ textTransform: 'capitalize' }}
                                                                    >

                                                                        {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                            Object.entries(mappingValuesSet)
                                                                                .sort()
                                                                                .map(([key, value]) => {
                                                                                    return (
                                                                                        <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                            {value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                            {operationArray.length > 0 &&
                                                                operationArray.map((x, i) => {
                                                                    return (
                                                                        <Grid
                                                                            container
                                                                            md={3}
                                                                            xs={12}
                                                                            spacing={1}
                                                                            style={{ margin: 0 }}
                                                                        >

                                                                            <Grid
                                                                                item
                                                                                md={4}
                                                                            >
                                                                                <div className={classes.selectRoot}>
                                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                                        Operation
                                                                                    </FormLabel>
                                                                                    <Select
                                                                                        name="operation"
                                                                                        value={x.operation}
                                                                                        onChange={e => handleOperationChange(e, i)}
                                                                                        displayEmpty
                                                                                        className={clsx({
                                                                                            [classes.select]: true
                                                                                        })}
                                                                                        classes={{
                                                                                            selectMenu: classes.selectedItem
                                                                                        }}

                                                                                    >
                                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                                        </MenuItem>
                                                                                        {operationData.map((item) => {
                                                                                            return (
                                                                                                <MenuItem value={item.value} key={item.value}>
                                                                                                    {item.value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={8}
                                                                            >
                                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                                    {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="key_figure_names"
                                                                                    value={x.key_figure_names}
                                                                                    onChange={e => handleOperationChange(e, i)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                    </MenuItem>
                                                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Button color="primary" size="small"
                                                                                classes={{
                                                                                    root: classes.removeButton
                                                                                }}
                                                                                startIcon={<DeleteForeverIcon />}
                                                                                onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                        </Grid>
                                                                    );
                                                                })

                                                            }

                                                            <Grid
                                                                item
                                                                md={1}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Rate Type
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={rateType}
                                                                        onChange={(e) => setRateType(e.target.value)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {uomData.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={item.key}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                            {rateType === '$' &&
                                                                <Grid
                                                                    item
                                                                    md={1}
                                                                    xs={12}>
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel className={classes.chargeBackLabel}>
                                                                            UoM
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={uom}
                                                                            onChange={handleUom}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={item}
                                                                                        disabled={(item.value === '%' && props.calculationMethod === 'Fixed Amount') || (item.value === '$' && props.calculationMethod === 'Flat Rate' && keyfigurename != 'quantity') ? true : false}
                                                                                    >
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Start Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveStartDate}
                                                                                onChange={date => handleEffectiveStartDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        End Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveEndDate}
                                                                                onChange={date => handleEffectiveEndDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={2}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <FormLabel className={classes.chargeBackLabel}>
                                                                    {' '}
                                                                </FormLabel>
                                                                <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 8 }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        {editMode ?
                                                                            <Button
                                                                                color='secondary'
                                                                                variant="contained"
                                                                                startIcon={<EditIcon />}
                                                                                size={'small'}
                                                                                classes={{
                                                                                    startIcon: classes.startIcon,
                                                                                    root: classes.checkIconContainer
                                                                                }}
                                                                                disabled={!keyfigurename || !rateType
                                                                                    // || (rateType === '$' ? !uom : null) 
                                                                                    || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !timingRow[0] ||
                                                                                    !(timingRow.length > 0 && timingRow.filter(e => (e.rate == '' || e.from == '')).length == 0) ? true : false}
                                                                                onClick={() => handleSubmit(editIndex, 'Quota')}
                                                                            >
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                color='secondary'
                                                                                variant="contained"
                                                                                size={'small'}
                                                                                startIcon={<CheckIcon />}
                                                                                classes={{
                                                                                    startIcon: classes.startIcon,
                                                                                    root: classes.checkIconContainer
                                                                                }}
                                                                                disabled={!keyfigurename || !rateType
                                                                                    //  || (rateType === '$' ? !uom : null) 
                                                                                    || !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !timingRow[0] ||
                                                                                    //(timingRow[0]&&!timingRow[0]['from']) || (timingRow[0]&&!timingRow[0]['rate'])
                                                                                    !(timingRow.length > 0 && timingRow.filter(e => (e.rate == '' || e.from == '')).length == 0)
                                                                                    ? true : false}
                                                                                onClick={() => handleSubmit('', 'Quota')}
                                                                            >
                                                                            </Button>
                                                                        }
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            startIcon={<DeleteForeverIcon />}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: classes.deleteIconContainer,
                                                                            }}
                                                                            onClick={() => handleRemoveQuota()}
                                                                            style={{ marginLeft: 5 }}
                                                                        >
                                                                        </Button>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            startIcon={<MonetizationOnIcon />}
                                                                            classes={{
                                                                                startIcon: classes.startIcon,
                                                                                root: timingRow[0] && (!timingRow[0]['from'] || !timingRow[0]['rate']) ? classes.dollorIconDeactive : classes.dollorIconActive,
                                                                            }}
                                                                            onClick={() => addTiming()}
                                                                            style={{ marginLeft: 5 }}
                                                                        >
                                                                        </Button>
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <Tooltip title='Add Key Figure'>
                                                                            <Button color="primary" size="small"
                                                                                startIcon={<AddIcon />}
                                                                                onClick={handleAddOperation}>Key Figure</Button>
                                                                        </Tooltip>

                                                                    </div>
                                                                </div>

                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                </form>
                                            </Card>
                                            :
                                            <Card>
                                                <form
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    <div className={classes.container}>
                                                        <Grid container spacing={1} >
                                                            {props.calculationMethod == 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                < Grid
                                                                    item
                                                                    md={2}
                                                                //style={{ padding: '0px 10px 0px 10px' }}
                                                                >
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel className={classes.chargeBackLabel} required>
                                                                            Formula Type
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={formulaTypeTier}
                                                                            onChange={(e) => setFormulaTypeTier(e.target.value)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {formulaTypeDataTier
                                                                                .map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem value={item.key} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                            {item.value}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}


                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {(props.calculationMethod === 'Price Point') ?
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                    xs={12}>
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                            Formula
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={formulaName}
                                                                            onChange={(e) => setFormulaName(e.target.value)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            style={{ textTransform: 'capitalize' }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {props.pricingFormulaList && props.pricingFormulaList.records && props.pricingFormulaList.records.length && props.pricingFormulaList.records.length > 0 && props.pricingFormulaList.records.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                        {item.formula_name}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                                :
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                    xs={12}
                                                                >
                                                                    <div className={classes.selectRoot}>

                                                                        <FormLabel required className={classes.chargeBackLabel}>
                                                                            {formulaTypeTier === 'Formula' ? 'Formula' : 'Key Figure Name'}
                                                                        </FormLabel>
                                                                        {formulaTypeTier === 'Formula' ?
                                                                            <Select
                                                                                value={formulaName}
                                                                                onChange={(e) => setFormulaName(e.target.value)}
                                                                                displayEmpty
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                                style={{ textTransform: 'capitalize' }}
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                            >
                                                                                <MenuItem value="" style={{ height: 30 }}>
                                                                                </MenuItem>
                                                                                {props.formulaListData && props.formulaListData.records && props.formulaListData.records.length > 0 && props.formulaListData.records.map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem value={item.ID} key={index} style={{ textTransform: 'capitalize' }}>
                                                                                            {item.formula_name}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                            :
                                                                            <Select
                                                                                value={keyfigurename}
                                                                                onChange={handleKeyfigurename}
                                                                                displayEmpty
                                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                className={clsx({
                                                                                    [classes.select]: true
                                                                                })}
                                                                                classes={{
                                                                                    selectMenu: classes.selectedItem
                                                                                }}
                                                                                style={{ textTransform: 'capitalize' }}
                                                                            >
                                                                                {mappingValuesSet && Object.keys(mappingValuesSet) && Object.keys(mappingValuesSet).length && Object.keys(mappingValuesSet).length > 0 &&
                                                                                    Object.entries(mappingValuesSet)
                                                                                        .sort()
                                                                                        .map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                            </Select>
                                                                        }
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            {operationArray && operationArray.length > 0 && formulaTypeTier != 'Formula' &&
                                                                operationArray.map((x, i) => {
                                                                    return (
                                                                        <Grid
                                                                            container
                                                                            md={3}
                                                                            xs={12}
                                                                            spacing={1}
                                                                            style={{ margin: 0 }}
                                                                        >

                                                                            <Grid
                                                                                item
                                                                                md={4}
                                                                            >
                                                                                <div className={classes.selectRoot}>
                                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                                        Operation
                                                                                    </FormLabel>
                                                                                    <Select
                                                                                        name="operation"
                                                                                        value={x.operation}
                                                                                        onChange={e => handleOperationChange(e, i)}
                                                                                        displayEmpty
                                                                                        className={clsx({
                                                                                            [classes.select]: true
                                                                                        })}
                                                                                        classes={{
                                                                                            selectMenu: classes.selectedItem
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                                        </MenuItem>
                                                                                        {operationData.map((item) => {
                                                                                            return (
                                                                                                <MenuItem value={item.value} key={item.value}>
                                                                                                    {item.value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={8}
                                                                            >
                                                                                <FormLabel required className={classes.chargeBackLabel}>
                                                                                    {keyFigureObject.name ? keyFigureObject.name : 'Key figure Name'}
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name="key_figure_names"
                                                                                    value={x.key_figure_names}
                                                                                    onChange={e => handleOperationChange(e, i)}
                                                                                    displayEmpty
                                                                                    MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                    className={clsx({
                                                                                        [classes.select]: true
                                                                                    })}
                                                                                    classes={{
                                                                                        selectMenu: classes.selectedItem
                                                                                    }}
                                                                                    style={{ textTransform: 'capitalize' }}
                                                                                >
                                                                                    <MenuItem value="" style={{ height: 30 }}>
                                                                                        {keyFigureData && keyFigureData.length > 0 ? '' : '- Please configure -'}
                                                                                    </MenuItem>
                                                                                    {mappingValuesSet && Object.keys(mappingValuesSet).length > 0 &&
                                                                                        Object.entries(mappingValuesSet).map(([key, value]) => {
                                                                                            return (
                                                                                                <MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
                                                                                                    {value}
                                                                                                </MenuItem>
                                                                                            )
                                                                                        })}
                                                                                </Select>
                                                                            </Grid>
                                                                            <Button color="primary" size="small"
                                                                                classes={{
                                                                                    root: classes.removeButton
                                                                                }}
                                                                                startIcon={<DeleteForeverIcon />}
                                                                                onClick={() => handleRemoveOperation(i)}>Remove</Button>
                                                                        </Grid>
                                                                    );
                                                                })

                                                            }
                                                            <Grid
                                                                item
                                                                md={1}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Rate
                                                                    </FormLabel>
                                                                    <Tooltip
                                                                        open={tooltipIsOpen}
                                                                        title="Rate should be less than or equal to 100."
                                                                    >
                                                                        <OutlinedInput
                                                                            value={rate}
                                                                            type={'number'}
                                                                            inputProps={{ min: "0", step: "1" }}
                                                                            classes={{ root: classes.inputTwoLine }}
                                                                            onChange={handleRate} />
                                                                    </Tooltip>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={1}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Rate Type
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={rateType}
                                                                        onChange={(e) => setRateType(e.target.value)}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        <MenuItem value="" style={{ height: 30 }}>
                                                                        </MenuItem>
                                                                        {uomData.map((item) => {
                                                                            return (
                                                                                <MenuItem value={item.key} key={item.key}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </div>
                                                            </Grid>
                                                            {rateType === '$' &&
                                                                <Grid
                                                                    item
                                                                    md={1}
                                                                    xs={12}>
                                                                    <div className={classes.selectRoot}>
                                                                        <FormLabel className={classes.chargeBackLabel}>
                                                                            UoM
                                                                        </FormLabel>
                                                                        <Select
                                                                            value={uom}
                                                                            onChange={handleUom}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {generalUOMAll && generalUOMAll.length > 0 && generalUOMAll.map((item) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={item}
                                                                                        disabled={(item.value === '%' && props.calculationMethod === 'Fixed Amount') || (item.value === '$' && props.calculationMethod === 'Flat Rate' && keyfigurename != 'quantity') ? true : false}
                                                                                    >
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        Start Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveStartDate}
                                                                                onChange={date => handleEffectiveStartDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={props.startDate ? props.startDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={2}
                                                                xs={12}
                                                            >
                                                                <div className={classes.selectRoot}>
                                                                    <FormLabel required className={classes.chargeBackLabel}>
                                                                        End Date
                                                                    </FormLabel>
                                                                    <LocalizationProvider libInstance={momentt} dateAdapter={AdapterMoment}>
                                                                        <Grid container justifyContent="space-around">
                                                                            <DatePicker
                                                                                disableToolbar
                                                                                clearable
                                                                                InputProps={{
                                                                                    padding: 0,
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        padding: '1px 10 1px 11px',
                                                                                        alignSelf: 'center',
                                                                                        alignItems: 'center',
                                                                                        border: '1px solid #E0E0E0',
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                value={effectiveEndDate}
                                                                                onChange={date => handleEffectiveEndDate(date)}
                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                format="MM/DD/YYYY"
                                                                                minDate={effectiveStartDate ? effectiveStartDate : ''}
                                                                            />
                                                                        </Grid>
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                md={rateType === '$' ? 1 : 2}
                                                                xs={12}
                                                            >
                                                                <Grid container spacing={2}>
                                                                    <Grid
                                                                        item
                                                                        md={12}
                                                                    >
                                                                        <Grid container spacing={1} >
                                                                            <Grid item md={12} >
                                                                                <Grid container style={{ paddingBottom: 15, marginTop: 40 }} >
                                                                                    <Grid item md={12} style={{ display: 'flex' }}>
                                                                                        {editMode ?
                                                                                            <Button
                                                                                                color='secondary'
                                                                                                variant="contained"
                                                                                                startIcon={<EditIcon />}
                                                                                                classes={{
                                                                                                    startIcon: classes.startIcon,
                                                                                                    root: classes.checkIconContainer
                                                                                                }}
                                                                                                disabled={(props.calculationMethod === 'Fixed Amount' ? !keyfigurename : !(props.calculationMethod === 'Price Point' || formulaTypeTier === 'Formula' ?
                                                                                                    formulaName : formulaTypeTier && keyfigurename)) ||
                                                                                                    !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !rate || !rateType || operationArray.some(hasNull) ? true : false}


                                                                                                onClick={() => handleSubmit(editIndex)}
                                                                                            >
                                                                                            </Button>
                                                                                            :
                                                                                            <Button
                                                                                                color='secondary'
                                                                                                variant="contained"
                                                                                                startIcon={<CheckIcon />}
                                                                                                classes={{
                                                                                                    startIcon: classes.startIcon,
                                                                                                    root: classes.checkIconContainer
                                                                                                }}
                                                                                                disabled={(props.calculationMethod === 'Fixed Amount' ? !keyfigurename : !(props.calculationMethod === 'Price Point' || formulaTypeTier === 'Formula' ?
                                                                                                    formulaName : formulaTypeTier && keyfigurename)) ||
                                                                                                    !(effectiveStartDate && Moment(effectiveStartDate).isValid() && effectiveEndDate && (Moment(effectiveEndDate).isValid()) && (Moment(effectiveEndDate) >= Moment(effectiveStartDate)) && (props.startDate ? Moment(effectiveStartDate) >= Moment(props.startDate) : true)) || !rate || !rateType || operationArray.some(hasNull) ? true : false}
                                                                                                onClick={() => handleSubmit('')}
                                                                                            >
                                                                                            </Button>
                                                                                        }
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            startIcon={<DeleteForeverIcon />}
                                                                                            classes={{
                                                                                                startIcon: classes.startIcon,
                                                                                                root: classes.deleteIconContainer,
                                                                                            }}
                                                                                            onClick={handleRemove}
                                                                                            style={{ marginLeft: 10 }}
                                                                                        >
                                                                                        </Button>
                                                                                    </Grid>

                                                                                    {props.calculationMethod != 'Price Point' && formulaTypeTier != 'Formula' &&
                                                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                                                                            <Tooltip title="Add Key Figure">
                                                                                                <Button color="primary" size="small"
                                                                                                    startIcon={<AddIcon style={{ marginRight: 2 }} />}
                                                                                                    onClick={handleAddOperation}> Key Figure</Button>
                                                                                            </Tooltip>
                                                                                        </div>}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </form>
                                            </Card>
                }
            </div >
            {/* calculation Rules Add ends here */}
            {/* calculation Rules Table starts here */}
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="CALCULATION RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                </div>
                <div className={classes.container2}>
                    {props.calculationMethod === 'Price Difference' ?
                        <Grid container >
                            <Grid
                                item
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {calculationStore.length > 0 ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                    <TableCell align='center'>Acquisition Price</TableCell>
                                                    <TableCell align='center'>Contract Price</TableCell>
                                                    <TableCell align='center'>Rate Type</TableCell>
                                                    <TableCell align='center'>UoM</TableCell>
                                                    <TableCell align='center'>Start Date</TableCell>
                                                    <TableCell align='center'>End Date</TableCell>
                                                    <TableCell align='center'>Options</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {calculationStore.map((item, index) => {
                                                    return (
                                                        <StyledTableRow key={item}>
                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>Material Number</TableCell>
                                                            <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ? item.attribute_key_values[0].attribute_value : ''}</TableCell>
                                                            <TableCell align='center' >{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].acquisition_price ? item.attribute_key_values[0].acquisition_price : ''}</TableCell>
                                                            <TableCell align='center'>{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].contract_price ? item.attribute_key_values[0].contract_price.toFixed(2) : ''}</TableCell>
                                                            <TableCell align='center'>{item.rate_type}</TableCell>
                                                            <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                            <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton
                                                                    onClick={() => editcalculationRule(item, index, 'CB')}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => handleDeleteLocally(index)}
                                                                    classes={{ root: classes.IconButton }}
                                                                    size="large">
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                                {/* <IconButton onClick={() => editcalculationRule(item)} classes={{ root: classes.IconButton }}>
                                                                    <img src={require('../../../../library/images/Copy.png')} alt='' style={{ paddingLeft: 10 }} />
                                                                </IconButton> */}
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography variant='h4'>
                                        There is no data to show now.
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        :
                        props.calculationSchema === 'Flat % - Multiple Rates per Contract'
                            || props.calculationSchema === 'Flat $ - Multiple Amounts per Contract'
                            || props.calculationSchema === 'BOM Explosion' ?
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {calculationStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                        <TableCell align='center'>Attribute Value</TableCell>
                                                        <TableCell align='center'>Formula Type</TableCell>
                                                        <TableCell align='center'>Formula</TableCell>
                                                        <TableCell align='center'>Key Figure Name</TableCell>
                                                        <TableCell align='center'>Rate</TableCell>
                                                        <TableCell align='center'>Rate Type</TableCell>
                                                        <TableCell align='center'>UoM</TableCell>
                                                        <TableCell align='center'>Start Date</TableCell>
                                                        <TableCell align='center'>End Date</TableCell>
                                                        <TableCell align='center'>Options</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {calculationStore.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={item}>
                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}> {item.attribute_names && item.attribute_names[0] && item.attribute_names && attributeNameArray[item.attribute_names[0]]}</TableCell>
                                                                <TableCell align='center'>{item.attribute_values}</TableCell>
                                                                <TableCell align='center'>{item.formula_type}</TableCell>
                                                                <TableCell align='center'>{item.formula_id ?
                                                                    props.formulaListData.records.map((item3) => {
                                                                        return item3.ID === item.formula_id ? item3.formula_name : null
                                                                    }) : ''}
                                                                </TableCell>
                                                                <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                    {/* {item.key_figure_names ? item.key_figure_names.join(',').replace(/_/g, ' ') : ''} */}
                                                                    {item.key_figure_names ?
                                                                        item.key_figure_names.map((item) => {
                                                                            return mappingValuesSet[item] + '  '
                                                                        })
                                                                        :
                                                                        ''
                                                                    }
                                                                </TableCell>
                                                                <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                <TableCell align='center'>{item.rate_type}</TableCell>
                                                                <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                <TableCell align='center'>
                                                                    <IconButton
                                                                        onClick={() => editcalculationRule(item, index, 'MR')}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteLocally(index)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                    {/* <IconButton onClick={() => editcalculationRule(item)} classes={{ root: classes.IconButton }}>
                                                                        <img src={require('../../../../library/images/Copy.png')} alt='' style={{ paddingLeft: 10 }} />
                                                                    </IconButton> */}
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                            There is no data to show now.
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            :

                            props.calculationMethod === 'Tiered Volume' || props.calculationMethod === 'Tiered Growth'
                                ?
                                <Grid container >
                                    <Grid
                                        item
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {calculationStore.length > 0 ?
                                            <TableContainer>
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell align='center'>Calculation Type</TableCell>
                                                            <TableCell align='center'>Formula Type</TableCell>
                                                            <TableCell align='center'>Key Figure Name</TableCell>
                                                            <TableCell align='center'>Formula</TableCell>
                                                            {/* {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                <TableCell align='center'>Maximum Amount</TableCell>
                                                            } */}
                                                            <TableCell align='center'>Rate Type</TableCell>
                                                            <TableCell align='center'>UoM</TableCell>
                                                            <TableCell align='center'>Start Date</TableCell>
                                                            <TableCell align='center'>End Date</TableCell>
                                                            <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {calculationStore
                                                            .sort(function (a, b) {
                                                                return calculationTypeData.indexOf(a.type) - calculationTypeData.indexOf(b.type);
                                                            })
                                                            //.sort((a, b) => (a.type.localeCompare(b.type) || a.attribute_values && a.attribute_values[0] && b.attribute_values && b.attribute_values[0] && (parseFloat(a.attribute_values[0]) - parseFloat(b.attribute_values[0])) || a.rate - b.rate || new Date(a.effective_start_date) - new Date(b.effective_start_date)))
                                                            .map((item, index) => {
                                                                return (
                                                                    <StyledTableRow key={item}>
                                                                        <TableCell align='center' >  {item.type}</TableCell>
                                                                        <TableCell align='center'>{item.formula_id ? 'Formula' : 'Key Figure'}</TableCell>
                                                                        <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                            {item.key_figure_names ?
                                                                                item.key_figure_names.map((item2, i) => {
                                                                                    return (<> {item.key_figure_names[i] && mappingValuesSet[item.key_figure_names[i]]
                                                                                        // checkLabel(item.key_figure_names[i]) ? checkLabel(item.key_figure_names[i]) : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                    } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                })
                                                                                :
                                                                                ''
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align='center' >
                                                                            {props.formulaListData.records.map((item2) => {
                                                                                return item2.ID === item.formula_id ? item2.formula_name : null
                                                                            }
                                                                            )
                                                                            }
                                                                        </TableCell>
                                                                        {/* {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                            <TableCell align='center'>{parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>

                                                                        } */}
                                                                        <TableCell align='center'>{item.rate_type}</TableCell>
                                                                        <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                        <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                        <TableCell align='center'>
                                                                            <IconButton
                                                                                onClick={() => editcalculationRule(item, index, 'Tiered Volume - Simple')}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() => handleDeleteLocally(index, 'tier')}
                                                                                classes={{ root: classes.IconButton }}
                                                                                size="large">
                                                                                <DeleteForeverIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </StyledTableRow>
                                                                );
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <Typography variant='h4'>
                                                There is no data to show now.
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                :
                                props.calculationMethod === 'Multi Axis'
                                    ?
                                    <Grid container >
                                        <Grid
                                            item
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            {calculationStore.length > 0 ?
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell align='center'>Calculation Type</TableCell>
                                                                <TableCell align='center'>Formula Type</TableCell>
                                                                <TableCell align='center'>Key Figure Name - Rebate</TableCell>
                                                                <TableCell align='center'>Formula Name - Rebate</TableCell>
                                                                {props.incentiveRateType === 'Conditional Attribute' &&
                                                                    <>
                                                                        <TableCell align='center' >Attribute Name</TableCell>
                                                                        <TableCell align='center' >Conditional Attribute Count</TableCell>
                                                                    </>
                                                                }
                                                                {Array.apply(null, {
                                                                    length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                }).map((e, i) => (
                                                                    <>
                                                                        <TableCell align='center' >{props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Key Figure Name' : 'Key Figure Name'}</TableCell>
                                                                        <TableCell align='center' >Key Figure Value</TableCell>
                                                                    </>
                                                                ))}
                                                                {props.incentiveRateType !== 'Conditional Attribute' && Array.apply(null, {
                                                                    length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                }).map((e, i) => (
                                                                    <>
                                                                        <TableCell align='center' >Attribute Name</TableCell>
                                                                        <TableCell align='center' >Attribute Value</TableCell>
                                                                    </>
                                                                ))}
                                                                {Array.apply(null, {
                                                                    length: formulaArrayMXSColumns
                                                                        ? formulaArrayMXSColumns : null
                                                                }).map((e, i) => (
                                                                    <>
                                                                        <TableCell align='center' >{props.incentiveRateType === 'Conditional Attribute' ? 'Conditional Formula Name' : 'Formula Name'}</TableCell>
                                                                        <TableCell align='center' >Formula Value</TableCell>
                                                                    </>
                                                                ))}
                                                                {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                    <TableCell align='center'>Maximum Amount</TableCell>
                                                                }
                                                                <TableCell align='center'>Rate</TableCell>
                                                                <TableCell align='center'>Start Date</TableCell>
                                                                <TableCell align='center'>End Date</TableCell>
                                                                <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody classes={{ root: classes.table }} >
                                                            {calculationStore
                                                                .sort(function (a, b) {
                                                                    return calculationTypeDataMXS.indexOf(a.type) - calculationTypeDataMXS.indexOf(b.type);
                                                                })
                                                                //.sort((a,b)=>a.type > b.type && a.effective_start_date < b.effective_start_date?1:-1)
                                                                //.sort((a,b)=>a.type>b.type&&a.effective_start_date<b.effective_start_date&&a.effective_end_date>b.effective_end_date?1:-1)                       
                                                                // .sort((a, b) => (a.type.localeCompare(b.type) || a.attribute_values && a.attribute_values[0] && b.attribute_values && b.attribute_values[0] && (parseFloat(a.attribute_values[0]) - parseFloat(b.attribute_values[0])) || a.rate - b.rate || new Date(a.effective_start_date) - new Date(b.effective_start_date)))
                                                                .map((item, index) => {
                                                                    return (
                                                                        <StyledTableRow key={item}>
                                                                            <TableCell align='center'>{item.type}</TableCell>
                                                                            <TableCell align='center'>{item.formula_type}</TableCell>
                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>{
                                                                                item.type === 'Rebate' &&
                                                                                    item.key_figure_names && item.key_figure_names && item.key_figure_names[0]
                                                                                    ? mappingValuesSet[item.key_figure_names[0]]
                                                                                    // checkLabel(item.key_figure_names[0]) ? checkLabel(item.key_figure_names[0]) :
                                                                                    //     item.key_figure_names[0].replace(/_/g, ' ') 
                                                                                    : ''}</TableCell>
                                                                            <TableCell>
                                                                                {item.type === 'Rebate' && item.formula_id &&
                                                                                    props.formulaListData.records.map((item3) => {
                                                                                        return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                    })}
                                                                            </TableCell>
                                                                            {props.incentiveRateType === 'Conditional Attribute' &&
                                                                                <>
                                                                                    <TableCell style={{ textTransform: 'capitalize' }}>
                                                                                        {item.attribute_name ? attributeNameArray && attributeNameArray[item.attribute_name] ? attributeNameArray[item.attribute_name] : item.attribute_name.replace(/_/g, ' ') : ''}
                                                                                    </TableCell>
                                                                                    <TableCell>{item.attribute_count ? parseFloat(item.attribute_count).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                                                </>
                                                                            }
                                                                            {Array.apply(null, {
                                                                                length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                            }).map((e, i) => (
                                                                                <>
                                                                                    <TableCell style={{ textTransform: 'capitalize' }}>{
                                                                                        item.type === 'Tier' &&
                                                                                        item.key_figure_values && item.key_figure_values[i] && item.key_figure_values[i].key &&
                                                                                        mappingValuesSet[item.key_figure_values[i].key]
                                                                                    }
                                                                                    </TableCell>
                                                                                    <TableCell>{item.type === 'Tier' && item.key_figure_values &&
                                                                                        item.key_figure_values[i] &&
                                                                                        item.key_figure_values[i].value &&
                                                                                        parseFloat(item.key_figure_values[i].value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                    </TableCell>
                                                                                </>
                                                                            ))}
                                                                            {props.incentiveRateType !== 'Conditional Attribute' && Array.apply(null, {
                                                                                length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                            }).map((e, i) => (
                                                                                <>
                                                                                    <TableCell style={{ textTransform: 'capitalize' }}>{item.type === 'Tier' && item.attribute_values &&
                                                                                        item.attribute_values[i] &&
                                                                                        item.attribute_values[i].attribute_names ? attributeNameArray && attributeNameArray[item.attribute_values[i].attribute_names] ? attributeNameArray[item.attribute_values[i].attribute_names] :
                                                                                        item.attribute_values[i].attribute_names.replace(/_/g, ' ') : ''}</TableCell>
                                                                                    <TableCell>{item.type === 'Tier' && item && item.attribute_values && item.attribute_values[i] && item.attribute_values[i].attribute_values}</TableCell>
                                                                                </>
                                                                            ))}
                                                                            {Array.apply(null, {
                                                                                length: formulaArrayMXSColumns
                                                                                    ? formulaArrayMXSColumns : null
                                                                            }).map((e, i) => (
                                                                                <>
                                                                                    <TableCell>
                                                                                        {item.formula_values && item.formula_values[i] && item.formula_values[i].formula_values &&
                                                                                            props.formulaListData.records.map((item3) => {
                                                                                                return item3.ID === item.formula_values[i].formula_names ? item3.formula_name : null
                                                                                            })}
                                                                                    </TableCell>
                                                                                    <TableCell>{item.formula_values && item.formula_values[i] &&
                                                                                        item.formula_values[i].formula_values &&
                                                                                        parseFloat(item.formula_values[i].formula_values).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                    </TableCell>
                                                                                </>
                                                                            ))}
                                                                            {props.maximumAmountPeriod === 'Payment Period - Tier' &&
                                                                                <TableCell align='center'>{item.maximum_amount ? parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>

                                                                            }
                                                                            <TableCell align='center'>{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center'>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <IconButton
                                                                                        onClick={() => editcalculationRule(item, index, 'Multi Axis')}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                    {item.type == 'Tier' &&
                                                                                        <IconButton onClick={() => clone(item, index)} classes={{ root: classes.IconButton }} size={'small'}>
                                                                                            <img src={require('../../../../library/images/Copy.png')} alt='' />
                                                                                        </IconButton>
                                                                                    }
                                                                                    <IconButton
                                                                                        onClick={() => handleDeleteLocally(index, 'Multi Axis')}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </TableCell>
                                                                        </StyledTableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                :
                                                <Typography variant='h4'>
                                                    There is no data to show now.
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    props.calculationMethod === 'Fixed Amount' && props.incentiveRateType === 'Attribute' ?
                                        <Grid container >
                                            <Grid
                                                item
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                {calculationStore.length > 0 ?
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead >
                                                                <TableRow >
                                                                    <TableCell align='center' style={{ textTransform: 'capitalize' }}>Attribute Name</TableCell>
                                                                    <TableCell align='center'>Attribute Value</TableCell>
                                                                    <TableCell classes={{ root: classes.leftAlign }}>Key Figure Name</TableCell>
                                                                    <TableCell align='center'>Rate</TableCell>
                                                                    <TableCell align='center'>Rate Type</TableCell>
                                                                    <TableCell align='center'>UoM</TableCell>
                                                                    <TableCell align='center' >Start Date</TableCell>
                                                                    <TableCell align='center' >End Date</TableCell>
                                                                    <TableCell align='center'>Options</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody classes={{ root: classes.table }} >
                                                                {calculationStore.map((item, index) => {
                                                                    return (
                                                                        <StyledTableRow key={item}>

                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>

                                                                                {item.attribute_names && item.attribute_names[0] && item.attribute_names[0] ?
                                                                                    attributeNameArray && attributeNameArray[item.attribute_names[0]] : item.attribute_names[0].replace(/_/g, ' ')
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align='center'>{item.attribute_values && item.attribute_values[0] ? item.attribute_values[0] : ''}</TableCell>
                                                                            <TableCell classes={{ root: classes.leftAlign }}>
                                                                                {item.key_figure_names ?
                                                                                    item.key_figure_names.map((item2, i) => {
                                                                                        return (<> {mappingValuesSet[item2]} {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                    })
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align='center'>{item.rate ? item.rate.toFixed(4) : ''}</TableCell>
                                                                            <TableCell align='center'>{item.rate_type}</TableCell>
                                                                            <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center'>
                                                                                <IconButton
                                                                                    onClick={() => editcalculationRule(item, index)}
                                                                                    classes={{ root: classes.IconButton }}
                                                                                    size="large">
                                                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    onClick={() => handleDeleteLocally(index)}
                                                                                    classes={{ root: classes.IconButton }}
                                                                                    size="large">
                                                                                    <DeleteForeverIcon />
                                                                                </IconButton>
                                                                                {/* <IconButton onClick={() => editcalculationRule(item)} classes={{ root: classes.IconButton }}>
                                                                        <img src={require('../../../../library/images/Copy.png')} alt='' style={{ paddingLeft: 10 }} />
                                                                    </IconButton> */}
                                                                            </TableCell>
                                                                        </StyledTableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <Typography variant='h4'>
                                                        There is no data to show now.
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>


                                        :
                                        (props.calculationSchema === 'Quota Achievement' || props.calculationSchema === 'PPM') ?
                                            <Grid container >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {calculationStore.length > 0 ?
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead >
                                                                    <TableRow >
                                                                        <TableCell align='center'>Key Figure Name</TableCell>
                                                                        <TableCell align='center'>Rate Type</TableCell>
                                                                        <TableCell align='center'>UoM</TableCell>
                                                                        <TableCell align='center'>Start Date</TableCell>
                                                                        <TableCell align='center'>End Date</TableCell>
                                                                        <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody classes={{ root: classes.table }} >
                                                                    {calculationStore
                                                                        .map((item, index) => {
                                                                            return (
                                                                                <StyledTableRow key={item}>
                                                                                    <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                        {item.key_figure_names ?
                                                                                            item.key_figure_names.map((item2, i) => {
                                                                                                return (<> {item.key_figure_names[i] && mappingValuesSet[item.key_figure_names[i]]
                                                                                                    //checkLabel(item.key_figure_names[i]) ? checkLabel(item.key_figure_names[i]) : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                                } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                            })
                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                    </TableCell>

                                                                                    <TableCell align='center'>{item.rate_type}</TableCell>
                                                                                    <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                                    <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    <TableCell align='center'>
                                                                                        <IconButton
                                                                                            onClick={() => editcalculationRule(item, index, 'Quota')}
                                                                                            classes={{ root: classes.IconButton }}
                                                                                            size="large">
                                                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                        </IconButton>
                                                                                        <IconButton
                                                                                            onClick={() => handleDeleteLocally(index, 'tier')}
                                                                                            classes={{ root: classes.IconButton }}
                                                                                            size="large">
                                                                                            <DeleteForeverIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </StyledTableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography variant='h4'>
                                                            There is no data to show now.
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {calculationStore.length > 0 ?
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead >
                                                                    <TableRow >
                                                                        {props.calculationMethod === 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                            <TableCell classes={{ root: classes.leftAlign }}>Formula Type</TableCell>
                                                                        }
                                                                        {props.calculationMethod !== 'Fixed Amount' && <TableCell classes={{ root: classes.leftAlign }}>Formula</TableCell>}
                                                                        {props.calculationMethod !== 'Price Point' && <TableCell classes={{ root: classes.leftAlign }}>Key Figure Name</TableCell>}
                                                                        <TableCell align='center'>Rate</TableCell>
                                                                        <TableCell align='center'>Rate Type</TableCell>
                                                                        <TableCell align='center'>UoM</TableCell>
                                                                        <TableCell align='center' >Start Date</TableCell>
                                                                        <TableCell align='center' >End Date</TableCell>
                                                                        <TableCell align='center' style={{ width: 100 }}>Options</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody classes={{ root: classes.table }} >
                                                                    {calculationStore.map((item, index) => {
                                                                        return (
                                                                            <StyledTableRow key={item}>
                                                                                {props.calculationMethod === 'Flat Rate' && props.incentiveRateType == 'Contract' &&
                                                                                    <TableCell align='center'>{item.formula_id ? 'Formula' : 'Key Figure'}</TableCell>}
                                                                                {props.calculationMethod !== 'Fixed Amount' && <TableCell align='center'>{props.calculationMethod === 'Price Point' ?
                                                                                    props.pricingFormulaList && props.pricingFormulaList.records && props.pricingFormulaList.records.map((item2) => {
                                                                                        return item2.ID === item.formula_id ? item2.formula_name : null
                                                                                    })
                                                                                    :
                                                                                    item.formula_id ?
                                                                                        props.formulaListData.records.map((item3) => {
                                                                                            return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                        }) : ''}
                                                                                </TableCell>}
                                                                                {props.calculationMethod !== 'Price Point' &&
                                                                                    <TableCell classes={{ root: classes.leftAlign }}>
                                                                                        {
                                                                                            item.key_figure_names ?
                                                                                                item.key_figure_names.map((item2, i) => {
                                                                                                    return (<> {
                                                                                                        // checkLabel(item2) ? checkLabel(item2)/*.filter(i => i)*/ :
                                                                                                        mappingValuesSet[item2]
                                                                                                    } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>)
                                                                                                })
                                                                                                :
                                                                                                ''
                                                                                        }
                                                                                    </TableCell>}
                                                                                <TableCell align='center'>{item.rate ? item.rate.toFixed(4) : ''}</TableCell>
                                                                                <TableCell align='center'>{item.rate_type}</TableCell>
                                                                                <TableCell align='center'>{item.incentive_uom}</TableCell>
                                                                                <TableCell align='center'>{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center'>{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center'>
                                                                                    <IconButton
                                                                                        onClick={() => editcalculationRule(item, index)}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        onClick={() => handleDeleteLocally(index)}
                                                                                        classes={{ root: classes.IconButton }}
                                                                                        size="large">
                                                                                        <DeleteForeverIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                            </StyledTableRow>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography variant='h4'>
                                                            There is no data to show now.
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                    }
                </div>
            </Card >
            {/* calculation Rules Table Ends here */}
        </div >
    );

});

const mapDispatchToProps = dispatch => {
    return {

        onCalculationRulesEdit: (data, id) => dispatch(editCalculationRules(data, id)),
        onCheckCalculationRulesTable: () => dispatch(getCalculationRulesTableData(localStorage.getItem('currentAddContractID'))),
        getFormulasList: () => dispatch(getFormulasList(1, 1000)),
        //getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getDefaultValuesAllDataCR: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE', 'CR')),
        onLoadingDefaultCR: (applicationType, sourceDataType) => dispatch(getDefaultValuesKeyFigure(applicationType, sourceDataType, 'on', 'key_figure_name', 'CR')),
        loadDefaultContract: (applicationType, fieldId) => dispatch(getInitialDataFromSourceDataType(applicationType, fieldId, 'contract')),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getGeneralUOM: () => dispatch(getGeneralUOM()),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit)),
        getPricingCalculationFormula: (page, limit) => dispatch(getPriceFormula(page, limit)),
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        onLoadingAttribute: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        //onLoadingDefault: () => dispatch(getDefaultValues()),
        uploadCalculationRules: (file, calculationMethod, appType, sourceDataType) => dispatch(uploadCalculationRules(file, calculationMethod, appType, sourceDataType)),


    }
}

const mapStateToProps = state => {
    return {
        tableData: state.addContractData.calculationRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        formulaListData: state.FormulaLabData.formulaListData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        formFieldsAllDataCR: state.addMultipleConfigurationData.formFieldsAllDataCR,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
        customerMasterData: state.customerData.customerMasterData,
        materialMasterData: state.customerData.materialMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        generalUOMAll: state.customerData.generalUOMAll,
        employeeMasterData: state.customerData.employeeMasterData,
        pricingFormulaList: state.pricingData.priceFormulaList,
        uploadedRules: state.addContractData.calculationRulesUploadData,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculationRules);