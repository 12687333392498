import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import "../BSNavbar.css";
import { getProfile } from "../../../../../../../redux/actions";
import { connect } from "react-redux";

const handleClick = (pge) => {
  if (pge === "/reporting-on-roll") {
    history.push({
      pathname: "/reporting-on-roll",
      state: "deleteData",
    });
  }
  else {
    history.push(pge);
  }
};

const menuItems = [
  { Dashboard: "/dashboard" },
  {
    Contracts: [
      { "Contract Setup": "/contract-setup" },
      { "Contract Approval": "/approval-reject" },
      { "Contract Log": '/contract-change-reports' },
      { "Expiring Contracts": "/expiring-contracts" },
      { "Expired Contracts": "/expired-contracts" },
      { "Document Management": "/documents" },
    ],
  },
  {
    "Calculation Management": [
      { "Formula Lab": "/formula-lab" },
      { "Calculation Simulation": "/calculation-simulation" },
      { "Calculation Analysis": "/contract-trends" },
      { "Exception Management": "/exception-management" },
      { "Exception Approval": "/exception-approval" },
      { "Quota Management": "/quota-management" },
      { "External Formula": "/external-formula-results" },
      { "Rebate Estimator": "/next-tier-simulation" },
    ],
  },
  {
    Accruals: [
      { "Accrual Postings": "/financial-postings" },
      { "Accrual Approvals": "/accrual-approvals" },
    ],
  },
  {
    Payments: [
      { "Payment Postings": "/payment-postings" },
      { "Payment Approvals": "/payment-approvals" },
      { "Partner Statement": "/partner-statement" },
    ],
  },
  {
    "Reporting & Analytics": [
      { "Operational Reports": "/select-operational-reports" },
      { "On Demand Queries": "/dynamic-query" },
      { "On Demand Analytics": "/on-demand-analytics" },
      { "Predefined Queries": "/predefined-query" },
      { "Analytics & Dashboard": "/analytics-main" },
      { "Process Reporting": "/reporting-on-roll" },

    ],
  },
  {
    "Utilities": [
      { "Batch Job Setup": "/batchjob-setup" },
      { "Batch Job Status": "/batchjob-status" },
      { "On Demand Videos": "/help-desk-cp" },
      { "External Accrual Posting": "/external-accural-postings" },
      { "External Payment Posting": "/external-payment-postings" },
    ],
  },
];

const CustomerRebate = (props) => {
  return (
    <Nav
      className="me-auto my-2 my-lg-0"
      style={{
        maxHeight: "100px",
        display: "flex",
        justifyContent: "space-between",
        margin: "auto",
      }}
      navbarScroll
    >
      {menuItems.map((item, i) => {
        if (typeof Object.values(item)[0] === "string")
          return (
            <Nav.Link
              onClick={() => {
                handleClick(Object.values(item)[0]);
              }}
              style={{
                borderRight:
                  i !== menuItems.length - 1 ? "1px solid lightgrey" : "none ",
              }}
            >
              {Object.keys(item)[0]}
            </Nav.Link>
          );
        else {
          return (
            <NavDropdown
              title={Object.keys(item)[0]}
              style={{
                borderRight:
                  i !== menuItems.length - 1 ? "1px solid lightgrey" : "none ",
              }}
            >
              {Object.values(item)[0].map((e) => {
                return (
                  <NavDropdown.Item
                    onClick={() => {
                      handleClick(Object.values(e)[0]);
                    }}
                  >
                    {Object.keys(e)[0]}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          );
        }
      })}
    </Nav>
  );
};

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.profileData,
    helpDeskData: state.HelpDeskData.HelpDeskData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRebate);
