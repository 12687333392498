import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    Select, MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, getDefaultValuesAllDataNew } from '../../../../../redux/actions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 10px 10px'
    },
    gridContainer: {
        padding: '0px 30px 0px 30px'
    },
    selectRoot: {
        padding: '18px 0px 5px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
}));

const Accrual = forwardRef((props, ref) => {

    const internalReferenceRef = useRef(null);
    const [internalReference, setInternalReference] = React.useState('');
    const postingsBlockRef = useRef(null);
    const [postingsBlock, setPostingsBlock] = React.useState('');
    const financialPostingsStartDateRef = useRef(null);
    const [financialPostingsStartDate, setFinancialPostingsStartDate] = React.useState('');
    const financialPostingsEndDateRef = useRef(null);
    const [financialPostingsEndDate, setFinancialPostingsEndDate] = React.useState('');
    const [accrualCreditProfitCenter, setAccrualCreditProfitCenter] = React.useState('');
    const [accrualDebitProfitCenter, setAccrualDebitProfitCenter] = React.useState('');



    const classes = useStyles();
    const { className } = props;
    const [accrualFrequency, setAccrualFrequency] = React.useState('');
    const [accrualLevel, setAccrualLevel] = React.useState('');
    const [copaLevel, setCopaLevel] = React.useState('');
    const [accrualCalendar, setAccrualCalendar] = React.useState('');
    const [accrualGLCredit, setAccrualGLCredit] = React.useState('');
    const [accrualGLDebit, setAccrualGLDebit] = React.useState('');
    const [autoRunPpa, setAutoRunPpa] = React.useState('');
    const [flexFields4, setFlexFields4] = React.useState('');
    const [accrualRun, setAccrualRun] = React.useState('');
    var accrualValues = {
        internal_reference: internalReference,
        postings_block: postingsBlock,
        postings_start_date: financialPostingsStartDate,
        postings_end_date: financialPostingsEndDate,
        accrual_frequency: accrualFrequency,
        accrual_level: accrualLevel,
        copa_level: copaLevel,
        auto_run_ppa: autoRunPpa,
        accrual_calendar: accrualCalendar,
        accrual_gl_account_credit: accrualGLCredit,
        accrual_gl_account_debit: accrualGLDebit,
        accrual_credit_profit_center: accrualCreditProfitCenter,
        accrual_debit_profit_center: accrualDebitProfitCenter,
        flex_fields_4: flexFields4,
        accrual_run: accrualRun
    };
    var mode = localStorage.getItem('mode');
    const accrualFrequencylRef = useRef(null);
    const accrualLevelRef = useRef(null);
    const copaRef = useRef(null);
    const accrualCalendarRef = useRef(null);
    const accrualCreditRef = useRef(null);
    const accrualDebitRef = useRef(null);

    useImperativeHandle(ref, () => ({
        validationCheck() {
            if (!accrualFrequencylRef.current.value || accrualFrequencylRef.current.value === '')
                setAccrualFrequency(false);
            if (!accrualLevelRef.current.value || accrualLevelRef.current.value === '')
                setAccrualLevel(false);
            if (!accrualCalendarRef.current.value || accrualCalendarRef.current.value === '')
                setAccrualCalendar(false);
            if (!copaRef.current.value || copaRef.current.value === '')
                setCopaLevel(false);
            if (!accrualCreditRef.current.value || accrualCreditRef.current.value === '')
                setAccrualGLCredit(false);
            if (!accrualDebitRef.current.value || accrualDebitRef.current.value === '')
                setAccrualGLDebit(false);
            //new values
            if (!internalReferenceRef.current.value || internalReferenceRef.current.value === '')
                setInternalReference(false);
            if (!postingsBlockRef.current.value || postingsBlockRef.current.value === '')
                setPostingsBlock(false);
            if (!financialPostingsStartDateRef.current.value || financialPostingsStartDateRef.current.value === '')
                setFinancialPostingsStartDate(false);
            if (!financialPostingsEndDateRef.current.value || financialPostingsEndDateRef.current.value === '')
                setFinancialPostingsEndDate(false);
        },
        getValues() {
            accrualValues = {
                postings_block: postingsBlockRef.current.value,
                accrual_frequency: accrualFrequencylRef.current.value,
                accrual_level: accrualLevelRef.current.value,
                copa_level: copaRef.current.value,
                accrual_calendar: accrualCalendarRef.current.value,
                accrual_gl_account_credit: accrualCreditRef.current.value,
                accrual_gl_account_debit: accrualDebitRef.current.value,
                //new values
                internal_reference: internalReferenceRef.current.value,
                postings_start_date: financialPostingsStartDateRef.current.value,
                postings_end_date: financialPostingsEndDateRef.current.value,
            };
            return accrualValues;
        }
    }));

    return (
        <div
            className={clsx(classes.root)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="ACCRUAL"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    {props.data && props.fieldData &&
                        <div className={classes.container}>
                            <Grid container className={classes.gridContainer}>
                                {props.data.accrual_level && props.fieldData.accrual_level &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_level.current} data={props.data.accrual_level} twoline='true' />
                                    </Grid>
                                }
                                {props.data.copa_level && props.fieldData.copa_level &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.copa_level.current} data={props.data.copa_level} twoline='true' />
                                    </Grid>
                                }
                                {props.data.accrual_frequency && props.fieldData.accrual_frequency &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_frequency.current} data={props.data.accrual_frequency} twoline='true' />
                                    </Grid>
                                }
                                {props.data.accrual_calendar && props.data.accrual_calendar != '0001-01-01T00:00:00Z' && props.fieldData.accrual_calendar &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_calendar.current} data={props.data.accrual_calendar} twoline='true' />
                                    </Grid>
                                }
                                {props.data.internal_reference && props.fieldData.internal_reference &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.internal_reference.current} data={props.data.internal_reference} twoline='true' />
                                    </Grid>
                                }
                                {props.data.accrual_gl_account_credit && props.fieldData.accrual_gl_account_credit &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_gl_account_credit.current} data={props.data.accrual_gl_account_credit} twoline='true' />
                                    </Grid>
                                }

                                {props.data.accrual_gl_account_debit && props.fieldData.accrual_gl_account_debit &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_gl_account_debit.current} data={props.data.accrual_gl_account_debit} twoline='true' />
                                    </Grid>
                                }

                                {props.data.accrual_credit_profit_center && props.fieldData.accrual_credit_profit_center &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_credit_profit_center.current} data={props.data.accrual_credit_profit_center} twoline='true' />
                                    </Grid>
                                }
                                {props.data.accrual_debit_profit_center && props.fieldData.accrual_debit_profit_center &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_credit_profit_center.current} data={props.data.accrual_debit_profit_center} twoline='true' />
                                    </Grid>
                                }
                                {props.data && props.data.accrual_run && props.fieldData.accrual_run &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.accrual_run.current} data={props.data.accrual_run} twoline='true' />
                                    </Grid>
                                }
                                {props.data && props.data.flex_fields_4 && props.fieldData.flex_fields_4 &&
                                    <Grid
                                        item
                                        md={2.4}
                                        xs={6}
                                    >
                                        <LabelText heading={props.fieldData.flex_fields_4.current} data={props.data.flex_fields_4} twoline='true' />
                                    </Grid>
                                }
                                {props.data.posting_schema == 'Payment then Amortization' &&
                                    <>
                                        {props.data && props.data.short_term_period && props.fieldData.short_term_period &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.short_term_period.current} data={props.data.short_term_period} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data && props.data.long_term_period && props.fieldData.long_term_period &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.long_term_period.current} data={props.data.long_term_period} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data && props.data.short_term_account && props.fieldData.short_term_account &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.short_term_account.current} data={props.data.short_term_account} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data && props.data.long_term_account && props.fieldData.long_term_account &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.long_term_account.current} data={props.data.long_term_account} twoline='true' />
                                            </Grid>
                                        }
                                        {props.data && props.data.amortization_value && props.fieldData.amortization_value &&
                                            <Grid
                                                item
                                                md={2.4}
                                                xs={6}
                                            >
                                                <LabelText heading={props.fieldData.amortization_value.current} data={props.data.amortization_value} twoline='true' />
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        </div>
                    }
                </form>
            </Card>
        </div>
    );

});

// const mapStateToProps = state => ({
//     //data: state.addContractData.contractGeneralData,
//    // dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
//     //labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew
// });

// const mapDispatchToProps = dispatch => {
//     return {
//         getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
//         onLoadingDefault: () => dispatch(getDefaultValues()),
//         onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),

//     }
// }

export default connect(null, null, null, { forwardRef: true })(Accrual);
