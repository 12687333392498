import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    IconButton,
    Card,
    FormLabel,
    MenuItem,
    Select,
    Breadcrumbs
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { loadValuesBasedOnAppTypeAndField, getDefaultValuesCustomerXRef, getDefaultValues, addKeyFigureDefaultDesigner, getDefaultValuesAllData, getLblDispDesFieldValue, allowedApps } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from "../../components/Inputs";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TextInputWithLabel, DropdownArray } from '../../components/Inputs';
import { AddBoxOutlined, DeleteForever } from '@mui/icons-material';
import { ChipDropdown } from '../../components/Inputs';
const useStyles = makeStyles(theme => ({
    roots: {
        backgroundColor: theme.palette.white,
        padding: 29,
        borderRadius: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    addIcon: {
        marginTop: 54,
        marginLeft: 11,
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2)',
            cursor: 'pointer'
        },
    },
    gridContainer: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',

    },
    selectedItem: {
        color: theme.palette.text.grey,
        marginTop: 10,
        width: '100%',
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 3,
        paddingBottom: 28,
    },
    // rootContainer: {
    //     border: '1px solid',
    //     borderColor: theme.palette.border.main,
    //     borderRadius: 0,
    //     marginTop: 0,
    //     height: 37,
    //     padding: 0
    // },
    // input: {
    //     padding: 0,
    //     height: 21,
    //     fontFamily: 'ProximaNova',
    //     fontSize: 13
    // },
    // inputRoot: {
    //     height: 21,
    //     paddingLeft: 8,
    //     paddingBottom: 38,
    //     paddingTop: 8
    // },
    // chipContainer: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     overflowX: 'overlay',
    //     flexFlow: 'unset'
    // },
    // chipLabel: {
    //     fontFamily: 'ProximaNova'
    // },
    // chip: {
    //     height: 21,
    //     margin: 0,
    //     marginLeft: 8,
    //     marginBottom: 8,
    //     backgroundColor: '#E1E4F3',
    //     '&&:hover': {
    //         backgroundColor: '#E1E4F3',
    //         color: theme.palette.black
    //     },
    //     fontFamily: 'ProximaNova',
    //     padding: 0
    // },
    formDiv: {
        fontSize: theme.typography.h3.fontSize
    },
    dropdownContainer: {
        maxHeight: 50,
        overflowY: "scroll"
    },
    textInputHeight: {
        height: 21
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        // marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
}));
const AddKeyFigure = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [attributeName, setAttributeName] = React.useState([]);
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    const [attributeValue, setAttributeValue] = React.useState([]);
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [keyFigure, setKeyFigureData] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [mappedKeyFigure, setMappedKeyFigureData] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [optionData, setOptionData] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [newArrayOfKeys, setNewArrayOfKeys] = React.useState([]);
    const [newArrayOfValues, setNewArrayOfValues] = React.useState([]);
    const [newArrayOfKeys2, setNewArrayOfKeys2] = React.useState([]);
    const [newArrayOfValues2, setNewArrayOfValues2] = React.useState([]);
    const format = { attribute_name: '', attribute_values: [], options: '' }
    const [allDataNew, setAllDataNew] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [boxCount, setBoxCount] = React.useState(1);
    const optionDataArray = ['EQUAL', 'NOT EQUAL'
        // { key: 'EQUAL', value: 'EQUAL' },
        // { key: 'NOT EQUAL', value: 'NOT EQUAL' },
    ];
    // const optionDataArray=['EQUAL','NOT EQUAL']
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        setOpen(true);
        // props.onLoadingDefaultAll();
        // setAllDataNew([...allDataNew,format])
    }, []);
    useEffect(() => {
        // props.onLoadingDefault();
        props.allowedApps()
    }, []);
    for (let i = 0; i < boxCount; i++) {
        if (allDataNew && allDataNew.length < boxCount) {
            setAllDataNew([...allDataNew, format])
        }
    }
    // useEffect(() => {
    //     if (allDataNew) {
    //         setAllDataNew([...allDataNew])
    //         console.log(allDataNew.length)
    //     }
    // }, [allDataNew])
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.keyFigureFormData && props.keyFigureFormData.records && props.keyFigureFormData.records.length > 0) {
            props.keyFigureFormData.records.forEach(function (item) {
                if (item.drop_down_value_keys)
                    tempKeys.push(...item.drop_down_value_keys);
                if (item.drop_down_values)
                    tempValues.push(...item.drop_down_values);
            });
            setNewArrayOfKeys2(tempKeys);
            setNewArrayOfValues2(tempValues);
        }
    }, [props.keyFigureFormData])
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && newArrayOfKeys2 && newArrayOfKeys2.length > 0) {
            newArrayOfKeys2.map((item, index) => {
                if (props.labelNewAPIData.field_label_attributes[item]) {
                    setKeyValuePairObject((keyValuePairObject) => ({
                        ...keyValuePairObject,
                        [item]: props.labelNewAPIData.field_label_attributes[item]['current']
                    }));
                } else {
                    setKeyValuePairObject((keyValuePairObject) => ({
                        ...keyValuePairObject,
                        [item]: newArrayOfValues2[index]
                    }));
                }
            })
        } else {
            setKeyValuePairObject([])
        }
    }, [newArrayOfKeys2, props.labelNewAPIData])
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {
            props.formFieldsAllData.records.forEach(function (item) {
                if (item.drop_down_value_keys)
                    tempKeys.push(...item.drop_down_value_keys);
                if (item.drop_down_values)
                    tempValues.push(...item.drop_down_values);
            });
            setNewArrayOfKeys(tempKeys);
            setNewArrayOfValues(tempValues);
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.labelNewAPIData && Object.keys(props.labelNewAPIData).length > 0 && newArrayOfKeys && newArrayOfKeys.length > 0) {
            newArrayOfKeys.map((item, index) => {
                if (props.labelNewAPIData.field_label_attributes[item]) {
                    setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
                        ...keyValuePairObjectNew,
                        [item]: props.labelNewAPIData.field_label_attributes[item]['current']
                    }));
                } else {
                    setKeyValuePairObjectNew((keyValuePairObjectNew) => ({
                        ...keyValuePairObjectNew,
                        [item]: newArrayOfValues[index]
                    }));
                }
            })
        } else {
            setKeyValuePairObjectNew([])
        }
    }, [newArrayOfKeys, props.labelNewAPIData])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            props.loadSourceDataType(props.allowedAppsData[0], 'source_data_type');
        }
    }, [props.allowedAppsData])
    function handleDeleteAttributeValue(value, index) {
        // var data = []
        // allDataNew[index]['attribute_values'].map(item => {
        //     if (value != item) {
        //         data.push(item)
        //     }
        // })
        // allDataNew[index]['attribute_values'] = data
        const deletedAllDataNew = allDataNew[index]['attribute_values'].filter(item => item !== value)
        const updatedAllDataNew = [...allDataNew];
        updatedAllDataNew[index]['attribute_values'] = deletedAllDataNew;
        setAllDataNew(updatedAllDataNew)

    }

    // function handleAttributeValue(event, index) {
    //     allDataNew[index]['attribute_values'] = event.target.value;
    // }

    function handleOnSubmit() {
        var newArray = []
        allDataNew.map(item => {
            if (item.attribute_name !== '') {
                newArray.push(item)
            }
        })
        var data = {
            "application_type": applicationType,
            "source_data_type": sourceDataType,
            // "attribute_name": attributeName,
            // "attribute_values": attributeValue,
            // "options": optionData,
            "key_figure": keyFigure,
            "mapped_key_figure": mappedKeyFigure.toString(),
            "multiple_attribute_filters": newArray
        };
        props.onSubmit(data);
    }
    function handleAttributeName(newValue, index) {
        allDataNew[index]['attribute_name'] = (newValue.target.value)
        setAllDataNew([...allDataNew])
    }
    function handleAttributeValues(newValue, index) {
        // var data = allDataNew[index]['attribute_values'];
        // data.push(newValue[newValue.length - 1])
        allDataNew[index]['attribute_values'] = newValue

    }
    function handleOptions(newValue, index) {
        allDataNew[index]['options'] = (newValue.target.value)
        setAllDataNew([...allDataNew])
    }
    function handleOnAdd() {
        // attributeName[addMember] = ''
        // attributeValue[addMember] = ''
        // optionData[addMember] = ''
        setAllDataNew([...allDataNew, format])
        setAddMember(addMember + 1)
    }
    // const handleDeleteLocally = (e, index) => {
    //     setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    //     setAllDataNew(allDataNew.filter((item, i) => i != index));
    // }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        setAttributeName('')
        setKeyFigureData('')
        setMappedKeyFigureData('')
        setAttributeValue([])
        props.getDefaultValuesAllData('attribute_name', e.target.value, applicationType);
        props.getDefaultKeyFigureData('key_figure_name', e.target.value, applicationType);
        var data = { "data_source": e.target.value }
        props.getLblDispDesFieldValue(data, 'post');

    }
    const handleApplicationType = (e) => {
        setApplicationType(e.target.value);
        setSourceDataType('')
        setSourceDataTypeDataFromAPI([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
    }

    function addBoxCount() {
        setBoxCount(boxCount + 1)
    }

    function removeBoxCount() {
        if (boxCount > 1) {
            setBoxCount(boxCount - 1);
            allDataNew.pop();
        }
    }

    return (
        <div className={classes.roots}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/key-figure-mapping'
                >
                    Key Figure Mapping
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Key Figure Mapping</Typography>
            </Breadcrumbs>

            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Application Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={applicationType}
                                                onChange={handleApplicationType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {allowedApps && allowedApps.length > 0 && allowedApps
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Source Data Type
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={sourceDataType}
                                                onChange={handleSourceDataType}
                                                displayEmpty
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                                {sourceDataTypeDataFromAPI && sourceDataTypeDataFromAPI.length > 0 && sourceDataTypeDataFromAPI
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} key={index}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel required className={classes.formDiv}>
                                            Source Key Figure
                                        </FormLabel>
                                        <Select
                                            value={keyFigure}
                                            style={{ maxHeight: "50px" }}
                                            onChange={(e) => setKeyFigureData(e.target.value)}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >   {keyValuePairObject && Object.keys(keyValuePairObject).length > 0 &&
                                            Object.entries(keyValuePairObject)
                                                .sort()
                                                ?.map(([key, value]) => {
                                                    return (
                                                        <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel required className={classes.formDiv}>
                                            Target Key Figure
                                        </FormLabel>
                                        <Select
                                            value={mappedKeyFigure}
                                            onChange={(e) => setMappedKeyFigureData(e.target.value)}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            menuPlacement="auto"
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            {keyValuePairObject && Object.keys(keyValuePairObject).length > 0 &&
                                                Object.entries(keyValuePairObject)
                                                    .sort()
                                                    ?.map(([key, value]) => {
                                                        return (
                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                {value}
                                                            </MenuItem>
                                                        )
                                                    })
                                            }
                                        </Select>
                                    </div>
                                </Grid>
                                {/* {(() => {
                                    let td = [];
                                    for (let i = 0; i < addMember; i++) {
                                        td.push(<>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}

                                            >
                                                <DropdownArray heading={i == 0 && 'Attribute Name'} twoline='true' onChange={(e) => handleAttributeName(e, i)}
                                                    placeholder={'Please select'} 
                                                    data={Object.keys(keyValuePairObjectNew)}
                                                    prevalue={allDataNew[i] && allDataNew[i]['attribute_name'] ? allDataNew[i]['attribute_name'] : ''}
                                                    required
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}

                                            >
                                                <DropdownArray heading={i == 0 && 'Option'} twoline='true' onChange={(e) => handleOptions(e, i)}
                                                    placeholder={'Please select'} 
                                                    data={optionDataArray} 
                                                    prevalue={allDataNew[i] && allDataNew[i]['options'] ? allDataNew[i]['options'] : ''}
                                                    required
                                                />

                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}

                                            >
                                                <TextInputWithLabel heading={i == 0 && 'Attribute Values'} twoline='true' onChange={(e) => handleAttributeValues(e, i)}
                                                    data={allDataNew[i] && allDataNew[i]['attribute_values'] ? allDataNew[i]['attribute_values'] : ''}
                                                    
                                                    prevalue={allDataNew[i] && allDataNew[i]['attribute_values'] ? allDataNew[i]['attribute_values'] : ''}
                                                    required
                                                />
                                            </Grid>
                                        
                                            <Grid
                                                item
                                                md={1}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                                style={{ paddingTop: 5, display: 'flex' }}
                                            >
                                                <IconButton style={{ marginTop: i == 0 ? 32 : 10 }} onClick={(e) => handleDeleteLocally(e, i)} classes={{ root: classes.IconButton }}>
                                                    <DeleteForever />
                                                </IconButton>
                                                <IconButton style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 42 : 25 }} onClick={handleOnAdd} classes={{ root: classes.IconButton }}>
                                                    <AddBoxOutlined />
                                                </IconButton>
                                            </Grid>
                                        </>)
                                    }
                                    return td;
                                })()} */}
                                {/* <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                            Attribute Name
                                        </FormLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                disableUnderline
                                                value={attributeName}
                                                onChange={(e) => setAttributeName(e.target.value)}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                            >
                                              {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                                    Object.entries(keyValuePairObjectNew)
                                                        .sort()
                                                        ?.map(([key, value]) => {
                                                            return (
                                                                <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <div className={classes.gridContainer}>
                                        <FormLabel required className={classes.formDiv}>
                                            Option
                                        </FormLabel>
                                        <Select
                                            value={optionData}
                                            onChange={(e) => setOptionData(e.target.value)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >
                                            
                                            {optionDataArray?.map((item) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key}>
                                                        {item.value}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className={classes.gridContainer}>
                                        <FormLabel required className={classes.formDiv}>
                                            Attribute Value
                                        </FormLabel>
                                                <ChipInput
                                                    classes={{
                                                        root: classes.rootContainer,
                                                        chip: classes.chip,
                                                        input: classes.input,
                                                        inputRoot: classes.inputRoot,
                                                        label: classes.chipLabel,
                                                        chipContainer: classes.chipContainer,
                                                    }}
                                                    value={attributeValue}
                                                    onAdd={(chips) => handleAttributeValue(chips)}
                                                    onDelete={(chip) => handleDeleteAttributeValue(chip)}
                                                    disableUnderline={true}
                                                /> 
                                    </div>
                                </Grid> */}
                                {(() => {
                                    let td = [];
                                    for (let i = 0; i < boxCount; i++) {
                                        if (allDataNew && allDataNew.length < boxCount) {
                                            setAllDataNew([...allDataNew, format])
                                        }
                                        td.push(<>
                                            <Grid item md={9} xs={12} style={{ display: 'flex', gap: 15 }}>
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <div className={classes.gridContainer}>
                                                        <FormLabel variant="h5" className={classes.formDiv} required>
                                                            Attribute Name
                                                        </FormLabel>
                                                        <div className={classes.dropdownContainer}>
                                                            <Select
                                                                disableUnderline
                                                                value={allDataNew[i] && allDataNew[i]['attribute_name']}
                                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                onChange={(e) => handleAttributeName(e, i)}
                                                                displayEmpty
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                            >
                                                                {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 &&
                                                                    Object.entries(keyValuePairObjectNew)
                                                                        .sort()
                                                                        .map(([key, value]) => {
                                                                            return (
                                                                                <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                                                                                    {value}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                }
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <div className={classes.gridContainer}>
                                                        <FormLabel required className={classes.formDiv}>
                                                            Option
                                                        </FormLabel>
                                                        <Select
                                                            value={allDataNew[i] && allDataNew[i]['options']}
                                                            onChange={(e) => handleOptions(e, i)}
                                                            displayEmpty
                                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                                            menuPlacement="auto"
                                                            className={clsx({
                                                                [classes.select]: true
                                                            })}
                                                            classes={{
                                                                selectMenu: classes.selectedItem
                                                            }}
                                                        >
                                                            {optionDataArray && optionDataArray.length > 0 &&
                                                                optionDataArray

                                                                    ?.map((item) => {
                                                                        return (
                                                                            <MenuItem key={item} value={item} style={{ textTransform: 'capitalize' }}>
                                                                                {item}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                            }
                                                        </Select>
                                                        {/* <Select
                                            value={allDataNew[i] && allDataNew[i]['options']}
                                            onChange={(e) => handleOptions(e, i)}
                                            displayEmpty
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                        >   { optionDataArray.map((item) => {
                                                return (
                                                    <MenuItem value={item} key={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select> */}
                                                    </div>
                                                </Grid>
                                                <Grid item md={4}>
                                                    <div className={classes.gridContainer}>
                                                        <FormLabel required className={classes.formDiv}>
                                                            Attribute Value
                                                        </FormLabel>
                                                        {/* <ChipDropdown
                                                            id={i}
                                                            data={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                            onChange={(e) => handleAttributeValues(e, i)}
                                                        /> */}
                                                        <ChipInput data={allDataNew[i] && allDataNew[i]['attribute_values']} onChange={newValue => handleAttributeValues(newValue, i)} />
                                                        {/* <ChipInput
                                                            classes={{
                                                                root: classes.rootContainer,
                                                                chip: classes.chip,
                                                                input: classes.input,
                                                                inputRoot: classes.inputRoot,
                                                                label: classes.chipLabel,
                                                                chipContainer: classes.chipContainer,
                                                            }}
                                                            defaultValue={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                            value={allDataNew[i] && allDataNew[i]['attribute_values']}
                                                            // onAdd={(chips) => handleAttributeValues(chips,i)}
                                                            onChange={(e) => handleAttributeValues(e, i)}
                                                            onDelete={(chip) => handleDeleteAttributeValue(chip, i)}
                                                            disableUnderline={true}
                                                            className={clsx({
                                                                [classes.textInput]: true
                                                            })}
                                                        /> */}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {i == boxCount - 1 &&
                                                <>
                                                    <RemoveCircleIcon
                                                        className={classes.addIcon}
                                                        color={"primary"}
                                                        onClick={removeBoxCount}
                                                    />
                                                    <AddCircleIcon
                                                        className={classes.addIcon}
                                                        color={"primary"}
                                                        onClick={addBoxCount}
                                                    />
                                                </>
                                            }
                                        </>)
                                    }
                                    return td;
                                })()}
                            </Grid>

                        </div>
                    </form>
                </Card>
                <div className={classes.buttonRoot}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={
                            applicationType && sourceDataType &&
                                // attributeName  && optionData && 
                                keyFigure.length > 0
                                && mappedKeyFigure.length > 0
                                // && attributeValue.length > 0 
                                ? false : true
                        }
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>

            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        loadingAPI: state.customerData.loading,
        dropdownDataAll: state.addMultipleConfigurationData.multipleConfigurationData,
        dropdownDataAttributeName: state.addMultipleConfigurationData.formFieldsAllDataER,
        attributeNameData: state.addMultipleConfigurationData.attributeNameData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        keyFigureFormData: state.addMultipleConfigurationData.formFieldsAllDataER,
        allowedAppsData: state.initialData.allowedApps,
        labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addKeyFigureDefaultDesigner(data)),
        onLoadingDefault: () => dispatch(getDefaultValuesCustomerXRef()),
        onLoadingDefaultAll: () => dispatch(getDefaultValues('noKeyWords')),
        // onLoadingDefaultAttributeName: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        getDefaultKeyFigureData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE', 'ER')),
        allowedApps: () => dispatch(allowedApps()),
        // onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddKeyFigure);