import React, { useEffect } from 'react';
import {
    Typography, Switch, Stack, FormControlLabel, FormGroup, Checkbox,
    InputAdornment, IconButton, TextField, FormControl, ToggleButton, ToggleButtonGroup,
    Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    Accordion,
    AccordionSummary,
    AccordionDetails, Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { ExpandMore, ExpandLess, AddCircleOutline } from '@mui/icons-material';
import { connect } from 'react-redux';
import { createGlobalSetup, getPromoTypes, getBudgetingConfig, getMarketFilters, getModels } from '../../../../../../redux/actions';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NestedDropdown, PromoMultiSelect } from '../../../../Components'
import { index } from 'd3';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        paddingBottom: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10,
        padding: 15,
    },
    rightContainer: {
        // backgroundColor: '#F8F8F8'
    },
    leftContainer: {
        marginTop: '12px',
        padding: '0px 45px',
        backgroundColor: '#F8F8F8'

    },
    defineContainer: {
        marginTop: '12px',
        // padding: '0px 45px',
        backgroundColor: '#F8F8F8',
        maxHeight: 634,
        height: 634,
        overflowY: 'auto',
        border: "0.8px solid #E2E2E2",
        borderRadius: 4,

    },
    chipContainer: {
        // paddingLeft: 30,
        display: 'flex',
        flexDirection: 'row',
        // paddingBottom: 10,
        backgroundColor: '#F8F8F8',
        padding: '20px 0px 20px 30px !important'
    },
    subContainer: {
        padding: '10px 16px 16px 16px',
        alignItems: 'center',
        //marginLeft: 30,
    },
    iconContainer: {
        marginTop: 40
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },

    searchBox: {
        backgroundColor: theme.palette.white
    },
    filterBox: {
        display: 'flex',
        justifyContent: 'space-around',
        border: '1px solid #E2E2E2',
        height: '48px',
        borderRadius: '8px',
        marginTop: 10,
        padding: '8px',
        width: 'fit-content',
        marginBottom: 42
    },

    filterItem: {
        display: 'flex',
        marginRight: 12
    },
    filterContainer: {
        marginRight: 12,
        //width: '25%',
        //marginTop: -5,
        display: 'flex',
        flexDirection: 'column'
    },
    rightHeader: {
        width: '100%'
        // display: 'flex',
        // justifyContent: 'space-between'
    },
    chip: {
        backgroundColor: 'white',
        border: '1px solid #E2E2E2'
    }
}));
const DefineMarket = (props) => {

    const classes = useStyles();
    const { className } = props;
    const history = useHistory();
    const [productLinesList, setProductLinesList] = React.useState([]);
    const [filterData, setFilterData] = React.useState([]);
    const [productLine, setProductLine] = React.useState([]);
    const [country, setCountry] = React.useState('USA');
    const [enableDesc, setEnableDesc] = React.useState(false);
    const [externalDesc, setExternalDesc] = React.useState('');
    const [stickerDesc, setStickerDesc] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [status, setStatus] = React.useState('pending');
    const [scenario, setScenario] = React.useState('current');
    const [models, setModels] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const [marketExpanded, setMarketExpanded] = React.useState([]);
    const [modelYear, setModelYear] = React.useState('');
    const [checkedModel, setCheckedModel] = React.useState([]);
    const [displaySelected, setDisplaySelected] = React.useState(true);
    const [toggle, setToggle] = React.useState(true);
    const [selectedModels, setSelectedModels] = React.useState([]);
    const [selectedModelItems, setSelectedModelItems] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [checkedLoc, setCheckedLoc] = React.useState([]);
    const [region, setRegion] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [itemOn, setItemOn] = React.useState('');
    const [regionList, setRegionList] = React.useState([]);
    const [stickerModels, setStickerModels] = React.useState([]);
    const [stickerLocs, setStickerLocs] = React.useState([]);
    useEffect(() => {
        setStickerDesc(props.stickerName)
    }, [props.stickerName]);

    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setProductLinesList(props.configData)
        }
    }, [props.configData]);
    useEffect(() => {
        console.log(props.modelData)
        if (props.modelData && props.modelData.length > 0) {

            setModels(props.modelData)
        }
    }, [props.modelData]);
    useEffect(() => {
        if (props.locationData && props.locationData.length > 0) {
            var region = props.locationData.map((item, index) => item.category)
            setRegionList(region)
            setLocations(props.locationData)
        }
    }, [props.locationData]);
    useEffect(() => {
        if (props.active == false) {
            setSelectedLoc([])
            setSelectedLocItems([])
            setSelectedModels([])
            setSelectedModelItems([])
            setModelYear('')
            setRegion('')
            setCheckedLoc([])
            setCheckedModel([])
        }
    }, [props.active])
    function handleModelYear(event) {
        setModelYear(event.target.value)
    }
    function handleRegion(event) {
        setRegion(event.target.value)
    }


    function handleCheckedData(array1, selected, child, type) {
        setAnchorEl(false)
        if (type !== 'cancel') {
            setSelectedModels(array1)
            setSelectedModelItems(selected)
            setStickerModels(child)
            props.handleDefineMarket(selected, array1, 'model')
            // props.handleDefineMarket(selected, child, 'model')
            const array2 = [];
            setAnchorEl(false)
            selected.forEach(obj => {
                const { material_group1, material_group2, material_group3, material_group4 } = obj;
                const existingCategoryA = array2.find(item => item.category === material_group1);
                if (existingCategoryA) {
                    const existingCategoryB = existingCategoryA.children.find(item => item.category === material_group2);
                    if (existingCategoryB) {
                        const existingCategoryC = existingCategoryB.children.find(item => item.category === material_group3);
                        if (existingCategoryC) {
                            existingCategoryC.children.push({
                                category: material_group4, type: 'material_group4', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3, 'material_group4': material_group4 },
                                children: []
                            });
                        } else {
                            existingCategoryB.children.push({
                                category: material_group3, type: 'material_group3', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3 },
                                children: [{ category: material_group4, type: 'material_group4', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3, 'material_group4': material_group4 }, children: [] }]
                            });
                        }
                    } else {
                        existingCategoryA.children.push({
                            category: material_group2, type: 'material_group2', children: [{
                                category: material_group3, type: 'material_group3', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3 },
                                children: [{ category: material_group4, type: 'material_group4', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3, 'material_group4': material_group4 }, children: [] }]
                            }]
                        });
                    }
                } else {
                    array2.push({
                        category: material_group1, type: 'material_group1',
                        children: [{
                            category: material_group2, type: 'material_group2',
                            children: [{
                                category: material_group3, type: 'material_group3',
                                relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3 },
                                children: [{ category: material_group4, type: 'material_group4', relationship: { 'material_group1': material_group1, 'material_group2': material_group2, 'material_group3': material_group3, 'material_group4': material_group4 }, children: [] }]
                            }]
                        }]
                    });
                }
            });
            console.log(array2)
            setCheckedModel([...array2])
            // props.handleSelectedModels([...array2])
            props.handleSelectedModels(child, 'model')
        }
    };
    function handleCheckedLoc(array1, selected, child, type) {
        setAnchorloc(false)
        if (type !== 'cancel') {
            setSelectedLoc(array1)
            setSelectedLocItems(selected)
            props.handleDefineMarket(selected, array1, 'location')
            const array2 = [];
            setAnchorEl(false)
            selected.forEach(obj => {
                const { country, region, state } = obj;
                const existingCategoryA = array2.find(item => item.category === region);
                if (existingCategoryA) {
                    existingCategoryA.children.push({ category: state, type: 'state', relationship: { country: country, region: region, state: state }, children: [] });

                } else {
                    array2.push({
                        category: region, type: 'region',
                        relationship: { country: country, region: region },
                        children: [{
                            category: state, type: 'state',
                            relationship: { country: country, region: region, state: state },
                            children: []
                        }]
                    });
                }
            });
            // array2
            // console.log(array2)
            setCheckedLoc([...array2])
            props.handleSelectedModels(child, 'location')
        }
    };
    function handleToggle(event) {
        setToggle(event.target.checked);
    };
    function handleDisplay(event) {
        setDisplaySelected(event.target.checked ? false : true);
    };
    function handleClose() {
        setOpen(false)
        setItemOn('')
    }
    function handleSubmit() {
        setOpen(false)
        if (displaySelected) {
            if (toggle) {
                const index = selectedModelItems.findIndex(item => JSON.stringify(item) === JSON.stringify(itemOn.relationship));
                const index1 = selectedModels.findIndex(item => JSON.stringify(item) === JSON.stringify(itemOn.relationship))
                setSelectedModelItems(selectedModelItems.filter((item, i) => index != i))
                setSelectedModels(selectedModels.filter((item, i) => index1 != i))
                handleCheckedData(selectedModels, selectedModelItems.filter((item, i) => index != i))
                props.handleDefineMarket(selectedModelItems.filter((item, i) => index != i), selectedModels.filter((item, i) => index1 != i), 'model')
            }
            else {
                const index = selectedLocItems.findIndex(item => JSON.stringify(item) === JSON.stringify(itemOn.relationship));
                setSelectedLocItems(selectedLocItems.filter((item, i) => index != i))
                handleCheckedLoc(selectedLoc, selectedLocItems.filter((item, i) => index != i))
                props.handleDefineMarket(selectedLocItems.filter((item, i) => index != i), null, 'location')
            }
        }
        else {
            if (toggle) {
                setSelectedModelItems([...selectedModelItems, itemOn.relationship])
                setSelectedModels([...selectedModels, itemOn.relationship])
                handleCheckedData([...selectedModels, itemOn.relationship], [...selectedModelItems, itemOn.relationship])
                props.handleDefineMarket([...selectedModelItems, itemOn.relationship], [...selectedModels, itemOn.relationship], 'model')
            }
            else {
                setSelectedLocItems([...selectedLocItems, itemOn.relationship])
                handleCheckedLoc(selectedLoc, [...selectedLocItems, itemOn.relationship])
                props.handleDefineMarket([...selectedLocItems, itemOn.relationship], null, 'location')
            }
        }
        setItemOn('')
    }
    function handleChipItem(item) {
        setOpen(true)
        setItemOn(item)
    }
    function handleOnAdd() {

    }
    const handleFilters = (value, field, type) => {
        setFilterData(value)
        if (value.length > 0)
            setModelYear(value[0])
        props.onChange(value, 'material_group1')
    }
    const handleClick = (type) => {
        if (type == 'model') {
            setAnchorEl(!anchorEl)
            const isExpanded = !anchorEl
            setExpanded(isExpanded ? [...expanded, 'model_filter'] : []);
        }
        else {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };
    const handleAccordionChange = (panel, type) => (event, isExpanded) => {
        setMarketExpanded(isExpanded ? [...marketExpanded, panel + "-" + type] : marketExpanded.filter(item => item != panel + "-" + type));
    };
    const renderNestedItems = (items) => {
        return items && items.map((item, index) => (
            <>

                {item.type == 'material_group1' || item.type == 'region' ?
                    renderNestedItems(item.children)
                    :
                    item.children && item.children.length > 0 ? (
                        <div key={index} style={{ flexGrow: 1, width: '100%' }} >
                            <Accordion
                                expanded={marketExpanded.includes(item.category + "-" + item.type)}
                                onChange={handleAccordionChange(item.category, item.type)}
                                style={{ margin: 0, borderBottom: "0.8px solid #E2E2E2", zindex: 1 }}
                                fullWidth
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore color={marketExpanded.includes(item.category + "-" + item.type) ? 'primary' : 'black'} />}
                                    className={classes.accordionMenu}
                                    style={{ paddingLeft: item.type == 'material_group3' ? 20 : 10 }}
                                >
                                    <Typography variant='h4'
                                        color={'grey'}
                                    >
                                        {item.category}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0,/* display: item.type == 'material_group3' && 'flex':'block', flexDirection: item.type == 'material_group3' && 'row'*/ }}
                                    className={(item.type == 'material_group3') && classes.chipContainer}
                                >
                                    {renderNestedItems(item.children)}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ) :
                        displaySelected ?
                            (<div style={{ paddingLeft: 30, marginRight: 5 }}>
                                <Chip
                                    label={item.category}
                                    variant="outlined"
                                    className={classes.chip}
                                    sx={{ backgroundColor: 'white' }}
                                    //onClick={handleClick}
                                    onDelete={() => handleChipItem(item)}

                                />
                            </div>
                            )
                            :
                            (toggle ? (selectedModelItems.indexOf(item.relationship) < 0) :
                                (selectedLocItems.indexOf(item.relationship) < 0)) && (
                                <div style={{ paddingLeft: 30, marginRight: 5 }}>
                                    <Chip
                                        label={item.category}
                                        variant="outlined"
                                        onDelete={() => handleChipItem(item)}
                                        deleteIcon={<AddCircleOutline />}
                                        sx={{ backgroundColor: 'white' }}
                                    //onClick={handleClick}

                                    />
                                </div>
                            )
                }
            </>
        ));
    };
    return (
        <div className={classes.root}>
            <Grid container item spacing={1}>
                <Grid
                    item xs={12} md={12}
                    classes={{ root: classes.leftContainer }}
                >
                    <div>
                        <Typography variant='h3' style={{ marginBottom: '24px' }}>SELECT YOUR MARKET</Typography>

                        <div className={classes.filterBox} >
                            <Typography variant='h4' color='grey' style={{ marginRight: 12 }}>Filter</Typography>
                            <div className={classes.filterItem}>
                                <Typography variant='h4' color='grey'>Model Year</Typography>
                                <PromoMultiSelect id='model-year' onChange={(value) => handleFilters(value, 'material_group1', 'material_master')}
                                    options={props.modelYears ? props.modelYears : []} />
                            </div>
                            <div className={classes.filterContainer}>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Model Selection</Typography>
                                    <IconButton onClick={() => handleClick('model')}>{anchorEl ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                </div>
                                {anchorEl &&
                                    <NestedDropdown head='Model Selection' id={'model'} data={models} expand={expanded} onSubmit={handleCheckedData}
                                        prevalue={selectedModels} selected={selectedModelItems} type='model_filter' />}
                            </div>
                            <div className={classes.filterContainer}>
                                <div className={classes.filterItem}>
                                    <Typography variant='h4' color='grey'>Location Selection</Typography>
                                    <IconButton onClick={() => handleClick('location')}>{anchorloc ? <ExpandLess /> : <ExpandMore />}</IconButton>
                                </div>
                                {anchorloc &&
                                    <NestedDropdown head='Location Selection' id={'locations'} data={locations} expand={expanded} onSubmit={handleCheckedLoc}
                                        prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                }
                            </div>

                        </div>
                    </div>
                </Grid >
                <Grid
                    item xs={12} md={12}
                    className={classes.rightContainer}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                    </div>
                    <div className={classes.rightHeader}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <FormControlLabel
                                    value={displaySelected}
                                    control={<Checkbox checked={displaySelected ? false : true} />}
                                    label={<Typography variant='h4' color='grey'>{toggle ? 'Display Unselected Models' : 'Display Unselected States'}</Typography>}
                                    labelPlacement="end"
                                    onChange={handleDisplay}
                                />
                            </div>
                            <div >
                                <Stack direction="row" spacing={1} alignItems="center" style={{ height: 30 }}>
                                    <Typography variant='h4' color='black'>STATES</Typography>
                                    <Switch sx={{ m: 1 }} checked={toggle} className={classes.toggleButton}
                                        onChange={handleToggle} />
                                    <Typography variant='h4' color='black'>MODELS</Typography>
                                </Stack>
                            </div>
                        </div>
                        <div>
                            {toggle ?
                                <ToggleButtonGroup
                                    color="primary"
                                    value={modelYear}
                                    exclusive
                                    onChange={handleModelYear}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {props.country != 'CA' ? filterData && filterData.map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>

                                    )
                                        : (
                                            <ToggleButton value={'Canada'}>Canada</ToggleButton>
                                        )}
                                </ToggleButtonGroup>
                                :
                                <ToggleButtonGroup
                                    color="primary"
                                    value={region}
                                    exclusive
                                    onChange={handleRegion}
                                    aria-label="Platform"
                                    style={{ position: 'relative', zindex: -1 }}
                                >
                                    {regionList && regionList.map(item =>
                                        <ToggleButton value={item}>{item}</ToggleButton>
                                    )}
                                </ToggleButtonGroup>
                            }
                        </div>
                        <div className={classes.defineContainer} style={{ position: 'relative', zindex: -1, backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ width: '100%', paddingTop: !toggle ? 10 : 0 }} className={!toggle && classes.chipContainer}>
                                {displaySelected ?
                                    toggle ?
                                        renderNestedItems(checkedModel.filter((item, index) => item.category == modelYear))
                                        :
                                        renderNestedItems(checkedLoc.filter((item, index) => item.category == region))
                                    :
                                    toggle ?
                                        renderNestedItems(models.filter((item, index) => item.category == modelYear))
                                        :
                                        renderNestedItems(locations && locations.filter((item, index) => item.category == region))
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid >
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle color='grey' style={{ cursor: 'move' }} id="dialog-title">
                    {displaySelected ? 'REMOVE FROM SELECTION ?' : 'ADD TO SELECTION ?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Chip
                            label={itemOn.category}
                            variant="outlined"
                            className={classes.chip}
                            sx={{ backgroundColor: 'white' }}

                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSubmit}>yes</Button>
                </DialogActions>
            </Dialog>
        </div >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        getPromotionTypes: () => dispatch(getPromoTypes()),
        getMarketFilters: (table, data) => dispatch(getMarketFilters(table, data)),
        getFilterData: (table, data) => dispatch(getModels(table, data, 'filter'))
    }
}

const mapStateToProps = state => {
    return {
        promotionTypes: state.promotionData.promoTypes,
        loading: state.promotionData.loading,
        //filterData: state.promotionData.promoFilters,
        modelData: state.promotionData.modelFilters,
        // locationData: state.promotionData.locationFilters,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DefineMarket);