import React, { useRef, useEffect } from 'react';
import {
    Typography, Breadcrumbs, Stepper, Step, StepButton, StepConnector, StepLabel, Button, Grid, useMediaQuery,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { GeneralData, GroupingIdentifiers, Ima360Data, OrganizationData } from './components';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { addSupplierMasterData, getLblDispDesFieldValue } from '../../redux/actions';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs = {
    generalData: 0,
    groupingIdentifiers: 1,
    ima360Data: 2,
    OrganizationData: 3
};
function getSteps() {
    return ['General Data', 'Grouping/Identifiers', 'IMA360 Data', 'Organization Data'];
}

const AddSupplier = props => {
    var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const childRef4 = useRef();
    const [saveTriggered, setSaveTriggered] = React.useState('');
    var generalData = {};
    var ima360Data = {};
    var groupingIdentifiersData = {};
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [sortedData, setSortedData] = React.useState([]);
    const skipped = new Set()
    const steps = getSteps();
    const history = useHistory();
    const totalSteps = () => {
        return getSteps().length;
    };
    const [organizationData, setOrganizationData] = React.useState([]);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        var data = { "data_source": "supplier_master" };
        props.getLblDispDesFieldValue(data, 'post');
    }, [])
    useEffect(() => {
        if (props.tableData) {
            setSortedData(props.tableData.field_label_attributes);
        }
    }, [props.tableData])
    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        if (step === tabs.eligibilityRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckEligibillityRulesTable(localStorage.getItem('currentAddContractID'));
        } else if (step === tabs.calculationRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckCalculationRulesTable(localStorage.getItem('currentAddContractID'));
        }
        setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleComplete = () => {
        var organizationDataArray = { "organizations": organizationData };
        var completeData = Object.assign(generalData, groupingIdentifiersData, ima360Data, organizationDataArray);
        props.onSubmit(completeData);
    };
    const handleDiscard = () => {
        history.push("/customer-master");
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/customer-master");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    // const checkValues = () => {
    //     if (generalData.supplier_name && generalData.street && generalData.city
    //         && generalData.region && generalData.postal_code && generalData.country) {
    //         setSaveDisabled(false)
    //     } else {
    //         setSaveDisabled(true)
    //     }
    // }
    const checkValues = () => {
        const mandatoryFields = Object.keys(sortedData)
            .filter(key => key !== 'supplier_number' && sortedData[key].mandatory);
        const hasMissingMandatoryFields = ((mandatoryFields.some(key => !generalData[key] && !groupingIdentifiersData[key] && !ima360Data[key])))
        setSaveDisabled(hasMissingMandatoryFields);
    };
    function handleGeneralDataChange(values) {
        generalData = values;
        checkValues();
    };
    function handleGroupingIdentifiers(values) {
        groupingIdentifiersData = values;
        checkValues();
    };
    function handleIma360Data(values) {
        ima360Data = values;
        checkValues();
    }
    function handleOrganizationData(values) {
        setOrganizationData(values);
        checkValues();
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function getStepContent(step) {
        const allSteps = [
            <GeneralData onChange={handleGeneralDataChange} data={sortedData} savetriggered={saveTriggered} ref={childRef1} />,
            <GroupingIdentifiers onChange={handleGroupingIdentifiers} data={sortedData} savetriggered={saveTriggered} ref={childRef2} />,
            <Ima360Data onChange={handleIma360Data} data={sortedData} savetriggered={saveTriggered} ref={childRef4} />,
            <OrganizationData onChange={handleOrganizationData} data={sortedData} savetriggered={saveTriggered} ref={childRef3} />,
        ];
        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    return (
        <div>
            <div className={classes.root}>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link variant='h1' classes={{
                        root: classes.link
                    }}
                        to='/supplier-master'
                    >
                        Supplier Master
                    </Link> */}
                    <Typography color="textPrimary" variant='h1'>&ensp;Add Supplier</Typography>
                </Breadcrumbs>
                <div className={classes.stepper}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}
                        classes={{ root: classes.stepperContainer }}
                        connector={
                            <StepConnector
                                classes={{
                                    line: classes.connectorLine,
                                    alternativeLabel: classes.connectorAlternativeLabel
                                }}
                            />
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}
                                    classes={{ horizontal: classes.horizontal }}
                                >
                                    <StepButton
                                        onClick={handleStep(index)}
                                        completed={isStepComplete(index)}
                                        {...buttonProps}
                                        classes={{ root: classes.stepButton }}
                                    >
                                        <StepLabel>
                                            {label}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <Grid container >
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                    >
                        {activeStep !== 0 &&
                            <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                Previous Step
                            </Button>
                        }
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        classes={{ root: classes.gridContainer }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button variant="outlined" color="primary" onClick={handleComplete}
                            className={clsx({
                                [classes.button]: true,
                                [classes.shiftContent]: !isDesktop,
                            })}
                            disabled={saveDisabled}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Save'
                            }
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                            Discard
                        </Button>
                        {activeStep != tabs.OrganizationData &&
                            <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}>
                                {activeStep === tabs.OrganizationData ? 'Done' : 'Next Step'}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loadingAPI: state.customerData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addSupplierMasterData(data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSupplier);