import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import {
    Typography
} from '@mui/material';
import { ChartComponent, SeriesCollectionDirective, Highlight, SeriesDirective, Inject, Legend, Category, ColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';
import Moment from "moment";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        padding: '0px 30px 20px 30px',
        borderRadius: 10,
        marginTop:'-1rem'
    },
    chartContainer: {
        marginTop: 30,
        // height: 250
    }
}));

const BarLineGraph = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [chartData,setChartData] = React.useState([]);
    const [chartData2,setChartData2] = React.useState([]);
    const [chartData3,setChartData3] = React.useState([]);
    const [maximumNumber,setMaximumNumber] = React.useState(10);
        useEffect(()=>{
        var newArray=[]
        var max=[];
        if(props.newData&& props.newData.length>0){
            props.newData.map((item,idx)=>{
                max.push(Number(item.CreateCount))
                if(item.AppFunctionality==='customer_master'){
                newArray.push({'x':Moment(item.WeekStart).format('MMM YYYY'),'y':Number(item.CreateCount)})
            }
            })
            var val = Math.max(...max.splice(1,max.length));
            setMaximumNumber(Number(Math.max(val,maximumNumber)))
            setChartData(newArray)
        }
    },[props.newData])
    useEffect(()=>{
        var newArray=[]
        if(props.newData&& props.newData.length>0){
            props.newData.map((item,idx)=>{
                if(item.AppFunctionality==='material_master'){
                    newArray.push({'x':Moment(item.WeekStart).format('MMM YYYY'),'y':Number(item.CreateCount)})
                }
            })
            setChartData2(newArray)
        }
    },[props.newData])
    useEffect(()=>{
        var newArray=[]
        if(props.newData&& props.newData.length>0){
            props.newData.map((item,idx)=>{
                if(item.AppFunctionality==='supplier_master'){
                    newArray.push({'x':Moment(item.WeekStart).format('MMM YYYY'),'y':Number(item.CreateCount)})
                }
            })

            setChartData3(newArray)
        }
    },[props.newData])  
    return (
        <div className={classes.root}>
            <div className={classes.chartContainer}>
            <Typography variant="h2" color='primary'  style={{ textAlign: "center" }}  > {props.text} </Typography>
            <ChartComponent 
            {...rest}
            id={props.id}
            style={{ textAlign: "center" }} 
            primaryXAxis={{ majorGridLines: { width: 0 }, 
                            minorGridLines: { width: 0 }, 
                            majorTickLines: { width: 0 }, 
                            minorTickLines: { width: 0 }, 
                            interval: 1, 
                            lineStyle: { width: 0 }, 
                            valueType: 'Category' 
                        }} 
            primaryYAxis={{ 
                            lineStyle: { width: 0 }, 
                            maximum: maximumNumber+maximumNumber/100,
                            interval: parseInt(maximumNumber/5),
                            majorTickLines: { width: 0 }, 
                            majorGridLines: { width: 1 }, 
                            minorGridLines: { width: 1 }, 
                            minorTickLines: { width: 0 }, 
                            labelFormat: '{value}', 
                            // title:props.text
                        }} 
                            legendSettings={{ enableHighlight: true }} 
                            // width={Browser.isDevice ? '100%' : '75%'} 
                            chartArea={{ border: { width: 0 } }} 
                            tooltip={{ enable: true }}
            > 
                    <Inject services={[ColumnSeries, Category, Legend, Tooltip, Highlight]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={chartData} xName='x' columnSpacing={0.1} yName='y' name='Customer Master' width={2} type="Column" fill='#2485FA'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chartData2} xName='x' columnSpacing={0.1} yName='y' name='Material Master' width={2} type="Column" fill='#FEC200'>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chartData3} xName='x' columnSpacing={0.1} yName='y' name='Supplier Master' width={2} type="Column" fill='#ACC200'>
                        </SeriesDirective>
                        {/* <SeriesDirective dataSource={chartData} xName='x' yName='y1' name='Peter' width={2} type='StackingLine' marker={{ isFilled: true, visible: true, shape: 'Diamond', width: 7, height: 7 }}>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chartData} xName='x' yName='y2' name='Steve' width={2} type='StackingLine' marker={{ isFilled: true, visible: true, shape: 'Rectangle', width: 5, height: 5 }}>
                        </SeriesDirective>
                        <SeriesDirective dataSource={chartData} xName='x' yName='y3' name='Charle' width={2} type='StackingLine' marker={{ isFilled: true, visible: true, shape: 'Triangle', width: 6, height: 6 }}>
                        </SeriesDirective> */}
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </div>
    );
};

BarLineGraph.propTypes = {
    className: PropTypes.string,
    classc: PropTypes.string,
    
};

export default BarLineGraph;