import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Button, Fab
} from '@mui/material';
import { Edit, DeleteForever, LibraryAdd, CloudUpload } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { getAttributeMappingData, deleteAttributeMapping } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const AttributeMapping = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 100);
    }, []);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.attributeMapData) {
            setAttributeData(props.attributeMapData);
        }
    }, [props.attributeMapData]);

    function editAttribute(id) {
        history.push({
            pathname: '/attribute-mapping/edit-attribute/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/attribute-mapping/view-attribute/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteAttributeMapping(id);
    }
    const [salesBasisAll, setSalesBasisAll] = React.useState([]);
    const arrayItem = [];

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Attribute Mapping </Typography>

                    <Fab aria-label="edit" variant="extended"
                        size='medium'
                        classes={{ root: classes.fabContainer }}
                        className={classes.fabContainer}
                        //    component={CustomRouterLink}
                        onClick={() => history.push('/attribute-mapping/add-attribute')}
                    >
                        <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                    </Fab>
                </div>
                {attributeData && attributeData.total_record > 0 ?
                    <TableContainer style={{ marginTop: -25 }} r>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                    {/* <TableCell align='center' className={classes.tabHead}>Source Table Name</TableCell> */}
                                    <TableCell align='center' className={classes.tabHead}>Source Attribute </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Option </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Source Attribute Values </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Update Condition</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Target Attribute </TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Target Attribute Values </TableCell>
                                    <TableCell align='center' className={classes.tabHead} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {attributeData && attributeData.records.map(item => {
                                    arrayItem.push(item.source_data_type)
                                    arrayItem.sort(function (a, b) {
                                        return a.localeCompare(b); //using String.prototype.localCompare()
                                    });
                                    console.log(arrayItem)
                                    return (
                                        <StyledTableRow key={item.ID} >
                                            <TableCell align='center' onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer' }}>
                                                {item.source_data_type}{
                                                }</TableCell>
                                            {/* <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.source_table_name.replace(/_/g, ' ')}</TableCell> */}
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.multiple_attribute_filters && item.multiple_attribute_filters[0].attribute_name.replace('_', " ")}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.multiple_attribute_filters && item.multiple_attribute_filters[0].options?.toString()}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.multiple_attribute_filters && item.multiple_attribute_filters[0].attribute_values?.toString()}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.update_condition}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.labeled_mapped_attribute?.toString()}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewAttribute(item.ID)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item && item.target_attribute_values}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => editAttribute(item.ID)}
                                                    size="large">
                                                    <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => deleteData(item.ID)}
                                                    size="large">
                                                    <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            component="div"
                            count={props.attributeMapData && props.attributeMapData.records ? props.attributeMapData.total_record : 0}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onChangePage={handleChangePage}
                            rowsPerPageOptions={rowsPerPageOptions}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                caption: classes.caption,
                                root: classes.paginationRoot
                            }}
                        /> */}
                        {props.attributeData &&
                            <div className={classes.buttonContainer}>
                                <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                            </div>
                        }
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        There is no data to show now.
                    </Typography>
                }
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        attributeMapData: state.AttributeMapData.AttributeMapData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getAttributeMappingData(pagination, limit)),
        deleteAttributeMapping: (id) => dispatch(deleteAttributeMapping(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeMapping);