import React, { useEffect, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    IconButton,
    FormLabel,
    Select,
    MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText,

} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { getFilterNameList, addIntegrationBatchJobSetup, getIntegrationSetupData } from '../../../redux/actions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { DropdownArray, TextInputWithLabel } from '../../../components/Inputs';
import { LabelText } from '../../../components/Inputs';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: 40
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    select: {
        width: '100%',
        marginTop: 13,
        height: 36
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '1px 0 1px 11px',
        alignSelf: 'center',
        //alignItems: 'center',
        marginTop: 10,
        border: '1px solid #E0E0E0',
        width: '100%',
    },
}));

const AddIntegrationBatchJobSetup = props => {
    const afRef = useRef(null);
    const classes = useStyles();
    const history = useHistory();
    const [userStore, setUserStore] = React.useState([]);
    const [integrationName, setIntegrationName] = React.useState('');
    const [integration, setIntegration] = React.useState([]);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [batchJobType, setBatchJobType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [plannedStartDate, setPlannedStartDate] = React.useState(null);
    const [frequency, setFrequency] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [plannedStartTime, setPlannedStartTime] = React.useState(null);
    const [plannedDateType, setPlannedDateType] = React.useState('');
    const [plannedStartCalendar, setPlannedStartCalendar] = React.useState('');
    const [plannedStartCalendarArray, setPlannedStartCalendarArray] = React.useState([]);
    const [filterValue, setFiltervalue] = React.useState([]);
    const [filterValue2, setFiltervalue2] = React.useState([]);
    const [filterName, setFilterName] = React.useState([]);
    const [filterNameArray, setFilterNameArray] = React.useState([]);
    const [addMember, setAddMember] = React.useState(1);
    const [activityArray, setActivityArray] = React.useState([]);
    useEffect(() => {
        props.getIntegrationSetupData();
    }, []);
    useEffect(() => {
        if (props.filterNameList) {
            setFilterNameArray(props.filterNameList);
        }
    }, [props.filterNameList]);
    useEffect(() => {
        if (props.integrationSetupData && props.integrationSetupData.records.length > 0) {
            var newArray = []
            var newActivityArray = []
            props.integrationSetupData.records.map((item, index) => {
                newArray.push(item.name + '(' + item.ID + ')')
                newActivityArray.push(item.ID + '(' + item.activity)
            })
            setIntegration(newArray)
            setActivityArray(newActivityArray)
        }
    }, [props.integrationSetupData])
    useEffect(() => {
        if (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time') {
            setPlannedDateType('static');
        }
        else {
            setPlannedDateType('');
        }
    }, [frequency])
    const frequencyTypesArray = [
        'Hourly', 'Daily', 'Weekly', 'Monthly', 'Quarterly', 'One Time'
    ]
    function handleContinue() {
        var data1 = parseInt(batchJobName.split('(').pop());
        setOpen(false);
        var data
        activityArray.map(item => {
            data = item.slice(0, 2)
            if (parseFloat(data) == parseFloat(data1)) {
                props.getFilterNameList(item.split('(').pop(), 'date');
            }
        })
    }
    function handleClear() {
        setAddMember(1);
    }
    function handleOnSubmit() {
        var date = [];
        filterValue.map((item, index) => {
            date.push(filterValue[index].concat("To", filterValue2[index]))
        })
        var data = {
            "app_type": "Integration Studio",
            "batch_job_type": "Automatic Integration",
            "batch_job_name": batchJobName.replace(/[0-9]/g, '').slice(0, -2),
            "planned_date_type": plannedDateType,
            "planned_start_date": plannedStartDate ? Moment.utc(plannedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDThh:mm:ssZ') : Moment.utc().format('YYYY-MM-DDThh:mm:ssZ'),
            "planned_start_time": plannedStartTime.concat(":00"),
            "frequency": frequency,

            "batch_filter_criteria": [{
                "integration_id": parseInt(batchJobName.split('(').pop()),
                "integration_filters": filterName,
                "integration_filter_values": date,
            }]
        }
        props.onSubmit(data);
    }
    function handleClearAll() {
        handleClear();
        setUserStore([]);
    }
    function handleApplicationType(newValue) {
        setApplicationType(newValue)
    }
    function handlePlannedStartTime(event) {
        setPlannedStartTime(event.target.value);

        if (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time') {
            setPlannedDateType('static');
        } else {
            setPlannedDateType(plannedDateType);
        }
    }
    function handleFilterName(newValue, index) {
        filterName[index] = newValue;
    }
    function handleFilterValue(newValue, index) {
        filterValue[index] = newValue;
    }
    function handleFilterValue2(newValue, index) {
        filterValue2[index] = newValue;
    }
    function handleOnAdd() {
        filterName[addMember] = ''
        filterValue[addMember] = ''
        filterValue2[addMember] = ''
        setAddMember(addMember + 1)
    }
    const handleDeleteLocally = (e, index) => {
        setFilterName(item => item.filter((item, i) => i !== index));
        setFiltervalue(item => item.filter((item, i) => i != index));
        setFiltervalue2(item => item.filter((item, i) => i != index));
        setAddMember(addMember - 1 > 0 ? addMember - 1 : 1)
    }
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/batchjob-setup'
                >  Batch Job Setup
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Batch Job</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer} >
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'none' : 'flex') }}>
                                {batchJobName &&
                                    <Grid
                                        item
                                        md={10}
                                        xs={12}
                                    >  <LabelText heading='Integration Name' data={batchJobName} twoline='true' />
                                    </Grid>}
                                {(() => {
                                    let td = [];
                                    for (let i = 0; i < addMember; i++) {
                                        td.push(<>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >   <DropdownArray classes={{ root: classes.formLabel }} heading={i == 0 && 'Filter Name'}
                                                placeholder={'Please select'}
                                                twoline='true'
                                                onChange={(e) => handleFilterName(e, i)}
                                                data={filterNameArray}
                                                prevalue={'init'} />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                <TextInputWithLabel classes={{ root: classes.formLabel }}
                                                    heading={i == 0 && 'Start Date and Time'}
                                                    twoline='true'
                                                    onChange={(e) => handleFilterValue(e, i)}
                                                    data={filterValue}
                                                    type="datetime-local"
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                <TextInputWithLabel classes={{ root: classes.formLabel }}
                                                    heading={i == 0 && 'End Date and Time'}
                                                    type="datetime-local"
                                                    twoline='true'
                                                    onChange={(e) => handleFilterValue2(e, i)}
                                                    data={filterValue2}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={1}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                                style={{ paddingTop: 20, marginTop: 15 }}
                                            >
                                                <IconButton
                                                    style={{ marginTop: i == 0 ? 32 : 10 }}
                                                    onClick={(e) => handleDeleteLocally(e, i)}
                                                    classes={{ root: classes.IconButton }}
                                                    size="large">
                                                    <ClearIcon />
                                                </IconButton>
                                                <IconButton
                                                    style={{ visibility: i == addMember - 1 ? 'visible' : 'hidden', marginTop: i == 0 ? 32 : 10 }}
                                                    onClick={handleOnAdd}
                                                    classes={{ root: classes.IconButton }}
                                                    size="large">
                                                    <AddBoxOutlinedIcon />
                                                </IconButton>

                                            </Grid>
                                        </>)
                                    }
                                    return td;
                                })()}
                            </Grid>
                            <Grid container spacing={2} style={{ display: (batchJobType === 'Automatic Integration' ? 'flex' : 'none') }}>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >   <FormLabel className={classes.formLabel} required>
                                        Integration Name
                                    </FormLabel>
                                    <Select
                                        onChange={(e) => setIntegrationName(e.target.value)}
                                        value={integrationName}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        className={clsx({
                                            [classes.select]: true,
                                        })}
                                    >   <MenuItem value="">
                                            {props.placeholder}
                                        </MenuItem>
                                        {integration.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index} name={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card >
                <Dialog open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ paddingBottom: 39 }}
                                    >   <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Batch Job Name
                                            </FormLabel>
                                            <Select
                                                onChange={(e) => setBatchJobName(e.target.value)}
                                                value={batchJobName}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true,
                                                })}
                                            >   <MenuItem value="">
                                                    {props.placeholder}
                                                </MenuItem>
                                                {integration.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index} name={item}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >   <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Frequency
                                            </FormLabel>
                                            <Select
                                                value={frequency}
                                                onChange={(e) => setFrequency(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >   {frequencyTypesArray
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Date Type
                                            </FormLabel>
                                            <Select
                                                value={plannedDateType}
                                                onChange={(e) => setPlannedDateType(e.target.value)}
                                                displayEmpty
                                                disabled={!frequency || (frequency === 'Hourly' || frequency === 'Daily' || frequency === 'One Time')}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                <MenuItem value="static">Static</MenuItem>
                                                <MenuItem value="dynamic" style={{ display: (frequency === 'Monthly' || frequency === 'Quarterly') ? 'block' : 'none' }}>
                                                    Dynamic
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType != 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Date
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Grid container justifyContent="space-around">
                                                <DatePicker
                                                    disableToolbar
                                                    clearable
                                                    InputProps={{
                                                        padding: 0,
                                                        disableUnderline: true,
                                                        style: {
                                                            // padding: '1px 4px 1px 11px',
                                                            alignSelf: 'center',
                                                            alignItems: 'center',
                                                            marginTop: 12,
                                                            height: 38,
                                                            border: '1px solid #E0E0E0',
                                                            width: '100%'
                                                        }
                                                    }}
                                                    value={plannedStartDate}
                                                    onChange={(e) => setPlannedStartDate(e)}
                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                    format="MM/DD/YYYY"
                                                />
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        style={{ display: plannedDateType === 'dynamic' ? 'block' : 'none', paddingRight: 10 }}
                                    >
                                        <div className={classes.selectRoot}>
                                            <FormLabel className={classes.formLabel} required>
                                                Planned Start Calendar
                                            </FormLabel>
                                            <Select
                                                value={plannedStartCalendar}
                                                onChange={(e) => setPlannedStartCalendar(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            > {plannedStartCalendarArray && plannedStartCalendarArray.drop_down_values && plannedStartCalendarArray.drop_down_values
                                                .filter(item => item != 'Manual')
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>

                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}

                                    >
                                        <FormLabel className={classes.formLabel} required>
                                            Planned Start Time
                                        </FormLabel>
                                        <div className={classes.selectRoot}
                                            style={{ padding: 0 }}>
                                            <TextField
                                                id="time"
                                                type="time"
                                                //defaultValue=""
                                                value={plannedStartTime}
                                                onChange={handlePlannedStartTime}
                                                // className={classes.textField}
                                                style={{ paddingLeft: -19, width: '98%' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => history.push('/integration-batchjob-setup')} color="primary" variant="outlined" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleContinue()} color="primary" autoFocus variant="contained"
                            disabled={batchJobName && plannedStartTime && frequency && plannedStartDate ? false
                                : true
                            }
                        > Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                    >
                        Submit
                    </Button>
                </div>

            </div >
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        integrationSetupData: state.customerData.integrationSetupData,
        filterNameList: state.customerData.filterNameList,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addIntegrationBatchJobSetup(data)),
        getIntegrationSetupData: (pagination, limit) => dispatch(getIntegrationSetupData(pagination, limit)),
        getFilterNameList: (activity, date) => dispatch(getFilterNameList(activity, date)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddIntegrationBatchJobSetup);