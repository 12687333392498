import React, { useEffect } from "react";
import Moment from "moment";
import {
  getDefaultValuesAllDataNew,
  loadValuesBasedOnAppTypeAndField,
  loadIncentiveTrends,
  getContractsTableDataByApplicationType,
  allowedApps, getLblDispDesFieldValue, dashboardAnalyticsNames
} from "../../redux/actions";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Select,
  MenuItem,
  FormLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import clsx from "clsx";
import { Chart } from "react-google-charts";
import { Grid, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const IncentiveTrends = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "15px 0px 15px 0px",
      display: "flex",
      alignItems: "center",
    },
    rootDiv: {
      backgroundColor: theme.palette.white,
      borderRadius: 10,
      margin: "5px 0px 16px 0px",
    },
    graphContainer: {
      width: "80%",
      height: "100%",
      marginTop: 10,
      display: "flex",
      alignSelf: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    select: {
      width: "100%",
      color: "#1675e0",
      backgroundColor: "white",
    },
    selectedItem: {
      borderRadius: 5,
    },
    buttonContainer: {
      justifyContent: "center",
      display: "flex",
      paddingTop: 34,
      paddingBottom: 5,
    },
    button: {
      marginRight: 10,
    },
    fontSetting: {
      fontSize: theme.typography.h3.fontSize,
    },
    autocompleteInput: {
      "& .MuiOutlinedInput-input": {
        height: 18, // Adjust the height as per your requirement
      },
    },
  }));

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [contractNumber, setContractNumber] = React.useState(0);
  const [labelNewAPIData, setLabelNewAPIData] = React.useState({});
  const [postingType, setPostingType] = React.useState("accrual");
  const [sourceDataType, setSourceDataType] = React.useState("");
  const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem("application"));
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([]);
  const [avssGraphData, setAvssGraphData] = React.useState([]);
  const [avssGraphDataTier, setAvssGraphDataTier] = React.useState([]);
  const [contractNumbersList, setContractNumbersList] = React.useState([]);
  const [avssGraphDataBottom, setAvssGraphDataBottom] = React.useState([]);
  const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
  const [radioGroupValue, setRadioGroupValue] = React.useState("incentive_amount");
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (
      props.labelNewAPIData &&
      Object.keys(props.labelNewAPIData).length > 0
    ) {
      setLabelNewAPIData(props.labelNewAPIData.field_label_attributes);
    }
  }, [props.labelNewAPIData]);

  function checkLabel(name) {
    var x =
      Object.keys(labelNewAPIData).length > 0 &&
      Object.entries(labelNewAPIData).map(([key, value]) => {
        if (key == name) {
          return labelNewAPIData[name]["current"];
        }
      });
    var filteredData =
      x &&
      x.filter(function (element) {
        return element !== undefined;
      });
    if (filteredData && filteredData.length > 0) {
      console.log(filteredData[0]);
      return filteredData[0];
    } else {
      return name;
    }
  }

  useEffect(() => {
    if (props.allowedAnalyticsAppsData && props.allowedAnalyticsAppsData.length > 0) {
      const postingsSummaryAnalytic = props.allowedAnalyticsAppsData.find(item =>
        item.analytic_name === "Trending by Contract Number"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedAnalyticsAppsData]);

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setContractNumber(allowedTilesFilter[0].contract_number);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && postingType)
      props.incentiveTrends(
        Moment(startDate).format("MM/DD/YYYY"),
        Moment(endDate).format("MM/DD/YYYY"),
        postingType,
        applicationType,
        sourceDataType,
        contractNumber
      );
  }, [allowedTilesFilter]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
  };

  const handleApplicationtype = (e) => {
    props.onCheckContractsTable(e.target.value);
    setSourceDataType("");
    setSourceDataTypeDataFromAPI([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    setApplicationType(e.target.value);
  };

  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
  };

  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);

  useEffect(() => {
    props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
    props.allowedAnalyticsApps(sessionStorage.getItem("application"));
  }, []);

  const handleContractNumber = (e) => {
    setContractNumber(e);
  };

  function handleRadioGroup(newValue) {
    setRadioGroupValue(newValue.target.value);
  }

  useEffect(() => {
    props.allowedApps();
  }, []);

  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);

  useEffect(() => {
    var label = ["", "Incentive Amount"];
    var mapData = [];
    mapData.push(label);
    if (props.incentiveTrendsData && props.incentiveTrendsData.length > 0) {
      var data = props.incentiveTrendsData;
      var temp = [];
      data &&
        data.length > 0 &&
        data.map((item) => {
          temp.push([
            item.incentive_date,
            item.incentive_amount ? Number(item.incentive_amount) : 0,
          ]);
        });
      mapData.push(...temp);
      setAvssGraphData(mapData);
    } else {
      setAvssGraphData(label);
    }
    var labelTier = ["", "Tier Basis"];
    var mapDataTier = [];
    mapDataTier.push(labelTier);
    if (props.incentiveTrendsData && props.incentiveTrendsData.length > 0) {
      var dataTier = props.incentiveTrendsData;
      var tempTier = [];
      dataTier &&
        dataTier.length > 0 &&
        dataTier.map((item) => {
          tempTier.push([
            item.incentive_date,
            item.tier_basis ? Number(item.tier_basis) : 0,
          ]);
        });
      mapDataTier.push(...tempTier);
      setAvssGraphDataTier(mapDataTier);
    } else {
      setAvssGraphDataTier(labelTier);
    }
    var label2 = [];
    var label2Display = [];
    var mapData2 = [];
    if (props.incentiveTrendsData && props.incentiveTrendsData.length > 0) {
      var data2 = props.incentiveTrendsData;
      var temp2 = [];
      var singleData = props.incentiveTrendsData[0];
      var dynamicHead = Object.keys(singleData);
      const removableLabel = [
        "tier_basis",
        "incentive_amount",
        "contract_number",
      ];
      dynamicHead = dynamicHead.filter(function (item) {
        return !removableLabel.includes(item);
      });
      label2Display.push("");
      dynamicHead
        .filter((item) => item != "incentive_date")
        .map((item) => {
          label2Display.push(item);
        });
      label2 = dynamicHead;
      mapData2.push(label2Display);
      data &&
        data.length > 0 &&
        data.map((item) => {
          temp2.push(
            label2 &&
            label2.map((lab) => {
              return lab === "incentive_date" ? item[lab] : Number(item[lab]);
            })
          );
        });
      mapData2.push(...temp2);

      mapData2 &&
        mapData2[0] &&
        mapData2[0].map((item, i) => {
          var lab = checkLabel(item);
          mapData2[0][i] = lab;
        });

      setAvssGraphDataBottom(mapData2);
    } else {
      label2 = ["Incentive Date"];
      mapData2.push(label2);

      mapData2 &&
        mapData2[0] &&
        mapData2[0].map((item, i) => {
          var lab = checkLabel(item);
          mapData2[0][i] = lab;
        });

      setAvssGraphDataBottom(mapData2);
    }
  }, [props.incentiveTrendsData]);

  useEffect(() => {
    var data = { "data_source": "Direct - Sales Data" }
    props.getLblDispDesFieldValue(data, 'post');
    props.onCheckContractsTable(applicationType);
  }, []);

  useEffect(() => {
    var contracts = [];
    if (
      props.contractTabledataComplete &&
      props.contractTabledataComplete.records &&
      props.contractTabledataComplete.records.length > 0
    ) {
      props.contractTabledataComplete.records.map((item) => {
        contracts.push(item.contract_number.toString());
      });
      setContractNumbersList(contracts);
    }
    var tempNameArray = [];
    props.contractTabledataComplete &&
      props.contractTabledataComplete.records &&
      props.contractTabledataComplete.records.map((item) => {
        tempNameArray.push(item.internal_description);
      });
    setInternalDescriptionArray(tempNameArray);
  }, [props.contractTabledataComplete]);

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleRun = () => {
    if (startDate && endDate)
      props.incentiveTrends(
        Moment(startDate).format("MM/DD/YYYY"),
        Moment(endDate).format("MM/DD/YYYY"),
        postingType,
        applicationType,
        sourceDataType,
        contractNumber
      );
  }

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      internalDescriptionArray[contractNumbersList.indexOf(option)] + option,
  });

  return (
    <div className={classes.rootDiv}>
      <Grid container className={classes.root}>
        <Grid
          container
          style={{
            margin: 10,
            padding: 5,
            border: "1px solid #E6E6E6",
            borderRadius: 5,
          }}
        >
          {/* <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Application Type
              </Typography>
              <Select
                value={applicationType}
                onChange={handleApplicationtype}
                displayEmpty
                className={clsx({
                  [classes.select]: true,
                })}
                classes={{
                  selectMenu: classes.selectedItem,
                }}
              >
                {allowedApps
                  .filter(
                    (item) =>
                      item !== "Customer Chargeback" &&
                      item !== "Promotions" &&
                      item !== "Supplier Chargeback" &&
                      item !== "Pricing" &&
                      item !== "Profit Optimization"
                  )
                  .map((item, index) => {
                    return (
                      <MenuItem
                        value={item}
                        key={index}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </Grid> */}

          <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Source Data Type
              </Typography>
              <Select
                value={sourceDataType}
                onChange={handleSourceDataType}
                displayEmpty
                className={clsx({
                  [classes.select]: true,
                })}
                classes={{
                  selectMenu: classes.selectedItem,
                }}
              >
                {sourceDataTypeDataFromAPI.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>

          <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Posting Type
              </Typography>
              <Select
                value={postingType}
                onChange={handlePostingType}
                label="Posting Type"
                className={clsx({
                  [classes.select]: true,
                })}
                classes={{
                  selectMenu: classes.selectedItem,
                }}
              >
                <MenuItem value={"accrual"}>Accrual</MenuItem>
                <MenuItem value={"payment"}>Payment</MenuItem>
              </Select>
            </div>
          </Grid>

          <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container justifyContent="space-around">
                  <DatePicker
                    disableToolbar
                    clearable
                    InputProps={{
                      padding: 0,
                      disableUnderline: true,
                      style: {
                        // padding: "1px 0 1px 11px",
                        alignSelf: "center",
                        alignItems: "center",
                        border: "1px solid #E0E0E0",
                        width: "100%",
                        borderRadius: 5,
                        height: 37,
                        color: "#1675e0",
                      },
                    }}
                    value={startDate}
                    onChange={handleStartDate}
                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                    format="MM/DD/YYYY"
                  />
                </Grid>
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Grid container justifyContent="space-around">
                  <DatePicker
                    disableToolbar
                    clearable
                    InputProps={{
                      padding: 0,
                      disableUnderline: true,
                      style: {
                        // padding: "1px 0 1px 11px",
                        alignSelf: "center",
                        alignItems: "center",
                        border: "1px solid #E0E0E0",
                        width: "100%",
                        borderRadius: 5,
                        height: 37,
                        color: "#1675e0",
                      },
                    }}
                    value={endDate}
                    onChange={handleEndDate}
                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                    format="MM/DD/YYYY"
                    minDate={startDate ? startDate : false}
                  />
                </Grid>
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={2} xs={12} style={{ paddingRight: 15 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography classes={{ root: classes.fontSetting }}>
                Contract Number
              </Typography>
              <Autocomplete
                options={contractNumbersList}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    style={{ height: 20, borderRadius: "0px !important" }}
                    className={classes.autocompleteInput}
                  />
                )}
                filterOptions={filterOptions}
                value={contractNumber}
                style={{ marginBottom: 10 }}
                onChange={(event, newValue) => {
                  handleContractNumber(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {internalDescriptionArray[
                      contractNumbersList.indexOf(option)
                    ]
                      ? internalDescriptionArray[
                      contractNumbersList.indexOf(option)
                      ]
                      : ""}{" "}
                    - ({option}){" "}
                  </li>
                )}
                classes={{
                  option: {
                    borderBottom: `1px solid red`,
                    // Hover
                    '&[data-focus="true"]': {
                      backgroundColor: "red",
                      borderColor: "transparent",
                    },
                    // Selected
                    '&[aria-selected="true"]': {
                      backgroundColor: "red",
                      borderColor: "transparent",
                    },
                  },
                  listbox: {
                    padding: 0,
                  },
                  inputRoot: {
                    borderRadius: "0px !important",
                  },
                }}
              />
            </div>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleRun}
              twoline="true"
              disabled={(!startDate || !endDate || !postingType) ? true : false}
            >
              <DirectionsRunIcon />
            </Button>
          </div>
        </Grid>
        {/* to unhide */}

        {contractNumber && startDate && endDate ? (
          <Grid container className={classes.root}>
            <Grid
              item
              md={12}
              xs={12}
              classes={{ root: classes.gridContainer }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 50,
              }}
            >
              <RadioGroup
                row
                aria-label="position"
                name="position"
                className={classes.radioContainer}
                onChange={handleRadioGroup}
                value={radioGroupValue}
              >
                <FormControlLabel
                  value="incentive_amount"
                  control={<Radio color="primary" />}
                  label="Incentive Amount"
                  classes={{ labelPlacementStart: classes.radioLabel }}
                />
                <FormControlLabel
                  value="tier_basis"
                  control={<Radio color="primary" />}
                  label="Tier Basis"
                  classes={{ labelPlacementStart: classes.radioLabel }}
                />
              </RadioGroup>
            </Grid>
            {radioGroupValue === "incentive_amount" && (
              <Grid item md={12} xs={6} className={classes.container}>
                <div className={`area-chart-wrapper${classes.graphContainer}`}>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={avssGraphData}
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              </Grid>
            )}
            {radioGroupValue === "tier_basis" && (
              <Grid item md={12} xs={6} className={classes.container}>
                <div className={`area-chart-wrapper${classes.graphContainer}`}>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="Bar"
                    loader={<div>Loading Tier Basis Chart</div>}
                    data={avssGraphDataTier}
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              </Grid>
            )}

            <Grid item md={12} xs={6} className={classes.container}>
              <div
                className={`area-chart-wrapper${classes.graphContainer}`}
                style={{ marginTop: 15 }}
              >
                <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={avssGraphDataBottom}
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}></div>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    incentiveTrendsData: state.initialData.incentiveTrendsData,
    contractTabledataComplete: state.addContractData.contractAllAppType,
    labelNewAPIData: state.addMultipleConfigurationData.defaultValueLblDisp,
    allowedAppsData: state.initialData.allowedApps,
    allowedAnalyticsAppsData: state.initialData.dashboardanalyticsname
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    incentiveTrends: (
      s,
      e,
      postingType,
      applicationType,
      sourceDataType,
      contractNumber
    ) =>
      dispatch(
        loadIncentiveTrends(
          s,
          e,
          postingType,
          applicationType,
          sourceDataType,
          contractNumber
        )
      ),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    onCheckContractsTable: (appType) =>
      dispatch(getContractsTableDataByApplicationType(appType)),
    getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    allowedApps: () => dispatch(allowedApps()),
    allowedAnalyticsApps: (type) => dispatch(dashboardAnalyticsNames(type, "analytics"))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncentiveTrends);
