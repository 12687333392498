import React, { useEffect, forwardRef, useRef } from 'react';
import {
    Grid,
    Button,
    Typography,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    IconButton,
    OutlinedInput,
    FormLabel
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import { getEligibilityRulesTableData, editEligibilityRules, getDefaultValues } from '../../../../redux/actions';
import momentt from 'moment-timezone';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    container2: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height:'38px'
    },
    error: {
        border: '1px solid red !important'
    },
    //new styles
    formLabel: {
        fontSize: 13,
        height: 30
    }
}));

const OrganizationData = forwardRef((props) => {
    useEffect(() => {
        if (localStorage.getItem('mode') === 'edit')
            props.onCheckEligibilityRulesTable();
        props.onLoadingDefault();
    }, []);
    const [organizationStore, setOrganizationStore] = React.useState([]);
    const classes = useStyles();
    const { className } = props;
    const [editMode, setEditMode] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState('');
    const [salesOrganization, setSalesOrganization] = React.useState('');
    const [distributionChannel, setDistributionChannel] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [companyCode, setCompanyCode] = React.useState('');
    const [priceListType, setPriceListType] = React.useState('');
    const [priceGroup, setPriceGroup] = React.useState('');
    const [shipToParty, setShipToParty] = React.useState('');
    const [billToParty, setBillToParty] = React.useState('');
    const [payer, setPayer] = React.useState('');
    const [flexAttribute5, setFlexAttribute5] = React.useState('');
    const [flexAttribute6, setFlexAttribute6] = React.useState('');
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            }
        }
    }))(TableRow);
    function handleSalesOrganization(event) {
        setSalesOrganization(event.target.value);
    }
    function handleDistributionChannel(event) {
        setDistributionChannel(event.target.value);
    }
    function handleDivision(event) {
        setDivision(event.target.value);
    }
    function handleCompanyCode(event) {
        setCompanyCode(event.target.value);
    }
    function handlePriceListType(event) {
        setPriceListType(event.target.value);
    }
    function handlePriceGroup(event) {
        setPriceGroup(event.target.value);
    }
    function handleShipToParty(event) {
        setShipToParty(event.target.value);
    }
    function handleBillToParty(event) {
        setBillToParty(event.target.value);
    }
    function handlePayer(event) {
        setPayer(event.target.value);
    }
    function handleFlexAttribute5(event) {
        setFlexAttribute5(event.target.value);
    }
    function handleFlexAttribute6(event) {
        setFlexAttribute6(event.target.value);
    }
    function handleSubmit(index) {
        var data = {
            "sales_organization": salesOrganization,
            "distribution_channel": distributionChannel,
            "division": division,
            "company_code": companyCode,
            "price_list_type": priceListType,
            "price_group": priceGroup,
            "ship_to_party": shipToParty,
            "bill_to_party": billToParty,
            "payer": payer,
            "flex_attribute_5": flexAttribute5,
            "flex_attribute_6": flexAttribute6,
        };
        if (editIndex || editIndex === 0) {
            var editedArray = [...organizationStore];
            editedArray[editIndex] = data;
            setOrganizationStore(editedArray);
            props.onChange(editedArray)
        }
        else {
            setOrganizationStore([...organizationStore, data]);
            props.onChange([...organizationStore, data])
        }
        setEditMode(false);
        setEditIndex('');
        handleRemove();
    }
    function handleRemove() {
        setEditMode(false);
        setEditIndex('');
        setSalesOrganization('');
        setDistributionChannel('');
        setDivision('');
        setCompanyCode('');
        setPriceListType('');
        setPriceGroup('');
        setShipToParty('');
        setBillToParty('');
        setPayer('');
        setFlexAttribute5('');
        setFlexAttribute6('');
    }
    function handleEditRow(item, index) {
        setEditIndex(index)
        setEditMode(true);
        setSalesOrganization(item.sales_organization);
        setDistributionChannel(item.distribution_channel);
        setDivision(item.division);
        setCompanyCode(item.company_code);
        setPriceListType(item.price_list_type);
        setPriceGroup(item.price_group);
        setShipToParty(item.ship_to_party);
        setBillToParty(item.bill_to_party);
        setPayer(item.payer);
        setFlexAttribute5(item.flex_attribute_5);
        setFlexAttribute6(item.flex_attribute_6);
    }
 const handleDeleteLocally = (index) => {
        setOrganizationStore(item => item.filter((item, i) => i !== index));
        props.onChange(organizationStore.filter((item, i) => i !== index))
    }
    return (
        <div>
            <div className={clsx(classes.root, className)}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Sales organization*
                                            </FormLabel>
                                        <OutlinedInput
                                            value={salesOrganization}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleSalesOrganization} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Distrubution Channel*
                                            </FormLabel>
                                        <OutlinedInput
                                            value={distributionChannel}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDistributionChannel} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Division*
                                            </FormLabel>
                                        <OutlinedInput
                                            value={division}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleDivision} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Company Code*
                                            </FormLabel>
                                        <OutlinedInput
                                            value={companyCode}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleCompanyCode} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Price List Type
                                            </FormLabel>
                                        <OutlinedInput
                                            value={priceListType}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePriceListType} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Price Group
                                            </FormLabel>
                                        <OutlinedInput
                                            value={priceGroup}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePriceGroup} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Ship To Party
                                            </FormLabel>
                                        <OutlinedInput
                                            value={shipToParty}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleShipToParty} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Bill To Party
                                            </FormLabel>
                                        <OutlinedInput
                                            value={billToParty}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleBillToParty} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Payer
                                            </FormLabel>
                                        <OutlinedInput
                                            value={payer}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handlePayer} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 5
                                            </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute5}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute5} />
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                    xs={12}
                                >
                                    <div className={classes.selectRoot}>
                                        <FormLabel className={classes.formLabel}>
                                            Flex Attribute 6
                                            </FormLabel>
                                        <OutlinedInput
                                            value={flexAttribute6}
                                            inputProps={{ min: "0", step: "1" }}
                                            classes={{ root: classes.inputTwoLine }}
                                            onChange={handleFlexAttribute6} />
                                    </div>
                                </Grid>
                                <Grid item md={1} classes={{ root: classes.centerButton }}>
                                    <Grid container style={{ paddingTop: 19 }} >
                                        <Grid item md={6} >
                                            {editMode ?
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    onClick={() => handleSubmit(editIndex)}
                                                    disabled={salesOrganization && distributionChannel && division && companyCode ? false : true}
                                                >
                                                </Button>
                                                :
                                                <Button
                                                    color='secondary'
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    classes={{
                                                        startIcon: classes.startIcon,
                                                        root: classes.checkIconContainer
                                                    }}
                                                    disabled={salesOrganization && distributionChannel && division && companyCode ? false : true}
                                                    onClick={handleSubmit}
                                                >
                                                </Button>
                                            }
                                        </Grid>
                                        <Grid item
                                            md={6}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<DeleteForeverIcon />}
                                                classes={{
                                                    startIcon: classes.startIcon,
                                                    root: classes.deleteIconContainer,
                                                }}
                                                onClick={handleRemove}
                                            >
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="ORGANIZATION DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {organizationStore.length > 0 ?
                                <TableContainer>
                                    <Table>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell align='center'>Sales Organization</TableCell>
                                                <TableCell align='center' >Distribution Channel</TableCell>
                                                <TableCell align='center'>Division</TableCell>
                                                <TableCell align='center'>Company Code</TableCell>
                                                <TableCell align='center'>Price List Type</TableCell>
                                                <TableCell align='center'>Price Group</TableCell>
                                                <TableCell align='center'>Ship To Party</TableCell>
                                                <TableCell align='center'>Bill To Party</TableCell>
                                                <TableCell align='center'>Payer</TableCell>
                                                <TableCell align='center'>Flex Attribute 5</TableCell>
                                                <TableCell align='center'>Flex Attribute 6</TableCell>
                                                <TableCell align='center' style={{width: 70}}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody classes={{ root: classes.table }} >
                                            {organizationStore.map((item, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell align='center'>{item.sales_organization}</TableCell>
                                                        <TableCell align='center'>{item.distribution_channel}</TableCell>
                                                        <TableCell align='center'>{item.division}</TableCell>
                                                        <TableCell align='center'>{item.company_code}</TableCell>
                                                        <TableCell align='center'>{item.price_list_type}</TableCell>
                                                        <TableCell align='center'>{item.price_group}</TableCell>
                                                        <TableCell align='center'>{item.ship_to_party}</TableCell>
                                                        <TableCell align='center'>{item.bill_to_party}</TableCell>
                                                        <TableCell align='center'>{item.payer}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_5}</TableCell>
                                                        <TableCell align='center'>{item.flex_attribute_6}</TableCell>
                                                        <TableCell align='center'>
                                                            <IconButton
                                                                classes={{ root: classes.IconButton }}
                                                                onClick={() => handleEditRow(item, index)}
                                                                size="large">
                                                                <EditIcon color="disabled" style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLocally(index)}
                                                                classes={{ root: classes.IconButton }}
                                                                size="large">
                                                                    <DeleteForeverIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </div>
    );

});

const mapDispatchToProps = dispatch => {
    return {
        onEligibilityRulesEdit: (data, id) => dispatch(editEligibilityRules(data, id)),
        onCheckEligibilityRulesTable: () => dispatch(getEligibilityRulesTableData(localStorage.getItem('currentAddContractID'))),
        onLoadingDefault: () => dispatch(getDefaultValues())
    }
}

const mapStateToProps = state => {
    return {
        tableData: state.addContractData.eligibilityRulesData,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationData);