import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Card,
  FormLabel,
  MenuItem,
  Select,
  ListItemText,
  TextField,
  Checkbox,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  addAnalysisDesigner,
  getSingleAnalysisDesigner,
  getAnalysisDesignerList,
  allowedApps,
  getAnalyticsFilters,
  postAccessData,
  addUser,
  getAccessDataNew,
  appRights,
  getOrganizationAppTitle,
} from "../../redux/actions";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import ChipInput from "material-ui-chip-input";
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: 10,
    borderRadius: 10,
    padding: "27px 29px 27px 29px",
  },
  bodyContainer: {
    backgroundColor: theme.palette.white,
    border: "1px solid #EEEEEE",
    borderRadius: 6,
    marginBottom: 23,
    marginTop: 25,
  },
  link: {
    color: theme.palette.text.primary,
    opacity: 0.3,
    textDecoration: 'none',
    border: 0,
    fontSize: 16,
    paddingRight: 10
  },
  container: {
    padding: 10,
  },
  gridContainer: {
    padding: "0px 16px 0px 16px",
  },
  button: {
    marginRight: 25,
    width: 140,
  },
  selectRoot: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  select: {
    width: "100%",
  },
  selectedItem: {
    color: theme.palette.text.grey,
    marginTop: 10,
    width: "100%",
  },
  buttonRoot: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 28,
  },
  paper: { minWidth: window.screen.width * 0.5 },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginBottom: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  formDiv: {
    fontSize: theme.typography.h3.fontSize,
  },
  dropdownContainer: {
    maxHeight: 50,
    overflowY: "scroll",
  },
  rootContainer: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: 3,
    height: 36,
    padding: 0,
  },
  chip: {
    height: 21,
    margin: 0,
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: "#E1E4F3",
    "&&:hover": {
      backgroundColor: "#E1E4F3",
      color: theme.palette.black,
    },
    fontFamily: "ProximaNova",
    padding: 0,
  },
  input: {
    padding: 0,
    height: 21,
    fontFamily: "ProximaNova",
    fontSize: 13,
  },
  inputRoot: {
    height: 21,
    paddingLeft: 8,
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "overlay",
    flexFlow: "unset",
    borderRadius: 5,
  },
  dropdownAction: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #ddd",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  btn: {
    marginRight: 25,
    padding: "10px,15px",
  },
}));

const AddAnalyticsDesigner = (props) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        overflowY: 'scroll',
        scrollBehaviour: ' smooth',
        MarginTop: 0,
        menuPlacement: "top"
      }
    }, getContentAnchorEl: null,
  }
  const classes = useStyles();
  const [editID, setEditID] = React.useState(0);
  const [applicationType, setApplicationType] = React.useState("");
  const [accessData, setAccessData] = React.useState([]);
  const [allowedApps, setAllowedApps] = React.useState([]);
  const [analyticName, setAnalyticName] = React.useState("");
  const [analyticNameArray, setAnalyticNameArray] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [filtersArray, setFiltersArray] = React.useState([]);
  const [listOpen, setListOpen] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
  const [customerNumberList, setCustomerNumberList] = React.useState([]);
  const [functionality, setFunctionality] = React.useState("");
  const isAllSelected =
    filtersArray.length > 0 && selected.length === filtersArray.length;
  const functionalityArray = ["Dashboard", "Analytics"];
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [postingType, setPostingType] = React.useState("");
  const [dataSourceType, setDataSourceType] = React.useState("");
  const [groupBy, setGroupBy] = React.useState("");
  const startDateValues = [
    "First Day of Last Year",
    "First Day of Current Year",
    "First Day of Last Three Months",
    "First Day of Last Six Months",
  ];
  const endDateValues = [
    "Last Day of Current Month",
    "Last Day of Previous Month",
    "Last Day of Last Year",
  ];
  const postingTypeValues = ["Accrual", "Payment"];
  const dataSourceTypeValues = ["Direct - Sales Data", "Indirect - Sales Data"];
  const groupByValues = [
    "Contract Type",
    "Contract Group",
    "Contract Sub Group",
  ];
  const [apps, setApps] = React.useState([]);
  const [securityRole, setSecurityRole] = React.useState([]);
  useEffect(() => {
    props.allowedApps();
    //props.getAnalysisDesignerList();
  }, []);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);
  useEffect(() => {
    props.getAccessData();
    props.getOrganizationAppTitle();
  }, []);
  useEffect(() => {
    if (props.accessData) {
      setAccessData(props.accessData);
      // setApps(props.accessData.name)
    }
  }, [props.accessData]);

  const handleAccessData = (e) => {
    setAccessData(e.target.value);
  };
  const handleAppsData = (e) => {
    setApps(e.target.value);
  };
  useEffect(() => {
    var newArray = [];
    if (props.analysisDropdown && props.analysisDropdown.length > 0) {
      props.analysisDropdown.map((item) => {
        newArray.push(item);
      });
    }
    // setAnalyticNameArray(newArray)
  }, [props.analysisDropdown]);
  useEffect(() => {
    var pathname = window.location.pathname;
    var appId = pathname.substring(pathname.lastIndexOf("/") + 1);
    setEditID(appId);
    if (appId) props.getSingleAttributeMapping(appId);
  }, []);

  useEffect(() => {
    if (props.attributeMapData) {
      setApplicationType(props.attributeMapData.application_type);
      setFunctionality(props.attributeMapData.functionality);
      setApps(props.attributeMapData.security_role);
      setAnalyticName(props.attributeMapData.analytic_name);
      handleAnalysisName(props.attributeMapData.analytic_name, true);
      // setAnalyticName(props.attributeMapData.analytic_name)
      // setFilters(props.attributeMapData.filters?props.attributeMapData.filters:[])
      // setSelected(props.attributeMapData.filters)
      analyticNameArray.map((item) => {
        if (item.analytic_name === analyticName) {
          setFilters(item.filters);
          setSelected(item.filters);
          setCustomerNameListArray(item.filters);
        }
      });
      props.getAnalyticsFilters(
        props.attributeMapData.application_type,
        props.attributeMapData.functionality
      );
    }
  }, [props.attributeMapData]);
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      customerNameListArray[customerNumberList.indexOf(option)] + option,
  });
  const handleApplicationtype = (e) => {
    setApplicationType(e.target.value);
    props.getAnalyticsFilters(e.target.value);
  };
  function handleAnalysisName(e, takeValue) {
    if (takeValue) {
      setAnalyticName(e);
      var farray = [];
      if (
        props.analyticsfiltersDropdown &&
        props.analyticsfiltersDropdown.length > 0
      ) {
        props.analyticsfiltersDropdown.map((item) => {
          if (
            item.analytic_name === e &&
            item.filters &&
            item.filters.length > 0
          ) {
            farray.push(...item.filters);
          }
          setFiltersArray(farray);
        });
      }
    } else {
      setAnalyticName(e);
      setAnalyticName(e.target.value);
      var farray = [];
      if (
        props.analyticsfiltersDropdown &&
        props.analyticsfiltersDropdown.length > 0
      ) {
        props.analyticsfiltersDropdown.map((item) => {
          if (
            item.analytic_name === e.target.value &&
            item.filters &&
            item.filters.length > 0
          ) {
            farray.push(...item.filters);
          }
          setFiltersArray(farray);
        });
      }
    }
  }
  function handleOnSubmit() {
    Object.entries(attributeObjectAPI).map(([key, value]) => {
      if (filters.includes(key)) {
      } else {
        delete attributeObjectAPI[key];
      }
    });
    var data = {
      application_type: applicationType,
      security_role: apps,
      functionality: functionality,
      analytic_name: analyticName,
      filters: attributeObjectAPI,
    };
    props.onSubmit(data, editID);
  }
  const handleFunctionality = (e) => {
    setFunctionality(e.target.value);
    props.getAnalyticsFilters(applicationType, e.target.value);
  };
  useEffect(() => {
    var newArray = [];
    if (
      props.analyticsfiltersDropdown &&
      props.analyticsfiltersDropdown.length > 0
    ) {
      props.analyticsfiltersDropdown.map((item) => {
        newArray.push(item.analytic_name);
      });
    }
    setAnalyticNameArray(newArray);
  }, [props.analyticsfiltersDropdown]);
  const handleSelectValues = (event, value) => {
    let newSelected = [];
    const selectedIndex = selected.indexOf(value);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    if (value === "all") {
      setSelected(selected.length === filtersArray.length ? [] : filtersArray);
      return;
    }
    setSelected(newSelected);
  };
  function onAdd() {
    setFilters(selected);
    setListOpen(false);
  }
  function onCancel() {
    setListOpen(false);
    setSelected([]);
  }
  function handleOpen() {
    setSelected(filters);
    setListOpen(true);
  }
  const [attributeObjectAPI, setAttributeObjectAPI] = React.useState([]);
  const handleAttributeValues = (value, item) => {
    if (item === "start_date") {
      setStartDate(value);
    }
    if (item === "end_date") {
      setEndDate(value);
    }
    if (item === "posting_type") {
      setPostingType(value);
    }
    if (item === "data_source_type") {
      setDataSourceType(value);
    }
    if (item === "groupBy") {
      setGroupBy(value);
    }
    var attributeObject = [];
    attributeObject.push({
      [item]: value,
    });
    if (item === "customer_number") {
      value = [value];
    }
    setAttributeObjectAPI((prevState) => ({
      ...prevState,
      [item]: value,
    }));
  };
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          variant="h4"
          classes={{
            root: classes.link,
          }}
          to="/analytics-designer"
        >
          Analytics Designer
        </Link>
        <Typography color="textPrimary" variant="h4">
          Edit Analytics Designer
        </Typography>
      </Breadcrumbs>
      <div className={classes.bodyContainer}>
        <Card>
          <form autoComplete="off" noValidate>
            <div className={classes.container}>
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} required>
                    Application Type
                  </FormLabel>
                  <Select
                    value={applicationType}
                    onChange={handleApplicationtype}
                    displayEmpty
                    MenuProps={{ ...MenuProps, autoFocus: true }}
                    style={{ textTransform: "capitalize" }}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    {allowedApps.map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item md={3} xs={12}>
                  <FormLabel className={classes.formDiv} required>
                    Persona
                  </FormLabel>
                  <Select
                    value={apps}
                    onChange={handleAppsData}
                    displayEmpty
                    MenuProps={{ ...MenuProps, autoFocus: true }}
                    style={{ textTransform: "capitalize" }}
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    {accessData.map((item, index) => {
                      return (
                        <MenuItem
                          value={item.name}
                          key={index}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                {/* <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel
                      variant="h5"
                      className={classes.formDiv}
                      required
                    >
                      Functionality
                    </FormLabel>
                    <div className={classes.dropdownContainer}>
                      <Select
                        disableUnderline
                        value={functionality}
                        onChange={handleFunctionality}
                        displayEmpty
                        classes={{
                          selectMenu: classes.selectedItem,
                        }}
                        className={clsx({
                          [classes.select]: true,
                        })}
                      >
                        {functionalityArray &&
                          functionalityArray.map((item) => {
                            return (
                              <MenuItem value={item} key={item}>
                                {item.replace(/-/g, " ")}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </Grid> */}
                <Grid item md={3} xs={12}>
                  <div className={classes.selectRoot}>
                    <FormLabel required className={classes.formDiv}>
                      Analytics Name
                    </FormLabel>
                    <Select
                      value={analyticName}
                      onChange={(e) => handleAnalysisName(e)}
                      MenuProps={{ ...MenuProps, autoFocus: true }}
                      style={{ textTransform: "capitalize" }}
                      displayEmpty
                      className={clsx({
                        [classes.select]: true,
                      })}
                      classes={{
                        selectMenu: classes.selectedItem,
                      }}
                    >
                      {analyticNameArray &&
                        analyticNameArray.map((item, index) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div className={classes.selectRoot}>
                    <FormLabel required className={classes.formDiv}>
                      Filters
                    </FormLabel>
                    <Select
                      value={filters}
                      displayEmpty
                      MenuProps={{ ...MenuProps, autoFocus: true }}
                      onOpen={handleOpen}
                      onClose={() => setListOpen(false)}
                      open={listOpen}
                      renderValue={(appType) => {
                        var priceType = [];
                        appType.map((item) => {
                          priceType.push(item.replace(/_/g, " "));
                        });
                        return priceType.join(", ");
                      }}
                      className={clsx({
                        [classes.select]: true,
                      })}
                      classes={{
                        selectMenu: classes.selectedItem,
                      }}
                      multiple
                    >
                      <MenuItem
                        value="all"
                        onClick={(event) => handleSelectValues(event, "all")}
                        style={{ textTransform: "capitalize" }}
                      >
                        <Checkbox color="primary" checked={isAllSelected} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {filtersArray &&
                        filtersArray.map((item) => {
                          return (
                            <MenuItem
                              onClick={(event) =>
                                handleSelectValues(event, item)
                              }
                              value={item}
                              key={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              <Checkbox
                                color="primary"
                                checked={selected.indexOf(item) > -1}
                              />
                              <ListItemText primary={item.replace(/_/g, " ")} />
                            </MenuItem>
                          );
                        })}
                      <div className={classes.dropdownAction}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.btn}
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={onAdd}
                        >
                          Apply
                        </Button>
                      </div>
                    </Select>
                  </div>
                </Grid>
                {filters.length > 0 &&
                  filters.map((item) => {
                    return (
                      <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                        <FormLabel
                          style={{
                            color: "grey",
                            fontSize: 14,
                            marginBottom: 5,
                            textTransform: "capitalize",
                          }}
                        >
                          {item.replace(/_/g, " ")}
                        </FormLabel>
                        {item === "start_date" && (
                          <Select
                            displayEmpty
                            value={startDate}
                            onChange={(startDate) =>
                              handleAttributeValues(
                                startDate.target.value,
                                item
                              )
                            }
                            className={clsx({
                              [classes.select]: true,
                            })}
                            classes={{
                              selectMenu: classes.selectedItem,
                            }}
                          >
                            {" "}
                            {startDateValues.length > 0 &&
                              startDateValues.map((item) => {
                                return (
                                  <MenuItem value={item} key={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        {item === "end_date" && (
                          <Select
                            displayEmpty
                            value={endDate}
                            onChange={(endDate) =>
                              handleAttributeValues(endDate.target.value, item)
                            }
                            className={clsx({
                              [classes.select]: true,
                            })}
                            classes={{
                              selectMenu: classes.selectedItem,
                            }}
                          >
                            {" "}
                            {endDateValues.length > 0 &&
                              endDateValues.map((item) => {
                                return (
                                  <MenuItem value={item} key={item}>
                                    {/* {item.replace(/_/g, ' ')} */}
                                    {item}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        {item === "posting_type" && (
                          <Select
                            displayEmpty
                            value={postingType}
                            onChange={(postingType) =>
                              handleAttributeValues(
                                postingType.target.value,
                                item
                              )
                            }
                            className={clsx({
                              [classes.select]: true,
                            })}
                            classes={{
                              selectMenu: classes.selectedItem,
                            }}
                          >
                            {" "}
                            {postingTypeValues.length > 0 &&
                              postingTypeValues.map((item) => {
                                return (
                                  <MenuItem
                                    value={item ? item.toLowerCase() : item}
                                    key={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        {item === "data_source_type" && (
                          <Select
                            displayEmpty
                            value={dataSourceType}
                            onChange={(dataSourceType) =>
                              handleAttributeValues(
                                dataSourceType.target.value,
                                item
                              )
                            }
                            className={clsx({
                              [classes.select]: true,
                            })}
                            classes={{
                              selectMenu: classes.selectedItem,
                            }}
                          >
                            {" "}
                            {dataSourceTypeValues.length > 0 &&
                              dataSourceTypeValues.map((item) => {
                                return (
                                  <MenuItem value={item} key={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        {item === "groupBy" && (
                          <Select
                            displayEmpty
                            value={groupBy}
                            onChange={(groupBy) =>
                              handleAttributeValues(groupBy.target.value, item)
                            }
                            className={clsx({
                              [classes.select]: true,
                            })}
                            classes={{
                              selectMenu: classes.selectedItem,
                            }}
                          >
                            {" "}
                            {groupByValues.length > 0 &&
                              groupByValues.map((item) => {
                                return (
                                  <MenuItem value={item} key={item}>
                                    {item}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        {item != "start_date" &&
                          item != "end_date" &&
                          item != "posting_type" &&
                          item != "data_source_type" &&
                          item != "groupBy" && (
                            <ChipInput
                              classes={{
                                root: classes.rootContainer,
                                chip: classes.chip,
                                input: classes.input,
                                inputRoot: classes.inputRoot,
                                chipContainer: classes.chipContainer,
                                label: classes.chipLabel,
                              }}
                              onChange={(value) =>
                                handleAttributeValues(value, item)
                              }
                              disableUnderline={true}
                              className={clsx({
                                [classes.textInput]: true,
                              })}
                              style={{
                                display:
                                  item != "customer_number" ? "block" : "none",
                              }}
                            />
                          )}
                        <Autocomplete
                          options={customerNumberList}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              style={{
                                height: 20,
                                borderRadius: "0px !important",
                              }}
                            />
                          )}
                          filterOptions={filterOptions}
                          value={attributeObjectAPI["customer_number"]}
                          style={{
                            marginBottom: 10,
                            display:
                              item != "customer_number" ? "none" : "block",
                          }}
                          onChange={(event, newValue) =>
                            handleAttributeValues(newValue, item)
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              {customerNameListArray[
                                customerNumberList.indexOf(option)
                              ]
                                ? customerNameListArray[
                                customerNumberList.indexOf(option)
                                ]
                                : ""}{" "}
                              - ({option}){" "}
                            </li>
                          )}
                          classes={{
                            option: {
                              borderBottom: `1px solid red`,
                              // Hover
                              '&[data-focus="true"]': {
                                backgroundColor: "red",
                                borderColor: "transparent",
                              },
                              // Selected
                              '&[aria-selected="true"]': {
                                backgroundColor: "red",
                                borderColor: "transparent",
                              },
                            },
                            listbox: {
                              padding: 0,
                            },
                            inputRoot: {
                              borderRadius: "0px !important",
                            },
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </form>
        </Card>
        <div className={classes.buttonRoot}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOnSubmit}
            disabled={
              applicationType && analyticName && filters
                ? false
                : true
            }
          >
            {props.loadingAPI ? (
              <BeatLoader
                color={"rgb(54, 215, 183)"}
                loading={props.loadingAPI}
                size={6}
              />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.simulationData.loading,
    loadingAPI: state.customerData.loading,
    attributeMapData: state.AnalyticsDesignerData.AnalysisDataSingle,
    allowedAppsData: state.initialData.allowedApps,
    analysisDropdown: state.AnalyticsDesignerData.AnalysisDropdownData,
    analyticsfiltersDropdown: state.AnalyticsDesignerData.AnalyticsFilters,
    accessData: state.addMultipleConfigurationData.accessDataNew,
    appRightsData: state.addMultipleConfigurationData.appRights,
    orgAppTitleData: state.addMultipleConfigurationData.orgAppTitleData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data, id) => dispatch(addAnalysisDesigner(data, "edit", id)),
    getSingleAttributeMapping: (id) => dispatch(getSingleAnalysisDesigner(id)),
    allowedApps: () => dispatch(allowedApps()),
    // getAnalysisDesignerList:() => dispatch(getAnalysisDesignerList()),
    getAnalyticsFilters: (applicationType, functionality, personnelType) =>
      dispatch(
        getAnalyticsFilters(applicationType, functionality, personnelType)
      ),
    getAccessData: () => dispatch(getAccessDataNew()),
    postAccessData: (formData, id) => dispatch(postAccessData(formData, id)),
    addUser: (name) => dispatch(addUser(name)),
    deleteUser: (id) => dispatch(addUser(id, "delete")),
    appRights: (app) => dispatch(appRights(app)),
    getOrganizationAppTitle: () => dispatch(getOrganizationAppTitle()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAnalyticsDesigner);
