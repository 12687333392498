import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { connect } from 'react-redux';
import BulletChart from '../../Charts/BulletChart';
import Moment from 'moment';
import { geographicalIncentive, getNewCustomers, loadAccrualVsPaymentGraph } from '../../../../redux/actions';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
}));
const NewCustomer = props => {
  const classes = useStyles();
  const [customerGraphData, setCustomerGraphData] = React.useState([]);
  const [maxData, setMaxData] = React.useState({});
  const [type, setType] = React.useState("Customer Master");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const masterDataArray = ["Customer Master", "Employee Master", "Material Master", 'Supplier Master'];


  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "New Master Data Incentives" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData]);

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setType(allowedTilesFilter[0].master_data_type);
    }
  }, [allowedTilesFilter]);


  useEffect(() => {
    if (startDate && endDate) {
      var data = {
        'start_date': Moment(startDate).format('MM/DD/YYYY'),
        'end_date': Moment(endDate).format('MM/DD/YYYY'),
        'master_data_type': type
      }
      props.getNewCust(data);
    }

  }, [allowedTilesFilter]);



  useEffect(() => {
    if (props.newCustomer && Object.values(props.newCustomer).length > 0) {
      setCustomerGraphData(props.newCustomer)
      var max = 100
      var maxValue = {
        'countMax': props.newCustomer.Count ? parseInt(props.newCustomer.Count) + 100 : 100,
        'salesMax': props.newCustomer.Sales ? (parseInt(props.newCustomer.Sales) + 100) : 100,
        'accrualMax': props.newCustomer.Accrual ? (parseInt(props.newCustomer.Accrual) + 100) : 100,
        'paymentMax': props.newCustomer.Payment ? (parseInt(props.newCustomer.Payment) + 100) : 100,
      }
      setMaxData(maxValue)
    }
  }, [props.newCustomer]);


  const handleType = ((e) => {
    setType(e.target.value);
    if (startDate && endDate) {
      var data = {
        'start_date': Moment(startDate).format('MM/DD/YYYY'),
        'end_date': Moment(endDate).format('MM/DD/YYYY'),
        'master_data_type': e.target.value,
      }
      props.getNewCust(data);
    }
  });


  return (
    <div>
      <div style={{ display: "flex", marginTop: 10, marginBottom: 10 }}>
        <FormControl sx={{ m: 1, maxWidth: 150 }} size="small">
          <InputLabel id="posting-type" color="primary">
            Type
          </InputLabel>
          <Select
            labelId="posting-type"
            id="posting-type-select"
            value={type}
            label="Posting Type"
            onChange={handleType}
            color="primary"
          // width="100%"
          >
            {masterDataArray && masterDataArray.map((item, index) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {customerGraphData && Object.values(customerGraphData).length > 0 ?
        <BulletChart bulletChartData1={customerGraphData.Count}
          bulletChartData2={customerGraphData.Sales}
          bulletChartData3={customerGraphData.Accrual}
          bulletChartData4={customerGraphData.Payment}
          maxData={maxData}
        />
        :
        <div className={classes.emptyContainer}> No data</div>
      }
    </div>
  );
};
const mapStateToProps = state => {
  return {
    newCustomer: state.initialData.newCustomerData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getNewCust: (data) => dispatch(getNewCustomers(data)),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType) => dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);