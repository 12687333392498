import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Typography, useMediaQuery,
    TableRow,
    Breadcrumbs, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getApplicationLogs } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    fabContainer: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
    }
}));
const ApplicationLogs = props => {
    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [logsValue, setLogsValue] = React.useState([]);
    useEffect(() => {
        props.getApplicationLogs();
    }, []);

    useEffect(() => {
        if (props.logsData) {
            setLogsValue(props.logsData)
        }
        else {
            setLogsValue([])
        }
    }, [props.logsData])
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="primary" variant='h1'>Application Logs</Typography>
                </Breadcrumbs>
            </div>
            <div className={classes.fabContainer}>
                <Typography color="textprimary" variant='h3'>{logsValue}</Typography>
            </div>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.dataSetupData.loading,
        logsData: state.dataSetupData.ApplicationLogs,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getApplicationLogs: () => dispatch(getApplicationLogs()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogs);