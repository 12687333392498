import React, { useEffect, Fragment } from 'react';
import {
    Typography, Breadcrumbs, Link,
    Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";
import { getContractChangeLog } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto'
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    container: {
        flex: 1
    }
}));


const ChangeRequestDetail = props => {
    const classes = useStyles();
    const location = useLocation();
    const [oldDataAPI, setOldDataAPI] = React.useState({});
    const [newDataAPI, setNewDataAPI] = React.useState({});
    const [changedObject, setChangedObject] = React.useState({});
    let history = useHistory();
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);
    useEffect(() => {
        var pathname = window.location.pathname;
        var appId = pathname.substring(pathname.lastIndexOf('/') + 1);
        if (appId)
            props.getcontractViewdetails(appId);
    }, [])
    const [contractChangeLog, setContractChangeLog] = React.useState({});
    useEffect(() => {
        if (props.contractChangeLog) {
            setContractChangeLog(props.contractChangeLog);
        }
    }, [props.contractChangeLog])

    useEffect(() => {
        if (props.oldData && Object.keys(props.oldData).length > -1) {
            setOldDataAPI({ ...props.oldData })
        }
    }, [props.oldData])
    useEffect(() => {
        if (props.changedData && Object.keys(props.changedData).length != 0) {
            setNewDataAPI({ ...props.changedData })
        }
    }, [props.changedData])

    useEffect(() => {
        delete oldDataAPI['calculation_rules'];
        delete oldDataAPI['eligibility_rules'];
        delete oldDataAPI['target_rules'];
        delete oldDataAPI['chargeback_calculation_rules'];
        delete oldDataAPI['created_by'];
        delete oldDataAPI['updated_by'];
        delete oldDataAPI['notes'];
        delete oldDataAPI['owned_by'];
        delete oldDataAPI['search_tokens'];
        delete oldDataAPI['Prototype'];
        delete oldDataAPI['created_at'];
        delete oldDataAPI['updated_at'];
        delete oldDataAPI['id'];
        delete oldDataAPI['organization'];

        delete newDataAPI['calculation_rules'];
        delete newDataAPI['eligibility_rules'];
        delete newDataAPI['target_rules'];
        delete newDataAPI['chargeback_calculation_rules'];
        delete newDataAPI['created_by'];
        delete newDataAPI['updated_by'];
        delete newDataAPI['notes'];
        delete newDataAPI['owned_by'];
        delete newDataAPI['search_tokens'];
        delete newDataAPI['Prototype'];
        delete newDataAPI['created_at'];
        delete newDataAPI['updated_at'];
        delete newDataAPI['id'];
        delete newDataAPI['organization'];

        Object.keys(oldDataAPI).forEach(key => {
            if (oldDataAPI[key] !== newDataAPI[key]) {
                setChangedObject(state => ({
                    ...state,
                    [key]: newDataAPI[key]
                }))
            }
        });
    }, [oldDataAPI || newDataAPI])

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.goBack()}
                >


                    {location.page === 'contract-approval' ?
                        (sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Approval' : 'Contract Approval')
                        :
                        sessionStorage.getItem('application') === 'Sales Commission' ? 'Incentive Plan Setup'
                            :
                            'Contract Setup'}
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Compare Value</Typography>
            </Breadcrumbs>

            <div className={classes.container}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Field Name</TableCell>
                                <TableCell align='center'>Current Contract Value</TableCell>
                                <TableCell align='center'>Pending Change Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contractChangeLog && Object.entries(contractChangeLog).length > 0 && Object.entries(contractChangeLog)
                                .filter(([key, value]) => key != 'eligibility_rules')
                                .filter(([key, value]) => key != 'calculation_rules')
                                .filter(([key, value]) => key != 'notes')
                                .filter(([key, value]) => key != 'target_rules' && key != 'contract_dependency_rules')
                                .filter(([key, value]) => key != 'chargeback_calculation_rules')
                                .map(([key, value]) => {
                                    return (
                                        <StyledTableRow>
                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>  {key.replace(/_/g, ' ')}</TableCell>
                                            <TableCell align='center'>{key === 'Created At' ? Moment(contractChangeLog[key]['previous_time']).local().format('MM/DD/YYYY HH:mm') : contractChangeLog[key]['previous']}</TableCell>
                                            <TableCell align='center'>{key === 'Created At' ? Moment(contractChangeLog[key]['current_time']).local().format('MM/DD/YYYY HH:mm') : contractChangeLog[key]['current']}</TableCell>
                                        </StyledTableRow>
                                    );
                                })}

                            {contractChangeLog && Object.entries(contractChangeLog).length > 0 && Object.entries(contractChangeLog)
                                .filter(([key, value]) => key === 'eligibility_rules' || key === 'calculation_rules' || key === 'notes'
                                    || key === 'target_rules' || key === 'chargeback_calculation_rules' || key === 'contract_dependency_rules')

                                .map(([key, value]) => {
                                    return (
                                        <Fragment>
                                            <TableRow>
                                                <TableCell align='center' style={{ textTransform: 'capitalize' }} rowSpan={(Object.keys(value).length) + 1}>
                                                    {key.replace(/_/g, ' ')}
                                                </TableCell >
                                            </TableRow>
                                            {Object.entries(value).map(([k, v]) => (
                                                <StyledTableRow>
                                                    <TableCell align='center' >{v['previous']}</TableCell>
                                                    <TableCell align='center'>{v['current']}</TableCell>
                                                </StyledTableRow>


                                            ))
                                            }
                                        </Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        oldData: state.addContractData.contractGeneralData,
        changedData: state.addContractData.changedContractData,
        contractChangeLog: state.addContractData.contractChangeLog
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getcontractViewdetails: (id) => dispatch(getContractChangeLog(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangeRequestDetail);