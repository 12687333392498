import React from 'react';
import {
    Card,
    CardHeader,
    Grid,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { LabelText } from '../../../../../components/Inputs';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    }
}));

const Accrual = props => {

    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="ADDITIONAL DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <div className={classes.container}>
                        {props.data && props.fieldData &&
                            <div className={classes.container}>
                                <Grid container className={classes.gridContainer}>

                                    {props.data && props.data.auto_run_ppa && props.fieldData && props.fieldData.auto_run_ppa &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.auto_run_ppa.current} data={props.data.auto_run_ppa} twoline='true' />
                                        </Grid>
                                    }


                                    {props.data.postings_start_date && props.data.postings_start_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_start_date &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.postings_start_date.current} data={Moment.utc(props.data.postings_start_date).format('MM/DD/YYYY')} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.postings_end_date && props.data.postings_end_date != '0001-01-01T00:00:00Z' && props.fieldData.postings_end_date &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.postings_end_date.current} data={Moment.utc(props.data.postings_end_date).format('MM/DD/YYYY')} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.postings_block && props.fieldData.postings_block &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.postings_block.current} data={props.data.postings_block} twoline='true' />
                                        </Grid>
                                    }
                                    {props.data.posting_approval && props.fieldData.posting_approval &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.posting_approval.current} data={props.data.posting_approval} twoline='true' />
                                        </Grid>
                                    }
                                    {props.fieldData.suppress_negative_rebate &&
                                        <Grid
                                            item
                                            md={2.4}
                                            xs={6}
                                        >
                                            <LabelText heading={props.fieldData.suppress_negative_rebate.current}
                                                data={props.data.suppress_negative_rebate && props.data.suppress_negative_rebate == true ? 'Yes' : 'No'} twoline='true' />
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        }
                    </div>
                </form>
            </Card>
        </div>
    );

}

export default Accrual;