import React, { useRef, useState, useEffect } from 'react';
import {
    Grid, Button, Typography,
    Radio, RadioGroup, FormControlLabel, FormLabel,
    Select, MenuItem, TextField,
    Checkbox, Dialog,
    DialogActions,
    OutlinedInput,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Dropdown, DropdownArray } from '../../../components/Inputs';
import { SearchDropdown } from '../../../components/Inputs';
import SimpleDialog from '../../../components/Dialog';
import { toast } from 'react-toastify';
import { runFinancialPostingsSimulation, getDefaultValues, getContractsTableDataList, checkingPostingDate, addBatchJobSetup } from '../../../redux/actions';
// import ChipInput from 'material-ui-chip-input';
import { ChipInput } from '../../../components/Inputs';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import momentt from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { RotateLoader, RingLoader } from "react-spinners";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ContractNumberAC } from '../../../components/Inputs';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '10px 0px 0px 0px',
        marginTop: 5,
    },
    container: {
        padding: '0px 10px 0px 10px',
        marginBottom: 20
    },
    gridContainer: {
        padding: '0px 10px 0px 10px',
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17,
        fontSize: theme.typography.h3.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: -12,
        marginTop: 0,
        // height: 42,
        // height: 36,
        padding: 0,
        marginBottom: 14,
        height: 35,
        width: '100%'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    menuPaper: {
        maxHeight: 300
    },
    errorMsg: {
        color: '#f44336',
        fontSize: 12,
        paddingTop: 5
    },
    spinner: {
        height: '100vh'
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        padding: "1rem",
    },
}));

const FinancialPostingsForm = props => {
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const contractCustomisationUpdation = useRef();
    const [contractTypeArray, setContractTypeArray] = React.useState([]);
    const [accrualFrequency, setAccrualFrequency] = React.useState('Monthly');
    const [accrualFrequencyArray, setAccrualFrequencyArray] = React.useState([]);
    const [calculationMethodArray, setCalculationMethodArray] = React.useState([]);
    const [calculationMethod, setCalculationMethod] = React.useState('');
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [minimumDate, setMinDate] = React.useState(null);
    const [maximumDate, setMaxDate] = React.useState(null);
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [contractNumber, setContractNumber] = useState([]);
    const [contractType, setContractType] = React.useState('');
    const [postingDate, setPostingDate] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);
    const [startValid, setStartValid] = useState(true);
    const [endValid, setEndValid] = useState(true);
    const [postValid, setPostValid] = useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [endErrorMessage, setEndErrorMessage] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [open2, setOpen2] = useState(false);
    const [masterValue, setMasterValue] = React.useState([]);


    useEffect(() => {
        props.onLoadingDefault();
        props.onCheckContractsTable();
        props.onCheckpostingDate();
    }, []);
    useEffect(() => {

        if (props.checkPostingDate) {
            var date = new Date();
            if (props.checkPostingDate == 'Allowed') {
                setMinDate(Moment(date).subtract(1, 'months').startOf('month').format('MM/DD/YYYY'));
                setMaxDate(Moment(date).endOf('month'))
                setPostingDate(Moment(date).subtract(1, 'months').startOf('month'));
            }
            else {
                setMinDate(Moment(date).startOf('month').format('MM/DD/YYYY'));
                setPostingDate(Moment(date).startOf('month'));
                setMaxDate(Moment(date).format('MM/DD/YYYY'))
            }

        }
    }, [props.checkPostingDate]);
    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList(contracts);
            setInternalDescriptionArray(tempNameArray);
        }


    }, [props.contractTabledataComplete])
    useEffect(() => {
        setAccrualFrequency('Monthly')
        setCalculationPeriodStart(Moment.utc().subtract(1, 'months').startOf('month'));
        setCalculationPeriodEnd(Moment.utc().subtract(1, 'months').endOf('month'));

    }, []);
    useEffect(() => {
        if (!contractCustomisationUpdation.current) {
            {
                if (props.dropdownData && props.dropdownData.records && props.dropdownData.records.length > 0) {
                    props.dropdownData.records.map((item) => {
                        if (item.field_id === 'contract_type') {
                            setContractTypeArray(item);
                        }
                        if (item.field_id === 'calculation_method') {
                            setCalculationMethodArray(item);
                        }
                        if (item.field_id === 'accrual_frequency') {
                            setAccrualFrequencyArray(item);
                        }
                    })
                }
            }
        }
    });
    function handleTags(newValue) {
        setTags(newValue)
    }

    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const afRef = useRef(null);
    const postingDateRef = useRef(null);
    const handleClear = () => {
        setContractNumber([]);
        typeRef.current.reset();
        subTypeRef.current.reset();
        postingDateRef.current.reset();
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setAccrualFrequency('');
        setAccrualFrequency('');
        setRadioGroupValue('');
        afRef.current.reset();
    }
    const handleClickOpen = () => {
        if (contractNumber.length > 0) {
            if (calculationPeriodEnd && calculationPeriodStart) {
                setOpen(true)
            } else {
                if (!calculationPeriodEnd)
                    setCalculationPeriodEnd(false);
                if (!calculationPeriodStart)
                    setCalculationPeriodStart(false);
            }
        }
        else if (calculationPeriodEnd && calculationPeriodStart && calculationMethod && contractType && postingDate)
            setOpen(true);
        else {
            toast.error('Please fill all the required fields.');
            if (!calculationPeriodEnd)
                setCalculationPeriodEnd(false);
            if (!calculationPeriodStart)
                setCalculationPeriodStart(false);
            if (!calculationMethod)
                setCalculationMethod(false);
            if (!contractType)
                setContractType(false);
            if (!postingDate)
                setPostingDate(false);
        }
    };
    const handleClickOpen2 = () => {
        if (contractNumber.length > 0) {
            if (calculationPeriodEnd && calculationPeriodStart) {
                setOpen2(true)
            } else {
                if (!calculationPeriodEnd)
                    setCalculationPeriodEnd(false);
                if (!calculationPeriodStart)
                    setCalculationPeriodStart(false);
            }
        }
        else if (calculationPeriodEnd && calculationPeriodStart && calculationMethod && contractType && postingDate)
            setOpen2(true);
        else {
            toast.error('Please fill all the required fields.');
            if (!calculationPeriodEnd)
                setCalculationPeriodEnd(false);
            if (!calculationPeriodStart)
                setCalculationPeriodStart(false);
            if (!calculationMethod)
                setCalculationMethod(false);
            if (!contractType)
                setContractType(false);
            if (!postingDate)
                setPostingDate(false);
        }
    };
    var formData = {
        "application_type": sessionStorage.getItem('application'),
        "contract_number": contractNumber,
        "contract_type": contractType,
        "calculation_method": calculationMethod,
        "calculation_period_start": calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
        "calculation_period_end": calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
        "posting_date": postingDate ? Moment.utc(postingDate) : '',
        "accrual_type": radioGroupValue,
        "tags": tags
    };
    const format2 = {
        "app_type": localStorage.getItem("application"),
        "batch_job_type": "Accrual Postings",
        "batch_job_name": batchJobName,
        "planned_date_type": "static",
        "planned_start_date": Moment.utc(new Date()).add(1, 'minute').local().format('YYYY-MM-DDTHH:mm:ssZ').toString(),
        "planned_start_calendar": "",
        "planned_start_time": Moment.utc(new Date()).add(1, 'minute').local().format('HH:mm:00').toString(),
        "frequency": "One Time",
        "batch_filter_criteria": [
            {
                "filters": {
                    ...formData, "calculation_date_type": 'static',
                    'calculation_start_date': calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
                    'calculation_end_date': calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
                    'posting_type': radioGroupValue
                }
            }]
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            localStorage.setItem('page', 'Accrual');
            formData['contract_number'] = contractNumber.map(Number)
            if (masterValue && masterValue.length > 0) {
                formData['eligibility_rules'] = [
                    {
                        "option": "EQUAL",
                        "effective_start_date": calculationPeriodStart ? Moment.utc(calculationPeriodStart) : '',
                        "effective_end_date": calculationPeriodEnd ? Moment.utc(calculationPeriodEnd) : '',
                        "enabled": true,
                        "type": "Both",
                        "sequence": 0,
                        "attribute_key_values": [{
                            "attribute_name": sessionStorage.getItem("application") === 'Customer Rebate' ? "customer_number" : 'supplier_number',
                            "attribute_value": masterValue,
                            "attribute_type": "Attribute"
                        }]
                    }
                ];
            }
            props.onSubmit(formData);
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(newValue);
            if (accrualFrequency === 'Monthly') {
                if (Moment(newValue).startOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setErrorMessage('')
                    setCalculationPeriodEnd(Moment(newValue).endOf('month'))
                    setStartValid(true)

                }
                else {
                    setErrorMessage("Date should be starting of a Month. For Eg:" + Moment(newValue).startOf('month').format('MM/DD/YYYY'))
                    setStartValid(false)
                }


            }
        }
        else {
            setCalculationPeriodStart(newValue);
            if (!newValue)
                setCalculationPeriodEnd(null)
            setErrorMessage('')
            setStartValid(false)
        }


    }
    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(newValue);
            if (accrualFrequency === 'Monthly') {
                if (Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setEndErrorMessage('')
                    setEndValid(true)
                }
                else {
                    setEndErrorMessage("Date should be ending of a current start Month. For Eg: " + Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY'))
                    setEndValid(false)
                }
            }
        }
        else {
            setCalculationPeriodEnd(newValue)
            setEndErrorMessage('')
            setEndValid(false)
        }
    }
    function handleContractNumber(e, value) {

        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected)
            setContractNumber(newSelected);
        }
        else {
            setSelected([])
            setContractNumber([]);
        }

    }
    function handleContractType(newValue) {
        setContractType(newValue);
    }
    function handleCalculationMethod(newValue) {
        setCalculationMethod(newValue);
    }
    function handlePostingDate(newValue) {

        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue));
            if ((Moment(newValue).format('MM/DD/YYYY')) < (Moment(minimumDate).format('MM/DD/YYYY')) ||
                (Moment(newValue).format('MM/DD/YYYY') > Moment(maximumDate).format('MM/DD/YYYY')))
                setPostValid(false)
            else
                setPostValid(true)
        }
        else {
            setPostValid(false)
            setPostingDate(newValue);
        }
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    function handleAccrualFrequency(newValue) {
        setAccrualFrequency(newValue);
        if (newValue === 'Daily') {
            setCalculationPeriodStart(Moment());
            setCalculationPeriodEnd(Moment());
        } else if (newValue === 'Weekly') {
            setCalculationPeriodStart(Moment().startOf('week').format('MM/DD/YYYY'));
            setCalculationPeriodEnd(Moment().endOf('week').format('MM/DD/YYYY'));
        } else if (newValue === 'Monthly') {
            setCalculationPeriodStart(Moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'));
            setCalculationPeriodEnd(Moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'));
        }
        handlePostingDate(minimumDate)
    }
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    const handleBatchJobName = (e) => {
        setBatchJobName(e.target.value);
    }
    function handleBatchOnSubmit() {
        setOpen2(false)
        setBatchJobName('');
        delete format2.batch_filter_criteria[0].filters['calculation_period_start'];
        delete format2.batch_filter_criteria[0].filters['calculation_period_end'];
        delete format2.batch_filter_criteria[0].filters['accrual_type'];
        props.onSubmitBatchJob(format2)
    }
    function handleCancelDialog() {
        setOpen2(false)
        setBatchJobName('');
    }
    function handleContractNumberAC(value) {
        let newSelected = value;
        if (value.length > 0) {
            setSelected(newSelected);
            setContractNumber(newSelected);
        } else {
            setSelected([]);
            setContractNumber([]);
        }
    }
    function handleMasterValue(value) {
        setMasterValue(value)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<RingLoader size={100} />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary' style={{ marginLeft: 16 }}>
                        {sessionStorage.getItem('application') && sessionStorage.getItem('application') == 'Supplier Chargeback' ? 'Claims Provision' : 'Accrual Postings'}
                    </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.bodyContainer}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Dropdown classes={{ root: classes.fontSetting }} heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            //style={{ marginTop: -6 }}
                            >
                                {//calculationMethodArray && calculationMethodArray.name &&
                                    <DropdownArray classes={{ root: classes.fontSetting }}
                                        style={{ paddingTop: 3 }}
                                        required={contractNumber && contractNumber.length > 0 ? false : true} heading={calculationMethodArray && calculationMethodArray.name ? calculationMethodArray.name : 'Calculation Method'}
                                        data={calculationMethodArray && calculationMethodArray.drop_down_values ? calculationMethodArray.drop_down_values : []}
                                        //placeholder={calculationMethodArray.drop_down_values && calculationMethodArray.drop_down_values.length > 0 ? 'Select calculation method' : '- Please configure calculation method -'} 
                                        twoline='true' onChange={handleCalculationMethod} error={typeof calculationMethod === 'boolean' && !calculationMethod ? true : false} ref={subTypeRef} />
                                }

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div style={{ marginTop: -10 }}>
                                    <DropdownArray classes={{ root: classes.fontSetting }} required={contractNumber && contractNumber.length > 0 ? false : true} heading={contractTypeArray && contractTypeArray.name ? contractTypeArray.name : 'Contract Type'}
                                        data={contractTypeArray && contractTypeArray.drop_down_values ? contractTypeArray.drop_down_values : []}
                                        placeholder={contractTypeArray && contractTypeArray.drop_down_values && contractTypeArray.drop_down_values.length == 0 && '- Please configure contract type -'}
                                        twoline='true' onChange={handleContractType} /*error={typeof calculationPeriodStart === 'boolean' && !calculationPeriodStart ? true : false} ref={startRef}*/ error={typeof contractType === 'boolean' && !contractType ? true : false} ref={typeRef} />
                                </div>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                                style={{ marginTop: -5 }}
                            >
                                <FormLabel className={classes.chargeBackLabel} >
                                    Accrual Frequency
                                </FormLabel>
                                <Select
                                    onChange={handleAccrualFrequency}
                                    value={accrualFrequency}
                                    displayEmpty
                                    className={classes.select}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    MenuProps={{ classes: { paper: classes.menuPaper }, disableScrollLock: true }}
                                >

                                    <MenuItem value="" style={{ height: 30 }}>
                                    </MenuItem>

                                    {accrualFrequencyArray && accrualFrequencyArray.drop_down_values && accrualFrequencyArray.drop_down_values.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index} name={item} disabled={item != 'Monthly'}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                            // style={{ marginTop: '-0.6rem' }}
                            >
                                <div style={{ paddingTop: 15, marginTop: -9 }}>
                                    <SearchDropdown id='contract_number_multiple'
                                        multiple
                                        required={calculationMethod ? false : true}
                                        table='contract_header' keyName='contract_number'
                                        description={true} heading='Contract Number'
                                        onChange={handleContractNumberAC}
                                        applicationType={sessionStorage.getItem("application")}
                                    />
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                            // style={{ marginTop: -14 }}
                            >

                                <FormLabel required className={classes.chargeBackLabel} >
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%',
                                                    height: 38
                                                }
                                            }}
                                            value={postingDate}
                                            onChange={handlePostingDate}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            minDate={minimumDate ? minimumDate : ''}
                                            maxDate={maximumDate ? maximumDate : ''}
                                            format="MM/DD/YYYY"
                                            minDateMessage={'Date should not be before ' + Moment(minimumDate).format('MM/DD/YYYY')}
                                            maxDateMessage={'Date should not be after ' + Moment(maximumDate).format('MM/DD/YYYY')}

                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Calculation Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodStart}
                                            onChange={handleCalculationPeriodStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormLabel style={{ display: errorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                    {errorMessage}
                                </FormLabel>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Calculation End Date
                                </FormLabel>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    // padding: '0px 11px 0px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodEnd}
                                            onChange={handleCalculationPeriodEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormLabel style={{ display: endErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                    {endErrorMessage}
                                </FormLabel>

                            </Grid>
                            {sessionStorage.getItem('application') == 'Customer Rebate' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.container }}
                                >
                                    <div style={{ paddingTop: 15 }}>
                                        <SearchDropdown id='customer_multiple'
                                            table='customer_master' keyName='customer_number'
                                            description={true} heading='Customer Number'
                                            multiple
                                            onChange={handleMasterValue}
                                        />
                                    </div>
                                </Grid>
                            }
                            {sessionStorage.getItem('application') == 'Supplier Rebate' &&
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    classes={{ root: classes.container }}
                                >
                                    <div style={{ paddingTop: 15 }}>
                                        <SearchDropdown id='customer_multiple'
                                            table='supplier_master' keyName='supplier_number'
                                            description={true} heading='Supplier Number'
                                            multiple
                                            onChange={handleMasterValue}
                                        />
                                    </div>
                                </Grid>
                            }
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.container }}
                                style={{ marginTop: 10 }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                <ChipInput data={tags} onChange={handleTags} />
                            </Grid>

                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Accrual"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Accrual </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Accrual Reversal"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{ root: classes.fontSetting }} > Accrual Reversal </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    contractNumber && contractNumber.length > 0 ?
                                        (!postValid || !startValid || !endValid || !radioGroupValue ? true : false)
                                        :
                                        (!calculationMethod || !contractType || !postValid || !startValid || !endValid || !radioGroupValue) ? true : false}

                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'>
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run Online'
                                }
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen2} twoline='true'
                                style={{ width: 170 }}
                                disabled={
                                    contractNumber && contractNumber.length > 0 ?
                                        (!postValid || !startValid || !endValid || !radioGroupValue ? true : false)
                                        :
                                        (!calculationMethod || !contractType || !postValid || !startValid || !endValid || !radioGroupValue) ? true : false}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Schedule Batch Job'
                                }
                            </Button>
                        </div>
                    </div>


                </form>
                <SimpleDialog open={open} content='Do you want to submit financial postings?' handleDialog={handleDialog} />
            </div >
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Batch Job Name
                    </FormLabel>
                    <OutlinedInput
                        value={batchJobName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleBatchJobName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay >
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runFinancialPostingsSimulation(formData)),
        onSubmitBatchJob: (data) => dispatch(addBatchJobSetup(data)),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        onCheckContractsTable: () => dispatch(getContractsTableDataList()),
        onCheckpostingDate: () => dispatch(checkingPostingDate())

    }
}


const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        contractTabledataComplete: state.addContractData.contractAllListData,
        checkPostingDate: state.financialCloseData.checkpostingDate
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialPostingsForm);