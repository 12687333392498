import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    Typography,
    Breadcrumbs,
    Stepper,
    Step,
    StepButton,
    StepConnector,
    StepLabel,
    Button,
    Grid,
    useMediaQuery,
    Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import RingLoader from "react-spinners/RingLoader";
import { GeneralData, FinancialPostings, TargetRules, Reporting, /*EligibilityRules,*/ CalculationRules, ApprovalStatus, InternalNotes, ContractDependency, MultiplePayment, CalculationModifier } from './components';
import { useHistory } from "react-router-dom";
import { EligibilityRules } from '../ContractSetup/Containers';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
    addContractGeneralData, getContractDetailsData, getDefaultValues,
    getEligibilityRulesTableData, getCalculationRulesTableData,
    resetShowContractOverlapPopup, getContractDefaultDesigner, uploadEligibilityRulesClear,
    getDefaultValuesAllDataNew, getInitialDataFromSourceDataType, getCustomerMasterData,
    getGeneralUOM,
    fetchDropdownConfig, getLblDispDesFieldValue, fetchDocumentStatus
} from '../../redux/actions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: -20,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    stepper: {
        width: '100%',
        overflow: 'auto',
        marginTop: -10
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconContainer: {
        '& .text': {
            fontSize: '12px'
        }
    },
    connectorLine: {
        borderColor: theme.palette.primary.main,
    },
    connectorAlternativeLabel: {
        left: 'calc(-50% + 13px)',
        right: 'calc(50% + 13px)'
    },
    stepperContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 20,
        padding: 10
    },
    pageControls: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 142,
        marginLeft: 16,
        marginTop: 10
    },
    previous: {
        marginTop: 10,
        width: 142
    },
    gridContainer: {
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    shiftContent: {
        marginLeft: 0
    },
    stepButton: {
        [theme.breakpoints.down('lg')]: {
            width: 100
        }
    }
}));
const tabs =
    sessionStorage.getItem('application') === 'Pricing' ?
        {
            generalData: 0,
            TargetRules: 1,
            contractDependency: 2,
            calculationModifier: 3,
            eligibilityRules: 4,
            calculationRules: 5,
            Reporting: 6,
            notesAndAttachments: 7,
            approvalStatus: 8

        }
        :
        {
            generalData: 0,
            financialPostings: 1,
            MultiplePayments: 2,
            TargetRules: 3,
            contractDependency: 4,
            calculationModifier: 5,
            eligibilityRules: 6,
            calculationRules: 7,
            Reporting: 8,
            notesAndAttachments: 9,
            approvalStatus: 10

        };

const AddContract = props => {
    const currentApplication = sessionStorage.getItem('application')
    useEffect(() => {
        var data = {
            "data_source": "contract_header",
            "application_type": sessionStorage.getItem('application')
        }
        props.fieldDesigner(data, 'post');
    }, []);
    useEffect(() => {
        var appType = sessionStorage.getItem('application');
        props.uploadEligibilityRulesClear();
    }, [])
    useEffect(() => {
        props.getdropdownConfig()
        props.getGeneralUOM();
        props.getContractDefaultDesigner();
        props.resetShowContractOverlapPopup();
        var count = tabs ? Object.entries(tabs).length - 1 : 0
        setStepCount(count)
    }, []);
    var mode = localStorage.getItem('mode');
    const [targetTabEnabled, setTargetTabEnabled] = React.useState(false);
    const [eligibilityEnabled, setEligibilityEnabled] = React.useState(true);
    const [calcRuleEnabled, setCalcRuleEnabled] = React.useState(true);
    const [contractDependencyEnabled, setContractDependencyEnabled] = React.useState(false);
    const [calcModifierEnabled, setCalcModifierEnabled] = React.useState(false);
    const [multiPaymentRuleEnabled, setMultiPaymentRuleEnabled] = React.useState(false);
    const [stepCount, setStepCount] = React.useState(0);
    function getSteps() {
        if (currentApplication === 'Pricing') {
            return ['Primary*', ...(targetTabEnabled ? ['Commitment Rules*'] : []), ...(contractDependencyEnabled ? ['Contract Dependency*'] : []),
                ...(calcModifierEnabled ? ['Calculaton Rule Modifiers*'] : []), ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        } else {
            return ['Primary*', 'Financial Postings*', ...(multiPaymentRuleEnabled ? ['Multiple Payments*'] : []), ...(targetTabEnabled ? ['Commitment Rules*'] : []),
                ...(contractDependencyEnabled ? ['Contract Dependency*'] : []), ...(calcModifierEnabled ? ['Calculaton Rule Modifiers*'] : []),
                ...(eligibilityEnabled ? ['Eligibility Rules*'] : [])
                , ...(calcRuleEnabled ? ['Calculation Rules*'] : []), 'Reporting', 'Notes and Attachments', 'Approval Status*'];
        }

    }
    const classes = useStyles();
    const childRef1 = useRef();
    const childRef2 = useRef();
    const childRef3 = useRef();
    const [saveTriggered, setSaveTriggered] = React.useState('');
    const [postingSchema, setPostingSchema] = React.useState('');
    const [workflow, setWorkflow] = React.useState('');
    const [calculationSchema, setCalculationSchema] = React.useState('');
    const [calculationMethod, setCalculationMethod] = React.useState(currentApplication === 'Pricing' ? 'Price Difference' : '');
    const [incentiveRateType, setIncentiveRateType] = React.useState('');
    const [tierPeriod, setTierPeriod] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [eligibilityData, setEligibilityData] = React.useState([]);
    const [calculationData, setCalculationData] = React.useState([]);
    const [targetRulesData, setTargetRulesData] = React.useState([]);
    const [calcModifierData, setCalcModifierData] = React.useState([]);
    const [multiPaymentData, setMultiPaymentData] = React.useState([]);
    const [internalNotesData, setInternalNotesData] = React.useState('');
    const [defaultContract, setDefaultContract] = React.useState({});
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const [defaultContractAll, setDefaultContractAll] = React.useState([]);
    var generalData = {};
    var financialData = {};
    var miscellaneousData = {};
    var approvalStatusData = {};
    const [generalDataRequired, setGeneralDataRequired] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [saveDisabled, setSaveDisabled] = React.useState(true);
    const [maximumAmountPeriod, setMaximumAmountPeriod] = React.useState('');
    const [contractDependencyData, setContractDependencyData] = React.useState([]);
    const skipped = new Set()
    const steps = getSteps();
    const history = useHistory();

    const [contractFormFields, setContractFormFields] = React.useState([])
    const [formFields, setFormFields] = React.useState([]);
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [paymentPartnerType, setPaymentPartnerType] = React.useState([]);



    useEffect(() => {
        if (props.dropdownData && props.dropdownData.field_label_attributes && Object.entries(props.dropdownData.field_label_attributes).length > 0) {
            const tempObject = Object.entries(props.dropdownData.field_label_attributes).
                filter(([k, v]) => v.display)
                .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
            setContractFormFields(tempObject)
        }
    }, [props.dropdownData && props.dropdownData.field_label_attributes])


    useEffect(() => {
        if ((contractDependencyEnabled || targetTabEnabled) && formFields.length == 0) {
            var fields = ['adjustment_period', 'adjustment_driver', 'adjusted_driver', 'target_type']
            props.onLoadingFormField(fields)
        }
    }, [contractDependencyEnabled, targetTabEnabled])
    useEffect(() => {
        if (props.formFields && props.formFields.records)
            setFormFields(props.formFields.records)
    }, [props.formFields])

    const totalSteps = () => {
        return getSteps().length;
    };
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const skippedSteps = () => {
        return skipped.size;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const handleStep = (step) => () => {
        if (step === tabs.eligibilityRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckEligibillityRulesTable(localStorage.getItem('currentAddContractID'));
        } else if (step === tabs.calculationRules && (localStorage.getItem('mode') === 'edit')) {
            props.onCheckCalculationRulesTable(localStorage.getItem('currentAddContractID'));
        }
        if (calculationMethod && postingSchema && sourceDataType && workflow)
            setActiveStep(step);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps() - skippedSteps();
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const completedSteps = () => {
        return completed.size;
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleComplete = (forced) => {
        var completeData;
        var eligibilityRulesObject = { "eligibility_rules": eligibilityData };
        var targetRulesObject = { "target_rules": targetRulesData };
        var contractDependencyRules = { "contract_dependency_rules": contractDependencyData };
        var calculationRulesObject = { "calculation_rules": calculationData };
        var calculationRuleModfier = { "contract_calculation_rule_modifiers": calcModifierData };
        var multiplePayment = { "contract_multiple_payment_rules": multiPaymentData };
        var internalNotesObject = internalNotesData.length > 0 ? { "notes": JSON.parse(internalNotesData) } : [];
        if (currentApplication === 'Pricing') {
            completeData = Object.assign(generalData, miscellaneousData, multiplePayment, targetRulesObject, contractDependencyRules, calculationRuleModfier, eligibilityRulesObject, calculationRulesObject, approvalStatusData, internalNotesObject);
        } else {
            completeData = Object.assign(generalData, financialData, multiplePayment, miscellaneousData, targetRulesObject, contractDependencyRules, calculationRuleModfier, eligibilityRulesObject, calculationRulesObject, approvalStatusData, internalNotesObject);
        }
        console.log(completeData)
        props.onSubmit(completeData, forced);
    };
    const handleDiscard = () => {
        props.uploadEligibilityRulesClear();
        history.push("/contract-setup");
    };
    const handleNext = () => {
        if (isLastStep())
            history.push("/contract-setup");
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !completed.has(i)) : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    useEffect(() => {
        // if (activeStep == steps.length - 1) {
        var approveForm = {
            "application_type": generalData.application_type,
            "functionality": "contract_header",
            "workflow_name": workflow//generalData.workflow_name
        }
        props.getApprovalWorkflow(approveForm)
        // }
    }, [workflow])
    function handleGeneralDataChange(values, requiredValues) {
        setCalculationMethod(values.calculation_method);
        setIncentiveRateType(values.incentive_rate_type);
        if (values.calculation_method === 'Flat Rate' && values.incentive_rate_type === 'Contract') {
            setCalculationSchema('Flat % - One Rate per Contract')
        }
        else if (values.calculation_method === 'Flat Rate' && values.incentive_rate_type === 'Attribute') {
            setCalculationSchema('Flat % - Multiple Rates per Contract')
        }
        else if (values.calculation_method === 'Fixed Amount' && values.incentive_rate_type === 'Contract') {
            setCalculationSchema('Flat $ - One lumpsum Amount per Contract')
        }
        else if (values.calculation_method === 'Fixed Amount' && values.incentive_rate_type === 'Attribute') {
            setCalculationSchema('Flat $ - One lumpsum Amount per Contract')
        }
        else if (values.calculation_method === 'Price Difference') {
            setCalculationSchema('Price Difference')
        }
        else if (values.calculation_method === 'Tiered Growth') {
            setCalculationSchema('Tiered Growth - Simple')
        } else if (values.calculation_method === 'Tiered Volume') {
            setCalculationSchema('Tiered Growth - Simple')
        }
        else if (values.calculation_method === 'Fixed Amount') {
            setCalculationSchema('Fixed Amount')
        } else {
            setCalculationSchema(values.calculation_schema)
        }
        if (values.maximum_amount_period) {
            setMaximumAmountPeriod(values.maximum_amount_period)

        }
        if (values.commitment_achievement != 'No') {
            setTargetTabEnabled(true);
        } else {
            setTargetTabEnabled(false);
        }
        if (values.contract_dependency == 'Yes') {
            setContractDependencyEnabled(true);

        } else {
            setContractDependencyEnabled(false);
        }
        if ((calculationMethod === 'Flat Rate' || calculationMethod === 'Fixed Amount') && incentiveRateType === 'Attribute') {
            setEligibilityEnabled(false)
        } else {
            setEligibilityEnabled(true)
        }
        if (values.calculation_rule_modifier == 'Yes') {
            setCalcModifierEnabled(true);

        } else {
            setCalcModifierEnabled(false);
        }
        if (values.calculation_method == 'External Calculation') {
            setCalcRuleEnabled(false);

        } else {
            setCalcRuleEnabled(true);
        }
        generalData = values;
        setPostingSchema(values.posting_schema);
        setTierPeriod(values.tier_period);
        setGeneralDataRequired(JSON.stringify(requiredValues));
        setSourceDataType(values.source_data_type);
        setStartDate(values.valid_from);
        setEndDate(values.valid_to);
        setWorkflow(values.workflow_name)
        if (values && values.contract_type && defaultContractAll) {
            var theObject = defaultContractAll.filter(obj => {
                return obj.contract_type === values.contract_type
            })
            setDefaultContract(theObject[0]);
        }

        checkValues();
    };
    useEffect(() => {
        var count = Object.entries(tabs).length - 1
        if (!targetTabEnabled)
            count = stepCount - 1
        if (!eligibilityEnabled)
            count = stepCount - 1
        if (!contractDependencyEnabled)
            count = stepCount - 1
        setStepCount(count)

    }, [targetTabEnabled, eligibilityEnabled, contractDependencyEnabled])
    useEffect(() => {
        if (props.contractDefaultData && props.contractDefaultData.records && props.contractDefaultData.records.length > 0)
            setDefaultContractAll(props.contractDefaultData.records)
    }, [props.contractDefaultData])
    function handleFinancialDataChange(values) {
        financialData = values;
        if (values.payment_level == 'Multiple Payment - Different Levels' && values.payment_partner_role != '') {
            setMultiPaymentRuleEnabled(true);

        } else {
            setMultiPaymentRuleEnabled(false);
        }
        setPaymentMethod(values.payment_method)
        setPaymentPartnerType(values.payment_aggregation_level)
        // setPaymentPartnerType(values.payment_partner_role)
        checkValues();
    };
    function handleMiscellaneousDataChange(values) {
        miscellaneousData = values;
    };
    function handleTargetRules(values) {
        setTargetRulesData(values)
        checkValues();
    };
    function handleontractDependency(values) {
        setContractDependencyData(values)
        checkValues();
    }
    function handleCalculationModifier(values) {
        setCalcModifierData(values)
        checkValues();
    }
    function handleMultiplePayment(values) {
        setMultiPaymentData(values)
        checkValues();
    }
    function handleApprovalStatus(values) {
        approvalStatusData = values;
        checkValues();
    };
    function handleInternalNotes(values) {
        setInternalNotesData(JSON.stringify(values))

    };
    function handleEligibilityRules(values) {

        setEligibilityData(values);
        checkValues();
    };
    function handleCalculationRules(values) {
        setCalculationData(values);
        checkValues();
    };
    function isStepComplete(step) {
        return completed.has(step);
    }
    function handleDialog(bool) {
        if (bool === 'No') {
            setContractPopup(false)
            setOpen(false);

        } else {
            handleComplete(true);
            setContractPopup(false)
            setOpen(false);
        }
    }
    function getStepContent(step) {
        if (!targetTabEnabled) {
            if (eligibilityEnabled) {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        savetriggered={saveTriggered} ref={childRef1} postingSchema={postingSchema}
                        eligibiltyRules={eligibilityData}
                        calculationRules={calculationData} fieldData={contractFormFields}
                    />,

                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange}
                        savetriggered={saveTriggered} ref={childRef2} postingSchema={postingSchema}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        fieldData={contractFormFields}
                    />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment}
                        payment_method={paymentMethod} partnerType={paymentPartnerType}
                        formFields={formFields}
                        fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [<ContractDependency onChange={handleontractDependency}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                        contractDefaultData={defaultContract ? defaultContract : ''} fieldData={contractFormFields}
                    />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                    />] : []),
                    <EligibilityRules
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        onChange={handleEligibilityRules} calculationSchema={calculationSchema} calculationMethod={calculationMethod}
                        sourceDataType={sourceDataType} startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} />,
                    ...(calcRuleEnabled ? [<CalculationRules
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        onChange={handleCalculationRules} calculationSchema={calculationSchema}
                        calculationMethod={calculationMethod} maximumAmountPeriod={maximumAmountPeriod}
                        sourceDataType={sourceDataType} startDate={startDate} endDate={endDate}
                        incentiveRateType={incentiveRateType}
                    />] : []),
                    <Reporting contractDefaultData={defaultContract ? defaultContract : ''}
                        submitValues={(values) => handleMiscellaneousDataChange(values)} fieldData={contractFormFields} />,
                    <InternalNotes contractDefaultData={defaultContract ? defaultContract : ''}
                        submitValues={(values) => handleInternalNotes(values)} />,
                    <ApprovalStatus contractDefaultData={defaultContract ? defaultContract : ''}
                        submitValues={(values) => handleApprovalStatus(values)}
                    />
                ];
            } else {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        savetriggered={saveTriggered} ref={childRef1} postingSchema={postingSchema}
                        eligibiltyRules={eligibilityData}
                        calculationRules={calculationData} fieldData={contractFormFields}
                    />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange}
                        savetriggered={saveTriggered} ref={childRef2} postingSchema={postingSchema}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        fieldData={contractFormFields}
                    />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment}
                        payment_method={paymentMethod} partnerType={paymentPartnerType}
                        formFields={formFields}
                        fieldData={contractFormFields}
                    />] : []),
                    ...(contractDependencyEnabled ? [<ContractDependency onChange={handleontractDependency}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                        contractDefaultData={defaultContract ? defaultContract : ''} fieldData={contractFormFields}
                    />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                    />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        onChange={handleCalculationRules} calculationSchema={calculationSchema}
                        calculationMethod={calculationMethod} maximumAmountPeriod={maximumAmountPeriod}
                        sourceDataType={sourceDataType} startDate={startDate} endDate={endDate}
                        incentiveRateType={incentiveRateType} />] : []),
                    <Reporting contractDefaultData={defaultContract ? defaultContract : ''} fieldData={contractFormFields}
                        submitValues={(values) => handleMiscellaneousDataChange(values)} />,
                    <InternalNotes contractDefaultData={defaultContract ? defaultContract : ''}
                        submitValues={(values) => handleInternalNotes(values)} />,
                    <ApprovalStatus contractDefaultData={defaultContract ? defaultContract : ''}
                        submitValues={(values) => handleApprovalStatus(values)}
                    />
                ];
            }
        } else {
            if (eligibilityEnabled) {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} postingSchema={postingSchema} eligibiltyRules={eligibilityData}
                        calculationRules={calculationData} fieldData={contractFormFields}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                    />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange}
                        savetriggered={saveTriggered} ref={childRef2} postingSchema={postingSchema}
                        contractDefaultData={defaultContract ? defaultContract : ''} fieldData={contractFormFields}
                    />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment}
                        payment_method={paymentMethod} partnerType={paymentPartnerType}
                        formFields={formFields}
                        fieldData={contractFormFields}
                    />] : []),
                    <TargetRules submitValues={(values) => handleTargetRules(values)} startDate={startDate} endDate={endDate} fieldData={contractFormFields} formFields={formFields} />,

                    ...(contractDependencyEnabled ? [<ContractDependency onChange={handleontractDependency}
                        startDate={startDate} endDate={endDate} fieldData={contractFormFields}
                        contractDefaultData={defaultContract ? defaultContract : ''} formFields={formFields}
                    />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                    />] : []),
                    <EligibilityRules calculationMethod={calculationMethod} onChange={handleEligibilityRules} calculationSchema={calculationSchema}
                        sourceDataType={sourceDataType} startDate={startDate} endDate={endDate} maximumAmountPeriod={maximumAmountPeriod} />,
                    ...(calcRuleEnabled ? [<CalculationRules onChange={handleCalculationRules} calculationSchema={calculationSchema} startDate={startDate} endDate={endDate}
                        calculationMethod={calculationMethod} maximumAmountPeriod={maximumAmountPeriod}
                        sourceDataType={sourceDataType}
                        incentiveRateType={incentiveRateType}
                    />] : []),
                    <Reporting submitValues={(values) => handleMiscellaneousDataChange(values)} fieldData={contractFormFields} />,
                    <InternalNotes submitValues={(values) => handleInternalNotes(values)} />,
                    <ApprovalStatus submitValues={(values) => handleApprovalStatus(values)}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                    />
                ];
            } else {
                var allSteps = [
                    <GeneralData onChange={handleGeneralDataChange} savetriggered={saveTriggered} ref={childRef1} postingSchema={postingSchema} eligibiltyRules={eligibilityData}
                        calculationRules={calculationData} fieldData={contractFormFields} contractDefaultData={defaultContract ? defaultContract : ''} />,
                    ...(currentApplication != 'Pricing' ? [<FinancialPostings onChange={handleFinancialDataChange}
                        savetriggered={saveTriggered} ref={childRef2} postingSchema={postingSchema}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                        fieldData={contractFormFields}
                    />] : []),
                    ...(multiPaymentRuleEnabled ? [<MultiplePayment onChange={handleMultiplePayment}
                        payment_method={paymentMethod} partnerType={paymentPartnerType}
                        formFields={formFields}
                        fieldData={contractFormFields}
                    />] : []),
                    <TargetRules submitValues={(values) => handleTargetRules(values)} startDate={startDate} endDate={endDate} fieldData={contractFormFields} formFields={formFields} />,
                    ...(contractDependencyEnabled ? [<ContractDependency onChange={handleontractDependency}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                        contractDefaultData={defaultContract ? defaultContract : ''} fieldData={contractFormFields}
                    />] : []),
                    ...(calcModifierEnabled ? [<CalculationModifier onChange={handleCalculationModifier}
                        startDate={startDate} endDate={endDate} formFields={formFields}
                    />] : []),
                    ...(calcRuleEnabled ? [<CalculationRules onChange={handleCalculationRules} calculationSchema={calculationSchema} sourceDataType={sourceDataType} startDate={startDate} endDate={endDate}
                        calculationMethod={calculationMethod} maximumAmountPeriod={maximumAmountPeriod}
                        incentiveRateType={incentiveRateType}
                    />] : []),
                    <Reporting submitValues={(values) => handleMiscellaneousDataChange(values)} />,
                    <InternalNotes submitValues={(values) => handleInternalNotes(values)} />,
                    <ApprovalStatus submitValues={(values) => handleApprovalStatus(values)}
                        contractDefaultData={defaultContract ? defaultContract : ''}
                    />
                ];
            }
        }

        return (
            <>
                {allSteps.map(
                    (stepCmp, index) => {
                        return <div hidden={index !== step}>{stepCmp}</div>
                    })
                }
            </>
        );
    }

    function checkValues() {

        var d1 = new Date(generalData.valid_to);
        var d2 = new Date(generalData.valid_from);
        if (targetTabEnabled && targetRulesData.length === 0) {
            setSaveDisabled(true)
        }
        if ((contractDependencyEnabled && contractDependencyData.length === 0))
            setSaveDisabled(true)
        if (calcModifierEnabled && calcModifierData.length === 0)
            setSaveDisabled(true)
        if (multiPaymentRuleEnabled && multiPaymentData.length === 0)
            setSaveDisabled(true)
        if (postingSchema === 'Accrual Only' || postingSchema === 'Settlement Only'
            || postingSchema === 'No Postings' || postingSchema === 'Payment Only'
        ) {
            if (postingSchema === 'Accrual Only') {
                if (
                    //     (approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    generalData.contract_type
                    && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    && generalData.application_type
                    && generalData.internal_description
                    && generalData.currency
                    && generalData.valid_from
                    && generalData.valid_to
                    && generalData.contract_purpose
                    && generalData.source_data_type
                    && generalData.posting_schema
                    && (currentApplication != 'Pricing' && financialData.accrual_frequency)
                    && (currentApplication != 'Pricing' && financialData.accrual_level)
                    && (currentApplication != 'Pricing' && financialData.copa_level)
                    && (calculationMethod != 'External Calculation' ? calculationData.length > 0 : true)
                    && d1 >= d2
                    && generalData.workflow_name
                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }
            } else if (postingSchema === 'Settlement Only') {
                if (
                    //(approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    //&&
                    generalData.contract_type
                    && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    && generalData.application_type
                    && generalData.internal_description
                    && generalData.currency
                    && generalData.valid_from
                    && generalData.valid_to
                    && generalData.contract_purpose
                    && generalData.source_data_type
                    && generalData.posting_schema
                    && generalData.workflow_name
                    && (currentApplication != 'Pricing'
                        && financialData.payment_frequency && financialData.payment_calendar
                        && financialData.payment_terms && financialData.payment_partner_role
                        && financialData.payment_level && financialData.payment_method
                        && financialData.payment_partner
                        && financialData.payment_terms
                        && financialData.payment_due_date
                    )
                    && (calculationMethod != 'External Calculation' ? calculationData.length > 0 : true)
                    && d1 >= d2
                    //&& approvalStatusData.approval_group_id
                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }

            }

            if (postingSchema === 'Payment Only') {
                if (
                    //(approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    // && 
                    generalData.contract_type

                    && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    && generalData.application_type
                    && generalData.internal_description
                    && generalData.currency
                    && generalData.valid_from
                    && generalData.valid_to
                    && generalData.contract_purpose
                    && generalData.source_data_type
                    && generalData.posting_schema
                    && (currentApplication != 'Pricing' && financialData.payment_level)
                    && financialData.payment_due_date
                    && (calculationMethod != 'External Calculation' ? calculationData.length > 0 : true)
                    && d1 >= d2
                    && generalData.workflow_name
                ) {
                    setSaveDisabled(false)
                } else {

                    setSaveDisabled(true)
                }
            }

            else if (postingSchema === 'No Postings') {
                if (
                    //(approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                    //&&
                    generalData.contract_type
                    && generalData.calculation_method
                    // && generalData.incentive_rate_type
                    && generalData.application_type
                    && generalData.internal_description
                    && generalData.currency
                    && generalData.valid_from
                    && generalData.valid_to
                    && generalData.contract_purpose
                    && generalData.source_data_type
                    && generalData.posting_schema
                    && generalData.workflow_name
                    && (calculationMethod != 'External Calculation' ? calculationData.length > 0 : true)
                    && d1 >= d2
                    //&& approvalStatusData.approval_group_id
                ) {
                    setSaveDisabled(false)
                } else {
                    setSaveDisabled(true)
                }
            }
        } else {
            if (
                //(approvalStatusData.approval_status === 'new' || (approvalStatusData.approval_status != 'new' && approvalStatusData.approval_group_id))
                //&& 
                generalData.contract_type
                && generalData.calculation_method
                // && generalData.incentive_rate_type
                && generalData.application_type
                && generalData.internal_description
                && generalData.currency
                && generalData.valid_from
                && generalData.valid_to
                && generalData.contract_purpose
                && generalData.source_data_type
                && generalData.posting_schema
                && generalData.workflow_name
                && (currentApplication != 'Pricing' && financialData.accrual_frequency
                    && financialData.accrual_level && financialData.copa_level
                    && financialData.payment_frequency
                    && financialData.payment_terms
                    && financialData.payment_level && financialData.payment_method)
                && financialData.payment_due_date
                && (calculationMethod != 'External Calculation' ? calculationData.length > 0 : true)
                && d1 >= d2
                // && approvalStatusData.approval_group_id
            ) {
                console.log("false")
                setSaveDisabled(false)
            } else {
                console.log("true")
                setSaveDisabled(true)
            }
        }
        if (generalData.calculation_method === 'Tiered Growth' || generalData.calculation_method === 'Tiered Volume') {
            if (!generalData.tier_type) {
                setSaveDisabled(true)
            }



            if (!generalData.tier_period) {
                setSaveDisabled(true)
            }
        }
        if (currentApplication != 'Pricing') {
            if (financialData.payment_level === 'Multiple Payments') {
                if (!financialData.payment_aggregation_level || !financialData.payment_frequency || !financialData.payment_method || !financialData.payment_terms) {
                    setSaveDisabled(true)
                }
            } else if (financialData.payment_level === 'Single Payment') {
                if (!financialData.payment_partner_role || !financialData.payment_partner || !financialData.payment_frequency || !financialData.payment_method || !financialData.payment_terms) {
                    setSaveDisabled(true)
                }
            }
        }

        if ((calculationMethod === 'Flat Rate' || calculationMethod === 'Fixed Amount') && incentiveRateType === 'Attribute') {
            // setSaveDisabled(true)
        } else {
            if (!eligibilityData.length > 0)
                setSaveDisabled(true)
        }
        if (targetTabEnabled && targetRulesData.length === 0) {
            setSaveDisabled(true)
        }

    }
    const [contractPopup, setContractPopup] = React.useState(false);
    useEffect(() => {
        setContractPopup(props.showContractPopup)
    }, [props.showContractPopup])

    return (
        <div>
            {contractPopup &&
                <Dialog open={contractPopup}>
                    <DialogContent classes={{
                        root: classes.dialogContent
                    }}>
                        <Typography variant="h4">
                            <DialogContentText>
                                Similar contract already exists. Are you sure you want to create new one?
                            </DialogContentText>
                        </Typography>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialog
                    }}>
                        <Button onClick={() => handleDialog('No')} autoFocus color="primary" variant="outlined" >
                            No
                        </Button>
                        <Button onClick={() => handleDialog('Yes')} color="primary" autoFocus variant="contained"  >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <div className={classes.root}>
                <LoadingOverlay
                    active={props.loadingFields || props.loading}
                    spinner={<RingLoader size={100} />}
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: "50px",
                            "& svg circle": {
                                stroke: "#045FB4",
                            },
                        }),
                        overlay: (base) => ({
                            ...base,
                            background: "rgba(52, 52, 52, 0)",
                        }),
                        content: (base) => ({
                            ...base,
                            color: "black",
                        }),
                    }}
                    className={classes.spinner}
                >
                    <div className={classes.stepper}>
                        <Stepper alternativeLabel nonLinear activeStep={activeStep}
                            classes={{ root: classes.stepperContainer }}
                            connector={
                                <StepConnector
                                    classes={{
                                        line: classes.connectorLine,
                                        alternativeLabel: classes.connectorAlternativeLabel
                                    }}
                                />
                            }
                        >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const buttonProps = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}
                                        classes={{ horizontal: classes.horizontal }}
                                    >
                                        <StepButton
                                            onClick={handleStep(index)}
                                            completed={isStepComplete(index)}
                                            {...buttonProps}
                                            classes={{ root: classes.stepButton }}
                                        >
                                            <StepLabel>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                    <Grid container >
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {activeStep !== 0 &&
                                <Button variant="outlined" color="primary" onClick={handleBack} className={classes.previous}>
                                    Previous Step
                                </Button>
                            }
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                            style={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button variant="outlined" color="primary" onClick={handleComplete}
                                className={clsx({
                                    [classes.button]: true,
                                    [classes.shiftContent]: !isDesktop,
                                })}
                                // disabled={saveDisabled}
                                disabled={props.loading === true || (contractDependencyEnabled && contractDependencyData.length === 0) ? true : saveDisabled}
                            >
                                {props.loading ?
                                    <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                    :
                                    ''
                                }
                                {props.loading ? '' : 'Save'}
                            </Button>
                            <Button variant="outlined" color="primary" onClick={handleDiscard} className={classes.button}>
                                Discard
                            </Button>
                            {!isLastStep() &&
                                <Button variant="contained" color='primary' className={classes.button} onClick={handleNext}
                                    disabled={(!calculationMethod || !postingSchema || !sourceDataType || !workflow) ? true : false}
                                >
                                    {activeStep === tabs.approvalStatus ? 'Done' : 'Next Step'}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </LoadingOverlay>
            </div>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        eligibilityRulesData: state.addContractData.eligibilityRulesData,
        calculationRulesData: state.addContractData.calculationRulesData,
        contractData: state.addContractData.contractDetailData,
        showContractPopup: state.addContractData.showContractPopup,
        contractDefaultData: state.customerData.contractDefaultDesignerGetAll,
        loading: state.addContractData.loading,
        currentApp: state.profileData.currentApp,
        dropdownConfig: state.financialCloseData.dropdownConfig,
        dropdownData: state.addMultipleConfigurationData.defaultValueLblDisp,//addMultipleConfigurationData.multipleConfigurationData,
        loadingFields: state.addMultipleConfigurationData.loading,
        formFields: state.addMultipleConfigurationData.multipleConfigurationData,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data, forced) => dispatch(addContractGeneralData(data, forced)),
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        onCheckEligibillityRulesTable: (id) => dispatch(getEligibilityRulesTableData(id)),
        onCheckCalculationRulesTable: (id) => dispatch(getCalculationRulesTableData(id)),
        resetShowContractOverlapPopup: () => dispatch(resetShowContractOverlapPopup()),
        getContractDefaultDesigner: () => dispatch(getContractDefaultDesigner(1, 100)),
        //onLoadingDefault: (applicationType, sourceDataType, data) => dispatch(getDefaultValues(applicationType, sourceDataType, null, data, 'filter-fields')),
        uploadEligibilityRulesClear: () => dispatch(uploadEligibilityRulesClear()),
        loadDefaultContract: (applicationType, fieldId) => dispatch(getInitialDataFromSourceDataType(applicationType, fieldId, 'contract')),
        //onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getCustomerMasterData: () => dispatch(getCustomerMasterData(1, 10000)),
        getGeneralUOM: () => dispatch(getGeneralUOM()),
        getdropdownConfig: (field) => dispatch(fetchDropdownConfig(field)),
        onLoadingFormField: (field) => dispatch(getDefaultValues(sessionStorage.getItem('application'), null, null, field, 'filter-fields')),
        fieldDesigner: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getApprovalWorkflow: (data) => dispatch(fetchDocumentStatus(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContract);