import React, { useState, useEffect, useRef, forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  Typography, useMediaQuery, IconButton, Button, Dialog, DialogContent, DialogContentText, DialogActions, Fab
} from '@mui/material';
import { LibraryAdd, CloudUpload } from '@mui/icons-material';
import { connect } from "react-redux";
import { uploadCompetitorDataFile, getCompetitorData, getCompetitorDataById, DeleteCompetitorData, getLblDispDesFieldValue } from "../../redux/actions";
import HashLoader from "react-spinners/HashLoader";
import { createTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { NavLink as RouterLink } from "react-router-dom";
import Moment from 'moment';
import { DataUtil } from '@syncfusion/ej2-data';
import MaterialTable from 'material-table';
import { Edit, DeleteForever, Storage } from '@mui/icons-material';
import { tableIcons } from "../../components/Icons/TableIcons";
DataUtil.serverTimezoneOffset = 0;
const theme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        // width: '300px'
      },
    },
    MuiTableCell: {
      root: {
        padding: 10,
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 0,
      },
    },


  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    padding: '20px 16px',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
    // marginTop:5
  },
  caption: {
    fontSize: 20
  },
  hover: {
    cursor: 'pointer'
  },
  IconButton: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: 10
    }
  },
  fabContainer: {
    marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
    borderColor: theme.palette.primary.main,
    zIndex: 1
  }
}));
const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));
const CompetitorData = (props) => {
  const { className } = props;
  let grid;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const history = useHistory();
  const [dataRows, setDataRows] = React.useState([]);
  const classes = useStyles();
  const [currentFile, setCurrentFile] = useState(undefined);
  const [currentFileName, setCurrentFileName] = useState("");
  const [columns, setColumns] = React.useState([]);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [memberId, setMemberId] = React.useState(0);
  useEffect(() => {
    props.getCompetitorData();
    var data = { "data_source": "competitor_sales_data" }
    props.getLblDispDesFieldValue(data, 'post');
  }, []);
  const action = {
    field: 'Actions',
    title: 'Actions',
    type: 'string',
    editable: 'never',
    render: rowData =>
      <div style={{ whiteSpace: 'nowrap' }}>
        <IconButton
          classes={{ root: classes.IconButton }}
          onClick={() => editMember(rowData.ID)}
          size="large">
          <Edit color="disabled" classes={{ root: classes.caption }} />
        </IconButton>
        <IconButton
          classes={{ root: classes.IconButton }}
          onClick={() => deleteMembership(rowData.ID)}
          size="large">
          <DeleteForever color="disabled" classes={{ root: classes.caption }} />
        </IconButton>
      </div>

  }
  useEffect(() => {
    if (props.competitorData && props.competitorData.records && props.competitorData.records.length > 0)
      setDataRows(props.competitorData.records)
  }, [props.competitorData]);
  useEffect(() => {
    var newArray = []
    if (props.tableData) {
      Object.values(props.tableData.field_label_attributes)
        .filter(item => item.sort_list)
        .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
        .map((item, index) => {
          newArray.push({
            field: item.key,
            title: item.current,
            type: item.key != 'formula_result' ? 'string' : 'Number',
            editable: 'never',
            render: (rowData) => <div>{item.key == 'start_date' || item.key == 'end_date' ?
              Moment.utc(rowData[item.key]).format('MM/DD/YYYY') : rowData[item.key]}</div>
          })
        })
      newArray.push(action)
      setColumns(newArray)
    }
  }, [props.tableData])
  function editMember(id) {
    //    props.getMembershipDetails(id,'MembershipID');
    props.getCompetitorDataById(id)
    navigateToEdit(id);
  }
  function navigateToEdit(id) {
    history.push('/competitor-data/edit/' + id);
  }
  function deleteMembership(id) {
    setMemberId(id)
    setOpenPopUp(true)
  }
  function handleDialog() {
    setOpenPopUp(false)
    props.DeleteCompetitorData(memberId)
  }
  const inputFile = useRef(null);
  const handleDrop = (event) => {
    setCurrentFileName(event.target.files[0].name);
    const data = new FormData();
    data.append("file", event.target.files[0]);
    setCurrentFile(data);
    props.uploadCompetitorDataFile(data);
    setCurrentFile(undefined);
    setCurrentFileName("");
    event.target.value = "";
  };
  return (
    <div className={clsx(classes.root, className)}>
      <LoadingOverlay
        active={props.loading}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "#045FB4",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgba(52, 52, 52, 0)",
          }),
          content: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        spinner={<HashLoader />}
        className={classes.spinner}
      // text='Loading ... Please wait ...'
      >
        <div style={{ display: "flex", justifyContent: "space-between", padding: 0 }}>
          <Typography variant="h1" color='primary'> Competitor Sales Data </Typography>
          <div
            className={clsx({
              [classes.row]: isDesktop,
            })}
          >
            <Fab aria-label="edit" variant="extended"
              size='medium'
              className={classes.fabContainer}
              component={CustomRouterLink}
              to='/competitor-data/competitor-data-excel-upload'
            >
              <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
            </Fab>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={(e) => handleDrop(e)}
            />

          </div>
        </div>
        {props.competitorData && props.competitorData.records &&
          props.competitorData.records.length > 0 ?
          <MaterialTable
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                >
                </div>
              ),
            }}
            title={' '}
            editable={true}
            icons={tableIcons}
            columns={columns}
            data={dataRows}
            // style={{ marginTop: '-18px' }}
            options={{
              maxBodyHeight: '100vh',
              search: false,
              filtering: true,
              headerStyle: theme.mixins.MaterialHeader,
              cellStyle: theme.mixins.MaterialCell,
              pageSize: 15,
              pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
            }}
          />
          :
          <Typography variant='h4'>
            There is no data to show now.
          </Typography>
        }
      </LoadingOverlay>
      <Dialog open={openPopUp}>
        <DialogContent classes={{
          root: classes.dialogContent
        }}>
          <Typography variant="h4">
            <DialogContentText>
              Are you sure you want to delete competitor data  ?
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions classes={{
          root: classes.dialog
        }}>
          <Button onClick={() => setOpenPopUp(false)} autoFocus color="primary" variant="outlined" >
            No
          </Button>
          <Button onClick={handleDialog} color="primary" autoFocus variant="contained"  >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.dataSetupData.loading,
    competitorData: state.dataSetupData.competitorData,
    tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadCompetitorDataFile: (file) => dispatch(uploadCompetitorDataFile(file)),
    getCompetitorData: () => dispatch(getCompetitorData()),
    getCompetitorDataById: (id) => dispatch(getCompetitorDataById(id)),
    DeleteCompetitorData: (id) => dispatch(DeleteCompetitorData(id)),
    getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompetitorData);