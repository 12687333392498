import React, { useRef, useState, useEffect } from 'react';
import {
    Grid,Button,Typography,Radio,RadioGroup,
    FormControlLabel,FormLabel,OutlinedInput,TextField
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Dropdown} from '../../../components/Inputs';
import SimpleDialog from '../../../components/Dialog';
import { toast } from 'react-toastify';
import { runPaymentPostingsSimulation } from '../../../redux/actions';
import ChipInput from 'material-ui-chip-input';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'moment';
import momentt from 'moment-timezone';
import {RotateLoader,HashLoader} from "react-spinners";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider,DatePicker } from '@mui/x-date-pickers';
momentt.tz.setDefault('Etc/UTC');

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    radioContainer: {
        display: 'flex',
        paddingTop: 8
    },
    radioLabel: {
        flexDirection: 'row'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        width:'100%',
        marginBottom: 14
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    chargeBackLabel: {
        height: 34,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize:theme.typography.h3.fontSize
    },
    errorMsg: {
        color: '#f44336',
        fontSize: 12,
        paddingTop: 5
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    fontSetting:{
        fontSize:theme.typography.h3.fontSize
    }
}));



const PaymentPostingsForm = props => {

    const classes = useStyles();
    const { className } = props;
    const [minimumDate, setMinDate] = React.useState(Moment(new Date()).startOf('month').format('MM/DD/YYYY'));
    const [maximumDate, setMaxDate] = React.useState(Moment(new Date()).endOf('month').format('MM/DD/YYYY'));
    const [errorMessage, setErrorMessage] = React.useState('');
    const [endErrorMessage, setEndErrorMessage] = React.useState('');


    useEffect(() => {
        setPaymentFrequency('Monthly')
        setCalculationPeriodStart(Moment().subtract(1, 'months').startOf('month'));
        setCalculationPeriodEnd(Moment().subtract(1, 'months').endOf('month'));
        setPostingDate(Moment().startOf('month'))
    }, []);

   
    const [calculationPeriodStart, setCalculationPeriodStart] = React.useState(null);
    const [calculationPeriodEnd, setCalculationPeriodEnd] = React.useState(null);
    const [postingDate, setPostingDate] = React.useState(null);
    const [radioGroupValue, setRadioGroupValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [paymentFrequency, setPaymentFrequency] = React.useState('Monthly');
    const [tags, setTags] = useState([]);
    const [startValid, setStartValid] = useState(true);
    const [endValid, setEndValid] = useState(true);
    const [postValid, setPostValid] = useState(true);
    const [channelPartnerId,setChannelPartnerId] = React.useState('');
    const [claimNumber,setClaimNumber] = React.useState('');

    const typeRef = useRef(null);
    const subTypeRef = useRef(null);
    const postingDateRef = useRef(null);
    const handleClear = () => {
        setTags([])
        setChannelPartnerId('');
        setClaimNumber('');
        setPostingDate(null);
        setCalculationPeriodStart(null);
        setCalculationPeriodEnd(null);
        setRadioGroupValue('');
        typeRef.current.reset();
        subTypeRef.current.reset();
        postingDateRef.current.reset();
        
    }
    const handleClickOpen = () => {
        if (calculationPeriodEnd && calculationPeriodStart) {
            if (calculationPeriodEnd && calculationPeriodStart) {
                setOpen(true)
            } else {
                if (!calculationPeriodEnd)
                    setCalculationPeriodEnd(false);
                if (!calculationPeriodStart)
                    setCalculationPeriodStart(false);
            }
        }
        else if (calculationPeriodEnd && calculationPeriodStart && postingDate)
            setOpen(true);
        else {
            toast.error('Please fill all the required fields.');
            if (!calculationPeriodEnd)
                setCalculationPeriodEnd(false);
            if (!calculationPeriodStart)
                setCalculationPeriodStart(false);
            if (!postingDate)
                setPostingDate(false);
        }
    };
    const handleDialog = (bool) => {
        var sd = [Moment.utc(calculationPeriodStart).format('YYYY/MM/DD')];
        var ed = [Moment.utc(calculationPeriodEnd).format('YYYY/MM/DD')];
        var pd = [Moment.utc(postingDate).format('YYYY/MM/DD')];

        var formData = {
            "filters" : {
            "application_type": sessionStorage.getItem('application'),
            "calculation_start_date": sd,
            "calculation_end_date": ed,
            "posting_date": pd,
            "tags": tags,
            "claim_number":claimNumber.length > 0 ? [claimNumber] : [],
            "channel_partner_id" : channelPartnerId.length > 0 ? [channelPartnerId]:[]
            }
        };
        if (bool) {
            setOpen(false);
            localStorage.setItem('page', 'Payment');
            props.onSubmit(radioGroupValue,formData);
        } else {
            setOpen(false);
        }
    }

    function handleCalculationPeriodStart(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodStart(Moment(newValue));
            if (paymentFrequency === 'Monthly') {
                if (Moment(newValue).startOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setErrorMessage('')
                    setCalculationPeriodEnd(Moment(newValue).endOf('month'))
                    setStartValid(true)
                }
                else {
                    setErrorMessage("Date should be starting of a Month. For Eg:" + Moment(newValue).startOf('month').format('MM/DD/YYYY'))
                    setStartValid(false)
                }


            }
        }
        else {
            setCalculationPeriodStart(newValue);
            if (!newValue)
                setCalculationPeriodEnd(null)
            setErrorMessage('')
            setStartValid(false)
        }
    }
    function handleCalculationPeriodEnd(newValue) {
        if (newValue && newValue.isValid()) {
            setCalculationPeriodEnd(Moment(newValue));
            if (paymentFrequency === 'Monthly') {
                if (Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY') === Moment(newValue).format('MM/DD/YYYY')) {
                    setEndErrorMessage('')
                    setEndValid(true)
                }
                else {
                    setEndErrorMessage("Date should be ending of a current start Month. For Eg: " + Moment(calculationPeriodStart).endOf('month').format('MM/DD/YYYY'))
                    setEndValid(false)
                }
            }
        }
        else {
            setCalculationPeriodEnd(newValue);
            setEndErrorMessage('')
            setEndValid(false)
        }

    }
    
    function handleTagsAdd(newValue) {
        setTags([...tags, newValue]);
    }
    function handleTagsDelete(newValue) {
        var deletedTags = tags.filter(item => item !== newValue)
        setTags(deletedTags)
    }
    function handlePostingDate(newValue) {

        if (newValue && newValue.isValid()) {
            setPostingDate(Moment(newValue));

            if ((Moment(newValue)) < (Moment(minimumDate)) ||
                (Moment(newValue) > Moment(maximumDate)))
                setPostValid(false)
            else
                setPostValid(true)

        }
        else {
            setPostValid(false)
            setPostingDate(newValue);
        }

    }
   
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
  
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row}>
                    <Typography variant="h1" color='primary'> Payment Postings </Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <Dropdown className={classes.chargeBackLabel} heading={'Application Type'} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                                style={{marginTop:'0.8rem'}}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Claim Number
                                </FormLabel>
                                <OutlinedInput
                                        value={claimNumber}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setClaimNumber(e.target.value)}
                                    />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Channel Partner ID
                                </FormLabel>
                                <OutlinedInput
                                        value={channelPartnerId}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={e => setChannelPartnerId(e.target.value)}
                                    />
                               
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >

                                <FormLabel required className={classes.chargeBackLabel} >
                                    Posting Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={postingDate}
                                            onChange={handlePostingDate}
                                            minDate={minimumDate ? minimumDate : ''}
                                            maxDate={maximumDate ? maximumDate : ''}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                            minDateMessage={'Date should not be before ' + Moment(minimumDate).format('MM/DD/YYYY')}
                                            maxDateMessage={'Date should not be after ' + Moment(maximumDate).format('MM/DD/YYYY')}
                                        />
                                    </Grid>
                                </LocalizationProvider>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Calculation Start Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodStart}
                                            onChange={handleCalculationPeriodStart}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormLabel style={{ display: errorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                    {errorMessage}
                                </FormLabel>
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel required className={classes.chargeBackLabel}>
                                    Calculation End Date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            disableToolbar
                                            clearable
                                            InputProps={{
                                                padding: 0,
                                                disableUnderline: true,
                                                style: {
                                                    padding: '1px 11px 1px 11px',
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 10,
                                                    border: '1px solid #E0E0E0',
                                                    width: '100%'
                                                }
                                            }}
                                            value={calculationPeriodEnd}
                                            onChange={handleCalculationPeriodEnd}
                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                            format="MM/DD/YYYY"
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                <FormLabel style={{ display: endErrorMessage.length > 0 ? 'block' : 'none' }} className={classes.errorMsg}>
                                    {endErrorMessage}
                                </FormLabel>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <FormLabel className={classes.chargeBackLabel}>
                                    Tags
                                </FormLabel>
                                <ChipInput
                                    classes={{
                                        root: classes.rootContainer,
                                        chip: classes.chip,
                                        input: classes.input,
                                        inputRoot: classes.inputRoot,
                                        chipContainer: classes.chipContainer,
                                        label: classes.chipLabel
                                    }}
                                    value={tags}
                                    blurBehavior='add'
                                    onAdd={(chips) => handleTagsAdd(chips)}
                                    onDelete={(chip) => handleTagsDelete(chip)}

                                    disableUnderline={true}
                                />
                            </Grid>
                           
                            <Grid
                                item
                                md={12}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <RadioGroup row aria-label="position" name="position" className={classes.radioContainer} onChange={handleRadioGroup} value={radioGroupValue}>
                                    <FormControlLabel
                                        value="Payment"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{root:classes.fontSetting}} > Payment </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                    <FormControlLabel
                                        value="Payment Reversal"
                                        control={<Radio color="primary" />}
                                        label={<Typography classes={{root:classes.fontSetting}} > Payment Reversal </Typography>}
                                        classes={{ labelPlacementStart: classes.radioLabel }}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>{console.log(!postValid || !startValid || !endValid || !radioGroupValue ? true : false)}
                            <Button
                                disabled={
                                    
                                        !postValid || !startValid || !endValid || !radioGroupValue ? true : false
                                }
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                            >
                                {props.loading ?
                                    <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                    :
                                    'Run'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to submit Payment postings?' handleDialog={handleDialog} />
            </div>
        </LoadingOverlay>
    );

};
const mapStateToProps = state => {
    return {
        loading: state.initialData.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (type,formData) => dispatch(runPaymentPostingsSimulation(type,formData)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPostingsForm);