import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Button,
    Card,
    Grid,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    CardHeader,
    FormLabel,
    Select,
    MenuItem,
    Input,
    Checkbox, OutlinedInput,
    ListItemText
} from '@mui/material';
import { SearchInput } from '../../../components';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { DropdownArray } from '../../../components/Inputs';
import {
    addMultipleConfiguration, getDefaultValues,
    secondGetConfiguration, putMultipleConfiguration, getDynamicQuerySalesDataFields, getDefaultValuesAllData, uploadEligibilityRules,
    loadValuesBasedOnAppTypeAndField, allowedApps, getDefaultValuesAllDataNew
} from '../../../redux/actions';
import { ApplicationTypes } from '../../../library/constants'
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '20px 16px',
        marginTop: 10
    },
    submitContainer: {
        marginRight: -25,
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 35,
        paddingBottom: 28
    },
    button: {
        marginRight: 25,
        width: 140
    },
    checkIconContainer: {
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        marginTop: 10
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    centerButton: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'space-around'
        },
    },
    addRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    tableRoot: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    textInputRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    gridContainer: {
        width: '100%'
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        padding: 0,
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25,
        paddingTop: 8
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        marginTop: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    dropdownContainer: {
        marginTop: -30,
        padding: 10
    },
    formLabel: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    selectedItem: {
        color: 'black',
        border: '1px solid #E0E0E0',
        width: '100%',
        marginTop: 5,
    },
    select: {
        width: '100%',
        marginTop: 17,
        height: 41,
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
        //zIndex:1,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'

    },

    btn: {
        marginRight: 25,
        padding: '10px,15px'
    }
}));

const AttributeCountingTab = props => {
    const [currentID, setCurrentID] = React.useState(0);
    const [applicationType, setApplicationType] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const [newArrayOfKeys, setNewArrayOfKeys] = React.useState([]);
    const [newArrayOfValues, setNewArrayOfValues] = React.useState([]);
    useEffect(() => {
        props.getDynamicQuerySalesDataFields();
        props.allowedApps();
    }, []);

    useEffect(() => {
        if (applicationType && sourceDataType) {
            props.onLoadingDefault(applicationType, sourceDataType);
            props.getDefaultValuesAllData('attribute_name', sourceDataType, applicationType);
            props.onLoadingLabelDesignerNew('field-label-designer', applicationType, sourceDataType);
        }

    }, [applicationType, sourceDataType]);
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records.length > 0) {
            setSecondApiDropDownValues(props.dropdownAttributeData.records[0].drop_down_value_keys);
            setDescriptionValue(props.dropdownAttributeData.records[0].description);
            setSelectedRowName(props.dropdownAttributeData.records[0].name);
            setSelectedRowId(props.dropdownAttributeData.records[0].ID)
            setFieldValue(props.dropdownAttributeData.records[0].drop_down_values);
            setCurrentID(props.dropdownAttributeData.records[0].ID)
        }
    }, [props.dropdownAttributeData]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [keyValuePairObjectNew, setKeyValuePairObjectNew] = React.useState([]);
    const [allowedApps, setAllowedApps] = React.useState([]);
    const [formFieldsAllData, setFormFieldsAllData] = React.useState([]);
    useEffect(() => {
        if (props.formFieldsAllData && props.formFieldsAllData.records) {
            setFormFieldsAllData(props.formFieldsAllData)
        }
    }, [props.formFieldsAllData])
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
            setApplicationType(props.allowedAppsData[0])
            props.loadSourceDataType(props.allowedAppsData[0], 'source_data_type');
        }
    }, [props.allowedAppsData])



    //new dropdown values
    useEffect(() => {
        var tempValues = [];
        var tempKeys = [];
        if (props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.length > 0) {

            setKeyValuePairObjectNew(props.formFieldsAllData.records[0].label_names)
        }
    }, [props.formFieldsAllData])

    const classes = useStyles();
    const { className } = props;
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.table.row,
                height: 46
            },
            '&:nth-of-type(even)': {
                height: 40
            },
        },
    }))(TableRow);




    const handleChange = (item) => {
        setCurrentID(item.ID);
        setSecondApiDropDownValues(item.drop_down_value_keys);
        setDescriptionValue(item.description);
        setSelectedRowName(item.name);
        setSelectedRowId(item.ID)
        setFieldValue(item.drop_down_values);
    };

    const refDescription = useRef(null);
    const [fieldValue, setFieldValue] = React.useState('');
    const [fieldValueOriginal, setFieldValueOriginal] = React.useState([]);
    const [putActive, setPutActive] = React.useState(false);
    const [descriptionValue, setDescriptionValue] = React.useState('');
    const [selectedRowId, setSelectedRowId] = React.useState('');
    const [selectedRowKey, setSelectedRowKey] = React.useState([]);
    const [selectedRowName, setSelectedRowName] = React.useState('');
    const [secondApiDropDownValues, setSecondApiDropDownValues] = React.useState([]);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [listOpen, setListOpen] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [usersId, setUsersId] = React.useState([]);
    const [dropdownAttributeData, setDropdownAttributeData] = React.useState([]);
    const isAllSelected =
        Object.keys(keyValuePairObjectNew).length > 0 && Object.keys(keyValuePairObjectNew).length === selected.length;


    var tempArray = [];

    function handleSubmit(clear) {

        if (clear === 'clear') {
            var data = {
                "form_id": "IMA_CONFIG_CONTRACT",
                "description": descriptionValue,
                "name": selectedRowName,
                "field_id": 'attribute_name',
                "section": 0,
                "drop_down_values": [],
                "drop_down_value_keys": [],
                "type": "string"
            }
            props.onMultipleConfigurationPut(data, currentID, applicationType, sourceDataType);
            props.onLoadingDefault(applicationType, sourceDataType);
        } else {

            var tempArrKey = [];
            var tempArrVal = [];
            if (props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.string_fields && fieldValue) {
                fieldValue.map((item1) => {
                    props.formFieldsAllData && props.formFieldsAllData.records && props.formFieldsAllData.records.map((item2) => {
                        if (item1 === item2.field_id) {
                            if (!tempArrVal.includes(item2.name)) {
                                tempArrVal.push(item2.name)
                                tempArrKey.push(item2.field_id)
                            }
                        }
                    })
                })
            }



            //creating field values
            var commonKeys = formFieldsAllData.records[0].drop_down_value_keys;
            var values = formFieldsAllData.records[0].drop_down_values;
            var index;
            var valuesArrayForAPI = [];
            fieldValue.map((item) => {
                index = commonKeys.indexOf(item);
                if (index > -1)
                    valuesArrayForAPI.push(values[index])
                else
                    valuesArrayForAPI.push(item.replace(/_/g, ' '))
            })


            setFieldValueOriginal(tempArrVal);
            var data = {
                "form_id": "IMA_CONFIG_CONTRACT",
                "description": descriptionValue,
                "name": selectedRowName,
                "field_id": 'attribute_name',
                "section": 0,
                "drop_down_values": valuesArrayForAPI,
                "drop_down_value_keys": fieldValue,
                "type": "string"
            }
            props.onMultipleConfigurationPut(data, currentID, applicationType, sourceDataType);
            props.onLoadingDefault(applicationType, sourceDataType);
        }
    }
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records) {
            setDropdownAttributeData(props.dropdownAttributeData)
        }
    }, [props.dropdownAttributeData])
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
        props.onLoadingLabelDesignerNew('field-label-designer', applicationType, e.target.value);
        {
            dropdownAttributeData.records
                .filter(item => item.app_source_type === applicationType && item.data_source_type === e.target.value && item.field_id === 'attribute_name')
                .map((item, index) => {
                    handleChange(item)
                })
        }
    }
    const handleApplicationtype = (event) => {
        setSourceDataType('');
        setApplicationType(event.target.value);
        {
            dropdownAttributeData.records
                .filter(item => item.app_source_type === event.target.value && item.data_source_type === sourceDataType && item.field_id === 'attribute_name')
                .map((item, index) => {
                    handleChange(item)
                })
        }
        props.loadSourceDataType(event.target.value, 'source_data_type');
    }
    const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] = React.useState([])
    useEffect(() => {
        // console.log(props.sourceDataTypeValue)
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeDataFromAPI(props.sourceDataTypeValue['drop_down_value_keys'])
            setSourceDataType(props.sourceDataTypeValue['drop_down_value_keys'][0])
        }
    }, [props.sourceDataTypeValue])
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 450,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0
            }
        }, getContentAnchorEl: null,


    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            setSelected(selected.length === Object.entries(keyValuePairObjectNew).length ? [] : Object.entries(keyValuePairObjectNew).map(([key, value]) => key));
        }
        else
            setSelected(newSelected)
    }
    function onAdd() {

        setSecondApiDropDownValues(selected)
        setDisableEdit(false);
        setFieldValue(selected);
        setListOpen(false)
    }
    function onCancel() {
        setListOpen(false)
        setSelected([])

    }
    function handleOpen() {
        setSelected(secondApiDropDownValues)
        setListOpen(true)
    }
    return (
        <div className={clsx(classes.root, className)}>
            {/* <MultipleConfigurationTableAdd rowData={rowData} /> */}
            {/* <div style={{marginTop:'-18px'}}>
            <Grid container  classes={{ root: classes.flexEnd }} >
                  <Typography variant="h1"> Eligibility Designer </Typography>
                
            </Grid>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > Eligibility Designer</Typography>
            </div>
            <div
                className={clsx(classes.addRoot, className)} style={{ marginTop: '6px' }}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        {/* <div className={classes.container}> */}
                        <Grid container className={classes.container}>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.buttonContainer}
                                spacing={3}
                            >

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.dropdownContainer}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Application Type
                                    </FormLabel>
                                    <Select
                                        value={applicationType}
                                        onChange={handleApplicationtype}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ paddingTop: 7 }}
                                    >
                                        {
                                            allowedApps
                                                .filter(item => item != 'Promotions' && item != 'Profit Optimization')
                                                .map((item, index) => {
                                                    return (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.dropdownContainer}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        Source Data Type
                                    </FormLabel>
                                    <Select
                                        value={sourceDataType}
                                        onChange={handleSourceDataType}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }}
                                        style={{ paddingTop: 7 }}
                                    >
                                        {sourceDataTypeDataFromAPI
                                            .map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                container className={classes.textInputRoot}
                                style={{ marginTop: 0 }}
                            >
                                <Grid
                                    item
                                    className={classes.gridContainer}
                                    style={{ padding: "15px 0px 15px" }}
                                >
                                    <FormLabel className={classes.formLabel} error={false}>
                                        Field Value
                                    </FormLabel>
                                    {!sourceDataType ?
                                        <div style={{ width: '100%', marginTop: 10, textTransform: 'capitalize', height: 36, alignItems: 'center', display: 'flex' }}>
                                            Select Source Data Type
                                        </div>
                                        :
                                        <Select
                                            multiple
                                            input={<OutlinedInput />}
                                            value={secondApiDropDownValues}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            //onChange={(event, index) => handleSecondApiDropDownValues(event, index)}
                                            style={{ width: '100%', marginTop: 8, textTransform: 'capitalize', paddingLeft: 6, height: 40 }}
                                            onOpen={handleOpen}
                                            onClose={() => setListOpen(false)}
                                            open={listOpen}
                                            renderValue={(user) => {
                                                let name = '';
                                                name = user.toString().replace(/_/g, ' ');
                                                return (<div style={{ textTransform: 'capitalize', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</div>)
                                            }}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: 'capitalize' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {keyValuePairObjectNew && Object.keys(keyValuePairObjectNew).length > 0 ?
                                                Object.entries(keyValuePairObjectNew)
                                                    .sort()
                                                    .map(([key, value]) => {
                                                        return (
                                                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }} onClick={(event) => handleSelectValues(event, key)}>
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={selected.indexOf(key) > -1} />
                                                                <ListItemText primary={value} />
                                                            </MenuItem>
                                                        )
                                                    })
                                                :
                                                tempArray && tempArray.map((item) => {
                                                    return (
                                                        <MenuItem>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                            <div className={classes.dropdownAction}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    }
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                className={classes.buttonContainer}
                                style={{ paddingLeft: 10 }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckIcon />}
                                    classes={{
                                        root: classes.checkIconContainer
                                    }}
                                    // disabled={disableEdit}
                                    onClick={handleSubmit}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={<CheckIcon />}
                                    classes={{
                                        root: classes.checkIconContainer
                                    }}
                                    // disabled={disableEdit}
                                    onClick={() => handleSubmit('clear')}
                                    style={{ marginLeft: 5, backgroundColor: '#FFF779', color: 'black' }}
                                >
                                    Reset
                                </Button>
                            </Grid>

                        </Grid>
                        {/* </div> */}
                    </form>
                </Card>
            </div >
            {/* <MultipleConfigurationTable onEdit={(row) => handleOnEdit(row)} /> */}
            {/* table div */}
            <div
                className={clsx(classes.tableRoot, className)}>
                <Card>
                    <div className={classes.row} >
                        <CardHeader
                            title="EXISTING VALUES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                    </div>
                    {!sourceDataType ?
                        <div style={{ width: '100%', marginTop: 10, textTransform: 'capitalize', height: 36, alignItems: 'center', display: 'flex', paddingLeft: 26 }}>
                            Select Source Data Type
                        </div>
                        :
                        <div className={classes.container}>
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {dropdownAttributeData && dropdownAttributeData.records && dropdownAttributeData.records.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell classes={{ root: classes.leftAlign }}>Field Name</TableCell>
                                                        <TableCell classes={{ root: classes.leftAlign }}>Field Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {/* {props.dropdownAttributeData.records */}
                                                    {/* .filter(item => item.app_source_type === applicationType && item.data_source_type === sourceDataType && item.field_id === 'attribute_name') */}
                                                    {/* .map((item, index) => { */}
                                                    {/* return ( */}
                                                    <StyledTableRow style={{ cursor: 'pointer' }} onClick={() => handleChange(dropdownAttributeData.records[0])}>
                                                        <TableCell classes={{ root: classes.leftAlign }}>
                                                            {/* {item.name} */}
                                                            {dropdownAttributeData.records[0].name}
                                                        </TableCell>
                                                        <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                            {/* {item && item.drop_down_values && item.drop_down_values.toString().replace(/_/g, ' ')} */}
                                                            {dropdownAttributeData && Object.values(dropdownAttributeData.records[0].label_names).toString()}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                    {/* ); */}
                                                    {/* })} */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                            Loading ...
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Card>
            </div>
        </div >
    );

};

const mapStateToProps = state => {
    return {
        tableData: state.addMultipleConfigurationData.multipleConfigurationData,
        secondApiData: state.addMultipleConfigurationData.secondApiData,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsData,
        formFieldsAllData: state.addMultipleConfigurationData.formFieldsAllData,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        allowedAppsData: state.initialData.allowedApps,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onMultipleConfigurationAdd: (data) => dispatch(addMultipleConfiguration(data)),
        onMultipleConfigurationPut: (data, id, appType, sourceType) => dispatch(putMultipleConfiguration(data, id, appType, 'Eligibility Designer', sourceType)),
        secondGetFunction: (data) => dispatch(secondGetConfiguration(data)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'attribute_name')),
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        getDefaultValuesAllData: (keyFigureName, sourceDataType, applicationType) => dispatch(getDefaultValuesAllData(keyFigureName, sourceDataType, applicationType, 'on', 'IMA_CONFIG_MULTIPLE')),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        allowedApps: () => dispatch(allowedApps()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributeCountingTab);