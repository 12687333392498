import React, { useEffect, forwardRef, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    useMediaQuery,
    Button, Fab, Typography
} from '@mui/material';
import { CloudUpload, LibraryAdd } from '@mui/icons-material';
import { createTheme } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import ViewColumn from '@mui/icons-material/ViewColumn';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getAttributeListData, deleteAttributeData, saveEditAttributeData, uploadAttributeFile } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../components/Dialog';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const Attributes = props => {
    useEffect(() => {
        props.getAttributeListData({
            attribute_name: ''
        })
    }, []);
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [dataRows, setDataRows] = React.useState([]);
    const inputFile = useRef(null);
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    useEffect(() => {
        setDataRows(props.attributeData);
    }, [props.attributeData]);

    function editAttributeData(item) {
        props.saveEditAttributeData(item);
        history.push('/attributes/edit-attribute-data/' + item.id);
    };

    function handleClickUploadEvent() {
        inputFile.current.click();
    }


    const handleFileUpload = (event) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadAttributeFile(data);
    };
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteAttributeData(currentId);
        } else {
            setOpen(false);

        }
    }

    const handleClickOpen = (Id) => {
        setOpen(true)
        setCurrentId(Id)
    };

    const columns = [
        {
            field: 'attribute_name',
            title: 'Attribute Name',
            editable: 'never',
            type: 'string',
            render: (rowData) => <a className={classes.hover} style={{ textTransform: 'capitalize' }}> {rowData.attribute_name.replace(/_/g, ' ')}</a>
        },
        {
            field: 'attribute_value',
            title: 'Attribute Value',
            type: 'string',
            render: rowData => <a className={classes.hover}> {rowData.attribute_value}</a>

        },
        {
            field: 'attribute_desc',
            title: 'Attribute Description',
            type: 'string',
            editable: 'never',
            render: rowData => <a className={classes.hover}> {rowData.attribute_desc}</a>
        },

        {
            field: 'Actions',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => editAttributeData(rowData)}
                            size="large">
                            <EditIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_attributes-' + sessionStorage.getItem('application')) &&
                        <IconButton
                            classes={{ root: classes.IconButton }}
                            onClick={() => handleClickOpen(rowData.id)}
                            size="large">
                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                        </IconButton>}
                </div>

        },
    ];

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading Attributes. Please wait ...'
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Attributes and Description Data </Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_attributes-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/attributes/add-attribute-data'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>
                            <input type='file'
                                accept=".xlsx, .xls, .csv"
                                id='file' ref={inputFile} style={{ display: 'none' }}
                                onChange={(e) => handleFileUpload(e)} />

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                onClick={() => handleClickUploadEvent()}
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                        </div>}
                </div>
                {props.attributeData || props.attributeData.length == 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '0px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [15, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        attributeData: state.attributeListData.AttributeData,
        loading: state.attributeListData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAttributeListData: (data) => dispatch(getAttributeListData(data)),
        deleteAttributeData: (id) => dispatch(deleteAttributeData(id)),
        saveEditAttributeData: (data) => dispatch(saveEditAttributeData(data)),
        uploadAttributeFile: (file) => dispatch(uploadAttributeFile(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);