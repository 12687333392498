import React from 'react';
import {
    Grid,Typography,Breadcrumbs,Card,
    Table,TableCell,TableHead,TableRow,TableContainer,TableBody,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
}));

const PriceListDataViewQuery = props => {
    const classes = useStyles();
    const [listData, setlistData] = React.useState(localStorage.getItem('pricing-maintenance-query') ? JSON.parse(localStorage.getItem('pricing-maintenance-query')) : []);
    
    return (
        <div className={classes.root}>
                <Typography color="primary" variant='h1'>View Format</Typography>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {listData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                {Object.keys(listData).length > 0 && Object.entries(listData)
                                                        
                                                        .filter(([k, v]) => k != 'id' && k != 'format_name'&& k != 'organization_id'&& k!='created_by_id'&&k!='updated_by_id'&&k!='mandatory' && v>0)
                                                        .sort(function (a, b) {
                                                            return a[1] < b[1] ? -1 : 1;
                                                        })
                                                        .map(([k, v]) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{root:classes.fontSetting}}  style={{ textAlign: 'center', textTransform: 'capitalize' }}>{k.replace(/_/g, ' ')}</TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        {JSON.parse(localStorage.getItem('pricing-maintenance-query'))[k] ? JSON.parse(localStorage.getItem('pricing-maintenance-query'))[k] : ''}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div >
        </div>
    );
};
export default connect(null, null)(PriceListDataViewQuery);