import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Link,
    Card,
    OutlinedInput,
    FormLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { connect } from 'react-redux';
import { getExternalFormula, getLblDispDesFieldValue, getTargetInvoiceList } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import BeatLoader from "react-spinners/BeatLoader";
import ChipInput from 'material-ui-chip-input';
import { HashLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey,
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize,
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 37,
        padding: 0,
        marginBottom: 14
    },
    spinner: {
        height: '100vh'
    },
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const TargetInvoiceSearch = props => {
    const classes = useStyles();
    const [membershipFields, setMembershipFields] = React.useState([]);
    const [membershipData, setMembershipData] = React.useState({});
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);

    useEffect(() => {
        var data = { "data_source": "commitment_data" };
        props.getLblDispDesFieldValue(data, 'post');
    }, []);
    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    if (item.mandatory || item.sort_details) {
                        newArray.push(item)
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setMembershipFields(newArray.sort((a, b) => {
                return a.sort_details - b.sort_details;
            }))
            setAllowedField(mandatory)
            setRequiredField(requiredFields);
        }
    }, [props.tableData])
    function handleSubmit() {
        if (membershipData['start_date'])
            membershipData['start_date'] = Moment.utc(membershipData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        if (membershipData['end_date'])
            membershipData['end_date'] = Moment.utc(membershipData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z")
        const formData = {};
        Object.keys(membershipData).forEach((key) => {
            if (Array.isArray(membershipData[key]) && membershipData[key].length > 0) {
                formData[`${key}.in`] = membershipData[key].toString();
            }
        });
        formData['start_date'] = membershipData['start_date'] && Moment.utc(membershipData['start_date']).toISOString().split("T")[0].concat("T00:00:00.000Z");
        formData['end_date'] = membershipData['end_date'] && Moment.utc(membershipData['end_date']).toISOString().split("T")[0].concat("T00:00:00.000Z");
        props.onSubmit(1, 0, 'search', formData);
        localStorage.setItem('search-data-commitment-data', JSON.stringify(formData))
        handleClearAll();
    }

    function handleClearAll() {
        setMembershipData({})
    }
    function handleMembershipDataInput(newValue, key, type) {
        setMembershipData((prevData) => ({
            ...prevData,
            [key]: [...(prevData[key] || []), newValue],
        }));
    }

    function handleMembershipDataDelete(newValue, key) {
        setMembershipData((prevData) => {
            const currentData = prevData[key] || [];
            const updatedArray = currentData.filter((item) => item !== newValue);
            return {
                ...prevData,
                [key]: updatedArray,
            };
        });
    }
    const handleMembershipData = (value, key, type) => {
        if (type == 'reference' && value) {
            value = value.key
        }
        else {
            if (key == 'formula_result' && value)
                value = parseFloat(value)
        }
        setMembershipData({ ...membershipData, [key]: value })
    }
    return (
        <div className={classes.root}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <Typography color="primary" variant='h1'>Search Commitment Data</Typography>
                <div className={classes.bodyContainer}>
                    <Card>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.container}>

                                <Grid container spacing={2}>
                                    {membershipFields && membershipFields.length > 0 &&
                                        membershipFields.map((item, index) => (

                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                            >
                                                <div className={classes.selectRoot}>
                                                    <FormLabel className={classes.formLabel}>
                                                        {item.current}
                                                    </FormLabel>
                                                    {item.key == 'start_date' || item.key == 'end_date' ?
                                                        <Grid container justifyContent="space-around">
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DatePicker
                                                                    disableToolbar
                                                                    clearable
                                                                    InputProps={{
                                                                        padding: 0,
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            padding: '2px 8px 1px 11px',
                                                                            borderRadius: '3px',
                                                                            alignSelf: 'center',
                                                                            alignItems: 'center',
                                                                            marginTop: 10,
                                                                            border: '1px solid #E0E0E0',
                                                                            width: '100%'
                                                                        }
                                                                    }}
                                                                    value={membershipData[item.key] ? membershipData[item.key] : null}
                                                                    onChange={date => handleMembershipData(date, item.key)}
                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                    minDate={item.key == 'end_date' && membershipData['start_date'] ? membershipData['start_date'] : ''}
                                                                    format="MM/DD/YYYY"
                                                                />
                                                            </LocalizationProvider>

                                                        </Grid>
                                                        :
                                                        item.drop_down_values && item.drop_down_values.length > 0 ?
                                                            <Select
                                                                value={membershipData[item.key] ? membershipData[item.key] : ''}
                                                                onChange={(e) => handleMembershipData(e.target.value, item.key)}
                                                                displayEmpty
                                                                className={clsx({
                                                                    [classes.select]: true
                                                                })}
                                                                style={{ marginTop: 8 }}
                                                                classes={{
                                                                    selectMenu: classes.selectedItem
                                                                }}
                                                            >   {item.drop_down_values.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                            </Select>
                                                            :
                                                            // item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                            //     <MasterDataComponent attributeName={item.key} withOutLabel={true} id={item.key} attributeList={item.drop_down_reference_value ? item.drop_down_reference_value : []} onChange={(value) => handleMembershipData(value, item.key, 'reference')} />
                                                            //     :
                                                            <ChipInput
                                                                classes={{
                                                                    root: classes.rootContainer,
                                                                    chip: classes.chip,
                                                                    input: classes.input,
                                                                    inputRoot: classes.inputRoot,
                                                                    chipContainer: classes.chipContainer,
                                                                    label: classes.chipLabel
                                                                }}
                                                                value={membershipData[item.key] || []}
                                                                onAdd={(chips) => handleMembershipDataInput(chips, item.key, 'reference')}
                                                                onDelete={(chip) => handleMembershipDataDelete(chip, item.key)}
                                                                disableUnderline={true}
                                                                className={clsx({
                                                                    [classes.textInput]: true
                                                                })}
                                                            />
                                                    }
                                                </div>
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                            </div>
                        </form>
                    </Card>
                    <div className={classes.buttonRoot}>
                        <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                            Clear
                        </Button>
                        <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}
                            disabled={Object.entries(membershipData).length >= 1 ? false : true}
                        >
                            {props.loadingAPI ?
                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                :
                                'Submit'
                            }
                        </Button>
                    </div>
                </div >
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.addMultipleConfigurationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (pagination, limit, keyword, data) => dispatch(getTargetInvoiceList(pagination, limit, keyword, data)),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetInvoiceSearch);