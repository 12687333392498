import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {
    runContractReports, runDynamicQuery, runCalculationReports, runFinancialReports,
    runInvoiceReports, getQueryListAdmin, loadTargetData
} from '../../redux/actions';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import BeatLoader from "react-spinners/BeatLoader";
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        // padding: 29,    
        marginTop: 16
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    }
}));
const SelectOperationalReports = props => {
    const [flag, setFlag] = React.useState(true);
    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [targetReportsData, setTargetReportsData] = React.useState([]);
    const [operationalReportsData, setOperationalReportsData] = React.useState([
        {
            id: 'R001', functionality: 'Calculation Simulations',
            sourceDataType: '',
            reportName: 'Calculation Summary',
            reportDescription: 'Calculation Simulation Summary and Analysis',
            url: '/calculation-summary-reports'
        },
        {
            id: 'R002', functionality: 'Calculation Simulations',
            sourceDataType: 'Direct - Purchase Data',
            reportName: 'Simulations Details',
            reportDescription: 'Simulation details by purchase line',
            url: '/calculation-simulation-reports'
        },
        // {
        //     id: 'R003', functionality: 'Contract Setup',
        //     sourceDataType: '',
        //     reportName: 'Contract Details',
        //     reportDescription: 'Contract header details',
        //     url: '/contract-reports'
        // },
        {
            id: 'R004', functionality: 'Contract Setup',
            sourceDataType: '',
            reportName: 'Contract List',
            reportDescription: 'Search Contracts by Flexible Criteria',
            url: '/search-contract'
        },
        {
            id: 'R005', functionality: 'Financial Postings',
            sourceDataType: '',
            reportName: 'Postings Summary',
            reportDescription: 'Postings Summary and Analysis',
            url: '/financial-approvals-reports'
        },
        {
            id: 'R006', functionality: 'Commitment Data',
            sourceDataType: 'Commitment Data',
            reportName: 'Commitment Details',
            reportDescription: 'Commitment Details',
            url: '/target-data-reports'
        },
        {
            id: 'R007', functionality: 'Contract Setup',
            sourceDataType: '',
            reportName: 'Contract Change',
            reportDescription: 'Contract Change Log',
            url: '/contract-change-reports'
        },
        {
            id: 'R008', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Calculate Formula values',
            reportDescription: 'Calculate Formula values by Attribute',
            url: '/calulate-formula-values-resports'
        },
        {
            id: 'R009', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Ratio of Two Key Figures',
            reportDescription: 'Calculate Ratios by Attribute',
            url: '/ratio-key-figures-resports'
        },
        {
            id: 'R010', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Accrual vs. Payment',
            reportDescription: 'Accrual vs. Payment Analysis',
            url: '/accrual-vs-Payment-reports'
        },
        {
            id: 'R011', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Flexible Stats',
            reportDescription: 'Dynamic Top N by Attribute',
            url: '/top-N-attributes-resports'
        },
        {
            id: 'R012', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Incentive Variance Analysis',
            reportDescription: 'Incentive Variance Analysis by Attribute',
            url: '/incentive-variance-analysis-resports'
        },
        {
            id: 'R013', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Duplicate Payments',
            reportDescription: 'Duplicate Payments by Flexible Criteria',
            url: '/duplicate-payment-resports'
        },
        {
            id: 'R014', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Anamoly Report',
            reportDescription: 'Research Anamolies by Dynamic Analysis',
            url: '/anamoly-reports'
        },{
            id: 'R015', functionality: 'Financial Postings',
            sourceDataType: 'Financial Postings',
            reportName: 'Approval History',
            reportDescription: 'Accrual and Payment Approval History',
            url: '/approval-posting-history-resports'
        },
        {
            id: 'R016', functionality: 'Direct Sales Data',
            sourceDataType: 'Direct Sales Data',
            reportName: 'Calculate Formula values by Contract Attribute',
            reportDescription: 'Calculate Formula values by Contract Attribute',
            url: '/calulate-formula-values-by-contract-resports'
        },
    ]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    //dynamic query functions
    function runQuery(url, type, item) {
        localStorage.setItem('operationalReportsSourceDataType', item.sourceDataType);
        if (type === 'API') {
            localStorage.removeItem('dyamicQueryDownloadData')
            var data = {
                id: item.allItem.id.toString()
            };
            if (item.allItem && item.allItem.filter_fields && item.allItem.filter_fields.length > 0) {
                var object = { id: item.allItem.id, fields: item.allItem.filter_fields }
                localStorage.setItem('dynamic_filter_fields', JSON.stringify(object));
                history.push({ pathname: '/dynamic-query/dynamic-query-form', filterValues: item.allItem.filter_fields });
            } else {
                props.runDynamicQuery(data, item.allItem.id);
            }
        } else {
            history.push(url);
        }
    }
    function runQueryWithoutFilters(item) {
        if (item === 'Contract Details') {
            props.loadContractDetails(1, 10, 'noFilter');
        } else if (item === 'Calculation Simulation') {
            props.loadCalculationSimulation(1, 10, 'noFilter');
        }
        else if (item === 'Contract Overlap') {
            props.loadContractOverlap(1, 10, 'noFilter');
        } else if (item === 'Direct Sales Data - Postings Details') {
            props.loadCalculationSimulation(1, 10, 'noFilter');
        } else if (item === 'Postings Details') {
            props.loadFinancialReports(1, 10, 'noFilter');
        } else if (item === 'Sales Invoice Details') {
            props.loadInvoiceDetails(1, 10, 'noFilter');
        }
        else if (item === 'Postings summary') {
            history.push('/financial-approvals-reports')
        }
    }


    useEffect(() => {
        var temp = [];
        if (props.queryListAdminData && props.queryListAdminData.length > 0 && flag) {
            props.queryListAdminData.map((item, index) => {
                setFlag(false);
                temp.push({
                    'id': item.id, 'functionality': item.table_name, 'reportName': item.format_name,
                    'url': item.format_name, 'reportDescription': item.query_description,
                    'type': 'API',
                    'allItem': item
                });
                setOperationalReportsData([...operationalReportsData, ...temp]);
            });
        }
    }, [props.queryListAdminData]);
    useEffect(() => {
        var temp = [];
        if (props.targetData && props.targetData.length > 0 && flag) {
            props.targetData.map((item, index) => {
                setFlag(false);
                temp.push({
                    'id': item.id, 'functionality': 'Target Data Table', 'reportName': 'Target Report',
                    'url': '', 'reportDescription': 'Report for ID - ' + item.ID,
                    'type': 'API',
                    'allItem': item
                });
                setTargetReportsData([...targetReportsData, ...temp]);
            });
        }
    }, [props.targetData]);
    useEffect(() => {
        // props.getQueryListAdmin();
    }, []);
    const runTargetData = (item) => {
        history.push('/select-operational-reports/target-reports');
    }
    const runContractNumberVarianceReports = (item) => {
        history.push('/select-operational-reports/contract-number-variance-reports');
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{marginTop:-5,marginLeft:16}}> Operational Reports </Typography>
            </div>
            <TableContainer>
                <Table>
                    <TableHead >
                        <TableRow >
                            <TableCell align='center' style={{ width: 200 }} className={classes.tabHead}>Report ID</TableCell>
                            <TableCell align='center' style={{ width: 200 }} className={classes.tabHead}>Data Source</TableCell>
                            {/* <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell> */}
                            <TableCell align='center' className={classes.tabHead}>Report Name</TableCell>
                            <TableCell align='center' className={classes.tabHead}>Report Description</TableCell>
                            <TableCell align='center' className={classes.tabHead}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody classes={{ root: classes.table }}>
                        {operationalReportsData
                            .filter(item => sessionStorage.getItem('application') === 'Customer Rebate' ? item.id != ('R002' || 'R008') 
                            :sessionStorage.getItem('application') === ('Customer Chargeback') ? item.id != 'R001'&& item.id != 'R002' && item.id != 'R006'&& item.id != 'R006'
                            :sessionStorage.getItem('application') === ('Supplier Chargeback') ? item.id != 'R001'&& item.id != 'R002' && item.id != 'R006'&& item.id != 'R006':item
                            )
                            .map((item, index) => {
                                return (
                                    <StyledTableRow key={item.id} >
                                        <TableCell align='center' classes={{root:classes.fontSetting}} style={{
                                            cursor: 'pointer'

                                        }}>{item.id}</TableCell>
                                        <TableCell align='center' classes={{root:classes.fontSetting}} style={{
                                            cursor: 'pointer', textTransform: 'capitalize'}}>{item.functionality.replace(/_/g, ' ')} </TableCell>
                                        {/* <TableCell align='center' classes={{root:classes.fontSetting}} style={{
                                            cursor: 'pointer'}}>{item.sourceDataType} </TableCell> */}
                                        <TableCell align='center' classes={{root:classes.fontSetting}} style={{
                                            cursor: 'pointer'
                                        }}>{item.reportName}</TableCell>
                                        <TableCell align='center' classes={{root:classes.fontSetting}} style={{
                                            cursor: 'pointer',
                                        }}>{item.reportDescription}</TableCell>
                                        {props.loading ?
                                            <TableCell width={200} align='center' classes={{root:classes.fontSetting}}>
                                                <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                            </TableCell>
                                            :
                                            <TableCell align='center' classes={{root:classes.fontSetting}} width={200}>
                                                <IconButton
                                                    classes={{ root: classes.IconButton }}
                                                    onClick={() => runQuery(item.url, item.type, item)}
                                                    size="large">
                                                    <DirectionsRunIcon color="disabled" style={{ fontSize: 20 }} />
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.operationalReportsData.loading,
        queryListAdminData: state.operationalReportsData.queryListAdminSuccessData,
        targetData: state.operationalReportsData.targetData
    }
};
const mapDispatchToProps = dispatch => {
    return {
        runQueryWithoutFilters: (data) => dispatch(runDynamicQuery(data)),
        loadContractDetails: (start, end, keyword) => dispatch(runContractReports(start, end, keyword)),
        loadContractOverlap: (start, end, keyword) => dispatch(runContractReports(start, end, keyword, 'overlap')),
        loadCalculationSimulation: () => dispatch(runCalculationReports(1, 10, '')),
        loadFinancialReports: (start, end, keyword) => dispatch(runFinancialReports(start, end, keyword)),
        loadInvoiceDetails: (start, end, keyword) => dispatch(runInvoiceReports(start, end, keyword)),
        // getQueryListAdmin: () => dispatch(getQueryListAdmin()),
        runDynamicQuery: (data, id) => dispatch(runDynamicQuery(data, id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOperationalReports);