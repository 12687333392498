import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import { geographicalIncentive } from "../../../../redux/actions";
import Region from "../../Charts/Region";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
    FormLabel: {
      fontSize: 13, //theme.typography.h4.fontSize,
    },
  },
}));
const GeoAnalysis = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState('contract_number');

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Incentive Ratio by Attribute" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && groupBy && postingType) {
      props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), postingType, groupBy);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (props.geographicalIncentiveData) {
      setGraphData(props.geographicalIncentiveData);
    }
  }, [props.geographicalIncentiveData]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    props.geographicalIncentive(Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), e.target.value, groupBy);
  };

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }

  return (
    <div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <FormControl sx={{ m: 1, maxWidth: 120 }} size="small">
          <InputLabel id="posting-type">Posting Type</InputLabel>
          <Select
            labelId="posting-type"
            id="posting-type-select"
            value={postingType}
            label="Posting Type"
            onChange={handlePostingType}
          >
            <MenuItem value={"accrual"}>Accrual</MenuItem>
            <MenuItem value={"payment"}>Payment</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={{ width: "100%" }}>
        <Region data={graphData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    geographicalIncentiveData: state.initialData.georgraphicalIncentive,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geographicalIncentive: (s, e, postingType, groupBy) =>
      dispatch(
        geographicalIncentive(
          s,
          e,
          postingType,
          "Customer Rebate",
          "Direct - Sales Data",
          groupBy
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoAnalysis);
