import React, { useEffect, forwardRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Button, Fab, Typography } from '@mui/material';
import { LibraryAdd, CloudUpload, SearchSharp } from '@mui/icons-material';
import { createTheme } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import ViewColumn from '@mui/icons-material/ViewColumn';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { getTargetInvoiceList, putTargetData, getLblDispDesFieldValue } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import { NavLink as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import SimpleDialog from '../../../components/Dialog';
import Moment from 'moment';
import { HashLoader } from 'react-spinners';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonStyle: {
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        }
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    spinner: {
        height: '100vh'
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const TargetInvoice = props => {
    const theme = useTheme();
    const { className } = props;
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("")
    const [currentData, setCurrentData] = React.useState("")
    const [dataRows, setDataRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const type = history.location.type == 'edit' ? true : false;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    useEffect(() => {
        if (props.commitmentData && props.commitmentData.records && props.commitmentData.records.length >= 0 && type) {
            setCount(props.commitmentData.total_record)
            setDataRows(props.commitmentData.records)
            var data = { "data_source": "commitment_data" };
            props.loadSourceDataType(data, 'post');
        }
        else {
            setDataRows([])
        }
    }, [props.commitmentData]);

    useEffect(() => {
        var newArray = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.sort_list)
                .sort((a, b) => a.sort_list > b.sort_list ? 1 : -1)
                .map((item, index) => {
                    const columnDefinition = {
                        field: item.key,
                        title: item.current,
                        type: item.key !== 'formula_result' ? 'string' : 'Number',
                        editable: 'never',
                    };
                    if (item.key === 'formula_result') {
                        columnDefinition.render = (rowData) => {
                            if (rowData[item.key]) {
                                const words = rowData.formula_result && parseFloat(rowData.formula_result).toLocaleString();
                                return <div>{words}</div>;
                            }
                            return "";
                        };
                    }
                    else if (item.key === 'start_date' || item.key === 'end_date') {
                        columnDefinition.render = (rowData) => (
                            <div>
                                {Moment.utc(rowData[item.key]).format('MM/DD/YYYY')}
                            </div>
                        );
                    } else {
                        columnDefinition.render = (rowData) => (
                            <div>{rowData[item.key]}</div>
                        );
                    }

                    newArray.push(columnDefinition);
                });
            newArray.push(action)
            setColumns(newArray)
        }
    }, [props.tableData])

    const CustomRouterLink = forwardRef((props, ref) => (
        <div
            ref={ref}
            style={{ flexGrow: 1 }}
        >
            <RouterLink {...props} />
        </div>
    ));

    function editCommitmentData(id) {
        localStorage.setItem('currentID', id);
        history.push({
            pathname: '/target-invoice-query/edit-commitment-data/' + id,
            id: id
        });
    }
    function handleSearch() {
        history.push(
            '/target-invoice-query-search'
        );
    }

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.deleteCommitmentData(currentData, currentId, 'delete');
        } else {
            setOpen(false);
        }
    }
    const handleClickOpen = (item, id) => {
        setOpen(true)
        setCurrentId(id)
        setCurrentData(item)
    };
    const action = {
        field: 'Actions',
        title: 'Actions',
        type: 'string',
        editable: 'never',
        render: rowData =>
            <div style={{ whiteSpace: 'nowrap' }}>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_commitment_data-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => editCommitmentData(rowData.ID)}
                        size="large">
                        <EditIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_commitment_data-' + sessionStorage.getItem('application')) &&
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleClickOpen(rowData, rowData.ID)}
                        size="large">
                        <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>}
            </div>
    }
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner={<HashLoader />}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                className={classes.spinner}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <Typography variant="h1" color='primary'> Commitment Data</Typography>
                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_commitment_data-' + sessionStorage.getItem('application')) &&
                        <div className={clsx({
                            [classes.row]: isDesktop
                        })} >
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/target-invoice-query/add-commitment-data'
                            >
                                <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                            </Fab>

                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                className={classes.fabContainer}
                                component={CustomRouterLink}
                                to='/target-invoice-query/excel-upload'
                            >
                                <CloudUpload color='primary' /><Typography color='primary' style={{ marginLeft: 3 }}>Upload</Typography>
                            </Fab>
                            <Fab aria-label="edit" variant="extended"
                                size='medium'
                                classes={{ root: classes.fabContainer }}
                                // component={CustomRouterLink}
                                // to='/target-invoice-query-search'
                                onClick={handleSearch}
                            >
                                <SearchSharp color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Search</Typography>
                            </Fab>
                        </div>}
                </div>
                {dataRows && dataRows.length > 0 ?
                    <MaterialTable
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        style={{ marginTop: '-18px' }}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 15,
                            pageSizeOptions: [
                                15,
                                20,
                                50,
                            ],
                            // pageSizeOptions: [15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />

                    :
                    <div className={classes.noDataContainer}>
                        Display Commitment Data by clicking on Search Icon.
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        commitmentData: state.dataSetupData.targetInvoiceListData,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // getTargetInvoiceList: () => dispatch(getTargetInvoiceList()),
        deleteCommitmentData: (data, id) => dispatch(putTargetData(data, id, 'delete')),
        loadSourceDataType: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetInvoice);