import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Typography,
    TextField
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from "@mui/styles";
import clsx from 'clsx';
import ChipInput from 'material-ui-chip-input';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import RotateLoader from "react-spinners/RotateLoader";
import SimpleDialog from '../../components/Dialog';
import { history } from '../../components/Helpers';
import { MultiSelectDropdownKeyVal } from '../../components/Inputs';
import { addPricingBatchJobSetup, getDefaultValues, getBuildSalesDataFieldValue, runBuildSalesData } from '../../redux/actions';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 10px 0px 10px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 200
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        //borderRadius: 3,
        marginTop: 10,
        // height: 33,
        padding: 0,
        //marginBottom: 14,
        width: "100%"
    },
    chip: {
        height: 21,
        margin: 0,
        marginLeft: 8,
        marginBottom: 8,
        backgroundColor: '#E1E4F3',
        '&&:hover': {
            backgroundColor: '#E1E4F3',
            color: theme.palette.black
        },
        fontFamily: 'ProximaNova',
        padding: 0
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputRoot: {
        height: 21,
        paddingLeft: 8,
        paddingBottom: 25
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'overlay',
        flexFlow: 'unset'
    },
    deleteIcon: {
        height: 13
    },
    chipLabel: {
        fontFamily: 'ProximaNova'
    },
    contractContiner: {
        marginTop: 17
    },
    spinner: {
        height: '100vh'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        marginTop: 10,
        height: 38
    },
    select1: {
        width: '100%',
        marginTop: 20,
        height: 38,
        textTransform: "capitalize",
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize
    },
    paper: { minWidth: window.screen.width * .50 },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    baseColor: {
        color: theme.palette.primary.main,
    },
    inputTwoLineOutlined: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10,
        height: 38,
    },
    selectedItem: {
        borderRadius: 5,
        textTransform: "capitalize",
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        width: '100%'
    },
}));

const CalculationSimulationForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [radioGroupValue, setRadioGroupValue] = React.useState('');

    const [billingDateRangeKey, setBillingDateRangeKey] = React.useState('billing_date');
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [attributesData, setAttributesData] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});
    const options = ["range", "equal", "not equal", 'greater than', 'less than', 'greater than equal to', 'less than equal to', "pattern"];
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.onLoadingDefault();
        props.getBuildSalesDataFieldValue({ "table_name": "Direct - Sales Data", "app-functionality": 'SalesDataUploadFormat' });
    }, []);
    console.log(props.tableData.field_label_attributes)
    useEffect(() => {
        let listData = []
        if (props.tableData && props.tableData.field_label_attributes) {
            Object.values(props.tableData.field_label_attributes)
                .filter(item => item.key != 'key_figure_names' && item.key != 'attribute_names' && item.key != 'accrual_amount' && item.key != 'change_request_id'
                    && item.key != 'chargeback_calculation_rules' && item.key != 'has_active_update_request' && item.key != 'is_update_approval_contract' && item.key != 'payment_amount'
                    && item.key != 'update_contract_id' && item.key != 'update_request_closed' && item.key != 'target_rules'
                )
                .map((item) => {
                    if (item.key != 'approval_status') {
                        listData.push({
                            'field_id': item.key, 'name': item.current, 'type': item.type
                            , 'drop_down_value_keys': item.type === 'DROPDOWN.STRING' ?
                                item.drop_down_reference_value
                                : []
                        })

                    }
                    if (item.key === "approval_status") {
                        let temp = [
                            {
                                "desc": "New",
                                "key": "new"
                            },
                            {
                                "desc": "Submit for approval",
                                "key": "submit_approval"
                            },
                            {
                                "desc": "Accepted",
                                "key": "acc"
                            },
                            {
                                "desc": "Rejected",
                                "key": "rej"
                            },
                            {
                                "desc": "Changes Approved",
                                "key": "changes_acc"
                            }
                        ]

                        listData.push({ 'field_id': item.key, 'name': item.current, 'type': 'DROPDOWN.SINGLE', 'drop_down_value_keys': temp })
                    }
                })
            setAttributeArray(listData.filter((v, i, a) => a.findIndex(v2 => (v2.field_id === v.field_id)) === i))
        }
    }, [props.tableData])
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [transactionType, setTransactionType] = React.useState('');
    const [batchJobName, setBatchJobName] = React.useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [packetSize, setPacketSize] = React.useState('')
    const formData = {
        "billing_start_date": billingDateStart ? Moment.utc(billingDateStart).format('YYYY-MM-DD') : '',
        "billing_end_date": billingDateEnd ? Moment.utc(billingDateEnd).format('YYYY-MM-DD') : '',
        "records": radioGroupValue,
        "transaction_type": transactionType,
        "packet_size": packetSize,
        "filter_fields": {
            ...filterData
        }
    };
    const format2 = {
        "app_type": "Sales Data",
        "batch_job_type": "Build Sales Data",
        "batch_job_name": batchJobName,
        "planned_date_type": "static",
        "planned_start_date": Moment.utc(new Date()).add(1, 'minute').local().format('YYYY-MM-DDTHH:mm:ssZ'),
        "planned_start_calendar": "",
        "planned_start_time": Moment.utc(new Date()).add(1, 'minute').local().format('HH:mm:00').toString(),
        "frequency": "One Time",
        "batch_filter_criteria": [
            { "filters": formData }]
    }
    function handleRadioGroup(newValue) {
        setRadioGroupValue(newValue.target.value);
    }
    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            props.onSubmit(formData);
            handleClear();
        } else {
            setOpen(false);
        }
    }
    const handleClear = () => {
        setBillingDateEnd(null);
        setBillingDateStart(null);
        setTransactionType();
        setPacketSize('')
        setAttributesData([])
        setAttributes([])
        setFilterData({})
        setRadioGroupValue('')

    }
    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClickOpen2 = () => {
        setOpen2(true)
    };
    const handleOption = (e, item) => {
        setFilterData({
            ...filterData, [item.field_id]: {
                ...filterData[item.field_id],
                "filter_option": e
            }
        })
    }
    const handleRangeFilterData = (data, item, data2) => {
        setFilterData({
            ...filterData, [item.field_id]:
            {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": {
                    "from": data.toString(), "to": data2
                }
            }

        })
    }
    const handleFilterData = (data, item, type) => {
        let chipData = []
        let temp = {}

        if (type == 'start_date' || type == 'end_date') {
            temp = filterData[item.field_id] ? filterData[item.field_id] : {}
            setFilterData({ ...filterData, [item.field_id]: { ...temp, [type]: data } })
        }
        if (data && data.length > 0) {
            if (type == 'chip') {
                chipData = filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []
                chipData = [...chipData, data]
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": chipData
                    }
                })
            }
            else if (type == 'NUMERIC')
                setFilterData({
                    ...filterData, [item.field_id]:
                    {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": Number(data)
                    }
                })
            else
                setFilterData({
                    ...filterData, [item.field_id]: {
                        ...filterData[item.field_id],
                        "filter_name": item.field_id,
                        "filter_value": data
                    }
                })
        }

    }
    const handleDeleteFilterData = (newValue, index, item) => {
        let deleteFilterData = filterData[item.field_id]['filter_value'].filter(item => item !== newValue)
        setFilterData({
            ...filterData, [item.field_id]: {
                ...filterData[item.field_id],
                "filter_name": item.field_id,
                "filter_value": deleteFilterData


            }
        })
    }

    const handleBillingDateStart = (newValue) => {
        setBillingDateStart(newValue);
        if (billingDateEnd) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleBillingDateEnd = (newValue) => {
        setBillingDateEnd(newValue);
        if (newValue) {
            setBillingDateRangeKey('billing_date.range');
        } else {
            setBillingDateRangeKey('billing_date');
        }
    }
    const handleTransactionType = (value) => {
        setTransactionType(value);
    }
    const handleBatchJobName = (e) => {
        setBatchJobName(e.target.value);
    }
    function handleBatchOnSubmit() {
        setOpen2(false)
        setBatchJobName('');
        props.onSubmitBatchJob(format2)
    }
    function handleCancelDialog() {
        setOpen2(false)
        setBatchJobName('');
        history.push('/build-sales-invoice');
    }
    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item) => item.field_id));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
        setSelectedDetail([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
        setSelectedDetail(attributesData)
    }
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div
                className={clsx(classes.root, className)}>
                <div className={classes.row} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>Build Sales Data</Typography>
                </div>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required>
                                        Transaction Type
                                    </FormLabel>
                                    <Select
                                        value={transactionType}
                                        onChange={(e) => handleTransactionType(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }} >
                                        <MenuItem value={'INV'} key={'INV'}>
                                            INV
                                        </MenuItem>
                                        <MenuItem value={'SO'} key={'SO'}>
                                            SO
                                        </MenuItem>
                                        <MenuItem value={'DEL'} key={'DEL'}>
                                            DEL
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            {transactionType === 'INV' &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div style={{ display: 'flex' }}>

                                        <div className={classes.contractContiner}>
                                            <FormLabel required>Billing Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                height: '2.4rem',
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                //borderRadius: '0.2rem'
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                        <div className={classes.contractContiner} style={{ marginLeft: 10 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '2.7rem',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10,
                                                                //borderRadius: '0.2rem',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            {transactionType === 'SO' &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div style={{ display: 'flex' }}>

                                        <div className={classes.contractContiner}>
                                            <FormLabel required>Sales Order Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                height: '2.4rem',
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                //borderRadius: '0.2rem'
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                        <div className={classes.contractContiner} style={{ marginLeft: 10 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '2.7rem',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10,
                                                                //borderRadius: '0.2rem',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            {transactionType === 'DEL' &&
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}

                                >
                                    <div style={{ display: 'flex' }}>

                                        <div className={classes.contractContiner}>
                                            <FormLabel required>Delivery Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '0.6rem',
                                                                height: '2.4rem',
                                                                border: !billingDateStart && billingDateEnd ? '1px solid red' : '1px solid #E0E0E0',
                                                                width: '100%',
                                                                //borderRadius: '0.2rem'
                                                            }
                                                        }}
                                                        value={billingDateStart}
                                                        onChange={handleBillingDateStart}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                        <div className={classes.contractContiner} style={{ marginLeft: 10 }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '2.7rem',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginLeft: 10,
                                                                //borderRadius: '0.2rem',
                                                                height: '2.4rem'
                                                            }
                                                        }}
                                                        value={billingDateEnd}
                                                        onChange={handleBillingDateEnd}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                        minDate={billingDateStart ? billingDateStart : ''}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Grid>
                            }
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }} required>
                                        Processing Type
                                    </FormLabel>
                                    <Select
                                        value={radioGroupValue}
                                        onChange={(e) => setRadioGroupValue(e.target.value)}
                                        displayEmpty
                                        className={clsx({
                                            [classes.select]: true
                                        })}
                                        classes={{
                                            selectMenu: classes.selectedItem
                                        }} >
                                        <MenuItem value={'non-processed'} key={'non-processed'}>
                                            Process New Records
                                        </MenuItem>
                                        <MenuItem value={'all'} key={'all'}>
                                            Process All Records
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <div className={classes.contractContiner}>
                                    <FormLabel classes={{ root: classes.fontSetting }}>
                                        Packet Size
                                    </FormLabel>
                                    <OutlinedInput
                                        type={'number'}
                                        value={packetSize}
                                        classes={{ root: classes.inputTwoLineOutlined }}
                                        onChange={(e) => setPacketSize(e.target.value)}
                                    >
                                    </OutlinedInput>
                                </div>
                            </Grid>
                            <Grid container style={{ marginBottom: 20, marginTop: '2rem' }}>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ display: 'flex' }}
                                >
                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginRight: 20, paddingTop: 5 }}>
                                        Select Required Filters
                                    </FormLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id={'filter-list'}
                                        multiple
                                        value={attributes ? attributes : []}
                                        style={{ maxHeight: "50px", width: '70%', color: 'grey' }}
                                        input={<OutlinedInput />}
                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                        onOpen={() => handleOpen()}
                                        onClose={() => setSelectOpen(false)}
                                        open={selectOpen}
                                        renderValue={(filter) => {
                                            let type = []
                                            filter.map(itemX => {
                                                attributeArray.filter(item => item.field_id == itemX).map((item) => {
                                                    if (item.name)
                                                        type.push(item.name)
                                                })

                                            })
                                            return type.join(',')
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                            <Checkbox
                                                color='primary'
                                                checked={allSelect} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {attributeArray && attributeArray.length > 0 && attributeArray
                                            .sort((a, b) => a.name > b.name ? 1 : -1)
                                            .map((item) => {
                                                return (

                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.field_id, item)} value={item.field_id} key={item.field_id} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected && selected.indexOf(item.field_id) > -1}
                                                        />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <MenuItem value='' ></MenuItem>

                                        <div className={classes.dropdownAction}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                </Grid>
                                <div className={classes.bodyContainer} >
                                    {attributesData?.map((item, i) => {
                                        return (
                                            <Grid
                                                container
                                            >
                                                <Grid
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <div className={classes.row} >
                                                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Filter Name"}</Typography>
                                                    </div>
                                                    <FormLabel classes={{ root: classes.formLabel }} style={{ marginTop: '1rem' }} >  {item.name} </FormLabel>

                                                </Grid>
                                                <Grid
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <div className={classes.row} >
                                                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Option"}</Typography>
                                                    </div>
                                                    <Select
                                                        value={filterData[item.field_id] && filterData[item.field_id]['filter_option'] ? filterData[item.field_id]['filter_option'] : []}
                                                        onChange={(e) => handleOption(e.target.value, item)}
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        displayEmpty
                                                        className={clsx({
                                                            [classes.select1]: true,
                                                        })}
                                                        classes={{
                                                            selectMenu: classes.selectedItem,
                                                        }}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        {options &&
                                                            options
                                                                .map((item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={item}
                                                                            key={item}
                                                                            style={{ textTransform: "capitalize" }}
                                                                        >
                                                                            {item}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                    </Select>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    <div className={classes.row} >
                                                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Value"}</Typography>
                                                    </div>

                                                    {item.type == 'DROPDOWN.STRING' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                        <MultiSelectDropdownKeyVal capitalize={true}
                                                            listArray={item.drop_down_value_keys} data={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []} id={item.name} onChange={(e) => handleFilterData(e, item)} />
                                                        :
                                                        item.type == 'BOOL' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                            <div>

                                                                <Select
                                                                    value={filterData[item.field_id] ? filterData[item.field_id.filter_value] : ''}
                                                                    onChange={(e) => handleFilterData(e.target.value, item)}
                                                                    displayEmpty
                                                                    className={clsx({
                                                                        [classes.select]: true
                                                                    })}
                                                                    classes={{
                                                                        selectMenu: classes.selectedItem
                                                                    }}
                                                                >
                                                                    <MenuItem value={'true'} key={'true'}>
                                                                        Yes
                                                                    </MenuItem>
                                                                    <MenuItem value={'false'} key={'false'}>
                                                                        No
                                                                    </MenuItem>
                                                                </Select>
                                                            </div>
                                                            :
                                                            item.type == 'TIME' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                <div>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                            <Grid container justifyContent="space-around">
                                                                                <DatePicker
                                                                                    disableToolbar
                                                                                    clearable
                                                                                    InputProps={{
                                                                                        padding: 0,
                                                                                        disableUnderline: true,
                                                                                        style: {
                                                                                            padding: '0px 11px',
                                                                                            alignSelf: 'center',
                                                                                            alignItems: 'center',
                                                                                            marginTop: 10,
                                                                                            border: '1px solid #E0E0E0',
                                                                                            width: '100%',
                                                                                            marginRight: 5
                                                                                        }
                                                                                    }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['start_date'] ? filterData[item.field_id]['start_date'] : null}
                                                                                    onChange={(e) => handleFilterData(e, item, 'start_date')}
                                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                    format="MM/DD/YYYY"
                                                                                />
                                                                            </Grid>
                                                                        </LocalizationProvider>
                                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                            <Grid container justifyContent="space-around">
                                                                                <DatePicker
                                                                                    disableToolbar
                                                                                    clearable
                                                                                    InputProps={{
                                                                                        padding: 0,
                                                                                        disableUnderline: true,
                                                                                        style: {
                                                                                            padding: '0px 11px',
                                                                                            alignSelf: 'center',
                                                                                            alignItems: 'center',
                                                                                            marginTop: 10,
                                                                                            border: '1px solid #E0E0E0',
                                                                                            width: '100%'
                                                                                        }
                                                                                    }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['end_date'] ? filterData[item.field_id]['end_date'] : null}
                                                                                    onChange={(e) => handleFilterData(e, item, 'end_date')}
                                                                                    renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                    format="MM/DD/YYYY"
                                                                                />
                                                                            </Grid>
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                </div>
                                                                :
                                                                item.type == "DROPDOWN.SINGLE" && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                    <div>
                                                                        <Select
                                                                            value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                            onChange={(e) => handleFilterData(e.target.value, item)}
                                                                            displayEmpty
                                                                            className={clsx({
                                                                                [classes.select]: true
                                                                            })}
                                                                            classes={{
                                                                                selectMenu: classes.selectedItem
                                                                            }}
                                                                        >
                                                                            <MenuItem value="" style={{ height: 30 }}>
                                                                            </MenuItem>
                                                                            {item.drop_down_value_keys && Object.values(item.drop_down_value_keys).length > 0 && Object.values(item.drop_down_value_keys)
                                                                                .map((item) => {
                                                                                    return (
                                                                                        <MenuItem value={item.key} key={item.key} >
                                                                                            {item.desc}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}


                                                                        </Select>
                                                                    </div>
                                                                    :
                                                                    item.type == 'NUMERIC' && filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                        <div>
                                                                            <OutlinedInput
                                                                                type={'number'}
                                                                                classes={{ root: classes.inputTwoLineOutlined }}
                                                                                value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : ''}
                                                                                onChange={(e) => handleFilterData(e.target.value, item, 'NUMERIC')}
                                                                            />

                                                                        </div> :
                                                                        filterData[item.field_id] && filterData[item.field_id]['filter_option'] !== 'range' ?
                                                                            <div>
                                                                                <ChipInput
                                                                                    classes={{
                                                                                        root: classes.rootContainer,
                                                                                        chip: classes.chip,
                                                                                        input: classes.input,
                                                                                        inputRoot: classes.inputRoot,
                                                                                        chipContainer: classes.chipContainer,
                                                                                        label: classes.chipLabel
                                                                                    }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] ? filterData[item.field_id]['filter_value'] : []}
                                                                                    onAdd={(chips) => handleFilterData(chips, item, 'chip')}
                                                                                    onDelete={(chip, index) => handleDeleteFilterData(chip, index, item)}
                                                                                    disableUnderline={true}
                                                                                    className={clsx({
                                                                                        [classes.textInput]: true,
                                                                                    })}
                                                                                />

                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <OutlinedInput
                                                                                    classes={{ root: classes.inputTwoLineOutlined }}
                                                                                    value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : ''}
                                                                                    onChange={(e) => handleRangeFilterData(e.target.value, item)}
                                                                                />
                                                                            </div>
                                                    }
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={2}
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                    style={{ display: filterData[item.field_id] && filterData[item.field_id]['filter_option'] == 'range' ? 'block' : 'none' }}
                                                >
                                                    <div className={classes.row} >
                                                        <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }}>{i == 0 && "Range"}</Typography>
                                                    </div>
                                                    <div>
                                                        <OutlinedInput
                                                            classes={{ root: classes.inputTwoLineOutlined }}
                                                            value={filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['to'] ? filterData[item.field_id]['filter_value']['to'] : ''}
                                                            onChange={(e) => handleRangeFilterData(filterData[item.field_id] && filterData[item.field_id]['filter_value'] && filterData[item.field_id]['filter_value']['from'] ? filterData[item.field_id]['filter_value']['from'] : '', item, e.target.value)}
                                                        />
                                                    </div>
                                                </Grid>

                                            </Grid>
                                        );
                                    })
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} twoline='true' onClick={handleClear} >
                                Clear
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen} twoline='true'
                                disabled={transactionType && radioGroupValue && billingDateStart && billingDateEnd ? false : true}
                            >
                                {
                                    props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run Online'
                                }
                            </Button>
                            <Button
                                variant="contained" color="primary" className={classes.button} onClick={handleClickOpen2} twoline='true'
                                disabled={transactionType && radioGroupValue && billingDateStart && billingDateEnd ? false : true}
                            >
                                {
                                    props?.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Schedule Batch Job'
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                <SimpleDialog open={open} content='Do you want to map stage sales data?' handleDialog={handleDialog} />
            </div >
            <Dialog
                onClose={() => setOpen2(false)}
                aria-labelledby="simple-dialog-title"
                open={open2}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Batch Job Name
                    </FormLabel>
                    <OutlinedInput
                        value={batchJobName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleBatchJobName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleBatchOnSubmit} disabled={batchJobName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay >
    );

};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        tableData: state.dataSetupData.buildSalesData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (formData) => dispatch(runBuildSalesData(formData)),
        onSubmitBatchJob: (data) => dispatch(addPricingBatchJobSetup(data, null, null, 'Salse Data')),
        onLoadingDefault: () => dispatch(getDefaultValues()),
        getBuildSalesDataFieldValue: (data) => dispatch(getBuildSalesDataFieldValue(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CalculationSimulationForm);