import React, { useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Breadcrumbs,
    Typography,
    TableCell,
    TableRow,
} from '@mui/material';
import { connect } from 'react-redux';
import { history } from '../../../components/Helpers';
import Moment from 'moment';
import { runcontractChangeReports } from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import { HashLoader } from 'react-spinners';
import { tableIcons } from '../../../components/Icons/TableIcons';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '5px 10px 0px 10px',
    },
    link: {
        color: theme.palette.primary,
        // opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    formLabel: {
        color: theme.palette.text.primary,
        // color: "red"
    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.table.row,

            //height: 46
        },
        '&:nth-of-type(odd)': {

            //height: 40
        }
    }
}))(TableRow);
const ContractChangeReportsTable = props => {
    const classes = useStyles();
    const { className } = props;
    const theme = useTheme();
    var result = [];
    const [changelog, setChangelog] = React.useState(localStorage.getItem('ContractChangeReportType'));
    const columns = [
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
        },
        {
            field: 'field_name',
            title: 'Field Name',
            type: 'string',
        },
        {
            field: 'contract_pending_id',
            title: 'Contract Pending ID',
            type: 'string',
        },
        {
            field: 'current_sub_tbl_value',
            title: 'Current Contract Value',
            type: 'string',

        },
        {
            field: 'changed_sub_tbl_value',
            title: 'Pending Change Value',
            type: 'string',

        },
        {
            field: 'UpdatedAt',
            title: 'Updated At',
            type: 'string',
        },
        {
            field: 'changed_by',
            title: 'Updated By',
            type: 'string',
        },
    ];

    const change_column = [
        {
            field: 'contract_number',
            title: 'Contract Number',
            type: 'string',
        },
        {
            field: 'field_name',
            title: 'Field Name',
            type: 'string',
        },
        {
            field: 'current',
            title: 'Current Contract Value',
            type: 'string',
        },
        {
            field: 'previous',
            title: 'Pending Change Value',
            type: 'string',
        },
    ]
    const [dataRows, setDataRows] = React.useState([]);


    useEffect(() => {
        if (props.contractData) {
            if (props.contractData.records && props.contractData.records[0])
                setChangelog(props.contractData.records[0].type)
            setDataRows(props.contractData.records.sort((a, b) => a['contract_number'] < b['contract_number'] ? 1 : -1))

        }
    }, [props.contractData])
    function groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {})
    }
    const markDifference = (currentValue, pendingValue) => {

        const currentValueArray = currentValue.split(' ; ');
        const pendingValueArray = pendingValue.split(' ; ');
        const diffArray = [];
        currentValueArray.forEach((item, index) => {
            if (item !== pendingValueArray[index]) {
                diffArray.push(`<span style="color:#0037A2;">${currentValueArray[index]}</span>`);
            } else {
                diffArray.push(item);
            }
        });
        const highlightedValue = diffArray.join(' ; ');

        return <a className={classes.hover} dangerouslySetInnerHTML={{ __html: highlightedValue }} />;

    }
    return (
        <div className={clsx(classes.root, className)}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    variant="h4"
                    classes={{
                        root: classes.link,
                    }}
                    color='Primary'
                    to="/contract-change-reports"
                >Contract Change</Link>
                <Typography color="textPrimary" variant="h4">Results </Typography>
            </Breadcrumbs>

            <LoadingOverlay
                active={props.loading}
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                spinner={<HashLoader />}
                className={classes.spinner}
            >
                {changelog === "all_changes" && dataRows && dataRows.length > 0 ?

                    <MaterialTable
                        columns={columns}
                        data={dataRows}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        options={{
                            //maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            emptyRowsWhenPaging: false,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            pageSize: 10,
                            pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                        components={{
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                            Row: ({ data, index }) => {
                                result = data['change_log'] ? groupByKey(data['change_log'], 'field_name') : data
                                var len = []
                                var l = 0
                                var l1 = 0
                                var l1 = (Object.keys(result).length) + 1;
                                Object.entries(result).map((key, index) => {

                                    if (key[0] === 'calculation_rules' || key[0] === 'eligibility_rules' || key[0] === 'notes'
                                        || key[0] === 'target_rules' || key[0] === 'chargeback_calculation_rules' || key[0] === 'contract_dependency_rules') {
                                        key[1].map((k, index) => {
                                            var l = (k.changed_sub_tbl_value.length) + 1
                                            l1 = l1 + l
                                        })
                                    }
                                    else {
                                        l1 = l1 + (Object.keys(key[1]).length)
                                    }

                                })
                                l = l1 + l

                                return (
                                    <Fragment >
                                        <TableRow style={{ backgroundColor: index % 2 != 0 ? 'rgba(242, 242, 242, 0.3)' : 'none', marginTop: 50 }}>
                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                {data.contract_number}
                                            </TableCell>
                                        </TableRow>
                                        {
                                            Object.entries(result).map((key, index) => {

                                                key[1].map(x => {
                                                    if (!len[x.field_name])
                                                        len[x.field_name] = 0;
                                                    if (x.field_name == 'calculation_rules' || x.field_name == 'eligibility_rules' || x.field_name === 'notes'
                                                        || x.field_name == 'target_rules' || x.field_name === 'chargeback_calculation_rules' || x.field_name === 'contract_dependency_rules') {
                                                        len[x.field_name] = x.changed_sub_tbl_value.length + parseInt(len[x.field_name])
                                                    }
                                                })
                                                return (
                                                    <Fragment>
                                                        <TableRow>
                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ textTransform: 'capitalize', borderTop: '2px solid #ddd' }} rowSpan={(Object.keys(key[1]).length) + 1 + len[key[0]]} >
                                                                {key[0].replace(/_/g, ' ')}
                                                            </TableCell>
                                                        </TableRow>
                                                        {key[1]
                                                            .sort((a, b) => a['contract_pending_id'] > b['contract_pending_id'] ? 1 : -1)
                                                            .sort((a, b) => a['contract_pending_id'] > b['contract_pending_id'] && a['UpdatedAt'] > b['UpdatedAt'] ? 1 : -1)
                                                            .map((k, index) => (
                                                                (k['field_name'] == 'calculation_rules' || k['field_name'] == 'eligibility_rules' || k['field_name'] === 'notes'
                                                                    || k['field_name'] == 'target_rules' || k['field_name'] === 'chargeback_calculation_rules' || k['field_name'] === 'contract_dependency_rules') ?
                                                                    < >
                                                                        <TableRow >
                                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={(Object.keys(k['changed_sub_tbl_value']).length) + 1}>
                                                                                {k['contract_pending_id']}
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        {k['current_sub_tbl_value'].map((k1, index) => (

                                                                            <StyledTableRow>

                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}>
                                                                                    {/* {k1} */}{markDifference(k1, k['changed_sub_tbl_value'][index])}
                                                                                </TableCell>

                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}>
                                                                                    {/* {k['changed_sub_tbl_value'][index]} */}{markDifference(k['changed_sub_tbl_value'][index], k1)}
                                                                                </TableCell>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {Moment(k['UpdatedAt']).local().format('MM/DD/YYYY HH:mm')}
                                                                                </TableCell>
                                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                                    {k['changed_by']}
                                                                                </TableCell>
                                                                            </StyledTableRow>

                                                                        ))}


                                                                    </>
                                                                    :
                                                                    <TableRow>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['contract_pending_id']}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {/* {k['current_value']} */}{markDifference(k['current_value'], k['changed_value'])}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {/* {k['changed_value']} */} {markDifference(k['changed_value'], k['current_value'])}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {Moment(k['UpdatedAt']).local().format('MM/DD/YYYY HH:mm')}
                                                                        </TableCell>
                                                                        <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }}   >
                                                                            {k['changed_by']}
                                                                        </TableCell>
                                                                    </TableRow>

                                                            )

                                                            )}


                                                    </Fragment>
                                                );
                                            })
                                        }


                                    </Fragment>
                                );
                            }

                        }}
                    />
                    :
                    changelog === 'pending_change' && dataRows && dataRows.length > 0 ?
                        <MaterialTable
                            components={{
                                Toolbar: (props) => (
                                    <div
                                        style={{
                                            height: "0px",
                                        }}
                                    >
                                    </div>
                                ),
                                Row: ({ data, index }) => {
                                    var l = Object.keys(data.change_log).length + 1;
                                    data.change_log && Object.entries(data.change_log).map(([key, value]) => {
                                        if (key === 'calculation_rules' || key === 'eligibility_rules' || key === 'notes'
                                            || key === 'target_rules' || key === 'chargeback_calculation_rules' || key === 'contract_dependency_rules')
                                            l = l + (Object.keys(value).length)
                                    })
                                    return (
                                        <Fragment >
                                            <TableRow style={{ backgroundColor: index % 2 != 0 ? 'rgba(242, 242, 242, 0.3)' : 'none' }}>
                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: '2px solid #ddd' }} rowSpan={l} >
                                                    {data['contract_number']}
                                                </TableCell>
                                            </TableRow>
                                            {data.change_log && Object.entries(data.change_log)
                                                .map(([key, value], i) => (

                                                    (key === 'calculation_rules' || key === 'eligibility_rules' || key === 'notes'
                                                        || key === 'target_rules' || key === 'chargeback_calculation_rules' || key === 'contract_dependency_rules') ?
                                                        <Fragment>
                                                            <StyledTableRow >
                                                                <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ textTransform: 'capitalize', borderTop: i == 0 ? '2px solid #ddd' : '' }} rowSpan={(Object.keys(value).length) + 1}>
                                                                    {key.replace(/_/g, ' ')}
                                                                </TableCell>
                                                            </StyledTableRow>

                                                            {Object.entries(value).map(([k, v]) => (
                                                                <StyledTableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: i == 0 ? '2px solid #ddd' : '' }}>{markDifference(v['previous'], v['current'])}</TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: i == 0 ? '2px solid #ddd' : '' }}>{markDifference(v['current'], v['previous'])}</TableCell>

                                                                </StyledTableRow>


                                                            ))
                                                            }
                                                        </Fragment>
                                                        :
                                                        <StyledTableRow>
                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ textTransform: 'capitalize', borderTop: i == 0 ? '2px solid #ddd' : '' }}>{key.replace(/_/g, ' ')}</TableCell>
                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: i === 0 ? '2px solid #ddd' : '' }}>
                                                                {key === 'Created At' ? Moment(value['previous_time']).local().format('MM/DD/YYYY HH:mm') : markDifference(value['previous'], value['current'])}
                                                            </TableCell>
                                                            <TableCell classes={{ root: classes.fontSetting }} align='center' style={{ borderTop: i === 0 ? '2px solid #ddd' : '' }}>
                                                                {key === 'Created At' ? Moment(value['current_time']).local().format('MM/DD/YYYY HH:mm') : markDifference(value['current'], value['previous'])}
                                                            </TableCell>
                                                        </StyledTableRow>

                                                ))}

                                        </Fragment>
                                    );

                                }

                            }}
                            title={' '}
                            editable={false}
                            icons={tableIcons}
                            columns={change_column}
                            data={dataRows}
                            // style={{marginTop:'-18px'}}
                            options={{
                                maxBodyHeight: '100vh',
                                search: false,
                                filtering: true,
                                emptyRowsWhenPaging: false,
                                headerStyle: theme.mixins.MaterialHeader,
                                cellStyle: theme.mixins.MaterialCell,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 50, { value: dataRows.length, label: 'Show all' }],
                            }}
                        />
                        :
                        <Typography variant='h4'>
                            There is no data to show now.
                        </Typography>
                }
            </LoadingOverlay>

        </div >
    );

};
const mapStateToProps = state => {
    return {
        contractData: state.operationalReportsData.contractChangeReportsData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getContractChangelogData: (pagination, limit, keyword) => dispatch(runcontractChangeReports(pagination, limit, keyword)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContractChangeReportsTable);


