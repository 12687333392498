import React, { useEffect } from 'react';
import {
    Button, Switch, MenuItem, Select, Checkbox,
    Grid, Typography, TextField, IconButton,
    InputAdornment, CardHeader, Card, FormLabel, OutlinedInput
} from '@mui/material';
import {
    LocationOnOutlined, MonetizationOnOutlined, CalendarMonthOutlined,
    List, ErrorOutlineOutlined, ExpandMore, ExpandLess, ArrowDropDownTwoTone,
    ArrowDropUpTwoTone,
} from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import { makeStyles } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Moment from 'moment';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import clsx from 'clsx';
import { NestedDropdown, PromoMultiSelect, PromoSelect } from '../Components';
import { getMaterialFilters, getBudgetingConfig, getModels } from '../../../redux/actions';
import { MultiSelectDropdown } from '../../../components/Inputs';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        // borderRadius: 10,
        // padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 8,
    },

    topHeader: {
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        height: 70,
        border: '1px solid #E8E8E8',
        borderRadius: 2,
        alignItems: 'center',
    },
    mainHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        marginLeft: '5px',
        height: 135,
        border: '1px solid #E8E8E8',
        borderTop: 0,
        borderRadius: 2,
        backgroundColor: '#f5f5f5',
    },

    headerItem: {
        marginLeft: 35,
    },
    searchBox: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    searchItem: {
        width: '60%'
    },
    selectBox: {
        width: 175,
        height: 30,
        border: "1px #E8E8E8",
        borderRadius: 2,
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
    },
    arrowSelectBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '89%',
        border: '1px solid #E0E0E0',
        height: 37,
        padding: 3,
    },
    optionsSelectBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '52%',
        border: '1px solid #E0E0E0',
        height: 33,
        padding: 3,
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        // padding: '5px 25px 0px 10px !important'
    },
    mainToggleButton: {
        marginTop: 10,
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },
    toggleButton: {
        border: '1px solid',
        borderRadius: 25,
        height: 34,
        width: 61,
        display: 'flex',
        alignItems: 'center',
    },
    checkBoxFont: {
        fontSize: 14,
    },
    outlined: {
        width: '100%',
        height: '37px'
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '128px',
        textAlign: 'center',
        borderRadius: '2rem',
        height: '36px'
    },

}));

const ScenarioSetting = props => {
    const classes = useStyles();
    const history = useHistory();
    const [promotionStartDate, setPromotionStartDate] = React.useState(null);
    const [promotionEndDate, setPromotionEndDate] = React.useState(null);
    const [reservationStartDate, setReservationStartDate] = React.useState(null);
    const [reservationEndDate, setReservationEndDate] = React.useState(null);
    const [invoiceStartDate, setInvoiceStartDate] = React.useState(null);
    const [requireAmount, setRequireAmount] = React.useState(false);
    const [requireCertificate, setRequireCertificate] = React.useState(false);
    const [stackablePromotion, setStackablePromotion] = React.useState(false);
    const [promotionBy, setPromotionBy] = React.useState(false);
    const [promoCategory, setPromoCategory] = React.useState(false);
    const [locations, setLocations] = React.useState([]);
    const [promoDivisionArray, setPromoDivisionArray] = React.useState([]);
    const [promoTypeArray, setPromoTypeArray] = React.useState([]);
    const [usageCodeArray, setUsageCodeArray] = React.useState([]);
    const [salesProgramCodeArray, setSalesProgramCodeArray] = React.useState([]);
    const [promoModelYearArray, setPromoModelYearArray] = React.useState([]);
    const [promoLocation, setPromoLocation] = React.useState();
    const [productLine, setProductLine] = React.useState();
    const [country, setCountry] = React.useState();
    const [modelYear, setModelYear] = React.useState([]);
    const [promoCurrency, setPromoCurrency] = React.useState();
    const [promoType, setPromoType] = React.useState();
    const [usageCode, setUsageCode] = React.useState();
    const [promotionByValue, setPromotionByValue] = React.useState();
    const [option, setOption] = React.useState();
    const [depositAmount, setDepositAmount] = React.useState();
    const [salesProgramCode, setSalesProgramCode] = React.useState();
    const [data, setData] = React.useState([]);
    const currency = ['USD', 'CAD', 'EUR'];
    // const country = ['US', 'Canada'];
    const optionsArray = ['Certificate', 'Authorization Codes', 'Pin Code']
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocItems, setSelectedLocItems] = React.useState([]);
    const [expanded, setExpanded] = React.useState([]);
    const [anchorloc, setAnchorloc] = React.useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    };

    var formData
    useEffect(() => {
        props.getConfigData()
    }, []);

    useEffect(() => {
        props.getFilters()
    }, []);

    // useEffect(() => {
    //     var data = {
    //         table_name: 'territory',
    //     }
    //     props.getLocationData('territoryLocation', data)
    // }, []);

    // useEffect(() => {
    //     if (props.locationData && props.locationData.length > 0)
    //         setLocations(props.locationData)
    // }, []);


    useEffect(() => {
        if (props.filterData && props.filterData['material_master'] && props.filterData['material_master'][0] && props.filterData['material_master'][0]['material_group1'])
            setPromoModelYearArray(props.filterData['material_master'][0]['material_group1'])
    }, [props.filterData]);


    useEffect(() => {
        if (props.configData && props.configData.length > 0) {
            setPromoDivisionArray(props.configData[0].promotion_division)
            setPromoTypeArray(props.configData[0].promotion_type)
            setUsageCodeArray(props.configData[0].usage_code)
            setSalesProgramCodeArray(props.configData[0].sales_program_code)
        }
    }, [props.configData]);

    // useEffect(() => {
    //     props.onChange(data);
    // }, [data]);

    function handleFormData() {
        formData = {

            promotion_type: promoType,
            usage_code: usageCode,
            sales_program_code: salesProgramCode,
            option: option,
            country: country,
            division: productLine,
            model_year: modelYear,
            promotion_currency: promoCurrency,
            promotion_start_date: promotionStartDate ? new Date(Moment(promotionStartDate).format('YYYY-MM-DD')).toISOString() : null,
            promotion_end_date: promotionEndDate ? new Date(Moment(promotionEndDate).format('YYYY-MM-DD')).toISOString() : null,
            reservation_start_date: reservationStartDate ? new Date(Moment(reservationStartDate).format('YYYY-MM-DD')).toISOString() : null,
            reservation_end_date: reservationEndDate ? new Date(Moment(reservationEndDate).format('YYYY-MM-DD')).toISOString() : null,
            invoice_start_date: invoiceStartDate ? new Date(Moment(invoiceStartDate).format('YYYY-MM-DD')).toISOString() : null,
            deposit_amount: parseFloat(depositAmount),
            promotion_by: promotionByValue
        }

        setData(formData)
        props.onChange(formData, selectedLocItems)
        //  props.handleNext()
    }

    function handleCategoryToggle(e) {
        setPromoCategory(e.target.checked)
    }

    function handleStackableToggle(e) {
        setStackablePromotion(e.target.checked)
    }

    function handlePromotionByToggle(e) {
        setPromotionBy(e.target.checked)
        if (e.target.checked)
            setPromotionByValue('Distributor')
        else
            setPromotionByValue('Dealers')
    }

    function handleToggle(e) { }

    const handleFilters = (value, field, type) => {
        // setFilterData(value)
    }

    function handlePromoWhat(e, type) {
        if (type === 'promoType')
            setPromoType(e.target.value);
        if (type === 'usageCode')
            setUsageCode(e.target.value);
        if (type === 'salesProgramCode')
            setSalesProgramCode(e.target.value);
        if (type === 'options')
            setOption(e.target.value);
    }

    function handlePromoWhere(e, type) {
        if (type === 'productLine')
            setProductLine(e);
        if (type === 'currency')
            setPromoCurrency(e.target.value)
        if (type === 'modelYear')
            setModelYear(e)
    }

    const handleClick = (type) => {
        if (type == 'location') {
            setAnchorloc(!anchorloc)
            const isExpanded = !anchorloc
            setExpanded(isExpanded ? [...expanded, 'loc_filter'] : []);
        }
    };

    const handleCheckedLoc = (selectedItems, arr1, type) => {
        console.log(selectedItems)
        console.log(arr1)
        setAnchorloc(false)
        if (type !== 'cancel') {
            setSelectedLocItems(arr1)
            setSelectedLoc(selectedItems)
            const regions = {};

            selectedItems.forEach(item => {
                if (item.region) {
                    if (!regions[item.region]) {
                        regions[item.region] = new Set();
                    }
                    if (item.state) {
                        regions[item.region].add(item.state.toUpperCase());
                    }
                }
            });
            console.log(regions)
            const selectedLocations = [];
            if (selectedItems.some(item => item.country === 'USA')) {
                selectedLocations.push('USA');
                setCountry('US')
            }
            else {
                selectedLocations.push('CA')
                setCountry('Canada')
            }

            for (const region in regions) {
                const states = Array.from(regions[region]);
                if (states.length > 0) {
                    const regionString = `${region.toUpperCase()}(${states.join(',')})`;
                    selectedLocations.push(regionString);
                } else {
                    selectedLocations.push(region.toUpperCase());
                }
            }

            const selectedLocationsString = selectedLocations.join(', ');
            setPromoLocation(selectedLocationsString)
            console.log(selectedLocationsString)
        }
    }

    const handleProductLine = (value) => {
        if (value && value.length > 0)
            setProductLine(value)
    }

    const handleModelYear = (value) => {
        if (value && value.length > 0)
            setModelYear(value)
    }

    const handlePromotionStartDate = (value) => {
        setPromotionStartDate(value);
    }
    const handlePromotionEndDate = (value) => {
        setPromotionEndDate(value);
    }
    const handleReservationStartDate = (value) => {
        setReservationStartDate(value);
    }
    const handleReservationEndDate = (value) => {
        setReservationEndDate(value);
    }
    const handleInvoiceStartDate = (value) => {
        setInvoiceStartDate(value);
    }

    const handleDepositAmount = (e) => {
        setDepositAmount(e.target.value)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.topHeader}>
                    <div style={{ height: 50, marginLeft: 5, display: 'flex', justifyContent: 'space-between' }}>
                        <div className={classes.row}>
                            <Button style={{ marginLeft: 10, marginRight: 25 }} variant="outlined" startIcon={<ArrowLeftIcon />} onClick={() => history.push('/dashboard')}>
                                Return
                            </Button>
                            <Typography variant='h3' color='black' style={{ marginTop: 3, marginRight: 20 }}>
                                Scenario Setting
                            </Typography>
                            <Typography variant='h5' style={{ marginTop: 3 }}>
                                <CircleIcon style={{ marginLeft: 15, height: 10, color: 'red' }} />
                                In Progress
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.mainHeader}>
                    <div className={classes.headerItem} style={{ display: 'flex' }}>
                        <Typography variant='h4' color='grey' style={{ display: 'inline', marginRight: 10 }}>
                            Prefill Options from a previous scenario
                        </Typography>
                        <div className={classes.selectBox}>
                            <List />
                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginLeft: 5 }} >
                                None Selected
                            </FormLabel>
                            <PromoMultiSelect id='none-selected' onChange={(value) => handleFilters(value)}
                                options={[]} />
                        </div>
                    </div>
                    <div className={classes.headerItem}>
                        <Typography variant='h4' color='grey' style={{ display: 'inline' }}>
                            SCENARIO NAME
                        </Typography>
                        <Typography variant='h5' color='grey' style={{ display: 'inline' }}>
                            In addition, a generic name dedicated to your workspace will be automatically generated.
                        </Typography>
                    </div>
                    <div className={classes.searchBox}>
                        <div className={classes.searchItem}>
                            <TextField
                                placeholder="Promotion theme name"
                                onChange=""
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <GTranslateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ align: 'center', backgroundColor: 'white' }}
                            />
                        </div>
                        <div style={{ marginLeft: 250 }}>
                            <Button style={{ height: 35 }} variant="outlined" onClick={() => history.push('/promotion-setup')}>Cancel</Button>
                            <Button style={{ marginLeft: 10, height: 35 }}
                                variant="contained"
                                // disabled={productLine && country && promoType && usageCode && salesProgramCode && modelYear && promoCurrency &&
                                //     reservationStartDate && reservationEndDate && promotionStartDate && promotionEndDate ? false : true}
                                onClick={handleFormData}>Create Sticker</Button>
                        </div>
                    </div>

                </div>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <CardHeader
                            title="SCENARIO PROPERTIES"
                            titleTypographyProps={{ variant: 'h3' }}
                        />
                        <div className={classes.container}>
                            <Grid container
                                style={{ marginBottom: 16, padding: '0px 25px 0px 25px' }}
                                spacing={1}
                            >
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15 }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginBottom: 10 }} variant='h3'>WHAT</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Typography style={{ marginLeft: 5, marginTop: 12 }}>Live Promotion</Typography>
                                        <span className={classes.mainToggleButton} >
                                            <Switch
                                                checked={promoCategory}
                                                onClick={handleCategoryToggle}
                                                disabled={false} />
                                        </span>
                                        <Typography style={{ marginTop: 12 }}>Budget Forecasting</Typography>
                                    </div>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}

                                    >
                                        <Grid
                                            item
                                            md={3.5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >

                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 20 }} required>
                                                Promotion Type
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={promoType}
                                                onChange={(value) => handlePromoWhat(value, 'promoType')}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >

                                                {promoTypeArray && promoTypeArray.length > 0 && promoTypeArray
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: -2 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }} required>
                                                Usage Code
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={usageCode}
                                                onChange={(value) => handlePromoWhat(value, 'usageCode')}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >

                                                {usageCodeArray && usageCodeArray.length > 0 && usageCodeArray
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required>
                                                Sales Program Code
                                            </FormLabel>
                                            <Select
                                                disableUnderline
                                                value={salesProgramCode}
                                                onChange={(value) => handlePromoWhat(value, 'salesProgramCode')}
                                                displayEmpty
                                                MenuProps={{ ...MenuProps, autoFocus: true }}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                style={{ textTransform: 'capitalize' }}
                                            >

                                                {salesProgramCodeArray && salesProgramCodeArray.length > 0 && salesProgramCodeArray
                                                    .map((item) => {
                                                        return (
                                                            <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                {item}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 5 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <div style={{ marginLeft: -10 }}>
                                                <Checkbox
                                                    checked={requireAmount}
                                                    onChange={(e) => setRequireAmount(e.target.checked)}
                                                    color='primary'
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    className={classes.checkBoxContainer}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <FormLabel classes={{ root: classes.checkBoxFont }} >
                                                    Require Deposit Amount
                                                </FormLabel>
                                            </div>
                                            {requireAmount &&
                                                <div classes={classes.arrowSelectBox}>
                                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }}>
                                                        Deposit Amount
                                                    </FormLabel>
                                                    <OutlinedInput twoline='true'
                                                        type={'number'}
                                                        value={depositAmount != 0 ? depositAmount : ''}
                                                        onChange={handleDepositAmount}
                                                        className={classes.outlined} />
                                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                                        <ErrorOutlineOutlined />
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>If you need to enter several</Typography>
                                                            <Typography variant='h5' color='black' style={{ whiteSpace: 'nowrap' }}>
                                                                amounts <a href="#" style={{ cursor: 'pointer', textDecoration: 'underline' }}> click here</a>
                                                            </Typography></div>
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <div style={{ marginLeft: -10 }}>
                                                <Checkbox
                                                    checked={requireCertificate}
                                                    onChange={(e) => setRequireCertificate(e.target.checked)}
                                                    color='primary'
                                                    classes={{
                                                        root: classes.formControlRoot
                                                    }}
                                                    className={classes.checkBoxContainer}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <FormLabel classes={{ root: classes.checkBoxFont }} >
                                                    Require Certificates, Pincodes or Authorization Code
                                                </FormLabel>
                                            </div>
                                            {requireCertificate &&
                                                <>
                                                    <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15, marginRight: 60 }}>
                                                        Options
                                                    </FormLabel>
                                                    <Select
                                                        disableUnderline
                                                        value={option}
                                                        onChange={(value) => handlePromoWhat(value, 'options')}
                                                        displayEmpty
                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        className={clsx({
                                                            [classes.select]: true
                                                        })}
                                                        style={{ textTransform: 'capitalize', width: "60%" }}
                                                    >

                                                        {optionsArray && optionsArray.length > 0 && optionsArray
                                                            .map((item) => {
                                                                return (
                                                                    <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                                        {item}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, marginBottom: 20 }}

                                >

                                    <Typography className={classes.fontSetup} style={{ marginBottom: 10 }} variant='h3'>WHEN</Typography>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        spacing={2}

                                    >

                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required> Promotion Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                // marginTop: 10
                                                            }
                                                        }}
                                                        value={promotionStartDate}
                                                        onChange={handlePromotionStartDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel style={{ fontSize: 11, marginLeft: -80 }} >Equal as Registration Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginTop: 11
                                                            }
                                                        }}
                                                        value={promotionEndDate}
                                                        onChange={handlePromotionEndDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: -1 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required> Reservation Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                // marginTop: 10
                                                            }
                                                        }}
                                                        value={reservationStartDate}
                                                        onChange={handleReservationStartDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '100%',
                                                                marginTop: 41
                                                            }
                                                        }}
                                                        value={reservationEndDate}
                                                        onChange={handleReservationEndDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: -1 }}
                                        spacing={1}

                                    >
                                        <Grid
                                            item
                                            md={5}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} >Invoice Date</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Grid container justifyContent="space-around">
                                                    <DatePicker
                                                        disableToolbar
                                                        clearable
                                                        InputProps={{
                                                            padding: 0,
                                                            disableUnderline: true,
                                                            style: {
                                                                padding: '0px 11px',
                                                                height: 37,
                                                                alignSelf: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #E0E0E0',
                                                                width: '97%',
                                                                marginTop: 10,
                                                                marginLeft: -8
                                                            }
                                                        }}
                                                        value={invoiceStartDate}
                                                        onChange={handleInvoiceStartDate}
                                                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                        format="MM/DD/YYYY"
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, paddingBottom: 20, borderTop: '1px dashed #E0E0E0', marginBottom: 5 }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginTop: 15 }} variant='h3'>WHERE</Typography>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}
                                        spacing={2}

                                    >
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 19 }} required>
                                                Promotion Location
                                            </FormLabel>
                                            <OutlinedInput
                                                twoline='true'
                                                value={promoLocation}
                                                readOnly
                                                onClick={() => handleClick('location')}
                                                className={classes.outlined}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleClick('location')}>
                                                            {anchorloc ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {anchorloc &&
                                                <NestedDropdown head='Promotion Location' id={'locations'} data={props.locations} expand={expanded} onSubmit={handleCheckedLoc}
                                                    prevalue={selectedLoc} selected={selectedLocItems} type='loc_filter' />
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: -2, marginRight: 60 }} required>
                                                Product Line
                                            </FormLabel>
                                            <MultiSelectDropdown onChange={handleProductLine} data={productLine && productLine.length > 0 ? productLine : []} listArray={promoDivisionArray} />
                                        </Grid>
                                        <Grid
                                            item
                                            md={3.7}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        // style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: -2, marginRight: 60 }} required>
                                                Model Year
                                            </FormLabel>
                                            <MultiSelectDropdown onChange={handleModelYear} data={modelYear && modelYear.length > 0 ? modelYear : []} listArray={promoModelYearArray} />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3.5}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}

                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} required>
                                            Promotion Currency
                                        </FormLabel>
                                        <Select
                                            disableUnderline
                                            value={promoCurrency}
                                            onChange={(value) => handlePromoWhere(value, 'currency')}
                                            displayEmpty
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            classes={{
                                                selectMenu: classes.selectedItem
                                            }}
                                            className={clsx({
                                                [classes.select]: true
                                            })}
                                            style={{ textTransform: 'capitalize' }}
                                        >

                                            {currency && currency.length > 0 && currency
                                                .map((item) => {
                                                    return (
                                                        <MenuItem value={item} key={item} style={{ textTransform: 'capitalize' }}>
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                })}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                    style={{ marginTop: 15, paddingBottom: 20, borderTop: '1px dashed #E0E0E0' }}

                                >
                                    <Typography className={classes.fontSetup} style={{ marginTop: 15 }} variant='h3'>HOW</Typography>
                                    <Grid
                                        container
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 15 }}

                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }} >Stackable Promotion</FormLabel>
                                            <div style={{ display: 'flex' }}>
                                                <Typography style={{ marginLeft: 5 }}>No</Typography>
                                                <span className={classes.toggleStackableButton} >
                                                    <Switch
                                                        checked={stackablePromotion}
                                                        onClick={handleStackableToggle}
                                                        disabled={false} />
                                                </span>
                                                <Typography>Yes</Typography>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                            style={{ marginTop: 15 }}

                                        >
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>Promotion By</FormLabel>
                                            <div style={{ display: 'flex' }}>
                                                <Typography style={{ marginLeft: 5 }}>Dealers</Typography>
                                                <span className={classes.toggleButton} >
                                                    <Switch
                                                        checked={promotionBy}
                                                        onClick={handlePromotionByToggle}
                                                        disabled={false} />
                                                </span>
                                                <Typography>Distributor</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: 30 }}

                                    >
                                        {/* {stackablePromotion &&
                                        <div className={classes.arrowSelectBox}>
                                            <List />
                                            <FormLabel classes={{ root: classes.fontSetting }} style={{ marginBottom: 15 }}>
                                                Promotion Scenario
                                            </FormLabel>
                                            <PromoMultiSelect id='promotions-scenario' onChange={(value) => handleFilters(value, 'promotions_scenario')}
                                                options={[]} />
                                        </div>
                                        } */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Card>
            </div >
        </>
    );

};

const mapStateToProps = state => {
    return {
        filterData: state.promotionData.promoFilters,
        configData: state.calculationAnalysisData.budgetData,
        locationData: state.promotionData.promoLocationFilters,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFilters: () => dispatch(getMaterialFilters()),
        getConfigData: () => dispatch(getBudgetingConfig()),
        //  getLocationData: (table, data) => dispatch(getModels(table, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioSetting);