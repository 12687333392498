import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { history } from "../../../../../../../components/Helpers";
import '../BSNavbar.css';
import { getProfile } from "../../../../../../../redux/actions";
import SettingsIcon from '@mui/icons-material/Settings';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    navBgColor: {
        backgroundColor: theme.palette.primary.main
    },
    customDropdown: {
        '& .dropdown-toggle::after': {
            display: 'none !important',
        },
    },
    arrow: {
        color: 'black',
        marginBottom: -5,
    }
}));

const handleClick = (pge) => {
    history.push(pge)
}

const menuItems = [
    { 'Dashboard': '/dashboard' },
    {

        'Your WorkSpace': [
            // { 'Scenario List': '/#' },
            // { 'Create New Scenario': '/#' },
            { 'Scenario Comparison': '/#', icon: <BalanceOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },

        ]
    },
    {
        'Configuration': [
            { 'Grid': '/#', icon: <GridOnOutlinedIcon style={{ marginBottom: -7, marginRight: 6, padding: 2 }} /> },
            { 'Stickers Bank': '/promotion-sticker-setup', icon: <SellOutlinedIcon style={{ marginBottom: -7, marginRight: 6, padding: 1 }} /> },
            // { 'Business Rules': '/#' },
            { 'Access & User Management': '/#', icon: <SettingsIcon style={{ marginBottom: -7, marginRight: 6, padding: 2 }} /> },
        ]
    },
    {
        'Approval Flow': [
            { 'Pending Scenario Approval': '/#', icon: <VerifiedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Files Generator': '/#', icon: <CloudDownloadOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Takes Rate Approval': '/#', icon: <NoteAddOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
        ]
    },
    {
        'Reporting': [
            { 'Current Running Promotions': '/#', icon: <EqualizerOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
            { 'Elasticities': '/#', icon: <TimelineOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} /> },
        ]
    },
    {
        'Utilities': [

        ]
    },

]

const subMenuItems = [
    {
        'Budgeting': [
            { 'Global Setup': '/promotion-global-setup' },
            { 'Promotion Setup': '/promotion-setup' },
            { 'Costing Simulation': '/costing-simulation' },
            { 'Costing Postings': '/costing-postings' },
            { 'Costing Approvals': '/costing-approvals' }
        ],
        icon: <LocalAtmOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    },
    {
        'Analytics': [
            { 'Promotion Type': '/monthly-prom-type' },
            { 'Cost to Revenue': '/cost-to-revenue' },
            { 'Cost Allocation': '/cost-allocation' },
            { 'Regional Analysis': '/geographical-chart-us' },
            { 'Market Share': '/market-share' },
            { 'Time to Market': '/time-to-market' }
        ],
        icon: <AnalyticsOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    },

]

const subScenarioItems = [
    {
        'Scenario List': [
            { 'Sand Box': '/#' },
            { 'Approved Scenarios': '/#' },
            { 'History': '/#' },
        ],
        icon: <ListIcon style={{ marginBottom: -6, marginRight: 6 }} />
    },
    {
        'Create New Scenario': [
            { 'Manual': '/promotion-scenario-setup' },
            { 'From Recommendor': '/#' },
        ],
        icon: <DifferenceOutlinedIcon style={{ marginBottom: -6, marginRight: 6 }} />
    }
]

const subBusinessItems = [
    {
        'Business Rules': [
            // { 'Config Tables': '/#' },
            { 'Elasticity Definition': '/#' },
            { 'Decision Tree': '/#' },
        ], icon: <BookOutlinedIcon style={{ marginBottom: -5, marginRight: 6, padding: 2 }} />
    }
]

const subMasterItems = [
    {
        'Config Tables': [
            { 'Dropdown Designer': '/promo-configuration' },
            { 'Promotion Mapping': '/promo-mapping' },
        ]
    },
    {
        'Master Data': [
            { 'Material Master': '/material-master-promo' },
            { 'Coverage Cost': '/coverage-cost' },
            { 'Finance Cost': '/finance-cost' },
            { 'Promotion Territory': '/promotion-territory' },
            { 'Sales Forecast - Upload': '/sales-forecast-main' }
        ]
    }
]

const PromotionSticker = (props) => {
    const classes = useStyles();

    return (
        <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", display: 'flex', justifyContent: 'space-between', fontSize: '16px', margin: 'auto' }}
            navbarScroll
        >
            {menuItems.map((item, i) => {
                if (typeof Object.values(item)[0] === 'string')
                    return <Nav.Link onClick={(() => { handleClick(Object.values(item)[0]) })}
                        style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }} >
                        {item.icon}
                        {Object.keys(item)[0]}</Nav.Link>
                else {
                    return (
                        <NavDropdown title={Object.keys(item)[0]}
                            style={{ borderRight: i !== menuItems.length - 1 ? '1px solid lightgrey' : 'none ' }}>
                            {Object.values(item)[0].map(e => {
                                return (
                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })} >
                                        {e.icon}
                                        {Object.keys(e)[0]}
                                    </NavDropdown.Item>
                                )
                            })}
                            {Object.keys(item)[0] == 'Utilities' && subMenuItems.map(item => {
                                return (
                                    <NavDropdown title={
                                        <>
                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                        </>
                                    } className={classes.customDropdown}
                                        style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '150px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                            {Object.keys(item)[0] == 'Your WorkSpace' && subScenarioItems.map(item => {
                                return (
                                    <NavDropdown title={
                                        <>
                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                        </>
                                    }
                                        className={classes.customDropdown}
                                        style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '230px' }}>
                                        {Object.values(item)[0].map(e => {
                                            return (
                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                    {Object.keys(e)[0]}
                                                </NavDropdown.Item>
                                            )

                                        })}
                                    </NavDropdown>
                                )
                            })}
                            {Object.keys(item)[0] == 'Configuration' && subBusinessItems.map(item => {
                                return (
                                    <>
                                        <NavDropdown title={
                                            <>
                                                <span style={{ color: 'black' }}>{item.icon}</span>
                                                <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                            </>
                                        } className={classes.customDropdown}
                                            style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '140px' }}>
                                            {Object.values(item)[0].map(e => {
                                                return (
                                                    <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                        {Object.keys(e)[0]}
                                                    </NavDropdown.Item>
                                                )

                                            })}
                                            {subMasterItems.map(item => {
                                                return (
                                                    <NavDropdown title={
                                                        <>
                                                            <span style={{ color: 'black' }}>{item.icon}</span>
                                                            <span style={{ color: 'black' }}>{Object.keys(item)[0]}</span>
                                                            <ArrowDropDownIcon className={classes.arrow}></ArrowDropDownIcon>
                                                        </>
                                                    } style={{ marginLeft: '16px', marginRight: '16px', borderRadius: '10px', marginBottom: '5px', minWidth: '140px' }}>
                                                        {Object.values(item)[0].map(e => {
                                                            return (
                                                                <NavDropdown.Item onClick={(() => { handleClick(Object.values(e)[0]) })}>
                                                                    {Object.keys(e)[0]}
                                                                </NavDropdown.Item>
                                                            )
                                                        })}
                                                    </NavDropdown>
                                                )
                                            })}
                                        </NavDropdown>
                                    </>
                                )
                            })}
                        </NavDropdown>
                    )

                }

            })}

        </Nav >

    );
};


const mapStateToProps = (state) => {
    return {
        profileData: state.profileData.profileData,
        helpDeskData: state.HelpDeskData.HelpDeskData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfile: () => dispatch(getProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSticker);