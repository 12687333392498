import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography, useMediaQuery, IconButton, Fab,
    Table, TableCell, TableHead, TableRow, TableContainer, TableBody, TablePagination, Tooltip
} from '@mui/material';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Edit, DeleteForever, AddBoxOutlined, LibraryAdd } from '@mui/icons-material';
import { getFormulasList, getFormulaDetails, submitFormula } from '../../redux/actions';
import secureLocalStorage from 'react-secure-storage';
import { NavLink as RouterLink } from 'react-router-dom';
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        marginTop: 10
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    addColor: {
        color: 'black',
        marginRight: 20,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        // position:'absolute',
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));
const FormulaLabMain = props => {

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [apiResponse2, setApiResponse2] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    useEffect(() => {
        props.getFormulasList(1, 10);
    }, []);
    useEffect(() => {
        if (props.queryListData && props.queryListData.records && props.queryListData.records.length > 0) {
            setApiResponse2(props.queryListData.records);
        }
    }, [props.queryListData]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getFormulasList(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getFormulasList(1, event.target.value);
    };

    const handleEdit = (id, item) => {
        props.getFormulaDetails(id);
        localStorage.setItem('formula-details', JSON.stringify(item));
    }
    function viewFormula(item) {
        localStorage.setItem('formula-details', JSON.stringify(item))
        history.push({
            pathname: '/formula-lab/view-formula-lab/' + item.ID,
            id: item.ID
        });
    }


    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary' style={{ marginTop: 10 }} > Formula Lab</Typography>
                {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('post_formula-' + sessionStorage.getItem('application')) &&
                    <div className={clsx({
                        [classes.row]: isDesktop
                    })} >
                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            component={CustomRouterLink}
                            to='/formula-lab/add-formula-lab'
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                    </div>
                }
            </div>
            {props.queryListData && props.queryListData.records && props.queryListData.records.length > 0 ?
                <TableContainer style={{ marginTop: "-1.6rem" }}>
                    <Table >
                        <TableHead >
                            <TableRow >
                                <TableCell align='center' className={classes.tabHead}>Formula ID</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Formula Name</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Formula Expression</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Application Type</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Source Data Type</TableCell>
                                <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }}>
                            {apiResponse2
                                .filter(item => item.application_type === sessionStorage.getItem('application'))
                                .map(item => {
                                    return (
                                        <StyledTableRow key={item.id} >
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.ID}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.formula_name}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
                                                {item.formula}
                                            </TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.application_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }} onClick={() => viewFormula(item)} style={{ cursor: 'pointer', textTransform: 'capitalize' }}>{item.source_data_type}</TableCell>
                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula-' + sessionStorage.getItem('application')) &&
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => handleEdit(item.ID, item)}
                                                            size="large">
                                                            <Edit color="disabled" style={{ fontSize: 20 }} />
                                                        </IconButton>}
                                                    {secureLocalStorage.getItem('dYtz') && secureLocalStorage.getItem('dYtz').role && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights && secureLocalStorage.getItem('dYtz').role.rights.includes('put_formula-' + sessionStorage.getItem('application')) &&
                                                        <IconButton
                                                            onClick={() => props.deleteFormula(item, item.ID)}
                                                            classes={{ root: classes.IconButton }}
                                                            size="large">
                                                            <DeleteForever />
                                                        </IconButton>}
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        component="div"
                        count={props.queryListData && props.queryListData.total_record ? props.queryListData.total_record : 0}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        classes={{
                            caption: classes.caption,
                            root: classes.paginationRoot
                        }}
                    />

                </TableContainer>
                :
                <Typography variant='h4'>
                    There is no data to show now.
                </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        queryListData: state.FormulaLabData.formulaListData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFormulasList: (pagination, limit) => dispatch(getFormulasList(pagination, limit)),
        getFormulaDetails: (id) => dispatch(getFormulaDetails(id)),
        deleteFormula: (data, id) => dispatch(submitFormula(data, id, 'delete'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulaLabMain);