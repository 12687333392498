import {
  FETCH_START,
  FETCH_END,
  FORECAST_LEVEL_DATA_SUCCESS,
  FORECAST_LEVEL_BY_ID,
  FORECAST_LEVEL_DROPDWON_SUCCESS,
  SALES_FORECAST_FORMAT_LIST_SUCCESS,
  DYNAMIC_QUERY_SALES_FORECAST_DETAILS_SUCCESS,
  SALES_FORECAST_QUERY_SINGLE_SUCCESS,
  SALES_FORECAST_QUERY_FIELDS_SUCCESS,
  DYNAMIC_QUERY_FORECAST_FIELDS_SUCCESS,
  SALES_FORECAST_UPLOAD_SUCCESS,
  DISPLAY_FORECAST_SUCCESS,
  SALES_FORECAST_DATA_SUCCESS
} from "./ActionTypes";
import axios from "../../../axios.conf";
import { history } from "../../../components/Helpers";
import { toast } from "react-toastify";
import { ServerError } from "../../../library/constants/constants";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

// Historical Sales Upload
export const uploadHistoricalSalesFile = (file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadHistoricalSalesFileApi(file)
      .then((response) => {
        if (response.status === 200) {
          //window.location.reload();
          dispatch(fetchEnd());
          toast.success("File has been successfully uploaded.");
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const uploadHistoricalSalesFileApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  result = axios.post("upload-sales-history", file, { headers: headers });
  return result;
};
//  get Forecast Level
export const getAllForecastLevelData = () => {
  return (dispatch) => {
    getAllForecastLevelDataApi().then((response) => {
      dispatch(getAllForecastLevelDataSuccess(response.data.response));
    });
  };
};

const getAllForecastLevelDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-forecast-level-all?page=1&&limit=0", {
    headers: headers,
  });
  return result;
};

export const getAllForecastLevelDataSuccess = (data) => {
  return {
    type: FORECAST_LEVEL_DATA_SUCCESS,
    data,
  };
};
export const createForecastLevel = (data, id, type) => {
  return (dispatch) => {
    createForecastLevelAPI(data, id, type)
      .then((response) => {
        history.push("/forecasting-config");
        dispatch(getAllForecastLevelData());
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};

const createForecastLevelAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "edit") {
    result = axios.put("sales-forecast-level/" + id, data, {
      headers: headers,
    });
  } else if (type === "delete") {
    result = axios.delete("sales-forecast-level/" + id, { headers: headers });
  } else {
    result = axios.post("sales-forecast-level", data, { headers: headers });
  }
  return result;
};
export const getForecastLevelById = (id) => {
  return (dispatch) => {
    getForecastLevelByIdAPI(id)
      .then((response) => {
        dispatch(getForecastLevelByIdSuccess(response.data.response));
        history.push("/forecasting-config/edit-config/" + id);
      })
      .catch((error) => { });
  };
};

export const viewForecastLevelById = (id) => {
  return (dispatch) => {
    getForecastLevelByIdAPI(id)
      .then((response) => {
        dispatch(getForecastLevelByIdSuccess(response.data.response));
        history.push("/forecasting-config/view-config/" + id);
      })
      .catch((error) => { });
  };
};

const getForecastLevelByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-forecast-level?id=" + id, { headers: headers });
  return result;
};

export const getForecastLevelByIdSuccess = (data) => {
  return {
    type: FORECAST_LEVEL_BY_ID,
    data,
  };
};

//  get forecast config drop down value
export const getAllForecastLevelDropDwon = () => {
  return (dispatch) => {
    getAllForecastLevelDropDwonApi().then((response) => {
      dispatch(getAllForecastLevelDropDwonSuccess(response.data.response));
    });
  };
};

const getAllForecastLevelDropDwonApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-forecast-fields", { headers: headers });
  return result;
};

export const getAllForecastLevelDropDwonSuccess = (data) => {
  return {
    type: FORECAST_LEVEL_DROPDWON_SUCCESS,
    data,
  };
};

//predict sales
export const predictSales = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    predictSalesAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/forecasting-sales");
        toast.success("Forecasting is saved.");
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const predictSalesAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("predict-sales", data, { headers: headers });
  return result;
};
export const displayForecast = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    displayForecastAPI(data, id, type)
      .then((response) => {
        dispatch(displayForecastSuccess(response.data.response));
        history.push("/display-forecast-result");
        toast.success("Success");
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const displayForecastAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("predicted-sales", data, { headers: headers });
  return result;
};
export const displayForecastSuccess = (data) => {
  return {
    type: DISPLAY_FORECAST_SUCCESS,
    data,
  };
};
export const buildForecast = (data, type, item) => {
  return (dispatch) => {
    dispatch(fetchStart());
    buildForecastAPI(data, type, item)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //dispatch(calculationSimulationFailed(error));
        //toast.error(error.response.data.complete_error_message)
      });
  };
};
const buildForecastAPI = (data, type, item) => {
  for (var propName in data["filters"]) {
    if (
      data["filters"][propName] === "" ||
      data["filters"][propName] === null ||
      data["filters"][propName] === undefined ||
      data["filters"][propName].length === 0 ||
      data["filters"][propName] ===
      "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data["filters"][propName] === "1970-01-01T00:00:00.000Z" ||
      data["filters"][propName] === ","
    ) {
      delete data["filters"][propName];
    }
  }
  if (
    data["filters"]["billing_date"][0].length == 0 ||
    data["filters"]["billing_date"][1].length == 0
  ) {
    delete data["filters"]["billing_date"];
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("copy-sales-to-forecast", data, { headers: headers });
  return result;
};
// getSalesForecastFormatList -- get all format lists
export const getSalesForecastFormatList = () => {
  return (dispatch) => {
    getSalesForecastFormatListAPI()
      .then((response) => {
        dispatch(getSalesForecastFormatListSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const getSalesForecastFormatListAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format?app-functionality=SalesForecastDataFormat",
    { headers: headers }
  );
  return result;
};

export const getSalesForecastFormatListSuccess = (data) => {
  return {
    type: SALES_FORECAST_FORMAT_LIST_SUCCESS,
    data,
  };
};

//get dynamic query for sales forecast--- view query
export const getDynamicQuerySalesForecast = (id) => {
  return (dispatch) => {
    getDynamicQuerySalesForecastAPI(id).then((response) => {
      dispatch(getDynamicQuerySalesForecastSuccess(response.data.response));
      // if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'sales_data') {
      //     dispatch(getDynamicQuerySalesDataFields());
      // } else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'financial_postings') {
      //     dispatch(getDynamicQueryFields());
      // }
      // else if (response.data && response.data.response && response.data.response.table_name &&
      //     response.data.response.table_name === 'purchase_data') {
      //     dispatch(getPurchaseDataFields());
      // } else {
      //     dispatch(getCalculationSimulationDataFields());
      // }
    });
  };
};

const getDynamicQuerySalesForecastAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-forecast-data-format?format_id=" + id, {
    headers: headers,
  });
  return result;
};
export const getDynamicQuerySalesForecastSuccess = (data) => {
  return {
    type: DYNAMIC_QUERY_SALES_FORECAST_DETAILS_SUCCESS,
    data,
  };
};
// for sales forecast viewformat
export const getDynamicQueryForecastFields = () => {
  return (dispatch) => {
    getDynamicQueryForecastFieldsAPI().then((response) => {
      dispatch(getDynamicQueryForecastFieldsSuccess(response.data.response));
    });
  };
};

const getDynamicQueryForecastFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("financial-posting-fields", { headers: headers });
  return result;
};

export const getDynamicQueryForecastFieldsSuccess = (data) => {
  localStorage.setItem("dq_table_name", data.table_name);
  return {
    type: DYNAMIC_QUERY_FORECAST_FIELDS_SUCCESS,
    data,
  };
};

export const fetchSalesForecastQuerySingle = (id) => {
  return (dispatch) => {
    fetchSalesForecastQuerySingleAPI(id)
      .then((response) => {
        dispatch(fetchSalesforecastQuerySingleSuccess(response.data.response));
        history.push("/historical-sales-upload/edit-format/" + id);
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};

const fetchSalesForecastQuerySingleAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "upload-format/" + id + "?app-functionality=SalesForecastDataFormat",
    { headers: headers }
  );
  return result;
};

export const fetchSalesforecastQuerySingleSuccess = (data) => {
  return {
    type: SALES_FORECAST_QUERY_SINGLE_SUCCESS,
    data,
  };
};

//upload new sales data
export const salesForecastDataUploadNew = (file, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    salesForecastDataUploadNewAPI(file, id, type)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadSalesForecastFileSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //if (error.response && error.response.data && error.response.data.complete_error_message)
        //toast.error(error.response.data.complete_error_message)
      });
  };
};

//update sales forecast format
export const updateSalesForecastFormatQuery = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateSalesForecastFormatQueryApi(id, data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          if (type == "SalesForecastupload") {
            history.push("/sales-forecast-upload")
          }
          else {
            history.push("/historical-sales-upload");
          }
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateSalesForecastFormatQueryApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put(
    "upload-format/" + id + "?app-functionality=SalesForecastDataFormat",
    data,
    { headers: headers }
  );
  return result;
};

const salesForecastDataUploadNewAPI = (file, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "multipart/form-data;",
  };
  var result;
  if (type == "Sales") result = axios.post("upload-file?format_id=" + id, file, { headers: headers });
  else result = axios.post("sales-forecast-data-upload?format_id=" + id, file, { headers: headers });

  // var result; = axios.post("sales-forecast-data-upload?format_id=" + id, file, {
  //   headers: headers,

  return result;
};
export const uploadSalesForecastFileSuccess = (data) => {
  return {
    type: SALES_FORECAST_UPLOAD_SUCCESS,
    data,
  };
};

//load default forecast fields -- get all fields
export const loadSalesForecastDataFormatFields = () => {
  return (dispatch) => {
    loadSalesForecastDataFormatFieldsAPI()
      .then((response) => {
        dispatch(
          loadSalesForecastDataFormatFieldsSuccess(response.data.response)
        );
      })
      .catch((error) => {
        //toast.error('Error');
      });
  };
};
// delete sales forecast format
export const deleteSalesForecastDataQuery = (id, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteSalesForecastDataQueryApi(id, page)
      .then((response) => {
        if (page == 'salesForecastUpload')
          history.push("/sales-forecast-upload");
        else
          history.push("/historical-sales-upload");
        toast.success(response.data.response);
        dispatch(getSalesForecastFormatList());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteSalesForecastDataQueryApi = (id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;

  result = axios.delete("upload-format/" + id, { headers: headers });
  return result;
};

const loadSalesForecastDataFormatFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
    "Content-Type": "application/x-www-form-urlencoded",
  };
  var result;
  result = axios.get(
    "upload-format-fields?app-functionality=SalesForecastDataFormat",
    { headers: headers }
  );
  return result;
};

export const loadSalesForecastDataFormatFieldsSuccess = (data) => {
  return {
    type: SALES_FORECAST_QUERY_FIELDS_SUCCESS,
    data,
  };
};

// post Sales forecast Data Query
export const postSalesForecastDataQuery = (data, type) => {
  return (dispatch) => {
    postSalesForecastDataQueryAPI(data).then((response) => {
      if (type == "SalesForecastupload") {
        history.push("/sales-forecast-upload")
      }
      else {
        history.push("/historical-sales-upload");
      }
    });
  };
};

const postSalesForecastDataQueryAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "upload-format?app-functionality=SalesForecastDataFormat",
    data,
    { headers: headers }
  );
  return result;
};

export const getSalesForecastMainData = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getSalesForecastMainDataApi().then((response) => {
      dispatch(fetchEnd());
      dispatch(getSalesForecastMainDataSuccess(response.data.response));
    });
  };
};

const getSalesForecastMainDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-forecast-data?page=1&limit=0", {
    headers: headers,
  });
  return result;
};

export const getSalesForecastMainDataSuccess = (data) => {
  return {
    type: SALES_FORECAST_DATA_SUCCESS,
    data,
  };
};
