import React, { useEffect } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs,
    Card, Table, TableCell, TableHead, TableRow,
    TableContainer, TableBody, TextField, OutlinedInput,
    FormLabel, Dialog, DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    getCotXrefFormatFields, updateCotXrefFormatData, getCotXrefFormatDataById
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    paper: { minWidth: window.screen.width * .50 }
}));

const CotXREFEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [membershipOwnerId, setMembershipOwnerId] = React.useState('');
    const [membershipOwnerName, setMembershipOwnerName] = React.useState('');
    const [membershipOwnerClassofTrade, setMembershipOwnerClassofTrade] = React.useState('');
    const [imaClassOfTrade, setImaClassOfTrade] = React.useState('');
    const [imaClassOfTradeDescription, setImaClassOfTradeDescription] = React.useState('');
    const [cotXrefData, setCotXrefData] = React.useState(localStorage.getItem('cot-xref-query') ? JSON.parse(localStorage.getItem('cot-xref-query')) : []);
    const [defaultValue, setDefaultValue] = React.useState({});
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.getCotXrefFormatFields();
        props.getCotXrefFormatDataById(cotXrefData.id)
    }, []);

    useEffect(() => {
        if (props.cotXrefSingleData) {
            setQueryObject(props.cotXrefSingleData.format)
            if (props.cotXrefSingleData.default_values != null) {
                setDefaultValue(props.cotXrefSingleData.default_values)
            }
            setQueryName(props.cotXrefSingleData.format.format_name)
            setImaClassOfTrade(props.cotXrefSingleData.format.ima_class_of_trade)
            setImaClassOfTradeDescription(props.cotXrefSingleData.format.ima_class_of_trade_description)
            setMembershipOwnerClassofTrade(props.cotXrefSingleData.format.channel_partner_class_of_trade)
            setMembershipOwnerId(props.cotXrefSingleData.format.channel_partner_id)
            setMembershipOwnerName(props.cotXrefSingleData.format.channel_partner_name)
        }

    })

    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    function handleClearAll() {
        for (var propName in queryObject) {
            if (propName != 'format_name') {
                queryObject[propName] = ''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
        setQueryObject({ ...queryObject });
        setImaClassOfTrade('')
        setImaClassOfTradeDescription('')
        setMembershipOwnerClassofTrade('')
        setMembershipOwnerId('')
        setMembershipOwnerName('')
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === '' || queryObject[propName] === null || queryObject[propName] === undefined) {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        props.onSubmit(cotXrefData.id, { ...queryObject, 'format_name': queryName, 'default_values': defaultValue });
    }
    const requiredFields = [
        'ima_class_of_trade', 'ima_class_of_trade_description', 'channel_partner_class_of_trade', 'channel_partner_id',
        'channel_partner_name'
    ]
    var replaceChars = {
        "_": " ",
        "uom": "UoM",
        "id": "ID",
        "ima": "IMA"
    };

    function handleCancelDialog() {
        history.push('/cot-xref/cot-xref-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        }
        else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if (item === 'ima_class_of_trade') {
            setImaClassOfTrade(event.target.value);
        }
        if (item === 'ima_class_of_trade_description') {
            setImaClassOfTradeDescription(event.target.value);
        }
        if (item === 'channel_partner_class_of_trade') {
            setMembershipOwnerClassofTrade(event.target.value);
        }
        if (item === 'channel_partner_id') {
            setMembershipOwnerId(event.target.value);
        }
        if (item === 'channel_partner_name') {
            setMembershipOwnerName(event.target.value);
        }

    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if (item === 'ima_class_of_trade') {
            setImaClassOfTrade(event.target.value);
        }
        if (item === 'ima_class_of_trade_description') {
            setImaClassOfTradeDescription(event.target.value);
        }
        if (item === 'channel_partner_class_of_trade') {
            setMembershipOwnerClassofTrade(event.target.value);
        }
        if (item === 'channel_partner_id') {
            setMembershipOwnerId(event.target.value);
        }
        if (item === 'channel_partner_name') {
            setMembershipOwnerName(event.target.value);
        }
    };
    return (
        <div className={classes.root} >
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/cot-xref/cot-xref-excel-upload'
                >
                    COT XREF
                </Link>
                <Typography color="textPrimary" variant='h4'> Edit COT XREF</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.salesInvoiceQuery ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.salesInvoiceQuery
                                                        .filter(item => item != 'id' && item != 'format_name' && item != 'organization_id' && item != 'created_by_id' && item != 'updated_by_id' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                        {requiredFields.includes(item) ? <p>{item.replace(/_|uom|id|ima/g, function (match) { return replaceChars[match] })}<span style={{ color: 'red' }}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item] ? queryObject[item] : ''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('cot-xref-query'))[item] ? JSON.parse(localStorage.getItem('cot-xref-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell classes={{ root: classes.fontSetting }} style={{ width: 70, paddingLeft: 10 }}>
                                                                        <TextField
                                                                            value={defaultValue[item] ? defaultValue[item] : ''}
                                                                            type="string"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange2(e, item)}
                                                                            defaultValue={JSON.parse(localStorage.getItem('cot-xref-query'))[item] ? JSON.parse(localStorage.getItem('cot-xref-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                        disabled={(membershipOwnerClassofTrade || defaultValue['channel_partner_class_of_trade']) && (membershipOwnerId || defaultValue['channel_partner_id']) && (membershipOwnerName || defaultValue['channel_partner_name'])
                            && (imaClassOfTrade || defaultValue['ima_class_of_trade']) && (imaClassOfTradeDescription || defaultValue['ima_class_of_trade_description']) ? false : true}
                    >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateCotXrefFormatData(id, data)),
        getCotXrefFormatFields: () => dispatch(getCotXrefFormatFields()),
        getCotXrefFormatDataById: (id) => dispatch(getCotXrefFormatDataById(id))
    }
}

const mapStateToProps = state => {
    return {
        salesInvoiceQuery: state.customerData.cotXrefFieldsData,
        cotXrefSingleData: state.customerData.cotXrefSingleData
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CotXREFEditQuery);