import React, { useEffect } from 'react';
import {
    Card, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableBody, Typography, CardHeader
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import Moment from 'moment';
import { getDefaultValuesAllDataNew } from '../../../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    leftAlign: {
        textAlign: 'left',
        paddingLeft: 28,
        fontSize: theme.typography.h4.fontSize
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 26,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            paddingLeft: 26,
            marginRight: -15
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const CalculationRules = props => {
    const classes = useStyles();
    const { className } = props;
    const [calculationStore, setCalculationStore] = React.useState([]);
    const [keyFigureArrayMXSColumns, setKeyFigureArrayMXSColumns] = React.useState([]);
    const [attributeArrayMXSColumns, setAttributeArrayMXSColumns] = React.useState([]);
    const [formulaArrayMXSColumns, setFormulaArrayMXSColumns] = React.useState([]);
    const [contractData, setContractData] = React.useState([]);
    const [mappingValuesSet, setMappingValuesSet] = React.useState({});
    const [attributeNameData, setAttributeNameData] = React.useState({});
    const headArr1 = ['Calculation Type', 'Formula Type', 'Key Figure Name', 'Formula', 'From', 'Maximum Amount', 'Rate', 'Rate Type', 'UoM', 'Start Date', 'End Date']
    const headArr2 = ['Key Figure Name', 'Rate Type', 'UoM', 'From', 'Rate', 'Start Date', 'End Date']
    const calculationTypeData = ['Tier', 'Rebate', 'Both']
    const calculationTypeDataMXS = ['Tier', 'Rebate']

    useEffect(() => {
        if (props.data)
            setContractData(props.data)
        if (props.data && props.data.calculation_method && props.data.calculation_method === 'Multi Axis') {
            var calcRules = props.data.calculation_rules;

            if (props.data && props.data.calculation_rules && props.data.calculation_method && props.data.calculation_method === 'Multi Axis') {
                const order = ({ attribute_key_values }) => attribute_key_values && attribute_key_values.find(o => 'priority' in o)['priority'];
                props.data.calculation_rules && props.data.calculation_rules.length > 0 && props.data.calculation_rules
                    .sort((a, b) => order(a) - order(b))
                    .map((itemMain, index) => {
                        var tempKF = 0;
                        var tempA = 0;
                        var tempF = 0;
                        var tempKFArr = [];
                        var tempAArr = [];
                        var tempFArr = [];
                        itemMain.type === 'Tier' && itemMain.attribute_key_values && itemMain.attribute_key_values
                            .map((itemX, i) => {
                                if (props.data.incentive_rate_type == 'Conditional Attribute') {
                                    itemX.attribute_conditions && itemX.attribute_conditions
                                        .map((itemY, i) => {
                                            if (itemY.attribute_type === 'Key Figure') {
                                                tempKF = tempKF + 1;
                                                var tempA = {
                                                    key: itemY.attribute_name,
                                                    value: itemY.attribute_value
                                                }
                                                tempKFArr.push(tempA);
                                            }
                                            else if (itemY.attribute_type === 'Formula') {
                                                tempF = tempF + 1;
                                                var tempC = {
                                                    key: itemY.attribute_name,
                                                    value: itemY.attribute_value
                                                }
                                                tempFArr.push(tempC);
                                            }
                                        })
                                    if (itemX.attribute_name) {
                                        calcRules[index]['attribute_name'] = itemX.attribute_name;
                                        calcRules[index]['attribute_count'] = itemX.attribute_value;
                                    }


                                }
                                else {
                                    if (itemX.attribute_type === 'Key Figure') {
                                        tempKF = tempKF + 1;
                                        var tempA = {
                                            key: itemX.attribute_name,
                                            value: itemX.attribute_value
                                        }
                                        tempKFArr.push(tempA);
                                    } else if (itemX.attribute_type === 'Attribute') {
                                        tempA = tempA + 1;
                                        var tempB = {
                                            key: itemX.attribute_name,
                                            value: itemX.attribute_value
                                        }
                                        tempAArr.push(tempB);
                                    }
                                    else {
                                        if (itemX.attribute_type === 'Formula') {
                                            tempF = tempF + 1;
                                            var tempC = {
                                                key: itemX.attribute_name,
                                                value: itemX.attribute_value
                                            }
                                            tempFArr.push(tempC);
                                        }
                                    }
                                }

                            })
                        calcRules[index]['key_fig_api'] = tempKFArr;
                        calcRules[index]['attri_api'] = tempAArr;
                        calcRules[index]['form_api'] = tempFArr;

                    })

                setCalculationStore(calcRules)
            }

            calcRules.map(itemMain => {
                var tempKL = 0;
                var tempAL = 0;
                var tempFL = 0;
                if (itemMain.key_fig_api && itemMain.key_fig_api.length > tempKL) {
                    setKeyFigureArrayMXSColumns(itemMain.key_fig_api.length);
                }
                if (itemMain.attri_api && itemMain.attri_api.length > tempAL) {
                    setAttributeArrayMXSColumns(itemMain.attri_api.length);
                }
                if (itemMain.form_api && itemMain.form_api.length > tempFL) {
                    setFormulaArrayMXSColumns(itemMain.form_api.length);
                }
            })

        }
        else if (props.data && props.data.calculation_method && props.data.calculation_method != 'Multi Axis') {
            if (props.data && props.data.calculation_method == 'Chargeback calculation' || props.data.calculation_method == 'Price Difference') {
                setCalculationStore(props.data.calculation_rules);
            }
            else if (props.data && props.data.calculation_rules && props.data.calculation_rules.length > 0) {
                setCalculationStore(props.data.calculation_rules);
            }
            if ((props.data && props.data.calculation_method && (props.data.calculation_method === 'Tiered Volume' || props.data.calculation_method === 'Tiered Growth'))) {
                // const order = ({ attribute_key_values }) => attribute_key_values && attribute_key_values.find(o => 'attribute_value' in o)['attribute_value'];
                var ruleData = props.data.calculation_rules//.sort((a, b) => order(a) - order(b))
                setCalculationStore(ruleData)
            }
        }

    }, props.data);
    const [formulaListDataAPI, setFormulaListDataAPI] = React.useState([]);

    useEffect(() => {
        if (props.formulaListData && props.formulaListData.records) {
            setFormulaListDataAPI(props.formulaListData.records)
        }
    }, [props.formulaListData])
    useEffect(() => {
        if (props.dropdownAttributeData && props.dropdownAttributeData.records && props.dropdownAttributeData.records[0]) {
            if (props.dropdownAttributeData.records[0].field_id === "attribute_name") {
                setAttributeNameData(props.dropdownAttributeData.records[0].label_names);
            }
        }
    }, [props.dropdownAttributeData])
    useEffect(() => {
        if (props.dropdownDataCR && props.dropdownDataCR.records && props.dropdownDataCR.records[0]) {
            if (props.dropdownDataCR.records[0].field_id === 'key_figure_name') {
                setMappingValuesSet(props.dropdownDataCR.records[0].label_names);
            }
        }
    }, [props.dropdownDataCR])

    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <div className={classes.row} >
                    <CardHeader
                        title="CALCULATION RULES"
                        titleTypographyProps={{ variant: 'h3' }}
                    />
                    <SearchInput />
                </div>
                {contractData && calculationStore && calculationStore.length > 0 &&
                    <div className={classes.container}>
                        {(contractData.calculation_schema == 'Chargeback calculation') ?
                            <Grid container >
                                <Grid
                                    item
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    {calculationStore.length > 0 ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Value</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Acquisition Price</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Contract Price</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {calculationStore
                                                        .map((item, index) => {
                                                            return (
                                                                <StyledTableRow key={item.ID}>
                                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>
                                                                        {item.attribute_key_values ?
                                                                            item.attribute_key_values.map((item2, i) => {
                                                                                return attributeNameData && attributeNameData[item2.attribute_name] ? attributeNameData[item2.attribute_name] : item2.attribute_name.replace(/_/g, ' ');
                                                                            })
                                                                            :
                                                                            ''}
                                                                    </TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values.filter(item => item.attribute_value).map(e => e.attribute_value)}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}  >{item.attribute_key_values && item.attribute_key_values.filter(item => item.acquisition_price).map(e => e.acquisition_price.toFixed(2))}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values.filter(item => item.contract_price).map(e => e.contract_price.toFixed(2))}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Typography variant='h4'>
                                            There is no data to show now.
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            :
                            contractData && contractData.calculation_method === 'Price Difference' ?
                                <Grid container >
                                    <Grid
                                        item
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        {calculationStore.length > 0 ?
                                            <TableContainer>
                                                <Table>
                                                    <TableHead >
                                                        <TableRow >
                                                            <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Value</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Acquisition Price</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Contract Price</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody classes={{ root: classes.table }} >
                                                        {contractData && contractData.calculation_rules &&
                                                            contractData.calculation_rules
                                                                .map((item, index) => {
                                                                    return (
                                                                        <StyledTableRow key={item.ID}>
                                                                            <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>{attributeNameData && attributeNameData['material_number'] ? attributeNameData['material_number'] : 'Material Number'}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values.filter(item => item.attribute_value).map(e => e.attribute_value)}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >{item.attribute_key_values && item.attribute_key_values.filter(item => item.acquisition_price).map(e => e.acquisition_price.toFixed(2))}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values.filter(item => item.contract_price).map(e => e.contract_price.toFixed(2))}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                        </StyledTableRow>
                                                                    );
                                                                })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            :
                                            <Typography variant='h4'>
                                                There is no data to show now.
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                :
                                contractData && contractData.calculation_method && contractData.calculation_method == 'Flat Rate' && contractData.incentive_rate_type === 'Attribute' ?
                                    <Grid container >
                                        <Grid
                                            item
                                            xs={12}
                                            classes={{ root: classes.gridContainer }}
                                        >
                                            {contractData && contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                <TableCell classes={{ root: classes.leftAlign }}>Attribute Name</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Value</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>Formula Type</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}>Formula</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Key Figure Name</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody classes={{ root: classes.table }} >
                                                            {contractData && contractData.calculation_rules &&
                                                                contractData && contractData.calculation_rules
                                                                    .map((item, index) => {
                                                                        return (
                                                                            <StyledTableRow key={item.ID}>
                                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}> {item.attribute_key_values ?
                                                                                    item.attribute_key_values.map((item2, i) => {
                                                                                        return attributeNameData && attributeNameData[item2.attribute_name] ? attributeNameData[item2.attribute_name] : item2.attribute_name.replace(/_/g, ' ');
                                                                                    })
                                                                                    :
                                                                                    ''}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ? item.attribute_key_values[0].attribute_value : ''}</TableCell>
                                                                                <TableCell align='center'>{item.formula_type}</TableCell>
                                                                                <TableCell align='center'>{item.formula_id ?
                                                                                    props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                        return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                    }) : ''}
                                                                                </TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >
                                                                                    {
                                                                                        item.key_figure_names ?
                                                                                            item.key_figure_names.map(item => {
                                                                                                return mappingValuesSet && mappingValuesSet[item] ? mappingValuesSet[item] : item.replace(/_/g, ' ');

                                                                                            })
                                                                                            : ''}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                            </StyledTableRow>
                                                                        );
                                                                    })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                :
                                                <Typography variant='h4'>
                                                    There is no data to show now.
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    contractData && contractData.calculation_method && (contractData.calculation_method === 'Tiered Volume' || contractData.calculation_method === 'Tiered Growth') ?
                                        <Grid container >
                                            <Grid
                                                item
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            >
                                                {contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead >
                                                                <TableRow >
                                                                    {headArr1
                                                                        .filter(item => !((contractData.maximum_amount_period !== 'Payment Period - Tier') && item == 'Maximum Amount'))
                                                                        .map(item => {
                                                                            return (
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item}</TableCell>
                                                                            )
                                                                        })}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody classes={{ root: classes.table }} >
                                                                {contractData.calculation_rules
                                                                    .sort(function (a, b) {
                                                                        return calculationTypeData.indexOf(a.type) - calculationTypeData.indexOf(b.type);
                                                                    }).map((item, index) => {
                                                                        return (
                                                                            <StyledTableRow key={item.ID}>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}  >{item.type}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }}  >{item.formula_type}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                                    {
                                                                                        item.key_figure_names ?
                                                                                            item.key_figure_names.map((item2, i) => {
                                                                                                return <> {item.key_figure_names[i] &&
                                                                                                    mappingValuesSet && mappingValuesSet[item.key_figure_names[i]] ? mappingValuesSet[item.key_figure_names[i]] : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                                } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>;
                                                                                            })
                                                                                            :
                                                                                            ''
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >
                                                                                    {item && item.formula_id && item.formula_id ?
                                                                                        (formulaListDataAPI &&
                                                                                            formulaListDataAPI.length > 0 && formulaListDataAPI
                                                                                                .filter(item2 => item2['ID'] === item.formula_id)
                                                                                                .map(item2 => {
                                                                                                    return item2['formula_name']
                                                                                                })
                                                                                        )
                                                                                        : ''}
                                                                                </TableCell>

                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                                    {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ?
                                                                                        parseFloat(item.attribute_key_values[0].attribute_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                                    }
                                                                                </TableCell>
                                                                                {contractData.maximum_amount_period === 'Payment Period - Tier' &&
                                                                                    <TableCell align='center'>{parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                                                }
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.type != 'Rebate' ? item.rate_type : ''}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                            </StyledTableRow>
                                                                        );
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <Typography variant='h4'>
                                                        There is no data to show now.
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        contractData && contractData.calculation_method && contractData.calculation_method === 'Multi Axis' ?
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    classes={{ root: classes.gridContainer }}
                                                >
                                                    {calculationStore.length > 0 ?
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead >
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Calculation Type</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Formula Type</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Key Figure Name - Rebate</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Formula  Name- Rebate</TableCell>
                                                                    {contractData.incentive_rate_type === 'Conditional Attribute' &&
                                                                        <>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>Attribute Name</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}>Conditional Attribute Count</TableCell>
                                                                        </>
                                                                    }
                                                                    {Array.apply(null, {
                                                                        length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                    }).map((e, i) => (
                                                                        <>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >{contractData.incentive_rate_type === 'Conditional Attribute' ?
                                                                                'Conditional Key Figure Name' : 'Key Figure Name'}
                                                                            </TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >Key Figure Value</TableCell>
                                                                        </>
                                                                    ))}
                                                                    {Array.apply(null, {
                                                                        length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                    }).map((e, i) => (
                                                                        <>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >Attribute Name</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >Attribute Value</TableCell>
                                                                        </>
                                                                    ))}
                                                                    {Array.apply(null, {
                                                                        length: formulaArrayMXSColumns ? formulaArrayMXSColumns : 0
                                                                    }).map((e, i) => (
                                                                        <>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >{contractData.incentive_rate_type === 'Conditional Attribute' ?
                                                                                'Conditional Formula Name' : 'Formula Name'}</TableCell>
                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }}  >Formula Value</TableCell>
                                                                        </>
                                                                    ))}
                                                                    {contractData.maximum_amount_period === 'Payment Period - Tier' &&
                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }}>Maximum Amount</TableCell>
                                                                    }
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                                </TableHead>
                                                                <TableBody classes={{ root: classes.table }} >
                                                                    {calculationStore
                                                                        .sort(function (a, b) {
                                                                            return calculationTypeDataMXS.indexOf(a.type) - calculationTypeDataMXS.indexOf(b.type);
                                                                        }).map((item) => {
                                                                            return (
                                                                                <StyledTableRow key={item.ID}>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}  >  {item.type}</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.formula_type}</TableCell>
                                                                                    <TableCell align='center' style={{ textTransform: 'capitalize' }}>{
                                                                                        item.type === 'Rebate' &&
                                                                                            item.key_figure_names && item.key_figure_names && item.key_figure_names[0]
                                                                                            ?
                                                                                            mappingValuesSet && mappingValuesSet[item.key_figure_names[0]] ? mappingValuesSet[item.key_figure_names[0]] :
                                                                                                item.key_figure_names[0].replace(/_/g, ' ') : ''}
                                                                                    </TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{
                                                                                        item.type === 'Rebate' && item.formula_id && props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                            return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                        })
                                                                                    }</TableCell>
                                                                                    {contractData.incentive_rate_type === 'Conditional Attribute' &&
                                                                                        <>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.attribute_name ? (attributeNameData && attributeNameData[item.attribute_name]) ?
                                                                                                    attributeNameData[item.attribute_name] : item.attribute_name.replace(/_/g, ' ') : ''}
                                                                                            </TableCell>
                                                                                            <TableCell align='center'>
                                                                                                {item.attribute_count ? parseFloat(item.attribute_count).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
                                                                                            </TableCell>
                                                                                        </>
                                                                                    }
                                                                                    {Array.apply(null, {
                                                                                        length: keyFigureArrayMXSColumns ? keyFigureArrayMXSColumns : 0
                                                                                    }).map((e, i) => (
                                                                                        <>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.key_fig_api && item.key_fig_api[i] && item.key_fig_api[i].key &&
                                                                                                    mappingValuesSet && mappingValuesSet[item.key_fig_api[i].key] && mappingValuesSet[item.key_fig_api[i].key]}
                                                                                            </TableCell>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.key_fig_api && item.key_fig_api[i] && item.key_fig_api[i].value &&
                                                                                                    parseFloat(item.key_fig_api[i].value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                            </TableCell>
                                                                                        </>
                                                                                    ))}
                                                                                    {Array.apply(null, {
                                                                                        length: attributeArrayMXSColumns ? attributeArrayMXSColumns : 0
                                                                                    }).map((e, i) => (
                                                                                        <>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.attri_api && item.attri_api[i] && item.attri_api[i].key ?
                                                                                                    attributeNameData && attributeNameData[item.attri_api[i].key] ? attributeNameData[item.attri_api[i].key] : item.attri_api[i].key.replace(/_/g, ' ') : ''}
                                                                                            </TableCell>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.attri_api && item.attri_api[i] && item.attri_api[i].value && item.attri_api[i].value.replace(/_/g, ' ')}
                                                                                            </TableCell>
                                                                                        </>
                                                                                    ))}
                                                                                    {Array.apply(null, {
                                                                                        length: formulaArrayMXSColumns
                                                                                            ? formulaArrayMXSColumns : null
                                                                                    }).map((e, i) => (
                                                                                        <>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.form_api && item.form_api[i] && item.form_api[i].key &&

                                                                                                    props.formulaListData && props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                                        return item3.ID == item.form_api[i].key ? item3.formula_name : null
                                                                                                    })



                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell align='center' style={{ textTransform: 'capitalize' }}>
                                                                                                {item.type === 'Tier' && item.form_api && item.form_api[i] && item.form_api[i].value &&
                                                                                                    parseFloat(item.form_api[i].value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                                            </TableCell>
                                                                                        </>
                                                                                    ))}
                                                                                    {contractData.maximum_amount_period === 'Payment Period - Tier' &&
                                                                                        <TableCell align='center'>{parseFloat(item.maximum_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                                                    }
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                </StyledTableRow>
                                                                            );
                                                                        })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Typography variant='h4'>
                                                            There is no data to show now.
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                            :
                                            contractData && contractData.calculation_method && (contractData.calculation_method == 'Quota Achievement' || contractData.calculation_method == 'PPM') ?
                                                <Grid container >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        classes={{ root: classes.gridContainer }}
                                                    >
                                                        {contractData && contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead >
                                                                        <TableRow >
                                                                            {headArr2.map(item => {
                                                                                return (
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >{item}</TableCell>
                                                                                )
                                                                            })}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody classes={{ root: classes.table }} >
                                                                        {contractData.calculation_rules
                                                                            .sort((a, b) => (a.type.localeCompare(b.type) || a.attribute_values && a.attribute_values[0] && b.attribute_values && b.attribute_values[0] && (parseFloat(a.attribute_values[0]) - parseFloat(b.attribute_values[0])) || a.rate - b.rate || new Date(a.effective_start_date) - new Date(b.effective_start_date)))
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <StyledTableRow key={item.ID}>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                                            {item.key_figure_names ?
                                                                                                item.key_figure_names.map((item2, i) => {
                                                                                                    return <> {item.key_figure_names[i] &&
                                                                                                        mappingValuesSet && mappingValuesSet[item.key_figure_names[i]] ? mappingValuesSet[item.key_figure_names[i]] : item.key_figure_names[i].replace(/_/g, ' ')
                                                                                                    } {item.operations && item.operations[i] && item.operations[i] ? item.operations[i] : ''}</>;
                                                                                                })
                                                                                                :
                                                                                                ''
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >
                                                                                            {item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ?
                                                                                                parseFloat(item.attribute_key_values[0].attribute_value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                                            }</TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                    </StyledTableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            :
                                                            <Typography variant='h4'>
                                                                There is no data to show now.
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                :
                                                contractData && contractData.calculation_method === 'Scale' ?
                                                    <Grid container >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            classes={{ root: classes.gridContainer }}
                                                        >
                                                            {contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableHead >
                                                                            <TableRow >
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Key Figure Name</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody classes={{ root: classes.table }} >
                                                                            {contractData.calculation_rules
                                                                                .map((item) => {
                                                                                    return (
                                                                                        <StyledTableRow key={item.ID}>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>{item.key_figure_names[0] &&
                                                                                                mappingValuesSet && mappingValuesSet[item.key_figure_names[0]] ? mappingValuesSet[item.key_figure_names[0]] : item.key_figure_names[0].replace(/_/g, ' ')
                                                                                            } </TableCell>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                            <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                        </StyledTableRow>
                                                                                    );
                                                                                })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                                :
                                                                <Typography variant='h4'>
                                                                    There is no data to show now.
                                                                </Typography>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    contractData && contractData.calculation_method === 'Fixed Amount' && contractData.incentive_rate_type === 'Attribute' ?
                                                        <Grid container >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                            >
                                                                {contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableHead >
                                                                                <TableRow >
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Name</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Attribute Value</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Key Figure Name</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Start Date</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >End Date</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody classes={{ root: classes.table }} >
                                                                                {contractData.calculation_rules
                                                                                    .map((item, index) => {
                                                                                        return (
                                                                                            <StyledTableRow key={item.ID}>
                                                                                                <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}> {item.attribute_key_values ?
                                                                                                    item.attribute_key_values.map((item2, i) => {
                                                                                                        return attributeNameData && attributeNameData[item2.attribute_name] ? attributeNameData[item2.attribute_name] : item2.attribute_name.replace(/_/g, ' ');
                                                                                                    })
                                                                                                    :
                                                                                                    ''}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.attribute_key_values && item.attribute_key_values[0] && item.attribute_key_values[0].attribute_value ? item.attribute_key_values[0].attribute_value : ''}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ textTransform: 'capitalize' }}>
                                                                                                    {item.key_figure_names && item.key_figure_names.length > 0 && item.key_figure_names.map((item2, i) => {
                                                                                                        return <>{
                                                                                                            mappingValuesSet && mappingValuesSet[item2] ? mappingValuesSet[item2] : item2.replace(/_/g, ' ')
                                                                                                        }{item.operations && item.operations[i] ? item.operations[i] : ''}</>;
                                                                                                    })}

                                                                                                    {/* {checkLabel(item.key_figure_names[0]) ? checkLabel(item.key_figure_names[0]) : item.key_figure_names[0].replace(/_/g, ' ')} */}
                                                                                                    {/* {item.key_figure_names[0] && item.key_figure_names[0].replace(/_/g, ' ')} */}
                                                                                                </TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate && item.rate.toFixed(4)}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                            </StyledTableRow>
                                                                                        );
                                                                                    })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    :
                                                                    <Typography variant='h4'>
                                                                        There is no data to show now.
                                                                    </Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                classes={{ root: classes.gridContainer }}
                                                            >
                                                                {contractData.calculation_rules && contractData.calculation_rules.length > 0 ?
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableHead >
                                                                                <TableRow >
                                                                                    {contractData.calculation_method === 'Flat Rate' && contractData.incentive_rate_type == 'Contract' &&
                                                                                        <TableCell classes={{ root: classes.leftAlign }}>Formula Type</TableCell>
                                                                                    }
                                                                                    <TableCell classes={{ root: classes.leftAlign }}>Formula</TableCell>
                                                                                    {contractData.calculation_method !== 'Price Point' &&
                                                                                        <TableCell classes={{ root: classes.leftAlign }}>Key Figure Name</TableCell>}
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >Rate Type</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }} >UoM</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}  >Start Date</TableCell>
                                                                                    <TableCell align='center' classes={{ root: classes.fontSetting }}  >End Date</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody classes={{ root: classes.table }} >
                                                                                {contractData.calculation_rules
                                                                                    .map((item, index) => {
                                                                                        return (
                                                                                            <StyledTableRow key={item.ID}>
                                                                                                <TableCell align='center'>{item.formula_id ? 'Formula' : 'Key Figure'}</TableCell>
                                                                                                <TableCell align='center'>{
                                                                                                    contractData.calculation_method === 'Price Point'
                                                                                                        ?
                                                                                                        props.pricingFormulaList && props.pricingFormulaList.records &&
                                                                                                        props.pricingFormulaList.records.length > 0 &&
                                                                                                        props.pricingFormulaList.records.map((item2) => {
                                                                                                            return item2.ID === item.formula_id ? item2.formula_name : null
                                                                                                        })
                                                                                                        :
                                                                                                        item.formula_id ?
                                                                                                            props.formulaListData.records && props.formulaListData.records.map((item3) => {
                                                                                                                return item3.ID === item.formula_id ? item3.formula_name : null
                                                                                                            }) : ''}
                                                                                                </TableCell>
                                                                                                {contractData.calculation_method !== 'Price Point' &&
                                                                                                    <TableCell classes={{ root: classes.leftAlign }} style={{ textTransform: 'capitalize' }}>

                                                                                                        {item.key_figure_names && item.key_figure_names.length > 0 && item.key_figure_names.map((item2, i) => {
                                                                                                            return <>{
                                                                                                                mappingValuesSet && mappingValuesSet[item2] ? mappingValuesSet[item2] : item2.replace(/_/g, ' ')
                                                                                                            }{item.operations && item.operations[i] ? item.operations[i] : ''}</>;
                                                                                                        })
                                                                                                        }
                                                                                                    </TableCell>}
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate.toFixed(4)}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.rate_type}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{item.incentive_uom}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_start_date).format('MM/DD/YYYY')}</TableCell>
                                                                                                <TableCell align='center' classes={{ root: classes.fontSetting }} >{Moment.utc(item.effective_end_date).format('MM/DD/YYYY')}</TableCell>
                                                                                            </StyledTableRow>
                                                                                        );
                                                                                    })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    :
                                                                    <Typography variant='h4'>
                                                                        There is no data to show now.
                                                                    </Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                        }
                    </div>
                }
            </Card>
        </div>
    );

};

const mapStateToProps = state => {
    return {
        data: state.addContractData.contractGeneralData,
        formulaListData: state.FormulaLabData.formulaListData,
        pricingFormulaList: state.pricingData.priceFormulaList,
        dropdownAttributeData: state.addMultipleConfigurationData.attributeNameData,
        dropdownDataCR: state.addMultipleConfigurationData.multipleConfigurationDataKF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationRules);
