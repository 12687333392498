import React, { useEffect } from 'react';
import { HeaderFieldsContainer, CustomerSpecifiedFieldsContainer, AdminDataContainer } from '../../../containers';
import { connect } from 'react-redux';

const GeneralData = React.forwardRef((props, ref) => {
    const [eligibilityRules, setEligibilityRules] = React.useState([]);
    const [calculationRules, setCalculationRules] = React.useState([]);

    useEffect(() => {
        setEligibilityRules(props.eligibiltyRules)
    }, [props.eligibiltyRules])

    useEffect(() => {
        setCalculationRules(props.calculationRules)
    }, [props.calculationRules])

    function handleChange(newValue, requiredValues) {
        props.onChange(newValue, requiredValues);
    }

    return (
        <div>{console.log(props.contractDefaultData)}
            <HeaderFieldsContainer
                contractDefaultData={props.contractDefaultData}
                required onChange={handleChange} savetriggered={props.savetriggered} ref={ref}
                eligibilityRules={eligibilityRules}
                calculationRules={calculationRules}
                fieldData={props.fieldData}
            />
        </div>
    );

});

export default connect(null, null, null, { forwardRef: true })(GeneralData);