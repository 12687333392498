import {
  LOAD_DASHBOARD_COUNT,
  LOAD_DASHBOARD_COUNT_FAIL,
  ACTIVE_CONTRACT_GRAPH,
  MONTHLY_INCENTIVE_GRAPH,
  ACCRUAL_PAYMENT_GRAPH_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS,
  PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS,
  PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS,
  INCOMING_CLAIM_SUCCESS,
  INCOMING_CLAIM_DETAIL_SUCCESS,
  OUTGOING_CLAIM_SUCCESS,
  SALES_PAYMENT_GRAPH_SUCCESS,
  ACCRUAL_VARIANCE_SUCCESS,
  CONTRACT_VARIANCE_SUCCESS,
  SOLD_TO_PARTY_VARIANCE_SUCCESS,
  MATERIAL_NUMBER_VARIANCE_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS,
  GEORGRAPHICAL_INCENTIVE,
  TOP_25,
  REBATE_TRENDS_SECOND,
  VARIANCE_COMBINED_SUCCESS,
  INCENTIVE_TREND_SUCCESS,
  ALLOWED_APPS_SUCCESS,
  DASHBOARD_ANALYTICS_NAMES_SUCCESS,
  DASHBOARD_DASHBOARD_NAMES_SUCCESS,
  PAYMENT_DURATION,
  CLAIM_ERROR,
  CLAIM_AGING_CUSTOMER_CHARGEBACK,
  OUTGOING_CLAIM_DETAIL_SUCCESS,
  FETCH_START,
  FETCH_END,
  CLAIM_VALUE_AND_ERROR_CBS,
  CLAIM_COUNT_CCB_SCB,
  PROM_PLAN_SALES_INCE,
  TIME_TO_MARKET,
  MARKET_SHARE,
  PROMO_BUDGET_REENUE,
  PROMO_ANALY_COST,
  PROMO_COST_PERC,
  PRICING_DASHBOARD_MARGIN_CUSTOMER,
  PRICING_DASHBOARD_MARGIN_MATERIAL,
  DISPUTED_CLAIM_DETAIL_SUCCESS,
  POST_OUT_DATA,
  PRICING_DASHBOARD_COMBINED,
  PRICING_MARGIN_REVENUE,
  PRICING_MARGIN_REVENUE_DASHBOARD,
  TOP_BOTTOM_PERFORMER,
  MARGIN_PRICE__DIFF,
  DASHBOARD_DATA,
  NEW_CUSTOMERS,
  REBATE_OBJECTIVE_SUCCESS,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";
import Moment from "moment";

export const dashboardCountSuccess = (data) => {
  return {
    type: LOAD_DASHBOARD_COUNT,
    data,
  };
};

export const dashboardCountFail = (error) => {
  return {
    type: LOAD_DASHBOARD_COUNT_FAIL,
    error,
  };
};
export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const loadPaymentDurationsGraph = (sd, ed) => {
  return (dispatch) => {
    loadPaymentDurationsGraphAPI(sd, ed)
      .then((response) => {
        dispatch(loadPaymentDurationsGraphSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadPaymentDurationsGraphAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("incoming-claims/claim-aging?start_date=" + sd + "&end_date=" + ed, {
    headers: headers,
  });
  return result;
};

export const loadPaymentDurationsGraphSuccess = (data) => {
  return {
    type: PAYMENT_DURATION,
    data,
  };
};

export const loadClaimErrorCustomerChargeBack = (sd, ed) => {
  return (dispatch) => {
    loadClaimErrorCustomerChargeBackAPI(sd, ed)
      .then((response) => {
        dispatch(loadClaimErrorSuccess(response.data.response));
      })
      .catch((error) => {
      });
  };
};

const loadClaimErrorCustomerChargeBackAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "outgoing-claims/claim-error?start_date=" + sd + "&end_date=" + ed + "&application_type=" + sessionStorage.getItem("application"),
    { headers: headers }
  );
  return result;
};

export const loadClaimError = (sd, ed) => {
  return (dispatch) => {
    loadClaimErrorAPI(sd, ed)
      .then((response) => {
        dispatch(loadClaimErrorSuccess(response.data.response));
      })
      .catch((error) => {
      });
  };
};

const loadClaimErrorAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("incoming-claims/claim-error?start_date=" + sd + "&end_date=" + ed, {
    headers: headers,
  });
  return result;
};

export const loadClaimErrorSuccess = (data) => {
  return {
    type: CLAIM_ERROR,
    data,
  };
};

export const getDashboardCount = (sd, ed, page, formula, type, ver) => {
  return (dispatch) => {
    dispatch(fetchStart());
    dashboardCountApi(sd, ed, page, formula, type, ver)
      .then((response) => {
        if (type === "claim Status") {
          dispatch(fetchEnd());
          dispatch(dashboardCountSuccess(response.data.response));
          history.push("/claim-status-result");
        } else {
          dispatch(fetchEnd());
          dispatch(dashboardCountSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(dashboardCountFail(error));
        // toast.error(ServerError);
      });
  };
};

const dashboardCountApi = (sd, ed, page, formula, supplier, type,) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  var app = sessionStorage.getItem("application");
  // result = axios.get(
  //   "dashboard?app_source_type=" + sessionStorage.getItem("application"),
  //   { headers: headers }
  // );
  if (page === "supplier") {
    result = axios.post("incoming-claims/claim-stats?view_by=" + ed, sd, { headers: headers });
  } else if (page === "customer") {
    result = axios.post("outgoing-claims/claim-stats?view_by=" + ed, sd, { headers: headers });
  }
  else if (page === "Contract Stats") {
    result = axios.get(
      "contracts/count-by-status?app_source_type=" + sessionStorage.getItem("application") + "&start_date=" + sd + "&end_date=" + ed,
      { headers: headers }
    );
  }
  else if (page === "Profit Optimization") {
    result = axios.get(
      "profit-optimization/stats" + "?start_date=" + sd + "&end_date=" + ed + "&formula=" + formula,
      null,
      { headers: headers }
    );
  }
  else {
    if (page === "Promotions") {
      result = axios.post(
        "promotions/stats" + "?start_date=" + sd + "&end_date=" + ed,
        null,
        { headers: headers }
      );
    } else if (app === "Customer Chargeback") {
      result = axios.post("outgoing-claims/claim-stats", sd, { headers: headers });
    } else if (app === "Supplier Chargeback") {
      result = axios.post("incoming-claims/claim-stats", sd, { headers: headers });
    } else {
      result = axios.get(
        "contracts/count-by-status?app_source_type=" + sessionStorage.getItem("application") + "&start_date=" + sd + "&end_date=" + ed,
        { headers: headers }
      );
    }
  }
  return result;
};

//Active Contracts Graph
export const getActiveContractsGraph = (type, sd, ed) => {
  return (dispatch) => {
    activeContractsGraphAPI(type, sd, ed)
      .then((response) => {
        dispatch(activeContractGraphSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const activeContractsGraphAPI = (type, sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "contracts/monthly-active-contracts?app_source_type=" +
    type + "&start_date=" + sd + "&end_date=" + ed,
    { headers: headers }
  );
  return result;
};

export const activeContractGraphSuccess = (data) => {
  return {
    type: ACTIVE_CONTRACT_GRAPH,
    data,
  };
};

//Monthly Incentive Graph
export const getMonthlyIncentiveGraph = () => {
  return (dispatch) => {
    monthlyIncentiveGraphAPI()
      .then((response) => {
        dispatch(monthlyIncentiveGraphSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const monthlyIncentiveGraphAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "dashboard/monthly_incentive?app_source_type=" +
    localStorage.getItem("application"),
    { headers: headers }
  );
  return result;
};

export const monthlyIncentiveGraphSuccess = (data) => {
  return {
    type: MONTHLY_INCENTIVE_GRAPH,
    data,
  };
};

//analytics vs settlement
export const loadAccrualVsPaymentGraph = (applicationType, sourceDataType, startDate, endDate, aggregationField) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadAccrualVsPaymentGraphApi(applicationType, sourceDataType, startDate, endDate, aggregationField)
      .then((response) => {
        dispatch(loadAccrualVsPaymentGraphSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const loadAccrualVsPaymentGraphApi = (applicationType, sourceDataType, startDate, endDate, aggregationField) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (applicationType === "passingAsParams") {
    result = axios.get("incentives/accrual-vs-payment", {
      params: sourceDataType,
      headers: headers,
    });
  } else if (applicationType === "Customer Chargeback") {
    result = axios.get("outgoing-claims/accrual-vs-payment?application_type=" +
      applicationType, {
      headers: headers,
    });
  } else if (applicationType === "Supplier Chargeback") {
    result = axios.get("incoming-claims/accrual-vs-payment?application_type=" +
      applicationType, {
      headers: headers,
    });
  } else {
    result = axios.get(
      "incentives/accrual-vs-payment?application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType +
      "&start_date=" +
      startDate +
      "&end_date=" +
      endDate +
      "&groupBy=" +
      aggregationField,
      { headers: headers }
    );
  }
  return result;
};

export const loadAccrualVsPaymentGraphSuccess = (data) => {
  return {
    type: ACCRUAL_PAYMENT_GRAPH_SUCCESS,
    data,
  };
};

//sales vs payment
export const loadSalesVsPaymentGraph = (applicationType, sourceDataType, startDate, endDate,groupBy) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadSalesVsPaymentGraphAPI(applicationType, sourceDataType, startDate, endDate,groupBy)
      .then((response) => {
        dispatch(loadSalesVsPaymentGraphSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const loadSalesVsPaymentGraphAPI = (applicationType, sourceDataType, startDate, endDate,groupBy) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incentives/gross-to-net?application_type=" +
    applicationType +
    "&data_source_type=" +
    sourceDataType +
    "&start_date=" +
    startDate +
    "&end_date=" +
    endDate +
    "&groupBy=" +
    groupBy,
    { headers: headers }
  );
  return result;
};

export const loadSalesVsPaymentGraphSuccess = (data) => {
  return {
    type: SALES_PAYMENT_GRAPH_SUCCESS,
    data,
  };
};

//accrual variance
export const loadAccrualVarianceGraph = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  command,
  groupBy
) => {
  return (dispatch) => {
    loadAccrualVarianceGraphAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType,
      command,
      groupBy
    )
      .then((response) => {
        dispatch(loadAccrualVarianceGraphSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadAccrualVarianceGraphAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  command,
  groupBy
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (end_date === "dashboard") {
    result = axios.get("financial-posting-variance", {
      params: start_date,
      headers: headers,
    });
  } else if (command == "postingType") {
    result = axios.get(
      "incentives/variance-by-attribute?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType +
      "&groupBy=" +
      groupBy,
      { headers: headers }
    );
  } else {
    if (type === "pv") {
      result = axios.get(
        "incentives/variance-by-attribute?posting_type=payment&start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&application_type=" +
        applicationType +
        "&data_source_type=" +
        sourceDataType +
        "&aggregation_field=" +
        groupBy,
        { headers: headers }
      );
    } else {
      result = axios.get(
        "incentives/variance-by-attribute?posting_type=accrual&start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&application_type=" +
        applicationType +
        "&data_source_type=" +
        sourceDataType +
        "&aggregation_field=" +
        groupBy,
        { headers: headers }
      );
    }
  }
  return result;
};

export const loadAccrualVarianceGraphSuccess = (data) => {
  return {
    type: ACCRUAL_VARIANCE_SUCCESS,
    data,
  };
};

//contract variance
export const loadContractVarianceGraph = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  return (dispatch) => {
    loadContractVarianceGraphAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType
    )
      .then((response) => {
        dispatch(loadContractVarianceGraphSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadContractVarianceGraphAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "cv") {
    result = axios.get(
      "financial-posting-contract-type-variance?posting_type=Accrual,Accrual Reversal&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else {
    result = axios.get(
      "financial-posting-contract-type-variance?posting_type=Payment,Payment Reversal,Delta Payment&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  }
  return result;
};

export const loadContractVarianceGraphSuccess = (data) => {
  return {
    type: CONTRACT_VARIANCE_SUCCESS,
    data,
  };
};

//sold to party variance
export const loadSoldToPartyVarianceGraph = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  return (dispatch) => {
    loadSoldToPartyVarianceAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType
    )
      .then((response) => {
        dispatch(loadSoldToPartyVarianceSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadSoldToPartyVarianceAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "cv") {
    result = axios.get(
      "financial-posting-sold-to-party-variance?posting_type=Accrual,Accrual Reversal&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else {
    result = axios.get(
      "financial-posting-sold-to-party-variance?posting_type=Payment,Payment Reversal,Delta Payment&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  }
  return result;
};

export const loadSoldToPartyVarianceSuccess = (data) => {
  return {
    type: SOLD_TO_PARTY_VARIANCE_SUCCESS,
    data,
  };
};

//material number variance
export const loadMaterialNumberVarianceGraph = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  return (dispatch) => {
    loadMaterialNumberVarianceAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType
    )
      .then((response) => {
        dispatch(loadMaterialNumberVarianceSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadMaterialNumberVarianceAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "cv") {
    result = axios.get(
      "financial-posting-material-number-variance?posting_type=Accrual,Accrual Reversal&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else {
    result = axios.get(
      "financial-posting-material-number-variance?posting_type=Payment,Payment Reversal,Delta Payment&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  }
  return result;
};

export const loadMaterialNumberVarianceSuccess = (data) => {
  return {
    type: MATERIAL_NUMBER_VARIANCE_SUCCESS,
    data,
  };
};

//payment graph
export const loadPaymentGraph = (
  data,
  range,
  type,
  applicationType,
  sourceDataType
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadPaymentGraphApi(data, range, type, applicationType, sourceDataType)
      .then((response) => {
        if (type === "payment") {
          if (data === "contract_type")
            dispatch(
              loadPaymentGraphContractTypeSuccess(
                response.data.response.payment_result
              )
            );
          else if (data === "calculation_method") {
            dispatch(
              loadPaymentGraphCalculationSchemaSuccess(
                response.data.response.payment_result
              )
            );
          } else if (data === "contract_group") {
            dispatch(
              loadPaymentGraphContractGroupSuccess(
                response.data.response.payment_result
              )
            );
          } else if (data === "contract_sub_group") {
            dispatch(
              loadPaymentGraphContractSubGroupSuccess(
                response.data.response.payment_result
              )
            );
          }
        } else if (type == "dashboard") {
          dispatch(loadRebateObjectiveSuccess(response.data.response));
        } else {
          if (data === "contract_type")
            dispatch(
              loadPaymentGraphContractTypeAccrualSuccess(
                response.data.response.payment_result
              )
            );
          else if (data === "calculation_method") {
            dispatch(
              loadPaymentGraphCalculationSchemaAccrualSuccess(
                response.data.response.payment_result
              )
            );
          } else if (data === "contract_group") {
            dispatch(
              loadPaymentGraphContractGroupAccrualSuccess(
                response.data.response.payment_result
              )
            );
          } else if (data === "contract_sub_group") {
            dispatch(
              loadPaymentGraphContractSubGroupAccrualSuccess(
                response.data.response.payment_result
              )
            );
          }
        }
        dispatch(fetchEnd());
      })
      .catch((error) => {
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const loadPaymentGraphApi = (
  data,
  range,
  type,
  applicationType,
  sourceDataType
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type == "dashboard") {
    result = axios.get(
      "incentives/postings-by-contract-attributes",
      { params: data },
      { headers: headers }
    );
  } else
    result = axios.get(
      "incentives/postings-by-contract-attributes?groupBy=" +
      data +
      "&" +
      range +
      "&posting_type=" +
      type +
      "&form_id=IMA_CONFIG_MULTIPLE" +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  return result;
};

export const loadRebateObjectiveSuccess = (data) => {
  return {
    type: REBATE_OBJECTIVE_SUCCESS,
    data,
  };
};
export const loadPaymentGraphContractTypeSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS,
    data,
  };
};

export const loadPaymentGraphCalculationSchemaSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS,
    data,
  };
};

export const loadPaymentGraphContractGroupSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS,
    data,
  };
};

export const loadPaymentGraphContractSubGroupSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS,
    data,
  };
};

export const loadPaymentGraphContractTypeAccrualSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS,
    data,
  };
};

export const loadPaymentGraphCalculationSchemaAccrualSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS,
    data,
  };
};

export const loadPaymentGraphContractGroupAccrualSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS,
    data,
  };
};

export const loadPaymentGraphContractSubGroupAccrualSuccess = (data) => {
  return {
    type: PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS,
    data,
  };
};

//claims
//incoming claim payment
export const getIncomingClaimsPayment = (id) => {
  return (dispatch) => {
    getIncomingClaimsPaymentApi(id)
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const getIncomingClaimsPaymentApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("process-payment?claim_number=" + id, {
    headers: headers,
  });
  return result;
};

//incoming claims
export const getIncomingClaims = (pagination, limit) => {
  return (dispatch) => {
    getIncomingClaimsApi(pagination, limit)
      .then((response) => {
        dispatch(getIncomingClaimsSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const getIncomingClaimsApi = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incoming-claims-header?offset=" + pagination + "&limit=" + limit,
    { headers: headers }
  );
  return result;
};

export const getIncomingClaimsSuccess = (data) => {
  return {
    type: INCOMING_CLAIM_SUCCESS,
    data,
  };
};

//edit incoming claims
export const editIncomingClaim = (id, reconcile) => {
  return (dispatch) => {
    editIncomingClaimApi(id, reconcile)
      .then((response) => {
        dispatch(editIncomingClaimSuccess(response.data.response));
        if (reconcile === "reconcile") {
          dispatch(reConcile(response.data.response, "", reconcile));
        } else {
          history.push("/supplier-incoming-claims/edit-claims/" + id);
        }
      })
      .catch((error) => {
        history.push("/supplier-incoming-claims/edit-claims/" + id);
      });
  };
};

const editIncomingClaimApi = (id, reconcile) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incoming-claims-group",
    { params: { claim_number: id } },
    { headers: headers }
  );
  return result;
};

export const editIncomingClaimSuccess = (data) => {
  return {
    type: INCOMING_CLAIM_DETAIL_SUCCESS,
    data,
  };
};

//update incoming claim data
export const updateIncomingClaimData = (formData) => {
  return (dispatch) => {
    updateIncomingClaimDataApi(formData)
      .then((response) => {
        toast.success(response.data.response)
        window.location.reload();
        // dispatch(editIncomingClaim(formData.claim_number, null)); // commented for future use
      })
      .catch((error) => {
        toast.error("Error in updating data. Please try again.");
      });
  };
};

const updateIncomingClaimDataApi = (formData) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("update-claims-direct", formData, { headers: headers });
  return result;
};

export const getOutgoingClaims = () => {
  return (dispatch) => {
    getOutgoingClaimsApi()
      .then((response) => {
        dispatch(getOutgoingClaimsApiSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const getOutgoingClaimsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("outgoing-claims-header", { headers: headers });
  return result;
};

export const getOutgoingClaimsApiSuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_SUCCESS,
    data,
  };
};

//outgoing claims posting
export const postOutgoingClaimPosting = (data, splitCriteria) => {
  return (dispatch) => {
    dispatch(fetchStart());
    postOutgoingClaimPostingAPI(data, splitCriteria)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.messageLog) {
          dispatch(postOutfoingClaimData(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const postOutgoingClaimPostingAPI = (data, splitCriteria) => {
  for (var propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var criteria = splitCriteria.toString();
  var result;
  result = axios.post("outgoing-claim?split_criteria=" + criteria, data, {
    headers: headers,
  });
  return result;
};
export const postOutfoingClaimData = (data) => {
  return {
    type: POST_OUT_DATA,
    data,
  };
};
export const handleSplitOutgoingClaim = (claimNumber) => {
  return (dispatch) => {
    handleSplitOutgoingClaimAPI(claimNumber)
      .then((response) => {
        toast.success("Success");
        dispatch(getOutgoingClaims());
      })
      .catch((error) => {
        toast.error("Error");
      });
  };
};

const handleSplitOutgoingClaimAPI = (claimNumber) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get(
    "split-error-outgoing-claims?claim_number=" + claimNumber,
    { headers: headers, responseType: "blob" }
  );
  return result;
};

//edit incoming claims
export const editOutgoingClaim = (id) => {
  return (dispatch) => {
    editOutgoingClaimApi(id)
      .then((response) => {
        dispatch(editOutgoingClaimSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const editOutgoingClaimApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "outgoing-claims-group",
    { params: { claim_number: id } },
    { headers: headers }
  );
  return result;
};

export const editOutgoingClaimSuccess = (data) => {
  return {
    type: OUTGOING_CLAIM_DETAIL_SUCCESS,
    data,
  };
};

//reconcile
export const reConcile = (data, claimNumber, reconcile) => {
  return (dispatch) => {
    reConcileAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        if (reconcile === "reconcile") {
          dispatch(getIncomingClaims());
        } else {
          dispatch(editIncomingClaim(claimNumber));
        }
      })
      .catch((error) => {
        toast.error("Error in updating data. Please try again.");
      });
  };
};

const reConcileAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("update-claims", data, { headers: headers });
  return result;
};

// handle split

export const handleSplit = (claimNumber) => {
  return (dispatch) => {
    handleSplitAPI(claimNumber)
      .then((response) => {
        toast.success("Success");
        dispatch(getIncomingClaims());
      })
      .catch((error) => {
        toast.error("Error");
      });
  };
};

const handleSplitAPI = (claimNumber) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  const result = axios.get("split-error-claims?claim_number=" + claimNumber, {
    headers: headers,
    responseType: "blob",
  });
  return result;
};

//georgraphichal chart
export const geographicalIncentive = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  groupBy
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    geographicalIncentiveAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType,
      groupBy
    )
      .then((response) => {
        dispatch(geographicalIncentiveSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(geographicalIncentiveSuccess([]));
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const geographicalIncentiveAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  groupBy
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  if (type === "Accrual") {
    var postingType = "accrual";
  } else {
    var postingType = "payment";
  }
  var result;
  if (end_date === "dashboard") {
    result = axios.get(
      "incentives/incentive-ratio-by-attribute",

      { params: start_date, headers: headers }
    );
  } else {
    result = axios.get(
      "incentives/incentive-ratio-by-attribute?posting_type=" +
      postingType +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType +
      "&groupBy=" +
      groupBy,
      { headers: headers }
    );
  }
  return result;
};

export const geographicalIncentiveSuccess = (data) => {
  return {
    type: GEORGRAPHICAL_INCENTIVE,
    data,
  };
};

//top 25
export const loadTop25Chart = (type, date, applicationType, sourceDataType, aggregationField, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadTop25ChartAPI(type, date, applicationType, sourceDataType, aggregationField, limit)
      .then((response) => {
        dispatch(loadTop25ChartSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const loadTop25ChartAPI = (type, date, applicationType, sourceDataType, aggregationField, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type === "Accrual") {
    var postingType = "accrual";
  } else {
    var postingType = "payment";
  }

  if (date === "dashboard") {
    result = axios.get("incentives/top-incentives", { params: type, headers: headers });
  } else {
    result = axios.get(
      "incentives/top-incentives?posting_type=" +
      postingType +
      "&" +
      date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType +
      "&groupBy=" +
      aggregationField +
      "&fetch_count=" +
      limit +
      "&user_input=" +
      true,
      { headers: headers }
    );
  }

  return result;
};

export const loadTop25ChartSuccess = (data) => {
  return {
    type: TOP_25,
    data,
  };
};


//load rebate trends second api

//accrual variance
export const loadRebateTrendsSecondAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  id
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadRebateTrendsSecondAPIAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType,
      id
    )
      .then((response) => {
        dispatch(loadRebateTrendsSecondAPISuccess(response.data.response));
        history.push("/rebate-trends/incentive-trends/" + id);
        dispatch(fetchEnd());
      })
      .catch((error) => {
        toast.error(ServerError);
        dispatch(fetchEnd());
      });
  };
};

const loadRebateTrendsSecondAPIAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  id
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result = axios.get(
    "incentives/trend-by-contract-number?start_date=" +
    start_date +
    "&end_date=" +
    end_date +
    "&application_type=" +
    applicationType +
    "&data_source_type=" +
    sourceDataType +
    "&contract_number=" +
    id +
    "&posting_type=accrual",
    { headers: headers }
  );
  return result;
};

export const loadRebateTrendsSecondAPISuccess = (data) => {
  return {
    type: REBATE_TRENDS_SECOND,
    data,
  };
};

//combined variance

export const loadVarianceCombined = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  postingType,
  varianceType
) => {
  return (dispatch) => {
    loadVarianceCombinedAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType,
      postingType
    )
      .then((response) => {
        dispatch(loadVarianceCombinedSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadVarianceCombinedAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  varianceType
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (varianceType === "Contract Variance") {
    result = axios.get(
      "financial-posting-variance?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else if (varianceType === "Contract Type Variance") {
    result = axios.get(
      "financial-posting-contract-type-variance?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else if (varianceType === "Customer Variance") {
    result = axios.get(
      "financial-posting-sold-to-party-variance?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  } else if (varianceType === "Material Variance") {
    result = axios.get(
      "financial-posting-material-number-variance?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType,
      { headers: headers }
    );
  }
  return result;
};

export const loadVarianceCombinedSuccess = (data) => {
  return {
    type: VARIANCE_COMBINED_SUCCESS,
    data,
  };
};

//new api for variance
export const loadVarianceCombinedNew = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  postingType,
  filterData,
  grouped,
  page,
  varianceType
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    loadVarianceCombinedNewAPI(
      start_date,
      end_date,
      type,
      applicationType,
      sourceDataType,
      postingType,
      filterData,
      grouped,
      page
    )
      .then((response) => {
        if (page === "Incentive Variance Analysis") {
          if(response.data.response==null){
            dispatch(fetchEnd());
            toast.warning("No records found.");
          }
          if (
            response.data.response &&
            Object.entries(response.data.response).length > 0
          ) {
            dispatch(fetchEnd());
            dispatch(loadVarianceCombinedNewSuccess(response.data.response));
            history.push("/incentive-variance-analysis-reports-results");
          }
        } else {
          dispatch(fetchEnd());
          dispatch(loadVarianceCombinedNewSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        toast.error(ServerError);
      });
  };
};

const loadVarianceCombinedNewAPI = (
  start_date,
  end_date,
  type,
  applicationType,
  sourceDataType,
  varianceType,
  filterData,
  grouped,
  page
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (page === "Incentive Variance Analysis") {
    result = axios.post(
      "incentives/variance-by-attribute?application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType 
      // "&aggregation_field=" +
      // varianceType +
      // "&grouped=" +
      // grouped
      ,filterData,
      { headers: headers }
    );
  }
  else if (filterData !== undefined) {
    if (varianceType === "contract_type") {
      result = axios.get(
        "incentives/variance-by-contract-attribute?posting_type=" +
        type +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&application_type=" +
        applicationType +
        "&data_source_type=" +
        sourceDataType +
        "&aggregation_field=" +
        varianceType +
        "&user_input=" +
        true +
        "&contract_type=" +
        filterData,
        { headers: headers }
      );
    }
    else {
      result = axios.get(
        "incentives/variance-by-contract-attribute?posting_type=" +
        type +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&application_type=" +
        applicationType +
        "&data_source_type=" +
        sourceDataType +
        "&aggregation_field=" +
        varianceType +
        "&user_input=" +
        true +
        "&contract_number=" +
        filterData,
        { headers: headers }
      );
    }


  }
  else {
    result = axios.get(
      "incentives/variance-by-contract-attribute?posting_type=" +
      type +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&application_type=" +
      applicationType +
      "&data_source_type=" +
      sourceDataType +
      "&aggregation_field=" +
      varianceType +
      "&user_input=" +
      true,
      // "&contract_type=" +
      // filterData,
      { headers: headers }
    );
  }
  return result;
};

export const loadVarianceCombinedNewSuccess = (data) => {
  return {
    type: VARIANCE_COMBINED_SUCCESS,
    data,
  };
};

//incentive trends

//analytics vs settlement
export const loadIncentiveTrends = (
  s,
  e,
  postingType,
  applicationType,
  sourceDataType,
  contractNumber
) => {
  return (dispatch) => {
    loadIncentiveTrendsAPI(
      s,
      e,
      postingType,
      applicationType,
      sourceDataType,
      contractNumber
    )
      .then((response) => {
        dispatch(loadIncentiveTrendsSuccess(response.data.response));
      })
      .catch((error) => {
        toast.error(ServerError);
      });
  };
};

const loadIncentiveTrendsAPI = (
  s,
  e,
  postingType,
  applicationType,
  sourceDataType,
  contractNumber
) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incentives/trend-by-contract-number?application_type=" +
    applicationType +
    "&data_source_type=" +
    sourceDataType +
    "&start_date=" +
    s +
    "&end_date=" +
    e +
    "&contract_number=" +
    contractNumber +
    "&posting_type=" +
    postingType,
    { headers: headers }
  );
  return result;
};

export const loadIncentiveTrendsSuccess = (data) => {
  return {
    type: INCENTIVE_TREND_SUCCESS,
    data,
  };
};
//conrtact trends
export const downloadContractTrendsResltsSheet = (data) => {
  return (dispatch) => {
    toast.info(
      "Downloading has been started in background. File will be downloaded automatically once ready."
    );
    downloadContractTrendsResltsSheetAPI(data).then((response) => {
      var [data, data2] = response.headers["content-type"].split(" ");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      var date = Date.now();
      link.setAttribute("download", data2 + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

const downloadContractTrendsResltsSheetAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  for (var propName in data) {
    if (
      data[propName] === "" ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === "1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z" ||
      data[propName] === "1970-01-01T00:00:00.000Z" ||
      data[propName] === ","
    ) {
      delete data[propName];
    }
  }
  const result = axios.get("download-contract-accuracy/v2", {
    headers: headers,
    params: data,
    responseType: "blob",
  });
  //old api
  // const result = axios.get("download-contract-accuracy", {
  //   headers: headers,
  //   params: data,
  //   responseType: "blob",
  // });
  return result;
};

export const dashboardAnalyticsNames = (type, type2) => {
  return (dispatch) => {
    dashboardAnalyticsNamesAPI(type, type2)
      .then((response) => {
        dispatch(dashboardAnalyticsNamesSuccess(response.data.response));
      });
  };
};

const dashboardAnalyticsNamesAPI = (type, type2) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("/dashboard/analytic-names?application_type=" + type + "&functionality=" + type2, { headers: headers });
  return result;
};

export const dashboardAnalyticsNamesSuccess = (data) => {
  return {
    type: DASHBOARD_ANALYTICS_NAMES_SUCCESS,
    data,
  };
};
export const dashboardDashBoardNames = (type, type2) => {
  return (dispatch) => {
    dashboardDashBoardNamesAPI(type, type2)
      .then((response) => {
        dispatch(dashboardDashBoardNamesSuccess(response.data.response));
      });
  };
};

const dashboardDashBoardNamesAPI = (type, type2) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("/dashboard/analytic-names?application_type=" + type + "&functionality=" + type2, { headers: headers });
  return result;
};

export const dashboardDashBoardNamesSuccess = (data) => {
  return {
    type: DASHBOARD_DASHBOARD_NAMES_SUCCESS,
    data,
  };
};


export const allowedApps = () => {
  return (dispatch) => {
    allowedAppsAPI().then((response) => {
      dispatch(allowedAppsSuccess(response.data.response));
    });
  };
};

const allowedAppsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("/user-auth-apps", { headers: headers });
  return result;
};

export const allowedAppsSuccess = (data) => {
  return {
    type: ALLOWED_APPS_SUCCESS,
    data,
  };
};

export const loadClaimAgingCustomerChargeBack = (sd, ed) => {
  return (dispatch) => {
    loadClaimAgingCustomerChargeBackAPI(sd, ed)
      .then((response) => {
        dispatch(
          loadClaimAgingCustomerChargeBackSuccess(response.data.response)
        );
      })
      .catch((error) => {
        dispatch(loadClaimAgingCustomerChargeBackSuccess({}));
        toast.error(error.response.data.complete_error_message);
      });
  };
};

const loadClaimAgingCustomerChargeBackAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "outgoing-claims/claim-aging?start_date=" + sd + "&end_date=" + ed + "&application_type=" + sessionStorage.getItem("application"),
    { headers: headers }
  );
  return result;
};

export const loadClaimAgingCustomerChargeBackSuccess = (data) => {
  return {
    type: CLAIM_AGING_CUSTOMER_CHARGEBACK,
    data,
  };
};

export const loadClaimValueAndError = (sd, ed) => {
  return (dispatch) => {
    loadClaimValueAndErrorAPI(sd, ed)
      .then((response) => {
        dispatch(loadClaimValueAndErrorSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(loadClaimValueAndErrorSuccess({}));
      });
  };
};

const loadClaimValueAndErrorAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  var app = sessionStorage.getItem("application");
  if (app == "Customer Chargeback") {
    result = axios.get(
      "outgoing-claims/claim-value?start_date=" + sd + "&end_date=" + ed + "&application_type=" + sessionStorage.getItem("application"),
      { headers: headers }
    );
  } else {
    result = axios.get(
      "incoming-claims/claim-value?start_date=" + sd + "&end_date=" + ed,
      { headers: headers }
    );
  }
  return result;
};

export const loadClaimValueAndErrorSuccess = (data) => {
  return {
    type: CLAIM_VALUE_AND_ERROR_CBS,
    data,
  };
};

export const loadClaimCountCCBSCB = (sd, ed) => {
  return (dispatch) => {
    loadClaimCountCCBSCBAPI(sd, ed)
      .then((response) => {
        dispatch(loadClaimCountCCBSCBSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(loadClaimCountCCBSCBSuccess({}));
        toast.error(error.response.data.message);
      });
  };
};

const loadClaimCountCCBSCBAPI = (sd, ed) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  var app = sessionStorage.getItem("application");
  if (app == "Customer Chargeback") {
    result = axios.get(
      "outgoing-claims/claim-count?start_date=" + sd + "&end_date=" + ed + "&application_type=" + sessionStorage.getItem("application"),
      { headers: headers }
    );
  } else {
    result = axios.get(
      "incoming-claims/claim-count?start_date=" + sd + "&end_date=" + ed,
      { headers: headers }
    );
  }
  return result;
};

export const loadClaimCountCCBSCBSuccess = (data) => {
  return {
    type: CLAIM_COUNT_CCB_SCB,
    data,
  };
};

export const promPlanSalsIncentives = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    promPlanSalsIncentivesAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (data === "reset_store") {
          dispatch(promPlanSalsIncentivesSuccess({}));
        } else {
          dispatch(promPlanSalsIncentivesSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(promPlanSalsIncentivesSuccess({}));
        // toast.error(ServerError);
      });
  };
};

const promPlanSalsIncentivesAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("profit-optimization/margin-trending", data, {
    headers: headers,
  });
  return result;
};

export const promPlanSalsIncentivesSuccess = (data) => {
  return {
    type: PROM_PLAN_SALES_INCE,
    data,
  };
};

export const timeToMarket = (data) => {
  return (dispatch) => {
    timeToMarketAPI(data)
      .then((response) => {
        if (data === "reset_store") {
          dispatch(timeToMarketSuccess([]));
        } else {
          dispatch(timeToMarketSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(timeToMarketSuccess([]));
      });
  };
};

const timeToMarketAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("time-to-market", data, { headers: headers });
  return result;
};

export const timeToMarketSuccess = (data) => {
  return {
    type: TIME_TO_MARKET,
    data,
  };
};

export const marketShare = (data) => {
  if (data === "reset_store") {
    return (dispatch) => {
      dispatch(marketShareSuccess([]));
    };
  } else
    return (dispatch) => {
      marketShareAPI(data)
        .then((response) => {
          if (data === "reset_store") {
            dispatch(marketShareSuccess([]));
          } else {
            dispatch(marketShareSuccess(response.data.response));
          }
        })
        .catch((error) => {
          dispatch(marketShareSuccess([]));
        });
    };
};

const marketShareAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("market-share", { params: data }, { headers: headers });
  return result;
};

export const marketShareSuccess = (data) => {
  return {
    type: MARKET_SHARE,
    data,
  };
};

export const marketShareNew = (data, filter) => {
  if (data === "reset_store") {
    return (dispatch) => {
      dispatch(marketShareSuccess([]));
    };
  } else
    return (dispatch) => {
      marketShareNewAPI(data, filter)
        .then((response) => {
          if (data === "reset_store") {
            dispatch(marketShareSuccess([]));
          } else {
            dispatch(marketShareSuccess(response.data.response));
          }
        })
        .catch((error) => {
          dispatch(marketShareSuccess([]));
        });
    };
};

const marketShareNewAPI = (data, filter) => {
  var filterTemp = filter;

  if (Object.keys(filterTemp).length > 0) {
    Object.entries(filterTemp).map(([key, value], index) => {
      filterTemp[key] = value.split();
    });
  }
  // filterTemp.map((x, index) => {
  //     console.log('current', filterTemp[index])
  //     console.log('[x[Object.keys(x)[0]]]', [x[Object.keys(x)[0]]])

  //     filterTemp[index][x[Object.keys(x)][0]] = {
  //         [Object.keys(x)[0]]: x[Object.keys(x)[0]].split()
  //     }
  // })
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "market-share-details?start_date=" +
    data.start_date +
    "&end_date=" +
    data.end_date +
    "&attribute_name=" +
    data.attribute_name,
    { filters: filter },
    // null,
    { headers: headers }
  );
  return result;
};

export const marketShareSuccessNew = (data) => {
  return {
    type: MARKET_SHARE,
    data,
  };
};

//promoton budgeting
export const promotionAnalyticsCost = (formData) => {
  if (formData === "reset_store") {
    return (dispatch) => {
      dispatch(promotionAnalyticsCostSuccss([]));
    };
  } else
    return (dispatch) => {
      promotionAnalyticsCostAPI(formData)
        .then((response) => {
          dispatch(promotionAnalyticsCostSuccss(response.data.response));
        })
        .catch((error) => {
          dispatch(promotionAnalyticsCostSuccss([]));
        });
    };
};

const promotionAnalyticsCostAPI = (formData) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "promotions/cost-by-promotion-type",
    { params: formData },
    { headers: headers }
  );
  return result;
};

export const promotionAnalyticsCostSuccss = (data) => {
  return {
    type: PROMO_ANALY_COST,
    data,
  };
};

export const promotionRevenueAnalytics = (formData, data1, data2) => {
  if (formData === "reset_store") {
    return (dispatch) => {
      dispatch(promotionRevenueAnalyticsSuccess([]));
    };
  } else
    return (dispatch) => {
      promotionRevenueAnalyticsAPI(formData, data1, data2)
        .then((response) => {
          dispatch(promotionRevenueAnalyticsSuccess(response.data.response));
        })
        .catch((error) => { });
    };
};

const promotionRevenueAnalyticsAPI = (formData, data1, data2) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (formData == "filter")
    result = axios.get(
      "promotions/cost-to-revenue",
      { params: data1 },
      { headers: headers }
    );
  else
    result = axios.get(
      "promotions/cost-to-revenue?start_date=" + data1 + "&end_date=" + data2,
      { headers: headers }
    );

  return result;
};

export const promotionRevenueAnalyticsSuccess = (data) => {
  return {
    type: PROMO_BUDGET_REENUE,
    data,
  };
};

export const promotionCostPercentage = (formData, sdate, edate) => {
  if (formData === "reset_store") {
    return (dispatch) => {
      dispatch(promotionCostPercentageSuccess({}));
    };
  } else
    return (dispatch) => {
      promotionCostPercentageAPI(formData, sdate, edate)
        .then((response) => {
          dispatch(promotionCostPercentageSuccess(response.data.response));
        })
        .catch((error) => {
          dispatch(promotionCostPercentageSuccess({}));
        });
    };
};

const promotionCostPercentageAPI = (formData, sdate, edate) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (formData == "filter")
    result = axios.get(
      "promotions/cost-percent-by-promotion-type",
      { params: sdate },
      { headers: headers }
    );
  else
    result = axios.get(
      "promotions/cost-percent-by-promotion-type?start_date=" +
      sdate +
      "&end_date=" +
      edate +
      "&periodicity=Cumulative",
      { headers: headers }
    );

  return result;
};

export const promotionCostPercentageSuccess = (data) => {
  return {
    type: PROMO_COST_PERC,
    data,
  };
};

export const pricingDashboardMargin = (id, data, type) => {
  return (dispatch) => {
    pricingDashboardMarginAPI(id, data, type)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.status == 203 &&
          id != "customer" &&
          id != "material"
        ) {
          toast.warn(response.data.message);
        }
        if (id == "combined") {
          dispatch(pricingDashboardCombinedSuccess(response.data.response));
          history.push("/price-analytics");
        }
        if (id == "waterfall") {
          dispatch(pricingDashboardCombinedSuccess(response.data.response));
        } else
          dispatch(pricingDashboardMarginSuccess(response.data.response, id));
      })
      .catch((error) => {
        /* if (
           error &&
           error.response &&
           error.response.data &&
           error.response.data.error &&
           error.response.data.error.complete_error_message
         )
           toast.error(error.response.data.error.complete_error_message);*/
      });
  };
};

const pricingDashboardMarginAPI = (id, data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result = [];
  if (id === "combined")
    result = axios.post("pricing/margin-actual-target-forecast", data, {
      headers: headers,
    });
  else if (id === "waterfall")
    result = axios.post("pricing/margin-actual-target-forecast", data, {
      headers: headers,
    });
  else if (id === "profit")
    result = axios.post("profit/margin-actual-target-forecast?application_type=" + type, data, {
      headers: headers,
    });
  else
    result = axios.post("pricing/margin-actual-target-forecast", data, {
      headers: headers,
    });
  return result;
};

export const pricingDashboardMarginSuccess = (data, id) => {
  return {
    type:
      id === "customer"
        ? PRICING_DASHBOARD_MARGIN_CUSTOMER
        : PRICING_DASHBOARD_MARGIN_MATERIAL,
    data,
  };
};
export const pricingDashboardCombinedSuccess = (data, id) => {
  return {
    type: PRICING_DASHBOARD_COMBINED,
    data,
  };
};
//outgoing postings
export const runOutgoingClaimPostings = (data, type, postDate) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runOutgoingClaimPostingsAPI(data, type, postDate)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data);
      });
  };
};

const runOutgoingClaimPostingsAPI = (data, type, postDate) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post(
    "process-outgoing-claim-posting?posting_type=" +
    type +
    "&posting_date=" +
    postDate,
    data,
    { headers: headers }
  );
  return result;
};

//disputed claim

export const runDisputedClaim = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runDisputedClaimApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          if (type === "Claim Submission") {
            dispatch(disputedClaimSuccess(response.data.response));
            history.push("/claims-submissions-result");
          } else {
            dispatch(disputedClaimSuccess(response.data.response));
            history.push("/customer-outgoing-disputed-claims-result");
          }
        } else {
          toast.error("No records found");
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong");
      });
  };
};

const runDisputedClaimApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("disputed-outgoing-claims", data, { headers: headers });
  return result;
};

export const disputedClaimSuccess = (data) => {
  return {
    type: DISPUTED_CLAIM_DETAIL_SUCCESS,
    data,
  };
};
export const downloadClaimSubmissions = (data, type, email) => {
  return (dispatch) => {
    dispatch(fetchStart());
    downloadClaimSubmissionsAPI(data, type, email)
      .then((response) => {
        dispatch(fetchEnd());
        if (type == "email") {
          toast.success("Email Sent Successfully!");
        } else {
          console.log(response.headers);
          var [data, data2] = response.headers["content-type"].split(" ");
          toast(
            "Please wait." + type == "pdf"
              ? "Pdf"
              : "File" + " is getting downloaded."
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var date = Date.now();
          link.setAttribute("download", data2); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.statusText
        )
          toast.error(error.response.statusText);
      });
  };
};
const downloadClaimSubmissionsAPI = (data, type, email) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (type == 'email')
    result = axios.post("download-disputed-outgoing-claims?send_mail=true", data, { headers: headers });
  else if (type == 'EDI')
    result = axios.post("download-disputed-outgoing-claims-edi", data, {
      headers: headers,
      responseType: "blob",
    });
  else
    result = axios.post("download-disputed-outgoing-claims", data, {
      headers: headers,
      responseType: "blob",
    });
  return result;
};
// update outgoing claims
export const updateOutgoingClaimData = (id, formData) => {
  return (dispatch) => {
    updateOutgoingClaimDataApi(id, formData)
      .then((response) => {
        toast.success("Successfully updated");
        dispatch(editOutgoingClaim(formData.claim_number));
      })
      .catch((error) => {
        //toast.error("Error in updating data. Please try again.");
      });
  };
};

const updateOutgoingClaimDataApi = (id, formData) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.put("update-outgoing-claims/" + id, formData, {
    headers: headers,
  });
  return result;
};

export const getPricingMarginRevenueData = (sdate, edate, attr, applicationType) => {
  return (dispatch) => {
    dispatch(fetchStart());
    pricingMarginRevenueDataAPI(sdate, edate, attr, applicationType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(pricingMarginRevenueDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
export const getPricingMarginRevenueDashboardData = (sdate, edate, attr, applicationType) => {
  return (dispatch) => {
    dispatch(fetchStart());
    pricingMarginRevenueDataAPI(sdate, edate, attr, applicationType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(pricingMarginRevenueDashboardData(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};
const pricingMarginRevenueDataAPI = (sdate, edate, attr, applicationType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "pricing/margin-revenue-contribution?start_date=" +
    sdate +
    "&&end_date=" +
    edate +
    "&&attribute_name=" +
    attr +
    "&application_type=" +
    applicationType,
    { headers: headers }
  );

  return result;
};

export const pricingMarginRevenueDataSuccess = (data) => {
  return {
    type: PRICING_MARGIN_REVENUE,
    data,
  };
};
export const pricingMarginRevenueDashboardData = (data) => {
  return {
    type: PRICING_MARGIN_REVENUE_DASHBOARD,
    data,
  };
};
export const getTopBottomPerformerData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTopBottomPerformerDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getTopBottomPerformerDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(ServerError);
      });
  };
};
const getTopBottomPerformerDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("sales-top-bottom-entities", data, { headers: headers });

  return result;
};
export const getTopBottomPerformerDataSuccess = (data) => {
  return {
    type: TOP_BOTTOM_PERFORMER,
    data,
  };
};
export const getMarginPriceDiff = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMarginPriceDiffAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getMarginPriceDiffSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(ServerError);
      });
  };
};
const getMarginPriceDiffAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.post("margin-price-analytics", data, { headers: headers });

  return result;
};
export const getMarginPriceDiffSuccess = (data) => {
  return {
    type: MARGIN_PRICE__DIFF,
    data,
  };
};

//rebate tiles
export const getRebateDashboardData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getRebateDashboardDataAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getRebateDashboardDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(ServerError);
      });
  };
};
const getRebateDashboardDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incentives/postings-total-amount",
    { params: data },
    { headers: headers }
  );

  return result;
};
export const getRebateDashboardDataSuccess = (data) => {
  return {
    type: DASHBOARD_DATA,
    data,
  };
};

export const getNewCustomers = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getNewCustomersAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getNewCustomersSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // toast.error(ServerError);
      });
  };
};
const getNewCustomersAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "incentives/postings-by-new-master-data",
    { params: data },
    { headers: headers }
  );

  return result;
};
export const getNewCustomersSuccess = (data) => {
  return {
    type: NEW_CUSTOMERS,
    data,
  };
};
