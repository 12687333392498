import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    Button, Fab
} from '@mui/material';
import { LibraryAdd } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    uploadPromotionMappingFile,
    deletePromotionMappingFormatData
} from '../../../redux/actions';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import BeatLoader from "react-spinners/BeatLoader";
import { getPromotionMappingFormatData } from '../../../redux/actions/Promotion/AuthAction';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    description: {
        textAlign: 'left',
        paddingLeft: 28
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    }
}));
const PromotionMappingExcelUpload = props => {

    const { className } = props;
    const inputFile = useRef(null)
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [currentEditableID, setCurrentEditableID] = useState('');
    const [promoData, setPromoData] = useState([]);
    useEffect(() => {
        props.getPromotionFormatData();
    }, []);
    const handleDrop = (event, id) => {
        const data = new FormData();
        data.append('file', event.target.files[0]);
        props.uploadPromotionFile(id, data);
    }

    useEffect(() => {
        if (props.promoFormatData) {
            setPromoData(props.promoFormatData)
        }
    }, [props.promoFormatData]);

    function runQuery(item) {
        setCurrentEditableID(item.id);
        inputFile.current.click();
    }
    function editQuery(item) {
        localStorage.setItem('promotion-mapping-query', JSON.stringify(item))
        history.push('/promo-mapping/promo-mapping-excel-upload/edit/' + item.id);

    }
    function viewQuery(item) {
        localStorage.setItem('promotion-mapping-query', JSON.stringify(item))
        history.push('/promo-mapping/promo-mapping-excel-upload/view/' + item.id);
    }
    function deleteQuery(item) {
        props.deletePromotionFormatData(item.id);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <div className={clsx({
                [classes.row]: isDesktop
            })} >
                <Typography variant="h1" color='primary'>
                    Promotion Mapping Excel Upload</Typography>
                <Fab aria-label="edit" variant="extended"
                    size='medium'
                    classes={{ root: classes.fabContainer }}
                    className={classes.fabContainer}
                    // component={CustomRouterLink}
                    onClick={() => history.push('/promo-mapping/promo-mapping-excel-upload/add-query')}
                >
                    <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                </Fab>
            </div>
            {
                promoData && promoData.length > 0 ?
                    <TableContainer style={{ marginTop: -20 }}>
                        <Table>
                            <TableHead >
                                <TableRow >
                                    <TableCell align='center' className={classes.tabHead}>Format ID</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Format</TableCell>
                                    <TableCell align='center' className={classes.tabHead}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody classes={{ root: classes.table }}>
                                {promoData
                                    .map(item => {
                                        return (
                                            <StyledTableRow key={item.id} >
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.id}</TableCell>
                                                <TableCell align='center' classes={{ root: classes.fontSetting }} style={{ cursor: 'pointer' }}>{item.format_name}</TableCell>
                                                {props.loading ?
                                                    <TableCell width={300} align='center'>
                                                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={6} />
                                                    </TableCell>
                                                    :
                                                    <TableCell align='center' width={300}>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => editQuery(item)}
                                                            size="large">
                                                            <EditIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => viewQuery(item)}
                                                            size="large">
                                                            <VisibilityIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <input type='file'
                                                            accept=".xlsx, .xls, .csv"
                                                            id='file' ref={inputFile} style={{ display: 'none' }}
                                                            onChange={(e) => handleDrop(e, item.id)} />
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => runQuery(item)}
                                                            size="large">
                                                            <PublishIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                        <IconButton
                                                            classes={{ root: classes.IconButton }}
                                                            onClick={() => deleteQuery(item)}
                                                            size="large">
                                                            <DeleteForeverIcon color="disabled" style={{ fontSize: 20 }} disabled={true} />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No invoice query list added.
                    </Typography>
            }
        </div >
    );
};

const mapStateToProps = state => {
    return {
        promoFormatData: state.promotionData.promoMapFormatData,
        loading: state.dataSetupData.loading,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        uploadPromotionFile: (id, file) => dispatch(uploadPromotionMappingFile(id, file)),
        deletePromotionFormatData: (id) => dispatch(deletePromotionMappingFormatData(id)),
        getPromotionFormatData: () => dispatch(getPromotionMappingFormatData()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionMappingExcelUpload);