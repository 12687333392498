import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme, withStyles } from '@mui/material/styles'
import { makeStyles } from '@mui/styles';
import {
    Typography,
    useMediaQuery,
    Button, Fab
} from '@mui/material';
import { connect } from 'react-redux';
import { getAnalysisDesignerData, deleteAnalysisDesigner } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import { AddBoxOutlined, LibraryAdd, DeleteForever, Edit } from '@mui/icons-material';
import SimpleDialog from '../../components/Dialog/SimpleDialog';
import Table from '../../components/Inputs/Table';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    caption: {
        fontSize: 12
    },
    paginationRoot: {
        border: '1px solid #EEEEEE',
        borderTop: 0
    },
    hover: {
        cursor: 'pointer'
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 15,
        marginTop: 15
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    container: {
        minWidth: 0,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10
        }
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize
    },
    fabContainer: {
        marginRight: 5, padding: 3, border: '2px solid', backgroundColor: '#ffff',
        borderColor: theme.palette.primary.main,
        zIndex: 1
    },
    fontSetting: {
        fontSize: theme.typography.h4.fontSize
    }
}));
const DashboardDesignerMain = props => {
    useEffect(() => {
        props.getAttributeMapData(1, 100);
    }, []);

    const { className } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [contractsTableComplete, setContractsTableComplete] = React.useState([]);
    const [attributeData, setAttributeData] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [currentId, setCurrentId] = React.useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getAttributeMapData(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.getAttributeMapData(1, event.target.value);
    };
    const handleSheetDownload = () => {
        if (contractsTableComplete && contractsTableComplete.records && contractsTableComplete.records.length > 0)
            props.downloadSheet(contractsTableComplete.records);
    }
    useEffect(() => {
        if (props.analysisData) {
            const filteredData = props.analysisData.filter(item => item.dashboard_name !== "");
            filteredData.sort((a, b) => (a.application_type > b.application_type ? 1 : -1));
            setAttributeData(filteredData);
        }
    }, [props.analysisData]);

    const editAttribute = (id) => {
        history.push({
            pathname: '/analytics-designer/edit-analytics/' + id,
            id: id
        });
    }
    function viewAttribute(id) {
        history.push({
            pathname: '/dashboard-designer/view-analytics/' + id,
            id: id
        });
    }
    const deleteData = (id) => {
        props.deleteAnalysisDesigner(id);
    }

    const handleClickOpen = (Id) => {
        setOpen(true);
        setCurrentId(Id);
    };

    const handleDialog = (bool) => {
        if (bool) {
            setOpen(false);
            deleteData(currentId);
        } else {
            setOpen(false);
        }
    }

    const columns = [
        {
            field: "application_type",
            title: "Application Type",
            editable: 'never',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.application_type}
                </div>
            ),
        },
        {
            field: 'security_role',
            title: 'Persona',
            type: 'string',
            render: rowData => {
                const roles = Array.isArray(rowData.security_role) ? rowData.security_role.join(', ') : rowData.security_role;
                return (
                    <div
                        onClick={() => viewAttribute(rowData.ID)} // Assuming this ID is for security_role
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        {roles}
                    </div>
                );
            },
        },
        {
            field: 'dashboard_name',
            title: 'DashBoard Name',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.dashboard_name}
                </div>
            ),
        },
        {
            field: 'dashboard_description',
            title: 'DashBoard Description',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.dashboard_description}
                </div>
            ),
        },
        {
            field: 'analytic_name',
            title: 'Analytic Name',
            type: 'string',
            render: (item) => (
                <div
                    onClick={() => viewAttribute(item.ID)}
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                >
                    {item.analytic_name}
                </div>
            ),
        },
        {
            field: 'action',
            title: 'Actions',
            type: 'string',
            editable: 'never',
            render: rowData =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                        classes={{ root: classes.IconButton }}
                        onClick={() => handleClickOpen(rowData.ID)}
                        size="large">
                        <DeleteForever color="disabled" style={{ fontSize: 20 }} />
                    </IconButton>
                </div>

        },
    ];


    return (
        <div className={clsx(classes.root, className)}>
            <LoadingOverlay
                active={props.loading}
                spinner
                styles={{
                    spinner: (base) => ({
                        ...base,
                        width: '50px',
                        '& svg circle': {
                            stroke: '#045FB4'
                        }
                    }),
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(52, 52, 52, 0)'
                    }),
                    content: (base) => ({
                        ...base,
                        color: 'black'
                    })
                }}
                text='Loading . Please wait ...'
                className={classes.spinner}
            >
                <div className={clsx({
                    [classes.row]: isDesktop
                })} >
                    <Typography variant="h1" color='primary' style={{ marginLeft: 10, marginTop: 10 }} > DashBoard Designer </Typography>
                    <div>

                        <Fab aria-label="edit" variant="extended"
                            size='medium'
                            classes={{ root: classes.fabContainer }}
                            className={classes.fabContainer}
                            onClick={() => history.push('/dashboard-designer/add-dashboard')}
                        >
                            <LibraryAdd color='primary' /> <Typography color='primary' style={{ marginLeft: 3 }}>Add</Typography>
                        </Fab>
                    </div>

                </div>
                {attributeData && attributeData.length > 0 ?
                    <Table columns={columns} dataRows={attributeData} />
                    :
                    <div>
                        There is no data to be shown
                    </div>
                }
                <SimpleDialog open={open} content='Do you want to delete data?' handleDialog={handleDialog} />
            </LoadingOverlay>
        </div >
    );
};

const mapStateToProps = state => {
    return {
        analysisData: state.AnalyticsDesignerData.AnalysisData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getAttributeMapData: (pagination, limit) => dispatch(getAnalysisDesignerData(pagination, limit, "dashboard")),
        deleteAnalysisDesigner: (id) => dispatch(deleteAnalysisDesigner(id, "dashboard"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardDesignerMain);