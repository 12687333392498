import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Dialog,
    DialogActions
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
    updateTerritoryFormatData,getTerritoryFormatFields,getTerritoryFormatDataById
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    button: {
        marginRight: 25,
        width: 140
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    formLabel: {
        fontSize:theme.typography.h3.fontSize,
        height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    fontSetting:{
        fontSize:theme.typography.h4.fontSize
    },
    tabHead: {
        backgroundColor: theme.palette.primary.main,
        fontSize:theme.typography.h4.fontSize
    },  
    paper: { minWidth: window.screen.width * .50 }
}));

const TerritoryEditQuery = props => {
    const classes = useStyles();
    const handleInitialSetup = () => {
        // setQueryObject({ ...queryObject, 'format_name': queryName });
        setOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [queryName, setQueryName] = React.useState('');
    const [territoryID, setTerritoryID] = React.useState('');
    const [parentTerritoryID, setParentTerritoryID] = React.useState('');
    const [territoryName, setTerritoryName] = React.useState('');
    const [parentTerritoryName, setParentTerritoryName] = React.useState('');
    const [defaultValue,setDefaultValue]=React.useState({});
    const requiredFields=[
        'territory_id','territory_name','parent_territory_id','parent_territory_name'
    ]
    const [purchaseQueryDataStorage, setPurchaseQueryDataStorage] = React.useState(localStorage.getItem('territory-query') ? JSON.parse(localStorage.getItem('territory-query')) : []);

    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    const handleDefaultValue = (e) => {
        setDefaultValue(e.target.value);
    }
    //newfields
    const [queryObject, setQueryObject] = React.useState({});

    useEffect(() => {
        setOpen(true);
        props.getTerritoryFormatFields();
        props.getTerritoryFormatDataById(purchaseQueryDataStorage.id)
    }, [purchaseQueryDataStorage]);

    useEffect(() => {
        if (props.territorySingleFormatData) {
            if(purchaseQueryDataStorage.format_name!==0)
            setQueryName(purchaseQueryDataStorage.format_name)
            if(props.territorySingleFormatData?.default_values!==null) {
                setDefaultValue(props.territorySingleFormatData?.default_values)
                if(props.territorySingleFormatData?.default_values?.territory_name)          
            setTerritoryName(props.territorySingleFormatData?.default_values?.territory_name)
            // if(props.territorySingleFormatData.default_values.territory_id!==0)
            setTerritoryID(props.territorySingleFormatData?.default_values?.territory_id)
            // if(props.territorySingleFormatData.default_values.parent_territory_id!==0)
            setParentTerritoryID(props.territorySingleFormatData?.default_values?.parent_territory_id)
            // if(props.territorySingleFormatData.default_values.parent_territory_name!==0)
            setParentTerritoryName(props.territorySingleFormatData?.default_values?.parent_territory_name)
            }
            if(purchaseQueryDataStorage.format!==0)
            setQueryObject(purchaseQueryDataStorage)
            if(purchaseQueryDataStorage.territory_name)
            setTerritoryName(purchaseQueryDataStorage.territory_name)
            if(purchaseQueryDataStorage.territory_id)
            setTerritoryID(purchaseQueryDataStorage.territory_id)
            if(purchaseQueryDataStorage.parent_territory_id)
            setParentTerritoryID(purchaseQueryDataStorage.parent_territory_id)
            if(purchaseQueryDataStorage.parent_territory_name)
            setParentTerritoryName(purchaseQueryDataStorage.parent_territory_name)   
        }
    }, [props.territorySingleFormatData]);
   
    function handleClear() {
        for (var propName in queryObject) {
            if(propName!='format_name')
            {
                 queryObject[propName] =''
            }
        }
        for (var propName in defaultValue) {
            if (propName != 'default_values') {
                defaultValue[propName] = ''
            }
        }
        setDefaultValue({ ...defaultValue });
        setQueryObject({ ...queryObject });
    }
    function handleOnSubmit() {
        for (var propName in queryObject) {
            if (queryObject[propName] === null || queryObject[propName] === undefined || queryObject[propName] === '') {
                delete queryObject[propName];
            }
        }
        for (var propName in defaultValue) {
            if (defaultValue[propName] === null || defaultValue[propName] === undefined || defaultValue[propName] === '') {
                delete defaultValue[propName];
            }
        }
        
        props.onSubmit(purchaseQueryDataStorage.id, {...queryObject,'format_name':queryName,'default_values':defaultValue });
    }
    function handleClearAll() {
        handleClear();
        setTerritoryID('')
        setTerritoryName('')
        setParentTerritoryID('')
        setParentTerritoryName('')
       
    }
    function handleCancelDialog() {
        history.push('/territory/territory-excel-upload');
    }
    const handleChange = (event, item) => {
        if (event.target.value) {
            setQueryObject({ ...queryObject, [item]: parseInt(event.target.value) });
        } else {
            setQueryObject({ ...queryObject, [item]: 0 });
        }
        if(item==='territory_name'){
            setTerritoryName(event.target.value)
        }
        if(item=='parent_territory_id'){
            setParentTerritoryID(event.target.value)
        }
        if(item=='parent_territory_name'){
            setParentTerritoryName(event.target.value)
        }
        if(item=='territory_id'){
            setTerritoryID(event.target.value)
        }
    };
    const handleChange2 = (event, item) => {
        if (event.target.value) {
            setDefaultValue({ ...defaultValue, [item]: event.target.value });
        } else {
            setDefaultValue({ ...defaultValue, [item]: '' });
        }
        if(item==='territory_name'){
            setTerritoryName(event.target.value)
        }
        if(item=='parent_territory_id'){
            setParentTerritoryID(event.target.value)
        }
        if(item=='parent_territory_name'){
            setParentTerritoryName(event.target.value)
        }
        if(item=='territory_id'){
            setTerritoryID(event.target.value)
        }};
    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/territory/territory-excel-upload'
                >
                   Territory Format
                </Link>
                <Typography color="textPrimary" variant='h4'>Edit Format</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Format Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName} />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={queryName ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.territoryListData ?
                                <div>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tabHead} >Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tabHead}  style={{ width: 200 }}>Sequence Number</TableCell>
                                                        <TableCell align='center' className={classes.tabHead} style={{ width: 200 }}>Default Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {props.territoryListData
                                                        .filter(item => item != 'format_name' && item != '')
                                                        .map((item) => {
                                                            return (
                                                                <TableRow key={item}>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                                    { requiredFields.includes(item)? <p>{item.replace(/_/g, ' ')}<span style={{color: 'red'}}> &nbsp;*</span></p> : item.replace(/_/g, ' ')}
                                                                    
                                                                </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70 }}>
                                                                        <TextField
                                                                            value={queryObject[item]? queryObject[item]:''}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            onChange={(e) => handleChange(e, item)}
                                                                            // defaultValue={JSON.parse(localStorage.getItem('territory-query'))[item] ? JSON.parse(localStorage.getItem('territory-query'))[item] : ''}
                                                                            />
                                                                    </TableCell>
                                                                    <TableCell classes={{root:classes.fontSetting}} style={{ width: 70, paddingLeft: 10  }}>
                                                                        <TextField
                                                                            value={defaultValue?.[item]||''}
                                                                            type="string"
                                                                             variant="outlined"
                                                                            onChange={(e) => handleChange2(e,item)}  
                                                                            // defaultValue={JSON.parse(localStorage.getItem('territory-query'))[item] ? JSON.parse(localStorage.getItem('territory-query'))[item] : ''}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}
                   disabled={territoryID && territoryName && parentTerritoryID && parentTerritoryName ?false:true}
                   >
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (id, data) => dispatch(updateTerritoryFormatData(id, data)),
        getTerritoryFormatFields: () => dispatch(getTerritoryFormatFields()),
        getTerritoryFormatDataById:(id)=>dispatch(getTerritoryFormatDataById(id)),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
      territoryListData: state.quotaManagementData.territoryFormatFieldData,
      territorySingleFormatData:state.quotaManagementData.territorySingleFormatData,
      
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoryEditQuery);