import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
} from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
}));
const RebateTrends = (props) => {
  const classes = useStyles();
  const [avssGraphData, setAvssGraphData] = React.useState([]);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter1, setAllowedTilesFilter1] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [aggregationField, setAggregationField] = React.useState('contract_type');



  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Monthly Accrual vs Payment" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter1([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter1([]);
      }
    }
  }, [props.allowedDashBoardData]);
  // console.log("11", allowedTilesFilter1)
  useEffect(() => {
    if (allowedTilesFilter1.length > 0) {
      if (allowedTilesFilter1[0].start_date)
        setStartDate(allowedTilesFilter1[0].start_date);
      if (allowedTilesFilter1[0].end_date)
        setEndDate(allowedTilesFilter1[0].end_date);
      if (allowedTilesFilter1[0].data_source_type)
        setSourceDataType(allowedTilesFilter1[0].data_source_type);
      if (allowedTilesFilter1[0].groupBy)
        setAggregationField(convertToSnakeCase(allowedTilesFilter1[0].groupBy))
    }
  }, [allowedTilesFilter1]);


  useEffect(() => {
    if (startDate && endDate && sourceDataType && aggregationField) {
      props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), aggregationField);
    }

  }, [allowedTilesFilter1]);

  function convertToSnakeCase(string) {
    return string.replace(/\s+/g, '_').toLowerCase();
  }

  // useEffect(() => {
  //   if (props.accrualDashBoardPaymentGraphData)
  //     setAvssGraphData(props.accrualDashBoardPaymentGraphData);
  // }, [props.accrualDashBoardPaymentGraphData]);

  useEffect(() => {
    if (props.accrualDashBoardPaymentGraphData) {
      if (
        props.accrualDashBoardPaymentGraphData &&
        ((props.accrualDashBoardPaymentGraphData.accrual_result &&
          props.accrualDashBoardPaymentGraphData.accrual_result !== null &&
          props.accrualDashBoardPaymentGraphData.accrual_result.length > 0) ||
          (props.accrualDashBoardPaymentGraphData.payment_result &&
            props.accrualDashBoardPaymentGraphData.payment_result !== null &&
            props.accrualDashBoardPaymentGraphData.payment_result.length > 0))
      ) {
        var data = [
          {
            data:
              props.accrualDashBoardPaymentGraphData &&
                props.accrualDashBoardPaymentGraphData.accrual_result
                ? props.accrualDashBoardPaymentGraphData.accrual_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Accrual",
            type: "Spline",
            shape: "Diamond",
            color: "#0000FF",
          },
          {
            data:
              props.accrualDashBoardPaymentGraphData &&
                props.accrualDashBoardPaymentGraphData.payment_result
                ? props.accrualDashBoardPaymentGraphData.payment_result
                : [],
            xName: "Date",
            yName: "Total",
            legend: "Payment",
            type: "Spline",
            shape: "Diamond",
            color: "#CA4218",
          },
        ];
        setAvssGraphData(data);
        //setAvssGraphData(props.accrualDashBoardPaymentGraphData);
      } else setAvssGraphData([]);
    } else setAvssGraphData([]);
  }, [props.accrualDashBoardPaymentGraphData]);

  return (
    <div>
      <SplineChart
        id="rebate-dashboard-charts"
        data={avssGraphData}
      //splineData1={avssGraphData && avssGraphData.accrual_result}
      //splineData2={avssGraphData && avssGraphData.payment_result}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    accrualDashBoardPaymentGraphData: state.initialData.accrualPaymentGraphData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geographicalIncentive: (s, e, postingType) =>
      dispatch(
        geographicalIncentive(
          s,
          e,
          postingType,
          "Customer Rebate",
          "Direct - Sales Data"
        )
      ),
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType, startDate, endDate, aggregationField) =>
      dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType, startDate, endDate, aggregationField)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RebateTrends);
