import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import {
    FormLabel, MenuItem, Select, Grid,
    Table, TableCell, TableHead, TableRow, TableContainer, ListItemText,
    TableBody, OutlinedInput, IconButton, Button, Typography, Checkbox, TextField
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DeleteForever, AddBoxOutlined } from '@mui/icons-material';
import LoadingOverlay from 'react-loading-overlay';
import { HashLoader, RotateLoader } from 'react-spinners'
import Moment from 'moment';
import { getDefaultValues, getContractsTableDataBySearchEligibilty, getLblDispDesFieldValue, getQueryRefById, updateQueryRefDataMaster } from '../../../redux/actions';
import { MultiSelectDropDownOperational, Dropdown, ChipInput } from '../../../components/Inputs';
import momentt from "moment-timezone";
import DynamicQueryFormRef from '../../DynamicQuery/DynamicQueryFormRef';
import FilterDialoge from '../../DynamicQuery/FilterDialoge';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        padding: '10px 29px 29px 29px',
        margin: '30px 0px 30px'
    },
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    },
    container: {
        paddingTop: 18,
        width: '100%'
    },
    container2: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 10,
        padding: '1rem'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30,
        width: '100%'
    },
    button: {
        marginRight: 25,
        width: 160,
        height: 36
    },
    select: {
        color: '#1675e0',
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 30,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        // marginBottom: 10
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25,
        paddingBottom: 16,
        width: '100%'
    },
    selectedItem: {
        borderRadius: 1,
        textTransform: "capitalize",
    },
    gridContainer: {
        padding: '0px 10px 10px 10px'
    },
    selected: {
        width: '150%',
        marginTop: 12
    },
    fontSetup: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        borderRadius: '1rem',
        // backgroundColor: theme.palette.primary.main,
        height: '2.5rem'
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        // paddingLeft: 10
    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const PromotionForm = props => {
    const classes = useStyles();
    const { className } = props;
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [addeligiblity, setAddEligibility] = React.useState(1)
    const [validItem, setValidItem] = React.useState(true)
    const [formData, setFormData] = React.useState([])
    const [attributeNameArray, setAttributeNameArray] = React.useState([])
    var eligibility = { attribute_name: '', attribute_value: [] }
    const [attributes, setAttributes] = React.useState([]);
    const [attributeArray, setAttributeArray] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [allSelect, setAllSelect] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [attributesData, setAttributesData] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);
    const [dynamicFieldValues, setDynamicFieldValues] = useState([]);
    const [dynamicFieldValuesFormSubmit, setDynamicFieldValuesFormSubmit] = useState([]);
    const [dynamicFieldValuesRange, setDynamicFieldValuesRange] = useState("");
    const [billingDateStart, setBillingDateStart] = React.useState(null);
    const [billingDateEnd, setBillingDateEnd] = React.useState(null);
    const [status, setStatus] = React.useState(false);
    const options = ["range", "equal", "not equal", "pattern"];
    const [numericFields, setNumericFields] = React.useState(['duration']);
    const [rangeOption, setRangeOption] = React.useState(['duration', 'document_status']);
    const [dateFields, setDateFields] = React.useState([]);
    const [rangeFields, setRangeFields] = React.useState([]);
    const [trigger, setTigger] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [filterID, setFilterID] = React.useState('');
    const [filterName, setFilterName] = React.useState("");
    const currentQueryID = "contract_header";
    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
    const [dropdownAttributeData, setDropdownAttributeData] = React.useState([]);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 280,
                overflowY: 'scroll',
                scrollBehaviour: ' smooth',
                MarginTop: 0,
                menuPlacement: "top"
            }
        }, getContentAnchorEl: null,
    }
    useEffect(() => {
        props.getLblDispDesFieldValues({ "data_source": "contract_header", "application_type": sessionStorage.getItem('application') }, 'post')
        setFormData([eligibility])
    }, []);
    useEffect(() => {
        if (applicationType && sourceDataType) {
            props.getKeyfigureData(applicationType, sourceDataType, 'attribute_name');
            props.onLoadingDefault(applicationType, sourceDataType);
        }

    }, [applicationType, sourceDataType]);
    useEffect(() => {
        var obj = []
        if (props.dropdownAttributeData && props.dropdownAttributeData.records) {
            Object.entries(props.dropdownAttributeData.records[0].label_names).map((item) => {
                obj.push({ 'desc': item[1], 'key': item[0] })
            })
            setDropdownAttributeData(obj)
        }
    }, [props.dropdownAttributeData])
    useEffect(() => {
        if (props.tableDataNew && props.tableDataNew.field_label_attributes) {
            var listData = [];
            Object.values(props.tableDataNew.field_label_attributes).forEach(item => {
                if (!item.ignore_fields && item.display) {
                    if (item.key != 'application_type' && item.key != 'source_data_type' && item.key != 'tier_evaluation_level') {
                        listData.push(item)
                    }
                    if (item.key === 'tier_evaluation_level') {
                        listData.push(
                            {
                                "current": item.current,
                                "data_type": item.data_type,
                                "default": item.default,
                                "display": item.display,
                                "drop_down_reference": item.drop_down_reference,
                                "drop_down_reference_value": dropdownAttributeData,
                                "drop_down_table": item.drop_down_table,
                                "ignore_dropdown": item.ignore_dropdown,
                                "ignore_fields": item.ignore_fields,
                                "key": item.key,
                                "mandatory": item.mandatory,
                                "required": item.required,
                                "type": item.type
                            }
                        )
                        // listData.push({ 'field_id': item.key, 'name': item.current, 'type': item.type, 'drop_down_value_keys': dropdownAttributeData })
                    }
                    if (item.type === 'TIME') {
                        dateFields.push(item.key)
                    }
                    if (item.type === 'STRING') {
                        rangeFields.push(item.key)
                    }
                }
                if (item.key === 'source_data_type') {
                    setSourceDataTypeArray({ ...item.drop_down_reference_value, 'edited_name': item.current });
                }
            });
            setAttributeArray(listData);
        }
    }, [props.tableDataNew, dropdownAttributeData]);
    useEffect(() => {
        var temp;
        var range
        let tempSelectedFilter = [];
        if (props.predefinedFilter && props.predefinedFilter.filter_fields && filterName === props.predefinedFilter.filter_name) {
            setDynamicFieldValuesFormSubmit(props.predefinedFilter.filter_fields)
            setFilterName(props.predefinedFilter.filter_name)
            setFormData(props.predefinedFilter.attribute_key_values)
            Object.values(props.predefinedFilter.filter_fields).map((item, i) => {
                tempSelectedFilter.push(item.filter_name)
                temp = { ...temp, [item.filter_name]: item.filter_value && item.filter_value.from ? item.filter_value.from : item.filter_value }
                if (item.filter_value && item.filter_value.to)
                    range = { ...range, [item.filter_name]: item.filter_value.to }
            })
            setDynamicFieldValues(temp)
            setDynamicFieldValuesRange(range)
            setAttributes(tempSelectedFilter)
            setAttributesData(attributeArray.filter(item => tempSelectedFilter.includes(item.key)))
        }
        else {
            setDynamicFieldValues({})
            setDynamicFieldValuesFormSubmit({})
            setDynamicFieldValuesRange({})
        }
    }, [props.predefinedFilter])
    useEffect(() => {
        if (props.attributeData && props.attributeData.records && props.attributeData.records.length > 0) {
            if (props.attributeData.records[0].label_names)
                setAttributeNameArray(props.attributeData.records[0].label_names);
        }
    }, [props.attributeData]);
    const handleSearch = (type) => {
        if (type === 'Save Search') {
            setOpenFilter(true)
        }
        if (type === 'Save Search & Run') {
            setOpenFilter(true)
            setTigger(true)
        }
    }
    const handleFilter = (item) => {
        setFilterID(item.id)
        setFilterName(item.filter_name)
        props.getPredefinedFilter(item.id)
    }
    const handleSelectValues = (event, value, item) => {
        let newSelected = [];
        let detail = [];
        const selectedIndex = selected.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
            detail = detail.concat(selectedDetail, item)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (value === "all") {
            setSelected(selected.length === attributeArray.length ? [] : attributeArray.map((item, i) => item.key));
            setAllSelect(selected.length === attributeArray.length ? false : true)
            setSelectedDetail(selected.length === attributeArray.length ? [] : attributeArray)
        }
        else {
            setSelected(newSelected)
            setAllSelect(newSelected.length === attributeArray.length ? true : false)
            if (selectedIndex != -1) {
                setSelectedDetail(selectedDetail.filter((item, i) => i != selectedIndex))
            }
            else
                setSelectedDetail(detail)
        }
    }
    function onAdd() {
        setSelectOpen(false)
        setAttributes(selected)
        setAttributesData(selectedDetail)
        //setDynamicFieldValues({ ...dynamicFieldValues, [level]: selected });
        //setDynamicFieldValuesFormSubmit({ ...dynamicFieldValuesFormSubmit, [level]: selected })
    }
    function onCancel() {
        setSelectOpen(false)
        setSelected([])
    }
    const handleOpen = () => {
        setSelectOpen(true)
        setSelected(attributes)
        setAllSelect(attributeArray && attributeArray.length > 0 && attributes.length === attributeArray.length)
    }
    function handleSubmit() {
        formData.map((item, index) => {
            if (item.attribute_name === '' && item.attribute_value.length === 0) {
                delete formData[index]
            }

        })
        var data = {
            "application_type": applicationType,
            "source_data_type": sourceDataType,
            "rule_type": "eligibility",
            "attribute_key_values": formData.filter(value => Object.keys(value).length !== 0),
            "filter_fields": {
                ...dynamicFieldValuesFormSubmit
            },
        }
        sessionStorage.setItem('search-contract-data', JSON.stringify(data))
        props.onSubmit(data)
    }
    const handleSaveAndRun = (name, type) => {
        setOpenFilter(false)
        formData.map((item, index) => {
            if (item.attribute_name === '' && item.attribute_value.length === 0) {
                delete formData[index]
            }

        })
        var data = {
            "application_type": applicationType,
            "source_data_type": sourceDataType,
            "rule_type": "eligibility",
            "attribute_key_values": formData.filter(value => Object.keys(value).length !== 0),
            "filter_fields": {
                ...dynamicFieldValuesFormSubmit
            },
        }
        if (dynamicFieldValuesFormSubmit) {
            var formData1 = {
                query_id: "contract_header",
                filter_name: name,
                filter_fields: dynamicFieldValuesFormSubmit,
                attribute_key_values: formData.filter(value => Object.keys(value).length !== 0),
            };
            if (type == 'edit')
                formData1['ID'] = filterID
            props.saveAndRun(type, formData1);
            if (trigger) {
                props.onSubmit(data);
            }
        }
    }
    const handleSourceDataType = (e) => {
        setSourceDataType(e.target.value);
    }
    const handleStartDate = (value) => {
        setStartDate(value);
    }
    const handleEndDate = (value) => {
        setEndDate(value);
    }
    function handleDeleteLocally(index) {
        formData.map((item, i) => {
            if (i == index - 1) {
                delete formData[i]
            }
        })
        setFormData(formData.filter(value => JSON.stringify(value) !== '{}'));
        setAddEligibility(addeligiblity - 1)
        checkvalues()
    }
    function handleChangeValue(field, index, value) {

        if (field == 'attribute_value')
            value = value
        formData[index - 1][field] = value
        setFormData([...formData])
        checkvalues()
    }
    function handleOnAdd() {
        var newValue = formData
        newValue = [...newValue, eligibility]
        setFormData(newValue);
        setAddEligibility(addeligiblity + 1)
        setValidItem(false)
    }
    function checkvalues() {
        formData.map((item, index) => {
            if (item.attribute_name === '' || item.attribute_value.length === 0 || item.option === '' || item.condition_id === 0) {
                setValidItem(false)
            }
            else
                setValidItem(true)

        })
    }

    const handleBillingDate = (e, type, fieldName) => {
        if (type == "start") {
            setBillingDateStart(e);
            setDynamicFieldValues({
                ...dynamicFieldValues,
                [fieldName]: Moment(e).format("YYYY-MM-DD"),
            });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = Moment(e).format("YYYY-MM-DD")
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: Moment(e).format("YYYY-MM-DD")
                    }
                });
            }
        } else {
            setBillingDateEnd(e);
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: dynamicFieldValues[fieldName], to: Moment(e).format("YYYY-MM-DD") },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(Moment(e).format("YYYY-MM-DD"));
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        }
    };
    const handleDynamicFieldValues = (e, fieldName, type, page) => {
        if (!dynamicFieldValuesFormSubmit[fieldName]) {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: { filter_name: fieldName },
            });
        }
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    var value = page == 'master' ? e.key : e
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: value });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: {
                                from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName]) : dynamicFieldValues[fieldName],
                                to: numericFields.includes(fieldName) ? Number(value) : value.toString()
                            },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange({ ...dynamicFieldValuesRange, [fieldName]: '' });
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                // setDynamicFieldValuesRange("");
            }
        } else if (type === "option") {
            setDynamicFieldValuesFormSubmit({
                ...dynamicFieldValuesFormSubmit,
                [fieldName]: {
                    ...dynamicFieldValuesFormSubmit[fieldName],
                    filter_name: fieldName,
                    filter_option: e,
                },
            });
        } else {
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: e });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(e) : e
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? e.map(Number) : e,
                    },
                });
            }
        }
    };

    const handleDynamicFieldValuesAdd = (e, fieldName, type, ix) => {
        if (type === "range") {
            if (
                dynamicFieldValues[fieldName] &&
                dynamicFieldValues[fieldName].length > 0
            ) {
                if (e) {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_value: { from: numericFields.includes(fieldName) ? Number(dynamicFieldValues[fieldName][0]) : dynamicFieldValues[fieldName][0], to: numericFields.includes(fieldName) ? Number(e) : e },
                        },
                    });
                } else {
                    setDynamicFieldValuesRange(e);
                    setDynamicFieldValuesFormSubmit({
                        ...dynamicFieldValuesFormSubmit,
                        [fieldName]: {
                            ...dynamicFieldValuesFormSubmit[fieldName],
                            filter_name: fieldName,
                            filter_value: numericFields.includes(fieldName) ? dynamicFieldValues[fieldName].map(Number) : dynamicFieldValues[fieldName],
                        },
                    });
                }
            } else {
                setDynamicFieldValuesRange("");
            }
        } else {
            var temp = dynamicFieldValues[fieldName]
                ? dynamicFieldValues[fieldName]
                : [];
            if (type === "pastedItem") {
                e.map((arg) => {
                    if (arg) temp.push(arg.trim());
                });
            } else {
                // temp.push(e);
                temp = e;
            }
            setDynamicFieldValues({ ...dynamicFieldValues, [fieldName]: temp });
            if (dynamicFieldValuesFormSubmit[fieldName] && dynamicFieldValuesFormSubmit[fieldName]['filter_value']
                && dynamicFieldValuesFormSubmit[fieldName]['filter_value']['to']) {
                dynamicFieldValuesFormSubmit[fieldName]['filter_value']['from'] = numericFields.includes(fieldName) ? Number(temp) : temp
            }
            else {
                setDynamicFieldValuesFormSubmit({
                    ...dynamicFieldValuesFormSubmit,
                    [fieldName]: {
                        ...dynamicFieldValuesFormSubmit[fieldName],
                        filter_name: fieldName,
                        filter_value: numericFields.includes(fieldName) ? temp.map(Number) : temp,
                    },
                });
            }
        }
    };
    return (
        <LoadingOverlay
            active={props.loading}
            styles={{
                spinner: (base) => ({
                    ...base,
                    width: '50px',
                    '& svg circle': {
                        stroke: '#64C4B4'
                    }
                }),
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(52, 52, 52, 0)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#64C4B4'
                })
            }}
            spinner={<HashLoader />}
            className={classes.spinner}
        >
            <div className={clsx(classes.root, className)}>
                <Typography variant="h1" color='primary'> Contract Search</Typography>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div style={{ display: 'flex' }}>
                        <div className={classes.container} style={{ width: '80%' }}>
                            <Grid container >
                                <Grid container style={{ marginBottom: 20, marginTop: '2rem' }}>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ marginTop: "-1.6rem" }}
                                    >
                                        <Dropdown heading={'Application Type'} style={{ padding: '0.4rem' }} twoline='true' {...props} disabled={true} placeholder={sessionStorage.getItem('application')} />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                    >
                                        <div className={classes.chargeBackLabel}>
                                            <FormLabel required>
                                                {sourceDataTypeArray.edited_name}
                                            </FormLabel>
                                            <Select
                                                value={sourceDataType}
                                                onChange={handleSourceDataType}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >
                                                {sourceDataTypeArray && Object.values(sourceDataTypeArray).length > 0 && Object.values(sourceDataTypeArray)
                                                    .map((item, index) => {
                                                        return (
                                                            <MenuItem value={item.key} key={index}>
                                                                {item.desc}
                                                            </MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                        classes={{ root: classes.gridContainer }}
                                        style={{ display: 'flex' }}
                                    >
                                        <FormLabel classes={{ root: classes.fontSetting }} style={{ marginRight: 20, paddingTop: 5 }}>
                                            Select Required Filters
                                        </FormLabel>
                                        <Select
                                            labelId="demo-mutiple-current-label"
                                            id={'filter-list'}
                                            multiple
                                            value={attributes ? attributes : []}
                                            style={{ maxHeight: "50px", width: '70%', color: 'grey' }}
                                            input={<OutlinedInput />}
                                            MenuProps={{ ...MenuProps, autoFocus: true }}
                                            onOpen={() => handleOpen()}
                                            onClose={() => setSelectOpen(false)}
                                            open={selectOpen}
                                            renderValue={(filter) => {
                                                let type = []
                                                filter.map(itemX => {
                                                    attributeArray.filter(item => item.key == itemX).map((item, i) => {
                                                        if (item.current)
                                                            type.push(item.current)
                                                    })

                                                })
                                                return type.join(',')
                                            }}
                                            className={classes.multiSelect}
                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all')} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={allSelect} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {attributeArray && attributeArray.length > 0 && attributeArray
                                                .sort((a, b) => a.current > b.current ? 1 : -1)
                                                .map((item, i) => {
                                                    return (

                                                        <MenuItem onClick={(event) => handleSelectValues(event, item.key, item)} value={item.key} key={item.key} style={{ textTransform: props.capitalize ? 'capitalize' : 'none' }}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected && selected.indexOf(item.key) > -1}
                                                            />
                                                            <ListItemText primary={item.current} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <MenuItem value='' ></MenuItem>
                                            <div className={classes.dropdownAction}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={onAdd} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </Grid>
                                    <>
                                        {attributesData && attributesData.length > 0 &&
                                            <div className={classes.bodyContainer}>
                                                {attributesData &&
                                                    attributesData.map((item, ix) => {
                                                        return <>
                                                            <Grid
                                                                item
                                                                md={12}
                                                                xs={12}
                                                                classes={{ root: classes.gridContainerOuter }}
                                                                style={{ display: "flex" }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    md={3}
                                                                    xs={12}
                                                                    classes={{ root: classes.gridContainer }}
                                                                    style={{
                                                                        position: 'relative'
                                                                    }}
                                                                >

                                                                    <FormLabel classes={{ root: classes.fontSetting }}
                                                                        style={{
                                                                            // position: 'absolute',
                                                                            // top: '50%',
                                                                            // left: '25%',
                                                                            // transform: 'translate(-50%, -50%)'
                                                                            marginTop: ix === 0 ? '0.7rem' : '-1.3rem'
                                                                        }}>
                                                                        <Typography variant="h3" color='primary' className={ix == 0 && classes.fontSetup} style={{
                                                                            marginTop: '-0.6rem',
                                                                            // position: 'absolute'
                                                                            width: '15rem'
                                                                        }}>{ix == 0 && "Filter Name"}</Typography>
                                                                        {
                                                                            ix == 0 ? <div style={{ marginTop: '8px' }} > {item.current} </div> : item.current
                                                                        }
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={2}
                                                                    xs={12}
                                                                    classes={{ root: classes.gridContainer }}
                                                                >
                                                                    <FormLabel
                                                                        classes={{ root: classes.fontSetting }}
                                                                    >
                                                                        <Typography variant="h3" color='primary' style={{ width: '11.5rem' }} className={ix == 0 && classes.fontSetup} >{ix === 0 && "Option"}</Typography>
                                                                    </FormLabel>
                                                                    <Select
                                                                        value={
                                                                            dynamicFieldValuesFormSubmit[
                                                                                item.key
                                                                            ] &&
                                                                                dynamicFieldValuesFormSubmit[item.key][
                                                                                "filter_option"
                                                                                ]
                                                                                ? dynamicFieldValuesFormSubmit[
                                                                                item.key
                                                                                ]["filter_option"]
                                                                                : ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleDynamicFieldValues(
                                                                                e.target.value,
                                                                                item.key,
                                                                                "option"
                                                                            )
                                                                        }
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        displayEmpty
                                                                        style={{ marginTop: ix === 0 ? '0px' : '-21px', textTransform: 'capitalize' }}
                                                                        className={clsx({
                                                                            [classes.select]: true,
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem,
                                                                        }}
                                                                        disabled={(item.key == "customer_number" || item.key == "supplier_number" || item.key == "employee_number") ? status : false}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {options &&
                                                                            options
                                                                                .filter(itemX => (numericFields.includes(item.key) == false ? (itemX != 'greater than' && itemX != 'greater than equal to' && itemX != 'less than' && itemX != 'less than equal to') : itemX != 'pattern') &&
                                                                                    ((!numericFields.includes(item.key) && !dateFields.includes(item.key) && !rangeFields.includes(item.key)) ? itemX != 'range' : itemX)
                                                                                    && (dateFields.includes(item.key) ? itemX != 'pattern' : itemX) && (rangeOption.includes(item.key) ? itemX != 'not equal' && itemX != 'equal' && itemX != 'pattern' : itemX))
                                                                                .map((item, index) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            value={item}
                                                                                            key={item}
                                                                                            style={{ textTransform: "capitalize" }}
                                                                                        >
                                                                                            {item}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                    </Select>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={7}
                                                                    xs={12}
                                                                    classes={{ root: classes.gridContainer }}
                                                                >
                                                                    <FormLabel
                                                                        classes={{ root: classes.fontSetting }}
                                                                    >
                                                                        <Typography variant="h3" color='primary' style={{ width: '26rem' }} className={ix == 0 && classes.fontSetup} >{ix == 0 && "Filter Value"}</Typography>
                                                                    </FormLabel>
                                                                    {dateFields.includes(item.key) ? (
                                                                        <div style={{
                                                                            display: "flex", marginTop: ix === 0 ? '0rem' : "-1.4rem"
                                                                        }}>
                                                                            < div style={{ width: "60%", marginRight: 5 }}>
                                                                                <LocalizationProvider
                                                                                    libInstance={momentt}
                                                                                    dateAdapter={AdapterMoment}
                                                                                >
                                                                                    <Grid container justifyContent="space-around">
                                                                                        <DatePicker
                                                                                            disableToolbar
                                                                                            clearable
                                                                                            InputProps={{
                                                                                                padding: 0,
                                                                                                disableUnderline: true,
                                                                                                style: {
                                                                                                    // padding: "0px 11px 0px 11px",
                                                                                                    alignSelf: "center",
                                                                                                    alignItems: "center",
                                                                                                    border: "1px solid #E0E0E0",
                                                                                                    width: "100%",
                                                                                                    height: 36,
                                                                                                    // borderRadius: 5,
                                                                                                    // color: "#1675e0",
                                                                                                },
                                                                                            }}
                                                                                            value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : null}
                                                                                            onChange={(e) =>
                                                                                                handleBillingDate(e, "start", item.key)
                                                                                            }
                                                                                            renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                            format="MM/DD/YYYY"
                                                                                        />
                                                                                    </Grid>
                                                                                </LocalizationProvider >
                                                                            </div >
                                                                            {
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.key
                                                                                ] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.key
                                                                                ]["filter_option"] &&
                                                                                dynamicFieldValuesFormSubmit[
                                                                                item.key
                                                                                ]["filter_option"] == "range" &&
                                                                                <div style={{ width: "40%", marginLeft: 5 }}>
                                                                                    < LocalizationProvider
                                                                                        libInstance={momentt}
                                                                                        dateAdapter={AdapterMoment}
                                                                                    >
                                                                                        <Grid container justifyContent="space-around">
                                                                                            <DatePicker
                                                                                                disableToolbar
                                                                                                clearable
                                                                                                InputProps={{
                                                                                                    padding: 0,
                                                                                                    disableUnderline: true,
                                                                                                    style: {
                                                                                                        // padding: "0px 11px 0px 11px",
                                                                                                        alignSelf: "center",
                                                                                                        alignItems: "center",
                                                                                                        border: "1px solid #E0E0E0",
                                                                                                        width: "100%",
                                                                                                        height: 36,
                                                                                                        // borderRadius: 5,
                                                                                                        //color: "#1675e0",
                                                                                                    },
                                                                                                }}
                                                                                                value={dynamicFieldValuesFormSubmit[item.key] && dynamicFieldValuesFormSubmit[item.key]['filter_value']
                                                                                                    && dynamicFieldValuesFormSubmit[item.key]['filter_value']['to'] ? dynamicFieldValuesFormSubmit[item.key]['filter_value']['to'] : null}
                                                                                                onChange={(e) =>
                                                                                                    handleBillingDate(e, "end", item.key)
                                                                                                }
                                                                                                renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                                                                                                format="MM/DD/YYYY"
                                                                                                disabled={
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                    ] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                        ]["filter_option"] &&
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                        item.key
                                                                                                        ]["filter_option"] == "range"
                                                                                                        ? false
                                                                                                        : true
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    </LocalizationProvider >
                                                                                </div >}
                                                                        </div >
                                                                    ) :
                                                                        dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                        ] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                            ]["filter_option"] &&
                                                                            dynamicFieldValuesFormSubmit[
                                                                            item.key
                                                                            ]["filter_option"] == "pattern" ?
                                                                            <div style={{ marginRight: 5, marginTop: ix == 0 ? '0rem' : "-1.3rem", width: "60%" }}>
                                                                                <ChipInput data={dynamicFieldValues[item.key] && typeof (dynamicFieldValues[item.key]) !== 'string'
                                                                                    ? dynamicFieldValues[item.key]
                                                                                    : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} />
                                                                            </div>
                                                                            :
                                                                            item.type == 'BOOL' ?
                                                                                <div style={{ display: "flex", marginTop: ix == 0 ? '-0.6rem' : '-1.9rem', width: "60%", marginRight: 5 }}>
                                                                                    <Select
                                                                                        value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                        style={{ marginTop: "10px", maxHeight: "50px", width: '100%' }}
                                                                                        onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                                        displayEmpty
                                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                                        className={classes.multiSelect}
                                                                                    >
                                                                                        <MenuItem value={'Yes'} key={'Yes'}>
                                                                                            Yes
                                                                                        </MenuItem>
                                                                                        <MenuItem value={'No'} key={'No'}>
                                                                                            No
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                </div>
                                                                                :
                                                                                item.key === 'duration'
                                                                                    ?
                                                                                    <div style={{ display: "flex", marginTop: ix == 0 ? '-0.6rem' : '-1.9rem' }}>
                                                                                        <div style={{ width: "60%" }}>
                                                                                            <OutlinedInput
                                                                                                type={props.type}
                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                                onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)}
                                                                                            />
                                                                                        </div>
                                                                                        {dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                        ] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                            ]["filter_option"] &&
                                                                                            dynamicFieldValuesFormSubmit[
                                                                                            item.key
                                                                                            ]["filter_option"] == "range" &&
                                                                                            <div style={{ width: "40%", marginRight: 5 }}>
                                                                                                <OutlinedInput
                                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                                    type={props.type}
                                                                                                    value={
                                                                                                        dynamicFieldValues[item.key] &&
                                                                                                            dynamicFieldValues[item.key]
                                                                                                                .length > 0
                                                                                                            ? dynamicFieldValuesRange[
                                                                                                            item.key
                                                                                                            ]
                                                                                                            : ""
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleDynamicFieldValues(
                                                                                                            e.target.value,
                                                                                                            item.key,
                                                                                                            "range"
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </div>}
                                                                                    </div>
                                                                                    :

                                                                                    // item.type == 'DROPDOWN.STRING' ?
                                                                                    item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                                        (
                                                                                            <div style={{ display: "flex", marginTop: ix === 0 ? '-0.6rem' : '-1.9rem' }}>
                                                                                                <div style={{ width: "40%", marginRight: 5 }}>
                                                                                                    <MultiSelectDropDownOperational capitalize={true} style={{ width: '100%', marginTop: -4 }}
                                                                                                        listArray={item.drop_down_reference_value} data={dynamicFieldValues[item.key] && dynamicFieldValues[item.key].length ? dynamicFieldValues[item.key] : []} id={item.current} onChange={(e) => handleDynamicFieldValues(e, item.key)} />
                                                                                                </div>
                                                                                                {dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                    ]["filter_option"] == "range" &&
                                                                                                    <div style={{ width: "40%" }}>
                                                                                                        <MultiSelectDropDownOperational capitalize={true} style={{ width: '100%', marginTop: -4 }}
                                                                                                            listArray={item.drop_down_reference_value} data={
                                                                                                                dynamicFieldValues[item.key] &&
                                                                                                                    dynamicFieldValues[item.key]
                                                                                                                        .length > 0
                                                                                                                    ? dynamicFieldValuesRange[
                                                                                                                    item.key
                                                                                                                    ]
                                                                                                                    : []} id={item.current} onChange={(e) => handleDynamicFieldValues(e, item.key, "range")} />
                                                                                                    </div>}
                                                                                            </div>
                                                                                        ) :
                                                                                        (
                                                                                            <div style={{ display: "flex", marginTop: ix == 0 ? '0' : '-1.2rem' }}>
                                                                                                <div style={{ width: "60%", marginRight: 5 }}>
                                                                                                    {
                                                                                                        dynamicFieldValuesFormSubmit[
                                                                                                            item.key
                                                                                                        ] &&
                                                                                                            dynamicFieldValuesFormSubmit[
                                                                                                            item.key
                                                                                                            ]["filter_option"] &&
                                                                                                            dynamicFieldValuesFormSubmit[
                                                                                                            item.key
                                                                                                            ]["filter_option"] !== "range" ?
                                                                                                            <ChipInput
                                                                                                                data={dynamicFieldValues[item.key]
                                                                                                                    ? dynamicFieldValues[item.key]
                                                                                                                    : []} onChange={newValue => handleDynamicFieldValuesAdd(newValue, item.key, null, ix)} />
                                                                                                            :
                                                                                                            <OutlinedInput
                                                                                                                type={props.type}
                                                                                                                classes={{ root: classes.inputTwoLine }}
                                                                                                                value={dynamicFieldValues[item.key] ? dynamicFieldValues[item.key] : []}
                                                                                                                onChange={(e) => handleDynamicFieldValues(e.target.value, item.key)} />
                                                                                                    }
                                                                                                </div>
                                                                                                {dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                ] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                    ]["filter_option"] &&
                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                    item.key
                                                                                                    ]["filter_option"] == "range" &&
                                                                                                    <div style={{ width: "40%", marginRight: 5 }}>
                                                                                                        <OutlinedInput
                                                                                                            classes={{ root: classes.inputTwoLine }}
                                                                                                            type={props.type}
                                                                                                            value={
                                                                                                                dynamicFieldValues[item.key] &&
                                                                                                                    dynamicFieldValues[item.key]
                                                                                                                        .length > 0
                                                                                                                    ? dynamicFieldValuesRange[
                                                                                                                    item.key
                                                                                                                    ]
                                                                                                                    : ""
                                                                                                            }
                                                                                                            onChange={(e) =>
                                                                                                                handleDynamicFieldValues(
                                                                                                                    e.target.value,
                                                                                                                    item.key,
                                                                                                                    "range"
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={
                                                                                                                dynamicFieldValuesFormSubmit[
                                                                                                                    item.key
                                                                                                                ] &&
                                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                                    item.key
                                                                                                                    ]["filter_option"] &&
                                                                                                                    dynamicFieldValuesFormSubmit[
                                                                                                                    item.key
                                                                                                                    ]["filter_option"] == "range"
                                                                                                                    ? false
                                                                                                                    : true
                                                                                                            }
                                                                                                        />
                                                                                                    </div>}
                                                                                            </div>
                                                                                        )
                                                                    }
                                                                </Grid >

                                                            </Grid >

                                                        </>;
                                                    })}
                                            </div >
                                        }
                                    </>
                                </Grid>
                                <div className={classes.container} style={{ paddingRight: '1rem' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell align='center'>Attribute Name <span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                                    <TableCell align='center'>Attribute Value<span style={{ display: validItem ? 'none' : 'inline' }}>*</span></TableCell>
                                                    <TableCell align='center' style={{ width: '100px' }}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody classes={{ root: classes.table }} >
                                                {(() => {
                                                    let td = [];
                                                    for (let i = 1; i <= addeligiblity; i++) {
                                                        td.push(<>
                                                            <StyledTableRow>
                                                                <TableCell>
                                                                    <Select
                                                                        value={formData[i - 1] && formData[i - 1]['attribute_name'] ? formData[i - 1]['attribute_name'] : ''}
                                                                        onChange={(event) => handleChangeValue('attribute_name', i, event.target.value)}
                                                                        style={{ width: '100%', marginTop: -2, textTransform: 'capitalize' }}
                                                                        MenuProps={{ ...MenuProps, autoFocus: true }}
                                                                        displayEmpty
                                                                        className={clsx({
                                                                            [classes.select]: true
                                                                        })}
                                                                        classes={{
                                                                            selectMenu: classes.selectedItem
                                                                        }}
                                                                    >
                                                                        {attributeNameArray && Object.keys(attributeNameArray).length > 0 &&
                                                                            Object.entries(attributeNameArray).map(([k, v]) => {
                                                                                return (
                                                                                    <MenuItem value={k} key={k} style={{ textTransform: 'capitalize' }}>
                                                                                        {v}
                                                                                    </MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div style={{ marginTop: '0rem' }} >
                                                                        <ChipInput
                                                                            id={i}
                                                                            data={formData[i - 1] && formData[i - 1]['attribute_value'] ? formData[i - 1]['attribute_value'] : ''}
                                                                            onChange={(value) => handleChangeValue('attribute_value', i, value)}
                                                                        />
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        style={{ visibility: i == 1 ? 'hidden' : 'visible', padding: 0 }}
                                                                        onClick={() => handleDeleteLocally(i)}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <DeleteForever />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        style={{ visibility: i == addeligiblity ? 'visible' : 'hidden', padding: 0 }}
                                                                        onClick={handleOnAdd}
                                                                        classes={{ root: classes.IconButton }}
                                                                        size="large">
                                                                        <AddBoxOutlined />
                                                                    </IconButton>

                                                                </TableCell>
                                                            </StyledTableRow>

                                                        </>);
                                                    }
                                                    return td;
                                                })()}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid>
                            <div className={classes.buttonContainer}>
                                <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} twoline='true'
                                    disabled={applicationType && validItem ? false : true}
                                >

                                    {props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loading} size={2} />
                                        :
                                        'Run'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search')}
                                    twoline="true"
                                    disabled={applicationType && validItem ? false : true}
                                >
                                    {props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search'
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => handleSearch('Save Search & Run')}
                                    twoline="true"
                                    disabled={applicationType && validItem ? false : true}
                                >
                                    {props.loading ?
                                        <RotateLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                                        :
                                        'Save Search & Run'
                                    }
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: '20%' }} className={classes.container2}>
                            <DynamicQueryFormRef id={currentQueryID} onChange={handleFilter} />
                        </div>
                    </div>
                </form>
            </div >
            {
                openFilter &&
                <FilterDialoge open={openFilter} filterName={filterName}
                    action={filterID ? 'edit' : 'create'}
                    onChange={handleSaveAndRun}
                    onCancel={() => setOpenFilter(false)}
                />
            }
        </LoadingOverlay >
    );
};

const mapStateToProps = state => {
    return {
        attributeData: state.addMultipleConfigurationData.attributeNameData,
        loading: state.promotionData.loading,
        tableDataNew: state.addMultipleConfigurationData.defaultValueLblDisp,
        predefinedFilter: state.operationalReportsData.dynamicQueryFilterData,
        dropdownAttributeData: state.addMultipleConfigurationData.tierEvaluationData,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(getContractsTableDataBySearchEligibilty(data)),
        getKeyfigureData: (applicationType, sourceDataType, field) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', field)),
        getLblDispDesFieldValues: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),
        getPredefinedFilter: (id) => dispatch(getQueryRefById(id)),
        saveAndRun: (type, data) => dispatch(updateQueryRefDataMaster(type, data, null, 'contract_header')),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, 'on', 'tier_evaluation_level')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionForm);