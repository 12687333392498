import {
  ATTRIBUTE_MAPPING_ALL,
  ATTRIBUTE_MAPPING_SINGLE,
  FETCH_START,
  FETCH_END,
} from "./ActionTypes";
import axios from "../../../../axios.conf";
import { toast } from "react-toastify";
import { ServerError } from "../../../../library/constants";
import { history } from "../../../../components/Helpers";
import secureLocalStorage from "react-secure-storage";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const addAttributeMappingDefaultDesigner = (data, edit, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addattributeMappingAPI(data, edit, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push("/attribute-mapping");
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addattributeMappingAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  if (edit === "edit")
    result = axios.put("sales-data-attribute-map/" + id, data, {
      headers: headers,
    });
  else
    result = axios.post("sales-data-attribute-map", data, { headers: headers });
  return result;
};

export const getAttributeMappingData = (pagination, limit) => {
  return (dispatch) => {
    getAttributeMappingAPI(pagination, limit)
      .then((response) => {
        dispatch(getAttributeMappingSuccess(response.data.response));
      })
      .catch((error) => {});
  };
};

const getAttributeMappingAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get(
    "sales-data-attribute-map?page=" + pagination + "&&limit=" + limit,
    { headers: headers }
  );
  return result;
};

export const getAttributeMappingSuccess = (data) => {
  return {
    type: ATTRIBUTE_MAPPING_ALL,
    data,
  };
};

export const getSingleAttributeMapping = (id) => {
  return (dispatch) => {
    getSingleAttributeMappingAPI(id)
      .then((response) => {
        dispatch(getAttributeMappingSuccessSingle(response.data.response));
      })
      .catch((error) => {
        //toast.error('Error!');
      });
  };
};

const getSingleAttributeMappingAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.get("sales-data-attribute-map/" + id, { headers: headers });
  return result;
};

export const getAttributeMappingSuccessSingle = (data) => {
  return {
    type: ATTRIBUTE_MAPPING_SINGLE,
    data,
  };
};

export const deleteAttributeMapping = (id) => {
  return (dispatch) => {
    deleteAttributeMappingApi(id)
      .then((response) => {
        dispatch(getAttributeMappingData(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {});
  };
};

const deleteAttributeMappingApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem("aTk"),
  };
  var result;
  result = axios.delete("sales-data-attribute-map/" + id, { headers: headers });
  return result;
};
