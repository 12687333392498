import React, { useEffect,useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@mui/styles';
import { runInvoiceReports, downloadSheet, searchInvoice, getDefaultValues } from '../../../redux/actions';
import { connect } from 'react-redux';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TablePagination,
    Typography,
    Breadcrumbs,
    Link,
    useMediaQuery,
    Button
} from '@mui/material';
import Moment from 'moment';
import { history } from '../../../components/Helpers';
import { Paper, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: 29,
        marginTop: 16
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    tableCell: {
        paddingLeft: 18
    },
    rootSearch: {
        borderBottomColor: theme.palette.table.border,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'center',
        display: 'flex',
        alignSelf: 'flex-end',
        borderRadius: 0,
        padding: 0,
        minWidth: 200,
        [theme.breakpoints.down('lg')]: {
            minWidth: 0,
            marginTop: 10,
            marginRight: 15,
        },
    },
    searchInput: {
        marginRight: 47
    },
    input: {
        fontSize: 14,
        flexGrow: 1,
        lineHeight: '16px',
        letterSpacing: '-0.05px',
        paddingLeft: 10,
    },
    iconButton: {
        padding: 0,
    },
    icon: {
        fontSize: 20,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        marginBottom: 15
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.table.row,
            height: 46
        },
        '&:nth-of-type(even)': {
            height: 40
        },
    },
}))(TableRow);

const InvoiceReportsTab = props => {
    var newArray = [];
    const classes = useStyles();
    const theme = useTheme();
    const { className } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([5, 10, 15]);
    const [invoiceReportsArray, setInvoiceReportsArray] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const contractCustomisationUpdation = useRef();
    const [tabHeads, setTabHeads] = React.useState([]);
    const [tabKeys, setTabKeys] = React.useState([]);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        setRowsPerPage(event.target.value);
        props.loadInvoiceDetails(1, event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.loadInvoiceDetails(newPage + 1, rowsPerPage);
    };
    const handleSheetDownload = () => {
        props.downloadSheet(invoiceReportsArray.records);
    }
    const handleSearch = () => {
        props.loadInvoiceDetails(1, 10, searchKeyword);
    }
    const handleSearchInput = (event) => {
        setSearchKeyword(event.target.value);
    }
    var enabledFormFields = [];
    useEffect(() => {
        if (props.invoiceCompleteData) {
            setInvoiceReportsArray(props.invoiceCompleteData);
        }
    }, [props.invoiceCompleteData]);
    useEffect(() => {
        props.onLoadingDefault();
    }, []);

    useEffect(()=>{
        var keywordsArray=['calendar_year_month','billing_date','billing_doc_number','billing_doc_line','customer_number',
            'net_value','quantity','billed_uom','material_number','supplier_number','employee_number','customer_name','material_description',
            'supplier_name','employee_name','unit_price','currency','fiscal_year_month','base_uom','pricing_date',
            'profit_center','order_reason','company_code','sales_org', 'distribution_channel','division',
            'flex_attribute1','flex_attribute2','flex_attribute3','flex_attribute4','flex_attribute5','flex_key_figure1','flex_key_figure2',
            'flex_key_figure3', 'flex_key_figure4','flex_key_figure5','flex_key_figure6','flex_key_figure7','flex_key_figure8','flex_key_figure9','flex_key_figure10','incentive_basis1','incentive_basis2','incentive_basis3',
            'incentive_basis4','incentive_basis5','sold_to_party','ship_to_party','bill_to_party','payer','customer_group',
            'customer_chain','customer_group1','customer_group2','customer_group3','customer_group4','material_group',
            'material_group1','material_group2','material_type','product_hierarchy','product_hierarchy_level1','product_hierarchy_level2',
            'product_hierarchy_level3', 'product_hierarchy_level4','product_hierarchy_level5','supplier_group','supplier_customer_number','sales_district','sales_group','sales_office',
            'region','country','territory_id','sales_order_type','sales_order_number','sales_order_line','delivery_type',
            'delivery_doc_number','delivery_doc_line','document_category','billing_type','item_category','debit_credit_indicator',
            'billing_doc_cancelled','cancelled_billing_doc','original_invoice_for_return','original_invoice_line_for_return','controlling_area',
            'cost_center','mfr_part_number','pa_segment_number_copa','pricing_procedure','payment_terms','plant','higher_level_bom_item',
            'source_system','ima_ineligible','ima_ineligible_application','zero_value_item','accounting_doc_line','accounting_doc_number',
            'billing_document_created_by','billing_document_created_date', 'billing_document_changed_by',
            'billing_document_changed_date','billingDocument_extracted_by','billing_document_extracted_date',
            'deleted','accrual_created_by','created_by_id',
       ]     
       if (!contractCustomisationUpdation.current) {
        if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
            enabledFormFields = props.tableData.records.filter(itemY => itemY.enabled != false).map(itemY => { return itemY.field_id });
        }
      
        if (props.tableData && props.tableData.records && props.tableData.records.length > 0) {
            keywordsArray.forEach(function (elem) {
                
               if(elem ==='supplier_customer_number'){
                    setTabHeads(tabHeads => [...tabHeads, 'Supplier Customer Number']);
                    setTabKeys(tabKeys => [...tabKeys, 'supplier_customer_number']);  
                }
                else if(elem ==='created_by_id'){
                    setTabHeads(tabHeads => [...tabHeads, 'Created By ID']);
                    setTabKeys(tabKeys => [...tabKeys, 'created_by_id']);  
                }
                else{
                     props.tableData.records
                    .filter(itemY => itemY.field_id === elem)
                    .filter(itemY => itemY.enabled == true)
                    .map(itemY => {
                        if(itemY.field_id ==='accrual_created_by'){
                            setTabHeads(tabHeads => [...tabHeads, 'Created By']); 
                        }
                        else{
                            setTabHeads(tabHeads => [...tabHeads, itemY.name]);
                        }
                        
                        setTabKeys(tabKeys => [...tabKeys, itemY.field_id]);
                    });
                }
            });
        }
    }
}, [props.tableData]);
useEffect(() => {
    setTabHeads(tabHeads.concat(newArray))
}, []);





    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    onClick={() => history.push('/invoice-details-reports')}
                >
                    Invoice Reports
                </Link>
                <Typography color="textPrimary" variant='h4'>&ensp;Results</Typography>
            </Breadcrumbs>
            <div
                className={clsx(classes.root, className)}
            >
                <div className={clsx({
                    [classes.row]: isDesktop,
                })} >
                    <Typography variant="h2"> Invoice Report Results </Typography>
                    <div className={clsx({ [classes.searchInput]: isDesktop })}>
                        <Paper
                            className={clsx(classes.rootSearch, className)}
                        >
                            <SearchIcon className={classes.icon} />
                            <Input
                                className={classes.input}
                                disableUnderline
                                placeholder="Search"
                                onChange={handleSearchInput}
                            />
                            <IconButton
                                type="submit"
                                className={classes.iconButton}
                                aria-label="Close"
                                onClick={handleSearch}
                                size="large">
                                <DoneIcon className={classes.icon} />
                            </IconButton>
                        </Paper>
                    </div>
                </div>
                {props.invoiceData && props.invoiceData.records && props.invoiceData.records.length > 0 ?
                    <TableContainer>
                    <Table>
                        <TableHead >
                            <TableRow >
                                {tabHeads.map(item => {
                                    return (
                                        <TableCell align={item.align ? 'left' : 'center'} className={classes.tableCell} key={item.ID}>
                                         {item}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody classes={{ root: classes.table }} >
                                {props.invoiceData.records && props.invoiceData.records.length > 0 && props.invoiceData.records.map((item) => {
                                    return (
                                <StyledTableRow key={item.id}>
                                    {tabKeys.includes('calendar_year_month') ?
                                      <TableCell align='left' className={classes.tableCell}>{item.calendar_year_month}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_date') ?
                                      <TableCell>{Moment.utc(item.billing_date).format('MM/DD/YYYY')}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_doc_number') ?
                                       <TableCell>{item.billing_doc_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_doc_line') ?
                                    <TableCell>{item.billing_doc_line}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_number') ?
                                    <TableCell>{item.customer_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('net_value') ?
                                    <TableCell>{item.net_value.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('quantity') ?
                                    <TableCell>{item.quantity.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('billed_uom') ?
                                    <TableCell>{item.billed_uom}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_number') ?
                                    <TableCell>{item.material_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('supplier_number') ?
                                    <TableCell>{item.supplier_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('employee_number') ?
                                    <TableCell>{item.employee_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_name') ?
                                    <TableCell>{item.customer_name}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_description') ?
                                    <TableCell>{item.material_description}</TableCell>
                                    :''}
                                    {tabKeys.includes('supplier_name') ?
                                    <TableCell>{item.supplier_name}</TableCell>
                                    :''}
                                    {tabKeys.includes('employee_name') ?
                                    <TableCell>{item.employee_name}</TableCell>
                                    :''}
                                    {tabKeys.includes('unit_price') ?
                                    <TableCell>{item.unit_price.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('currency') ?
                                    <TableCell>{item.currency}</TableCell>
                                    :''}
                                    {tabKeys.includes('fiscal_year_month') ?
                                    <TableCell>{item.fiscal_year_month}</TableCell>
                                    :''}
                                    {tabKeys.includes('base_uom') ?
                                    <TableCell>{item.base_uom}</TableCell>
                                    :''}
                                    {tabKeys.includes('pricing_date') ?
                                    <TableCell>{item.pricing_date != '0001-01-01T00:00:00Z' ? Moment.utc(item.pricing_date).format('MM/DD/YYYY hh:mm') : ''}</TableCell>
                                    :''}
                                    {tabKeys.includes('profit_center') ?
                                    <TableCell>{item.profit_center}</TableCell>
                                    :''}
                                    {tabKeys.includes('order_reason') ?
                                    <TableCell>{item.order_reason}</TableCell>
                                    :''}
                                    {tabKeys.includes('company_code') ?
                                    <TableCell>{item.company_code}</TableCell>
                                    :''}   
                                    {tabKeys.includes('sales_org') ?
                                    <TableCell>{item.sales_org}</TableCell>
                                    :''}
                                    {tabKeys.includes('distribution_channel') ?
                                    <TableCell>{item.distribution_channel}</TableCell>
                                    :''}
                                    {tabKeys.includes('division') ?
                                    <TableCell>{item.division}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_attribute1') ?
                                    <TableCell>{item.flex_attribute1}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_attribute2') ?
                                    <TableCell>{item.flex_attribute2}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_attribute3') ?
                                    <TableCell>{item.flex_attribute3}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_attribute4') ?
                                    <TableCell>{item.flex_attribute4}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_attribute5') ?
                                    <TableCell>{item.flex_attribute5}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure1') ?
                                    <TableCell>{item.flex_key_figure1 && item.flex_key_figure1.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure2') ?
                                    <TableCell>{item.flex_key_figure2 && item.flex_key_figure2.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure3') ?
                                    <TableCell>{item.flex_key_figure3 && item.flex_key_figure3.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure4') ?
                                    <TableCell>{item.flex_key_figure4 && item.flex_key_figure4.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure5') ?
                                    <TableCell>{item.flex_key_figure5 && item.flex_key_figure5.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure6') ?
                                    <TableCell>{item.flex_key_figure6 && item.flex_key_figure6.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure7') ?
                                    <TableCell>{item.flex_key_figure7 && item.flex_key_figure7.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure8') ?
                                    <TableCell>{item.flex_key_figure8 && item.flex_key_figure8.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure9') ?
                                    <TableCell>{item.flex_key_figure9 && item.flex_key_figure9.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('flex_key_figure10') ?
                                    <TableCell>{item.flex_key_figure10 && item.flex_key_figure10.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('incentive_basis1') ?
                                    <TableCell>{item.incentive_basis1 && item.incentive_basis1.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('incentive_basis2') ?
                                    <TableCell>{item.incentive_basis2 && item.incentive_basis2.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('incentive_basis3') ?
                                    <TableCell>{item.incentive_basis3 && item.incentive_basis3.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('incentive_basis4') ?
                                    <TableCell>{item.incentive_basis4 && item.incentive_basis4.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('incentive_basis5') ?
                                    <TableCell>{item.incentive_basis5 && item.incentive_basis5.toFixed(2)}</TableCell>
                                    :''}
                                    {tabKeys.includes('sold_to_party') ?
                                    <TableCell>{item.sold_to_party}</TableCell>
                                    :''}
                                    {tabKeys.includes('ship_to_party') ?
                                    <TableCell>{item.ship_to_party}</TableCell>
                                    :''}
                                    {tabKeys.includes('bill_to_party') ?
                                    <TableCell>{item.bill_to_party}</TableCell>
                                    :''}
                                    {tabKeys.includes('payer') ?
                                    <TableCell>{item.payer}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_group') ?
                                    <TableCell>{item.customer_group}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_chain') ?
                                    <TableCell>{item.customer_chain}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_group1') ?
                                    <TableCell>{item.customer_group1}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_group2') ?
                                    <TableCell>{item.customer_group2}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_group3') ?
                                    <TableCell>{item.customer_group3}</TableCell>
                                    :''}
                                    {tabKeys.includes('customer_group4') ?
                                    <TableCell>{item.customer_group4}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_group') ?
                                    <TableCell>{item.material_group}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_group1') ?
                                    <TableCell>{item.material_group1}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_group2') ?
                                    <TableCell>{item.material_group2}</TableCell>
                                    :''}
                                    {tabKeys.includes('material_type') ?
                                    <TableCell>{item.material_type}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy') ?
                                    <TableCell>{item.product_hierarchy}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy_level1') ?
                                    <TableCell>{item.product_hierarchy_level1}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy_level2') ?
                                    <TableCell>{item.product_hierarchy_level2}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy_level3') ?
                                    <TableCell>{item.product_hierarchy_level3}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy_level4') ?
                                    <TableCell>{item.product_hierarchy_level4}</TableCell>
                                    :''}
                                    {tabKeys.includes('product_hierarchy_level5') ?
                                    <TableCell>{item.product_hierarchy_level5}</TableCell>
                                    :''}
                                    {tabKeys.includes('supplier_group') ?
                                    <TableCell>{item.supplier_group}</TableCell>
                                    :''}
                                     {tabKeys.includes('supplier_customer_number') ?
                                    <TableCell>{item.supplier_customer_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_district') ?
                                    <TableCell>{item.sales_district}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_group') ?
                                    <TableCell>{item.sales_group}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_office') ?
                                    <TableCell>{item.sales_office}</TableCell>
                                    :''}
                                    {tabKeys.includes('region') ?
                                    <TableCell>{item.region}</TableCell>
                                    :''}
                                    {tabKeys.includes('country') ?
                                    <TableCell>{item.country}</TableCell>
                                    :''}
                                    {tabKeys.includes('territory_id') ?
                                    <TableCell>{item.territory_id}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_order_type') ?
                                    <TableCell>{item.sales_order_type}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_order_number') ?
                                    <TableCell>{item.sales_order_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('sales_order_line') ?
                                    <TableCell>{item.sales_order_line}</TableCell>
                                    :''}
                                    {tabKeys.includes('delivery_type') ?
                                    <TableCell>{item.delivery_type}</TableCell>
                                    :''}
                                    {tabKeys.includes('delivery_doc_number') ?
                                    <TableCell>{item.delivery_doc_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('delivery_doc_line') ?
                                    <TableCell>{item.delivery_doc_line}</TableCell>
                                    :''}
                                    {tabKeys.includes('document_category') ?
                                    <TableCell>{item.document_category}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_type') ?
                                    <TableCell>{item.billing_type}</TableCell>
                                    :''}
                                    {tabKeys.includes('item_category') ?
                                    <TableCell>{item.item_category}</TableCell>
                                    :''}
                                    {tabKeys.includes('debit_credit_indicator') ?
                                    <TableCell>{item.debit_credit_indicator}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_doc_cancelled') ?
                                    <TableCell>{item.billing_doc_cancelled}</TableCell>
                                    :''}
                                    {tabKeys.includes('cancelled_billing_doc') ?
                                    <TableCell>{item.cancelled_billing_doc}</TableCell>
                                    :''}
                                    {tabKeys.includes('original_invoice_for_return') ?
                                    <TableCell>{item.original_invoice_for_return}</TableCell>
                                    :''}
                                    {tabKeys.includes('original_invoice_line_for_return') ?
                                    <TableCell>{item.original_invoice_line_for_return}</TableCell>
                                    :''}
                                    {tabKeys.includes('controlling_area') ?
                                    <TableCell>{item.controlling_area}</TableCell>
                                    :''}
                                    {tabKeys.includes('cost_center') ?
                                    <TableCell>{item.cost_center}</TableCell>
                                    :''}
                                    {tabKeys.includes('mfr_part_number') ?
                                    <TableCell>{item.mfr_part_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('pa_segment_number_copa') ?
                                    <TableCell>{item.pa_segment_number_copa}</TableCell>
                                    :''}
                                    {tabKeys.includes('pricing_procedure') ?
                                    <TableCell>{item.pricing_procedure}</TableCell>
                                    :''}
                                    {tabKeys.includes('payment_terms') ?
                                    <TableCell>{item.payment_terms}</TableCell>
                                    :''}
                                    {tabKeys.includes('plant') ?
                                    <TableCell>{item.plant}</TableCell>
                                    :''}
                                    {tabKeys.includes('higher_level_bom_item') ?
                                    <TableCell>{item.higher_level_bom_item}</TableCell>
                                    :''}
                                    {tabKeys.includes('source_system') ?
                                    <TableCell>{item.source_system}</TableCell>
                                    :''}
                                    {tabKeys.includes('ima_ineligible') ?
                                    <TableCell>{item.ima_ineligible}</TableCell>
                                    :''}
                                    {tabKeys.includes('ima_ineligible_application') ?
                                    <TableCell>{item.ima_ineligible_application}</TableCell>
                                    :''}
                                    {tabKeys.includes('zero_value_item') ?
                                    <TableCell>{item.zero_value_item}</TableCell>
                                    :''}
                                     {tabKeys.includes('accounting_doc_line') ?
                                    <TableCell>{item.accounting_doc_line}</TableCell>
                                    :''}
                                    {tabKeys.includes('accounting_doc_number') ?
                                    <TableCell>{item.accounting_doc_number}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_document_created_by') ?
                                    <TableCell>{item.billing_document_created_by}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_document_created_date') ?
                                    <TableCell>{item.billing_document_created_date != '0001-01-01T00:00:00Z' ? Moment.utc(item.billing_document_created_date).format('MM/DD/YYYY hh:mm') : ''}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_document_changed_by') ?
                                    <TableCell>{item.billing_document_changed_by}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_document_changed_date') ?
                                    <TableCell>{item.billing_document_changed_date != '0001-01-01T00:00:00Z' ? Moment.utc(item.billing_document_changed_date).format('MM/DD/YYYY hh:mm') : ''}</TableCell>
                                    :''}
                                    {tabKeys.includes('billingDocument_extracted_by') ?
                                    <TableCell>{item.billingDocument_extracted_by}</TableCell>
                                    :''}
                                    {tabKeys.includes('billing_document_extracted_date') ?
                                    <TableCell>{item.billing_document_extracted_date != '0001-01-01T00:00:00Z' ? Moment.utc(item.billing_document_extracted_date).format('MM/DD/YYYY hh:mm') : ''}</TableCell>
                                    :''}
                                    {tabKeys.includes('deleted') ?
                                    <TableCell>{item.deleted}</TableCell>
                                    :''}
                                    {tabKeys.includes('accrual_created_by') ?
                                    <TableCell>{item.created_by}</TableCell>
                                    :''}
                                    {tabKeys.includes('created_by_id') ?
                                    <TableCell>{item.created_by_id}</TableCell>
                                    :''}

                                </StyledTableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography variant='h4'>
                        No Sales Data Found
                    </Typography>
                }
            </div >
            {props.invoiceData && props.invoiceData.records && props.invoiceData.records.length > 0 &&
                <TablePagination
                    component="div"
                    count={props.invoiceData.total_record}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                        root: classes.paginationRoot
                    }}
                />
            }
            {props.invoiceCompleteData &&
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleSheetDownload}> Download </Button>
                </div>
            }
        </div >
    );

};

const mapStateToProps = state => {
    return {
        invoiceData: state.operationalReportsData.invoiceReportsData,
        loading: state.operationalReportsData.loading,
        invoiceCompleteData: state.operationalReportsData.invoiceReportsCompleteData,
        tableData: state.addMultipleConfigurationData.multipleConfigurationData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadInvoiceDetails: (pagination, limit, keyword) => dispatch(runInvoiceReports(pagination, limit, keyword)),
        downloadSheet: (data) => dispatch(downloadSheet(data, 'sales-data')),
        onSearch: (keyword) => dispatch(searchInvoice(keyword)),
        onLoadingDefault: () => dispatch(getDefaultValues(sessionStorage.getItem('application'), localStorage.getItem('operationalReportsSourceDataType'), null, null, 'Display Designer')),
  
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReportsTab);