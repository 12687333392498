import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper, Input,IconButton,Button,useMediaQuery } from '@mui/material';
import {Search,Check,AddBoxOutlined} from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getCustomerMasterData } from '../../../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchInput: {
    marginRight: 47
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    minWidth: 0
  },
  container: {
    minWidth: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 10
    }
  },
  rootSearch: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    borderRadius: 0,
    padding: 0,
    minWidth: 200,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
      marginTop: 10,
      marginRight: 15,
    },
  },
  input: {
    fontSize: 14,
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px',
    paddingLeft: 10,
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const CustomerMasterToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
  const [searchText, setSearchText] = React.useState('');
  const addContract = () => {
    localStorage.removeItem('currentAddContractID');
    localStorage.setItem('mode', 'add');
    history.push('/default-page');
  }
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  }

  return (
    <div
      {...rest}
      className={classes.root}
    >
      <div className={clsx({ [classes.searchInput]: isDesktop })}>
        <Paper
          {...rest}
          className={clsx(classes.rootSearch, className)}
        >
          <Search className={classes.icon} />
          <Input
            {...rest}
            className={classes.input}
            disableUnderline
            placeholder="Search"
            onChange={handleSearchOnChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="Close"
            onClick={() => props.onSearch(searchText)}
            size="large">
            <Check className={classes.icon} />
          </IconButton>
        </Paper>
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/customer-master/add-customer'
        onClick={addContract}
      >
        {isDesktop ? 'Add Customer' : ''}
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddBoxOutlined />}
        classes={{
          startIcon: !isDesktop && classes.startIcon,
          root: !isDesktop && classes.container
        }}
        component={CustomRouterLink}
        to='/customer-master/customer-master-excel-upload'
        onClick={addContract}
        style={{ marginLeft: 10 }}
      >
        Upload Excel
      </Button>
    </div >
  );

};

CustomerMasterToolbar.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
    onSearch: (keyword) => dispatch(getCustomerMasterData(0, 100, keyword))
  }
}

export default connect(null, mapDispatchToProps)(CustomerMasterToolbar);