import React, { useEffect, forwardRef } from 'react';
import {
    Card,
    CardHeader,
    Grid,
    FormLabel,
    OutlinedInput
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Datepicker, LabelText, DropdownArray, AutoCompleteDropdown, DropdownKeyValue } from '../../../../components/Inputs';
import { connect } from 'react-redux';
import { getAllOrganizationUsers } from '../../../../redux/actions';
import * as moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: '0px 10px 0px 10px'
    },
    gridContainer: {
        padding: '0px 25px 0px 25px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 15,
        height: 75,
        maxHeight: 75
    },
    error: {
        color: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: 150
    },
    data: {
        color: theme.palette.text.grey,
        marginTop: 5,
        width: '100%',
        alignItems: 'center',
        height: 36,
        border: '1px solid #E0E0E0',
        display: 'flex',
        padding: '8px 10px 9px 10px'
    },
    boxRoot: {
        padding: '15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    boxGrid: {
        width: '100%'
    },
    inputTwoLine: {
        // marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    fontSetting: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize'

    },
    formlabelContainer: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        marginBottom: 0

    },
    formLabel: {
        fontSize: theme.typography.h3.fontSize,
        textTransform: 'capitalize',
        // paddingBottom: 10
    },
    dropdownAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 15,
        paddingBottom: 15,
        borderTop: '1px solid #ddd',
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
    selectedItem: {
        // color: theme.palette.text.grey,
        textTransform: 'capitalize'
    },
}));
const HeaderFieldsContiner = forwardRef((props, ref) => {
    const classes = useStyles();
    const { className } = props;


    const [mandatoryfields, setManadatoryFields] = React.useState([]);
    const [orgUsersAarray, setOrgUsersArray] = React.useState([]);


    useEffect(() => {
        if ((props.mode == 'view' || props.mode == 'submit'))
            props.getUsers();
    }, []);

    useEffect(() => {
        if (props.orgUsers && props.orgUsers.length > 0) {
            var temp = [];
            props.orgUsers.map(item => {
                temp = { ...temp, [item.id]: item.username }
            })
            setOrgUsersArray(temp);
        }
    }, [props.orgUsers]);




    const handleGeneralData = (key, value) => {
        if ((key == 'valid_from' || key == 'valid_to' || key == 'internal_signed_date' || key == 'external_signed_date')
            && value && value.isValid()) {
            props.onChange(key, value.toISOString().split('T')[0].concat('T00:00:00.000Z'))
        }
        else
            props.onChange(key, value)
    }

    var executed = false;
    useEffect(() => {
        if (props.fieldData && props.fieldData.length > 0) {
            setManadatoryFields(props.fieldData.filter((item) => item.mandatory).map(e => e.key))

        }
    }, [props.fieldData]);




    return (
        <div className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <CardHeader
                        title="PRIMARY DATA"
                        titleTypographyProps={{ variant: 'h3' }}
                    />

                    <div className={classes.container}>
                        <Grid container
                            style={{ marginBottom: 16 }}
                        >
                            {props.fieldData && props.fieldData.length > 0 &&
                                props.fieldData
                                    .filter((item, i) => item.key != 'document_status' && item.key != 'document_manager' && item.key != 'document_analyst' && item.key != 'duration')
                                    .map((item, i) => (

                                        (props.mode == 'view' || props.status == 'submit_approval') ?
                                            (props.generalData && props.generalData[item.key] && props.generalData[item.key] != 0) &&
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            // style={{ paddingTop: -10 }}
                                            >
                                                <LabelText heading={item.current} twoline='true'
                                                    data={(item.key == 'valid_from' || item.key == 'valid_to' || item.key == 'internal_signed_date' || item.key == 'external_signed_date') ?
                                                        moment.utc(props.generalData[item.key]).format('MM/DD/YYYY')
                                                        :
                                                        (item.key == 'contract_analyst' || item.key == 'contract_manager') ?
                                                            orgUsersAarray && orgUsersAarray[props.generalData[item.key]] ?
                                                                orgUsersAarray[props.generalData[item.key]] : ''
                                                            : props.generalData[item.key]}
                                                />
                                            </Grid>
                                            :
                                            <Grid
                                                item
                                                md={3}
                                                xs={12}
                                                classes={{ root: classes.gridContainer }}
                                            // style={{ paddingTop: -10 }}
                                            >
                                                {
                                                    // (props.mode=='view'||props.mode=='submit')?
                                                    // (props.generalData && props.generalData[item.key] && props.generalData[item.key]!= 0)?   
                                                    // <LabelText heading={item.current}  twoline='true'
                                                    //   data={(item.key=='valid_from'||item.key=='valid_to'||item.key=='internal_signed_date'||item.key=='external_signed_date')? 
                                                    //   moment.utc(props.generalData[item.key]).format('MM/DD/YYYY')
                                                    //   :
                                                    //   (item.key=='contract_analyst'||item.key=='contract_manager')?
                                                    //     orgUsersAarray&&orgUsersAarray[props.generalData[item.key]]?
                                                    //       orgUsersAarray[props.generalData[item.key]]:''
                                                    //   :props.generalData[item.key]}
                                                    // />:''
                                                    // :
                                                    item.key == 'application_type' ?
                                                        <LabelText classes={{ root: classes.fontSetting }} heading={item.current} data={sessionStorage.getItem('application')} twoline='true' border={true} />
                                                        :
                                                        item.key == 'document_id' ?
                                                            props.mode == 'create' ?
                                                                <div style={{ marginTop: '-0.3rem' }}>
                                                                    <FormLabel {...props} classes={{ root: classes.fontSetting }} required={item.mandatory} >
                                                                        {item.current}
                                                                    </FormLabel>
                                                                    < div className={classes.data} >Auto Generated</div>
                                                                </div>
                                                                :
                                                                <LabelText classes={{ root: classes.fontSetting }} heading={item.current} data={props.generalData.document_id} twoline='true' border={true} />

                                                            :
                                                            (item.key == 'valid_from' || item.key == 'valid_to' || item.key == 'internal_signed_date' || item.key == 'external_signed_date') ?
                                                                props.mode == 'create' ?
                                                                    <Datepicker required={item.mandatory} classes={{ root: classes.fontSetting }} heading={item.current} twoline='true'{...props}
                                                                        onChange={(value) => handleGeneralData(item.key, value)}
                                                                    // prevalue={props.generalData && props.generalData[item.key] != '0001-01-01T00:00:00Z' ? moment(props.generalData[item.key]) : ''}
                                                                    /> :
                                                                    <Datepicker required={item.mandatory} classes={{ root: classes.fontSetting }} heading={item.current} twoline='true'{...props}
                                                                        onChange={(value) => handleGeneralData(item.key, value)}
                                                                        prevalue={props.generalData && props.generalData[item.key] != '0001-01-01T00:00:00Z' ? moment(props.generalData[item.key]) : ''}
                                                                    />
                                                                :
                                                                item.key == 'document_type' || (item.drop_down_values && item.drop_down_values.length > 0) ?

                                                                    <DropdownArray classes={{ root: classes.fontSetting }} heading={item.current}
                                                                        placeholder={item.drop_down_values && item.drop_down_values.length == 0 ? 'Please Configure ' : ''}
                                                                        twoline='true' {...props} onChange={(value) => handleGeneralData(item.key, value)} data={item.drop_down_values}
                                                                        prevalue={props.generalData ? props.generalData[item.key] : ''} required={item.mandatory}
                                                                        disabled={item.key == 'document_type' && props.mode != 'create' ? true : false} id={item.key}
                                                                    />

                                                                    :
                                                                    item.key == 'workflow_name' ?
                                                                        <DropdownKeyValue classes={{ root: classes.fontSetting }} required onChange={(value) => handleGeneralData(item.key, value)}
                                                                            heading={item.current} placeholder={item.drop_down_reference_value && item.drop_down_reference_value.length == 0 ? 'Please Configure ' : ''}
                                                                            twoline='true' labelFormat={'desc'} disabled={props.mode != 'create' ? true : false} id={item.key}
                                                                            data={item.drop_down_reference_value} prevalue={props.generalData ? props.generalData[item.key] : ''} />
                                                                        :
                                                                        item.drop_down_reference_value && item.drop_down_reference_value.length > 0 ?
                                                                            <DropdownKeyValue classes={{ root: classes.fontSetting }} required onChange={(value) => handleGeneralData(item.key, value)}
                                                                                heading={item.current} placeholder={item.drop_down_reference_value && item.drop_down_reference_value.length == 0 ? 'Please Configure ' : ''}
                                                                                twoline='true' labelFormat={'desc'}
                                                                                // disabled={props.mode != 'create' ? true : false}
                                                                                id={item.key}
                                                                                data={item.drop_down_reference_value} prevalue={props.generalData ? props.generalData[item.key] : ''} />
                                                                            // <AutoCompleteDropdown heading={item.current} data={item.drop_down_reference_value} required={item.mandatory}
                                                                            //     // prevalue={{ 'key': props.generalData ? props.generalData[item.key] : '', 'desc': '' }}
                                                                            //     value={props.generalData && props.generalData[item.key] ? props.generalData[item.key]['desc'] : ''}
                                                                            //     onChange={(value) => handleGeneralData(item.key, value.key)} id={item.key} />

                                                                            :
                                                                            <div>
                                                                                <FormLabel {...props} classes={{ root: classes.fontSetting }} required={item.mandatory} >
                                                                                    {item.current}
                                                                                </FormLabel>
                                                                                <OutlinedInput
                                                                                    value={props.generalData ? props.generalData[item.key] : ''}
                                                                                    type={'String'}
                                                                                    classes={{ root: classes.inputTwoLine }}
                                                                                    onChange={(e) => handleGeneralData(item.key, e.target.value)} />
                                                                            </div>
                                                }
                                            </Grid>


                                    ))
                            }
                        </Grid>
                    </div>

                </form>
            </Card>
        </div >
    );

});

const mapStateToProps = state => ({
    orgUsers: state.operationalReportsData.orgUsers
});

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getAllOrganizationUsers()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HeaderFieldsContiner);