import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    Card,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableBody,
    TextField,
    OutlinedInput,
    FormLabel,
    Switch,
    Dialog,
    DialogActions,
    Select,
    MenuItem,
    Paper,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
    getDefaultValuesAllDataNew,
    getCalculationSimulationDataFields, getDynamicQueryFields,
    postDynamicQuery, getDynamicQuerySalesDataFields, getDefaultValuesAllData,
    getPurchaseDataFields, loadValuesBasedOnAppTypeAndField, getDynamicQueryFieldsNew, getDefaultValues,
    allowedApps
} from '../../redux/actions';
import { connect } from 'react-redux';
import { history } from '../../components/Helpers';
import clsx from 'clsx';
import { ApplicationTypes } from '../../library/constants';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    buttonContainer: {
        justifyContent: 'center',
        display: 'flex',
        paddingTop: 28,
        paddingBottom: 30
    },
    button: {
        marginRight: 25,
        width: 140
    },
    rootContainer: {
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 3,
        marginTop: 10,
        height: 33,
        padding: 0,
        marginBottom: 14
    },
    input: {
        padding: 0,
        height: 21,
        fontFamily: 'ProximaNova',
        fontSize: 13
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    inputTwoLine: {
        marginTop: 10,
        width: '100%',
        paddingLeft: 10
    },
    error: {
        border: '1px solid red !important'
    },
    formLabel: {
        fontSize: 13,
        // height: 50,
        display: 'flex',
        alignItems: 'flex-end'
    },
    centerButton: {
        display: 'flex',
        marginTop: 26
    },
    deleteIcon: {
        backgroundColor: 'red',
        borderRadius: 0
    },
    startIcon: {
        marginLeft: 0,
        marginRight: 0,
        minWidth: 0
    },
    deleteIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.red,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.red
        },
        height: 28,
        display: 'flex'
    },
    checkIconContainer: {
        minWidth: 0,
        padding: '6px 4px',
        backgroundColor: theme.palette.button.green,
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.button.green
        },
        color: theme.palette.white,
        height: 28,
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            marginRight: 15
        }
    },
    iconContainer: {
        height: 36,
        display: 'flex'
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    IconButton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10
        }
    },
    formControlRoot: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 6,
    },
    paper: { minWidth: window.screen.width * .50 },
    select: {
        width: '100%',
        marginTop: 10
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    center: {
        textAlign: 'center'
    },
    tableStyle: {
        backgroundColor: theme.palette.primary.main
    }
}));

const AddDynamicQuery = props => {
    const classes = useStyles();
    const [disabledItems, setDisabledItems] = React.useState([]);
    const handleInitialSetup = () => {
        var tempObj = {};
        if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
            apiArrayFieldsConcatinated.map((item1) => {
                Object.entries(props.labelNewAPIData).map(([key, value]) => {
                    if (item1 === key) {
                        tempObj[key] = value['current'];
                        console.log(value['current'])
                    }
                    // else if (localStorage.getItem('dq_table_name') === 'purchase_data') {
                    //     tempObj[item1] = item1.replace(/_/g, " ");
                    // }
                    // else {
                    //     tempObj[item1] = item1.replace(/_/g, " ");
                    // }
                })
            })
        }
        setKeyValuePairObjectAll(tempObj);

        var dis = [];
        props.formFieldsAllData && props.formFieldsAllData.records.length > 0 && props.formFieldsAllData.records.map((item) => {
            if (item.contract_display === false) {
                dis.push(item.field_id)
            }
        })
        setDisabledItems(dis);
        if (dis.length == 0) {
            setKeyValuePairObject(tempObj);
        }
        setOpen(false);

    }
    useEffect(() => {
        if (disabledItems.length > 0) {
            const filtered = Object.keys(keyValuePairObjectAll)
                .filter(key => !disabledItems.includes(key))
                .reduce((obj, key) => {
                    obj[key] = keyValuePairObjectAll[key];
                    return obj;
                }, {});
            setKeyValuePairObject(filtered);
        }
    }, [disabledItems])
    const [open, setOpen] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [queryName, setQueryName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [bomPartName, setBomPartName] = React.useState('');
    const [bomDescription, setBomDescription] = React.useState('');
    const [bomPartNumber, setBomPartNumber] = React.useState('');
    const [quantity, setQuantity] = React.useState('');
    const [uom, setUom] = React.useState('');
    const [incomingRoyalty, setIncomingRoyalty] = React.useState('');
    const [outgoingRoyalty, setOutgoingRoyalty] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [filterFieldsArray, setFilterFieldsArray] = React.useState(['customer_number', 'supplier_number']);
    const [sumFieldsArray, setSumFieldsArray] = React.useState([]);
    const [averageFieldsArray, setAverageFieldsArray] = React.useState([]);
    const [outputFieldsArray, setOutputFieldsArray] = React.useState({});
    const [aggregateFieldsArray, setAggregateFieldsArray] = React.useState([]);
    const [sortOrderArray, setSortOrderArray] = React.useState([]);
    const [apiArrayFieldsConcatinated, setApiArrayFieldsConcatinated] = React.useState([]);
    const [keyValuePairObjectAll, setKeyValuePairObjectAll] = React.useState([]);
    const [keyValuePairObject, setKeyValuePairObject] = React.useState([]);
    const [sortCounter, setSortCounter] = React.useState(1);
    const [sortCounterEnabler, setSortCounterEnabler] = React.useState(true);
    const financialPostingsFields = ['Contract Number', 'Posting Type', 'Billing Doc Number', 'Billing Doc Line'];
    const handleQueryName = (e) => {
        setQueryName(e.target.value);
    }
    useEffect(() => {
        setOpen(true);
        // props.getDynamicQueryFields();
        // props.getDefaultValuesAllData();
    }, []);

    useEffect(() => {
        if (props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields) {
            var concatinated = props.dynamicQueryFieldsData.numeric_fields.concat(props.dynamicQueryFieldsData.string_fields)
                .concat(props.dynamicQueryFieldsData.date_fields)
            setApiArrayFieldsConcatinated(concatinated)
        }
    }, [props.dynamicQueryFieldsData]);
    useEffect(() => {
        props.allowedApps();
    }, [])

    const [allowedApps, setAllowedApps] = React.useState([]);
    useEffect(() => {
        if (props.allowedAppsData && props.allowedAppsData.length > 0) {
            setAllowedApps(props.allowedAppsData);
        }
    }, [props.allowedAppsData])

    // useEffect(() => {
    //     var tempObj = {};
    //     if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
    //         apiArrayFieldsConcatinated.map((item1) => {
    //             Object.entries(props.labelNewAPIData).map(([key, value]) => {
    //                 if (item1 === key) {
    //                     tempObj[key] = value;
    //                 }
    //                 else if (localStorage.getItem('dq_table_name') === 'purchase_data') {
    //                     tempObj[item1] = item1.replace(/_/g, " ");
    //                 }
    //             })
    //         })
    //     }
    //     setKeyValuePairObject(tempObj);
    // }, [props.labelNewAPIData, apiArrayFieldsConcatinated]);

    const [editIndex, setEditIndex] = React.useState('');
    const [editMode, setEditMode] = React.useState(false);
    const [userStore, setUserStore] = React.useState([]);
    function handleClear() {
        setEditIndex('')
        setEditMode('');
        setBomPartNumber('');
        setBomPartName('');
        setQuantity('');
        setUom('');
        setIncomingRoyalty('');
        setOutgoingRoyalty('');
        setNotes('');
    }
    function handleOnSubmit() {
        var unsortedFields = [];
        if (outputFieldsArray || (sumFieldsArray.length > 0 && filterFieldsArray.length === 0
            && outputFieldsArray.length === 0 && aggregateFieldsArray.length === 0)) {

            for (var propName in outputFieldsArray) {
                unsortedFields.push(propName);
                if (outputFieldsArray[propName] === 0) {
                    delete outputFieldsArray[propName];
                }
            }
            var allFields = {
                "filter_fields": filterFieldsArray,
                "sum_fields": sumFieldsArray,
                "sort_fields": outputFieldsArray,
                "output_fields": unsortedFields,
                "groupby_fields": aggregateFieldsArray,
                "format_name": queryName,
                "table_name": props.dynamicQueryFieldsData.table_name,
                "query_description": description,
                "avg_fields": averageFieldsArray,
                "query_type": 2,
                "app_type": applicationType,
                "data_source_type": sourceDataType,
                "data_source": functionalityName
            };
            props.onSubmit(allFields);

        } else {
            alert('Please select atleast one output or only sum field')
        }
    }
    function handleClearAll() {
        setSortOrderArray([]);
        handleClear();
        setEditMode(false);
        setUserStore([]);
        setFilterFieldsArray([]);
        setAggregateFieldsArray([]);
        setAverageFieldsArray([]);
        setOutputFieldsArray([]);
        setSumFieldsArray([]);
    }
    function handleCancelDialog() {
        history.push('/collaboration-portal');
    }
    const handleChange = (event, item, type) => {
        if (type === 'filter_fields') {
            if (filterFieldsArray.includes(item)) {
                setFilterFieldsArray(filterFieldsArray.filter(item1 => item1 !== item));
            } else {
                setFilterFieldsArray([...filterFieldsArray, item]);
            }
        }
        if (type === 'aggregate_fields') {
            if (aggregateFieldsArray.includes(item)) {
                // if (sumFieldsArray.includes(item)) {
                //     if (outputFieldsArray.hasOwnProperty(item)) {
                //         // setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                //         // setOutputFieldsArray(outputFieldsArray.filter(item1 => item1 !== item));
                //     }
                // } else {
                //     setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
                // }
                setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));

            } else {
                setAggregateFieldsArray([...aggregateFieldsArray, item]);
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });//aggregate and output should be in sync
            }
        }
        if (type === 'avg_fields') {
            if (averageFieldsArray.includes(item)) {
                setAverageFieldsArray(averageFieldsArray.filter(item1 => item1 !== item));
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                setAverageFieldsArray([...averageFieldsArray, item]);
            }
        }
        if (type === 'sum_fields') {
            if (sumFieldsArray.includes(item)) {
                setSumFieldsArray(sumFieldsArray.filter(item1 => item1 !== item));
            } else {
                setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                setSumFieldsArray([...sumFieldsArray, item]);
            }
        }
        // if (type === 'output_fields') {
        //     if (outputFieldsArray.hasOwnProperty(item)) {
        //         if (sumFieldsArray.includes(item)) {

        //         } else {
        //             var tempArray = outputFieldsArray;
        //             delete tempArray[item];
        //             setOutputFieldsArray(tempArray)
        //             setAggregateFieldsArray(aggregateFieldsArray.filter(item1 => item1 !== item));
        //         }
        //     } else {
        //         setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
        //         setAggregateFieldsArray([...aggregateFieldsArray, item])//aggregate and output should be in sync
        //     }
        // }
        if (type === 'output_fields') {
            if (outputFieldsArray.hasOwnProperty(item)) {
                var tempArray = outputFieldsArray;
                delete tempArray[item];
                setOutputFieldsArray({ ...tempArray })
            } else {
                if (sortCounterEnabler) {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: sortCounter });
                } else {
                    setOutputFieldsArray({ ...outputFieldsArray, [item]: null });
                }
                setSortCounter(prevCount => prevCount + 1);
            }
        }
        if (type === 'sort_order') {
            setSortCounterEnabler(false);
            setSortOrderArray(event.target.value)
            setOutputFieldsArray({ ...outputFieldsArray, [item]: event.target.value ? parseInt(event.target.value) : null });
            event.preventDefault();
        }
        // event.preventDefault();
    };
    //functionality name
    const [functionalityName, setFunctionalityName] = React.useState('');
    const [sourceDataTypeArray, setSourceDataTypeArray] = React.useState([]);
    useEffect(() => {
        if (props.sourceDataTypeValue && Object.keys(props.sourceDataTypeValue).length > 0) {
            setSourceDataTypeArray(props.sourceDataTypeValue['drop_down_value_keys'])
        }
    }, [props.sourceDataTypeValue])


    const handleFunctionalityName = (e) => {
        setFunctionalityName(e.target.value);
        // if (e.target.value === 'Financial Postings Data') {
        //     props.getDynamicQueryFields();
        // } else if (e.target.value === 'Purchase Data') {
        //     props.getPurchaseDataFields();
        // }
        // else if (e.target.value === 'Sales Data') {
        //     props.getDynamicQuerySalesDataFields();
        // }
        // else {
        //     props.getCalculationSimulationDataFields();
        // }
        props.getDynamicQueryFields(applicationType, sourceDataType, e.target.value);
    }
    const handleQueryType = (e) => {

    }
    const [allOutput, setAllOutput] = React.useState(false);
    const handleAllOutput = (event) => {
        var tempObj = {};
        var tempArray = [];
        var i = 1;
        setAllOutput(event.target.checked);
        if (event.target.checked === true) {
            if (apiArrayFieldsConcatinated.length > 0 && props.labelNewAPIData) {
                apiArrayFieldsConcatinated.map((item1) => {
                    Object.entries(props.labelNewAPIData)
                        .sort()
                        .map(([key, value]) => {
                            if (item1 === key) {
                                tempObj[key] = 0;
                            }
                            else if (localStorage.getItem('dq_table_name') === 'purchase_data') {
                                tempObj[item1] = 0;
                            }
                        })
                })
            }
            {
                Object.entries(keyValuePairObject)
                    .sort()
                    .filter(([key, value]) => key != "")
                    .map(([key, value]) => {
                        tempArray.push(key)
                        tempObj[key] = i;
                        ++i;
                    })
            }
            setOutputFieldsArray(tempObj);
            // setAggregateFieldsArray(tempArray);
        } else {
            setOutputFieldsArray(tempObj);
            // setAggregateFieldsArray([]);
        }
    }

    const [dataSourceData, setDataSourceData] = React.useState([]);

    const [dataSource, setDataSource] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [sourceDataType, setSourceDataType] = React.useState('');
    const handleApplicationType = (e) => {
        setApplicationType(e.target.value);
        setSourceDataType('');
        setDataSourceData([]);
        setSourceDataTypeArray([]);
        props.loadSourceDataType(e.target.value, 'source_data_type');
    }
    const handleSourceDataType = (e) => {
        props.onLoadingDefault(applicationType, e.target.value, null, null, 'Display Designer');
        setSourceDataType(e.target.value);
        if (applicationType && e.target.value) {
            console.log('applicationt', applicationType)
            console.log('source', e.target.value)
            if (applicationType === 'Supplier Rebate' && e.target.value === 'Direct - Purchase Data') {
                setDataSourceData(['Purchase Data', 'Calculation Simulation', 'Financial Postings']);
            } else if (applicationType === 'Customer Chargeback' && e.target.value === 'Direct - Sales Data') {
                setDataSourceData(['Sales Data', 'Outgoing Claim'])
            } else if (applicationType === 'Supplier Chargeback' && e.target.value === 'Indirect - Sales Data') {
                setDataSourceData(['Incoming Claim']);
            } else {
                setDataSourceData(['Sales Data', 'Calculation Simulation', 'Financial Postings'])
            }
        } else {
            setDataSourceData([]);
        }
    }

    useEffect(() => {
        if (sourceDataType)
            props.onLoadingLabelDesignerNew('field-label-designer', sessionStorage.getItem('application'), sourceDataType)
    }, [sourceDataType])

    useEffect(() => {
        var tempObj = {};
        if (apiArrayFieldsConcatinated.length > 0 && props.formFieldsAllData && props.formFieldsAllData.records) {
            apiArrayFieldsConcatinated.map((item1) => {
                props.formFieldsAllData.records.map((item2) => {
                    if (item1 === item2.field_id) {
                        tempObj[item2.field_id] = item2.name;
                    }
                    else if (item1 != item2.field_id && !tempObj.hasOwnProperty(item1)) {
                        tempObj[item1] = item1;
                    }
                })
            })
        }
        // tempObj['customer_number'] = 'Customer Number';
        // tempObj['supplier_number'] = 'Supplier Number';
        setKeyValuePairObject(tempObj);
        // handleChange(true, 'customer_number', 'aggregate_fields')
    }, [props.formFieldsAllData, apiArrayFieldsConcatinated]);

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/collaboration-portal'
                >
                    Collaboration Portal
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Query</Typography>
            </Breadcrumbs>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="simple-dialog-title"
                open={open}
                disableEscapeKeyDown
                classes={{ paper: classes.paper }}>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Query Name
                    </FormLabel>
                    <OutlinedInput
                        value={queryName}
                        // inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={handleQueryName}
                    />
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Application Type
                    </FormLabel>
                    <Select
                        value={applicationType}
                        onChange={handleApplicationType}
                        className={clsx({
                            [classes.select]: true
                        })}
                        classes={{
                            selectMenu: classes.selectedItem
                        }}
                    >
                        <MenuItem value="">
                            Select Option
                        </MenuItem>
                        {allowedApps.map((item) => {
                            return (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Source Data Type
                    </FormLabel>
                    <Select
                        value={sourceDataType}
                        onChange={handleSourceDataType}
                        className={clsx({
                            [classes.select]: true
                        })}
                        classes={{
                            selectMenu: classes.selectedItem
                        }}
                    >
                        <MenuItem value="">
                            Select Option
                        </MenuItem>
                        {sourceDataTypeArray
                            .map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </div>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Data Source
                    </FormLabel>
                    <Select
                        value={functionalityName}
                        onChange={handleFunctionalityName}
                        className={clsx({
                            [classes.select]: true
                        })}
                        classes={{
                            selectMenu: classes.selectedItem
                        }}
                    >
                        <MenuItem value="">
                            Select Option
                        </MenuItem>
                        {dataSourceData.map((item) => {
                            return (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <div style={{ padding: 20 }}>
                    <FormLabel className={classes.formLabel} required>
                        Description
                    </FormLabel>
                    <OutlinedInput
                        value={description}
                        // inputProps={{ min: "0", step: "1" }}
                        classes={{ root: classes.inputTwoLine }}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <DialogActions>
                    <Button color="primary" onClick={handleCancelDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleInitialSetup} disabled={applicationType && sourceDataType && queryName && functionalityName && description ? false : true}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.bodyContainer}>
                <Card>
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <div className={classes.container}>
                            <Grid container spacing={2}>
                            </Grid>
                        </div>
                    </form>
                </Card>

                <div className={classes.container2}>
                    <Grid container >
                        <Grid
                            item
                            xs={12}
                            classes={{ root: classes.gridContainer }}
                        >
                            {props.dynamicQueryFieldsData && props.dynamicQueryFieldsData.numeric_fields ?
                                <Paper className={classes.root}>
                                    <div style={{ margin: 15 }}>
                                        <TableContainer style={{ overflowX: "initial" }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell align='center' className={classes.tableStyle}>Field Name</TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>Filter</TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>Aggregate</TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>Average</TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>Sum</TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>
                                                            <FormControlLabel
                                                                value="external"
                                                                control={<Checkbox color="primary"
                                                                    onChange={handleAllOutput}
                                                                    checked={allOutput}
                                                                />}
                                                                label={<div style={{ fontSize: 14 }}>Output</div>}
                                                                labelPlacement="right"
                                                                style={{ fontSize: 14 }}
                                                                classes={{ root: 'fontSize: 14px' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align='center' className={classes.tableStyle}>Sort Order</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody classes={{ root: classes.table }} >
                                                    {Object.entries(keyValuePairObject)
                                                        .sort()
                                                        .filter(([key, value]) => key != "")
                                                        .map(([key, value]) => {
                                                            return (
                                                                <TableRow key={key}>
                                                                    <TableCell style={{ textAlign: 'center', textTransform: 'capitalize' }}>{value}</TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={filterFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'filter_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                            disabled={key === 'customer_number' || key === 'supplier_number' ? true : false}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={aggregateFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'aggregate_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={averageFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'avg_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={sumFieldsArray.includes(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'sum_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align='center'>
                                                                        <Switch
                                                                            checked={outputFieldsArray.hasOwnProperty(key)}
                                                                            color='primary'
                                                                            onChange={(e) => handleChange(e, key, 'output_fields')}
                                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell style={{ width: 70 }}>
                                                                        <TextField
                                                                            key={['recipient', key].join('_')}
                                                                            type="number"
                                                                            variant="outlined"
                                                                            value={outputFieldsArray[key] || outputFieldsArray[key] === 0 ? outputFieldsArray[key] : ''}
                                                                            onChange={(e) => handleChange(e, key, 'sort_order')}
                                                                            disabled={outputFieldsArray.hasOwnProperty(key) ? false : true}
                                                                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                                        />
                                                                    </TableCell>

                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Paper>
                                :
                                <Typography variant='h4'>
                                    There is no data to show now.
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleOnSubmit}>
                        Submit
                    </Button>
                </div>
            </div >
        </div>
    );

};

const mapDispatchToProps = dispatch => {
    return {
        // getDynamicQueryFields: () => dispatch(getDynamicQueryFields()),
        getDynamicQuerySalesDataFields: () => dispatch(getDynamicQuerySalesDataFields()),
        getCalculationSimulationDataFields: () => dispatch(getCalculationSimulationDataFields()),
        onSubmit: (data) => dispatch(postDynamicQuery(data, '', 'collaboration')),
        getDefaultValuesAllData: () => dispatch(getDefaultValuesAllData()),
        onLoadingLabelDesignerNew: (command, applicationType, sourceDataType) => dispatch(getDefaultValuesAllDataNew(command, applicationType, sourceDataType)),
        getPurchaseDataFields: () => dispatch(getPurchaseDataFields()),
        loadSourceDataType: (applicationType, fieldId) => dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
        getDynamicQueryFields: (applicationType, sourceDataType, functionalityName) => dispatch(getDynamicQueryFieldsNew(applicationType, sourceDataType, functionalityName)),
        onLoadingDefault: (applicationType, sourceDataType) => dispatch(getDefaultValues(applicationType, sourceDataType, null, null, 'Display Designer')),
        allowedApps: () => dispatch(allowedApps())
    }
}

const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        dropdownData: state.customerData.dropdownData,
        dropdownDataCustomer: state.customerData.dropdownDataCustomer,
        dynamicQueryFieldsData: state.operationalReportsData.dynamicQueryFieldsDataNew,
        calculationFieldsData: state.customerData.calculationSimulationData,
        labelNewAPIData: state.addMultipleConfigurationData.formFieldsAllDataNew,
        queryTypes: state.operationalReportsData.queryTypesSuccess,
        sourceDataTypeValue: state.addMultipleConfigurationData.loadFieldValuesOnAppType,
        formFieldsAllData: state.addMultipleConfigurationData.multipleConfigurationData,
        allowedAppsData: state.initialData.allowedApps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicQuery);