import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Fab,
} from "@mui/material";
import { connect } from "react-redux";
import SplineChart from "../../Charts/SplineChart";
import Moment from "moment";
import {
  geographicalIncentive,
  loadAccrualVsPaymentGraph,
  loadTop25Chart,
} from "../../../../redux/actions";
import BarChart from "../../Charts/BarChart";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  activeContracts: {
    backgroundColor: "#38d138",
  },
  expiredContracts: {
    backgroundColor: "#d43333",
  },
  totalContracts: {
    backgroundColor: "#8F00FF",
  },
  waitingApprovals: {
    backgroundColor: "#FFBC03",
  },
  newContracts: {
    backgroundColor: "#7843DF",
  },
  contractCellContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: "0px 16px 18px 16px !important",
    },
  },
}));
const TopStats = (props) => {
  const classes = useStyles();
  const [graphData, setGraphData] = React.useState([]);
  const [postingType, setPostingType] = React.useState("accrual");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [sourceDataType, setSourceDataType] = React.useState('Direct - Sales Data');
  const [attribute, setAttribute] = React.useState("customer_number");
  const dimension = { yAxisTitle: "Incentive  Amount" };

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Top Incentives by Attribute" && item.dashboard_name === "Summarized Dashboard"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData]);

  useEffect(() => {
    if (allowedTilesFilter.length > 0) {
      if (allowedTilesFilter[0].start_date)
        setStartDate(allowedTilesFilter[0].start_date);
      if (allowedTilesFilter[0].end_date)
        setEndDate(allowedTilesFilter[0].end_date);
      // setStartDate(allowedTilesFilter[0].start_date);
      // setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setPostingType(allowedTilesFilter[0].posting_type);
      setAttribute(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (startDate && endDate && sourceDataType && postingType) {
      var data = {
        application_type: sessionStorage.getItem("application"),
        data_source_type: sourceDataType,
        start_date: Moment(startDate).format('MM/DD/YYYY'),
        end_date: Moment(endDate).format('MM/DD/YYYY'),
        posting_type: postingType,
        aggregation_attribute: attribute,
      };
      props.loadTop25Chart(data);
    }
  }, [allowedTilesFilter]);


  useEffect(() => {
    if (props.top25Data) {
      if (props.top25Data[attribute] && props.top25Data[attribute].length > 0) {
        var graphData = [
          {
            data: props.top25Data[attribute],
            xName: "value",
            yName: "incentive_amount",
            legend: attribute.replace(/_/g, " "),
            type: "Column",
          },
        ];
        setGraphData(graphData);
      } else {
        setGraphData([]);
      }
    }
  }, [props.top25Data]);

  const handlePostingType = (e) => {
    setPostingType(e.target.value);
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: e.target.value,
      aggregation_attribute: attribute,
    };
    props.loadTop25Chart(data);
  };

  const handleAttribute = (e) => {
    setAttribute(e.target.value);
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: Moment(startDate).format('MM/DD/YYYY'),
      end_date: Moment(endDate).format('MM/DD/YYYY'),
      posting_type: postingType,
      aggregation_attribute: e.target.value,
    };
    props.loadTop25Chart(data);
  };

  const handlePeriod = (type) => {
    var sDate;
    var eDate;
    if (type == "month") {
      sDate = Moment.utc()
        .subtract(1, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else if (type == "quarter") {
      sDate = Moment.utc()
        .subtract(3, "months")
        .startOf("month")
        .format("MM/DD/YYYY");
      eDate = Moment.utc()
        .subtract(3, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
    } else {
      sDate = Moment.utc().startOf("year").format("MM/DD/YYYY");
      eDate = Moment.utc().endOf("year").format("MM/DD/YYYY");
    }
    // props.loadTop25Chart({ ...data, start_date: sDate, end_date: eDate });
    var data = {
      application_type: sessionStorage.getItem("application"),
      data_source_type: sourceDataType,
      start_date: sDate,
      end_date: eDate,
      posting_type: postingType,
      aggregation_attribute: attribute,
    };
    props.loadTop25Chart(data);
  };

  function convertToSnakeCase(string) {
    if (string)
      return string.replace(/\s+/g, '_').toLowerCase();
  }


  return (
    <div>
      <div style={{ display: "flex", marginTop: 10 }}>
        <FormControl sx={{ m: 1, maxWidth: 120 }} size="small">
          <InputLabel id="posting-type">Posting Type</InputLabel>
          <Select
            labelId="posting-type"
            id="posting-type-select"
            value={postingType}
            label="Posting Type"
            onChange={handlePostingType}
          >
            <MenuItem value={"accrual"}>Accrual</MenuItem>
            <MenuItem value={"payment"}>Payment</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, maxWidth: 180 }} size="small">
          <InputLabel id="posting-type">Attributes</InputLabel>
          <Select
            labelId="attributes"
            id="attributes-select"
            value={attribute}
            label="Attribute"
            onChange={handleAttribute}
          >
            <MenuItem value={"contract_number"}>Contract Number</MenuItem>
            <MenuItem value={"supplier_number"}>Supplier Number</MenuItem>
            <MenuItem value={"material_number"}>Material Number</MenuItem>
            <MenuItem value={"customer_number"}>Customer Number</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab
            size="small"
            color="primary"
            aria-label="monthly"
            onClick={() => handlePeriod("month")}
          >
            <span style={{ marginLeft: 6 }}>M</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="quarterly"
            onClick={() => handlePeriod("quarter")}
          >
            <span style={{ marginLeft: 9 }}>Q</span>
          </Fab>
          <Fab
            size="small"
            color="primary"
            aria-label="yearly"
            onClick={() => handlePeriod("year")}
          >
            <span style={{ marginLeft: 9 }}>Y</span>
          </Fab>
        </Box>
      </div>
      <div>
        <BarChart
          data={graphData}
          attribute={attribute}
          dimension={dimension}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    allowedDashBoardData: state.initialData.dashboardashboardname,
    top25Data: state.initialData.top25,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTop25Chart: (data) => dispatch(loadTop25Chart(data, "dashboard")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopStats);
