import React, { useEffect } from "react";
import "../../../../library/scss/rsuite-default.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  FormLabel,
  MenuItem,
  Select, FormControl, InputLabel, Button, TextField
} from "@mui/material";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  allowedApps,
  loadAccrualVsPaymentGraph,
  loadValuesBasedOnAppTypeAndField,
} from "../../../../redux/actions";
import { connect } from "react-redux";
import Moment from "moment";
import clsx from "clsx";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ChartTheme,
  Legend,
  Category,
  Tooltip,
  ColumnSeries,
  ILoadedEventArgs,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { EmitType } from "@syncfusion/ej2-base";
import { Browser } from "@syncfusion/ej2-base";
import LoadingOverlay from 'react-loading-overlay';
import RingLoader from "react-spinners/RingLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
  },
  rootDiv: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    margin: "5px 0px 16px 0px",
  },
  graphContainer: {
    width: "80%",
    height: "100%",
    marginTop: 10,
    display: "flex",
    alignSelf: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    color: "#1675e0",
  },
  selectedItem: {
    borderRadius: 5,
  },
  fontSetting: {
    fontSize: "13px",
    lineHeight: 1.3
  },
  spinner: {
    // height: '100vh'
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: 24,
    paddingBottom: 5,
  },
  button: {
    marginRight: 10,
    // width: 80,
  },
}));
const MonthlyAccrualvsPayment = (props) => {
  const classes = useStyles();
  const [avssGraphData, setAvssGraphData] = React.useState([]);
  const [activeContractGraphArray, setActiveContractGraphArray] =
    React.useState({});

  const [avssGraphData1, setAvssGraphData1] = React.useState([]);
  const [avssGraphData2, setAvssGraphData2] = React.useState([]);
  const [maximumNumber, setMaximumNumber] = React.useState(100);
  const [allowedTilesFilter, setAllowedTilesFilter] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState('contract_type')

  useEffect(() => {
    if (props.allowedDashBoardData && props.allowedDashBoardData.length > 0) {
      const postingsSummaryAnalytic = props.allowedDashBoardData.find(item =>
        item.analytic_name === "Monthly Accrual vs Payment" && item.dashboard_name === "Postings Summary Analysis"
      );
      if (postingsSummaryAnalytic) {
        setAllowedTilesFilter([postingsSummaryAnalytic.filters]);
      } else {
        setAllowedTilesFilter([]);
      }
    }
  }, [props.allowedDashBoardData])

  useEffect(() => {
    if (allowedTilesFilter && allowedTilesFilter.length > 0) {
      setStartDate(allowedTilesFilter[0].start_date);
      setEndDate(allowedTilesFilter[0].end_date);
      setSourceDataType(allowedTilesFilter[0].data_source_type);
      setGroupBy(convertToSnakeCase(allowedTilesFilter[0].groupBy));
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    // if (props.page !== "dashboard")
    if (sourceDataType && startDate && endDate && groupBy) {
      props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, startDate, endDate, groupBy);
    }
  }, [allowedTilesFilter]);

  useEffect(() => {
    if (props.activeContractsGraphData) {
      setActiveContractGraphArray(props.activeContractsGraphData);
    }
  }, [props.activeContractsGraphData]);

  useEffect(() => {
    const months = [];
    const months1 = [];
    const months2 = [];
    var i;
    var label = ["", "Accrual", "Payment"];
    var accrualData = props.accrualPaymentGraphData.accrual_result;
    var paymentData = props.accrualPaymentGraphData.payment_result;
    const dateEnd = Moment();
    const dateStart = Moment().subtract(11, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push([dateStart.format("MMM YYYY")]);
      dateStart.add(1, "month");
    }
    months.forEach((e) => {
      e.push(0, 0);
    });
    months.unshift(label);
    setAvssGraphData(months);
    if (
      (props.accrualPaymentGraphData &&
        props.accrualPaymentGraphData.accrual_result &&
        props.accrualPaymentGraphData.accrual_result.length > 0) ||
      (props.accrualPaymentGraphData &&
        props.accrualPaymentGraphData.payment_result &&
        props.accrualPaymentGraphData.payment_result.length > 0)
    ) {
      for (i = 0; i <= 12; i++) {
        accrualData.forEach((e) => {
          if (Moment.utc(e.Date).format("MMM YYYY") === months[i][0]) {
            months[i][1] = e.Total;
          }
        });
        paymentData.forEach((e) => {
          if (Moment.utc(e.Date).format("MMM YYYY") === months[i][0]) {
            months[i][2] = e.Total
          }
        });
      }
    }
    var temp = {};
    var max = [];
    var max2 = [];
    months.map((item) => {
      temp.x = item[0];
      temp.y = item[1];
      max.push(item[1])
      max2.push(item[2])
      temp.toolTipMappingName = "Accrual";
      months1.push({ x: item[0], y: item[1], toolTipMappingName: 'Accrual' });
      temp.y = item[2];
      temp.toolTipMappingName = "Payment";
      months2.push({ x: item[0], y: item[2], toolTipMappingName: 'Payment' });
      temp = {};
    });
    var val = Math.max(...max.splice(1, max.length));
    var val2 = Math.max(...max2.splice(1, max2.length));
    setMaximumNumber(Math.max(val, val2))
    setAvssGraphData1(months1.filter((item) => item.y !== "Accrual"));
    setAvssGraphData2(months2.filter((item) => item.y !== "Payment"));
  }, [props.accrualPaymentGraphData]);
  useEffect(() => {
    props.allowedApps();
  }, []);

  const [allowedApps, setAllowedApps] = React.useState([]);
  useEffect(() => {
    if (props.allowedAppsData && props.allowedAppsData.length > 0) {
      setAllowedApps(props.allowedAppsData);
    }
  }, [props.allowedAppsData]);

  const data = {
    labels: activeContractGraphArray ? activeContractGraphArray.x_axis : "",
    datasets: [
      {
        label: "This year",
        data: activeContractGraphArray
          ? activeContractGraphArray.x_axis_values
          : [],
        backgroundColor: "#6FDEF6",
        barThickness: 6,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: { display: false },
    cornerRadius: 30,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "rgba(13, 19, 51, 0.4)",
            fontSize: 13,
          },
          gridLines: {
            display: false,
            drawBorder: false,
            color: "#EEEEEE",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            fontColor: "rgba(13, 19, 51, 0.4)",
            fontSize: 14,
            userCallback: function (label, index, labels) {
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "#EEEEEE",
            color: "#EEEEEE",
          },
        },
      ],
    },
  };

  const [sourceDataType, setSourceDataType] = React.useState(
    "Direct - Sales Data"
  );
  const [applicationType, setApplicationType] =
    React.useState(sessionStorage.getItem("application"));
  const [sourceDataTypeDataFromAPI, setSourceDataTypeDataFromAPI] =
    React.useState([]);
  const handleApplicationtype = (e) => {
    setSourceDataType("");
    setSourceDataTypeDataFromAPI([]);
    props.loadSourceDataType(e.target.value, "source_data_type");
    setApplicationType(e.target.value);
  };
  const handleSourceDataType = (e) => {
    setSourceDataType(e.target.value);
    // props.loadAccrualVsPaymentGraph(applicationType, e.target.value, startDate, endDate);
  };
  useEffect(() => {
    if (
      props.sourceDataTypeValue &&
      Object.keys(props.sourceDataTypeValue).length > 0
    ) {
      setSourceDataTypeDataFromAPI(
        props.sourceDataTypeValue["drop_down_value_keys"]
      );
    }
  }, [props.sourceDataTypeValue]);
  useEffect(() => {
    props.loadSourceDataType(sessionStorage.getItem("application"), "source_data_type");
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e);
  }
  const handleEndDate = (e) => {
    setEndDate(e);
  }
  const handleGroupBy = (e) => {
    setGroupBy(e.target.value);
  }
  function convertToSnakeCase(string) {
    if (string) {
      return string.replace(/\s+/g, '_').toLowerCase();
    }
    return "";
  }
  function handleSubmit() {
    props.loadAccrualVsPaymentGraph(sessionStorage.getItem("application"), sourceDataType, Moment(startDate).format('YYYY-MM-DDTHH:mm:ss'), Moment(endDate).format('YYYY-MM-DDTHH:mm:ss'), groupBy);
  }

  return (
    <LoadingOverlay
      active={props.loading}
      spinner={<RingLoader size={100} />}
      styles={{
        spinner: (base) => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: '#045FB4'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgba(52, 52, 52, 0)'
        }),
        content: (base) => ({
          ...base,
          color: 'black'
        })
      }}

      className={classes.spinner}
    >
      <div className={classes.rootDiv}>
        <Grid
          container
          className={classes.root}
          style={{ justifyContent: "space-between" }}
        >
          {props.page !== "dashboard" && (
            <Grid
              container
              style={{
                margin: 10,
                padding: 5,
                border: "1px solid #E6E6E6",
                borderRadius: 5,
              }}
            >
              <Grid
                item
                md={3}
                xs={12}
                style={{ paddingRight: 15 }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography classes={{ root: classes.fontSetting }} >Start Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: '1px 0 1px 11px',
                            alignSelf: 'center',
                            alignItems: 'center',
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderRadius: 5,
                            height: 37,
                            color: '#1675e0'
                          }
                        }}
                        value={startDate}
                        onChange={handleStartDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                style={{ paddingRight: 15 }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography classes={{ root: classes.fontSetting }} >End Date</Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        disableToolbar
                        clearable
                        InputProps={{
                          padding: 0,
                          disableUnderline: true,
                          style: {
                            // padding: '1px 0 1px 11px',
                            alignSelf: 'center',
                            alignItems: 'center',
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderRadius: 5,
                            height: 37,
                            color: '#1675e0'
                          }
                        }}
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} />}
                        format="MM/DD/YYYY"
                        minDate={startDate ? startDate : false}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={3} xs={12} style={{ paddingRight: 15 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography classes={{ root: classes.fontSetting }}>
                    Source Data Type
                  </Typography>
                  <Select
                    value={sourceDataType}
                    onChange={handleSourceDataType}
                    displayEmpty
                    className={clsx({
                      [classes.select]: true,
                    })}
                    classes={{
                      selectMenu: classes.selectedItem,
                    }}
                  >
                    {sourceDataTypeDataFromAPI.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                style={{ paddingRight: 13 }}
              >
                <Typography classes={{ root: classes.fontSetting }} >Aggregation Field</Typography>
                <Select
                  value={groupBy}
                  onChange={handleGroupBy}
                  className={clsx({
                    [classes.select]: true
                  })}
                  classes={{
                    selectMenu: classes.selectedItem
                  }}
                >
                  <MenuItem value={'contract_type'}>
                    Contract Type
                  </MenuItem>
                  <MenuItem value={'contract_group'}>
                    Contract Group
                  </MenuItem>
                  <MenuItem value={'contract_sub_group'}>
                    Contract Sub Group
                  </MenuItem>
                  <MenuItem value={'calculation_method'}>
                    Calculation Method
                  </MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                md={1}
                xs={12}
              // style={{ paddingRight: 18 }}
              >
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmit}
                    twoline="true"
                    disabled={sourceDataType && startDate && endDate && groupBy ? false : true}
                  >
                    <DirectionsRunIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid container className={classes.root}>
            <Grid item md={12} xs={6} className={classes.container}>
              <div className={`area-chart-wrapper${classes.graphContainer}`}>
                <ChartComponent
                  id="charts"
                  style={{ textAlign: "center" }}
                  legendSettings={{ enableHighlight: true }}
                  primaryXAxis={{
                    labelIntersectAction: Browser.isDevice ? "None" : "Trim",
                    labelRotation: Browser.isDevice ? -45 : 0,
                    valueType: "Category",
                    interval: 1,
                    majorGridLines: { width: 0 },
                    majorTickLines: { width: 0 },
                    edgeLabelPlacement: "Shift",
                  }}
                  primaryYAxis={{
                    // title: "Accrual Vs. Payment",
                    majorTickLines: { width: 0 },
                    lineStyle: { width: 0 },
                    maximum: maximumNumber + maximumNumber / 100,
                    interval: parseInt(maximumNumber / 10),
                  }}
                  chartArea={{ border: { width: 0 } }}
                  tooltip={{
                    enable: true,
                    header: "<b>${point.tooltip}</b>",
                    shared: true,
                  }}
                  width={Browser.isDevice ? "100%" : "90%"}
                  height={Browser.isDevice ? "100%" : "100%"}
                // title="Accrual vs. Payment"
                >
                  <Inject
                    services={[
                      ColumnSeries,
                      Legend,
                      Tooltip,
                      Category,
                      DataLabel,
                      Highlight,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={avssGraphData1}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Accrual"
                      type="Column"
                      fill='#2485FA'
                    ></SeriesDirective>
                    <SeriesDirective
                      dataSource={avssGraphData2}
                      tooltipMappingName="toolTipMappingName"
                      xName="x"
                      columnSpacing={0.1}
                      yName="y"
                      name="Payment"
                      type="Column"
                      fill='#FEC200'
                    ></SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>
              </div>
            </Grid>
          </Grid>
          {/* <Grid
            container
            className={`${classes.root} ${classes.container}`}
            style={{ marginTop: 40 }}
          >
            <Typography
              variant="h5"
              style={{ textAlign: "center" }}
              color="primary"
            >
              {" "}
              Active Contracts{" "}
            </Typography>
            <div className={`area-chart-wrapper ${classes.graphContainer}`}>
              <Bar data={data} options={options} width={"100%"} />
            </div>
          </Grid> */}
        </Grid>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.initialData.loading,
    accrualPaymentGraphData: state.initialData.accrualPaymentGraphData,
    activeContractsGraphData: state.initialData.activeContractsGraphData,
    sourceDataTypeValue:
      state.addMultipleConfigurationData.loadFieldValuesOnAppType,
    allowedAppsData: state.initialData.allowedApps,
    allowedDashBoardData: state.initialData.dashboardashboardname,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAccrualVsPaymentGraph: (applicationType, sourceDataType, startDate, endDate, groupBy) =>
      dispatch(loadAccrualVsPaymentGraph(applicationType, sourceDataType, startDate, endDate, groupBy)),
    loadSourceDataType: (applicationType, fieldId) =>
      dispatch(loadValuesBasedOnAppTypeAndField(applicationType, fieldId)),
    allowedApps: () => dispatch(allowedApps()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyAccrualvsPayment);
