import React, { useEffect, useRef } from 'react';
import {
    Card,
    Grid,
    CardHeader,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { DropdownKeyValue, DropdownArray, TextInputWithLabel } from '../../../../components/Inputs';
import { getContractDetailsData, getDefaultValues, fetchDocumentHistory, getApprovalGroupData, getApprovalLevelData } from '../../../../redux/actions';
import { connect } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Moment from 'moment';
import { ContractComments } from '../../../ContractSetup';
import DocHistory from '../../../DocumentManagement/Components/DocHistory';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23
    },
    container: {
        padding: 10
    },
    gridContainer: {
        padding: '0px 16px 0px 16px'
    },
    headerRootTimeline: {
        margin: 0,
        padding: '10px 0 0 40px',
        textAlign: 'center'
    },
    headerRoot: {
        margin: 0,
        padding: '10px 0 0 0'
    },
}));

const ApprovalStatus = (props, ref) => {
    const [approvalStatus, setApprovalStatus] = React.useState('');
    const [approvalGroup, setApprovalGroup] = React.useState('');
    const [notes, setNotes] = React.useState([]);
    const [approvalDetails, setApprovalDetails] = React.useState({});
    const [history, setHistory] = React.useState([]);

    const [approvalGroupArray, setApprovalGroupArray] = React.useState([]);
    const [possibleStatuses, setPossibleStatuses] = React.useState([]);



    useEffect(() => {
        if (props.historyData && props.historyData.length) {
            setHistory(props.historyData)
        }
    }, [props.historyData]);


    useEffect(() => {
        if (props.data && props.data.approval_group_id) {
            setApprovalGroup(props.data.approval_group_id)
        }
        if (props.data && props.data.contract_number) {
            var data = {
                "functionality": "contract_header",
                "functionality_id": props.data.contract_number,
                "application_type": sessionStorage.getItem('application')
            }
            props.getHistory(data)
        }
    }, [props.data]);
    useEffect(() => {
        if (props.comments) {
            setNotes(props.comments)
        }
    }, [props.comments]);
    useEffect(() => {
        if (props.workflowStatus && props.workflowStatus.length > 0) {
            setApprovalDetails(props.workflowStatus)
            var temp = props.workflowStatus.filter(item => item.status_description == (props.data && props.data.contract_status)).map(item => item)
            if (temp && temp[0]) {
                setPossibleStatuses(temp[0].possible_statuses)
                setApprovalGroupArray(temp[0])
                setApprovalGroup(temp[0].approval_group_details)
                props.submitValues('approval_group_id', temp[0].approval_group_details && temp[0].approval_group_details.key ? temp[0].approval_group_details.key : 0);
            }
        }
    }, [props.workflowStatus])
    var mode = localStorage.getItem('mode');
    const classes = useStyles();
    const { className } = props;

    function handleApprovalStatus(newValue) {
        setApprovalStatus(newValue);
        props.submitValues('contract_status', newValue);
        var temp = approvalDetails && approvalDetails.filter(item => item.status_description == newValue).map(item => item)
        if (temp && temp[0]) {
            props.submitValues('approval_status', temp[0].approval_status);
        }
    }
    function handleApprovalGroup(e) {
        setApprovalGroup(e.target.value);
        props.submitValues('approval_group_id', e.target.value);
    }

    function handleNotes(value) {
        setNotes([...notes, value])
        props.submitComments([...notes, value])
    }
    return (
        <div
            className={clsx(classes.root, className)}>
            <Card>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container >
                            <Grid
                                item
                                md={7}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                {/* {approvalStatusArray &&
                                    approvalStatusArray.enabled && */}
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', padding: 10 }}>
                                            <CardHeader
                                                title="APPROVAL STATUS"
                                                titleTypographyProps={{ variant: 'h3' }}
                                                classes={{ root: classes.headerRoot }}
                                            />
                                            <DropdownArray heading={""} twoline='true' onChange={handleApprovalStatus}
                                                data={possibleStatuses ? possibleStatuses : []}
                                                prevalue={props.data ? props.data.contract_status : ''} />
                                        </div>
                                        {approvalGroup && approvalGroup.name &&
                                            <div style={{ width: '50%', padding: 10 }}>
                                                <CardHeader
                                                    title={"APPROVAL GROUP*"}
                                                    titleTypographyProps={{ variant: 'h3' }}
                                                    classes={{ root: classes.headerRoot }}
                                                //style={{ paddingBottom: 20 }}
                                                />

                                                <FormControl >
                                                    <TextInputWithLabel classes={{ root: classes.fontSetting }} twoline="true" mandatory
                                                        prevalue={approvalGroup.name} disabled />
                                                    {/* <Select
                                                        disableUnderline
                                                        onChange={handleApprovalGroup}
                                                        displayEmpty
                                                        classes={{
                                                            selectMenu: classes.selectedItem
                                                        }}
                                                        value={approvalGroup}
                                                    >
                                                        {approvalGroupArray && approvalGroupArray.approval_group_details && approvalGroupArray.approval_group_details
                                                            .map((item) => {
                                                                return (
                                                                    <MenuItem value={item.key} key={item.key}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Select> */}
                                                </FormControl>

                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                classes={{ root: classes.gridContainer }}
                            >
                                <ContractComments onChange={handleNotes} approvalStatus={props.data && props.data.approval_status} contractId={props.data.id} contractNumber={props.data.contract_number}
                                    notes={notes} />

                            </Grid>
                            {(history && history.length > 0) &&
                                < Grid
                                    item
                                    md={12}
                                    xs={12}
                                    classes={{ root: classes.gridContainer }}
                                >
                                    <DocHistory data={history} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </form>
            </Card>
        </div >
    );

}

const mapStateToProps = state => ({
    //data: state.addContractData.contractGeneralData,
    dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
    historyData: state.DocumentManagement.documentHistory,
    approvalGroupData: state.addContractData.approvalLevelData,
    workflowStatus: state.DocumentManagement.documentStatus,
});

const mapDispatchToProps = dispatch => {
    return {
        getcontractdetails: (id) => dispatch(getContractDetailsData(id)),
        // onLoadingDefault: () => dispatch(getDefaultValues()),
        //onLoadingHistory: () => dispatch(getHistory()),
        getApprovalGroupData: () => dispatch(getApprovalLevelData()),
        getHistory: (data) => dispatch(fetchDocumentHistory(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ApprovalStatus);