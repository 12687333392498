import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
    OutlinedInput,
    FormLabel,
    MenuItem,
    Select, TextField, Checkbox,
    ListItemText, Input
} from '@mui/material';
import clsx from 'clsx';
import MomentUtils from "@date-io/moment";
import { makeStyles, useTheme } from '@mui/styles';
import { allowedApps, addExceptionManagement, getContractsTableDataList, getMaterialMasterData, getCustomerMasterData, getSupplierMasterData, getEmployeeData, getExceptionTierEvals, fetchDropdownConfig, getLblDispDesFieldValue } from '../../redux/actions';
import { connect } from 'react-redux';
import BeatLoader from "react-spinners/BeatLoader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AllOtherAttributeMasterComponent, ContractNumberAC, MasterDataComponent, SearchDropdown } from '../../components/Inputs';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Datepicker, LabelText, TextInputWithLabel, DropdownArray, MultiSelectDropdown } from '../../components/Inputs';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: 10,
        borderRadius: 10,
        padding: '27px 29px 27px 29px'
    },
    bodyContainer: {
        backgroundColor: theme.palette.white,
        border: '1px solid #EEEEEE',
        borderRadius: 6,
        marginBottom: 23,
        marginTop: 25
    },
    link: {
        color: theme.palette.text.primary,
        opacity: 0.3,
        textDecoration: 'none',
        border: 0,
        fontSize: 16,
        paddingRight: 10
    },
    container: {
        padding: 10
    },
    button: {
        marginRight: 25,
        width: 140
    },
    selectRoot: {
        padding: '0px 0px 15px 0px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
    },
    select: {
        width: '100%',
        marginTop: 10
    },
    inputTwoLine: {
        marginTop: 0,
        width: '100%',
        paddingLeft: 10,
        height: 36
    },
    formLabel: {
        height: 50,
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: theme.typography.h3.fontSize
    },
    buttonRoot: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 28,
        paddingTop: 30
    },
    selectedItem: {
        color: theme.palette.text.grey
    },
    dropdownbottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid #ddd',
    },
    btn: {
        marginRight: 25,
        padding: '10px,15px'
    },
}));
const AddApprovalDesigner = props => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [exceptionValue, setExceptionValue] = React.useState();
    const [overrideReason, setOverrideReason] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [allowedApps, setAllowedApps] = React.useState([sessionStorage.getItem('application')]);
    const [applicationType, setApplicationType] = React.useState(sessionStorage.getItem('application'));
    const [contractNumber, setContractNumber] = React.useState([]);
    const [exceptionIdentifier, setExceptionIdentifier] = React.useState([]);
    const [calculationRules, setCalculationRules] = React.useState([]);
    const [indentifierValue, setIdentifierValue] = React.useState('');
    const [exceptionType, setExceptionType] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [contractNumbersList, setContractNumbersList] = React.useState([]);
    const [internalDescriptionArray, setInternalDescriptionArray] = React.useState([]);
    const [contractNumbersList2, setContractNumbersList2] = React.useState([]);
    const [internalDescriptionArray2, setInternalDescriptionArray2] = React.useState([]);
    const [contractNumberMultiple, setContractNumberMultiple] = React.useState([]);
    const [exceptionIdentifierArray, setExceptionIdentifierArray] = React.useState([]);
    const [attributeValues, setAttributeValues] = React.useState({});
    const [attributeValues2, setAttributeValues2] = React.useState({});
    const [selected3, setSelected3] = React.useState([]);
    const [open3, setOpen3] = React.useState('');
    const [customerNumberList, setCustomerNumberList] = React.useState([]);
    const [customerNameListArray, setCustomerNameListArray] = React.useState([]);
    const [materialNumberListArray, setMaterialNumberListArray] = React.useState([]);
    const [materialNumberList, setMaterialNumberList] = React.useState([]);
    const [dynamicFields, setDynamicFields] = React.useState([])
    const [calcExceptionValue, setCalcExceptionValue] = React.useState([])
    const [fieldLabel, setFieldLabel] = React.useState([]);
    const [type, setType] = React.useState('');
    const [externalFields, setExternalFields] = React.useState([]);
    const [allowedField, setAllowedField] = React.useState('');
    const [requiredField, setRequiredField] = React.useState([]);
    const isAllSelected3 =
        exceptionIdentifierArray && exceptionIdentifierArray.length > 0 && selected3.length === exceptionIdentifierArray.length;
    const isAllExceptionValue =
        calculationRules && calculationRules.length > 0 && selected3.length === calculationRules.length;

    useEffect(() => {
        props.onCheckContractsTable(localStorage.getItem('application_type'));
    }, []);

    useEffect(() => {
        var data = { "data_source": "incentive_overrides" };
        props.getLblDispDesFieldValue(data, 'post');
    }, []);


    useEffect(() => {
        if (props.tableData) {
            setFieldLabel(props.tableData.field_label_attributes);
        }
    }, [props.tableData])

    useEffect(() => {
        var newArray = []
        var mandatory = []
        var requiredFields = []
        if (props.tableData) {
            Object.values(props.tableData.field_label_attributes)
                .sort((a, b) => a.sort_details > b.sort_details ? 1 : -1)
                .map((item) => {
                    newArray.push(item)
                    if (item.mandatory || item.sort_details) {
                        if (item.mandatory) {
                            mandatory.push(item.key)
                            requiredFields.push(item.current)
                        }
                    }
                })
            setExternalFields(newArray)
            setAllowedField(mandatory)
            setRequiredField(requiredFields);

        }
    }, [props.tableData])


    // useEffect(()=>{
    //     if(props.exceptionManagementTierEvals){
    //         Object.entries(props.exceptionManagementTierEvals).map(([key,value]) => {
    //         if(value.name==='Customer Number' &&activeDropdown&&activeDropdown['customer_number']) {
    //             props.getCustomerMasterData(1,0);
    //         }
    //         else if(value.name==='Material Number' &&activeDropdown&&activeDropdown['customer_number']) {
    //             props.getMaterialMasterData()
    //         }
    //         }
    //         )
    //     }
    // },[props.exceptionManagementTierEvals])

    // useEffect(() => {
    //     if (props.customerMasterData && props.customerMasterData.records && props.customerMasterData.records.length > 0) {
    //         var tempArray = [];
    //         var tempNameArray = [];
    //         props.customerMasterData.records.map((item) => {
    //             tempArray.push(item.customer_number);
    //             tempNameArray.push(item.customer_name);
    //         })
    //         setCustomerNumberList(tempArray)
    //         setCustomerNameListArray(tempNameArray);
    //     }
    // }, [props.customerMasterData])
    // useEffect(() => {
    //     if (props.materialMasterData && props.materialMasterData.records && props.materialMasterData.records.length > 0) {
    //         var tempArray = []
    //         var tempNameArray = []
    //         props.materialMasterData.records.map((item) => {
    //             tempArray.push(item.material_number);
    //             tempNameArray.push(item.material_description);
    //         })
    //         setMaterialNumberList(tempArray)
    //         setMaterialNumberListArray(tempNameArray);
    //     }
    // }, [props.materialMasterData])
    // useEffect(()=>{
    //     var newArray = [];
    //     var tempNameArray = [];
    //         if (props.supplierMasterData && props.supplierMasterData.records && props.supplierMasterData.records.length > 0)
    //             props.supplierMasterData.records
    //                 .map(e => {
    //                     newArray.push(e.supplier_number);
    //                     tempNameArray.push(e.supplier_name);
    //                 })
    //         // setDropDownValue(newArray.sort((a, b) => a - b));
    //         setContractNumbersList(newArray);
    //         setInternalDescriptionArray(tempNameArray);
    // },[props.supplierMasterData])
    // useEffect(() => {
    //     var newArray=[]
    //     var tempNameArray = [];
    //     if (props.employeeMasterData && props.employeeMasterData.records && props.employeeMasterData.records.length > 0)
    //             props.employeeMasterData.records
    //                 .map(e => {
    //                     newArray.push(e.employee_number);
    //                     tempNameArray.push(e.employee_name);
    //                 })
    //         // setDropDownValue(newArray.sort((a, b) => a - b));
    //         setContractNumbersList(newArray);
    //         setInternalDescriptionArray(tempNameArray);
    // }, [props.employeeMasterData]);    
    useEffect(() => {
        var contracts = [];
        var tempNameArray = [];
        if (props.contractTabledataComplete) {
            props.contractTabledataComplete.map(item => {
                contracts.push(item.contract_number.toString())
                tempNameArray.push(item.internal_description);
            })
            setContractNumbersList2(contracts);
            setInternalDescriptionArray2(tempNameArray);
        }


    }, [props.contractTabledataComplete])
    useEffect(() => {
        var newArray = []
        var newArray2 = []
        if (props.exceptionManagementTierEvals && Object.entries(props.exceptionManagementTierEvals).length > 0) {
            Object.entries(props.exceptionManagementTierEvals).map(([key, value]) => {
                if (!value['is_key_figure'])
                    newArray.push({ 'key': key, ...value })
                if (value['is_key_figure'])
                    newArray2.push({ 'key': key, ...value })
            })
            setExceptionIdentifierArray(newArray)
            setCalculationRules(newArray2)
        }

    }, [props.exceptionManagementTierEvals])

    useEffect(() => {
        var newArray = []
        if (exceptionIdentifier && exceptionIdentifier.length > 0 && exceptionIdentifierArray && exceptionIdentifierArray.length > 0) {
            exceptionIdentifierArray.map(item => {
                if (exceptionIdentifier.includes(item.key)) {
                    newArray.push(item)
                }
            })
            setDynamicFields(newArray)
        }

    }, [exceptionIdentifier, exceptionIdentifierArray])
    const filterOptions = createFilterOptions({
        stringify: (option) => internalDescriptionArray[contractNumbersList.indexOf(option)] + option
    });
    const filterOptions2 = createFilterOptions({
        stringify: (option) => internalDescriptionArray2[contractNumbersList2.indexOf(option)] + option
    });

    function handleExceptionReason(newValue) {
        if (newValue) {
            setOverrideReason(newValue);
        }
        else
            setOverrideReason('')
    }
    const handleApplicationtype = (e) => {
        setApplicationType(e.target.value);
    }

    const handleExceptionType = (e) => {
        setExceptionType(e.target.value)
    }

    const handleContractNumberMultiple = (newValue) => {
        let newSelected = newValue;
        if (newValue.length > 0) {
            setSelected(newSelected);
            setContractNumberMultiple(newSelected);
            setExceptionIdentifierArray([])
            setCalculationRules([])
            props.getExceptionTierEvals(applicationType, newSelected)
        } else {
            setSelected([]);
            setContractNumberMultiple([]);
            setExceptionIdentifierArray([])
            setCalculationRules([])
        }
    };
    function handleOnSubmit() {
        var data = {
            "application_type": applicationType,
            "contract_number": parseInt(contractNumberMultiple),
            "start_date": Moment(startDate).format('YYYY-MM-DD'),
            "end_date": Moment(endDate).format('YYYY-MM-DD'),
            "exception_value": exceptionValue ? parseFloat(exceptionValue) : 0,
            "exception_reason": overrideReason,
            "comments": comments,
            "exception_type": exceptionType,

        };
        if (exceptionType == 'calculation_rule') {
            attributeValues["rule_exception"] = attributeValues2;
        }
        data["exception_mapping"] = attributeValues;

        props.onSubmit(data);
    }
    function handleClearAll() {
        setContractNumber([])
        setStartDate(null)
        setEndDate(null)
        setExceptionValue('')
        setOverrideReason('')
        setComments('')
        setExceptionType('')
        setIdentifierValue('')
        setExceptionIdentifier([])
    }
    function handleStartDate(newValue) {
        if (newValue && newValue.isValid()) {
            setStartDate(newValue);
        } else {
            setStartDate(newValue);
        }
    }
    function handleEndDate(newValue) {
        if (newValue && newValue.isValid()) {
            setEndDate(newValue);
        } else {
            setEndDate(newValue);
        }
    }
    function handleAttributeValues(newValue, item, key) {
        setAttributeValues({ ...attributeValues, [item]: key ? Number(newValue) : newValue })
    }
    function handleAttributeValues2(newValue, item, key) {
        setAttributeValues2({ ...attributeValues2, [item]: key ? Number(newValue) : newValue })
    }
    const handleSelectValues = (event, value, type) => {
        let newSelected = [];
        const selectedIndex = selected3.indexOf(value);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected3, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected3.slice(1));
        } else if (selectedIndex === selected3.length - 1) {
            newSelected = newSelected.concat(selected3.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected3.slice(0, selectedIndex),
                selected3.slice(selectedIndex + 1),
            );
        }
        if (value === "all") {
            if (type == 'exception_value')
                setSelected3(selected3.length === calculationRules.map(item => item.key).length ? [] : calculationRules.map(item => item.key));

            else
                setSelected3(selected3.length === exceptionIdentifierArray.length ? [] : exceptionIdentifierArray.map(item => item.key));
        }
        else
            setSelected3(newSelected)
    }
    function handleOpen(type) {
        setType(type)
        if (type == 'exception_value')
            setSelected3(calcExceptionValue)
        else
            setSelected3(exceptionIdentifier)
        setOpen3(true)
    }
    function onCancel() {
        setOpen3(false)
        setSelected3([])
    }
    function onAdd(type) {
        if (type == 'exception_value')
            setCalcExceptionValue(selected3)
        else
            setExceptionIdentifier(selected3)
        setOpen3(false)
        setSelected3([])
    }

    function handleClose() {
        setOpen3(false)
        setType([])
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link variant='h4' classes={{
                    root: classes.link
                }}
                    to='/exception-management'
                >
                    Exception Management
                </Link>
                <Typography color="textPrimary" variant='h4'>Add Exception</Typography>
            </Breadcrumbs>
            <div className={classes.bodyContainer}>
                <form
                    autoComplete="off"
                    noValidate
                >
                    <div className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['application_type'] ? fieldLabel['application_type']['current'] : 'Application Type'}
                                </FormLabel>
                                <Select
                                    value={applicationType}
                                    disabled
                                    onChange={handleApplicationtype}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    style={{ marginTop: '0.2rem' }}
                                >   {allowedApps && allowedApps.length > 0 && allowedApps.map((item, index) => {
                                    return (
                                        <MenuItem value={item} key={index} style={{ textTransform: 'capitalize' }}>
                                            {item}
                                        </MenuItem>
                                    )
                                })}
                                </Select>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['contract_number'] ? fieldLabel['contract_number']['current'] : 'Contract Number'}
                                </FormLabel>
                                <div className={classes.dropdownColumn} style={{ marginTop: -3 }} >
                                    <SearchDropdown id='contract_number_multiple'
                                        table='contract_header' keyName='contract_number'
                                        description={true}
                                        onChange={handleContractNumberMultiple}
                                        applicationType={sessionStorage.getItem("application")}
                                    />

                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                            >
                                {exceptionIdentifierArray && exceptionIdentifierArray.length > 0 ?
                                    <>
                                        <FormLabel
                                            classes={{ root: classes.formLabel }}
                                        >
                                            {fieldLabel['exception_mapping'] ? fieldLabel['exception_mapping']['current'] : 'Exception Identifier'}
                                        </FormLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={exceptionIdentifier}
                                            style={{ maxHeight: "50px", width: '100%', marginTop: 0, height: 35 }}
                                            input={<OutlinedInput sx={{ padding: '4px' }} />}
                                            onOpen={() => handleOpen('identifier')}
                                            onClose={handleClose}
                                            open={open3 && type == 'identifier'}
                                            renderValue={(appType) => {
                                                var priceType = []
                                                priceType = exceptionIdentifierArray.filter(item => appType.includes(item.key)).map(item => item.name)
                                                return priceType.join(", ")
                                            }}
                                            className={classes.multiSelect}

                                        >
                                            <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all', 'identifier')}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={isAllSelected3} />
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {exceptionIdentifierArray && exceptionIdentifierArray.length > 0 && exceptionIdentifierArray
                                                .map(item => {
                                                    return (
                                                        <MenuItem onClick={(event) => handleSelectValues(event, item.key, 'identifier')} value={item.key} key={item.key}>
                                                            <Checkbox
                                                                color='primary'
                                                                checked={selected3.indexOf(item.key) > -1} />
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            <div className={classes.dropdownbottom}>
                                                <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('identifier')} >
                                                    Apply
                                                </Button>
                                            </div>
                                        </Select>
                                    </>
                                    :
                                    <LabelText style={{ marginTop: '0.6rem' }} heading={fieldLabel['exception_mapping'] ? fieldLabel['exception_mapping']['current'] : 'Exception Identifier'} data={'Contract Number'} twoline='true' border />
                                }
                            </Grid>
                            {dynamicFields && dynamicFields.length > 0 && dynamicFields.map(item => {
                                return (

                                    (item.key == 'supplier_number' || item.key == 'employee_number' || item.key == 'customer_number' || item.key == 'material_number') ?
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                            style={{ marginTop: '0.5rem' }}
                                        >
                                            <FormLabel className={classes.formLabel}>
                                                {item.name}
                                            </FormLabel>
                                            <div style={{ marginTop: -3 }}>
                                                <SearchDropdown id='exception_single'
                                                    table={item.key == 'supplier_number' ? 'supplier_master' : item.key == 'employee_number' ? 'employee_master'
                                                        : item.key == 'material_number' ? 'material_master' : 'customer_master'}
                                                    keyName={item.key}
                                                    description={true}
                                                    onChange={(value) => handleAttributeValues(value, item.key, item.is_key_figure)}
                                                    style={{ marginTop: 0 }}
                                                />
                                            </div>
                                        </Grid>

                                        :
                                        <Grid
                                            item
                                            md={2}
                                            xs={12}
                                        // classes={{ root: classes.gridContainer }}

                                        >
                                            {props.viewMode ?
                                                <LabelText heading={item.name} data={attributeValues && attributeValues[item.key] && attributeValues[item.key].join(",")} twoline='true' required />
                                                :
                                                <>
                                                    <FormLabel className={classes.formLabel}>
                                                        {item.name}
                                                    </FormLabel>
                                                    {/* Object.values(item) */}
                                                    <OutlinedInput
                                                        type={item.is_key_figure ? 'Number' : 'String'}
                                                        value={attributeValues && attributeValues[item.key]}
                                                        classes={{ root: classes.inputTwoLine }}
                                                        onChange={(e) => handleAttributeValues(e.target.value, item.key, item.is_key_figure)}
                                                    />
                                                </>
                                            }
                                        </Grid>
                                )
                            })}

                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: '-0.3rem' }}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['start_date'] ? fieldLabel['start_date']['current'] : 'Start Date'}
                                </FormLabel>
                                <div>
                                    <Datepicker
                                        twoline='true' onChange={handleStartDate} error={typeof startDate === 'boolean' && !startDate ? true : false}
                                        prevalue={startDate} />
                                </div>

                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: '-0.3rem' }}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['end_date'] ? fieldLabel['end_date']['current'] : 'End Date'}
                                </FormLabel>
                                <div>
                                    <Datepicker
                                        twoline='true'{...props} onChange={handleEndDate} error={typeof endDate === 'boolean' && !endDate ? true : false}
                                        prevalue={endDate} />
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: "-2px" }}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['exception_type'] ? fieldLabel['exception_type']['current'] : 'Exception Type'}
                                </FormLabel>
                                <Select
                                    value={exceptionType}
                                    onChange={handleExceptionType}
                                    displayEmpty
                                    className={clsx({
                                        [classes.select]: true
                                    })}
                                    classes={{
                                        selectMenu: classes.selectedItem
                                    }}
                                    style={{ height: 35 }}
                                >
                                    <MenuItem value={'calculation_rule'} key={'calculation_rule'} style={{ textTransform: 'capitalize' }}> Calculation Rule </MenuItem>
                                    <MenuItem value={'incentive_amount'} key={'incentive_amount'} style={{ textTransform: 'capitalize' }}> Incentive Amount </MenuItem>
                                    <MenuItem value={'tier_rate'} key={'tier_rate'} style={{ textTransform: 'capitalize' }}> Incentive Rate </MenuItem>
                                </Select>
                            </Grid>

                            <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: 2 }}
                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                    required
                                >
                                    {fieldLabel['exception_value'] ? fieldLabel['exception_value']['current'] : 'Exception Value'}
                                </FormLabel>
                                {exceptionType === 'calculation_rule' ?
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={calcExceptionValue}
                                        style={{ maxHeight: "50px", width: '100%', height: 37, marginTop: 0 }}
                                        input={<OutlinedInput />}
                                        onOpen={() => handleOpen('exception_value')}
                                        onClose={handleClose}
                                        open={open3 && type == 'exception_value'}
                                        renderValue={(appType) => {
                                            var priceType = []
                                            priceType = calculationRules.filter(item => appType.includes(item.key)).map(item => item.name)
                                            return priceType.join(", ")
                                        }}
                                        className={classes.multiSelect}

                                    >
                                        <MenuItem value='all' onClick={(event) => handleSelectValues(event, 'all', 'exception_value')}>
                                            <Checkbox
                                                color='primary'
                                                checked={isAllExceptionValue} />
                                            <ListItemText primary='Select All' />
                                        </MenuItem>
                                        {calculationRules && calculationRules.length > 0 && calculationRules
                                            .map(item => {
                                                return (
                                                    <MenuItem onClick={(event) => handleSelectValues(event, item.key, 'exception_value')} value={item.key} key={item.key}>
                                                        <Checkbox
                                                            color='primary'
                                                            checked={selected3.indexOf(item.key) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                )
                                            })}
                                        <div className={classes.dropdownbottom}>
                                            <Button variant="outlined" color="primary" className={classes.btn} onClick={onCancel}>
                                                Cancel
                                            </Button>

                                            <Button variant="contained" color="primary" className={classes.btn} onClick={() => onAdd('exception_value')} >
                                                Apply
                                            </Button>
                                        </div>
                                    </Select>
                                    :
                                    <OutlinedInput
                                        value={exceptionValue}
                                        inputProps={{ min: "1", step: "1" }}
                                        type={'number'}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setExceptionValue(e.target.value)} />
                                }
                            </Grid>

                            {calcExceptionValue && calcExceptionValue.length > 0 && calculationRules && calculationRules.length > 0 &&
                                calculationRules.filter(item => calcExceptionValue.includes(item.key)).map(item => {
                                    return (

                                        (item.key == 'supplier_number' || item.key == 'employee_number' || item.key == 'customer_number' || item.key == 'material_number') ?
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                                style={{ marginTop: '0.5rem' }}
                                            >
                                                <FormLabel className={classes.formLabel}>
                                                    {item.name}
                                                </FormLabel>
                                                <div style={{ marginTop: -3 }}>
                                                    <SearchDropdown id='rule_single'
                                                        table={item.key == 'supplier_number' ? 'supplier_master' : item.key == 'employee_number' ? 'employee_master'
                                                            : item.key == 'material_number' ? 'material_master' : 'customer_master'}
                                                        keyName={item.key}
                                                        description={true}
                                                        onChange={(value) => handleAttributeValues2(value, item.key, item.is_key_figure)}
                                                    />
                                                </div>
                                            </Grid>

                                            :
                                            <Grid
                                                item
                                                md={2}
                                                xs={12}
                                            // classes={{ root: classes.gridContainer }}

                                            >
                                                {props.viewMode ?
                                                    <LabelText heading={item.name} data={attributeValues2 && attributeValues2[item.key] && attributeValues2[item.key].join(",")} twoline='true' required />
                                                    :
                                                    <>
                                                        <FormLabel className={classes.formLabel}>
                                                            {item.name}
                                                        </FormLabel>
                                                        <OutlinedInput
                                                            type={item.is_key_figure ? 'Number' : 'String'}
                                                            value={attributeValues2 && attributeValues2[item.key]}
                                                            classes={{ root: classes.inputTwoLine }}
                                                            onChange={(e) => handleAttributeValues2(e.target.value, item.key, item.is_key_figure)}
                                                        />
                                                    </>
                                                }
                                            </Grid>
                                    )
                                })}

                            <Grid
                                item
                                md={2}
                                xs={12}

                            >
                                <FormLabel
                                    classes={{ root: classes.formLabel }}
                                >
                                    {fieldLabel['exception_reason'] ? fieldLabel['exception_reason']['current'] : 'Exception Reason'}
                                </FormLabel>

                                {externalFields.map((currentObject, index) => {
                                    if (currentObject.default === 'Exception Reason') {
                                        return currentObject.drop_down_reference_value && currentObject.drop_down_reference_value.length > 0 ? (
                                            <Select
                                                value={overrideReason}
                                                onChange={(e) => handleExceptionReason(e.target.value)}
                                                displayEmpty
                                                className={clsx({
                                                    [classes.select]: true
                                                })}
                                                classes={{
                                                    selectMenu: classes.selectedItem
                                                }}
                                            >   {currentObject.drop_down_reference_value && currentObject.drop_down_reference_value.length > 0 && currentObject.drop_down_reference_value.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.key} key={item.key} style={{ textTransform: 'capitalize' }}>
                                                        {item.key}
                                                    </MenuItem>
                                                )
                                            })}
                                            </Select>
                                        ) : (
                                            <OutlinedInput
                                                key={index}
                                                value={overrideReason}
                                                classes={{ root: classes.inputTwoLine }}
                                                onChange={(e) => handleExceptionReason(e.target.value)}
                                            />
                                        );
                                    }
                                })}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                                style={{ marginTop: '0.2rem' }}
                            >
                                <div className={classes.selectRoot}>
                                    <FormLabel
                                        classes={{ root: classes.formLabel }}
                                    >
                                        {fieldLabel['comments'] ? fieldLabel['comments']['current'] : 'Comments'}
                                    </FormLabel>
                                    <OutlinedInput
                                        value={comments}
                                        classes={{ root: classes.inputTwoLine }}
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
                <div className={classes.buttonRoot}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                        Clear
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                        disabled={!applicationType || !contractNumberMultiple || !startDate || !endDate || !exceptionType ||
                            (exceptionType == 'calculation_rule' ? (!exceptionValue && Object.entries(attributeValues2).length == 0) : !exceptionValue) ? true : false}
                    >
                        {props.loadingAPI ?
                            <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                            :
                            'Submit'
                        }
                    </Button>
                </div>
            </div >
            {/* <div className={classes.buttonRoot}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClearAll} >
                    Clear
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={props.loadingAPI ? '' : handleOnSubmit}
                    disabled={!applicationType || !contractNumberMultiple || !startDate || !endDate || !exceptionType ||
                        (exceptionType == 'calculation_rule' ? (!exceptionValue && Object.entries(attributeValues2).length == 0) : !exceptionValue) ? true : false}
                >
                    {props.loadingAPI ?
                        <BeatLoader color={'rgb(54, 215, 183)'} loading={props.loadingAPI} size={6} />
                        :
                        'Submit'
                    }
                </Button>
            </div> */}
        </div >
    );
};
const mapStateToProps = state => {
    return {
        loading: state.simulationData.loading,
        tableData: state.addMultipleConfigurationData.defaultValueLblDisp,
        dropdownData: state.addMultipleConfigurationData.multipleConfigurationData,
        loadingAPI: state.customerData.loading,
        approvalData: state.customerData.approvalDesignerGetAll,
        approvalGroupData: state.addMultipleConfigurationData.approvalGroupData,
        loadingDropdownValues: state.addMultipleConfigurationData.loading,
        allowedAppsData: state.initialData.allowedApps,
        customerMasterData: state.customerData.customerMasterData,
        supplierMasterData: state.customerData.supplierMasterData,
        employeeMasterData: state.customerData.employeeMasterData,
        contractTabledataComplete: state.addContractData.contractAllListData,
        exceptionManagementTierEvals: state.customerData.exceptionManagementTierEvals,
        materialMasterData: state.customerData.materialMasterData,
        dropdownConfig: state.financialCloseData.dropdownConfig
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (data) => dispatch(addExceptionManagement(data)),
        getExceptionTierEvals: (app, data) => dispatch(getExceptionTierEvals(app, data)),
        allowedApps: () => dispatch(allowedApps()),
        getCustomerMasterData: (pagination, limit) => dispatch(getCustomerMasterData(pagination, limit)),
        getSupplierMasterData: (pagination, limit) => dispatch(getSupplierMasterData(pagination, limit)),
        getEmployeeData: (pagination, limit) => dispatch(getEmployeeData(pagination, limit, '')),
        onCheckContractsTable: (applicationType) => dispatch(getContractsTableDataList(applicationType)),
        getMaterialMasterData: () => dispatch(getMaterialMasterData('NoLimit')),
        getLblDispDesFieldValue: (data, type) => dispatch(getLblDispDesFieldValue(data, type)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddApprovalDesigner);