import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    TablePagination,
    Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getMessageLogs } from '../../redux/actions';
import MaterialTable from 'material-table';
import { createTheme } from "@mui/material/styles";
import Moment from 'moment';
import { tableIcons } from '../../components/Icons/TableIcons';
const theme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
            },
        },
        MuiTableCell: {
            root: {
                padding: 10
            },
        },
        MuiToolbar: {
            root: {
                minHeight: 0
            },
        },
    }
});
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        padding: '20px 16px',
    }
}));

const MessageLog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [dataRows, setDataRows] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const columns = [
        {
            field: 'app',
            title: 'APP',
            editable: 'never',
            type: 'string',

        },
        {
            field: 'functionality',
            title: 'Functionality',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'input_type',
            title: 'Input Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'input_number',
            title: 'Input Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'transaction_number',
            title: 'Transaction Number',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'message_type',
            title: 'Message Type',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'message_description',
            title: 'Message Description',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'user_name',
            title: 'User Name',
            type: 'string',
            editable: 'never',
        },
        {
            field: 'updated_at',
            title: 'Created At',
            type: 'string',
            editable: 'never',
            render: (rowData) => <a className={classes.hover} > {Moment(rowData.updated_at).local().format('YYYY-MM-DD HH:MM:SS')}</a>
        },
    ];
    useEffect(() => {
        props.getMessageLog(1, 15);
    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.getMessageLog(newPage + 1, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event, newValue) => {
        setPage(0);
        console.log(event.target.value)
        setRowsPerPage(event.target.value);
        props.getMessageLog(1, event.target.value);
    };
    useEffect(() => {
        var tempRows = [];
        if (props.messageLogData && props.messageLogData.records && props.messageLogData.total_record > 0) {
            props.messageLogData.records
                .map(e => {
                    tempRows.push({
                        id: e.ID,
                        app: e.app,
                        functionality: e.functionality,
                        input_type: e.input_type,
                        input_number: e.input_number,
                        transaction_number: e.transaction_number,
                        message_type: e.message_type,
                        message_description: e.message_description,
                        user_name: e.user_name,
                        updated_at: e.UpdatedAt
                    });
                })
            setDataRows(tempRows);
            setCount(props.messageLogData.total_record)
        }
    }, [props.messageLogData]);
    return (
        <div>
            <Typography variant="h1" color='primary' style={{ marginLeft: 16, marginTop: 18 }}>Activity Logs</Typography>
            <div className={classes.root}>
                {props.messageLogData && props.messageLogData.records && props.messageLogData.total_record > 0 ?
                    <MaterialTable
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[5, 15, 20]}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}

                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            ),
                            Toolbar: (props) => (
                                <div
                                    style={{
                                        height: "0px",
                                    }}
                                >
                                </div>
                            ),
                        }}
                        title={' '}
                        editable={true}
                        icons={tableIcons}
                        columns={columns}
                        data={dataRows}
                        options={{
                            maxBodyHeight: '100vh',
                            search: false,
                            filtering: true,
                            pageSize: rowsPerPage,
                            count: count,
                            page: page,
                            headerStyle: theme.mixins.MaterialHeader,
                            cellStyle: theme.mixins.MaterialCell,
                            //pageSize: 10,
                            //pageSizeOptions: [10, 20, 50, { value: dataRows.length, label: 'Show all' }],
                        }}
                    />
                    :
                    <div>
                        There is no data to show now.
                    </div>
                }
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        messageLogData: state.auditLabData.messageLogData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        getMessageLog: (pagination, limit) => dispatch(getMessageLogs(pagination, limit)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageLog);